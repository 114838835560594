import { useMutation } from "@apollo/client";
import { convertToRaw } from "draft-js";

import { GET_OPTIONS, UPSERT_OPTION } from "../../../queries/queries";

export function useAddOption() {
  const [addOption, { loading, error }] = useMutation(UPSERT_OPTION);

  const addOptions = (options: any[]) => {
    return addOption({
      variables: {
        objects: options.map((a) => {
          return {
            ...a,
            value: JSON.stringify(convertToRaw(a.value.getCurrentContent())),
          };
        }),
        update_columns: ["id", "isCorrect", "value"],
      },
      refetchQueries: [
        {
          query: GET_OPTIONS,
          variables: {
            q_id: options[0]?.q_id,
          },
        },
      ],
    });
  };

  return { addOptions, error, loading };
}
