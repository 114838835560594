import { Button, makeStyles } from "@material-ui/core";
import { useModalContext } from "context/modalProvider";
import { ReactNode } from "react";

const useStyles = makeStyles((theme) => ({
  modalContent: {
    marginBottom: theme.spacing(2),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    gap: theme.spacing(2),
  },
  confirmButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.light,
    },
  },
  cancelButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.light,
    },
  },
}));

export const useConfirmModal = () => {
  const { openModal, closeModal } = useModalContext();
  const classes = useStyles();

  const openConfirmModal = (content: ReactNode): Promise<void> => {
    return new Promise((resolve, reject) => {
      openModal(
        "Confirmation",
        <div className={classes.modalContent}>{content}</div>,
        <div className={classes.buttonContainer}>
          <Button
            className={classes.confirmButton}
            onClick={() => {
              resolve();
              closeModal();
            }}
          >
            Confirm
          </Button>
          <Button
            className={classes.cancelButton}
            onClick={() => {
              reject();
              closeModal();
            }}
          >
            Cancel
          </Button>
        </div>,
        "sm"
      );
    });
  };

  return openConfirmModal;
};
