import draftToHtml from "draftjs-to-html";
import {
  Examsonline_Attempt,
  Examsonline_Questions,
  Examsonline_Sessionrecord,
  Examsonline_Tests,
} from "generated/graphql";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import "./answersheet.scss";
import { Tab, Tabs, Paper, Grid } from "@material-ui/core";

import { useTable } from "hooks/useTable";
import { OptionsReadonly } from "shared/components/options-readonly/option-readonly";
import { Helper } from "helper/helper";
import NoRecords from "component/banners/no-records";

interface Props {
  attempt_id: string;
  studentId: string;
  test: Examsonline_Tests;
  attempt: Examsonline_Attempt;
  answers: Examsonline_Sessionrecord[];
}

export function AnswerSheet(props: Props) {

  const {test, attempt, answers}= props;
  const [activeSection, setactiveSection] = useState();

  console.log(props.answers, test.questions)

  if(Helper.validateExamsonlineTest(test)) return <NoRecords  />

  useEffect(() => {
    !!test?.test_sections && setactiveSection(test?.test_sections[0].id);
  }, [test?.test_sections?.length]);

  const handleChange = (e, value) => {
    setactiveSection(value);
  };

  const getSelectedOptions = (id: string) => {
    const selectedOptions = answers?.filter((f) => f.questionId == id);
    if (!selectedOptions) return [];

    return selectedOptions[0]?.selectedOptions || [];
  };

  const transformTableData = (data: Examsonline_Questions[]) => {
    return (
      data?.map((question) => ({
        ...question,
        Question: (
          <>
            <div
              dangerouslySetInnerHTML={{
                __html: draftToHtml(JSON.parse(question?.text)),
              }}
            ></div>
            <OptionsReadonly
              selected={getSelectedOptions(question.id.toString())}
              options={question.options}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: draftToHtml(
                  JSON.parse(question?.explanation as string)
                ),
              }}
            ></div>
          </>
        ),
      })) || []
    );
  };

  const table = useTable({
    data:
      transformTableData(
        test?.questions.filter(
          (f) => f.section == activeSection
        ) as Examsonline_Questions[] || []
      ) || [],
    filterColumns: [
      "__typename",
      "id",
      "options",
      "text",
      "section",
      "test_id",
      "order",
      "type",
      "explanation",
      "user",
      "createdAt",
      "testId",
      ],
    checkDisabled: (row: any) => row.status === "Finished",
    dateFormat: "MMM D YYYY hh:mm A",
    pagination: false,
  });


  return (
    <Container className="answer-sheet">
      <Grid container spacing={2}>
        <Grid item xs={12} className="text-center lead m-2 font-weight-bold">
          AnswerSheet for -{attempt?.email}
        </Grid>
      </Grid>
      <Grid container spacing={2} className="mb-2">
        <Grid item xs={12}>
          <Paper square>
            {activeSection && (
              <Tabs
                value={activeSection}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChange}
                aria-label="disabled tabs example"
                className="small"
                variant="scrollable"
                scrollButtons="auto"
              >
                {test?.test_sections?.map((e) => {
                  return <Tab label={e.name} value={e.id} key={e.id} />;
                })}
              </Tabs>
            )}
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={2} className="m-2 text-center">
        <Grid item xs={12}>
          {table}
        </Grid>
      </Grid>
    </Container>
  );
}
