import React, { createContext, useContext, useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import { useHistory } from "react-router-dom";
import axios from "axios";

interface IAuthContext {
  isLoggedIn: boolean;
  userToken: string | null;
  role: string | null;
  login: (token: string) => void;
  logout: () => void;
  gotoUserHomePage: (role: string) => void;
}

const AuthContext = createContext<IAuthContext | undefined>(undefined);

export const AuthProvider: React.FC = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userToken, setUserToken] = useState<string | null>(
    localStorage.getItem("token")
  );
  const [role, setRole] = useState<string | null>(localStorage.getItem("role"));
  const history = useHistory();

  const login = (token: string) => {
    const decodedToken: { role: string; role_id: string } = jwtDecode(token);
    const role = decodedToken.role.toUpperCase();
    localStorage.setItem("role", role);
    localStorage.setItem("role_id", decodedToken.role_id.toUpperCase());
    localStorage.setItem("userToken", token);
    setUserToken(token);
    setRole(decodedToken.role.toUpperCase());
    setIsLoggedIn(true);

    gotoUserHomePage(role);
  };

  const logout = async () => {
    sessionStorage.clear();
    localStorage.clear();
    setUserToken(null);
    setRole(null);
    setIsLoggedIn(false);
    history.push("/login");
  };

  const gotoUserHomePage = (role) => {
    try {
      switch (role) {
        case "AUTHOR":
          history.push("/author");
          break;
        case "STUDENT":
          history.push("/student");
          break;
        case "SUPPORT":
          history.push("/support");
          break;
        case "ORG-ADMIN":
          history.push("/org-admin");
          break;
        default:
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    // make axios call to /auth/verify to check if we are logged in
    // if we are logged in then set the state to true

    userToken &&
      axios
        .post(`${import.meta.env.VITE_REACT_APP_API_URL}/auth/verify`, {
          token: userToken,
        })
        .then((res) => {
          if (res?.status === 201) {
            setIsLoggedIn(true);
            // if on homepage and logged in, redirect to user homepage
            if (history.location.pathname === "/") {
              gotoUserHomePage(role);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });

    const unlisten = history.listen((location) => {
      if (location.pathname === "/") {
        gotoUserHomePage(role);
      }
    });

    return () => {
      unlisten();
    };
  }, [userToken, role, history]);

  return (
    <AuthContext.Provider
      value={{ isLoggedIn, userToken, role, login, logout, gotoUserHomePage }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
