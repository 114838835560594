import { useGet_CommentsQuery } from "generated/graphql";
import { useState } from "react";

export function useGetComments(
  offset: number,
  limit = 10,
  test_id?: number,
  ticket_id?: string,
  ref_id?: string
) {
  const [comments, setcomments] = useState<any[]>([]);
  const [total, settotal] = useState<any>(0);

  const { data, loading, error, refetch, fetchMore } = useGet_CommentsQuery({
    variables: {
      _eq: test_id,
      offset: offset,
      limit: limit,
      ticket_id: ticket_id,
      ref_id: ref_id,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (res) => {
      setcomments(res.examsonline_comments);
      settotal(res?.total_count?.aggregate?.count);
    },
  });

  return { data, loading, error, comments, refetch, fetchMore, total };
}
