import { Component } from "react";
import { Card, CardDeck, Col, Container, Row } from "react-bootstrap";
import { Result } from "../../models/result.model";
import "./result.scss";

export interface Props {
  history: any;
  data: Result;
}

class SecondContainer extends Component<Props> {
  render() {
    return (
      <Container fluid="md" className="pt-3" id="second">
        <Row className="text-center">
          <Col md={10} className="alignCenter">
            <CardDeck>
              <Card className="font-weight-bold  bg-success">
                <Card.Body>
                  <span>{this.props.data?.attempt.metadata.correct} Correct</span>
                </Card.Body>
              </Card>
              <Card className="bg-warning">
                <Card.Body>
                  <span> {this.props.data?.attempt.metadata.skipped} Skipped</span>
                </Card.Body>
              </Card>
              <Card className="font-weight-bold text-white bg-dark">
                <Card.Body>
                  <span>{this.props.data.attempt.metadata.incorrect} Wrong</span>
                </Card.Body>
              </Card>
            </CardDeck>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default SecondContainer;
