import {
  Grid,
  Card,
  CardContent,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Chip,
} from "@material-ui/core";
import { TestAnalyticsResult } from "models/analytics/testAnalytics.model";
import useFetch from "hooks/useFetchPreview";
import GenericBarChart from "./charts/generticBarChart";

const TestAnalytics = ({ testId }) => {
  const { data } = useFetch<TestAnalyticsResult>(`/analytics/test/${testId}`);

  // const { test } = useTestById(testId);

  const startDate = new Date();
  startDate.setMonth(startDate.getMonth() - 1);

  if (!data) return null;

  const colors = ["default", "primary", "secondary", "error", "warning", "info", "success"];


  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Card>
          <CardContent>
            <Typography variant="h5" component="h2">
              <Chip label={data?.totalTestSessions} color="default" />
              <span> Test Sessions</span>
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={6}>
        <Card>
          <CardContent>
            <Typography variant="h5" component="h2">
              <Chip
                label={data?.averageCompletionTimeMinutes + " mins"}
                color="default"
              />
              <span> Average Time to Complete</span>
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={6}>
        <Card>
        <CardContent>
            <Typography variant="h5" component="h2">
              Score Statistics
            </Typography>

            <Table size="small">
              <TableBody>
                {data.scoreStatistics.map((stat, index) => (
                  <TableRow key={index}>
                    <TableCell>{stat.category}</TableCell>
                    <TableCell>
                      <Chip
                        label={stat.count.toFixed(2)}
                        color={colors[index % colors.length] as "default" | "primary" | "secondary"}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={6}>
        <Card>
          <CardContent>
            <Typography variant="h5" component="h2">
              Top Scored Sessions
            </Typography>
            <Table size="small">
              <TableBody>
                {data.topScorers.map((stat, index) => (
                  <TableRow key={index}>
                    <TableCell>{stat.session}</TableCell>
                    <TableCell>{stat.score}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} lg={6}>
        <GenericBarChart
          title={"Sessions by Date"}
          data={data?.sessionsByDate}
          xAxisKey="date"
          barKey="count"
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <GenericBarChart 
          title={"Average Session Scores"}
          data={data.scoreCandlestickData}
          xAxisKey="date"
          barKey="average"
          barColor="green"
        />
      </Grid>
    </Grid>
  );
};

export default TestAnalytics;
