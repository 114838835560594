import { useMutation } from "@apollo/client";
import { cloneDeep } from "lodash";

import { DELETE_OPTION, GET_OPTIONS } from "../../../queries/queries";

export function useDeleteOptions(q_id: number) {
  const [deleteOpt, { loading, error }] = useMutation(DELETE_OPTION, {
    update(cache, { data }) {
      const existing: any = cache.readQuery({
        query: GET_OPTIONS,
        variables: { q_id: q_id },
      });
      const updated = (cloneDeep(existing).examsonline_options =
        existing.examsonline_options.filter(
          (t: any) => t.id !== data.delete_examsonline_options_by_pk.id
        ));
      cache.writeQuery({
        query: GET_OPTIONS,
        variables: { q_id: q_id },
        data: { examsonline_options: updated },
      });
    },
  });

  const deleteOption = (id: number | undefined) => {
    return deleteOpt({
      variables: {
        id: id,
      },
    });
  };

  return { deleteOption, error, loading };
}
