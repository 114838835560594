import {  HTMLProps } from "react";
import { Container, Jumbotron, Row } from "react-bootstrap";
import "./banner.scss";

export default function TestOffline(props: HTMLProps<any>) {
  return (
    <Container
      hidden={Boolean(props.hidden)}
      className="banner-no-records"
    >
      <Row className="text-center">
        <Jumbotron className=" lead font-size-bold">
          This Test is currently offline and cannot be attempted. Please check
          with the author of this test to enable it.
        </Jumbotron>
      </Row>
    </Container>
  );
}
