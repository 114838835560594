import { WatchQueryFetchPolicy } from "@apollo/client";
import { useGet_Quiz_By_IdQuery } from "generated/graphql";
import { Helper } from "helper/helper";
import { Quiz, quiztInitialState } from "models/quiz.model";
import { useEffect, useState } from "react";

export function useQuizById(
  id: string,
  policy = "cache-and-nework",
  onCompleted = () => {}
) {
  const [quiz, setquiz] = useState<any>(null); // TODO add a  type
  const [quizEditorstate,setquizEditorstate] = useState<Quiz>(quiztInitialState);

  const { data, loading, error, refetch } = useGet_Quiz_By_IdQuery({
    variables: {
      id: id,
      order_by: [],
    },
    fetchPolicy: policy as WatchQueryFetchPolicy,
    onCompleted: onCompleted,
  });

  useEffect(() => {
    if (!!data) {
      setquiz(data.examsonline_quiz[0]);
        setquizEditorstate(
          Helper.contentStateToQuizEditorState(data.examsonline_quiz[0] as any)
        );
    }
  }, [data]);

  return { quiz, loading, error, data, refetch, quizEditorstate };
}
