import {
  Grid,
  IconButton,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";
import GroupsGrid from "component/groups/grid/groups-grid";
import {
  Examsonline_Tests,
  useGet_Contest_By_IdQuery,
  useGet_Test_By_IdQuery,
  useRemove_Test_From_ContestMutation,
  useUpdate_Contest_Test_IdMutation,
} from "generated/graphql";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";
import { useHistory, useParams } from "react-router";
import {
  TestCardColumns,
  TestCardNext,
} from "shared/components/cards/test-card/test-card";
import TestGrid from "shared/grids/test-grid";
import ParticipantsGrid from "./grid/participants-grid";
export function AssignContest() {
  const params: { id: string } = useParams();
  const history = useHistory();

  const [updateContestTestId] = useUpdate_Contest_Test_IdMutation();
  const { data } = useGet_Contest_By_IdQuery({
    variables: {
      _eq: params.id,
    },
  });

  const { data: test_data } = useGet_Test_By_IdQuery({
    variables: {
      id: data?.examsonline_contest[0].test_id,
    },
  });

  const test = test_data?.examsonline_tests[0]


  const [removeTestFromContestMutation] = useRemove_Test_From_ContestMutation();

  const deleteTest = () => {
    removeTestFromContestMutation({
      variables: {
        _eq: params.id,
      },
    });
  };

  const onsubmit = () => {
    history.push(`/contest/preview/${data?.examsonline_contest[0]?.id}`);
  };

  const onBack = () => {
    history.push(`/contest/create/${data?.examsonline_contest[0]?.id}`);
  };

  function getSteps() {
    return ["Assign Test", "Invite User", "Participants"];
  }

  const onSelect = (test: Examsonline_Tests) => {
    updateContestTestId({
      variables: {
        test_id: test.id,
        _eq: params.id,
      },
    });
  };

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return (
          <div>
            {" "}
            <Row hidden={!data?.examsonline_contest[0].test_id}>
              <Col style={{ fontSize: ".6em" }} className="w-75" lg={10}>
                {test && (
                  <TestCardNext
                    test={test as Examsonline_Tests}
                    edit={() => {}}
                    readonly={true}
                    hideColumns={[
                      TestCardColumns.IMPORT,
                      TestCardColumns.BUY,
                      TestCardColumns.STATS,
                      TestCardColumns.CHANGE_STATUS,
                      TestCardColumns.ASSIGN,
                      TestCardColumns.ACTIONS,
                    ]}
                  />
                )}
              </Col>
              <Col xs={4} className="text-center m-auto" lg={2}>
                <span>
                  {" "}
                  <IconButton
                    style={{ fontSize: "1.2em" }}
                    title="Assign Test"
                    size="small"
                    color="secondary"
                    className="m-auto"
                    aria-label=""
                    onClick={deleteTest}
                  >
                    <FaTrash></FaTrash>
                  </IconButton>
                </span>
              </Col>
            </Row>
          </div>
        );
      case 1:
        return (
          <Grid xs={12} item>
            <GroupsGrid readonly={true} limit={5} contest_id={params.id} />
          </Grid>
        );
      case 2:
        return (
          <Grid xs={12} item>
            <ParticipantsGrid contest_id={params.id} />
          </Grid>
        );
      default:
        return "Unknown step";
    }
  }

  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Container className="pt-lg-2 pt-md-2 mb-2 mt-3 ">
      <Row>
        <Col className="md-6">
          <h2 className="text-center">Assign A Test For This Contest</h2>
        </Col>
      </Row>
      <Row hidden={!!data?.examsonline_contest[0].test_id}>
        <Col xs={12} className="m-auto font-weight-bold mt-2">
          <TestGrid
            enableSelect={true}
            hideColumns={[
              TestCardColumns.IMPORT,
              TestCardColumns.BUY,
              TestCardColumns.STATS,
              TestCardColumns.ASSIGN,
              TestCardColumns.ACTIONS,
            ]}
            onSelect={onSelect}
            contest={params.id}
          />
        </Col>
      </Row>

      <Row hidden={!data?.examsonline_contest[0].test_id} lg={10}>
        <Col>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
                <StepContent>
                  <Typography>{getStepContent(index)}</Typography>
                  <div>
                    <div>
                      <Row className="text-center">
                        <Col className="m-2">
                          <Button
                            hidden={activeStep !== 0}
                            variant="contained"
                            color="primary"
                            onClick={onBack}
                          >
                            Back
                          </Button>

                          <Button
                            variant="contained"
                            color="primary"
                            hidden={activeStep === 0}
                            onClick={handleBack}
                          >
                            Back
                          </Button>
                        </Col>
                        <Col className="m-2">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                          >
                            {activeStep === steps.length - 1
                              ? "Finish"
                              : "Next"}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </StepContent>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length && (
            <Row className="text-center my-2">
              <Col className="m-2" lg={3}>
                <Button color="primary" variant="contained" onClick={onBack}>
                  Back
                </Button>
              </Col>
              <Col className="m-2">
                <Button color="primary" variant="contained" onClick={onsubmit}>
                  Next
                </Button>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Container>
  );
}
