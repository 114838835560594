import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { Add, PostAdd, Search } from "@material-ui/icons";
import { FaLightbulb } from "react-icons/fa";

type ActionType = "add" | "search" | "addSection" | "Generate";

interface Action {
  type: ActionType;
  handler: () => void;
  tooltip: string;
}

interface EditorActionsProps {
  actions: Action[];
}

const EditorActions: React.FC<EditorActionsProps> = ({ actions }) => {
    const icons = {
      add: <Add />,
      search: <Search />,
      addSection: <PostAdd />,
      Generate: <FaLightbulb />,
    };
  
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        {actions.map((action, index) => (
          <Tooltip key={index} title={action.tooltip}>
            <IconButton color="primary" onClick={action.handler}>{icons[action.type]}</IconButton>
          </Tooltip>
        ))}
      </div>
    );
  };
  
  export default EditorActions;
