import { useMutation } from "@apollo/client";
import { ADD_TEST } from "queries/queries";

export const useCreateTest = () => {
  const [createTestMutation, { loading, error }] = useMutation(ADD_TEST);

  const createTest = async (test) => {
    try {
      const { data } = await createTestMutation({
        variables: {
          objects: test,
          update_columns: [
            "randomise",
            "pincode",
            "createdAt",
            "time",
            "enabled",
            "accesstype",
            "name",
            "emailnotify",
            "document",
            "maxAttempts",
            "isDraft",
            "showResult",
            "author",
            "updatedAt",
            "errorMessage",
            "description",
            "price",
          ],
        },
      });


      return data;
    } catch (err) {
      console.error(err);
    }
  };

  return { createTest, loading, error };
};