import "react-tagsinput/react-tagsinput.css";
import {
  Examsonline_Contest,
  Examsonline_Contest_Update_Column,
  useCreate_Contest_FormMutation,
  useGet_Contest_By_IdQuery,
} from "generated/graphql";
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { Button } from "@material-ui/core";
import { useHistory, useParams } from "react-router";
import { Formik } from "formik";
import * as yup from "yup";
import TagsInput from "react-tagsinput";
import { useState } from "react";
import Loading from "shared/components/loader/loading";
import {
  DELETE_TOPICS_BT_CONTEST_ID,
  GET_CONTEST_BY_ID,
} from "queries/queries";
import { useApolloClient } from "@apollo/client";
import _, { cloneDeep } from "lodash";

export interface Props {
  history: any;
  editTest: Function;
  match: any;
}

const initialvalue: Examsonline_Contest = {
  name: "",
  duration: 15,
  description: "",
  topics: [],
  author: "",
  startDate: new Date(),
  endDate: new Date(),
  id: "",
  comments: [],
  comments_aggregate: {
    aggregate: undefined,
    nodes: [],
  },
  files: [],
  files_aggregate: {
    aggregate: undefined,
    nodes: [],
  },
  participates: [],
  participates_aggregate: {
    aggregate: undefined,
    nodes: [],
  },
  ratings: [],
  ratings_aggregate: {
    aggregate: undefined,
    nodes: [],
  },
  topics_aggregate: {
    aggregate: undefined,
    nodes: [],
  },
  attempts: [],
  attempts_aggregate: {
    __typename: undefined,
    aggregate: undefined,
    nodes: [],
  }
}


const schema = yup.object({
  name: yup.string().required("test name is required"),
  duration: yup
    .number()
    .required("completion time is required")
    .integer(" should be an integer number")
    .min(1, " time cannot be less than 1 minute"),
  description: yup.string().required("minimum 10 characters required").min(10),
  topics: yup.array().required("please enter atleast one topic"),
  id: yup.string(),
  startDate: yup.string().required("startdate is require"),
  endDate: yup.string().required("enddate is require"),
  author: yup.string(),
});

export default function CreateContest(props: Props) {
  const [contest, setcontest] = useState<Examsonline_Contest>(initialvalue);
  const client = useApolloClient();
  const [createContestFormMutation] = useCreate_Contest_FormMutation({
    variables: {
      update_columns: [
        Examsonline_Contest_Update_Column.Name,
        Examsonline_Contest_Update_Column.Duration,
        Examsonline_Contest_Update_Column.Description,
        Examsonline_Contest_Update_Column.StartDate,
        Examsonline_Contest_Update_Column.EndDate
      ],
    },
  });

  const history = useHistory();
  const params: { id: string } = useParams();

  const deleteTopics = async (values: any) => {
    if (!_.isEqual(values?.topics, contest?.topics)) {
      client
        .mutate({
          mutation: DELETE_TOPICS_BT_CONTEST_ID,
          variables: { _eq: params.id },
          refetchQueries: [
            { query: GET_CONTEST_BY_ID, variables: { _eq: params.id } },
          ],
        })
        .then((a) => {
          console.log("old topics tags cleared");
        })
        .catch((e) => {
          console.error("error ocurred", e);
        });
    } else {
      values.topics = [];
      Promise.resolve();
    }
  };
  const onsubmit = async (values) => {
    const temp = cloneDeep(values);
    if (params.id) {
      await deleteTopics(temp);
    }
    delete temp.author;
    delete temp.files;
    delete temp.topics_aggregate;
    delete temp.ratings_aggregate;
    delete temp.comments_aggregate;
    delete temp.participates_aggregate;
    delete temp.files_aggregate;
    delete temp.files;
    delete temp.ratings;
    delete temp.comments;
    delete temp.participates;
    delete temp.attempts_aggregate
    delete temp.attempts

    if (temp.id === "") {
      delete temp.id;
    }

    temp.topics = {
      data: temp.topics
        .map((t: any) => (!!t?.tag ? t : { tag: t }))
        .map((t: any) => _.omit(t, "__typename")),
    };
    createContestFormMutation({
      variables: {
        objects: [temp],
      },
    }).then((data) => {
      history.push(
        `/contest/assign/${data.data?.insert_examsonline_contest?.returning[0].id}`
      );
    });
  };

  const { loading, error } = useGet_Contest_By_IdQuery({
    variables: { _eq: params.id },
    skip: !params.id,
    onCompleted: (data) => {
      setcontest(data.examsonline_contest[0] as Examsonline_Contest);
    },
  });

  if (error && !!props.match.params.id) return <p> error</p>;

  return (
    <Container fluid className="p-4 " id="create-test-form">
      {loading && <Loading />}
      <Row className="text-center pb-3">
        <Col className="m-auto" xs={12} lg={6}>
          <h1 className="my-2">Create Contest</h1>
          <Formik
            enableReinitialize
            validationSchema={schema}
            onSubmit={onsubmit}
            initialValues={{ ...contest }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
              setFieldValue,
            }) => (
              <Form
                className="  text-center"
                noValidate
                onSubmit={handleSubmit}
              >
                <Row>
                  <Form.Group as={Col} md={6} xs={12} controlId="testname">
                    <Form.Label>Contest Name</Form.Label>
                    <Form.Control
                      autoComplete="off"
                      type="text"
                      name="name"
                      placeholder="Enter contest name"
                      onChange={handleChange}
                      value={values.name}
                      isValid={Boolean(touched.name && !errors.name)}
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="6" xs={12} controlId="time">
                    <Form.Label>Duration</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="duration"
                        onChange={handleChange}
                        value={values.duration}
                        isValid={Boolean(touched.duration && !errors.duration)}
                        isInvalid={!!errors.duration}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text id="inputGroupPrepend">
                          mins
                        </InputGroup.Text>
                      </InputGroup.Append>
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {errors.duration}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group as={Col} md={6} xs={12} controlId="startdate">
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control
                      id="startDate"
                      type="datetime-local"
                      name="startDate"
                      onChange={handleChange}
                      value={values.startDate}
                      isValid={Boolean(touched.startDate && !errors.startDate)}
                      isInvalid={!!errors.startDate}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.startDate}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md={6} xs={12} controlId="startdate">
                    <Form.Label>End Date</Form.Label>
                    <Form.Control
                      id="endDate"
                      type="datetime-local"
                      name="endDate"
                      onChange={handleChange}
                      value={values.endDate}
                      isValid={Boolean(touched.endDate && !errors.endDate)}
                      isInvalid={!!errors.endDate}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.endDate}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    className="text-left mx-auto"
                    as={Col}
                    md="6"
                    controlId="topics"
                  >
                    <Form.Label>Topics related to this Contest</Form.Label>
                    <TagsInput
                      inputProps={{
                        placeholder: "eg maths, science",
                      }}
                      value={values?.topics?.map((t: any) => {
                        if (typeof t !== "string") {
                          return t?.tag;
                        }
                        return t;
                      })}
                      onChange={(e) =>
                        setFieldValue(
                          "topics",
                          e.map((sr) => sr.toLowerCase())
                        )
                      }
                      addOnBlur={true}
                    />
                    <Form.Control
                      autoComplete="off"
                      onBlur={handleBlur}
                      hidden={true}
                      name="topics"
                      onChange={() => {}}
                      value={values.topics}
                      isValid={Boolean(touched.topics && !errors.topics)}
                      isInvalid={!!errors.topics}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.topics}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="12" xs={12} controlId="description">
                    <Form.Label>Contest Description</Form.Label>
                    <Form.Control
                      autoComplete="off"
                      as="textarea"
                      type="text"
                      name="description"
                      placeholder="Few words about this contest"
                      onChange={handleChange}
                      value={values.description}
                      isValid={Boolean(
                        touched.description && !errors.description
                      )}
                      isInvalid={!!errors.description}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.description}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Button type="submit" color="primary" variant="contained">
                  Next{" "}
                </Button>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </Container>
  );
}
