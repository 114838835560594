import {
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  TablePagination,
  TableSortLabel,
  TextField,
  Grid,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NoRecords from "component/banners/no-records";
import moment from "moment";
import { ReactElement, useState } from "react";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    "MuiTableCell-root": {
      fontSize: ".8rem",
      fontWeight: "bold",
      margin: "0",
      padding: "3px",
    },
  },
  paper: {
    width: "100%",
    marginBottom: "20px",
  },
  header: {
    fontWeight: "bold",
    padding: "10px",
  },
  toolbar: {
    fontSize: "20px",
    padding: "10px",
    fontWeight: "bold",
    verticalAlign: "middle",
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#f3f3f3",
    },
  },
  disabled: {
    backgroundColor: "#ddd",
  },
});

interface TableProps<T> {
  data: T[];
  filterColumns?: string[];
  checkDisabled?: (item: T) => boolean;
  dateFormat?: string;
  limit?: number;
  noRecordsMessage?: string;
  pagination?: boolean;
  total?: number; // total number of pages
  onPageChange?: (page: number, limit: number) => void; // function to handle page change
  handleSearch?: (value: string) => void;
  heading?: string | ReactElement;
  toolbar?: boolean;
}

export function useTable<T>(props: TableProps<T>) {
  const classes = useStyles();
  const {
    data,
    filterColumns,
    checkDisabled,
    dateFormat = "MMM D YYYY hh:mm A",
    noRecordsMessage,
    handleSearch,
  } = props;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(props?.limit || 10);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [sortField, setSortField] = useState<string | null>(null);
  const [query, setQuery] = useState<string>("");

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    if (props.onPageChange) {
      props.onPageChange(newPage, rowsPerPage);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    if (props.onPageChange) {
      props.onPageChange(0, newRowsPerPage);
    }
  };

  const handleSort = (field: string) => {
    setSortField(field);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const handleSearchQuery = (value: string) => {
    setQuery(value);
    if (handleSearch) {
      handleSearch(value);
    }
  };

  if (data?.length == 0)
    return (
      <Paper className="w-100">
        <Grid>
          <h2 className="m-3">{props.heading}</h2>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className=" p-5"
        >
          <NoRecords
            message={noRecordsMessage || "No records found for this table"}
          />

          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setQuery("");
              props.handleSearch && props.handleSearch("");
            }}
            hidden={!query}
          >
            Reset
          </Button>
        </Grid>
      </Paper>
    );

  const sortedData = [...data].sort((a, b) => {
    if (sortField) {
      if (a[sortField] < b[sortField]) {
        return sortOrder === "asc" ? -1 : 1;
      }
      if (a[sortField] > b[sortField]) {
        return sortOrder === "asc" ? 1 : -1;
      }
    }
    return 0;
  });

  const filteredKeys = Object.keys(sortedData[0] as object).filter(
    (key) => !filterColumns?.includes(key)
  );

  const displayedData = props.pagination
    ? sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : sortedData;

  return (
    <Paper elevation={1} className={classes.paper}>
      <Grid
        hidden={!props.toolbar}
        container
        justifyContent="space-between"
        className="p-2"
      >
        <Grid item xs={12} sm={6}>
          <h2 className={classes.toolbar}>{props.heading}</h2>
        </Grid>
        <Grid item>
          <TextField
            variant="outlined"
            placeholder="Search..."
            onChange={(e) => handleSearchQuery(e.target.value)}
            size="small"
          />
        </Grid>
      </Grid>
      <TableContainer>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell
                hidden={filterColumns?.includes("s_no")}
                className={classes.header}
              >
                S.No
              </TableCell>
              {filteredKeys.map((key) => (
                <TableCell key={key} align="center" className={classes.header}>
                  <TableSortLabel
                    active={sortField === key}
                    direction={sortOrder}
                    onClick={() => handleSort(key)}
                  >
                    {key.toUpperCase()}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedData.map((item, index) => (
              <TableRow
                key={index}
                className={`${classes.row} ${
                  checkDisabled?.(item) ? classes.disabled : ""
                }`}
              >
                <TableCell align="center">
                  {index + 1 + page * rowsPerPage}
                </TableCell>
                {filteredKeys.map((key) => (
                  <TableCell key={key} align="center">
                    {Array.isArray(item[key])
                      ? item[key].join(", ")
                      : typeof item[key] === "string" &&
                        moment(item[key], moment.ISO_8601, true).isValid() &&
                        dateFormat
                      ? moment(item[key]).format(dateFormat)
                      : item[key]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {props.pagination && (
        <TablePagination
          rowsPerPageOptions={[props.limit || 5]}
          component="div"
          count={props.total || data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
}
