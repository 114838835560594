// import Axios from "axios";
import NoRecords from "component/banners/no-records";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { useTestById } from "shared/hooks/useTestById";
import "./success-test.scss";
import { Examsonline_Questions, Examsonline_Test_Sections, useUpdate_Draft_StatusMutation } from "generated/graphql";
import Loading from "shared/components/loader/loading";
import { QuestionsAccordian } from "shared/components/questions-accordian/question-accordian";
import { FaArrowLeft } from "react-icons/fa";
import { useState } from "react";
import { Grid, Paper, Tab, Tabs } from "@material-ui/core";
import CreateTestSessionForm from "component/test/components/session/createTestSessionForm";

export interface Props {
  history: any;
  match: any;
}

export function SuccessTestNext(props: Props) {
  const { loading, test, error } = useTestById(props.match.params.id);
  const [tabindex, settabindex] = useState(0);

  const [updateDraftStatusMutation, { loading: savingdraft }] =
    useUpdate_Draft_StatusMutation();

  const hanldeTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    settabindex(newValue);
  };

  const goToPreview = async () => {
    await updateDraftStatusMutation({
      variables: {
        _eq: test?.id,
        isDraft: false,
      },
    });
    props.history.push("/preview/" + props.match.params.id);
  };
  const goToAuthor = () => {
    props.history.push("/edit/" + props.match.params.id);
  };

  if (test?.questions?.length === 0) return <NoRecords />;
  if (error) return <p>An error ocurred</p>;

  return (
    <div className="success-test">
      {loading && <Loading />}
      <Container fluid>
        <Grid item xs={12} lg={6} className="mx-auto mt-3 p-2 ">
          <Paper square>
            <Tabs
              centered
              indicatorColor="primary"
              textColor="primary"
              aria-label="disabled tabs example"
              onChange={hanldeTabChange}
              value={tabindex}
            >
              <Tab title="Success" label="Success" className="SucessTab " />
              <Tab title="Preview" label="Preview" className="PreviewTab" />
            </Tabs>
          </Paper>
        </Grid>
        <div hidden={tabindex !== 1}>
          <Row>
            <Col lg={12} xs={12} className="preview-panel bg-light ">
              <Col xs={12} className="p-1 p-lg-5 bg-white">
                <QuestionsAccordian
                  questions={test?.questions as Examsonline_Questions[]}
                  sections={test?.test_sections as Examsonline_Test_Sections[]}
                />
              </Col>
            </Col>
          </Row>
        </div>
        <div hidden={tabindex !== 0}>
          <Row>
            <Col lg={6} md={8} xs={12} className="side-bar m-auto ">
              <CreateTestSessionForm testId={props.match.params.id} onSuccess={function (id: string): void {
                throw new Error("Function not implemented.");
              } } />
            </Col>
          </Row>
        </div>
        <Row className="my-5 justify-content-around">
          <Col md="6" lg={3}>
            <Button className="col" variant="outline-dark" onClick={goToAuthor}>
              Back <FaArrowLeft />
            </Button>
          </Col>

          <Col md="6" lg={3}>
            <Button
              className="col font-weight-bold"
              variant="primary"
              onClick={goToPreview}
            >
              Finish {savingdraft && <Spinner animation="border" />}
            </Button>{" "}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SuccessTestNext;
