import { useQuery } from "@apollo/client";
import { Order_By } from "generated/graphql";
import { useState } from "react";
import { GET_TOP_STUDENTS } from "../../../queries/queries";

export interface StudentScore {
  email: string;
  percentage: number;
  correct: number;
  wrong: number;
  skipped: number;
  count: any;
}

export function useGetTopStudents(test_id: number | undefined, limit = 5, perc_order_by?:Order_By, correct_order_by?: Order_By ) {
  const [students, setstudents] = useState<StudentScore[]>([]);
  const { loading, error, refetch, fetchMore } = useQuery(GET_TOP_STUDENTS, {
    variables: { _eq2: test_id, limit: limit, perc_order_by: perc_order_by, _correct_order_by: correct_order_by },
    fetchPolicy: "cache-first",
    skip: !test_id,
    onCompleted: (data) => {
      setstudents(data.examsonline_attempt);
    },
  });

  return { students, loading, error, refetch, fetchMore };
}
