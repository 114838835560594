import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const GenericModal = ({
  isOpen,
  handleClose,
  title,
  content,
  footer,
  size,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  console.log(content);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={size}
      fullScreen={fullScreen}
      style={{ zIndex: 9999 }}
    >
      <DialogTitle>
        {title}
        <IconButton
          style={{ position: "absolute", right: "10px", top: "10px" }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions className="bg-light">{footer}</DialogActions>{" "}
    </Dialog>
  );
};

export default GenericModal;
