import { Formik } from "formik";
import { Component } from "react";
import { Button, Form, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import * as yup from "yup";

export default class StudentResultSearch extends Component<any> {
  checkTestId = (form: any) => {
    this.props.history.push(`/findresults/${form.email}`);
  };
  schema1 = yup.object({
    email: yup
      .string()
      .required("No email or Roll No provided.")
      .min(3)
      .matches(/^[a-zA-Z0-9_.@-]+$/, "Invalid Email or Roll No"),
  });
  render() {
    return (
      <div>
        <Col xs={12} lg={6} className="m-auto">
          <Card>
            <Card.Body>
              <Formik
                validationSchema={this.schema1}
                onSubmit={this.checkTestId}
                initialValues={{ email: "" }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  errors,
                }) => (
                  <Form id="right-box" noValidate onSubmit={handleSubmit}>
                    <label className="testTitle p-3" htmlFor="basic-url">
                      Find Results
                    </label>
                    <Form.Group controlId="formGridTestId">
                      <Form.Control
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.email && !errors.email}
                        isInvalid={!!errors.email}
                        placeholder="Enter your email or Roll no"
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Col
                        sm={{ span: 12 }}
                        className="text-center startTestBtn"
                      >
                        <Button
                          type="submit"
                          variant="primary"
                          size="lg"
                          className="col-md-4 btn mr-2"
                          active
                        >
                          Search
                        </Button>
                      </Col>
                    </Form.Group>
                  </Form>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </Col>
      </div>
    );
  }
}
