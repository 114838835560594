import React, { useState, useCallback, useEffect } from "react";
import { Chip, Input } from "@material-ui/core";
import { useUpdate_Question_By_IdMutation } from "generated/graphql";
import { GET_TEST_BY_ID } from "queries/queries";
import { useAppContext } from "context/appProvider";

interface MarksInputProps {
  initialMarks: number;
  readonly?: boolean;
  questionId: number;
}

const MarksInput: React.FC<MarksInputProps> = ({
  initialMarks,
  readonly = false,
  questionId,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [marks, setMarks] = useState(initialMarks);
  const context = useAppContext();

  const [updateQuestion] = useUpdate_Question_By_IdMutation();

  useEffect(() => {
    setMarks(initialMarks);
  }, [initialMarks]);

  const handleButtonClick = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation(); // Prevent React synthetic event propagation
    setIsEditing(true);
  }, []);

  const handleInputChange = useCallback((event) => {
    setMarks(event.target.value);
  }, []);

  const handleInputBlur = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsEditing(false);

      await updateQuestion({
        variables: {
          _eq: questionId,
          _set: {
            marks: marks,
          },
        },
        refetchQueries: [{query: GET_TEST_BY_ID, variables: {id: context.inEditTest?.id }}],
      });
    },
    [marks, questionId, updateQuestion]
  );

  return isEditing ? (
    <span onClick={(e) => e.stopPropagation()}>
      <Input
        style={{ maxWidth: "50px" }}
        type="number"
        className="bg-white"
        value={marks}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
      />
    </span>
  ) : (
    <span onClick={(e) => e.stopPropagation()}>
      <Chip
        variant="default"
        className="bg-success text-dark"
        color="secondary"
        onClick={handleButtonClick}
        label={`${marks} point${marks !== 1 ? "s" : ""}`}
      />
    </span>
  );
};

export default MarksInput;
