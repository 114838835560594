import Modal from "react-bootstrap/Modal";

import { appState } from "../cache/appState";
import { useTestById } from "../hooks/useTestById";
import Loading from "shared/components/loader/loading";
import { useAppState } from "shared/hooks/useUpdateAppstate";
import AuthorLogin from "component/auth/login/author-login";
import { useHistory, useLocation } from "react-router-dom";

export function AskLoginModal(props: any) {
  const { state } = useAppState();


  const location = useLocation()

  const isValidLocation = ()=>{
      if(location.pathname.toString().includes('login') || location.pathname.toString().match('')){
          return false
      }
      return true
  }

  const handleClose = () => {
    appState({ ...appState(), askToLogin: false });
  };
  const { test, loading } = useTestById(state.selectedTestId);
  const history = useHistory();

  return (
    <Modal
      size="lg"
      dialogClassName="modal-large"
      show={state.askToLogin && isValidLocation()}
      onHide={handleClose}
    >
      {loading && <Loading />}
      <Modal.Header closeButton className="text-center">
        <Modal.Title>
          {test?.name} - {test?.questions.length} Questions
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="px-0 mx-0">
        <AuthorLogin history={history} />
      </Modal.Body>

      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}
