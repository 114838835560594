import { useMutation } from '@apollo/client';
import { cloneDeep } from 'lodash';

import { DELETE_QUESTION, GET_TEST_BY_ID } from '../../../queries/queries';
import { Get_Test_By_IdDocument } from 'generated/graphql';

export default function useDeleteQuestion(test_id: number) {
  const [deleteQues] = useMutation(DELETE_QUESTION, {
    update(cache, { data }) {
      const existing: any = cache.readQuery({
        query: GET_TEST_BY_ID,
        variables: { id: test_id },
      });
      const updated = (cloneDeep(existing).examsonline_tests[0]!.questions =
        existing.examsonline_tests[0]!.questions.filter(
          (t: any) => t.id !== data.delete_examsonline_questions.returning[0].id
        ));

        return updated;
      // cache.writeQuery({
      //   query: GET_TEST_BY_ID,
      //   variables: { id: test_id },
      //   data: { examsonline_tests: updated },
      // });
    },
    refetchQueries:[Get_Test_By_IdDocument]
  });

  const deleteQuestion = (id: number) => {
    deleteQues({
      variables: {
        _eq: id,
      },
    });
  };

  return { deleteQuestion}
}
