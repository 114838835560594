import { Grid, Button, TextField } from "@material-ui/core";
import { FileCopy, OpenInNew } from "@material-ui/icons";
import QRCode from "qrcode.react";

export function ShareUrl({ url }) {
  const copyLink = () => {
    navigator.clipboard.writeText(url);
  };

  const openLink = () => {
    window.open(url, "_blank");
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} zeroMinWidth container justifyContent="center" className="p-3 m-5">
        <QRCode value={url} />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          value={url}
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid item xs={6} justifyContent="center">
        <Button
          variant="outlined"
          startIcon={<FileCopy fontSize="small" />}
          onClick={copyLink}
          size="small"
        >
          Copy Link
        </Button>
      </Grid>
      <Grid item xs={6} justifyContent="center">
        <Button size="small" variant="outlined" startIcon={<OpenInNew />} onClick={openLink}>
          Open Link
        </Button>
      </Grid>
    </Grid>
  );
}
