import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const useFetch = <T,>(path: string, skip: boolean = false) => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    if (skip) return;
    setLoading(true);
    try {
      const response = await axios.get(path);
      setData(response.data);
      setError(null);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, [path, skip]);

  useEffect(() => {
    fetchData();
  }, [fetchData, skip]); // Added skip as a dependency

  return { data, loading, error, refetch: fetchData };
};

export default useFetch;