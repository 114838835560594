import { Grid, Paper, Box, useTheme } from "@material-ui/core";
import { IsCorrect } from "component/question/edit/iscorrect";
import draftToHtml from "draftjs-to-html";
import { Option } from "models/option.model";
import "./options-readonly.css"; // New CSS file for styling

interface Props {
  options: Option[];
  selected: number[];
}

export function OptionsReadonly({ options, selected }: Props) {
  const theme = useTheme();

  const isCorrect = (option: Option) => {
    const isSelected = selected.includes(option.id);

    if (
      !isSelected ||
      (selected.length < options.filter((o) => o.isCorrect).length &&
        selected.length > 0)
    )
      return "skipped";

    if (isSelected && option.isCorrect) return true;

    if (isSelected && !option.isCorrect) return false;

    return null;
  };

  const getBackgroundColor = (option: Option) => {
    if (selected?.includes(option.id)) {
      if (isCorrect(option) === "skipped") {
        return theme.palette.warning.main;
      }
      return isCorrect(option)
        ? theme.palette.success.main
        : theme.palette.error.main;
    }
    return theme.palette.background.paper;
  };

  const convertToHtml = (value: string) => {
    try {
      const parsedValue = JSON.parse(value);
      if (parsedValue.blocks && parsedValue.entityMap) {
        return draftToHtml(parsedValue);
      }
    } catch (e) {
      console.error("Error parsing draft HTML block:", e);
    }
    return value;
  };

  if (!options) return null;

  return (
    <Grid container spacing={1}>
      {options.map((option, index) => (
        <Grid item xs={12} key={index}>
          <Paper
            className="option-container"
            style={{
              backgroundColor: getBackgroundColor(option),
            }}
          >
            <Grid container alignItems="center">
              <Grid item xs={3} md={3} lg={2} className="is-correct-container">
                <IsCorrect readonly={true} option={option} />
              </Grid>
              <Grid item xs={9} lg={10} md={8} className="text-container">
                <Box p={1} >
                  <div
                  style={{ alignContent: "center" }}
                    dangerouslySetInnerHTML={{
                      __html: convertToHtml(option.value),
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}
