// import "react-data-grid/dist/react-data-grid.css";
import { Helmet } from "react-helmet";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
// import GA from "./helper/google.analytics";
import AppRouter from "./routes/app-router";
// import ImageViewLarge from "./shared/files/view-large/image-view-large";
import "./theme/sbadmin.scss";
import "./theme/theme.scss";
import ErrorBanner from "component/banners/error";
import { AskLoginModal } from "shared/modals/askLoginModal";
import { Footer } from "shared/components/footer/footer";
import { AuthProvider } from "context/authProvider";
import ProPlanWrapper from "component/pro-plan/proPlanWrapper";
import { useNetworkStatus } from "hooks/useNetworkStatus";

function App() {
  const apiUrl = import.meta.env.VITE_REACT_APP_API_URL || "";

  const { isOnline } = useNetworkStatus(apiUrl);

  return (
    <div>
      {isOnline && (
        <BrowserRouter>
          <AuthProvider>
            <Helmet>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
              />
              <meta name="theme-color" content="#000000" />
              <title>ExamsCreator</title>
              <link rel="canonical" href="http://examsonline.asia" />
              <meta
                name="description"
                content="create and conduct online exams and share with students.
      Get detailed results and test analytics.Sign up for free and manage your exams online today"
              />
            </Helmet>
            {/* {GA.init() && <GA.RouteTracker />} */}
            <ProPlanWrapper isProUser={undefined}>
              <AppRouter></AppRouter>
            </ProPlanWrapper>
            <AskLoginModal />
            <Footer />
          </AuthProvider>
        </BrowserRouter>
      )}
      {!isOnline && <ErrorBanner message="We are experiencing Some " />}
    </div>
  );
}

export default App;
