import { useReactiveVar } from "@apollo/client";
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Image,
  Nav,
  Tab,
} from "react-bootstrap";
import { useToast } from "shared/hooks/useToast";
import { appState } from "../../cache/appState";
import { useState } from "react";
import axios from "axios";
import "./profile.scss";
import { useAppState } from "shared/hooks/useUpdateAppstate";
import { Grid, Paper, TextField, Typography } from "@material-ui/core";
import { useGetUserInfo } from "shared/hooks/user/useGetUserInfo";
import { Examsonline_Roles } from "generated/graphql";
import RoleCard from "./role-card";
import ImagesPanel from "shared/files/images-panel";
import FileSizeUsed from "../files/file-size-used";
import BillingTable from "component/billing/billingTable";

export function Profile() {
  const appstate = useReactiveVar(appState);

  let showRandomPassword = (Math.random() + 1).toString(36).substring(5);

  const [password, setPassword] = useState(showRandomPassword);
  const [confirmPassword, setConfirmPassword] = useState(showRandomPassword);
  const { dangerToast, successToast } = useToast();
  const { state } = useAppState();

  const handlePassword = (e: any) => {
    setPassword(e.target.value);
  };

  const handleConfirmPassword = (e: any) => {
    setConfirmPassword(e.target.value);
  };

  const updatePassword = (e: any) => {
    axios
      .put(`/user/`, { email: appstate?.userInfo?.email, password: password })
      .then((res) => {
        successToast("Password successfully changed");
      })
      .catch(() => {
        dangerToast("Error while changing password");
      });
  };

  //if (loading) return <Loading />;
  const { userInfo } = useGetUserInfo();

  return (
    <Container fluid id="profile" className="my-3">
      <Tab.Container id="tab-container-users" defaultActiveKey="first">
        <Row>
          <Col sm={3}>
            <Card className="w-100 mb-4">
              <Image src={"/images/avatar.png"} />
              <Card.Body>
                <Card.Title className="text-center">
                  {state.userInfo?.username}
                </Card.Title>
              </Card.Body>
            </Card>{" "}
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="first">Personal Info</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">Change Password</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="third">Payment</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="fourth">History</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="files">Files</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <Grid container spacing={1}>
                  <Grid item xs={6} className="text-center m-auto ">
                    <FileSizeUsed />
                  </Grid>

                  <Grid item xs={12} className="text-center m-1 ">
                    <Typography variant="h4" className="font-korona">
                      Roles
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {userInfo?.roles?.map((o: Examsonline_Roles) => {
                      return <RoleCard role={o as Examsonline_Roles} />;
                    })}
                  </Grid>
                </Grid>
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <Grid container spacing={3}>
                  <Grid item xs={12} className="text-center ">
                    <Paper className="text-center p-4">
                      <h5>Last password changed on 13th Dec</h5>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} className="mt-3">
                    <h6>Change Password</h6>
                    <hr></hr>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <TextField
                      fullWidth
                      label="New Password"
                      variant="outlined"
                      color="primary"
                      value={password}
                      onChange={handlePassword}
                      type="password"
                      className="bg-white"
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <TextField
                      fullWidth
                      label="Confirm Password"
                      variant="outlined"
                      value={confirmPassword}
                      color="primary"
                      onChange={handleConfirmPassword}
                      type="password"
                      className="bg-white"
                    />
                  </Grid>
                  <Grid item xs={12} lg={4} className="m-auto">
                    <Button
                      variant="primary"
                      onClick={updatePassword}
                      disabled={password !== confirmPassword}
                    >
                      Save Changes
                    </Button>
                  </Grid>
                </Grid>
              </Tab.Pane>
              <Tab.Pane eventKey="third">
                <Grid container>
                  <BillingTable />
                </Grid>
              </Tab.Pane>
              <Tab.Pane eventKey="files">
                <Grid container spacing={3} className="=" p-2 m-2>
                  <ImagesPanel />
                </Grid>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Container>
  );
}
