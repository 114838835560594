import jwtDecode from "jwt-decode";
import { useState } from "react";

export const useToken = () => {
  const getToken = () => {
    const tokenString = localStorage.getItem("token");
    if (tokenString === null) {
      return "";
    }
    return tokenString;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken: any) => {
    sessionStorage.setItem("sessionToken", JSON.stringify(userToken));
    setToken(userToken.token);
  };

  const getsessionToken = () => {
    const tokenString = sessionStorage.getItem("sessionToken");
    return tokenString;
  };

  const decodedsessionToken = () => {
    const tokenString = sessionStorage.getItem("sessionToken");
    if (tokenString === null) {
      return {};
    }
    const userToken:{ attempt_id: string} = jwtDecode(tokenString);
    return { ...userToken };
  };

  return {
    setToken,
    getToken,
    token,
    saveToken,
    getsessionToken,
    decodedsessionToken,
  };
};
