import React from "react";
import { Formik, Form } from "formik";
import { Grid, TextField, Button, Link } from "@material-ui/core";
import SignUp from "../auth/login/signUp";
import { useAuth } from "context/authProvider";
import { useToast } from "shared/hooks/useToast";
import * as yup from "yup";
import Axios from "axios";

const LoginForm: React.FC = () => {
  const { login } = useAuth();

  const { successToast } = useToast();

  const schema = yup.object({
    username: yup
      .string()
      .required("No email provided.")
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid Email"),
    password: yup
      .string()
      .required("No password provided.")
      .min(4, "Password is too short - should be 8 chars minimum."),
  });

  const handleSubmit = (form: any) => {
    Axios.post(`${import.meta.env.VITE_REACT_APP_API_URL}/auth/login`, form)
      .then((response) => {
        if (response.status === 201) {
          const token = response.data["access_token"];

          window.localStorage.setItem("token", token);
          successToast("Welcome");
          login(token);
        } else {
          console.log("err");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={{ username: "", password: "" }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={3} className="p-2">
            <Grid xs={12} item>
              <TextField
                fullWidth
                data-testid="email"
                name="username"
                value={values.username}
                onChange={handleChange}
                onBlur={handleBlur}
                id="username"
                label="Email"
                variant="outlined"
                error={!!errors.username}
                helperText={errors.username}
                size="small"
              />
            </Grid>

            <Grid xs={12} item>
              <TextField
                fullWidth
                data-testid="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                id="password"
                label="Password"
                variant="outlined"
                error={!!errors.password}
                helperText={errors.password}
                size="small"
                type="password"
              />
            </Grid>
            <Grid item xs={12} container justifyContent="space-around">
              <Button type="submit" variant="contained" color="primary">
                Login
              </Button>
              <SignUp />
            </Grid>
            <Grid item xs={12} className="text-center m-auto pt-1 ">
              <Link component="a" href="/reset">
                Forgot Password?
              </Link>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
