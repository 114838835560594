import { ACCESS } from "../component/enums/access.enum";
import GroupTest from "./gorup-test.model";
import { Question } from "./question.model";

export interface Quiz {
  name: string;
  pincode: string;
  emailnotify: boolean;
  author: string;
  enabled: boolean;
  time: number;
  description: string;
  topics: any[];
  questions: Question<any>[];
  accesstype: ACCESS;
  id?: string;
  isDraft?: boolean;
  errorMessage?: any;
  createdAt?: Date;
  updatedAt?: Date;
  schedules?: [GroupTest];
  randomise: boolean;
  showResult?: boolean;
  maxAttempts?: number;
  document?: any;
  //   test_sections:any[]
}

export const quiztInitialState: Quiz = {
  name: "",
  time: 0,
  pincode: "",
  enabled: true,
  emailnotify: true,
  author: "",
  topics: [],
  accesstype: ACCESS.PUBLIC,
  isDraft: true,
  errorMessage: null,
  questions: [],
  description: "",
  randomise: true,
};
