import axios from "axios";
import { useReportTimesSpent } from "component/test/hooks/useReportTimesSpent";
import { Examsonline_Questions, Examsonline_Tests } from "generated/graphql";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useModalContext } from "./modalProvider";
import { Answer } from "models/answer.model";

interface TestContextType {
  activeQuestion: Examsonline_Questions | null;
  setActiveQuestion: (question: Examsonline_Questions | null) => void;
  activeSection: string | null;
  setActiveSection: React.Dispatch<React.SetStateAction<string | null>>;
  answers: Answer[];
  addAnswer: (
    questionId: number,
    selectedOptions: number[],
    textAnswer: string
  ) => void;
  selectQuestionById: (q_id: number) => void;
  handleTabSelect: (section: number) => void;
  test: Examsonline_Tests | undefined;
  setTest: (args: Examsonline_Tests | undefined) => void;
  token: { attempt_id: string; sessionId: string; email: string };
  updateTimeSpent: (questionId: number, timeSpent: number) => void;
}

const SessionContext = React.createContext<TestContextType | undefined>(
  undefined
);
const SessionProvider = ({ children }) => {
  const [activeQuestion, setActiveQuestion] = useState<Examsonline_Questions>();
  const [activeSection, setActiveSection] = useState<string>();
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [token] = useState<any>(
    jwtDecode(window.sessionStorage.getItem("sessionToken") as string)
  );
  const [test, setTest] = useState<Examsonline_Tests>();

  const selectQuestionById = (q_id) => {
    setActiveQuestion(q_id);
  };

  const handleTabSelect = (section) => {
    setActiveQuestion(section);
  };

  useReportTimesSpent(activeQuestion, answers);

  const addAnswer = (
    questionId: number,
    selectedOptions: number[],
    textAnswer: string
  ) => {
    const answer: Answer = {
      questionId,
      selectedOptions,
      textAnswer,
      timeSpent: 0,
    };

    setAnswers((ans) => {
      const isExist = ans.find((a) => a.questionId === questionId);
      if (isExist) {
        return ans.map((a) => {
          if (a.questionId === questionId) {
            return answer;
          }
          return a;
        });
      } else {
        return [...ans, answer];
      }
    });
  };

  useEffect(() => {
    // Initialize answers for each question
    const questions = test?.questions || [];
    const initialAnswers = questions.map((question) => ({
      questionId: question.id,
      selectedOptions: [],
      textAnswer: "",
      timeSpent: 0,
    }));
    setAnswers(initialAnswers);
  }, [test]);

  useEffect(() => {
    // Find the first question in the new section and set it as the active question
    if (activeSection) {
      const firstQuestionInSection = test?.questions.find(
        (q) => q.section === activeSection
      );
      if (firstQuestionInSection) {
        setActiveQuestion(firstQuestionInSection);
      } else {
        console.log(`No questions found in section ${activeSection}.`);
      }
    }
  }, [activeSection]); // Depend on activeSection

  const updateTimeSpent = (questionId: number, timeSpent: number) => {
    setAnswers((prevAnswers) =>
      prevAnswers.map((answer) =>
        answer.questionId === questionId ? { ...answer, timeSpent } : answer
      )
    );
  };

  const contextValue = {
    activeQuestion,
    setActiveQuestion,
    activeSection,
    setActiveSection,
    answers,
    addAnswer,
    selectQuestionById,
    handleTabSelect,
    test,
    setTest,
    token,
    updateTimeSpent,
  };

  return (
    <SessionContext.Provider value={contextValue as TestContextType}>
      {children}
    </SessionContext.Provider>
  );
};

export default SessionProvider;

export function useTestContext() {
  const context = React.useContext(SessionContext);
  const history = useHistory();
  const { closeAllModals } = useModalContext();
  if (context === undefined) {
    throw new Error("useTestContext must be used within a TestProvider");
  }

  const getActiveQuestionIndex = () => getQuestionIndex(context);
  const isQuestionAnswered = (questionId: number) =>
    checkQuestionAnswered(context, questionId);

  const submitTest = async () => {
    await submitSession(context, history);
    closeAllModals();
  };
  const next = () => navigateToNextQuestion(context);
  const back = () => navigateToBackQuestion(context);

  return {
    ...context,
    getActiveQuestionIndex,
    isQuestionAnswered,
    submitTest,
    next,
    back,
  };
}

function getQuestionIndex(context) {
  return context.test?.questions.findIndex(
    (question) => question.id === context.activeQuestion?.id
  );
}

function checkQuestionAnswered(context, questionId: number) {
  const val = context.answers.find(
    (answer) => answer.questionId === questionId
  );
  if (!val) return false;

  if (val.selectedOptions.length > 0) return true;
  if (val.textAnswer !== "") return true;

  return false;
}

async function submitSession(context, history) {
  try {
    const response = await axios.post(`sessions/${context?.test?.id}/submit`, {
      attemptId: context.token.attempt_id,
      sessionId: context.token.sessionId,
      studentId: context.token.username,
      answers: context.answers,
    });

    await axios.post(`/result/${context.token.attempt_id}`);
    if (response.status == 201) {
      history.push(`/result/${context.token.attempt_id}`);
    }
  } catch (error) {
    console.error(`Error: ${error}`);
  }
}

function navigateToNextQuestion(context) {
  const activeQuestionIndex = getQuestionIndex(context);

  const nextQuestion = context.test?.questions[activeQuestionIndex + 1];

  if (nextQuestion) {
    context.setActiveQuestion(nextQuestion);
    context.setActiveSection(nextQuestion.section);
  } else {
    alert("This was the last question");
  }
}

function navigateToBackQuestion(context) {
  const activeQuestionIndex = getQuestionIndex(context);

  const nextQuestion = context.test?.questions[activeQuestionIndex - 1];

  if (nextQuestion) {
    context.setActiveQuestion(nextQuestion);
  } else {
    alert("This was the first question");
  }
}
