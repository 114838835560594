import { gql } from "@apollo/client";
import { CommentFragment } from "./fragments/comment.fragment";
import { TestDetailFragment } from "./fragments/test/test-detail";
import { TestPreviewFragment } from "./fragments/test/test-preview";
import { QuestionFragment } from "./fragments/question.fragment";

export const ALL_TEST = gql`
  query ALL_TEST {
    examsonline_tests(order_by: { id: asc }, limit: 10) {
      author
      createdAt
      errorMessage
      topics {
        tag
      }
      name
      time
      questions {
        order
        text
      }
    }
  }
`;

export const GET_TEST_BY_ID = gql`
  query GET_TEST_BY_ID(
    $id: Int
    $order_by: [examsonline_tests_order_by!] = { id: desc }
  ) {
    examsonline_tests(where: { id: { _eq: $id } }, order_by: $order_by) {
      ...TestDetailFragment
    }
  }
  ${TestDetailFragment}
`;

export const GET_TEST_BY_ID_STUDENT = gql`
  query GET_TEST_BY_ID_STUDENT($id: Int) {
    examsonline_tests(order_by: { id: desc }, where: { id: { _eq: $id } }) {
      id
      accesstype
      author
      createdAt
      description
      document
      emailnotify
      enabled
      errorMessage
      maxAttempts
      isDraft
      name
      randomise
      showResult
      time
      questions {
        id
        order
        text
        marks
        test_id
        type
        section
        options {
          id
          value
          type
        }
      }
      topics {
        tag
      }
    }
  }
`;

export const PREVIEW_TEST_BY_ID = gql`
  query PREVIEW_TEST_BY_ID($id: Int) {
    examsonline_tests(order_by: { id: desc }, where: { id: { _eq: $id } }) {
      ...TestPreviewFragment
      topics {
        tag
      }
    }
  }
  ${TestPreviewFragment}
`;

export const GET_TEST_QUESTION_CORRECT_WRONG = gql`
  query GET_TEST_QUESTION_CORRECT_WRONG($_eq: Int = 33) {
    examsonline_tests(where: { id: { _eq: $_eq } }) {
      questions {
        id
        wrong: answers_aggregate(where: { isCorrect: { _eq: false } }) {
          aggregate {
            count
          }
        }
        correct: answers_aggregate(where: { isCorrect: { _eq: true } }) {
          aggregate {
            count
          }
        }
        skipped: answers_aggregate(where: { skipped: { _eq: true } }) {
          aggregate {
            count
          }
        }
      }
    }
  }
`;

export const ADD_TEST = gql`
  mutation add_test(
    $objects: [examsonline_tests_insert_input!] = {}
    $update_columns: [examsonline_tests_update_column!]!
  ) {
    insert_examsonline_tests(
      objects: $objects
      on_conflict: { constraint: tests_pkey, update_columns: $update_columns }
    ) {
      returning {
        id
        name
        createdAt
        test_sections {
          name
          id
        }
        topics {
          tag
          id
        }
      }
    }
  }
`;

export const UPDATE_TEST = gql`
  mutation update_test(
    $_set: examsonline_tests_set_input = {}
    $test_id: Int!
  ) {
    update_examsonline_tests(where: { id: { _eq: $test_id } }, _set: $_set) {
      returning {
        id
        enabled
        topics {
          tag
        }
      }
    }
  }
`;

export const ADD_ANSWERS = gql`
  mutation ADD_ANSWERS(
    $objects: [examsonline_answers_insert_input!] = {}
    $update_columns: [examsonline_answers_update_column!] = attempt_id
  ) {
    insert_examsonline_answers(
      objects: $objects
      on_conflict: { constraint: answers_pkey, update_columns: $update_columns }
    ) {
      returning {
        q_id
        attempt_id
      }
    }
  }
`;

export const GET_ATTEMPTS = gql`
  query get_attempts(
    $sessionId: uuid!
    $email: String
    $limit: Int
    $offset: Int
  ) {
    examsonline_attempt(
      where: {
        _and: [
          { sessionId: { _eq: $sessionId } }
          { email: { _regex: $email } }
        ]
      }
      limit: $limit
      offset: $offset
      order_by: { createdAt: desc }
    ) {
      email
      test_id
      createdAt
      id
      completed
      percentage
      correct
      wrong
      skipped
    }
    examsonline_attempt_aggregate(
      where: {
        _and: [
          { sessionId: { _eq: $sessionId } }
          { email: { _regex: $email } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_OPTIONS = gql`
  query GET_OPTIONS($q_id: Int) {
    examsonline_options(
      order_by: { id: asc }
      where: { q_id: { _eq: $q_id } }
    ) {
      id
      isCorrect
      q_id
      value
      type
    }
  }
`;
export const GET_OPTIONS_FOR_STUDENT = gql`
  query GET_OPTIONS_FOR_STUDENT($q_id: Int) {
    examsonline_options(
      order_by: { id: asc }
      where: { q_id: { _eq: $q_id } }
    ) {
      id
      q_id
      value
      type
    }
  }
`;

export const GET_ORGS = gql`
  query GET_ORGS {
    examsonline_organisation {
      name
      id
    }
  }
`;

export const GET_QUESTIONS_BY_TEST_ID = gql`
  query get_questions($test_id: Int!) {
    examsonline_questions(where: { test_id: { _eq: $test_id } }) {
      id
      section
    }
  }
`;

export const GET_QUESTION = gql`
  query GET_QUESTION($test_id: Int!) {
    examsonline_questions(where: { test_id: { _eq: $test_id } }) {
      options {
        id
      }
      id
    }
  }
`;

export const SUB_TESTS = gql`
  subscription SUB_TESTS {
    examsonline_tests {
      accesstype
      id
      author
      createdAt
      description
      emailnotify
      document
      updatedAt
      time
      showResult
      topics {
        tag
      }
      randomise
      name
      maxAttempts
      isDraft
      errorMessage
      enabled
    }
  }
`;

/** User section  */

export const ADD_USER = gql`
  mutation add_user($input: [examsonline_user_insert_input!]!) {
    insert_examsonline_user(objects: $input) {
      returning {
        email
      }
    }
  }
`;

/** topic actions */

export const DELETE_TOPICS_BT_TEST_ID = gql`
  mutation delete_topics_by_test_id($test_id: Int!) {
    delete_examsonline_topics(where: { test_id: { _eq: $test_id } }) {
      affected_rows
    }
  }
`;

export const EDIT_TEST_SUBS = gql`
  subscription EDIT_TEST_SUBS($test_id: Int!) {
    examsonline_tests(where: { id: { _eq: $test_id } }) {
      id
      questions {
        text
        id
      }
    }
  }
`;

export const ATTEMPT = gql`
  query ATTEMPT($id: uuid!) {
    examsonline_attempt(where: { id: { _eq: $id } }) {
      email
      id
      stale
      test_id
      completed
      createdAt
    }
  }
`;

export const ADD_TIMESPENT = gql`
  mutation ADD_TIMESPENT(
    $objects: [examsonline_timespent_insert_input!] = {}
    $update_columns: [examsonline_timespent_update_column!] = time
  ) {
    insert_examsonline_timespent(
      objects: $objects
      on_conflict: {
        constraint: timespent_q_id_attempt_id_id_key
        update_columns: $update_columns
      }
    ) {
      returning {
        attempt_id
        id
        q_id
      }
    }
  }
`;

export const GET_TIMESPENT = gql`
  query GET_TIMESPENT($attempt_id: uuid!, $q_id: Int!) {
    examsonline_timespent(
      where: { attempt_id: { _eq: $attempt_id }, q_id: { _eq: $q_id } }
    ) {
      time
      q_id
      attempt_id
      id
    }
  }
`;
export const GET_TIMESPENT_ATTEMPT = gql`
  query GET_TIMESPENT_ATTEMPT($attempt_id: uuid!) {
    examsonline_timespent(where: { attempt_id: { _eq: $attempt_id } }) {
      time
      q_id
      attempt_id
      id
    }
  }
`;

export const GET_RESULTS = gql`
  query GET_RESULTS(
    $test_id: Int_comparison_exp = { _eq: 33 }
    $_gte: timestamptz = ""
  ) {
    examsonline_attempt(
      where: { test_id: $test_id, createdAt: { _gte: $_gte } }
    ) {
      id
      email
      createdAt
      updatedAt
      completed
      test_id
      skipped
      correct
      wrong
      percentage
      timespents_aggregate {
        aggregate {
          avg {
            time
          }
        }
      }
    }
  }
`;

export const SEARCH_TEST_BY_TAG = gql`
  query SEARCH_TEST_BY_TAG(
    $_iregex: String = ""
    $_iregex1: String = ""
    $limit: Int = 140
    $offset: Int = 0
  ) {
    examsonline_topics(
      order_by: { test: { createdAt: desc_nulls_last } }
      where: {
        _or: [
          { tag: { _iregex: $_iregex } }
          { test: { name: { _iregex: $_iregex1 } } }
        ]
      }
      limit: $limit
      offset: $offset
    ) {
      test {
        id
        topics {
          tag
        }
        questions_aggregate {
          aggregate {
            count
          }
        }
        name
        author
        time
        enabled
        createdAt
        description
        accesstype
        errorMessage
        isDraft
        price
        points
        files {
          id
          info
        }
        orders_aggregate {
          aggregate {
            count
          }
        }
      }
    }
    total_count: examsonline_topics_aggregate {
      aggregate {
        count
      }
    }
  }
`;
export const GET_AVG_TIMESPENT_BY_QUES = gql`
  query GET_AVG_TIMESPENT_BY_QUES($_eq: Int = 33) {
    examsonline_tests(where: { id: { _eq: $_eq } }) {
      questions {
        timespents_aggregate {
          aggregate {
            avg {
              time
            }
          }
        }
      }
    }
  }
`;

export const DELETE_QUESTION = gql`
  mutation DELETE_QUESTION($_eq: Int = 10) {
    delete_examsonline_questions(where: { id: { _eq: $_eq } }) {
      returning {
        id
        order
        text
        marks
        test_id
        type
        section
        options {
          id
          value
          isCorrect
          type
        }
      }
    }
  }
`;
export const GET_TOTAL_USERS_COUNT = gql`
  query GET_TOTAL_USERS_COUNT {
    examsonline_user_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const GET_APP_TEST_COUNT = gql`
  query GET_APP_TEST_COUNT {
    examsonline_tests_aggregate {
      aggregate {
        count
      }
    }
  }
`;
export const GET_ORG_COUNT = gql`
  query GET_ORG_COUNT {
    examsonline_organisation_aggregate {
      aggregate {
        count
      }
    }
  }
`;
export const UPSERT_QUESTIONS = gql`
  mutation UPSERT_QUESTIONS(
    $objects: [examsonline_questions_insert_input!] = {}
  ) {
    insert_examsonline_questions(
      objects: $objects
      on_conflict: {
        constraint: questions_pkey
        update_columns: [text, marks, explanation]
      }
    ) {
      returning {
        options {
          id
          isCorrect
          q_id
          type
          value
        }
        createdAt
        id
        marks
        order
        test_id
        quiz_id
        text
        type
        section
        explanation
      }
    }
  }
`;
export const ADD_INITIAL_QUESTION = gql`
  mutation ADD_INITIAL_QUESTION(
    $text: String!
    $options: examsonline_options_arr_rel_insert_input = { data: [] }
    $order: Int = 1
    $section: uuid = ""
    $quiz_id: uuid = null
    $test_id: Int!
  ) {
    insert_examsonline_questions(
      objects: {
        marks: 1
        order: $order
        text: $text
        type: 0
        options: $options
        section: $section
        quiz_id: $quiz_id
        test_id: $test_id
      }
    ) {
      returning {
        id
        text
        order
        section
        options {
          id
          value
          isCorrect
          type
        }
      }
    }
  }
`;

export const UPSERT_OPTION = gql`
  mutation upsert_option(
    $objects: [examsonline_options_insert_input!]!
    $update_columns: [examsonline_options_update_column!] = id
  ) {
    insert_examsonline_options(
      objects: $objects
      on_conflict: { constraint: options_pkey, update_columns: $update_columns }
    ) {
      returning {
        id
        q_id
        isCorrect
        type
        value
      }
    }
  }
`;

export const GET_ALL_TESTS_BY_AUTHOR = gql`
  query GET_ALL_TESTS_BY_AUTHORS(
    $_iregex: String = ""
    $limit: Int = 5
    $offset: Int = 0
    $order_by: [examsonline_tests_order_by!] = { createdAt: desc }
    $_eq: String = ""
    $enabled: Boolean_comparison_exp = {}
    $createdAt: timestamp_comparison_exp = {}
  ) @cached {
    examsonline_tests(
      order_by: $order_by
      where: {
        _or: [
          { name: { _iregex: $_iregex } }
          { topics: { tag: { _iregex: $_iregex } } }
        ]
        author: { _eq: $_eq }
        enabled: $enabled
        createdAt: $createdAt
      }
      limit: $limit
      offset: $offset
    ) {
      author
      createdAt
      description
      document
      maxAttempts
      pincode
      id
      time
      enabled
      updatedAt
      isDraft
      points
      attempts_aggregate {
        aggregate {
          count
        }
      }
      questions {
        id
      }
      topics {
        tag
      }
      files {
        id
        info
      }
      name
    }
    examsonline_tests_aggregate(
      where: {
        _or: [
          { name: { _iregex: $_iregex } }
          { topics: { tag: { _iregex: $_iregex } } }
        ]
        author: { _eq: $_eq }
        createdAt: $createdAt
        enabled: $enabled
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const UPDATE_OPTION_BY_ID = gql`
  mutation UPDATE_OPTION_BY_ID(
    $id: Int!
    $_set: examsonline_options_set_input!
  ) {
    update_examsonline_options(
      where: { id: { _eq: $id } }
      _inc: {}
      _set: $_set
    ) {
      returning {
        id
        isCorrect
        value
      }
    }
  }
`;

export const GET_TEST_COUNT = gql`
  query testcount($author: String!) {
    examsonline_tests_aggregate(where: { author: { _eq: $author } }) {
      aggregate {
        count
      }
    }
  }
`;

export const ADD_SELECTED_OPTION = gql`
  mutation ADD_SELECTED_OPTION(
    $object: examsonline_selected_options_insert_input = {}
    $update_columns: [examsonline_selected_options_update_column!] = attempt_id
  ) {
    insert_examsonline_selected_options_one(
      object: $object
      on_conflict: {
        constraint: selected_options_pkey
        update_columns: $update_columns
      }
    ) {
      attempt_id
      option_id
      type
      value
    }
  }
`;

export const DELETE_SELECTED_OPTIONS = gql`
  mutation DELETE_SELECTED_OPTIONS($_eq: uuid = "", $_eq1: Int = 10) {
    delete_examsonline_selected_options(
      where: { attempt_id: { _eq: $_eq }, option_id: { _eq: $_eq1 } }
    ) {
      returning {
        attempt_id
        option_id
      }
    }
  }
`;
export const GET_USER_INFO = gql`
  query GET_USER_INFO($_eq: String = "") {
    examsonline_user(where: { email: { _eq: $_eq } }) {
      organisation {
        id
        name
      }
      email
      username
      tourCompleted
      role
      id
      roles(order_by: { organisation: { roles_aggregate: { count: desc } } }) {
        id
        role
        organisation {
          id
          name
        }
      }
    }
  }
`;
export const DELETE_OPTION = gql`
  mutation DELETE_OPTION($id: Int = 10) {
    delete_examsonline_options_by_pk(id: $id) {
      id
    }
  }
`;
export const DELETE_OPTIONS_BY_QID = gql`
  mutation DELETE_OPTIONS_BY_QID($_eq: Int = 10) {
    delete_examsonline_options(where: { _and: { q_id: { _eq: $_eq } } }) {
      returning {
        id
      }
    }
  }
`;
export const GET_RATING = gql`
  query GET_RATING($_eq1: Int = 10) {
    examsonline_rating(where: { test_id: { _eq: $_eq1 } }) {
      id
      test_id
      value
    }
  }
`;

export const ADD_RATING = gql`
  mutation ADD_RATING($objects: [examsonline_rating_insert_input!] = {}) {
    insert_examsonline_rating(
      objects: $objects
      on_conflict: { constraint: rating_pkey, update_columns: value }
    ) {
      returning {
        id
        test_id
        value
      }
    }
  }
`;

export const GET_SELECTED_OPTIONS = gql`
  query GET_SELECTED_OPTIONS($_eq: Int = 10, $_eq1: uuid = "") {
    examsonline_selected_options(
      where: { option: { q_id: { _eq: $_eq } }, attempt_id: { _eq: $_eq1 } }
    ) {
      value
      type
      attempt_id
      id: option_id
    }
  }
`;

export const GET_ANSWER_SHEET = gql`
  query GET_ANSWER_SHEET($_eq: uuid = "") {
    examsonline_answers(
      where: { attempt_id: { _eq: $_eq } }
      order_by: { question: { order: asc } }
    ) {
      id
      score
      type
      attempt {
        test_id
        email
      }
      question {
        id
        marks
        order
        type
        text
        section
        answers(where: { attempt_id: { _eq: $_eq } }) {
          score
        }
        selected_options(where: { attempt_id: { _eq: $_eq } }) {
          value
          type
        }
      }
    }
  }
`;

export const GET_ALL_SELECTED_OPTIONS = gql`
  query GET_ALL_SELECTED_OPTIONS($_eq1: uuid = "") {
    examsonline_selected_options(where: { attempt_id: { _eq: $_eq1 } }) {
      value
      type
      attempt_id
      id: option_id
      isCorrect
    }
  }
`;

export const GET_TOP_STUDENTS = gql`
  query GET_TOP_STUDENTS(
    $limit: Int = 10
    $_eq2: Int = 10
    $_perc_order_by: order_by = desc_nulls_last
    $_correct_order_by: order_by = desc
  ) {
    examsonline_attempt(
      limit: $limit
      order_by: { percentage: $_perc_order_by, correct: $_correct_order_by }
      where: { test_id: { _eq: $_eq2 } }
    ) {
      percentage
      email
      createdAt
      correct
      skipped
      wrong
      count: answers_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export const UPDATE_SCORE = gql`
  mutation UPDATE_SCORE($_eq: uuid = "", $_eq1: Int = 10, $score: Int = 10) {
    update_examsonline_answers(
      where: { attempt_id: { _eq: $_eq }, q_id: { _eq: $_eq1 } }
      _set: { score: $score }
    ) {
      returning {
        score
      }
    }
  }
`;

export const SEARCH_QUESTIONS = gql`
  query SEARCH_QUESTIONS(
    $_iregex1: String = ""
    $_iregex2: String = ""
    $limit: Int = 10
    $offset: Int = 10
  ) {
    examsonline_questions(
      where: {
        _or: { test: { topics: { tag: { _iregex: $_iregex1 } } } }
        test: { name: { _iregex: $_iregex2 } }
      }
      limit: $limit
      offset: $offset
    ) {
      id
      marks
      type
      text
      options {
        id
        value
        isCorrect
        type
      }
      test {
        name
        author
        ratings_aggregate {
          aggregate {
            avg {
              value
            }
          }
        }
      }
    }
    total_count: examsonline_questions_aggregate(
      where: { _or: { test: { topics: { tag: { _iregex: $_iregex1 } } } } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const UPDATE_PASS = gql`
  mutation UPDATE_PASS($email: String = "", $password: String = "") {
    update_examsonline_user(
      where: { email: { _eq: $email } }
      _set: { email: $email, password: $password }
    ) {
      returning {
        email
      }
    }
  }
`;
export const SEARCH_QUESTIONS_BY_TAG = gql`
  query SEARCH_QUESTIONS_BY_TAG($_ilike: String = "") {
    examsonline_questions(
      where: { test: { topics: { tag: { _ilike: $_ilike } } } }
    ) {
      text
      id
      author: test {
        author
      }
      incorrect_count: answers_aggregate(where: { isCorrect: { _eq: false } }) {
        aggregate {
          count
        }
      }
      correct_count: answers_aggregate(where: { isCorrect: { _eq: false } }) {
        aggregate {
          count
        }
      }
    }
  }
`;

export const REGISTER_USER = gql`
  mutation REGISTER_USER(
    $email: String = ""
    $password: String = ""
    $org1: uuid = ""
  ) {
    insert_examsonline_user(
      objects: { email: $email, password: $password, org: $org1 }
    ) {
      returning {
        email
      }
    }
  }
`;
export const ADD_SECTION = gql`
  mutation ADD_SECTION($name: String = "", $test_id: Int = 10) {
    insert_examsonline_test_sections(
      objects: { name: $name, test_id: $test_id }
    ) {
      returning {
        name
        id
        test_id
      }
    }
  }
`;
export const GET_COMMENTS = gql`
  query GET_COMMENTS(
    $limit: Int = 6
    $offset: Int = 0
    $_eq: Int = 0
    $ticket_id: uuid = "00000000-0000-0000-0000-000000000000"
    $asg_id: uuid = "00000000-0000-0000-0000-000000000000"
    $ref_id: String = "00000000-0000-0000-0000-000000000000"
  ) {
    examsonline_comments(
      where: {
        _or: [
          { test_id: { _eq: $_eq } }
          { ticket_id: { _eq: $ticket_id } }
          { asg_id: { _eq: $asg_id } }
          { ref_id: { _eq: $ref_id } } # Use ref_id in the where clause
        ]
      }
      limit: $limit
      offset: $offset
      order_by: { createdAt: desc }
    ) {
      ...CommentFragment
    }
    total_count: examsonline_comments_aggregate(
      where: {
        _or: [
          { test_id: { _eq: $_eq } }
          { ticket_id: { _eq: $ticket_id } }
          { asg_id: { _eq: $asg_id } }
          { ref_id: { _eq: $ref_id } } # Use ref_id in the where clause
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
  ${CommentFragment}
`;

export const ADD_COMMENT = gql`
  mutation ADD_COMMENT(
    $objects: [examsonline_comments_insert_input!] = {}
    $update_columns: [examsonline_comments_update_column!] = createdAt
  ) {
    insert_examsonline_comments(
      objects: $objects
      on_conflict: {
        constraint: comments_pkey
        update_columns: $update_columns
      }
    ) {
      returning {
        asg_id
        id
        ref_id
        test_id
        ticket_id
        updatedAt
        user
        value
      }
    }
  }
`;

export const DELETE_TEST = gql`
  mutation DELETE_TEST($_eq: Int = 10) {
    delete_examsonline_tests(where: { id: { _eq: $_eq } }) {
      returning {
        id
      }
    }
  }
`;
export const UPDATE_TOUR = gql`
  mutation UPDATE_TOUR($_eq: String = "", $tourCompleted: Boolean = false) {
    update_examsonline_user(
      where: { email: { _eq: $_eq } }
      _set: { tourCompleted: $tourCompleted }
    ) {
      returning {
        tourCompleted
        email
      }
    }
  }
`;
export const UPDATE_QUESTION_SECTION = gql`
  mutation UPDATE_QUESTION_SECTION(
    $section: uuid = ""
    $_eq: Int = 10
    $_eq1: uuid = ""
  ) {
    update_examsonline_questions(
      where: { test_id: { _eq: $_eq }, section: { _eq: $_eq1 } }
      _set: { section: $section }
    ) {
      returning {
        id
        section
      }
    }
  }
`;
export const UPDATE_DRAFT_STATUS = gql`
  mutation UPDATE_DRAFT_STATUS($_eq: Int = 10, $isDraft: Boolean = false) {
    update_examsonline_tests(
      where: { id: { _eq: $_eq } }
      _set: { isDraft: $isDraft }
    ) {
      returning {
        isDraft
        id
      }
    }
  }
`;

export const GET_BANNER_NOTIFICATION = gql`
  query GET_BANNER_NOTIFICATION(
    $limit: Int = 10
    $offset: Int = 10
    $_iregex: String = ""
  ) {
    examsonline_banner_notification(
      limit: $limit
      offset: $offset
      where: { message: { _iregex: $_iregex } }
    ) {
      id
      message
      type
      group {
        name
      }
      organisation {
        name
        id
      }
      role {
        user
      }
    }
    examsonline_banner_notification_aggregate {
      aggregate {
        count
      }
    }
  }
`;
export const ADD_QUIZ = gql`
  mutation ADD_QUIZ(
    $objects: [examsonline_quiz_insert_input!] = {}
    $update_columns: [examsonline_quiz_update_column!]!
  ) {
    insert_examsonline_quiz(
      objects: $objects
      on_conflict: { constraint: quiz_pkey, update_columns: $update_columns }
    ) {
      returning {
        id
        name
        createdAt
      }
    }
  }
`;
export const ADD_CONTACT_US = gql`
  mutation ADD_CONTACT_US(
    $contact: String = ""
    $message: String = ""
    $name: String = ""
    $reason: String = ""
  ) {
    insert_examsonline_contactus(
      objects: {
        email: $contact
        message: $message
        name: $name
        reason: $reason
      }
    ) {
      returning {
        id
      }
    }
  }
`;
export const GET_ORG_USERS = gql`
  query GET_ORG_USERS(
    $_iregex: String = ""
    $limit: Int = 5
    $offset: Int = 0
    $_eq: String = ""
  ) {
    examsonline_organisation {
      roles(
        where: { user: { _iregex: $_iregex }, role: { _eq: $_eq } }
        limit: $limit
        offset: $offset
        order_by: { createdAt: desc }
      ) {
        id
        org_id
        role
        user
        createdAt
        active
        userByUser {
          username
        }
      }
      total: roles_aggregate(
        where: { user: { _iregex: $_iregex }, role: { _eq: $_eq } }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`;
export const GET_ORG_NAME_PUBLIC = gql`
  query GET_ORG_NAME_PUBLIC($_eq: uuid = "") {
    examsonline_organisation(where: { id: { _eq: $_eq } }) {
      id
      name
    }
  }
`;
export const GET_QUIZ_BY_ID = gql`
  query GET_QUIZ_BY_ID(
    $id: uuid
    $order_by: [examsonline_quiz_order_by!] = { id: desc }
  ) {
    examsonline_quiz(where: { id: { _eq: $id } }, order_by: $order_by) {
      id
      accesstype
      author
      createdAt
      description
      document
      emailnotify
      enabled
      errorMessage
      maxAttempts
      isDraft
      name
      pincode
      randomise
      showResult
      time
      test_sections {
        id
        name
      }
      topics {
        tag
      }
    }
  }
`;

export const GET_ALL_QUIZ = gql`
  query GET_ALL_QUIZ(
    $limit: Int = 10
    $offset: Int = 0
    $_iregex: String = ""
  ) {
    examsonline_quiz(
      limit: $limit
      offset: $offset
      where: { name: { _iregex: $_iregex } }
    ) {
      author
      createdAt
      description
      document
      emailnotify
      enabled
      isDraft
      maxAttempts
      name
      pincode
      randomise
      showResult
      time
      updatedAt
      errorMessage
      id
      questions {
        id
        marks
        order
        quiz_id
        section
        text
        options {
          id
          isCorrect
          q_id
        }
      }
    }
  }
`;
export const ADD_GROUP = gql`
  mutation ADD_GROUP(
    $name: String = ""
    $description: String = ""
    $public: Boolean = true
  ) {
    insert_examsonline_groups(
      objects: { name: $name, description: $description, public: $public }
    ) {
      returning {
        id
        name
        user
        updatedAt
        description
        public
      }
    }
  }
`;

export const GET_GROUPS = gql`
  query GET_GROUPS($limit: Int = 10, $offset: Int = 0, $_iregex: String = "") {
    examsonline_groups(
      limit: $limit
      offset: $offset
      where: { name: { _iregex: $_iregex } }
      order_by: { createdAt: desc_nulls_last }
    ) {
      id
      name
      user
      createdAt
      updatedAt
      members_aggregate {
        aggregate {
          count
        }
      }
      files {
        info
      }

      assignments_aggregate {
        aggregate {
          count
        }
      }
    }
    total: examsonline_groups_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const GET_GROUP_BY_ID = gql`
  query GET_GROUP_BY_ID($_eq: uuid = "") {
    examsonline_groups(where: { id: { _eq: $_eq } }) {
      id
      name
      updatedAt
      createdAt
      user
      members_aggregate {
        aggregate {
          count
        }
      }
      files {
        info
      }
    }
  }
`;
export const DELETE_QUIZ_BY_ID = gql`
  mutation DELETE_QUIZ_BY_ID($_eq: uuid = "") {
    delete_examsonline_quiz(where: { id: { _eq: $_eq } }) {
      returning {
        id
      }
    }
  }
`;
export const CREATE_assignments = gql`
  mutation CREATE_assignments(
    $quiz_id: uuid = ""
    $test_id: Int = 10
    $name: String = ""
    $startDate: timestamptz = ""
    $endDate: timestamptz = ""
  ) {
    insert_examsonline_assignments(
      objects: {
        name: $name
        test_id: $test_id
        quiz_id: $quiz_id
        startDate: $startDate
        endDate: $endDate
      }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
export const DELETE_GROUP_BY_ID = gql`
  mutation DELETE_GROUP_BY_ID($_eq: uuid = "") {
    delete_examsonline_groups(where: { id: { _eq: $_eq } }) {
      returning {
        id
      }
    }
  }
`;
export const ADD_MEMBER_TO_GROUP = gql`
  mutation ADD_MEMBER_TO_GROUP($email: String = "", $group_id: uuid = "") {
    insert_examsonline_members(
      objects: { email: $email, group_id: $group_id }
    ) {
      returning {
        email
        group_id
        id
        updatedAt
        createdAt
      }
    }
  }
`;
export const ASSIGN_TEST_TO_GROUP = gql`
  mutation ASSIGN_TEST_TO_GROUP(
    $name: String = ""
    $test_id: Int = 10
    $startDate: timestamptz = ""
    $endDate: timestamptz = ""
    $group: uuid = ""
  ) {
    insert_examsonline_assignments(
      objects: {
        name: $name
        test_id: $test_id
        startDate: $startDate
        endDate: $endDate
        group: $group
      }
    ) {
      returning {
        id
        startDate
        endDate
        test_id
        name
        group
      }
    }
  }
`;

export const UPCOMING_ASSIGNMENTS = gql`
  query UPCOMING_ASSIGNMENTS(
    $limit: Int = 10
    $offset: Int = 0
    $_iregex: String = ""
  ) {
    examsonline_assignments(
      order_by: { startDate: desc }
      limit: $limit
      offset: $offset
      where: { name: { _iregex: $_iregex } }
    ) {
      createdAt
      endDate
      id
      name
      startDate
      test_id
      user
    }
    total: examsonline_assignments_aggregate(
      where: { name: { _iregex: $_iregex } }
    ) {
      aggregate {
        count
      }
    }
  }
`;
export const GET_ALL_MEMBERS_OF_GROUP = gql`
  query GET_ALL_MEMBERS_OF_GROUP(
    $_iregex: String = ""
    $limit: Int = 10
    $offset: Int = 0
    $_eq: uuid = ""
  ) {
    examsonline_members(
      limit: $limit
      offset: $offset
      where: { email: { _iregex: $_iregex }, group_id: { _eq: $_eq } }
    ) {
      createdAt
      email
      group_id
      id
      updatedAt
    }
    total: examsonline_members_aggregate(
      where: { email: { _iregex: $_iregex }, group_id: { _eq: $_eq } }
    ) {
      aggregate {
        count
      }
    }
  }
`;
export const DELETE_MEMBER_BY_ID = gql`
  mutation DELETE_MEMBER_BY_ID($_eq: uuid = "") {
    delete_examsonline_members(where: { id: { _eq: $_eq } }) {
      returning {
        createdAt
        email
        id
        group_id
        updatedAt
      }
      affected_rows
    }
  }
`;

export const GET_TOTAL_SUBMISSIONS_FOR_AUTHOR = gql`
  query GET_TOTAL_SUBMISSIONS_FOR_AUTHOR($_eq: Boolean = true) {
    examsonline_attempt_aggregate(where: { completed: { _eq: $_eq } }) {
      aggregate {
        count
      }
    }
  }
`;
export const GROUP_MEMBERSHIPS = gql`
  query GROUP_MEMBERSHIPS($limit: Int = 10, $offset: Int = 0) {
    examsonline_members(limit: $limit, offset: $offset) {
      createdAt
      email
      group_id
      id
      user
      group {
        name
        id
        user
      }
    }
  }
`;

export const GET_MY_ASSIGNMENTS = gql`
  query GET_MY_ASSIGNMENTS(
    $limit: Int = 10
    $offset: Int = 0
    $_iregex: String = ""
  ) {
    examsonline_assignments(
      limit: $limit
      offset: $offset
      order_by: { endDate: asc }
      where: { name: { _iregex: $_iregex } }
    ) {
      name
      createdAt
      endDate
      group
      id
      quiz_id
      startDate
      test_id
      type
      user
      comments_aggregate {
        aggregate {
          count
        }
      }
      groupByGroup {
        id
        name
      }
      attempts(where: { assignment: { name: { _iregex: $_iregex } } }) {
        completed
        createdAt
        email
        correct
        asg_id
        percentage
        id
        quiz_id
        scheduleid
        stale
        test_id
        updatedAt
        wrong
        skipped
      }
    }
    total: examsonline_assignments_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const GET_ALL_SUPPORT_TICKETS = gql`
  query GET_ALL_SUPPORT_TICKETS(
    $limit: Int = 10
    $offset: Int = 0
    $_iregex1: String = ""
  ) {
    examsonline_tickets(
      limit: $limit
      offset: $offset
      order_by: { createdAt: desc }
      where: { description: { _iregex: $_iregex1 } }
    ) {
      assigned
      createdAt
      createdBy
      id
      description
      name
      status
      updatedAt
      user {
        email
        organisation {
          id
          name
          plan
          email
          country
          activated
        }
        role
      }
      org_id
    }
    total: examsonline_tickets_aggregate(
      where: { description: { _iregex: $_iregex1 } }
    ) {
      aggregate {
        count
      }
    }
  }
`;
export const GET_ALL_ORGS_BY_COUNT = gql`
  query GET_ALL_ORGS_BY_COUNT($limit: Int = 10) {
    examsonline_organisation(
      order_by: { users_aggregate: { count: desc_nulls_last } }
      limit: $limit
    ) {
      activated
      country
      createdAt
      email
      id
      name
      phone
      plan
      updatedAt
      total_users: users_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export const SUPPORT_STATS = gql`
  query SUPPORT_STATS {
    examsonline_organisation_aggregate {
      aggregate {
        count
      }
    }

    examsonline_tickets_aggregate {
      aggregate {
        count
      }
    }

    examsonline_user_aggregate {
      aggregate {
        count
      }
    }
  }
`;
export const GROUP_DETAILS = gql`
  query GROUP_DETAILS($_eq: uuid = "") {
    examsonline_groups(where: { id: { _eq: $_eq } }) {
      id
      name
      updatedAt
      user
      createdAt
      public
      members_aggregate {
        aggregate {
          count
        }
      }
      assignments_aggregate {
        aggregate {
          count
        }
      }
      files {
        id
        info
      }
    }
  }
`;

export const GROUP_ASSIGNMENTS = gql`
  query GROUP_ASSIGNMENTS(
    $limit: Int = 10
    $offset: Int = 10
    $_eq: uuid = ""
    $_iregex: String = ""
  ) {
    examsonline_assignments(
      limit: $limit
      offset: $offset
      where: { group: { _eq: $_eq }, name: { _iregex: $_iregex } }
      order_by: { createdAt: desc }
    ) {
      id
      name
      startDate
      test_id
      createdAt
      endDate
      group
      comments_aggregate {
        aggregate {
          count
        }
      }
      attempts_aggregate {
        aggregate {
          count
        }
      }
      groupByGroup {
        members_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
`;
export const AUTHOR_GROUPS_LIST = gql`
  query AUTHOR_GROUPS_LIST {
    examsonline_groups {
      name
      id
      createdAt
    }
  }
`;
export const GET_TOTAL_ROLE_COUNT = gql`
  query GET_TOTAL_ROLE_COUNT {
    examsonline_user_aggregate(where: { role: { _eq: "STUDENT" } }) {
      aggregate {
        count
      }
    }
    total_author: examsonline_user_aggregate(
      where: { role: { _eq: "AUTHOR" } }
    ) {
      aggregate {
        count
      }
    }
  }
`;
export const GET_TOTAL_GROUPS = gql`
  query GET_TOTAL_GROUPS {
    examsonline_groups_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const GET_ALL_USERS = gql`
  query GET_ALL_USERS(
    $_iregex: String = ""
    $limit: Int = 10
    $offset: Int = 0
  ) {
    examsonline_user(
      where: { email: { _iregex: $_iregex } }
      limit: $limit
      offset: $offset
    ) {
      email
      id
      role
    }
    total: examsonline_user_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const GET_STUDENT_RESULTS_BY_EMAIL = gql`
  query GET_STUDENT_RESULTS_BY_EMAIL(
    $_gte: timestamptz = "11/01/2020"
    $limit: Int = 10
    $offset: Int = 0
    $_iregex: String = ""
  ) {
    examsonline_attempt(
      limit: $limit
      offset: $offset
      where: { createdAt: { _gte: $_gte }, email: { _iregex: $_iregex } }
      order_by: { createdAt: desc_nulls_last }
    ) {
      id
      email
      createdAt
      updatedAt
      completed
      test_id
      skipped
      correct
      wrong
      percentage
      test {
        author
        name
        id
      }
    }
    total: examsonline_attempt_aggregate(
      where: { createdAt: { _gte: $_gte }, email: { _iregex: $_iregex } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_ALL_ORG = gql`
  query GET_ALL_ORG($_iregex: String = "", $limit: Int = 10, $offset: Int = 0) {
    examsonline_organisation(
      where: { name: { _iregex: $_iregex } }
      limit: $limit
      offset: $offset
    ) {
      name
      email
      id
      plan
      phone
    }
    total: examsonline_organisation_aggregate {
      aggregate {
        count
      }
    }
  }
`;
export const GET_TICKET_DETAILS = gql`
  query GET_TICKET_DETAILS($_eq: uuid = "") {
    examsonline_tickets(where: { id: { _eq: $_eq }, description: {} }) {
      assigned
      createdAt
      createdBy
      description
      name
      id
      org_id
      status
      updatedAt
      user {
        email
        organisation {
          name
          id
        }
        activated
      }
      comments {
        ...CommentFragment
      }
    }
  }
  ${CommentFragment}
`;

export const GET_ISSUE_DETAILS = gql`
  query GET_ISSUE_DETAILS(
    $limit: Int = 10
    $offset: Int = 0
    $_iregex: String = ""
  ) {
    examsonline_tickets(
      limit: $limit
      offset: $offset
      where: { description: { _iregex: $_iregex } }
      order_by: { createdAt: desc }
    ) {
      createdAt
      description
      status
      id
      name
      updatedAt
    }
    total: examsonline_tickets_aggregate {
      aggregate {
        count
      }
    }
  }
`;
export const Get_Tickets_Description = gql`
  query Get_Tickets_Description($_iregex: String = "") {
    examsonline_tickets(
      limit: 5
      order_by: { createdAt: desc }
      where: { description: { _iregex: $_iregex } }
    ) {
      description
      name
      createdAt
      createdBy
    }
  }
`;
export const CREATE_TICKET = gql`
  mutation CREATE_TICKET(
    $description: String = ""
    $priority: String = ""
    $name: String = ""
  ) {
    insert_examsonline_tickets(
      objects: { description: $description, priority: $priority, name: $name }
    ) {
      returning {
        id
        updatedAt
        createdBy
        createdAt
        description
        name
        org_id
        status
        priority
      }
    }
  }
`;
export const CREATE_DEMO = gql`
  mutation CREATE_DEMO(
    $name: String = ""
    $message: String = ""
    $reason: String = ""
    $email: String = ""
    $phone: numeric = ""
  ) {
    insert_examsonline_contactus(
      objects: {
        name: $name
        message: $message
        reason: $reason
        email: $email
        phone: $phone
      }
    ) {
      returning {
        id
        message
        reason
      }
    }
  }
`;
export const GET_ALL_JOINED_GROUPS = gql`
  query GET_ALL_JOINED_GROUPS {
    examsonline_members {
      group: group {
        id
        name
      }
    }
  }
`;
export const CREATE_CONTEST_FORM = gql`
  mutation CREATE_CONTEST_FORM(
    $objects: [examsonline_contest_insert_input!] = {}
    $update_columns: [examsonline_contest_update_column!] = createdAt
  ) {
    insert_examsonline_contest(
      objects: $objects
      on_conflict: { constraint: contest_pkey, update_columns: $update_columns }
    ) {
      returning {
        author
        id
        name
        endDate
        duration
        description
        createdAt
        startDate
        test_id
        updatedAt
      }
    }
  }
`;
export const GET_ALL_CONTEST = gql`
  query GET_ALL_CONTEST($_eq: uuid = "") {
    examsonline_contest(where: { id: { _eq: $_eq } }) {
      author
      description
      endDate
      duration
      createdAt
      id
      name
      topics {
        tag
      }
      startDate
      test_id
      updatedAt
      user {
        email
        organisation {
          name
        }
      }
    }
  }
`;
export const ADD_PRIORITY_STATUS = gql`
  query ADD_PRIORITY_STATUS {
    _enumtable_priority(limit: 10) {
      value
    }
  }
`;
export const ADD_COUNTRY = gql`
  query ADD_COUNTRY {
    _enumtable_country {
      name
    }
  }
`;

export const TICKET_STATUS_LIST = gql`
  query TICKET_STATUS_LIST {
    _enumtable_ticket_status {
      status
    }
  }
`;

export const SUPPORT_USER_LIST = gql`
  query SUPPORT_USER_LIST {
    examsonline_roles(where: { role: { _eq: "SUPPORT" } }) {
      user
    }
  }
`;
export const GET_MY_ASSIGNMENTS_FILTER_BY_DATE = gql`
  query GET_MY_ASSIGNMENTS_FILTER_BY_DATE(
    $_gte: timestamptz = ""
    $_lte: timestamptz = ""
  ) {
    examsonline_assignments(
      where: { startDate: { _gte: $_gte, _lte: $_lte } }
    ) {
      startDate
      id
      name
      test_id
    }
  }
`;
export const GET_CONTEST = gql`
  query GET_CONTEST($limit: Int = 10, $offset: Int = 0, $_iregex: String = "") {
    examsonline_contest(
      limit: $limit
      offset: $offset
      where: { name: { _iregex: $_iregex } }
      order_by: { createdAt: desc_nulls_last }
    ) {
      id
      name
      createdAt
      author
      duration
      endDate
      startDate
      test_id
      files {
        info
      }
      topics {
        tag
      }
    }
    total: examsonline_contest_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const GET_CONTEST_BY_ID = gql`
  query GET_CONTEST_BY_ID($_eq: uuid = "") {
    examsonline_contest(
      where: { id: { _eq: $_eq } }
      order_by: { createdAt: asc_nulls_last }
    ) {
      id
      name
      createdAt
      author
      duration
      description
      endDate
      test_id
      startDate
      files {
        info
      }
      topics {
        tag
      }
    }
  }
`;

export const UPDATE_CONTEST_TEST_ID = gql`
  mutation UPDATE_CONTEST_TEST_ID($_eq: uuid = "", $test_id: Int = 10) {
    update_examsonline_contest(
      where: { id: { _eq: $_eq } }
      _set: { test_id: $test_id }
    ) {
      returning {
        id
        test_id
        updatedAt
      }
    }
  }
`;

export const JOIN_PARTICIPANTS = gql`
  mutation JOIN_PARTICIPANTS($contest_id: uuid = "", $email: String = "") {
    insert_examsonline_participate(
      objects: { contest_id: $contest_id, email: $email }
    ) {
      returning {
        email
        id
      }
    }
  }
`;

export const PARTICIPATING_IN_CONTEST = gql`
  query PARTICIPATING_IN_CONTEST($_eq: uuid = "", $_eq1: String = "") {
    examsonline_participate(
      where: { contest_id: { _eq: $_eq }, email: { _eq: $_eq1 } }
    ) {
      email
      id
    }
  }
`;
export const JOIN_CONTEST_MANY = gql`
  mutation JOIN_CONTEST_MANY(
    $objects: [examsonline_participate_insert_input!] = {}
  ) {
    insert_examsonline_participate(objects: $objects) {
      affected_rows
    }
  }
`;

export const DELETE_TOPICS_BT_CONTEST_ID = gql`
  mutation delete_topics_by_contest_id($_eq: uuid = "") {
    delete_examsonline_topics(where: { contest_id: { _eq: $_eq } }) {
      affected_rows
    }
  }
`;

export const GET_ALL_PARTICIPANTS = gql`
  query GET_ALL_PARTICIPANTS(
    $limit: Int = 10
    $offset: Int = 0
    $_iregex: String = ""
    $_eq: uuid = ""
    $_eq1: uuid = ""
  ) {
    examsonline_participate(
      limit: $limit
      offset: $offset
      where: { email: { _iregex: $_iregex }, contest_id: { _eq: $_eq } }
      order_by: { createdAt: desc_nulls_last }
    ) {
      email
      createdAt
      id
      contest_id
    }
    total: examsonline_participate_aggregate(
      where: { contest_id: { _eq: $_eq1 } }
    ) {
      aggregate {
        count
      }
    }
  }
`;
export const GET_ASSIGNMENT_BY_ID = gql`
  query GET_ASSIGNMENT_BY_ID($_eq: uuid = "") {
    examsonline_assignments(where: { id: { _eq: $_eq } }) {
      id
      name
      startDate
      endDate
      createdAt
      user
      test_id
      comments {
        ...CommentFragment
      }
    }
  }
  ${CommentFragment}
`;

export const REMOVE_TEST_FROM_CONTEST = gql`
  mutation REMOVE_TEST_FROM_CONTEST($test_id: Int = null, $_eq: uuid = "") {
    update_examsonline_contest(
      where: { id: { _eq: $_eq } }
      _set: { test_id: $test_id }
    ) {
      returning {
        id
        test_id
      }
    }
  }
`;
export const MY_FILES = gql`
  query MY_FILES($limit: Int = 4, $offset: Int = 0) {
    examsonline_file(limit: $limit, offset: $offset) {
      id
      info
      size
      test {
        id
        name
        author
      }
    }
    total: examsonline_file_aggregate {
      aggregate {
        count
      }
    }
    total_size: examsonline_file_aggregate {
      aggregate {
        sum {
          size
        }
      }
    }
  }
`;

export const SEARCH_CONTEST_BY_TAG = gql`
  query SEARCH_CONTEST_BY_TAG(
    $_iregex: String = ""
    $_iregex1: String = ""
    $limit: Int = 140
    $offset: Int = 0
  ) {
    examsonline_topics(
      order_by: { contest: { createdAt: desc_nulls_last } }
      where: {
        _or: [
          { tag: { _iregex: $_iregex } }
          { contest: { name: { _iregex: $_iregex1 } } }
        ]
      }
      limit: $limit
      offset: $offset
    ) {
      contest {
        id
        topics {
          tag
        }
        name
        author
        createdAt
        duration
        description
        files {
          id
          info
        }
      }
    }
    total_count: examsonline_contest_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const UPDATE_TICKET_DETAILS = gql`
  mutation UPDATE_TICKET_DETAILS(
    $_set: examsonline_tickets_set_input = {}
    $_eq: uuid = ""
  ) {
    update_examsonline_tickets(where: { id: { _eq: $_eq } }, _set: $_set) {
      returning {
        id
        status
        assigned
      }
    }
  }
`;

export const GET_TEST_PINCODE = gql`
  query GET_TEST_PINCODE($_eq: Int = 10) {
    examsonline_tests(where: { id: { _eq: $_eq } }) {
      pincode
    }
  }
`;

export const TOTAL_FILE_SIZE_USED = gql`
  query TOTAL_FILE_SIZE_USED {
    examsonline_file_aggregate {
      aggregate {
        sum {
          size
        }
      }
    }
  }
`;
export const GET_ALL_PARTICIPANTS_RESULT = gql`
  query GET_ALL_PARTICIPANTS_RESULT(
    $limit: Int = 10
    $offset: Int = 0
    $_iregex: String = ""
    $_eq: uuid = ""
    $_eq1: uuid = ""
  ) {
    examsonline_attempt(
      limit: $limit
      offset: $offset
      where: { email: { _iregex: $_iregex }, contest_id: { _eq: $_eq } }
      order_by: { percentage: desc_nulls_first }
    ) {
      id
      email
      createdAt
      updatedAt
      correct
      wrong
      percentage
      skipped
      completed
    }
    total: examsonline_attempt_aggregate(
      where: { contest_id: { _eq: $_eq1 } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_ALL_TEST_PARTICIPANTS_RESULT = gql`
  query GET_ALL_TEST_PARTICIPANTS_RESULT(
    $limit: Int = 10
    $offset: Int = 0
    $_iregex: String = ""
    $_eq: Int = 10
    $asg_id: uuid_comparison_exp = {}
  ) {
    examsonline_attempt(
      limit: $limit
      offset: $offset
      where: {
        _and: { email: { _iregex: $_iregex } }
        _or: { asg_id: $asg_id, test_id: { _eq: $_eq } }
      }
      order_by: { percentage: desc_nulls_first }
    ) {
      id
      email
      createdAt
      updatedAt
      correct
      wrong
      percentage
      skipped
      completed
    }
    total: examsonline_attempt_aggregate(
      where: {
        _and: { email: { _iregex: $_iregex } }
        _or: { asg_id: $asg_id, test_id: { _eq: $_eq } }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const DEACTIVATE_ROLE = gql`
  mutation DEACTIVATE_ROLE(
    $_eq: uuid = ""
    $active: Boolean!
    $_iregex: String = ""
  ) {
    update_examsonline_roles(
      where: { id: { _eq: $_eq }, role: { _iregex: $_iregex } }
      _set: { active: $active }
    ) {
      returning {
        id
        active
        role
      }
    }
  }
`;
export const ORDER_ITEM = gql`
  mutation ORDER_ITEM($objects: [examsonline_order_insert_input!] = {}) {
    insert_examsonline_order(objects: $objects) {
      returning {
        id
        plan
        email
        createdAt
        role_id
        status
        test_id
        updatedAt
      }
    }
  }
`;

export const MY_ORDERS = gql`
  query MY_ORDERS($limit: Int = 10, $offset: Int = 0) {
    examsonline_order(
      limit: $limit
      offset: $offset
      order_by: { createdAt: desc_nulls_last }
    ) {
      amount
      createdAt
      email
      id
      plan
      role_id
      status
      test {
        id
        name
        files {
          id
          info
          owner
        }
        pincode
        enabled
        description
      }
    }
    total_count: examsonline_order_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const GET_QUESTION_BY_ID = gql`
  query GET_QUESTION_BY_ID($_eq: Int!) {
    examsonline_questions(where: { id: { _eq: $_eq } }) {
      ...QuestionFragment
    }
  }
    ${QuestionFragment}
`;


export const UPDATE_QUESTION_BY_ID = gql`
  mutation UPDATE_QUESTION_BY_ID(
    $_eq: Int = 10
    $_set: examsonline_questions_set_input = {}
  ) {
    update_examsonline_questions(where: { id: { _eq: $_eq } }, _set: $_set) {
      returning {
        ...QuestionFragment
      }
    }
  }
    ${QuestionFragment}
`;


export const UPDATE_OPTIONS_BY_QUESTION_ID = gql`
  mutation UPDATE_OPTIONS_BY_QUESTION_ID(
    $_set: examsonline_options_set_input = {}
    $_eq: Int!
  ) {
    update_examsonline_options(where: { q_id: { _eq: $_eq } }, _set: $_set) {
      returning {
        id
        isCorrect
        q_id
        type
        value
      }
    }
  }
`;
