import Axios from "axios";
import { Helper } from "helper/helper";
import { Roles } from "models/roles";
import { FC, HTMLProps, useCallback } from "react";
import Dropzone from "react-dropzone";
import { FaCamera } from "react-icons/fa";
import "./images.scss";
import { useGradient } from "hooks/useGradient";

interface Props {
  onUpload: Function;
  test_id?: number;
  contest_id?: string;
  group_id?: string;
  placeHoler?: string;
}

export const AddCover: FC<Props & HTMLProps<any>> = ({
  onUpload,
  test_id,
  contest_id,
  group_id,
  placeHoler,
  hidden,
  ...props
}) => {
  const { getGradient } = useGradient();
  const role = Helper.getUserRole();

  const onDrop = useCallback((files: any) => {
    let formData = new FormData();
    files.forEach((file: any) => {
      formData.append(file.name, file);

      // send test_id
      test_id && formData.append("test_id", test_id.toString());

      // send contest_id
      contest_id && formData.append("contest_id", contest_id);

      // send groupid
      group_id && formData.append("group_id", group_id);
    });

    Axios.post("/file/image/editor", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        console.log(" image uploaded", res.data);
        onUpload(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [onUpload, test_id, contest_id, group_id]);

  return (
    <div className="w-100" hidden={hidden || role !== Roles.AUTHOR}>
      <Dropzone onDrop={onDrop}>
        {({ getRootProps, getInputProps }) => (
          <section
            className="drop-zone text-center text-primary w-100"
            style={{ background: getGradient(test_id) }}
          >
            <div {...getRootProps()} style={{ position: "relative" }}>
              <input {...getInputProps()} />
              <p className="px-2 px-lg-2">
                {placeHoler || `Drag 'n' drop or select to add cover image`}
              </p>
              <FaCamera
                style={{
                  position: "absolute",
                  // right: "10px",
                  // bottom: "10px",
                }}
              />
            </div>
          </section>
        )}
      </Dropzone>
    </div>
  );
};