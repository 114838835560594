import { EditorState, convertToRaw } from "draft-js";
import { cloneDeep } from "lodash";
import { Helper } from "../../../helper/helper";
import { GET_QUIZ_BY_ID, GET_TEST_BY_ID } from "../../../queries/queries";
import { Examsonline_Questions, useAdd_Initial_QuestionMutation } from "generated/graphql";

export function useAddQuestion() {
  const [addQues, { loading, error }] = useAdd_Initial_QuestionMutation()

  const addQUestionFromEditorState = (
    question: Examsonline_Questions & any,
    test_id : number| null = null,
    options: any[] = [],
    order:number,
    section: string,
    quiz_id: string | null = null,
) => {
    const textContent = question.text instanceof EditorState 
        ? JSON.stringify(convertToRaw(question.text.getCurrentContent())) 
        : question.text;

    return addQues({
      variables: {
        test_id: test_id || 0,
        text: textContent,
        options: {
          data: Helper.contentOptionsToString(options),
        },
        order:order,
        section: section,
        explanation: JSON.stringify(convertToRaw(EditorState.createEmpty().getCurrentContent())),
      },
      refetchQueries: [
        {
          query: GET_TEST_BY_ID,
          variables: {
            id: test_id,
          },
        },
      ],
    });
};

  const addQUestion = (
    question: Examsonline_Questions,
    test_id: number,
    options: any[] = [],
    order:number,
    section: string

  ) => {
    return addQues({
      variables: {
        test_id: test_id,
        text: question.text,
        options: {
          data: cloneDeep(options).map((a)=> {
            delete a.id
            return a
          }),
        },
        order:order,
        section: section
      },
      refetchQueries: [
        {
          query: GET_TEST_BY_ID,
          variables: {
            id: test_id,
          },
          
        },
      ],
    });
  };


  const addQuizQuestion = (
    question: Examsonline_Questions & any,
    test_id : number| null = null,
    options: any[] = [],
    order:number,
    section: string,
    quiz_id: string | null = null,
  ) => {
    return addQues({
      variables: {
        test_id: test_id || 0,
        text: JSON.stringify(convertToRaw(question.text.getCurrentContent())),
        options: {
          data: Helper.contentOptionsToString(options),
        },
        order:order,
        section: section,
        quiz_id: quiz_id,
      },
      refetchQueries: [
        {
          query: GET_QUIZ_BY_ID,
          variables: {
            id: quiz_id,
          },
        },
      ],
    });
  };

  return { addQUestionFromEditorState, addQUestion, addQuizQuestion, error, loading };
}
