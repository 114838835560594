import { Row, Col, Image } from "react-bootstrap";
import "./banner.scss";

interface Props {
  message?: string;
}

export default function ErrorBanner(props: Props) {

  return (
    <div className="banner-error p-lg-5 p-xs-1 m-lg-5 m-xs-1">
      <Row className=" mx-auto text-center">
        <Col className=" m-auto" xs={12}>
          <Image
            fluid
            src={"/error.svg"}
            alt="Error"
            className="w-25"
          />
        </Col>
        <Col xs={12} className="text-center wrap-text font-weight-bold mt-5">
          {!props?.message ? "Something went wrong, we cannot connect to our services" : props.message}
        </Col>
      </Row>
    </div>
  );
}
