import { useAuth } from "context/authProvider";
import { Route, useHistory, useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export const ProtectedRoute = (props) => {
  const currentRole = localStorage.getItem("role");
  const history = useHistory();
  const location = useLocation();

  const { isLoggedIn } = useAuth();

  const handleClose = () => {
    history.push("/");
  };

  const isValid = props.roles.includes(currentRole) && isLoggedIn && location.pathname === props.path;
  const downprops: any = props;

  if (!isValid && location.pathname === props.path) {
    console.log("isnot Valid", currentRole);
  }

  return (
    <>
      <Modal show={!isValid && location.pathname === props.path} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Unauthorized Access</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You are not authorized to access this page. Please login with the
          correct role.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Go to Home Page
          </Button>
        </Modal.Footer>
      </Modal>
      <Route {...downprops} />
    </>
  );
};