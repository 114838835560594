import {
  QuestionInitialState,
  optionInitialState,
} from "component/create-test/_store/create-test.reducer";
import { EditorState, convertToRaw } from "draft-js";
import {
  Examsonline_Questions,
  useUpsert_QuestionsMutation,
} from "generated/graphql";
import { Helper } from "helper/helper";
import { useHistory, useParams } from "react-router-dom";
import { useAddQuestion } from "shared/hooks/questions/useAddQuestion";
import useDeleteQuestion from "shared/hooks/questions/useDeleteQuestion";
import { useToast } from "shared/hooks/useToast";
import Axios from "axios";
import { cloneDeep } from "lodash";
import { useAppContext } from "context/appProvider";

export interface QuestionHandlers {
  handleScore: (e: React.ChangeEvent<HTMLInputElement>) => void;
  goto: (i: number) => void;
  onCreate: () => void;
  AddSolution: () => void;
  save: () => void;
  uploadImageCallBack: (e: File) => Promise<any>;
  handleTextChange: (editorState: EditorState) => void;
  handleExplanationChange: (editorState: EditorState) => void;
  getQuestionsBySection: () => Examsonline_Questions[];
  handleTypeChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBack: () => void;
  onCopy: () => void;
  handleDelete: (id?: number) => void;
  addQuestion: (sectionId: string) => void;
  handleTabSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
  tabSelected: (event: React.ChangeEvent<HTMLInputElement>) => void;
  getTotalMarks: () => number;
}

const useQuestionHandlers = (
  question: Examsonline_Questions,
  setQuestion: React.Dispatch<React.SetStateAction<Examsonline_Questions>>,
  index: number,
  setIndex: React.Dispatch<React.SetStateAction<number>>,
  activeSection: string,
  setActiveSection: React.Dispatch<React.SetStateAction<string>>,
  testEditorstate: any,
  refetch: () => void,
  setActiveTab: React.Dispatch<React.SetStateAction<string>>,
  questions: Examsonline_Questions[]
): QuestionHandlers => {
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const { addQUestionFromEditorState } = useAddQuestion();
  const [upsert_questions] = useUpsert_QuestionsMutation();
  const { deleteQuestion } = useDeleteQuestion(parseInt(params?.id));
  const { warningToast } = useToast();
  const context = useAppContext();

  const handleScore = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuestion({ ...question, marks: e.target.value });
  };

  const goto = (i: number) => {
    setIndex(i);
    const list = getQuestionsBySection();
    const question = list.filter((a) => a.id === i)[0];
    setQuestion(question);
  };

  const onCreate = () => {
    refetch();
    let flag = true;

    testEditorstate.questions.forEach((q) => {
      const validated = Helper.validaeQuestion(q);
      if (!validated.isValid && flag) {
        alert(
          `Please select one correct answer for Question number , ${q?.order}`
        );
        setIndex(q.id);
        setActiveSection(q.section);
        flag = false;
      }
    });

    if (flag) {
      history.push(`/preview/${testEditorstate.id}`);
    }
  };

  const AddSolution = () => {
    setQuestion({
      ...question,
      explanation: JSON.stringify(
        convertToRaw(EditorState.createEmpty().getCurrentContent())
      ),
    });
  };

  const saveText = () => {
    const editQuestion = cloneDeep(question);
    delete editQuestion.options;

    editQuestion.order = index + 1;

    upsert_questions({
      variables: {
        objects: [
          {
            ...editQuestion,
          },
        ],
      },
    }).then(() => {
      console.log("question added");
    });
  };


  const uploadImageCallBack = (e: File) => {
    const formData = new FormData();
    formData.append(e.name, e);

    return Axios.post("/file/image/editor", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };

  const handleTextChange = (editorState: EditorState) => {
    console.log("editorState", JSON.stringify(convertToRaw(editorState.getCurrentContent())));
    setQuestion({
      ...question,
      text: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
    });
  };

  const handleExplanationChange = (editorState: EditorState) => {
    setQuestion({
      ...question,
      explanation: JSON.stringify(
        convertToRaw(editorState.getCurrentContent())
      ),
    });
  };

  // change question type
  const handleTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuestion({ ...question, type: e.target.value });
  };

  const getQuestionsBySection = () => {
    return Helper.filterQuesBySection(testEditorstate.questions, activeSection);
  };

  const onBack = () => {
    history.push(`/addtest/${params?.id}`);
  };

  const onCopy = () => {
    const options = testEditorstate?.questions
      .filter((a) => a.id === question.id)[0]
      .options.map((o) => {
        const temp = { ...o };
        delete temp.id;
        return temp;
      });

    addQUestionFromEditorState(
      testEditorstate?.questions.filter((a) => a.id === question.id)[0],
      parseInt(params?.id),
      options,
      questions.length,
      activeSection
    );
  };

  const handleDelete = (id?: number) => {
    deleteQuestion(id || question.id);
    // delete from dynamodb
    context.questionAPI.deleteQuestion(question.id.toString());
  };

  const addQuestion = (sectionId?: string) => {
    addQUestionFromEditorState(
      QuestionInitialState as any,
      parseInt(params?.id),
      [optionInitialState, optionInitialState],
      testEditorstate.questions.length,
      sectionId || activeSection
    )
      .then((res) => {
        setIndex(res.data.insert_examsonline_questions.returning[0].id);
        setQuestion(res.data.insert_examsonline_questions.returning[0]);

        const question = res.data.insert_examsonline_questions.returning[0];
        context.questionAPI.createQuestion({
          ...question,
          id: question.id.toString(),
          topics: testEditorstate.topics.map((e) => e.tag).join(","),
        });
      })
      .catch(() => {
        warningToast(
          "something went wrong, If your students already use this question, it cannot be deleted"
        );
      });
  };

  const handleTabSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    setActiveTab(e.target.value);
  };

  const tabSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuestion({ ...question, activeTab: event.target.value });
  };

  const getTotalMarks = () => {
    return testEditorstate.questions.reduce((a, b) => a + b.marks, 0);
  };

  return {
    handleScore,
    goto,
    onCreate,
    AddSolution,
    save: saveText,
    uploadImageCallBack,
    handleTextChange,
    handleExplanationChange,
    getQuestionsBySection,
    handleTypeChange,
    onBack,
    onCopy,
    handleDelete,
    addQuestion,
    handleTabSelect,
    tabSelected,
    getTotalMarks,
  };
};

export { useQuestionHandlers };
