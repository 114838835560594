import { useEffect, useState } from "react";
import introJs from "intro.js";
import {
  useGet_User_InfoQuery,
  useUpdate_TourMutation,
} from "generated/graphql";
import { useLocation } from "react-router-dom";
import { GET_USER_INFO } from "queries/queries";
import { Helper } from "helper/helper";

export interface OnBoardingSteps {
  element: string;
  intro: string;
  position: string;
}
export function useTour(delay = 1000) {
  const [introJS] = useState(introJs());
  const [started, setstarted] = useState(false);
  const shouldstart = sessionStorage.getItem("tour")

  useEffect(() => {
    if (!started && shouldstart) {
      setTimeout(() => {
        introJS.start();
        setstarted(true);
      }, 1500);
    }
  }, [shouldstart]);

  const { data } = useGet_User_InfoQuery({
    fetchPolicy: "cache-first",
    variables:{
      _eq: Helper.decodeToken().username,
    }
  });

  const [finishtour] = useUpdate_TourMutation({
    variables: {
      _eq: data?.examsonline_user[0].email,
      tourCompleted: true,
    },
    refetchQueries: [{ query: GET_USER_INFO }],
  });

  const location = useLocation();

  const onBoardingSetup = (
    steps: OnBoardingSteps[],
    options: {
      nextLabel?: string;
      doneLabel?: string;
      onComplete?: () => void;
      onExit?: () => void;
    } = {
      nextLabel: "Next",
      doneLabel: "Done",
      onComplete: undefined,
      onExit: undefined,
    }
  ) => {
    introJS.setOptions({
      doneLabel: options.doneLabel || "Done",
      exitOnOverlayClick: false,
      highlightClass: "highlightClass",
      keyboardNavigation: true,
      nextLabel: options.nextLabel || "Next",
      overlayOpacity: 0.5,
      prevLabel: "Back",
      showBullets: false,
      showButtons: true,
      showProgress: false,
      showStepNumbers: false,
      skipLabel: "Skip Tour",
      steps,
      tooltipClass: "tooltipClass",
    });

    introJS.oncomplete(() => {
      if (options && options.onComplete) {
        if (location.pathname.includes("/edit")) {
          finishtour();
        }
        options.onComplete();
      }
    });
    introJS.onexit(() => {
      finishtour();
      sessionStorage.removeItem('tour')
      if (options && options.onExit) {
       
        options.onExit();
      }
    });

    return introJS;
  };

  return { showTour: data?.examsonline_user[0].tourCompleted, onBoardingSetup };
}
