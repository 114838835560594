import { Examsonline_Contest, useGet_ContestQuery } from "generated/graphql";
import Loading from "shared/components/loader/loading";
import Button from "@material-ui/core/Button";
import { Grid, LinearProgress, TextField } from "@material-ui/core";
import ErrorBanner from "component/banners/error";
import { Add } from "@material-ui/icons";
import { useState } from "react";
import usePagintaion from "shared/hooks/pagination/usePagintaion";
import ContestCard, {
  ContestCardColumns,
} from "../card/contest-card";
import { useHistory } from "react-router-dom";
import Formatter from "shared/translations/formatter";
import { useTranslation } from "react-i18next";

interface Props {
  contest?: string;
  hideColumns?: ContestCardColumns[];
  enableSelect?: boolean;
  onSelect?: (test: Examsonline_Contest) => void;
  history?: any;
  canCreate?: boolean;
}

export default function ContestGrid(props: Props) {
  const [limit] = useState(5);
  const [total, settotal] = useState<number | undefined>(0);
  const { t } = useTranslation("common");

  const [search, setsearch] = useState("");
  const handleOnChange = (e: any) => {
    setsearch(e.target.value);
    reset();
  };

  const { pages, activePage, reset } = usePagintaion(total || 0, limit);

  const { data, loading, error } = useGet_ContestQuery({
    variables: {
      limit: limit,
      offset: limit * (activePage - 1),
      _iregex: search,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      settotal(data?.total.aggregate?.count);
    },
  });

  const history = useHistory();

  const addContest = () => {
    history.push("/contest/create");
  };

  if (error) return <ErrorBanner></ErrorBanner>;

  return (
    <div>
      {loading && <Loading />}
      <Grid container className="pt-lg-1 pt-md-2 mb-2 mt-3 " spacing={1}>
        <Grid
          hidden={!props?.canCreate}
          className="text-center m-auto"
          item
          xs={6}
          lg={3}
        >
          <Button
            title={t("author.contestGrid.createContest", {})}
            onClick={addContest}
            className=" btn-create-group "
            variant="contained"
            size="small"
          >
            <Add />{" "}
            <Formatter options={{}} text="author.contestGrid.createContest" />
          </Button>
        </Grid>
        <Grid item xs={6} lg={4} className="m-auto">
          <TextField
            fullWidth
            variant="outlined"
            onChange={handleOnChange}
            value={search}
            type="text"
            placeholder={t("author.contestGrid.search", {})}
            size="small"
            className="m-auto bg-white"
          />
        </Grid>

        <Grid item xs={12} lg={5} className="p-1 m-auto">
          {pages()}
        </Grid>
        {loading && <LinearProgress color="primary" />}

        <Grid xs={12} item>
          {data &&
            data.examsonline_contest.map((g) => {
              return (
                <ContestCard
                  contest={g as Examsonline_Contest}
                  isSelectable={props.enableSelect}
                  hideColumns={props?.hideColumns}
                  onSelect={props.onSelect}
                />
              );
            })}
        </Grid>
      </Grid>
    </div>
  );
}
