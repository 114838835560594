import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { GET_TEST_QUESTION_CORRECT_WRONG } from '../../../queries/queries';

export function useGetTestQuestionStack(test_id: number | undefined) {
  const [questions, setquestions] = useState<any[]>([]);

  const { loading, data, error, refetch } = useQuery(
    GET_TEST_QUESTION_CORRECT_WRONG,
    {
      variables: { _eq: test_id },
      fetchPolicy: "cache-first",
      skip: !test_id,
    }
  );

  useEffect(() => {
    if (!!data) {
      setquestions(
        data.examsonline_tests[0].questions.map((q: any, index: number) => {
          const obj = {
            id: q.id,
            index:"Q."+ (index+1),
            wrong: q.wrong.aggregate.count,
            correct: q.correct.aggregate.count,
            skipped:q.skipped.aggregate.count,
          };

          return obj;
        })
      );
    }
  }, [data]);

  return { questions, loading, error, data, refetch };
}
