import { Badge, Card, Col, ProgressBar, Row } from "react-bootstrap";
import NoRecords from "../../../../component/banners/no-records";
import { StudentScore } from "../../../hooks/result/useGetTopStudents";
import "./student-score-card.scss";

export interface Props {
  data: StudentScore;
}

export function StudentScoreCard(props: Props) {

  const getProgress = (no: number) => {
    return (no / props.data.count.aggregate.count) * 100;
  };

  if (!props) return <NoRecords />;
  return (
    <Row className="student-score-card  border-left-primary  h-100 py-2 m-2">
      <Col xs={12} >
        <Card className="w-100">
          <Card.Body>
            <Row className="font-weight-bold">
              <Col xs={6} lg={3}>
                <Badge variant="dark">{props.data.email} </Badge>{" "}
              </Col>
              <Col xs={6} lg={2}>
                <Badge className="p-1 text-dark" variant="warning">
                  {props.data.percentage} %
                </Badge>{" "}
              </Col>
              <Col xs={12} lg={2}>
                Correct - {props.data.correct}
                <ProgressBar
                  variant="success"
                  now={getProgress(props.data.correct)}
                />
              </Col>
              <Col xs={12} lg={2}>
                skipped - {props.data.skipped}
                <ProgressBar
                  variant="warning"
                  now={getProgress(props.data.skipped)}
                />
              </Col>
              <Col xs={12} lg={2}>
                wrong - {props.data.wrong}
                <ProgressBar
                  variant="danger"
                  now={getProgress(props.data.wrong)}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
