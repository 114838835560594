import { Examsonline_Comments } from "generated/graphql";
import { Grid, Typography, Avatar, Chip } from "@material-ui/core";
import Moment from "react-moment";
import "./comments.scss";

interface Props {
  comment: Examsonline_Comments;
}

export default function ViewComment(props: Props) {
  const handleColor = (e: any) => {
    if (e === "support") {
      return "warning";
    } else if (e === "author") {
      return "primary";
    } else {
      return "default";
    }
  };

  return (
    <Grid container spacing={2} className="m-0 m-md-2 p-1 view-comment" alignItems="center">
      <Grid item xs={2} sm={1}>
        <Avatar>{props.comment.user[0].toUpperCase()}</Avatar>
        <Grid item >
            <Typography variant="body1">{props.comment.user}</Typography>
          </Grid>
      </Grid>
      <Grid item xs={10} sm={11}>
        <Grid container justify="flex-end" alignItems="center">

          <Grid item>
            <Typography variant="body2" color="textSecondary">
              <Moment fromNow>{props.comment.updatedAt}</Moment>
            </Typography>
          </Grid>
        </Grid>
        <Grid container justify="space-between" alignItems="center">
          <Grid item xs={12} sm={10}>
            <Typography variant="body2">{props.comment.value}</Typography>
          </Grid>
          <Grid item>
            <Chip label={props.comment.role} color={handleColor(props.comment.role)} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}