import { QuestionAPI } from "api/questionAPI";
import { SessionAPI } from "api/sessionAPI";
import { TestAPI } from "api/testAPI";
import { Examsonline_Tests } from "generated/graphql";
import { createContext, useContext, useState, ReactNode } from "react";

interface AppContextType {
  inEditTest: Examsonline_Tests | null;
  setInEditTest: (test: Examsonline_Tests | null) => void;
  activeEditSection: string | null;
  setActiveEditSection: (section: string | null) => void;
  sessionAPI: SessionAPI;
  questionAPI: QuestionAPI;
  testAPI: TestAPI;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

export function useAppContext() {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppContextProvider");
  }
  return context;
}

interface AppContextProviderProps {
  children: ReactNode;
}

export function AppContextProvider({ children }: AppContextProviderProps) {
  const [inEditTest, setInEditTest] = useState<Examsonline_Tests | null>(null);
  const [activeEditSection, setActiveEditSection] = useState<string | null>(
    null
  );

  const sessionAPI = new SessionAPI();
  const questionAPI = new QuestionAPI();
  const testAPI = new TestAPI();

  const value: AppContextType = {
    inEditTest,
    setInEditTest,
    activeEditSection,
    setActiveEditSection,
    sessionAPI,
    questionAPI,
    testAPI
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}
