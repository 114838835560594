import { useMutation } from "@apollo/client";
import { Option } from "../../../models/option.model";

import { DELETE_SELECTED_OPTIONS } from "../../../queries/queries";

export function useDeleteSelectedOption() {
  const [deleteSelectedOption] = useMutation(DELETE_SELECTED_OPTIONS);

  const deleteoption = (option: Option, attempt_id: string) => {

    return deleteSelectedOption({
      variables: {
        _eq: attempt_id,
        _eq1: option.id,
      },
    });
  };

  return { deleteoption };
}
