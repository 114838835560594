import { Helper } from "helper/helper";
import { Container, Button, Grid, Typography } from "@material-ui/core";
import { useTestContext } from "context/sessionProvider";

interface Props {}

const ViewQuestionsButtons: React.FC<Props> = ({}) => {
  const {
    test,
    isQuestionAnswered,
    activeSection,
    setActiveQuestion,
    activeQuestion,
  } = useTestContext();

  if (!test) return null;
  if (!activeSection) return null;
  if (!activeQuestion) return null;

  const filteredQuestions = Helper.filterQuesBySection(
    test.questions,
    activeSection
  );

  function onQuesClick(Question): void {
    setActiveQuestion(Question);
  }

  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={2}
        className="d-flex justify-content-center bg-white"
      >
        <Grid item xs={12}>
          <Typography variant="h6" className="text-center">
            <Typography variant="h6" className="text-center">
              {activeSection &&
                Helper.getSectionName(activeSection, test.test_sections)}
            </Typography>{" "}
          </Typography>
        </Grid>

        {filteredQuestions.map((question, index) => (
          <Grid item key={index}>
            <Button
              onClick={() => onQuesClick(question)}
              size="small"
              variant={
                isQuestionAnswered(question.id) ? "contained" : "outlined"
              }
              // add border bottom class if question is active
              className={`${
                activeQuestion?.id == question.id ? "border-bottom-success" : ""
              }`}
              color={isQuestionAnswered(question.id) ? "primary" : "default"}
            >
              {index + 1}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ViewQuestionsButtons;
