import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Chip,
} from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import SchoolIcon from "@material-ui/icons/School";
import { Tags } from "shared/components/tags/tags";
import { FaTags } from "react-icons/fa";
import SectionBar from "./sections";
import useTestAnalytics from "component/analytics/hooks/useTestAnalytics";
import { BarChart, VerifiedUser } from "@material-ui/icons";
import { Examsonline_Tests } from "generated/graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  card: {
    padding: theme.spacing(2),
    width: "90%",
  },
  title: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  listItemIcon: {
    minWidth: "30px",
  },
  listItemText: {
    marginLeft: theme.spacing(1),
  },
  sectionBarContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(1),
  },
}));

interface Props {
  test: Examsonline_Tests;
  totalMarks: number;
}

const TestInfoCard = (props: Props) => {
  const { test } = props;
  const classes = useStyles();

  const { showModal } = useTestAnalytics();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      className="m-1"
    >
      <Card variant="elevation" className={classes.card} >
        <CardContent>
          <Box className={classes?.title}>
            <Typography variant="h3" component="div">
              {test.name}
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={4} sm={6}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Box display="flex" alignItems="center">
                    <ListItemIcon className={classes.listItemIcon}>
                      <AccessTimeIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      primary="Time"
                      secondary={`${test.time} minutes`}
                      className={classes.listItemText}
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <Box display="flex" alignItems="center">
                    <ListItemIcon className={classes.listItemIcon}>
                      <SchoolIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      primary="Total Marks"
                      secondary={`${test?.points} points`}
                      className={classes.listItemText}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4} sm={6}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Box display="flex" alignItems="center">
                    <ListItemIcon className={classes.listItemIcon}>
                      <FaTags style={{ fontSize: "20px" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Topics"
                      secondary={<Tags tags={test.topics} variant="default" />}
                      className={classes.listItemText}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  className={classes.sectionBarContainer}
                >
                  <SectionBar
                    sections={test.test_sections}
                    totalQuestions={test.questions.length}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4} sm={6}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Box display="flex" alignItems="center">
                    <ListItemIcon className={classes.listItemIcon}>
                      <VerifiedUser />
                    </ListItemIcon>
                    <ListItemText
                      primary="Author"
                      secondary={test.author}
                      className={classes.listItemText}
                    />
                  </Box>
                </Grid>
                <Grid item className={classes.sectionBarContainer}>
                  <Box display="flex" alignItems="center">
                    <ListItemIcon className={classes.listItemIcon}>
                      <BarChart />
                    </ListItemIcon>
                    <ListItemText
                      primary="Analytics"
                      onClick={() => showModal(test.id.toString())}
                      className={classes.listItemText}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default TestInfoCard;
