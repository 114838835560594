import { QuestionModel } from "models/question.model";
import { BaseAPI } from "./baseAPI";

export class QuestionAPI extends BaseAPI {
  constructor(baseURL?: string) {
    super(baseURL);
  }

  async getQuestionById(questionId: string) {
    return this.get(`/questions/${questionId}`);
  }

  async updateQuestion(data: Record<string, any>) {
    return this.put(`/questions`, data);
  }

  async deleteQuestion(questionId: string) {
    return this.delete(`/questions/${questionId}`);
  }

  async getQuestionsByCategory(topics: string) {
    return this.get(`/questions/category/${topics}`);
  }

  async createQuestion(data: Record<string, any>) {
    return this.post(`/questions`, data);
  }

  async batchGetQuestions(ids: string): Promise<QuestionModel[]> {
    return this.get(`/questions/batch?ids=${ids}`);
  }

  async searchByTopic(topic: string) {
    return this.get(`/questions/category/${topic}`);
  }
}
