import { FaStar } from "react-icons/fa";

interface Props {
  max: number;
}

export function RatingReadOnly(props: Props) {
  const getStars = () => {
    let content: any[] = [];
    for (let i = 0; i <= props.max; i++) {
      content.push(<FaStar key={i} data-testid="rating-star" />);
    }
    return content;
  };

  return <div>{getStars()}</div>;
}
