import React, { useMemo, useState, useCallback } from "react";
import { Examsonline_Questions } from "generated/graphql";
import { Helper } from "helper/helper";
import { ListGroup } from "react-bootstrap";
import { Box, Button, Collapse, Grid } from "@material-ui/core";
import { Delete, FileCopy } from "@material-ui/icons";
import Question, { QuestionMode } from "component/question/question";
import { useTestEditorContext } from "context/testEditorProvider";
import MarksInput from "component/marksInput";
import Section from "../section";
import "./questionList.css";

const QuestionsList = () => {
  const {
    test,
    onCopy,
    setActiveQuestion,
    question: activeQuestion,
    handleDelete,
    uploadImageCallBack,
    updateTestSection,
  } = useTestEditorContext();

  // State to track the currently open question
  const [openQuestionId, setOpenQuestionId] = useState<number | null>(null);

  // Memoize sorted sections
  const sortedSections = useMemo(() => {
    return test?.test_sections
      ? [...test.test_sections].sort((a, b) => {
          if (!a.createdAt || !b.createdAt) return 0;
          return (
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          );
        })
      : [];
  }, [test.test_sections]);

  // Memoize filtered questions by section
  const getQuestionsBySection = useCallback(
    (sectionId: string) =>
      Helper.filterQuesBySection(test.questions, sectionId),
    [test.questions]
  );

  // Handler for selecting a question
  const handleItemClick = useCallback(
    (question: Examsonline_Questions) => {
      setActiveQuestion(question);
      setOpenQuestionId((prevId) =>
        prevId === question.id ? null : question.id
      ); 
    },
    [setActiveQuestion]
  );

  // Handler for marks change
  const handleMarks = useCallback(
    (newMarks: number) => {
      if (!activeQuestion) return;
      const updatedQuestion = { ...activeQuestion, marks: newMarks };
      setActiveQuestion(updatedQuestion);
    },
    [activeQuestion, setActiveQuestion]
  );

  // Render individual questions
  const QuestionItem = React.memo(
    ({
      question,
      index,
    }: {
      question: Examsonline_Questions;
      index: number;
    }) => {
      const isOpen = question.id === openQuestionId;

      return (
        <div key={question.id}>
          <ListGroup.Item
            onClick={() => handleItemClick(question)}
            action
            active={question.id === activeQuestion?.id}
            className={
              !Helper.validaeQuestion(question).isValid
                ? " bg-warning "
                : "border-bottom"
            }
          >
            <Grid className="d-flex justify-content-between">
              <span className="text-overflow-ellipsis">
                Q. {index + 1} {Helper.getQUestionText(question)}
              </span>
              <Box width={200} className="align-items-center">
                <span className="float-right">
                <MarksInput
                    questionId={question.id}
                    key={question.id}
                    initialMarks={question.marks}
                  />
                </span>
                <Button
                  size="small"
                  title="Copy Question"
                  onClick={onCopy}
                  variant="text"
                >
                  <FileCopy />
                </Button>
                <Button
                  size="small"
                  title="Delete Question"
                  onClick={() => handleDelete(question.id)}
                  variant="text"
                  color="secondary"
                >
                  <Delete />
                </Button>
              </Box>
            </Grid>
          </ListGroup.Item>
          {isOpen && (
            <Collapse in={isOpen}>
              <div className="p-2">
                <Question
                  question={question} 
                  mode={QuestionMode.edit}
                  uploadImageCallBack={uploadImageCallBack}
                />
              </div>
            </Collapse>
          )}
        </div>
      );
    }
  );

  return (
    <Grid item xs={12} className="m-0 px-1 px-lg-4">
      {sortedSections.map((section) => (
        <ListGroup style={{ width: "100%" }} key={section.id}>
          <Section
            handleChange={updateTestSection}
            section={section}
            key={section.id}
          >
            {getQuestionsBySection(section.id).map((question, index) => (
              <QuestionItem
                key={question.id}
                question={question}
                index={index}
              />
            ))}
          </Section>
        </ListGroup>
      ))}
    </Grid>
  );
};

export default QuestionsList;
