import { useQuery, useReactiveVar } from "@apollo/client";
import _ from "lodash";
import { useEffect } from "react";

import { Option } from "../../../models/option.model";
import { GET_OPTIONS_FOR_STUDENT } from "../../../queries/queries";
import { answerVar } from "../../../shared/cache/test_cache";
import { useAddAnswer } from "../../../shared/hooks/tests/useAddAnswer";
import { useDeleteSelectedOption } from "../../../shared/hooks/tests/useDeleteSelectedOption";
import { useGetAllSelectedOptions } from "../../../shared/hooks/tests/useGetAllSelectedoptions";
import { useSelectOption } from "../../../shared/hooks/tests/useSelectOption";
import { QuestionType } from "../../enums/question.type.enum";
import { Helper } from "helper/helper";
import { useToken } from "hooks/useToken";

interface OptionsHookProps {
  q_id: number;
  type: QuestionType;
}

interface OptionsHookResult {
  loading: boolean;
  error: any;
  selectedOptions: Option[];
  options: Option[];
  answers: Option[];
  updateOption: (option: Option) => void;
  getTextValue: (op: Option) => any;
  getIsSelected: (op: Option) => boolean;
  handleTextChange: (e: any, op: Option) => void;
}

export const useOptions = ({
  q_id,
  type,
}: OptionsHookProps): OptionsHookResult => {
  const answers = useReactiveVar(answerVar);
  const { addAnswer } = useAddAnswer();
  const { deleteoption } = useDeleteSelectedOption();
  const { addSelectedOption } = useSelectOption();

  const { decodedsessionToken} = useToken();

  const { attempt_id} = decodedsessionToken()

  const { selectedOptions } = useGetAllSelectedOptions(attempt_id);

  const { loading, data, error } = useQuery(GET_OPTIONS_FOR_STUDENT, {
    variables: {
      q_id,
    },
    fetchPolicy: "cache-first",
    skip: !q_id,
  });

  useEffect(() => {
    const savedAnswers = Helper.contentOptionsToEditorState(selectedOptions);
    if (answers.length === 0) {
      answerVar(savedAnswers);
    } else {
      const updated = answers.map((a) => {
        let temp = a;
        savedAnswers.map((b) => {
          if (b.id === a.id) {
            temp = b;
          }
          return b;
        });

        return temp;
      });

      answerVar(updated);
    }
  }, [selectedOptions]);

  useEffect(() => {
    addAnswer({
      attempt_id: attempt_id,
      q_id,
      isCorrect: false,
      type,
    });
  }, [q_id]);

  const updateOption = (option: Option) => {
    if (option.type === QuestionType.MCQ) {
      if (getIsSelected(option)) {
        answerVar([..._.remove(answerVar(), (a) => a.id !== option.id)]);
        deleteoption(option, attempt_id);
      } else {
        answerVar([...answerVar(), { ...option, isCorrect: true, q_id }]);
        addSelectedOption(
          { ...option, isCorrect: true, q_id },
          attempt_id
        );
      }
    } else {
      // update text answer here
      addSelectedOption(
        answers
          .filter((a) => a.id === option.id)
          .map((b) => {
            return Helper.contentOptionsToString([b])[0];
          })[0],
        attempt_id
      );
    }
  };

  const getTextValue = (op: Option) => {
    return answers.filter((a) => a.id === op.id)[0]?.value || "";
  };
  const getIsSelected = (op: Option) => {
    return answers.filter((a) => a.id === op.id)[0]?.isCorrect || false;
  };

  const handleTextChange = (e: any, op: Option) => {
    if (answers.filter((a) => a.id === op.id).length > 0) {
      const updated = answers.map((c: Option) => {
        if (c.id === op.id) {
          return { ...c, value: e };
        }
        return c;
      });
      answerVar(updated);
    } else {
      answerVar([...answerVar(), { ...op, value: e }]);
    }
  };

  return {
    loading,
    error,
    selectedOptions,
    options: data?.examsonline_options || [],
    answers,
    updateOption,
    getTextValue,
    getIsSelected,
    handleTextChange,
  };
};
