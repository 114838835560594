import { gql } from "@apollo/client";
import { OptionsFragment } from "./options.fragment";

export const QuestionFragment = gql`
  fragment QuestionFragment on examsonline_questions {
    id
    order
    text
    marks
    explanation
    test_id
    type
    section
    options {
      ...OptionsFragment
    }
  }${OptionsFragment}
`;
