import React, { forwardRef } from 'react';
import { Button, Grid } from '@material-ui/core';

interface NextBackButtonsProps {
  onBack: () => void;
  onNext?: () => void;
  nextButtonRef?: React.Ref<HTMLButtonElement>;
  nextButtonLabel?: string;
  backButtonLabel?: string;
  nextButtonType?: 'button' | 'submit' | 'reset';
  nextButtonVariant?: 'text' | 'outlined' | 'contained';
  nextButtonColor?: 'default' | 'inherit' | 'primary' | 'secondary';
}

const NextBackButtons = forwardRef<HTMLButtonElement, NextBackButtonsProps>(({
  onBack,
  onNext,
  nextButtonRef,
  nextButtonLabel = 'Next',
  backButtonLabel = 'Back',
  nextButtonType = 'submit',
  nextButtonVariant = 'contained',
  nextButtonColor = 'primary',
}, ref) => {
  return (
	<Grid container spacing={3}>
	  <Grid
		item
		xs={12}
		className="d-flex justify-content-around my-1 m-lg-4"
	  >
		<Button
		  className="btn mx-auto btn-primary btn-next"
		  size="small"
          variant='contained'
		  onClick={onBack}
		>
		  {backButtonLabel}
		</Button>
		<Button
		  size="small"
		  className="btn mx-auto btn-primary btn-next"
		  type={nextButtonType}
		  ref={nextButtonRef || ref}
		  variant={nextButtonVariant}
		  color={nextButtonColor}
		  onClick={onNext}
		>
		  {nextButtonLabel}
		</Button>
	  </Grid>
	</Grid>
  );
});

export default NextBackButtons;