import {
  Button,
  Grid,
  LinearProgress,
  ThemeProvider,
  Typography,
  createTheme,
} from "@material-ui/core";
import { useState, useEffect } from "react";
import { useTable } from "hooks/useTable";
import { useParams } from "react-router-dom";
import Loading from "shared/components/loader/loading";
import { useAnswerSheet } from "hooks/useAnswerSheet";
import { green } from "@material-ui/core/colors";
import SessionOverview from "component/session/sessionOverview";
import { useViewResultModal } from "hooks/useViewResultModal";
import SessionAnalytics from "component/analytics/sessionAnalytics";
import useFetch from "hooks/useFetchPreview";

interface Props {
  sessionId?: string;
  email?: string;
}

const theme = createTheme({
  palette: {
    primary: green,
  },
});

const ProgressBarWithLabel = ({ value }: { value: number }) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <ThemeProvider theme={theme}>
      <LinearProgress
        variant="determinate"
        color="primary"
        value={value}
        style={{ flexGrow: 1 }}
      />
    </ThemeProvider>
    <Typography
      variant="body2"
      color="textSecondary"
      style={{ marginLeft: "5px" }}
    >
      {`${Math.round(value)}%`}
    </Typography>
  </div>
);

export default function SessionAttempts(props: Props) {
  const [searchAttempt, setSearchAttempt] = useState("");
  const [, setTotal] = useState(10);

  const handleOnChange = (e: any) => {
    setSearchAttempt(e);
  };

  const params: { id: string } = useParams();

  const { data, loading } = useFetch<any[]>(`/sessions/${params.id}/attempts`);
  const { viewPage } = useViewResultModal("Result");

  const handleViewResult = (attempt) => {
    viewPage(
      `${import.meta.env.VITE_APP_URL}/result/${attempt.id}`,
      attempt.id
    );
  };
  useEffect(() => {
    data && setTotal(data.length);
  }, [data]);

  const { AnswerSheetDrawer, AnswerSheetButton } = useAnswerSheet();

  const tableData = data
    ?.map((attempt) => {
      return {
        id: attempt.id,
        email: attempt.email,
        createdAt:
          typeof attempt.createdAt === "string"
            ? new Date(attempt.createdAt).toLocaleString()
            : "",
        score: attempt.metadata.score,
        percentage: (
          <ProgressBarWithLabel value={attempt.metadata.percentage} />
        ),
        actions: (
          <Button
            variant="outlined"
            size="small"
            onClick={() => handleViewResult(attempt)}
            color="primary"
          >
            View Result
          </Button>
        ),
        timeTaken: `${attempt.metadata.timeSpent / 1000}s`,
        answerSheet: (
          <AnswerSheetButton studentId={attempt.email} attemptId={attempt.id} />
        ),
      };
    })
    .filter((attempt) => {
      return attempt.email.includes(searchAttempt);
    });

  const table = useTable({
    data: tableData || [],
    filterColumns: ["__typename", "id"],
    pagination: true,
    handleSearch: handleOnChange,
    toolbar: true,
    heading: "Session Attempts",
  });

  const sessionId = props.sessionId || params.id || "";

  const { data: sessionData } = useFetch(`/dynamodb/session/${sessionId}`);

  return (
    <Grid spacing={1}>
      <SessionOverview session={sessionData} readonly={false} />
      <SessionAnalytics sessionId={sessionId} />
      {loading && <Loading />}
      <Grid item xs={12} lg={12} className="p-3 p-lg-5 m-1 m-lg-1">
        {table}
      </Grid>
      <AnswerSheetDrawer />
    </Grid>
  );
}
