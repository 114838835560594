import { Typography, Grid, TextField } from "@material-ui/core";

interface TableToolbarProps<T> {
  handleOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSortChange?: (event) => void;
  searchValue: string;
  sortValue?: keyof T;
  title: React.ReactNode;
  placeholder: string;
}

const noop = () => {};

const TableToolbar = <T extends object>({
  handleOnChange = noop,
  handleSortChange = noop,
  searchValue,
  title,
  placeholder,
}: TableToolbarProps<T>) => {
  return (
    <Grid
      container
      justifyContent="space-around"
      alignItems="center"
      style={{ marginLeft: "15px" }}
    >
      <Grid item xs={12} sm={4}>
        <Typography variant="h6" id="tableTitle" component="div">
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          style={{}}
          fullWidth
          variant="outlined"
          placeholder={placeholder}
          onChange={handleOnChange}
          value={searchValue}
          size="small"
        />
      </Grid>
    </Grid>
  );
};

export default TableToolbar;
