import { QuestionDifficultyEnum } from "component/enums/question.difficulty.enum";
import { useState } from "react";

export function useChatGPT() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const generateText = async (keywords: string[], numQuestions: number, difficultyLevel: QuestionDifficultyEnum) => {
    setIsLoading(true);
    setError(null);

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `http://localhost:8082/Ai/generateTest?topic=${keywords.join(",")}&numQuestions=${numQuestions}`,
        requestOptions
      );
      const data = await response.json();
      // Do something with the data

      return data;
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return { generateText, isLoading, error };
}
