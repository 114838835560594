import {
  Typography,
  Grid,
  CardMedia,
  IconButton,
} from "@material-ui/core";
import NoRecords from "component/banners/no-records";
import { Examsonline_Contest } from "generated/graphql";
import moment from "moment";
import { Badge, Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaPencilAlt, FaSignOutAlt } from "react-icons/fa";
import Moment from "react-moment";
import { useHistory } from "react-router";
import { Tags } from "shared/components/tags/tags";
import { maskEmail } from "shared/hooks/auth/maskEmail";
import Formatter from "shared/translations/formatter";
import "./contest-card.scss";

export enum ContestCardColumns {
  "MEDIA",
  "PLAN",
  "STARTENDDATE",
  "EDITTEST",
  "RESULTS",
  "AUTHOR",
}


export interface Props {
  contest: Examsonline_Contest;
  hideColumns?: ContestCardColumns[];
  isSelectable?: boolean;
  onSelect?: (test: Examsonline_Contest) => void;
}

export default function ContestCard(props: Props) {
  const history = useHistory();

  const { contest } = props
  const gotoContest = () => {
    history.push(`/contest/preview/${props.contest.id}`);
  };
 const {maskedEmail}= maskEmail(props?.contest?.author ||"");
  const editTest = () => {
    history.push(`/contest/create/${props.contest.id}`);
  };
  const { t } = useTranslation("common");

  const shouldHide = (colName: ContestCardColumns): boolean => {
    if (!props?.hideColumns) return false;

    if (props.hideColumns.includes(colName)) return true;

    return false;
  };

  const handleSelect = () => {
    if (props?.onSelect) {
      props.onSelect(props.contest);
    }
  };

  const isContestExpired =()=>{
     return moment(contest?.endDate) < moment(new Date())
  }

  if(!props?.contest?.id) return <NoRecords />

  return (
    <Card className={ isContestExpired() ? "border card-animate  mt-2 w-100 contest-card card-disabled" : "border card-animate  mt-2 w-100 contest-card" }>
      <Grid container>
        <Grid item xs={4} lg={2} hidden={shouldHide(ContestCardColumns.MEDIA)}>
          <CardMedia
            className="m-2 rounded"
            style={{ width: "70%", height: "60px" }}
            image={
              props.contest?.files[0]?.info?.location ||
              + "/group.svg"
            }
            title="Live from space album cover"
          />
        </Grid>
        <Grid className="m-auto font-krona  text-center" item xs={4} lg={1}>
          <Typography variant="h6"> {props?.contest?.name}</Typography>
        </Grid>
        <Grid className="m-auto text-primary text-center" item xs={4} lg={1} hidden={shouldHide(ContestCardColumns.AUTHOR)}>
          <Badge variant="dark">{maskedEmail}</Badge>
          
        </Grid>
        <Grid className="m-auto text-primary text-center" item xs={4} lg={1} hidden={shouldHide(ContestCardColumns.PLAN)}>
          <Badge variant="danger">1</Badge>
        </Grid>
        <Grid className="m-auto text-center" item xs={4} lg={1} >
          <Tags tags={props.contest.topics} variant="dark" />
        </Grid>
        <Grid className="m-auto text-center" item xs={4} lg={2} hidden={shouldHide(ContestCardColumns.STARTENDDATE)}>
          <span className="small">
            <Badge
              className="mx-2"
              id="startdate"
              variant="primary"
              title="Assignment start date"
            >
              <Formatter options={{}} text="author.contest-card.startDate" />
            </Badge>

            <Badge
              className="mx-2"
              id="enddate"
              variant="danger"
              title="Assignment end date"
            >
              <Formatter options={{}} text="author.contest-card.endDate" />
            </Badge>
          </span>
          <br />
          <span
            title={moment(props.contest.startDate).format("LLL").toString()}
          >
            <Moment className="mx-2" format="DD-MMM">
              {props.contest.startDate}
            </Moment>{" "}
          </span>
          |
          <span title={moment(props.contest.endDate).format("LLL").toString()}>
            <Moment className="mx-2" format="DD-MMM">
              {props.contest.endDate}
            </Moment>
          </span>
        </Grid>
        <Grid className="m-auto text-center" item xs={4} lg={2}>
          <span className="small">
            <Badge variant="warning"> <Formatter options={{}} text="author.contest-card.createdAt" /></Badge>
          </span>{" "}
          <br />
          <Moment className="font-krona" format="LLL">
            {props.contest.createdAt}
          </Moment>
        </Grid>
        <Grid hidden={!props.isSelectable} className="m-auto">
          <Button title="Select" onClick={handleSelect} variant="primary" size="sm">
            Select
          </Button>
        </Grid>
        <Grid className="m-auto text-center" item xs={4} lg={1} hidden={shouldHide(ContestCardColumns.EDITTEST)}>
          <IconButton
            style={{ fontSize: "1.2em" }}
            title={t("author.contest-card.editContest", {})}
            className="m-auto text-dark"
            aria-label="edit contest"
            onClick={editTest}
          >
            <FaPencilAlt size={20}></FaPencilAlt>
          </IconButton>
        </Grid>
        <Grid className="m-auto text-primary text-center" item xs={4} lg={1} hidden={shouldHide(ContestCardColumns.RESULTS)}>
          <IconButton aria-label="" onClick={gotoContest}>
            <FaSignOutAlt />
          </IconButton>
        </Grid>
      </Grid>
    </Card>
  );
}
