import { Box, Typography } from "@material-ui/core";

interface StatusProps {
  status: string;
}

const Status: React.FC<StatusProps> = ({ status }) => {
  let bgColor;

  switch (status) {
    case "Active":
      bgColor = "success.main";
      break;
    case "Finished":
      bgColor = "secondary.main";
      break;
    default:
      bgColor = "primary.main";
  }

  return (
    <Box  bgcolor={bgColor} color="white"  borderRadius="borderRadius" textAlign="center">
      <Typography variant="subtitle1">{status}</Typography>
    </Box>
  );
};

export default Status;