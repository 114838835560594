import GenericModal from "component/genericModal";
import { createContext, useState, useContext } from "react";

// Define the type for the modal state
interface ModalState {
  id: string;
  isOpen: boolean;
  title: string;
  content: JSX.Element | null;
  footer: JSX.Element | null;
  size: string;
  closeAllModals: () => void;
}

// Create the Modal context
const ModalContext = createContext<{
  modals: ModalState[];
  openModal: (
    title: string,
    content: JSX.Element | null,
    footer: JSX.Element | null,
    size: string
  ) => void;
  closeModal: (id?: string) => void;
  closeAllModals: () => void;
}>({
  modals: [],
  openModal: () => {},
  closeModal: () => {},
  closeAllModals: () => {},
});

// Create the Modal provider component
export const ModalProvider = ({ children }) => {
  const [modals, setModals] = useState<ModalState[]>([]);

  const openModal = (
    title: string,
    content: JSX.Element | null,
    footer: JSX.Element | null,
    size: string
  ) => {
    const id = Math.random().toString(36).substr(2, 9); // Generate a random ID
    setModals([...modals, { id, isOpen: true, title, content, footer, size, closeAllModals }]);
  };

  const closeModal = (id: string) => {
    // if id not found close top modal
    if (!id) {
      setModals(modals.slice(0, -1));
      return;
    }
    setModals(modals.filter((modal) => modal.id !== id));
  };

  const closeAllModals = () => {
    setModals([]);
  };

  return (
    <ModalContext.Provider value={{ modals, openModal, closeModal, closeAllModals }}>
      {children}
      {modals.map((modal) => (
        <GenericModal
          key={modal.id}
          isOpen={modal.isOpen}
          handleClose={() => closeModal(modal.id)}
          title={modal.title}
          content={modal.content}
          footer={modal.footer}
          size={modal.size}
        />
      ))}
    </ModalContext.Provider>
  );
};

// Create the custom hook
export const useModalContext = () => {
  return useContext(ModalContext);
};
