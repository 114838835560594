import { gql } from "@apollo/client";
import { QuestionFragment } from "../question.fragment";

export const TestDetailFragment = gql`
  fragment TestDetailFragment on examsonline_tests {
    id
    accesstype
    author
    createdAt
    description
    document
    emailnotify
    enabled
    errorMessage
    maxAttempts
    isDraft
    name
    pincode
    randomise
    showResult
    time
    price
    points
    files {
      id
      info
    }
    test_sections {
      name
      id
      questions{
        id
      }
        createdAt
    }
    questions(order_by: { order: asc }) {
      ...QuestionFragment
    }
    topics {
      tag
    }
  }${QuestionFragment}
`;
