import { Examsonline_Tests_Insert_Input, useAdd_TestMutation, useUpdate_Question_SectionMutation } from "generated/graphql";
import { cloneDeep } from "lodash";
import { useHistory } from "react-router-dom";
import { Test } from "../../../models/test.model";
import { useToast } from "../useToast";
import { useAppState } from "../useUpdateAppstate";

export function useDuplicateTest() {
  const { successToast, warningToast } = useToast();

  const [addTest, { loading, error }] = useAdd_TestMutation();

   const [updatesection] = useUpdate_Question_SectionMutation();

   const {state } = useAppState()
  

  const history = useHistory();

  const prepareQuestions = (test: Test) => {
    return test.questions.map((q: any, index) => {
      delete q.id;
      delete q.test_id;
      q.options = {
        data: q.options.map((op: any) => {
          delete op.id;
          return op;
        }),
      };
      return q;
    });
  };

  const duplicateTest = (test: Test) => {
   const clone = cloneDeep(test);
    addTest({
      variables: {
        objects: {
          ...clone,
          questions: { data: prepareQuestions(clone) },
          name: "copy of " + clone.name,
          author: state.userInfo?.email, 
          test_sections: {
            data: clone.test_sections.map((a) => {
              delete a.id;
              delete a.questions;
              return a;
            }),
          },
          topics: {
            data: clone.topics.map((a) => {
              delete a.id;
              return a;
            }),
          },
        } as Examsonline_Tests_Insert_Input, 
        update_columns: [],
      } 
    })
      .then((res) => {
        successToast("test duplicated successfully");

        const new_sections = res.data?.insert_examsonline_tests?.returning[0].test_sections
         
         test.test_sections.map(async (sec, index)=>{
           await updatesection({
             variables:{
               section: new_sections!![index].id,
               _eq: res.data?.insert_examsonline_tests?.returning[0].id,
               _eq1: sec.id
               
             }
           })
         })


        history.push(
          `/addtest/${res.data?.insert_examsonline_tests?.returning[0].id}`
        );
      })
      .catch((e) => {
        console.error(e);
        warningToast("something went wrong please try again later");
      });
  };

  return { duplicateTest, error, loading };
}
