import { useState, useEffect } from "react";
import axios from "axios";

export const useNetworkStatus = (apiUrl: string) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [apiError, setApiError] = useState<boolean | null>(null);

  useEffect(() => {
    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    const checkApiStatus = async () => {
      try {
        await axios.get(apiUrl);
        setApiError(false);
      } catch (error) {
        setApiError(true);
      }
    };

    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    // Check API status on mount
    checkApiStatus();

    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, [apiUrl]);

  return { isOnline, apiError };
};

