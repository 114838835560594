import { Component } from "react";
import { Button, ButtonGroup, Container, Row } from "react-bootstrap";
import { FiZoomIn, FiZoomOut } from "react-icons/fi";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// const options = {
//   cMapUrl: "cmaps/",
//   cMapPacked: true,
// };

interface State {
  numPages: number;
  currentPage: number;
  scale: number;
}

export default class Sample extends Component<any, State> {
  state = {
    file: "./sample.pdf",
    numPages: 1,
    currentPage: 1,
    scale: 1,
  };

  onDocumentLoadSuccess = (doc: any) => {
    this.setState({ numPages: doc.numPages });
  };

  nexPage = () => {
    this.setState({ currentPage: this.state.currentPage + 1 });
  };

  backPage = () => {
    this.setState({ currentPage: this.state.currentPage - 1 });
  };

  zoomIn = () => {
    this.setState({ scale: this.state.scale + 0.1 });
  };
  zoomOut = () => {
    if (this.state.scale > 0.5) {
      this.setState({ scale: this.state.scale - 0.1 });
    }
  };

  render() {
    return (
      <Container>
        <Row className="justify-content-around mx-5 my-2">
          <Button
            disabled={this.state.currentPage <= 1}
            variant="dark"
            size="sm"
            onClick={this.backPage}
          >
            Back
          </Button>
          <span>
            {" "}
            Page - {this.state.currentPage}/{this.state?.numPages}{" "}
          </span>
          <ButtonGroup aria-label="Basic example">
            <Button onClick={this.zoomIn} variant="secondary">
              <FiZoomIn></FiZoomIn>{" "}
            </Button>
            <Button onClick={this.zoomOut} variant="secondary">
              <FiZoomOut></FiZoomOut>{" "}
            </Button>
          </ButtonGroup>
          <Button
            disabled={this.state.currentPage >= this.state.numPages}
            variant="dark"
            size="sm"
            onClick={this.nexPage}
          >
            Next
          </Button>
        </Row>
        <Row className="justify-content-center">
          <Document
            className="overflow-auto"
            file={this.props.file}
            onLoadSuccess={this.onDocumentLoadSuccess}
          >
            <Page
              key={`page_${this.state.numPages + 1}`}
              pageNumber={this.state.currentPage}
              scale={this.state.scale}
            />

            {/* {[1, 2, 3, 10, 11, 12].map((page) => (
            <Page pageNumber={page} />
          ))} */}
          </Document>
        </Row>
      </Container>
    );
  }
}
