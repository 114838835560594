import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { SEARCH_QUESTIONS } from '../../../queries/queries';
import { Examsonline_Questions } from "generated/graphql";

export function useSearchQuestionByTopic(limit=10,offset=0) {
  const [srchstring, setsrchstring] = useState("");
  const [questions, setquestions] = useState<Examsonline_Questions[]>([]);
  const [totalcount, settotalcount] = useState(0)

  const { loading, error, fetchMore } = useQuery(SEARCH_QUESTIONS, {
    variables: {
      _iregex: srchstring,
      _iregex1: srchstring,
      limit:limit,
      offset: offset
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setquestions( data.examsonline_questions);
      settotalcount(data.totalcount?.aggregate?.count)
    },
  });

  const search = (str: string) => {
    setsrchstring(str);
  };

  return { questions, loading, error, search, fetchMore, totalcount };
}
