import { Row, Col, Image, Button , Container} from "react-bootstrap";

interface  Props{
  onclick: any
}


export function NetworkError(props: Props) {

  const handleClick = ()=>{
    props.onclick()
  }

  return (
    <Container>
      <Row className=" text-center">
        <Col xs={12}>
          <Image src={"images/network-error.jpg"} />
        </Col>
        <Col>
        <Button block onClick={handleClick} variant="outline-primary"> Retry </Button>
        </Col>
      </Row>

    </Container>
  );
}
