import {
  convertFromRaw,
  convertToRaw,
  EditorState,
  ContentState,
} from "draft-js";
import { Quiz } from "models/quiz.model";
import ReactGA from "react-ga";
import { QuestionType } from "../component/enums/question.type.enum";
import { Option } from "../models/option.model";
import { Test } from "../models/test.model";
import jwt_decode from "jwt-decode";
import {
  Examsonline_Options,
  Examsonline_Questions,
  Examsonline_Test_Sections,
  Examsonline_Tests,
} from "generated/graphql";
import { QuestionAPI } from "api/questionAPI";

declare const window: any;

export class Helper {
  static updateObjectInArray(array: any[], action: any) {
    if (array.length === 0) {
      array.push(action.payload.item);
      return array;
    }

    return array.map((item: any, index: number) => {
      if (index !== action.payload.index) {
        // This isn't the item we care about - keep it as-is
        return item;
      }

      // Otherwise, this is the one we want - return an updated value
      return {
        ...item,
        ...action.payload.item,
      };
    });
  }

  static updateOptions(array: any[], action: any) {
    if (array.length === 0) {
      return array;
    }

    return array.map((item: Examsonline_Questions, index: number) => {
      if (index !== action.payload.index) {
        // This isn't the item we care about - keep it as-is
        return item;
      }

      // Otherwise, this is the one we want - return an updated value
      return {
        ...item,
        options: action.payload.options,
      };
    });
  }

  static deleteQuestion(arr: Examsonline_Questions[], index: number) {
    arr.splice(index, 1);
    return arr.map((a, index) => {
      return { ...a, id: index };
    });
  }

  static create_UUID() {
    var dt = new Date().getTime();
    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return uuid;
  }

  static contentStateToEditorState(test: Test): Test {
    const ques = (test: Test) => {
      if (!test) {
        return [];
      }

      return (
        test?.questions.map((a) => {
          return {
            ...a,
            text: EditorState.createWithContent(
              convertFromRaw(JSON.parse(a.text))
            ),
            explanation: a?.explanation
              ? EditorState.createWithContent(
                  convertFromRaw(JSON.parse(a.explanation))
                )
              : EditorState.createEmpty(),
            options: a.options.map((option) => {
              return {
                ...option,
                value: !!option.value
                  ? EditorState.createWithContent(
                      convertFromRaw(JSON.parse(option.value))
                    )
                  : EditorState.createEmpty(),
              };
            }),
          };
        }) || test
      );
    };

    return { ...test, questions: ques(test) };
  }

  static contentOptionsToEditorState(options: Option[]): Option[] {
    return options.map((option) => {
      return {
        ...option,
        value: !!option.value
          ? EditorState.createWithContent(
              convertFromRaw(JSON.parse(option.value))
            )
          : EditorState.createEmpty(),
      };
    });
  }
  static contentOptionsToString(
    options: Examsonline_Options[] & any
  ): Examsonline_Options[] {
    return options.map((option) => {
      return {
        ...option,
        value: !!option.value
          ? JSON.stringify(convertToRaw(option.value.getCurrentContent()))
          : JSON.stringify(
              convertToRaw(EditorState.createEmpty().getCurrentContent())
            ),
      };
    });
  }

  static editorStateToContentState(test: Test): Test {
    const ques = test.questions.map((a) => {
      return {
        ...a,
        text: JSON.stringify(convertToRaw(a.text.getCurrentContent())),
        options: a.options.map((option) => {
          return {
            ...option,
            value: JSON.stringify(
              convertToRaw(option.value.getCurrentContent())
            ),
          };
        }),
      };
    });

    return { ...test, questions: ques };
  }
  static editorStateToContentStateOnlyQuestions(
    questions: Examsonline_Questions[]
  ): Examsonline_Questions[] {
    return questions.map((a: any) => {
      return {
        ...a,
        text: JSON.stringify(convertToRaw(a.text.getCurrentContent())),
        options: a.options.map((option) => {
          return {
            ...option,
            value: JSON.stringify(
              convertToRaw(option.value.getCurrentContent())
            ),
          };
        }),
      };
    });
  }

  // function to download image on click TODO not working for canvas QR

  static downloadQR(e: any) {
    fetch(e.target.href, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "image.png"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  static validaeQuestion = (question: any) => {
    let isValid = false;
    if (question.type === QuestionType.MCQ) {
      question?.options?.map((a: Option, index: number) => {
        if (a.isCorrect) {
          isValid = true;
        }
        return a;
      });
    } else if (parseInt(question.type) === QuestionType.TEXT) {
      isValid = true;
    }

    return { isValid: isValid, q_no: question.id };
  };

  static TAGNAMES: any = {
    select: "input",
    change: "input",
    submit: "form",
    reset: "form",
    error: "img",
    load: "img",
    abort: "img",
    focus: "div",
  };
  static isEventSupported(eventName: string) {
    var el = document.createElement(Helper.TAGNAMES[eventName] || "div");
    eventName = "on" + eventName;
    var isSupported = eventName in el;
    if (!isSupported) {
      el.setAttribute(eventName, "return;");
      isSupported = typeof el[eventName] == "function";
    }
    el = null;
    return isSupported;
  }

  static FB_INIT() {
    if (window?.FB) {
      window?.FB?.XFBML.parse();
    }
  }

  static sendGAEvent(category: string, name: string) {
    ReactGA.event({
      category: category,
      action: name,
    });
  }

  static generateGradient() {
    var hexValues = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "a",
      "b",
      "c",
      "d",
      "e",
    ];

    function populate(a: any) {
      for (var i = 0; i < 2; i++) {
        var x = Math.round(Math.random() * 14);
        var y = hexValues[x];
        a += y;
      }
      return a;
    }

    var newColor1 = populate("#");
    var newColor2 = "#0470dc";
    var angle = Math.round(Math.random() * 360);

    const gradient =
      "linear-gradient(" + angle + "deg, " + newColor1 + ", " + newColor2 + ")";
    return { background: gradient };
  }

  static addDebounce = (fn: Function, timeout: number) => {
    let savedArgs: any = null;
    let inDebounce = false;

    return (args: any) => {
      savedArgs = args;
      if (!inDebounce) {
        inDebounce = true;
        setTimeout(() => {
          fn(savedArgs);
          inDebounce = false;
        }, timeout);
      }
    };
  };

  static getQUestionText = (q: Examsonline_Questions) => {
    if (!q) return "";
    const blocks = JSON.parse(q?.text)?.blocks;

    const value: string = blocks
      .map((block: any) => (!block.text.trim() && "\n") || block.text)
      .join("\n");
    return value;
  };

  static filterQuesBySection = (
    arr: Examsonline_Questions[],
    section: string
  ) => {
    return arr?.filter((a) => a.section === section);
  };

  static contentStateToQuizEditorState(test: Quiz): Quiz {
    const ques = (test: Quiz) => {
      if (!test) {
        return [];
      }

      return test.questions.map((a) => {
        return {
          ...a,
          text: EditorState.createWithContent(
            convertFromRaw(JSON.parse(a.text))
          ),
          options: a.options.map((option) => {
            return {
              ...option,
              value: !!option.value
                ? EditorState.createWithContent(
                    convertFromRaw(JSON.parse(option.value))
                  )
                : EditorState.createEmpty(),
            };
          }),
        };
      });
    };

    return { ...test, questions: ques(test) };
  }

  static decodeToken = (): { username: string } => {
    const token = localStorage.getItem("token");
    if (!token) {
      return { username: "" };
    }
    try {
      if (!!token) {
        return jwt_decode(token);
      }
      return { username: "" };
    } catch (e) {
      return { username: "" };
    }
  };

  static getUserRole = () => {
    return localStorage.getItem("role");
  };

  static getStudentToken = sessionStorage.getItem("sessionToken");

  static getSectionName = (
    section: string,
    sections: Examsonline_Test_Sections[]
  ) => {
    const sec = sections.find((a) => a.id === section);
    return sec?.name;
  };

  static validateExamsonlineTest = (test: Examsonline_Tests): string | null => {
    if (!test.questions) {
      return "Test must have at least one question.";
    }
    // Validation rules
    if (!test.name.trim()) {
      return "Test name cannot be empty.";
    }

    if (!test.description.trim()) {
      return "Test description cannot be empty.";
    }

    if (!test.author) {
      return "Test must have an author.";
    }

    if (test.questions?.length === 0) {
      return "Test must have at least one question.";
    }

    // If all checks pass, return null indicating no errors
    return null;
  };

  static mapAiResponseToDraftJsQuestion = (question) => {
    const questionContentState = ContentState.createFromText(question.text);
    const questionEditorState =
      EditorState.createWithContent(questionContentState);
    const questionRawContentState = convertToRaw(
      questionEditorState.getCurrentContent()
    );

    // explanation
    const explanationContentState = ContentState.createFromText(
      question?.explanation || "NO Explanation"
    );
    const explanationEditorState = EditorState.createWithContent(
      explanationContentState
    );
    const explanationRawContentState = convertToRaw(
      explanationEditorState.getCurrentContent()
    );

    const options = question.options.map((option) => {
      const optionContentState = ContentState.createFromText(option.text);
      const optionEditorState =
        EditorState.createWithContent(optionContentState);
      const optionRawContentState = convertToRaw(
        optionEditorState.getCurrentContent()
      );

      return {
        ...option,
        value: JSON.stringify(optionRawContentState),
      };
    });

    return {
      ...question,
      text: JSON.stringify(questionRawContentState),
      options,
      explanation: JSON.stringify(explanationRawContentState),
    };
  };

  static getAllQuestionBySection = async (
    section: Examsonline_Test_Sections
  ) => {
    const api = new QuestionAPI();

    const ids = section.questions.map((a) => a.id).join(",");

    const questions = await api.batchGetQuestions(ids);

    return questions;
  };
}
