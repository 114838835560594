import { Badge, Col, Row, InputGroup, Form, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { convertToRaw } from "draft-js";
import EditQuizForm from "./edit-quiz-form";
import { FaPlus, FaTimes } from "react-icons/fa";
import { Editor } from "react-draft-wysiwyg";
import Axios from "axios";
import ImportTestGrid from "shared/components/table/import-test.grid";
import { UPSERT_QUESTIONS } from "../../../queries/queries";
import { QuestionType } from "../../enums/question.type.enum";
import { useQuizById } from "shared/hooks/quiz/useQuizById";
import { useHistory, useParams } from "react-router";
import { EditOptionsNext } from "component/question/edit/options";
import { useAddQuestion } from "shared/hooks/questions/useAddQuestion";
import {
  optionInitialState,
  QuestionInitialState,
} from "component/create-test/_store/create-test.reducer";
import { useToast } from "shared/hooks/useToast";
import QuizPreview from "./quiz-preview";
import { Grid, Paper, Tab, Tabs } from "@material-ui/core";

export default function EditQuiz() {
  const params: { id: string } = useParams();
  const history = useHistory();
  const { quiz, quizEditorstate } = useQuizById(params.id);
  const { warningToast } = useToast();
  const { addQuizQuestion } = useAddQuestion();
  const [question, setquestion] = useState<any>(null);
  const [tab, settab] = useState(0);

  const addQuestion = () => {
    addQuizQuestion(
      QuestionInitialState as any,
      null,
      [optionInitialState, optionInitialState],
      quizEditorstate?.questions?.length || 0,
      quiz.test_sections[0].id,
      params.id
    )
      .then((res: any) => {
        /** set index to latest question */
        setindex(res.data.insert_examsonline_questions.returning[0].id);
      })
      .catch((e) => {
        warningToast(
          "something went wrong, If your students already use this question, it cannot be deleted"
        );
      });
  };

  useEffect(() => {
    if (quizEditorstate.questions.length >1) {
      setquestion(quizEditorstate.questions[0]);
    }

    if (!!quiz?.id && quizEditorstate?.questions?.length === 0) {
      addQuestion();
    }
  }, [quizEditorstate.questions.length, quiz]);

  const [srchstring, setsrchstring] = useState("");
  const [srchtype, setsrchtype] = useState("Tests");
  const [upsert_questions] = useMutation(UPSERT_QUESTIONS);
  const handleSearch = (e: any) => {
    setsrchstring(e.target.value);
  };
  const clearSearch = () => {
    setsrchstring("");
  };
  const [index, setindex] = useState(0);

  const handleScore = (e: any) => {
    setquestion({ ...question, marks: e.target.value });
  };
  const uploadImageCallBack = (e: any) => {
    let formData = new FormData();
    formData.append(e.name, e);

    return Axios.post("/file/image/editor", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };
  const saveText = () => {
    delete question.options;
    upsert_questions({
      variables: {
        objects: [
          {
            ...question,
            type: QuestionType.MCQ,
            text: JSON.stringify(
              convertToRaw(question.text.getCurrentContent())
            ),
            order: index + 1,
          },
        ],
        update_columns: ["text", "order", "marks"],
      },
    }).then((a) => {
      console.log("question added");
    });
  };

  const handleTextChange = (editorState: any) => {
    setquestion({
      ...question,
      text: editorState,
    });
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    settab(newValue);
  };

  const gotoQuiz = () => {
    history.push(`/myquiz/${quiz.id}`);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <EditQuizForm quiz={quiz} />
      </Grid>
      <Grid
        xs={12}
        lg={8}
        item
        key={quiz?.id}
        hidden={!quiz?.id}
        className=" mx-auto mt-lg-3"
        style={{ minHeight: "50vh" }}
      >
        <Paper className="col-lg-8 col-xs-12 mx-auto" square>
          <Tabs
            centered
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
            aria-label="disabled tabs example"
          >
            <Tab label="Editor" />
            <Tab label="Online Library" />
          </Tabs>
        </Paper>
        <Grid item hidden={tab !== 0}>
          <Col xs={12} md={2} key={question?.id}>
            <div className="h5 d-flex  justify-content-center">
              <Badge className="m-2" variant="secondary">
                Q. ID {index + 1}
              </Badge>
            </div>
          </Col>
          <Col xs={12}>
            <Form>
              <Form.Group
                onDrop={(e: any) =>
                  console.log("dropped", e.dataTransfer.getData("URL"))
                }
                onBlur={saveText}
              >
                <Form.Label></Form.Label>

                <Editor
                  toolbar={{
                    options: [
                      "inline",
                      "list",
                      "image",
                      "textAlign",
                      "blockType",
                      "colorPicker",
                    ],
                    inline: { inDropdown: false },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    blockType: {
                      inDropdown: false,
                      options: ["Normal", "H1", "H2", "H3", "Code"],
                    },
                    image: {
                      uploadCallback: uploadImageCallBack,
                      alt: { present: false, mandatory: false },
                      inputAccept:
                        "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                      previewImage: true,
                      defaultSize: {
                        height: "200",
                        width: "300",
                      },
                    },
                  }}
                  editorState={question?.text}
                  wrapperClassName="edit-question-wrapper"
                  editorClassName="edit-question-editor"
                  onEditorStateChange={handleTextChange}
                  placeholder="Enter Question Text"
                />
              </Form.Group>
            </Form>
          </Col>
          <Col xs={12} md={10}>
            <EditOptionsNext  key={index} q_id={question?.id}></EditOptionsNext>
          </Col>
          <Col
            xs={12}
            md={2}
            className="d-flex flex-column justify-content-around"
          >
            <Form.Group className="score-input mb-4 ">
              <Form.Label column="lg">Points</Form.Label>
              <Form.Control
                min="1"
                type="number"
                maxLength={1}
                size="lg"
                value={question?.marks}
                onChange={handleScore}
                onBlur={saveText}
              ></Form.Control>
            </Form.Group>
          </Col>

          <Row className="text-center mb-5">
            <Col>
              <Button
                className="m-auto"
                variant="outline-primary"
                onClick={() => addQuestion()}
              >
                <FaPlus /> Add Question
              </Button>
            </Col>
            <Col>
              <Button
                className="m-auto"
                variant="primary"
                onClick={() => gotoQuiz()}
              >
                Finish
              </Button>
            </Col>
          </Row>
        </Grid>
        <Grid item hidden={tab !== 1}>
          <Col
            xs={9}
            lg={6}
            className="mx-auto my-1 search-input my-lg-3"
            id="search"
          >
            <InputGroup>
              <Form.Control
                onChange={handleSearch}
                type="text"
                value={srchstring}
                placeholder="Search questions by topic"
                className="my-1"
              />
              <Form.Group controlId="searchFilter" className="my-1 ">
                <Form.Control
                  onChange={(val) => setsrchtype(val.target.value)}
                  value={srchtype}
                  as="select"
                >
                  <option>Tests</option>
                  <option>Questions</option>
                </Form.Control>
              </Form.Group>
              <InputGroup.Append hidden={!srchstring}>
                <InputGroup.Text
                  className="m-1"
                  onClick={clearSearch}
                  id="basic-addon2"
                >
                  <FaTimes /> Clear
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Col>
          <ImportTestGrid srchString={srchstring} srchtype={srchtype} />
        </Grid>
      </Grid>
      <Grid lg={4} item>
        <QuizPreview
          onSelect={(index) => {
            setquestion(quizEditorstate.questions[index]);
          }}
          questions={quiz?.questions}
        />
      </Grid>
    </Grid>
  );
}
