import { ShareUrl } from "shared/share/shareUrl";
import EmbedCode from "component/embedCode";
import { useModalContext } from "context/modalProvider";

// Explicitly type the 'link' parameter as string
export const useShareTest = () => {
  const { openModal } = useModalContext();

  const handleOpen = (link?: string) => {
    openModal(
      "Share",
      <>
        <ShareUrl url={link} />
        <EmbedCode url={link} />
      </>,
      null,
      "sm"
    );
  };

  return { handleOpen };
};
