import { EditorState } from "draft-js";
import { Filter } from "../../../models/filter.model";
import { Option } from "../../../models/option.model";
import { Question } from "../../../models/question.model";
import { Test } from "../../../models/test.model";
import { ACCESS } from "../../enums/access.enum";
import { Mode } from "../../enums/mode.enum";
import { QuestionState } from "../../enums/question.state.enum";
import { QuestionType } from "../../enums/question.type.enum";

export const optionInitialState: Option = {
  isCorrect: false,
  value: EditorState.createEmpty(),
  type: QuestionType.MCQ,
};
export const testInitialState: Test = {
  name: "",
  time: 0,
  pincode: "",
  enabled: true,
  emailnotify: true,
  author: "",
  topics: [],
  accesstype: ACCESS.PUBLIC,
  isDraft: true,
  errorMessage: null,
  questions: [],
  description: "",
  randomise: true,
  test_sections: [],
};

export const QuestionInitialState = {
  id: 0,
  text: EditorState.createEmpty(),
  type: QuestionType.MCQ,
  options: [
    optionInitialState,
    { ...optionInitialState, id: 1 },
    { ...optionInitialState, id: 2 },
  ],
  isVisible: true,
  state: QuestionState.NOTANSWERED,
  timeSpent: 0,
  marks: 1,
  section: "DEFAULT",
};

export interface CreateTest {
  questions: Question<any>[];
  testInfo: Test;
  isEdit: boolean;
  filter: Filter;
  hasError: boolean;
  errorInfo: any;
  mode: Mode;
}
