import "react-toastify/dist/ReactToastify.css";

import { Suspense } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import AboutUs from "../pages/about/about";
import { AuthorDashboardNext } from "../pages/author/author";
// import myQuiz from "../component/Dashboard/my-quiz";
import Recharge from "../component/payment/recharge";
import PreviewNext from "../component/preview/preview";
import DocumentTest from "../component/quiz/document-test";
import { ResultsNext } from "../component/result/result";
import { TestSubmissionsNext } from "../component/submission/test.submissions";
import { SuccessTestNext } from "../component/test/components/success-test/success-test";
import EditTestComponent from "../component/test/components/edit/edit";
import NextTest from "../component/test/test";
import AppError from "../shared/components/errors/app-error";
import { Profile } from "../shared/components/profile/profile";
import { useGetUserInfo } from "../shared/hooks/user/useGetUserInfo";
import { TestLibrary } from "component/library/test/test-library";
import CreateContest from "component/contest/create-contest-form";
import { ProtectedRoute } from "./protected-route";
import { ContestResult } from "component/contest/contest-result";
import { AssignContest } from "component/contest/assign-contest";
import { useClearErrors } from "shared/hooks/utils/useClearErrors";
import AppStoreContainer from "component/library/app-store-container";
import Credits from "component/credits";
import { SideBar } from "component/sidebar/sidebar";

import {
  ConfirmUser,
  AskRegister,
  AcceptInvite,
  Group,
  Student,
  Support,
  OrgAdmin,
  TicketDetailView,
  AskSupport,
  RequestDemoForm,
  ContactUs,
  AuthorLogin,
  StudentAllResults,
  createTestForm,
  Checkout,
  AppHeader,
  Home,
  Preview,
  TermsOfService,
  PrivacyPolicy,
  ForgotPassword,
  ResetPassword,
} from "./lazLoadedComponents";
import EditQuiz from "component/quiz/edit/edit-quiz";
import ErrorRoutes from "./errorRoutes";
import SessionLogin from "component/preview/SessionLogin";
import SessionAttempts from "component/test/components/session/sessionAttempts";
import Sessions from "component/test/components/session/sessions";
import Bill from "pages/bill";
import StudentResultSearch from "component/home/student-search-result";
import { Grid } from "@material-ui/core";

const renderLoader = () => (
  <div id="loader" className="modal-backdrop show">
    <Spinner className="logo" animation="grow" variant="primary" role="status">
      <span className="sr-only">Loading...</span>
    </Spinner>
  </div>
);
export default function AppRouter() {
  useGetUserInfo();

  // clear errors on navigation
  useClearErrors();

  return (
    <div fluid className="p-0 m-0">
      <Suspense fallback={renderLoader()}>
        <Grid>
          <Col xs={12}>
            <AppHeader></AppHeader>
          </Col>
        </Grid>
        <Grid container>
          <SideBar />
          <Col id="router-container" className="bg-light mx-0 px-0">
            <AppError></AppError>
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <Switch>
              <Route
                exact
                path="/confirm/:email/:token"
                component={ConfirmUser}
              />
              <ProtectedRoute
                roles={["AUTHOR"]}
                exact
                path="/edit/:id"
                component={EditTestComponent}
              />
              <Route exact path="/edit/document" component={DocumentTest} />
              <Route exact path="/contactus" component={DocumentTest} />
              <Route exact path="/login" component={AuthorLogin} />
              <Route path="/signup" component={AskRegister} />
              <ProtectedRoute
                roles={["SUPPORT"]}
                exact
                path="/support"
                component={Support}
              />
              <Route exact path="/support/ask" component={AskSupport} />
              <Route
                path="/accept/:orgid/:email/:code"
                component={AcceptInvite}
              />
              <ProtectedRoute
                roles={["AUTHOR"]}
                exact
                path="/quiz/edit"
                component={EditQuiz}
              />
              <ProtectedRoute
                roles={["AUTHOR"]}
                exact
                path="/quiz/edit/:id"
                component={EditQuiz}
              />
              <ProtectedRoute
                roles={["AUTHOR"]}
                exact
                path="/addtest/:id"
                component={createTestForm}
              />
              <ProtectedRoute
                roles={["AUTHOR"]}
                exact
                path="/addtest"
                component={createTestForm}
              />
              <Route exact path="/" component={Home} />
              <Route exact path="/result/:attemptId" component={ResultsNext} />
              <ProtectedRoute
                roles={["AUTHOR", "STUDENT"]}
                exact
                path="/profile"
                component={Profile}
              />
              <ProtectedRoute
                roles={["AUTHOR"]}
                exact
                path="/result/session/:id/:asg_id?"
                component={TestSubmissionsNext}
              />

              <Route exact path="/preview/:id" component={PreviewNext} />
              <Route
                exact
                path="/preview/assignment/:id/:asg_id"
                component={Preview}
              />
              <Route exact path="/session/login/:id" component={SessionLogin} />
              <Route exact path="/session/:id" component={SessionAttempts} />
              <Route exact path="/sessions/:id" component={Sessions} />
              <Route
                exact
                path="/preview/contest/:id/:contest_id"
                component={Preview}
              />
              <Route exact path="/success/:id" component={SuccessTestNext} />
              <ProtectedRoute
                roles={["AUTHOR"]}
                exact
                path="/author"
                component={AuthorDashboardNext}
              />
              <Route exact path="/next/test/:id" component={NextTest} />
              <Route exact path="/next/test/:id/:asg_id" component={NextTest} />
              <Route
                exact
                path="/next/test/contest/:id/:contest_id"
                component={NextTest}
              />
              <Route exact path="/reset" component={ForgotPassword} />
              <Route exact path="/reset/:token" component={ResetPassword} />
              <Route exact path="/contactus" component={ContactUs} />
              <Route exact path="/upgrade" component={Recharge} />
              <Route exact path="/library" component={TestLibrary} />
              <ProtectedRoute
                roles={["AUTHOR"]}
                exact
                path="/checkout"
                component={Checkout}
              />
              <Route
                exact
                path="/findresults/:email"
                component={StudentAllResults}
              />
              <Route exact path="/find" component={StudentResultSearch} />
              <Route exact path="/student" component={Student} />
              <Route exact path="/student/group/:id" component={Group} />
              <ProtectedRoute
                roles={["ORG-ADMIN"]}
                exact
                path="/org-admin"
                component={OrgAdmin}
              />
              <Route exact path="/ticket/:id" component={TicketDetailView} />
              <Route
                exact
                path="/docs/privacypolicy"
                component={PrivacyPolicy}
              />
              <Route
                exact
                path="/docs/termsofservice"
                component={TermsOfService}
              />
              <Route exact path="/about" component={AboutUs} />
              <Route exact path="/contact" component={ContactUs} />
              <ProtectedRoute
                roles={["AUTHOR"]}
                exact
                path="/group/:id"
                component={Group}
              />
              <Route
                exact
                path="/contest/create/:id"
                component={CreateContest}
              />
              <Route exact path="/contest/create" component={CreateContest} />
              <Route exact path="/contest/result" component={ContestResult} />
              <Route
                exact
                path="/contest/assign/:id"
                component={AssignContest}
              />
              <Route exact path="/demo" component={RequestDemoForm} />
              <Route path="/demo" component={RequestDemoForm} />
              <Route path="/store" component={AppStoreContainer} />
              <Route path="/credits" component={Credits} />
              <Route path="/billing" component={Bill} />
              <ErrorRoutes />
            </Switch>
          </Col>
        </Grid>
        <AskSupport />
      </Suspense>
    </div>
  );
}
