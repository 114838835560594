import { Pagination } from "@material-ui/lab";
import { useState } from "react";


/**
 * 
 * @param total 
 * @param limit 
 * @returns 
 */

const usePagintaion = (total: number, limit = 10) => {
  const [activePage, setactivePage] = useState<number>(1);

  const reset = () => {
    setactivePage(1);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setactivePage(value);
  };
  const pages = () => {
    return (
      <Pagination
        className="float-right m-auto"
        onChange={handleChange}
        page={activePage}
        count={Math.ceil(total / limit)}
        variant="outlined"
        shape="rounded"
        color="primary"
      />
    );
  };

  return { activePage, pages, reset };
};

export default usePagintaion;
