import { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { useAppState } from "shared/hooks/useUpdateAppstate";
import Loading from "../loader/loading";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

declare const window: Window;

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AppError() {
  const { state, clearErrors } = useAppState();
  const setShow = (e: any) => {
    handleErrors(e);
    clearErrors();
  };

  const handleErrors = (e: any) => {
    // handle 401 error
    if (e?.status === 401) {
      window.localStorage.clear();
      console.log("redirect due to 401");
      // history!.push("/");
    }
  };

  return (
    <Fragment>
      <div className="my-3" hidden={state.errors.length === 0}>
        {state.errors.map((e: any) => {
          return (
            <Snackbar open={true} autoHideDuration={6000} onClose={() => setShow(e)}>
              <Alert onClose={() => setShow(e)} severity="error">
                <div hidden={e?.data?.message}>
                  {e?.status || e?.statusCode || e}
                </div>
                <div hidden={e?.data?.message}>{e?.statusText}</div>
                <br></br>
                <div>{e?.data?.message || e?.data?.message?.error}</div>
              </Alert>
            </Snackbar>
          );
        })}
      </div>
     { state.loading &&  <Loading />}
    </Fragment>
  );
}