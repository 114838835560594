import "./login.scss";
import "react-toastify/dist/ReactToastify.css";

import { Card, Col, Container, Row } from "react-bootstrap";

import Formatter from "shared/translations/formatter";
import LoginForm from "../../forms/loginForm";

export interface States {
  username: any;
  password: any;
  hasError: any;
  errorMessage: any;
  testId: any;
  clicked: any;
}
function AuthorLogin() {
  return (
    <Container className="author-login">
      <Row id="">
        <Col xs={12} className="my-2 my-lg-5 mx-auto">
          <Card className="border-0  m-auto">
            {
              <Card.Body>
                <Card.Title className="text-center  font-weight-bold pb-3">
                  <h2>
                    <Formatter options={{}} text={"author-login.authorLogin"} />
                  </h2>
                </Card.Title>
                <Row>
                  <Col>
                    <LoginForm />
                  </Col>
                </Row>
              </Card.Body>
            }
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default AuthorLogin;
