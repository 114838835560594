// LeftPanel.tsx
import React from "react";
import { Grid } from "@material-ui/core";
import ViewQuestionsButtons from "component/create-test/view-questions.buttons";
import Submit from "../submit.modal";
import { useTestContext } from "context/sessionProvider";

interface LeftPanelProps {
  onBack: () => void;
  history: any;
  token: any;
}

const LeftPanel: React.FC<LeftPanelProps> = ({ onBack, history, token }) => {
  const { test } = useTestContext();

  if (!test) return null;

  return (
    <Grid item xs={12} lg={4} className="test-left min-vh-100">
      <Grid item xs={12} className="p-3 lead text-center border-bottom">
        <div className="font-weight-bold">{test.name}</div>
        <div>Total Questions: {test.questions.length}</div>
        <div>Time to Complete: {test.time} minutes</div>
      </Grid>
      <Grid item xs={12} className="my-5">
        <ViewQuestionsButtons />
      </Grid>
      <Grid item xs={12} className="my-5">
        <Submit
          onBack={onBack}
          history={history}
          test={test}
          sessionId={token?.sessionId}
          attempt_id={token?.attempt_id}
          user={token?.email}
        />
      </Grid>
      <Grid item xs={12} className="greyed-out-panel">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
          </Grid>
          <Grid item xs={12} md={6}>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LeftPanel;
