import { useState } from "react";
import { Container,Row,Col,Tab,Tabs } from "react-bootstrap";
import { AnswerSheet } from "./answersheet";

interface Props2 {
    answersheets: any[];
  }
  

export function AnswerSheetModal(props: Props2) {
    const [active, setactive] = useState<any>(
      props.answersheets[props.answersheets.length - 1].id
    );
  
    const handleSelect = (key: any) => {
      setactive(key);
    };
  
    return (
      <Container>
        <Row className="my-5 answer-sheet-wrapper">
          <Col>
            <Tabs
              variant="pills"
              onSelect={handleSelect}
              activeKey={active}
              transition={false}
              id="answersheet-tabs"
            >
              {props.answersheets.map((a) => {
                return (
                  <Tab eventKey={a.id} title={a.email}>
                    <AnswerSheet {...a} />
                  </Tab>
                );
              })}
            </Tabs>
          </Col>
        </Row>
      </Container>
    );
  }
  