// CenterPanel.tsx
import React from "react";
import { Grid, Paper } from "@material-ui/core";
import draftToHtml from "draftjs-to-html";
import Options from "../options/options";
import { useTestContext } from "context/sessionProvider";
import NextBackButtons from "component/Nav/NextBackButtons";

interface CenterPanelProps {
  onBack: any;
}

const CenterPanel: React.FC<CenterPanelProps> = ({ onBack }) => {
  const { activeQuestion, addAnswer, getActiveQuestionIndex, answers, next } =
    useTestContext();

  const onOptionsChange = (selectedOptionIds) => {
    activeQuestion && addAnswer(activeQuestion.id, selectedOptionIds, "");
  };

  if (!activeQuestion?.text) return null;

  return (
    <Grid item xs={12} md={8} lg={8} className="test-center">
      <Grid container>
        <Grid item xs={12}>
          <Paper square className="col-xs-12 col-lg-9 mx-auto"></Paper>
        </Grid>
      </Grid>
      <Grid container className="p-2">
        <Grid item xs={12} md={6} className="m-auto">
          <Grid container className="back-btn p-2 text-muted lead text-center">
            <Grid item xs={12}>
              Question {getActiveQuestionIndex() + 1}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Paper className="w-100 p-2">
          <Grid item xs={12} md={12} className="h5 text-center">
            <div
              dangerouslySetInnerHTML={{
                __html: draftToHtml(JSON.parse(activeQuestion?.text)),
              }}
            ></div>
          </Grid>
        </Paper>
      </Grid>
      <Grid item>
        <Options
          type={activeQuestion?.type}
          key={activeQuestion?.id}
          q_id={activeQuestion?.id}
          onOptionsChange={onOptionsChange}
          options={activeQuestion?.options}
          selectedOptions={
            answers.find((a) => a.questionId === activeQuestion?.id)
              ?.selectedOptions || []
          }
        ></Options>
      </Grid>

      <Grid container className="m-2 ">
        <NextBackButtons onBack={onBack} onNext={next} />
      </Grid>
    </Grid>
  );
};

export default CenterPanel;
