import * as yup from 'yup';

export const testValidationSchema = yup.object({
  name: yup.string().required("test name is required"),
  pincode: yup
    .string()
    .required("access code is required")
    .max(4, "should be 4 characters")
    .min(4, "should be 4 characters"),
  showResult: yup.boolean().required(),
  enabled: yup.boolean().required(),
  time: yup
    .number()
    .required("completion time is required")
    .integer("should be an integer number")
    .min(1, "time cannot be less than 1 minute"),
  description: yup.string().required("minimum 10 characters required").min(10),
  accesstype: yup.string().required(),
  topics: yup.array().required("please enter at least one topic"),
  questions: yup.array(),
  id: yup.number(),
  __V: yup.number(),
  createdAt: yup.string(),
  updatedAt: yup.string(),
  author: yup.string(),
  randomise: yup.boolean(),
  maxAttempts: yup.number().required().min(0).positive(),
});