import { useEffect, useState } from "react";
import { Row, Col, ProgressBar } from "react-bootstrap";
import "./timer.scss";
interface Props {
  expiryTime: number; // minutes
  onEndTimer: Function;
}

export function Timer(props: Props) {
  const [interval, setinterval] = useState<any>();
  const [timeleftSec, settimeleftSec] = useState(props.expiryTime * 60);

  useEffect(() => {
    setinterval(
      setInterval(() => {
        settimeleftSec((timeleftSec) => timeleftSec - 1);
      }, 1000)
    );

    return () => {
      clearInterval(interval);
    };
  }, [props.expiryTime, timeleftSec]);

  const getPerTime = (): number => {
    if (!!props.expiryTime && timeleftSec <= 0) {
      props.onEndTimer();
    }

    return Math.round((timeleftSec / props.expiryTime) * 60) * 100;
  };

  const getProgress = () => {
    if (getPerTime() > 10) {
      return (
        <ProgressBar
          className="text-dark"
          striped
          variant="success"
          label={`${Math.trunc(timeleftSec / 60)} mins`}
          now={getPerTime()}
        />
      );
    }
    if (getPerTime() <= 10 && getPerTime() > 5) {
      return <ProgressBar striped variant="warning" now={getPerTime()} />;
    }

    if (getPerTime() > 0 && getPerTime() < 2 * 60) {
      return <ProgressBar striped variant="danger" now={getPerTime()} />;
    }

    return <p>Time Over</p>;
  };

  return (
    <Row className="p-1  font-weight-bold timer">
      <Col key={timeleftSec} className=" text-dark mr-2">
        <ProgressBar now={100}>{getProgress()} </ProgressBar>
      </Col>
    </Row>
  );
}
