import { useQuery, useReactiveVar } from "@apollo/client";
import { useEffect } from "react";
import { GET_USER_INFO } from "../../../queries/queries";
import { appState } from "../../cache/appState";
import { Helper } from "helper/helper";
import { usePrevious } from "../utils/usePrevious";

export interface User {
  organisation: {
    id: string;
    name: string;
  };
  email: string;
  username: string;
  password: string;
  id: string;
  role: string;
}

export function useGetUserInfo() {
  const { data, loading, error, refetch } = useQuery(GET_USER_INFO, {
    skip:
      !Helper.decodeToken().username || Helper.decodeToken().username === "",
    variables: {
      _eq: Helper.decodeToken().username ,
    },
    onCompleted: (data) => {
      appState({
        ...appState(),
        userInfo: data.examsonline_user[0],
        showtour: !data.examsonline_user[0]?.tourCompleted,
      });
    },
    fetchPolicy: "network-only",
    onError:()=>{
      console.log('unable to fetch user information')
    }
  });

  
  const { loggedIn } = useReactiveVar(appState);
  const prevLogin = usePrevious(loggedIn)


  useEffect(() => {
    if (loggedIn !== prevLogin && loggedIn) {
      refetch();
    }
  }, [loggedIn]);

  return { userInfo: data?.examsonline_user[0], loading, error };
}
