import { useEffect, useState } from "react";
import { Button, Grid, Tab } from "@material-ui/core";
import QuestionGrid from "shared/grids/questions-grid";
import TestGrid from "shared/grids/test-grid";
import { TestCardColumns } from "shared/components/cards/test-card/test-card";
import { useModalContext } from "context/modalProvider";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";

export function useSearchLibrary() {
  const { openModal } = useModalContext();

  const handleOpen = () =>
    openModal("Search Questions", <TestLibrary />, <></>, "lg");
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "f") {
        event.preventDefault();
        handleOpen();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return {
    searchButton: (
      <Button
        variant="outlined"
        color="primary"
        onClick={handleOpen}
        startIcon={<Tab />}
      >
        Search
      </Button>
    ),

    search: handleOpen,
  };
}

function TestLibrary() {
  const [value, setValue] = useState("0");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid container>
      <TabContext value={value}>
        <Grid item xs={2}></Grid>
        <Grid item xs={12}>
          <TabList
            orientation="horizontal"
            variant="scrollable"
            onChange={handleChange}
          >
            <Tab label="Tests" value="0" />
            <Tab label="Questions" value="1" />
          </TabList>
          <TabPanel value="0">
            <TestGrid
              hideColumns={[
                TestCardColumns.ACTIONS,
                TestCardColumns.BUY,
                TestCardColumns.CHANGE_STATUS,
                TestCardColumns.ASSIGN,
                TestCardColumns.STATS,
                TestCardColumns.SHARE,
                TestCardColumns.CREATEDAT,
              ]}
              canCreate={false}
              limit={5}
            />
          </TabPanel>
          <TabPanel value="1">
            <QuestionGrid />
          </TabPanel>
        </Grid>
      </TabContext>
    </Grid>
  );
}
