import {
  Card,
  CardContent,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  IconButton,
  Grid,
  TableHead,
} from "@material-ui/core";
import moment from "moment";
import { Examsonline_Session } from "generated/graphql";
import { Share } from "@material-ui/icons";
import { useShareTest } from "hooks/useShare";
import useSessionStatus from "hooks/useSessionStatus";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  content: {
    padding: theme.spacing(2),
    position: "relative", // Add this
  },
  table: {
    minWidth: 300,
  },
  actions: {
    justifyContent: "flex-end",
    position: "absolute", // Add this
    right: 0, // Add this
    top: 0, // Add this
  },
}));

const SessionOverviewCard = ({ session, readonly }) => {
  const classes = useStyles();
  const { handleOpen } = useShareTest();

  const sessionDetails = session as Examsonline_Session;

  if (!sessionDetails) return null;

  const formattedStartTime = moment(sessionDetails.startTime).format("LLL");
  const formattedEndTime = moment(sessionDetails.endTime).format("LLL");

  const status = useSessionStatus(session.startTime, session.endTime);

  return (
    <Grid item xs={12} md={6} className="m-auto">
      <Card className={classes.root} elevation={0}>
        <CardContent className={classes.content}>
          <TableContainer
            component={Paper}
            className={classes.table}
            elevation={0}
          >
            <Table aria-label="session details table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Session Name</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Start Time</strong>
                  </TableCell>
                  <TableCell>
                    <strong>End Time</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Status</strong>
                  </TableCell>
                  {/* <TableCell>
                    <strong>Edit</strong>
                  </TableCell> */}
                  <TableCell>
                    <strong>Share</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{sessionDetails.name}</TableCell>
                  <TableCell>{formattedStartTime}</TableCell>
                  <TableCell>{formattedEndTime}</TableCell>
                  <TableCell> {status().label} </TableCell>
                  {/* <TableCell>
                    <IconButton
                      onClick={() => openEditSessionModal(sessionDetails)}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell> */}
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        handleOpen(`/session/login/${sessionDetails.id}`);
                      }}
                    >
                      <Share />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default SessionOverviewCard;
