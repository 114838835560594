import { useQuery } from "@apollo/client";
import { Examsonline_Tests } from "generated/graphql";
import { SEARCH_TEST_BY_TAG } from "../../../queries/queries";

/**
 * 
 * @param limit 
 * @param offset 
 * @param search 
 * @returns 
 */
export function useSearchTestByTopic(limit = 10, offset = 0, search) {
  const { data ,loading, error, fetchMore } = useQuery(SEARCH_TEST_BY_TAG, {
    variables: {
      _iregex: search,
      _iregex1: search,
      limit: limit,
      offset: offset,
    },
    fetchPolicy: "cache-and-network",

    skip: search === undefined || offset < 0,
  });

  return {
    testsByTopic: data?.examsonline_topics.map((a) => a?.test) as Examsonline_Tests[],
    loading,
    error,
    totalcount: data?.total_count?.aggregate?.count,
    fetchMore,
  };
}
