import {  useRef } from "react";

const ProPlanWrapper = ({ children, isProUser }) => {
  const wrapperRef = useRef(null);


  return <div ref={wrapperRef}>{children}</div>;
};

export default ProPlanWrapper;
