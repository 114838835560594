import React, { useState } from "react";
import { TextField, Switch, FormControlLabel, Grid } from "@material-ui/core";

interface NegativeMarkingProps {
  value: number;
  onChange: (value: number) => void;
}

const NegativeMarking: React.FC<NegativeMarkingProps> = ({ value, onChange }) => {
  const [isToggleOn, setIsToggleOn] = useState(value > 0);

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsToggleOn(event.target.checked);
    onChange(event.target.checked ? 0.25 : 0); 
  };

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(parseFloat(event.target.value));
};

  return (
    <Grid container item xs={12} alignItems="center" >
      <Grid item xs={12}>
        {!isToggleOn && <FormControlLabel
          control={<Switch checked={isToggleOn} onChange={handleToggle} />}
          label="Negative Marking"
        />
        }
      </Grid>
      {isToggleOn && (
        <Grid item xs={12}>
          <TextField
            label="Negative Marking Value"
            type="number"
            className="w-100"
            inputProps={{ step: 0.25, min: 0, max: 1 }}
            value={value}
            onChange={handleValueChange}
            fullWidth
          />
        </Grid>
      )}
    </Grid>
  );
};

export default NegativeMarking;