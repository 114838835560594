import {
  Examsonline_Questions,
  Examsonline_Tests,
  useUpdate_Test_SectionMutation,
} from "generated/graphql";
import {
  QuestionHandlers,
  useQuestionHandlers,
} from "hooks/useQuestionHandlers";
import {
  createContext,
  useContext,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  ChangeEvent,
} from "react";
import { useParams } from "react-router-dom";
import { useTestById } from "shared/hooks/useTestById";
import { useAppContext } from "./appProvider";
import { GET_TEST_BY_ID } from "queries/queries";

interface TestEditorContextType {
  index: number;
  setIndex: Dispatch<SetStateAction<number>>;
  question: any;
  setActiveQuestion: (q: any) => void; // replace 'any' with the actual type
  marks: number;
  setMarks: Dispatch<SetStateAction<number>>;
  activeSection: string;
  setActiveSection: Dispatch<SetStateAction<string>>;
  activeTab: string;
  setActiveTab: Dispatch<SetStateAction<string>>;
  srchString: string;
  setSrchString: Dispatch<SetStateAction<string>>;
  loading: boolean;
  testEditorstate: any;
  error: any;
  refetch: () => void;
  data: any;
  test: any;
  updateTestSection: any;
}

const TestEditorContext = createContext<
  TestEditorContextType & QuestionHandlers
>({
  index: 0,
  setIndex: () => {},
  question: null,
  setActiveQuestion: () => {},
  marks: 0,
  setMarks: () => {},
  activeSection: "",
  setActiveSection: () => {},
  activeTab: "",
  setActiveTab: () => {},
  srchString: "",
  setSrchString: () => "",
  loading: false,
  testEditorstate: null,
  error: null,
  refetch: () => {},
  data: null,
  test: null,
  addQuestion: () => {},
  handleScore: function (e: ChangeEvent<HTMLInputElement>): void {
    throw new Error("Function not implemented.");
  },
  goto: function (i: number): void {
    throw new Error("Function not implemented.");
  },
  onCreate: function (): void {
    throw new Error("Function not implemented.");
  },
  AddSolution: function (): void {
    throw new Error("Function not implemented.");
  },
  save: function (): void {
    throw new Error("Function not implemented.");
  },
  uploadImageCallBack: function (e: File): Promise<any> {
    throw new Error("Function not implemented.");
  },
  handleTextChange: function (editorState): void {
    throw new Error("Function not implemented.");
  },
  handleExplanationChange: function (editorState): void {
    throw new Error("Function not implemented.");
  },
  getQuestionsBySection: function (): Examsonline_Questions[] {
    throw new Error("Function not implemented.");
  },
  onBack: function (): void {
    throw new Error("Function not implemented.");
  },
  onCopy: function (): void {
    throw new Error("Function not implemented.");
  },
  handleDelete: function (id?: number | undefined): void {
    throw new Error("Function not implemented.");
  },
  handleTabSelect: function (e: ChangeEvent<HTMLInputElement>): void {
    throw new Error("Function not implemented.");
  },
  tabSelected: function (event: ChangeEvent<HTMLInputElement>): void {
    throw new Error("Function not implemented.");
  },
  getTotalMarks: function (): number {
    throw new Error("Function not implemented.");
  },
  updateTestSection: () => {},
  handleTypeChange: function (e: React.ChangeEvent): void {
    throw new Error("Function not implemented.");
  },
});

export const useTestEditorContext = () => useContext(TestEditorContext);

export const TestEditorProvider = ({ children }) => {
  const params = useParams<{ id: string }>();
  const [index, setIndex] = useState(0);
  const [question, setQuestion] = useState<any>();
  const [marks, setMarks] = useState(0);
  const [activeSection, setActiveSection] = useState("");
  const [activeTab, setActiveTab] = useState("Editor");
  const [srchString, setSrchString] = useState("");
  const { loading, testEditorstate, error, refetch, data, test } = useTestById(
    parseInt(params?.id)
  );

  const { setInEditTest, setActiveEditSection } = useAppContext();
  const [updateSection] = useUpdate_Test_SectionMutation({
    refetchQueries: [{ query: GET_TEST_BY_ID, variables: { id: test?.id } }],
  });

  const updateTestSection = (section: any) => {
    updateSection({
      variables: {
        _eq: section.id,
        name: section.name,
      },
    });
  };

  useEffect(() => {
    if (test) {
      setInEditTest(test as Examsonline_Tests);
      setActiveEditSection(activeSection);
    }
  }, [test, activeSection]);

  const setActiveQuestion = (q: any) => {
    setQuestion(q);
    setMarks(q?.marks);
    setActiveSection(q?.section);
    setActiveTab("Editor");
  };

  const questionHandlers = useQuestionHandlers(
    question,
    setActiveQuestion,
    index,
    setIndex,
    activeSection,
    setActiveSection,
    testEditorstate,
    refetch,
    setActiveTab,
    test?.questions as Examsonline_Questions[]
  );

  useEffect(() => {
    if (data) {
      setQuestion(data.examsonline_tests[0].questions[0]);
      setMarks(question?.marks);
      setActiveSection(data.examsonline_tests[0].test_sections[0].id);
      setActiveTab("Editor");

      // add first question if not exist
      if (test && test?.questions.length === 0) {
        console.log(test);
        questionHandlers.addQuestion(test?.test_sections[0].id);
      }
    }
  }, [test?.id]);

  return (
    <TestEditorContext.Provider
      value={{
        index,
        setIndex,
        question,
        setActiveQuestion,
        marks,
        setMarks,
        activeSection,
        setActiveSection,
        activeTab,
        setActiveTab,
        srchString,
        setSrchString,
        loading,
        testEditorstate,
        error,
        refetch,
        data,
        test,
        updateTestSection,
        ...questionHandlers,
      }}
    >
      {children}
    </TestEditorContext.Provider>
  );
};
