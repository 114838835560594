import bs from "bs-stepper";
import { ContentState, EditorState } from "draft-js";
import { Examsonline_Questions, Examsonline_Tests } from "generated/graphql";
import { Component } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Option } from "../../models/option.model";
import {
  optionInitialState,
  QuestionInitialState,
} from "../create-test/_store/create-test.reducer";
import { QuestionType } from "../enums/question.type.enum";

declare const document: any;

interface State {
  current: number;
  totalQuestions: any;
  questions: Examsonline_Questions[];
}

interface Props {
  testInfo: Examsonline_Tests;
  submit: Function;
}

export default class Stepper extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      current: 0,
      totalQuestions: this.props.testInfo.questions.length,
      questions: this.props.testInfo.questions,
    };
  }

  stepper: any;
  steps: any;

  componentDidMount() {
    this.steps = document.querySelector(".bs-stepper");
    this.stepper = new bs(this.steps, {
      linear: false,
      animation: false,
      selectors: {
        steps: ".step",
        trigger: ".step-trigger",
        stepper: ".bs-stepper",
      },
    });

    this.steps.addEventListener("show.bs-stepper", (event: any) => {
      // You can call prevent to stop the rendering of your step
      // event.preventDefault()
      if (!!this.state.totalQuestions && this.state.totalQuestions <= 0) {
        event.preventDefault();
      }

      this.setState({ current: event.detail.indexStep });

      console.warn(event.detail.indexStep);
    });
  }

  next = () => {
    this.stepper.next();
  };

  back = () => {
    this.stepper.previous();
  };

  submit = () => {
    this.props.submit(this.state.questions);
  };

  setQuestionType = (id: number, e: any) => {
    const updated = this.state.questions.map((q: Examsonline_Questions) => {
      if (q.id === id) {
        if (Number(e.target.value) === QuestionType.TEXT) {
          return {
            ...q,
            options: [
              { ...optionInitialState, type: QuestionType.TEXT, id: 1 },
            ],
            type: Number(e.target.value),
          };
        }

        return { ...q, type: Number(e.target.value), options: [] };
      }

      return q;
    }) as Examsonline_Questions[];

    this.setState({ questions: updated });
  };

  setOptions = (e: any, Q_id: number) => {
    const options: Option[] = [];
    for (let i = 1; i <= e.target.value; i++) {
      options.push({
        ...optionInitialState,
        id: i,
        value: EditorState.createWithContent(
          ContentState.createFromText("option " + i)
        ),
      });
    }

    const updated: Examsonline_Questions[] = this.state.questions.map((q: Examsonline_Questions) => {
      if (q.id === Q_id) {
        return { ...q, options: options };
      }
      return q;
    }) as Examsonline_Questions[];
    this.setState({ questions: updated });
  };

  setCorrect = (id: number, q_id: number, e: any) => {
    const updated = this.state.questions.map((q: Examsonline_Questions) => {
      if (q.id === q_id) {
        const options = q.options.map((o: Option) => {
          if (o.id === id) {
            return { ...o, isCorrect: e.target.checked };
          }
          return o;
        });
        return { ...q, options: options };
      }
      return q;
    }) as Examsonline_Questions[]
    console.log(updated);
    this.setState({ questions: updated });
  };

  handleTotalValueChange = (e: any) => {
    this.setState({ totalQuestions: e.target.value });
  };

  handleTotalvalueBlur = (e: any) => {
    const questions = [];
    for (let i = 0; i < e.target.value; i++) {
      questions.push(

        // @ts-ignore
        !!this.state.questions[i]
          ? this.state.questions[i]
          : { ...QuestionInitialState, id: i, options: [] }
      );
    }
    this.setState({ questions: questions });
  };
  getLableOfOptions = () => {};

  render() {
    return (
      <Row>
        <Col xs={12}>
          <div className="bs-stepper">
            <div className="bs-stepper-header" role="tablist">
              {/* <!-- your steps here --> */}
              <div className="step" data-target="#logins-part">
                <button
                  type="button"
                  className="step-trigger"
                  role="tab"
                  aria-controls="logins-part"
                  id="logins-part-trigger"
                >
                  <span className="bs-stepper-circle">1</span>
                  <span className="bs-stepper-label">Total Questions</span>
                </button>
              </div>
              <div className="line"></div>
              <div className="step" data-target="#information-part">
                <button
                  type="button"
                  className="step-trigger"
                  role="tab"
                  aria-controls="information-part"
                  id="information-part-trigger"
                >
                  <span className="bs-stepper-circle">2</span>
                  <span className="bs-stepper-label">Answer Keys</span>
                </button>
              </div>
            </div>
            <div className="bs-stepper-content">
              <div
                id="logins-part"
                className="content"
                role="tabpanel"
                aria-labelledby="logins-part-trigger"
              >
                {/* step 1 */}
                <Form>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>
                      How Many Questions are in this document
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={this.state.totalQuestions}
                      placeholder="Enter the number of questions"
                      onChange={this.handleTotalValueChange}
                      onBlur={this.handleTotalvalueBlur}
                      autoComplete="off"
                    />
                    <Form.Text className="text-muted">
                      Please make sure you enter same number of questions as in
                      the pdf
                    </Form.Text>
                  </Form.Group>

                  <Form.Row>
                    <Button
                      onClick={this.next}
                      variant="primary"
                      disabled={this.state.totalQuestions <= 0}
                    >
                      Next
                    </Button>
                  </Form.Row>
                </Form>
              </div>
              <div
                id="information-part"
                className="content"
                role="tabpanel"
                aria-labelledby="information-part-trigger"
              >
                {/* step 2 */}
                <Form>
                  {this.state.questions.map((q, index) => {
                    return (
                      <Form.Group>
                        <Form.Row className="justify-content-center text-muted">
                          Question {index + 1}
                        </Form.Row>
                        <Form.Row>
                          <Col>
                            <Form.Label>Type</Form.Label>
                            <Form.Control
                              onChange={(e: any) => {
                                this.setQuestionType(index, e);
                              }}
                              size="sm"
                              as="select"
                              value={q.type}
                            >
                              <option value={QuestionType.MCQ}>
                                Multiple Choice
                              </option>
                              <option value={QuestionType.TEXT}>
                                Text Answer
                              </option>
                            </Form.Control>{" "}
                          </Col>

                          <Col hidden={q.type === QuestionType.TEXT}>
                            <Form.Label>Max Options</Form.Label>
                            <Form.Control
                              onChange={(e) => this.setOptions(e, index)}
                              size="sm"
                              as="select"
                              value={q.options.length}
                            >
                              <option value={0}>Please Select</option>

                              <option>2</option>
                              <option>3</option>
                              <option>4</option>
                              <option>5</option>
                              <option>6</option>
                              <option>7</option>
                            </Form.Control>
                          </Col>
                          <Col
                            hidden={
                              q.type === QuestionType.TEXT ||
                              this.state.questions[index].options.length === 0
                            }
                          >
                            <Form.Label>Correct Option</Form.Label>
                            <Form.Row>
                              {this.state.questions[index].options.map((o) => {
                                return (
                                  <Form.Check
                                    onChange={(e: any) =>
                                      this.setCorrect(o.id, index, e)
                                    }
                                    label={"option " + o.id}
                                    name={o.id.toString()}
                                    checked={o.isCorrect}
                                  />
                                );
                              })}
                            </Form.Row>
                          </Col>
                        </Form.Row>
                      </Form.Group>
                    );
                  })}

                  <Form.Row className="justify-content-around">
                    <Button onClick={this.back} variant="primary">
                      Back
                    </Button>
                    <Button
                      onClick={this.submit}
                      variant="primary"
                      disabled={this.state.totalQuestions <= 0}
                    >
                      Submit
                    </Button>
                  </Form.Row>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}
