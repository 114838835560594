import { debounce } from "lodash";
import { useRef, useState } from "react";

export function useSearchString() {
  const [srchstring, setsrchstring] = useState("");
  const [value, setvalue] = useState("");

  const resetSrch = () => {
    setvalue("");
  };

  // NOTE DEBOUCNE DOSENT WORK WITHOUT USE REF
  const searchdebounce = useRef(debounce(setsrchstring, 300));

  const handleChange = (e: any) => {
    setvalue(e.target.value);
    searchdebounce.current(e.target.value);
  };

  return { srchstring, value, handleChange , resetSrch};
}
