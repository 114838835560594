import React, { useState, useEffect } from 'react';
import { TextField, Typography, Grid, IconButton } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

interface EmbedCodeGeneratorProps {
  url?: string;
}

const EmbedCodeGenerator: React.FC<EmbedCodeGeneratorProps> = ({ url }) => {
  const [embedCode, setEmbedCode] = useState('');

  useEffect(() => {
    const targetUrl = url || window.location.href;
    const htmlCode = `
      <!DOCTYPE html>
      <html>
      <head>
        <title>Page Title</title>
      </head>
      <body>
        <iframe src="${targetUrl}" width="500" height="300"></iframe>
      </body>
      </html>
    `;
    setEmbedCode(htmlCode);
  }, [url]);

  const handleCopy = () => {
    navigator.clipboard.writeText(embedCode);
    alert('Embed code copied to clipboard!');
  };

  return (
    <Grid container direction="column" spacing={2} className='my-2 bg-light'>
      <Grid item xs={12}>
        <Typography variant="h6">Embed Code</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          multiline
          rows={4}
          value={embedCode}
          InputProps={{
            readOnly: true,
          }}
          fullWidth
        />
        <IconButton className='float-right' color="primary" onClick={handleCopy}>
          <FileCopyOutlinedIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default EmbedCodeGenerator;