import { useReactiveVar } from "@apollo/client";
import { Examsonline_File } from "generated/graphql";
import { appState } from "shared/cache/appState";

export function useAppState() {
  const state = useReactiveVar(appState);

  const setLogeedIn = (val: boolean) => {
    appState({ ...appState(), loggedIn: val });
  };
  const setInEditTestId = (val: string) => {
    appState({ ...appState(), inEditTestId: val });
  };
  const setInEditSection = (val: string) => {
    appState({ ...appState(), inEditSection: val });
  };
  const setAddGroup = (val: boolean) => {
    appState({ ...appState(), showAddGroup: val });
  };
  const setAssignTestId = (val: number | null) => {
    appState({ ...appState(), assignTestId: val });
  };
  const setEditGroup = (val: string | null) => {
    appState({ ...appState(), inEditGroup: val });
  };
  const setAsgCommentId = (val: string | null) => {
    appState({ ...appState(), asg_comment_id: val });
  };

  const setFooterShow = (val: boolean) => {
    appState({ ...appState(), showFooter: val });
  };
  const setHeaderShow = (val: boolean) => {
    appState({ ...appState(), showHeader: val });
  };
  const setErrors = (error: any) => {
    appState({ ...appState(), errors: [...state.errors, error] });
  };
  const clearErrors = () => {
    appState({ ...appState(), errors: [] });
  };
  const setLargeImage = (file: Examsonline_File | null) => {
    appState({ ...appState(), largeImage: file });
  };
  const showContentPickerModal = (open: boolean) => {
    appState({ ...appState(), contentPickerModal: open });
  };
  const setShareTestId = (testId: number) => {
    appState({ ...appState(), shareTest: testId });
  };
  const showSidebar = (flag: boolean) => {
    appState({ ...appState(), showSidebar: flag });
  };


  return {
    state,
    setLogeedIn,
    setInEditTestId,
    setInEditSection,
    setAddGroup,
    setAssignTestId,
    setEditGroup,
    setAsgCommentId,
    setFooterShow,
    setHeaderShow,
    setErrors,
    clearErrors,
    setLargeImage,
    showContentPickerModal,
    setShareTestId,
    showSidebar,
  };
}
