import { Grid, IconButton } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import EmailIcon from "@material-ui/icons/Email";
import { FaGoogle } from "react-icons/fa";
import { Link } from "react-router-dom";

const SignUp = () => {
  return (
    <Grid
      container
      alignContent="center"
      alignItems="center"
      className="text-center m-auto "
    >
      <Grid item xs={12}>
        <IconButton color="primary">
          <FacebookIcon />
        </IconButton>
        <IconButton color="primary">
          <FaGoogle />
        </IconButton>
        <Link to="/signup">
          <IconButton color="primary">
            <EmailIcon />
          </IconButton>
        </Link>
      </Grid>
    </Grid>
  );
};

export default SignUp;
