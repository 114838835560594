import { useAppState } from "shared/hooks/useUpdateAppstate";
import { Examsonline_Groups, useGet_GroupsQuery } from "generated/graphql";
import Loading from "shared/components/loader/loading";
import Button from "@material-ui/core/Button";
import { Grid, LinearProgress, TextField } from "@material-ui/core";
import ErrorBanner from "component/banners/error";
import { Add } from "@material-ui/icons";
import { useState } from "react";
import usePagintaion from "shared/hooks/pagination/usePagintaion";
import GroupCard, { GroupCardColumns } from "../card/group-card";
import Formatter from "shared/translations/formatter";
import { useTranslation } from "react-i18next";
import GroupCardSquare from "../card/group-card-square";
import NoRecords from "component/banners/no-records";
interface Props {
  readonly?: any;
  limit?: number;
  contest_id?: any;
  canAdd?: boolean;
  enableSelect?: boolean;
  hideColumns?: GroupCardColumns[];
  onSelect?: (test: Examsonline_Groups) => void;
  variant?: "inline" | "square";
}
export default function GroupsGrid(props: Props) {
  const [limit] = useState(props?.limit || 5);
  const [total, settotal] = useState<number | undefined>(0);
  const { setAddGroup } = useAppState();

  const [search, setsearch] = useState("");
  const handleOnChange = (e: any) => {
    setsearch(e.target.value);
    reset();
  };

  const { pages, activePage, reset } = usePagintaion(total || 0, limit);

  const { data, loading, error } = useGet_GroupsQuery({
    variables: {
      limit: limit,
      offset: limit * (activePage - 1),
      _iregex: search,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      settotal(data?.total.aggregate?.count);
    },
  });

  const addGroup = () => {
    setAddGroup(true);
  };
  const { t } = useTranslation("common");
  const renderGroup = (group: Examsonline_Groups) => {
    if (props?.variant === "square") {
      return (
        <Grid xs={12} sm={6} lg={3} md={6} item>
          <GroupCardSquare group={group} />{" "}
        </Grid>
      );
    }

    return (
      <Grid xs={12} item>
        <GroupCard
          group={group}
          isSelectable={props.enableSelect}
          hideColumns={props?.hideColumns}
          onSelect={props?.onSelect}
          readonly={props.readonly}
          contest_id={props.contest_id}
        />
      </Grid>
    );
  };

  if (error) return <ErrorBanner></ErrorBanner>;

  return (
    <div className="w-100">
      {loading && <Loading />}
      <Grid container className="pt-lg-1 pt-md-2 mb-2 mt-3 " spacing={2}>
        <Grid
          className={props?.canAdd ? " text-center" : "d-none"}
          item
          xs={6}
          lg={3}
        >
          <Button
          data-testid="create-group"
            title={t("author.group-grid.createGroup", {})}
            onClick={addGroup}
            className=" btn-create-group "
            variant="contained"
            size="small"
          >
            <Add />{" "}
            <Formatter options={{}} text="author.group-grid.createGroup" />
          </Button>
        </Grid>
        <Grid item xs={6} lg={4} className="m-auto">
          <TextField
            fullWidth
            variant="outlined"
            onChange={handleOnChange}
            value={search}
            type="text"
            placeholder={t("author.search", {})}
            size="small"
            className="m-auto bg-white"
          />
        </Grid>

        <Grid item xs={12} lg={5} className="p-1 m-auto">
          {pages()}
        </Grid>
        {loading && <LinearProgress color="primary" />}
        <Grid container className="my-2" spacing={2}>
          {data &&
            data.examsonline_groups.map((g) => {
              return renderGroup(g as Examsonline_Groups);
            })}
          {data?.examsonline_groups.length === 0 && (
            <NoRecords message="No Groups Created" />
          )}
        </Grid>
      </Grid>
    </div>
  );
}
