import "./preview.scss";

export const Instruction = () => {
  return (
    <div className="m-4 instruction text-left">
      <u>
        <strong>General Instructions</strong>
      </u>
      <br />
      <div className="mt-2 ml-3 mb-2">
        1. &nbsp; This exam consists of multiple choice Questions, contributing
        to{" "}
        <u>
          <strong>100% of your score.</strong>
        </u>{" "}
        <br />
        2. &nbsp; Students are{" "}
        <u>
          <strong>NOT ALLOWED</strong>
        </u>{" "}
        to bring single-sided of A4 page with their hand-written. <br />
        3. &nbsp; Calculators are{" "}
        <u>
          <strong>ALLOWED</strong>
        </u>{" "}
        in the exam. <br />
        4. &nbsp; Communication devices are{" "}
        <u>
          <strong>NOT ALLOWED</strong>
        </u>{" "}
        in the exam. <br />
        5. &nbsp; Mobile phones are required to be turned off. <br />
        6. &nbsp; This exam paper is Exams Online's property and cannot be
        distributed without proper authorization.
        <br />
        7. &nbsp; There is no negative marking for this test.
      </div>
    </div>
  );
};
