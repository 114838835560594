import axios from "axios";
import { appState } from "../../shared/cache/appState";

export function axiosDefaults() {
  // set axios defaults
  axios.defaults.baseURL = import.meta.env.VITE_REACT_APP_API_URL;
  axios.defaults.headers.post["Content-Type"] =
    "application/x-www-form-urlencoded";

  // Add a request interceptor
  axios.interceptors.request.use(
    (config) => {
      appState({ ...appState(), errors: [] });
      appState({ ...appState(), loading: true });
      config.headers.Authorization = `Bearer ${window.localStorage.getItem(
        "token"
      )}`;
      return config;
    },
    function (error) {
      console.log("error request", error.response);
      // Do something with request error
      if (error.response.status === 401) {
      }
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      appState({ ...appState(), loading: false });
      return response;
    },
    (error) => {
      appState({ ...appState(), loading: false });

      if (error?.response?.status === 401) {
        window.localStorage.clear();
        if (!window.location.href.toString().includes("/login")) {
          window.location.href = "/login";
        }
      }
      // dispatch(addError(error.response));
      appState({
        ...appState(),
        errors: [...appState().errors, error.response],
      });
    }
  );
}
