import { useQuery } from '@apollo/client';
import { useState } from 'react';

import { Option } from '../../../models/option.model';
import { GET_ALL_SELECTED_OPTIONS } from '../../../queries/queries';

export function useGetAllSelectedOptions(attempt_id: string) {
  const [selectedOptions, setselectedOptions] = useState<Option[]>([]);
  
  const { loading , error } = useQuery(GET_ALL_SELECTED_OPTIONS, {
    variables: {
      _eq1: attempt_id
    },
    onCompleted: (data) => {
      setselectedOptions(data.examsonline_selected_options);
    },
    skip: !attempt_id
  });

  return { selectedOptions, loading , error };
}
