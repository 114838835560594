import { Examsonline_Questions } from "generated/graphql";
import { Col, Container, Row } from "react-bootstrap";
import { useTestById } from "shared/hooks/useTestById";
import { Helper } from "../../helper/helper";
import { Test } from "../../models/test.model";
import "./document-test.scss";
import Sample from "./pdf-example";
import Stepper from "./stepper";

interface Props {
  questions: Examsonline_Questions[];
  testInfo: Test;
  create: Function;
  addError: Function;
  history: any;
}

export default function DocumentTest(props: Props) {
  const { test } = useTestById(1);

  const onSubmit = (questions: Examsonline_Questions[]) => {
    let flag = true;

    questions.map((q: Examsonline_Questions) => {
      const validated = Helper.validaeQuestion(q);
      if (!validated.isValid) {
        // this.props.addError(
        //   `We cannot submit the test, Please mark atleast one
        //   correct option for Question ${validated.q_no + 1}`
        // );
        flag = false;
      }
      return q;
    });

    if (questions.length === 0) {
      // this.props.addError(`Please add atleast one question to this test`);
      flag = false;
    }

    if (flag) {
      // this.props.create(
      //   { ...this.props.testInfo, questions: questions },
      //   this.props
      // );
    }
  };

  // const path =
  //   "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf";

  // const onDocumentLoadSuccess = (no: any) => {
  //   setnumPages(no);
  // };

  // componentDidMount() {
  //   if (!this.props.testInfo.document) {
  //     this.props.history.goBack();
  //   }
  // }

  return (
    <Container className="doc-test-edit">
      <Row className="justify-content-center text-muted">
        <h3> Document Based Test</h3>{" "}
      </Row>
      <Row>
        <Col
          xs={12}
          md={6}
          className="doc-pdf d-flex flex-column justify-content-center"
        >
          <Sample key={test.id} file={test.document}></Sample>
        </Col>
        <Col xs={12} md={6}>
          <Stepper testInfo={test} submit={onSubmit} />
        </Col>
      </Row>
    </Container>
  );
}
