export class BaseAPI {
  public loading: boolean = false;
  private baseURL: string;

  constructor(baseURL?: string) {
    this.baseURL = baseURL || import.meta.env.VITE_REACT_APP_API_URL;
  }

  private async makeRequest<T>(
    request: () => Promise<Response>
  ): Promise<T> {
    this.loading = true;
    try {
      const response = await request();
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return await response.json();
    } finally {
      this.loading = false;
    }
  }

  protected async get<T>(url: string, config?: RequestInit): Promise<T> {
    return this.makeRequest(() =>
      fetch(`${this.baseURL}${url}`, { ...config, method: "GET" })
    );
  }

  protected async post<T>(
    url: string,
    data?: any,
    config?: RequestInit
  ): Promise<T> {
    return this.makeRequest(() =>
      fetch(`${this.baseURL}${url}`, {
        ...config,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...(config?.headers || {}),
        },
        body: JSON.stringify(data),
      })
    );
  }

  protected async put<T>(
    url: string,
    data?: any,
    config?: RequestInit
  ): Promise<T> {
    return this.makeRequest(() =>
      fetch(`${this.baseURL}${url}`, {
        ...config,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          ...(config?.headers || {}),
        },
        body: JSON.stringify(data),
      })
    );
  }

  protected async delete<T>(url: string, config?: RequestInit): Promise<T> {
    return this.makeRequest(() =>
      fetch(`${this.baseURL}${url}`, { ...config, method: "DELETE" })
    );
  }

  protected async patch<T>(
    url: string,
    data?: any,
    config?: RequestInit
  ): Promise<T> {
    return this.makeRequest(() =>
      fetch(`${this.baseURL}${url}`, {
        ...config,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          ...(config?.headers || {}),
        },
        body: JSON.stringify(data),
      })
    );
  }
}