import { useCallback, useState } from "react";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { OptionsReadonly } from "shared/components/options-readonly/option-readonly";
import { OptionsTabNext } from "component/question/edit/options-tab";
import {
  Examsonline_Questions,
  useUpdate_Question_By_IdMutation,
} from "generated/graphql";
import { Button, Grid, LinearProgress, Paper } from "@material-ui/core";
import { FileCopy, Lock, LockOpen } from "@material-ui/icons";
import { Explanation } from "./edit/explanation";
import { useCopyQuestions } from "shared/hooks/tests/useCopyQuestions";
import GenericEditor, { defaultToolbarOptions } from "component/genericEditor";
import { GET_TEST_BY_ID } from "queries/queries";

// define question mode
export enum QuestionMode {
  readonly = "readonly",
  edit = "edit",
  copy = "copy",
}

interface QuestionProps {
  question: Examsonline_Questions;
  mode: QuestionMode;
  uploadImageCallBack: any;
}

export default function Question({
  question,
  mode,
  uploadImageCallBack,
}: QuestionProps) {
  if (!question?.text) return null;

  const [editMode, setEditMode] = useState(true);
  const contentBlock = convertFromRaw(JSON.parse(question?.text || "{}"));

  const [updatedQuestion, { loading }] = useUpdate_Question_By_IdMutation({
    refetchQueries: [{ query: GET_TEST_BY_ID, variables: { id: question.id } }],
  });

  const editorState = contentBlock
    ? EditorState.createWithContent(contentBlock)
    : EditorState.createEmpty();

  const [currentEditorState, setEditorState] = useState(editorState);

  const handleEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const toggleMode = () => {
    setEditMode(!editMode);
  };

  const handleSave = async () => {
    await updatedQuestion({
      variables: {
        _eq: question.id,
        _set: {
          text: JSON.stringify(
            convertToRaw(currentEditorState.getCurrentContent())
          ),
        },
      },
    });
  };

  const { copyQuestion } = useCopyQuestions();

  const renderActionButtons = () => {
    switch (mode) {
      case "edit":
        return (
          <Button
            size="small"
            variant="text"
            onClick={toggleMode}
            className="float-right"
          >
            {editMode ? <LockOpen /> : <Lock />}
          </Button>
        );
      case "readonly":
        return (
          <Button
            size="small"
            variant="text"
            onClick={toggleMode}
            className="float-right"
          >
            {editMode ? <LockOpen /> : <Lock />}
          </Button>
        );
      case "copy":
        return (
          <Button
            size="small"
            variant="text"
            className="float-right"
            onClick={() => {
              copyQuestion(question);
            }}
          >
            <FileCopy />
          </Button>
        );
      default:
        return null;
    }
  };

  return (
    <Paper elevation={1} className=" p-2 p-lg-3">
      {renderActionButtons()}
      {loading && <LinearProgress />}
      <GenericEditor
        editorState={currentEditorState}
        onEditorStateChange={handleEditorStateChange}
        onBlur={handleSave}
        readOnly={!editMode}
      />
      {editMode ? (
        <OptionsTabNext question={question} />
      ) : (
        <OptionsReadonly options={question.options} selected={[]} />
      )}



      <Grid container className="text-left my-1">
        <Grid item xs={12} lg={10}>
          <h6>Explanation</h6>
        </Grid>
        <Explanation
          readOnly={!editMode}
          questionId={question.id}
          explanation={question.explanation}
        />
      </Grid>
    </Paper>
  );
}
