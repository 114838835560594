import { useState, useEffect } from "react";
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
} from "recharts";
import moment from "moment";
import { Typography, Grid } from "@material-ui/core";
import DateRangePicker from "./rangePicker";

interface GenericBarChartProps {
  data: any[];
  xAxisKey: string;
  barKey: string;
  title: string;
  barColor?: string;
}

const GenericBarChart = (props: GenericBarChartProps) => {

  const { data, xAxisKey, barKey, title } = props;

  const [formattedData, setFormattedData] = useState<any[]>([]);

  const [startDate, setStartDate] = useState(() => {
    const date = new Date();
    date.setMonth(date.getMonth() - 6);
    return date;
  });
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    const dateFormats = ["D/M/YYYY", "YYYY-MM-DD", "MM/DD/YYYY"]; // Add more formats as needed
    const newData = data.map((item) => {
      let parsedDate = moment(item.date, dateFormats, true);
      if (!parsedDate.isValid()) {
        parsedDate = moment(item.date); // Fallback to default parsing
      }
      return {
        ...item,
        date: parsedDate.toDate().getTime(),
      };
    });

    setFormattedData(newData);
  }, [data, startDate, endDate]);

  const handleDateRangeChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };


  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography variant="h6" component="h2" gutterBottom>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <DateRangePicker onDateRangeChange={handleDateRangeChange} />
        </Grid>
      </Grid>
      <ResponsiveContainer width="100%" height={250}>
        <BarChart
          data={formattedData}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey={xAxisKey}
            type="number"
            domain={[startDate.getTime(), endDate.getTime()]}
            scale="time"
            tickFormatter={(unixTime) => moment(unixTime).format("MMM D YYYY")}
          />
          <YAxis  />
          <Tooltip />
          <Legend />
          <Bar dataKey={barKey} fill={props.barColor || '#8884d8'} name={barKey} barSize={10}/>
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default GenericBarChart;