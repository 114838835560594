import React from "react";
import { Formik } from "formik";
import { useStudentLogin } from "hooks/useStudentLogin";
import { Col, Form, Button } from "react-bootstrap";
import * as yup from "yup";

interface Props {
  initialValues: {
    email?: string;
    sessionId?: string;
  };
  disabled?: boolean;
}

const schema = yup.object({
  email: yup
    .string()
    .required("No email or Roll No provided.")
    .min(3)
    .matches(/^[a-zA-Z0-9_.@-]+$/, "Invalid Email or Roll No"),
  sessionId: yup
    .string()
    .required("No sessionId provided.")
    .min(4, "sessionId should be 4 characters"),
});

const StudentLoginForm: React.FC<Props> = ({ initialValues , disabled}) => {
  const { login } = useStudentLogin();

  const handleSubmit = (form: any) => {
    login({ ...form, sessionId: form.sessionId });
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Control
                type="email"
                placeholder="Enter your email or Roll no"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={touched.email && !errors.email}
                isInvalid={!!errors.email}
                size="lg"
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Group controlId="formGridsessionId">
            <Form.Control
              type="text"
              placeholder="sessionId"
              name="sessionId"
              value={values.sessionId}
              onChange={handleChange}
              onBlur={handleBlur}
              isValid={touched.sessionId && !errors.sessionId}
              isInvalid={!!errors.sessionId}
              maxLength={4}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              {errors.sessionId}
            </Form.Control.Feedback>
          </Form.Group>
          <Button disabled={disabled} type="submit" className=" mt-1 mb-4 bg-primary">
            Login
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default StudentLoginForm;
