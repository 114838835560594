import { gql } from "@apollo/client";

export const OptionsFragment = gql`
  fragment OptionsFragment on examsonline_options {
    id
    value
    isCorrect
    type
  }
`;
