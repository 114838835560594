import { makeVar } from "@apollo/client";
import jwt_decode from "jwt-decode";
import { User } from "../../shared/hooks/user/useGetUserInfo";
import { UUID } from "uuid";
import { Examsonline_File } from "generated/graphql";
// shape of your local state
/** decode and save token to   app state  */
let temp: any;
try {
  temp = window.localStorage.getItem("token");
  temp = jwt_decode(temp);
} catch {}

export interface AppState {
  token: string;
  userInfo: User | null;
  showQuestionModal: boolean;
  selectedTestId: number | undefined;
  loading: boolean;
  showRatingModal: boolean;
  loggedIn: boolean;
  inEditTestId: String | null;
  inEditSection: UUID | null;
  showFooter: boolean;
  showHeader: boolean;
  showSidebar: boolean;
  showtour: boolean;
  showAddGroup: boolean;
  assignTestId: number | null;
  inEditGroup: string | null;
  asg_comment_id: string | null;
  errors: any[];
  largeImage: Examsonline_File | null;
  contentPickerModal: boolean;
  askToLogin: boolean;
  shareTest: number | null;
}

const initialState: AppState = {
  token: temp,
  userInfo: null,
  showQuestionModal: false,
  selectedTestId: 0,
  loading: false,
  showRatingModal: false,
  loggedIn: false,
  inEditTestId: null,
  inEditSection: null,
  showFooter: true,
  showHeader: true,
  showSidebar: true,
  showtour: false,
  showAddGroup: false,
  assignTestId: null,
  inEditGroup: null,
  asg_comment_id: null,
  errors: [],
  largeImage: null,
  contentPickerModal: false,
  askToLogin: false,
  shareTest: null
};

export const appState = makeVar<AppState>(initialState);
