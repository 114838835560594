import { useApolloClient, useMutation } from "@apollo/client";
import { cloneDeep } from "@apollo/client/utilities";
import { appState } from "shared/cache/appState";

import { Test } from "../../../models/test.model";
import { GET_TEST_BY_ID, UPSERT_QUESTIONS } from "../../../queries/queries";
import { useTestById } from "../useTestById";
import { useToast } from "../useToast";
import { useAppContext } from "context/appProvider";
import { QuestionType } from "component/enums/question.type.enum";

export function useCopyQuestions() {
  const [addQuestions, { loading, error }] = useMutation(UPSERT_QUESTIONS);
  const { successToast, warningToast } = useToast();
  const client = useApolloClient();
  const { inEditTest, activeEditSection } = useAppContext();

  const { test: target_test } = useTestById(Number(inEditTest?.id));

  const formatQuestion = (question: any) => {
    const { options, ...rest } = question;
    delete rest.id;
    const formattedOptions = options.map((option: any) => ({
      isCorrect: option.isCorrect,
      value: option.value,
      type: option.type || QuestionType.MCQ, // Ensure the type field is set
    }));

    return {
      ...rest,
      type: QuestionType.MCQ,
      options: {
        data: formattedOptions,
      },
      test_id: target_test?.id,
      section: activeEditSection,
      order: Number(target_test?.questions?.length) + 1,
    };
  };

  const prepareQuestions = (test: Test) => {
    return test.questions.map((q: any, index) => {
      delete q.id;
      q.options = {
        data: q.options.map((op: any) => {
          delete op.id;
          return op;
        }),
      };
      return {
        ...q,
        section: appState().inEditSection || activeEditSection,
        test_id: target_test?.id,
        order: Number(target_test?.questions?.length) + index + 1,
      };
    });
  };

  const copyTest = async (test_id: number) => {

    const { data } = await client.query({
      query: GET_TEST_BY_ID,
      variables: {
        id: test_id,
      },
      fetchPolicy: "cache-first",
    });
    const test = cloneDeep(data.examsonline_tests[0]);

    if (!target_test) {
      warningToast("no  target test specified");
      return null;
    }
    return addQuestions({
      variables: {
        objects: prepareQuestions(test),
      },
      refetchQueries: [
        {
          query: GET_TEST_BY_ID,
          variables: {
            id: target_test.id,
          },
        },
      ],
    })
      .then(() => {
        successToast("Test Questions Imported Successfully");
      })
      .catch((e) => {
        warningToast("something went wrong please try again later");
      });
  };

  const copyQuestion = async (question: any) => {
    const { options, ...rest } = question;
    delete rest.id;
    delete rest.test;
    const formattedOptions = options.map((option: any) => ({
      isCorrect: option.isCorrect,
      value: option.value,
      type: QuestionType.MCQ,
    }));

    return addQuestions({
      variables: {
        objects: [
          {
            ...rest,
            text: question.text,
            type: QuestionType.MCQ,
            options: {
              data: formattedOptions,
            },
            test_id: target_test?.id,
            section: appState().inEditSection || activeEditSection,
            order: Number(target_test?.questions?.length) + 1,
            explanation: question.explanation,
          },
        ],
      },
      refetchQueries: [
        {
          query: GET_TEST_BY_ID,
          variables: {
            id: target_test?.id,
          },
        },
      ],
    })
      .then(() => {
        successToast("Test Questions Imported Successfully");
      })
      .catch((e) => {
        warningToast("Something went wrong, please try again later");
      });
  };

  const copyAllQuestions = async (questions: any[]) => {
    const formattedQuestions = questions.map(formatQuestion);

    return addQuestions({
      variables: {
        objects: formattedQuestions,
      },
      refetchQueries: [
        {
          query: GET_TEST_BY_ID,
          variables: {
            id: target_test?.id,
          },
        },
      ],
    })
      .then(() => {
        successToast("All Test Questions Imported Successfully");
      })
      .catch((e) => {
        warningToast("Something went wrong, please try again later");
      });
  };

  return { copyTest, copyQuestion, error, loading, copyAllQuestions };
}
