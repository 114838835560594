import Axios from "axios";
import { Formik } from "formik";
import { Component } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { FaBitbucket } from "react-icons/fa";
import * as yup from "yup";
import { ProductType } from "../enums/product.enum";

export default class Recharge extends Component<any> {
  constructor(props: any) {
    super(props);
  }
  schema1 = yup.object({
    tests: yup
      .number()
      .integer("Number of tests cannot be fraction")
      .min(10)
      .required("Please enter the number of tests you want to add"),
  });
  submit(form: any) {
    Axios.post(`/payment/order`, {
      product: ProductType.PERSONAL,
      amount: form.tests * 1,
    })
      .then((res) => {
        this.props.history.push("/checkout", res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  render() {
    return (
      <Container>
        <Row>
          <Col xs={8} className="m-auto text-center">
            <h5 className="m-4">
              Add more Tests to your bucket <FaBitbucket></FaBitbucket>{" "}
            </h5>
          </Col>
          <Col xs={12} lg={6} className="m-auto">
            <Card>
              <Card.Body>
                <Formik
                  validationSchema={this.schema1}
                  onSubmit={this.submit.bind(this)}
                  initialValues={{ tests: 100 }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form id="right-box" noValidate onSubmit={handleSubmit}>
                      <label className="testTitle p-1" htmlFor="basic-url">
                        Number of Tests to add
                      </label>
                      <Form.Group controlId="formGridTestId">
                        <Form.Control
                          type="text"
                          name="tests"
                          value={values.tests}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.tests && !errors.tests}
                          isInvalid={!!errors.tests}
                          placeholder="Enter Number of Test"
                        />
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          {errors.tests}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group>
                        <label> Price in INR</label>

                        <Form.Control
                          type="number"
                          disabled
                          value={values?.tests}
                        ></Form.Control>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Col
                          sm={{ span: 12 }}
                          className="text-center startTestBtn"
                        >
                          <Button
                            type="submit"
                            variant="primary"
                            size="lg"
                            className="col-md-6 btn mr-2"
                            active
                          >
                            Goto Payment
                          </Button>
                        </Col>
                      </Form.Group>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
