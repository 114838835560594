import { useEffect } from "react";
import { useHistory } from "react-router-dom";

function useDuplicateToken() {
  const history = useHistory();

  useEffect(() => {
    if (!!sessionStorage.getItem("T_I_P")) {
      let r = window.confirm("Do you want to continue your last attempt!");
      if (r === true) {
        history.push(`/next/test/${sessionStorage.getItem("T_I_P")}`);
      } else {
        sessionStorage.removeItem("T_I_P");
      }
    }
  }, [ history]);
}

export default useDuplicateToken;
