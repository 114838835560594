import { PureComponent } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import {
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import "./result.scss";

interface Props {
  timeSpent: any[];
  height?: number;
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#fff",
          padding: "10px",
          border: "1px solid #ccc",
        }}
      >
        <p className="label">{`${label}`}</p>
        {payload.map((entry, index) => (
          <p
            key={`item-${index}`}
            style={{ color: entry.color }}
          >{`${entry.name}: ${entry.value} Secs`}</p>
        ))}
      </div>
    );
  }
  return null;
};

export default class TimeSpentChart extends PureComponent<Props> {
  render() {
    return (
      <Container fluid className="mt-3" id="third">
        <Row className="text-center  secondContainer">
          <Col md={10} sm={12} xs={12} className="alignCenter">
            <Card.Body className="p-md-5 p-0">
              <ResponsiveContainer
                width="100%"
                height={this.props?.height || 300}
              >
                <LineChart data={this.props.timeSpent}>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip content={CustomTooltip} />
                  <Legend />
                  <Line type="monotone" dataKey="timeSpent" stroke="#8884d8"  />
                  <Line
                    type="monotone"
                    dataKey="averageTimeSpent"
                    stroke="#82ca9d"
                  />
                </LineChart>
              </ResponsiveContainer>
            </Card.Body>
          </Col>
        </Row>
      </Container>
    );
  }
}
