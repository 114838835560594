import "./result.scss";

import { Fragment } from "react";

import { Result } from "../../models/result.model";
import { Test } from "../../models/test.model";
import PieChart from "./pieChart";
import SecondContainer from "./secondContainer";
import TimeSpentChart from "./time-spent.chart";
import Loading from "shared/components/loader/loading";
import NoRecords from "component/banners/no-records";
import { useHistory, useParams } from "react-router-dom";
import { AnswerSheet } from "component/submission/answersheet/answersheet";
import useFetch from "hooks/useFetchPreview";

export interface Props {
  askForRating: Function;
  attemptId?: string;
}
export interface States {
  results: Result | null;
  test: Test;
}

/** functional component */

export function ResultsNext(props: Props) {
  const params: { attemptId: string; id: string } = useParams();
  const history = useHistory();

  const { data, loading } = useFetch<Result>(
    `/result/${params.attemptId || props?.attemptId}`
  );

  const getTimeSpent = () => {
    return data?.records.map((a: any, index: number) => {
      const obj: any = {};
      obj.name = "Q." + (Number(index) + 1);
      obj.timeSpent = a.timeSpent / 1000;
      obj.tooltip = data?.test?.questions[index]?.text || "";

      // Find the average time spent for the current question
      const averageTime = data?.sessionMetadata.averageTimePerQuestion?.find(
        (b) => b.questionId == a.questionId
      )?.averageTimeSpent;

      obj.averageTimeSpent = averageTime || 0;

      return obj;
    });
  };
  if (loading) return <Loading />;
  if (!data) return <NoRecords />;

  return (
    <Fragment>
      <div>
        <PieChart test={data.test} results={data} history={history} />
        <SecondContainer data={data} history={history} />
        <TimeSpentChart

          timeSpent={getTimeSpent() || []}
        />
        {
          <AnswerSheet
            attempt={data.attempt}
            answers={data.records}
            test={data.test}
            attempt_id={params.attemptId || props?.attemptId || ""}
            studentId={data?.attempt.email || ""}
          />
        }
      </div>
    </Fragment>
  );
}
