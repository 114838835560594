import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppState } from "../useUpdateAppstate";

export function useClearErrors() {
  const { clearErrors } = useAppState();

  const location = useLocation();

  useEffect(() => {
    clearErrors();
  }, [location?.pathname]);
}
