import { useState } from "react";
import { Box, TextField, makeStyles, Grid, Typography } from "@material-ui/core";
import {
  Examsonline_Test_Sections,
  useAdd_SectionMutation,
} from "generated/graphql";
import EditorActions from "./edit/editorActions";
import { useTestEditorContext } from "context/testEditorProvider";
import { useSearchLibrary } from "./edit/useSearchLib";
import { GET_TEST_BY_ID } from "queries/queries";
import { useGenerateAIQuestionsModal } from "shared/hooks/tests/useGenerateAITest";

interface SectionProps {
  section: Examsonline_Test_Sections;
  children: React.ReactNode;
  handleChange: (section: Examsonline_Test_Sections) => void;
}

const useStyles = makeStyles({
  root: {
    border: "1px solid #ddd",
    borderRadius: "4px",
    padding: "20px",
    margin: "10px",
    position: "relative",
  },
  name: {
    position: "absolute",
    top: "-10px",
    left: "10px",
    backgroundColor: "#fff",
    padding: "2px 10px",
    borderRadius: "4px",
  },
});

export default function Section({
  section,
  children,
  handleChange,
}: SectionProps) {
  const [name, setName] = useState(section.name);
  const [isEditing, setIsEditing] = useState(false);
  const classes = useStyles();

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleBlur = () => {
    setIsEditing(false);
    handleChange({ ...section, name });
  };

  const handleNameClick = () => {
    setIsEditing(true);
  };

  const { addQuestion, activeSection, test } = useTestEditorContext();
  const { search } = useSearchLibrary();

  const [addSection] = useAdd_SectionMutation({
    variables: {
      name: "Untitled Section",
      test_id: test?.id,
    },
    refetchQueries: [{ query: GET_TEST_BY_ID, variables: { id: test?.id } }],
  });

  function renderToolbar() {
    const isActive = activeSection === section.id;
    const anySectionActive = test?.test_sections.some(
      (section) => section.id === activeSection
    );
    const hasQuestions = () => {
      return section.questions.length === 0;
    };

    const shouldShowToolbar = isActive || !anySectionActive || hasQuestions();

    const { showGenerateAIQuestionsModal } = useGenerateAIQuestionsModal();

    if (shouldShowToolbar) {
      return (
        <EditorActions
          actions={[
            {
              type: "add",
              handler: () => addQuestion(section.id),
              tooltip: "Add More Question",
            },
            {
              type: "search",
              handler: search,
              tooltip: "Search",
            },
            {
              type: "addSection",
              handler: () => addSection(),
              tooltip: "Add Section",
            },
            {
              type: "Generate",
              handler: showGenerateAIQuestionsModal,
              tooltip: "Generate",
            },
          ]}
        />
      );
    }

    return null;
  }
  return (
    <Box className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box className={classes.name}>
            {isEditing ? (
              <TextField
                variant="standard"
                value={name}
                placeholder="Section name"
                onChange={handleNameChange}
                onBlur={handleBlur}
                autoFocus
              />
            ) : (
              <Typography variant="body1" onClick={handleNameClick}>
                {name || "Section name"}
              </Typography>
            )}
          </Box>

        </Grid>
        <Grid item xs={12}>
          {children}
        </Grid>
        <Grid item xs={12}>
          {renderToolbar()}
          </Grid>
      </Grid>
    </Box>
  );
}
