import { Button, Grid, Tooltip } from "@material-ui/core";
import { useModalContext } from "context/modalProvider";
import { useCallback } from "react";
import QRCode from "qrcode.react";
import { Comments } from "shared/components/comments/comments";
import { Get_BillsDocument, useUpdate_BillMutation } from "generated/graphql";

export const usePaymentModal = () => {
  const { openModal } = useModalContext();
  const [updateBill] = useUpdate_BillMutation();

  const handleOpenModal = useCallback(
    (billing_id) => {
      const handleUpdateBill = () => {
        updateBill({
          variables: {
            id: { _eq: billing_id },
            status: "INREVIEW",
          },
          refetchQueries: [Get_BillsDocument],
        });
      };

      // disable button if comments not present on the modal

      const footer = (id) => {
        return (
          <Tooltip title="Click 'I have paid' once you upload proof of payment">
            <Button
              onClick={handleUpdateBill}
              variant="contained"
              color="secondary"
            >
              I have paid
            </Button>
          </Tooltip>
        );
      };

      const body = (
        <Grid container spacing={3} className="d-flex">
          <Grid item xs={12} className="d-flex">
            <h2 className="m-auto">Payment Details</h2>
          </Grid>
          <Grid item xs={12} className="d-flex justify-content-center">
            <p>Please scan the QR code below to make a payment:</p>
          </Grid>
          <Grid item xs={12} className="d-flex justify-content-center">
            <QRCode value="Payment URL" />
          </Grid>
          <Grid item xs={12}>
            <Comments
              placeholder="Please provide proof of your payment"
              ref_id={billing_id}
            />
          </Grid>
        </Grid>
      );

      openModal("Make a Payment", body, footer(billing_id), "md");
    },
    [openModal, updateBill]
  );

  return handleOpenModal;
};
