import { Container } from "@material-ui/core";
import { Col, Row } from "react-bootstrap";
import AppStore from "./app-store";

export default function AppStoreContainer() {
  return (
    <Container>
      <Row>
        <Col>
        <AppStore />
        </Col>
      </Row>
    </Container>
  );
}
