import { useState } from "react";
import { Alert, Row } from "react-bootstrap";
import { useAppState } from "shared/hooks/useUpdateAppstate";
import "./preview.scss";
import StudentLoginForm from "./studentLoginForm";
import { useParams } from "react-router";
import { Examsonline_Session, Examsonline_Tests } from "generated/graphql";
import { Box, Grid, Paper } from "@material-ui/core";
import useFetchPreview from "hooks/useFetchPreview";
import useDuplicateToken from "hooks/useDuplicateSession";
import { Instruction } from "./instruction";
import TestInfoCard from "component/test/components/testInfoCard";
import Loading from "shared/components/loader/loading";

export interface Props {
  onSubmit: any;
  email?: string;
  enable?: string;
  id?: string;
}

export function SessionLogin(props: Props) {
  const params: { id: string } = useParams();
  const { state } = useAppState();
  const [hasError] = useState(false);
  const { data, loading } = useFetchPreview<Examsonline_Session>(
    `sessions/${params.id}/preview`
  );
  const initialValues = {
    email: state.userInfo?.email || "",
    sessionId: params.id || props.id,
  };

  const session = data;
  const isFutureSession = new Date(session?.startTime).getTime() > Date.now();
  const isExpired = new Date(session?.endTime).getTime() < Date.now();
  const isNegativeMarking = session?.negativemarking > 0;

  useDuplicateToken();

  const handleError = () => {
    if (hasError) {
      return (
        <Row className="justify-content-center">
          <Alert variant="danger">
            Invalid Session ID, Please enter a valid session ID and try again.
          </Alert>
        </Row>
      );
    }
    return null;
  };

  return (
    <Grid container spacing={2} className="mt-4">
      {loading && <Loading />}
      {session && (
        <Box minHeight={800} width={"100%"} padding={5}>
          <Paper>
            <Grid container>
              <Grid item xs={12}>
                <TestInfoCard
                  test={session.test as Examsonline_Tests}
                  totalMarks={session.test?.questions.reduce(
                    (acc, q) => q.marks + acc,
                    0
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Instruction />
              </Grid>
              <Grid item xs={12} md={6} className="text-center">
                <Box padding={2}>
                  <StudentLoginForm
                    initialValues={initialValues}
                    disabled={isExpired || isFutureSession}
                  />
                  {isExpired && (
                    <Alert variant="warning">
                      This session has expired. Please check the session dates.
                    </Alert>
                  )}
                  {isFutureSession && (
                    <Alert variant="warning">
                      This session has not started yet. Please check the session
                      dates.
                    </Alert>
                  )}
                  {isNegativeMarking && (
                    <Alert variant="warning">
                      This session has negative marking enabled.{" "}
                      {session.negativemarking} of the marks will be deducted
                      for each wrong answer.
                    </Alert>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      )}
      <Grid item xs={12}>
        <Box padding={2}>{handleError()}</Box>
      </Grid>
    </Grid>
  );
}

export default SessionLogin;
