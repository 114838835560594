import { Grid, useTheme } from "@material-ui/core";
import { ReplayOutlined } from "@material-ui/icons";
import { useAppContext } from "context/appProvider";
import useFetch from "hooks/useFetchPreview";
import { useTable } from "hooks/useTable";
import { SessionAnalytics as SessionAnalyticsModel } from "models/analytics/sessionAnalytics.model";
import { ReactNode } from "react";
import { Button } from "react-bootstrap";
import { Tooltip } from "recharts";
import {
  PieChart,
  Pie,
  Cell,
  LineChart,
  CartesianGrid,
  Legend,
  Line,
  ResponsiveContainer,
} from "recharts";
import GenericBarChart from "./charts/generticBarChart";


import { TooltipProps } from "recharts";

const CustomTooltip = ({ active = false, payload = [], label = '' }: TooltipProps<any, any>) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`Question: ${label +1}`}</p>
        <p className="intro">{`Time Spent: ${payload[0].value} secs`}</p>
      </div>
    );
  }

  return null;
};

const SessionAnalytics = ({ sessionId }) => {
  const { data, refetch } = useFetch<
    SessionAnalyticsModel & { refresh: ReactNode }
  >(`/analytics/session/${sessionId}`);

  const context = useAppContext();

  const refreshSession = async () => {
    await context.sessionAPI.generateAnalytics(sessionId);

    refetch();
  };

  const table = useTable({
    data: data ? [data] : [],
    filterColumns: [
      "__typename",
      "id",
      "barGraphData",
      "pieChartData",
      "attempts",
      "averageTimePerQuestion",
    ],
    pagination: false,
    noRecordsMessage:
      "No Attempts yet, try sharing the session to your students",
  });

  const barGraphData = data?.barGraphData || [];
  const pieChartData = data?.pieChartData || [];

  // Use theme colors
  const theme = useTheme();
  const COLORS = [
    theme.palette.success.main,
    theme.palette.secondary.main,
    theme.palette.warning.main,
  ];

  if (!data) return null;

  data.refresh = (
    <Button onClick={() => refreshSession()}>
      <ReplayOutlined />
    </Button>
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={12} className="p-3 p-lg-5 m-1 m-lg-1">
        {table}
      </Grid>
      <Grid data-plan="pro" item xs={12} sm={6} lg={4}>
        <GenericBarChart
          data={barGraphData}
          xAxisKey="date"
          barKey="count"
          title="Attempts"
        />
      </Grid>

      <Grid
        data-plan="pro"
        item
        xs={12}
        sm={6}
        lg={4}
        className="d-flex justify-content-center "
      >
        <PieChart width={400} height={300}>
          <Pie
            data={pieChartData}
            cx={200}
            cy={150}
            labelLine={true}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
            label={({ name, percent }) =>
              `${name} ${(percent * 100).toFixed(0)}%`
            }
          >
            {pieChartData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </Grid>
      <Grid item xs={12} lg={4} sm={12}>
        <ResponsiveContainer width="100%" height={250}>
          <LineChart
            data={data.averageTimePerQuestion}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Line
              type="monotone"
              dataKey="averageTimeSpent"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </Grid>
    </Grid>
  );
};

export default SessionAnalytics;
