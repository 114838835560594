import { useCallback } from "react";
import { useModalContext } from "context/modalProvider";
import { ResultsNext } from "component/result/result";
import { Router, useHistory } from "react-router-dom";

export const useViewResultModal = (title) => {
  const { openModal } = useModalContext();
  const history = useHistory();

  const openViewPageModal = useCallback(
    (url, attemptId) => {
      if (url) {
        openModal(
          title,
          <Router history={history}>
            {" "}
            <ResultsNext attemptId={attemptId} askForRating={() => {}} />
          </Router>,
          null,
          "lg"
        );
      }
    },
    [openModal]
  );

  return {
    viewPage: openViewPageModal,
  };
};
