import "./question-accordian.scss";

import Aos from "aos";
import draftToHtml from "draftjs-to-html";

import { useEffect, useState } from "react";
import { Accordion, Button, Card, Col, Container, Row } from "react-bootstrap";

import { OptionsReadonly } from "shared/components/options-readonly/option-readonly";
import {
  Examsonline_Questions,
  Examsonline_Test_Sections,
} from "generated/graphql";
import { Paper, Tab, Tabs } from "@material-ui/core";
import NoRecords from "component/banners/no-records";

interface Props {
  questions: Examsonline_Questions[];
  sections: Examsonline_Test_Sections[];
}

export function QuestionsAccordian(props: Props) {
  const [activeSection, setactiveSection] = useState();
  const handleChange = (e, value) => {
    setactiveSection(value);
  };

  useEffect(() => {
    Aos.init();
  }, []);

  useEffect(() => {
    !!props.sections && setactiveSection(props.sections[0].id);
  }, [props?.sections?.length]);

  if (!props.questions) return <NoRecords />;

  return (
    <Container fluid>
      <Row className="mb-2">
        <Col>
          <Paper square>
            <Tabs
              value={activeSection}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
              aria-label="disabled tabs example"
              className="small"
              variant="scrollable"
              scrollButtons="auto"
            >
              {props.sections.map((e) => {
                return <Tab label={e.name} value={e.id} />;
              })}
            </Tabs>
          </Paper>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card
            data-aos="fade-up"
            data-aos-delay="50"
            data-aos-duration="500"
            data-aos-easing="ease-in-out"
            data-aos-once="true"
            className="question-accordian-container"
          >
            {props.questions
              ?.filter((s) => s.section === activeSection)
              .map((q, index: number) => {
                return (
                  <Accordion className="question-accordian">
                    <Accordion.Toggle as={Card.Header} eventKey={String(index)}>
                      <Row>
                        <Col md={2} lg={1} xs={3}>
                          <Button variant="dark">Q. {index + 1}</Button>
                        </Col>
                        <Col md={9} xs={9} className="mt-1">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: draftToHtml(JSON.parse(q?.text)),
                            }}
                          ></div>
                        </Col>
                      </Row>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={String(index)}>
                      <OptionsReadonly options={q?.options} selected={[]}></OptionsReadonly>
                    </Accordion.Collapse>
                  </Accordion>
                );
              })}
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
