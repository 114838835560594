import { Examsonline_Session } from "generated/graphql";
import { BaseAPI } from "./baseAPI";

export class SessionAPI extends BaseAPI {
  constructor(baseURL?: string) {
    super(baseURL);
  }

  async create(data: Record<string, any>) {
    return this.post(`/sessions/create`, data);
  }

  async getSessionById(sessionId: string) {
    return await this.get(`/sessions/${sessionId}`);
  }

  async getSessionsByTestId(testId: string): Promise<Examsonline_Session[]> {
    return this.get(`/sessions/test/${testId}`);
  }

  async updateSession(sessionId: string, data: Record<string, any>) {
    return this.put(`/sessions/${sessionId}`, data);
  }

  async deleteSession(sessionId: string) {
    return this.delete(`/sessions/${sessionId}`);
  }

  async generateAnalytics(sessionId: string) {
    return this.post(`/analytics/session/${sessionId}/generate`);
  }
}
