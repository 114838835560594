import { OnBoardingSteps } from "shared/hooks/useTour";

export const onboardSteps: OnBoardingSteps[] = [
  {
    element: "body",
    intro:
      "<h3> Welcome to Examscreator</h3><br/><h5>lets give you a tour</h5>",
    position: "center",
  },
  {
    element: ".btn-create-test",
    intro: "<h3>create test</h3>",
    position: "top",
  },
  {
    element: ".search-input",
    intro: "<h3>Search Test Created  By You  </h3>",
    position: "top",
  },
];
