import NoRecords from "component/banners/no-records";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSearchQuestionByTopic } from "shared/hooks/questions/useSearchQUestionByTopic";
import { useSearchTestByTopic } from "shared/hooks/tests/useSearchTestByTopic";
import Loading from "shared/components/loader/loading";
import { PaginationBasic } from "shared/components/pagination/pagination-basic";
import "./import.grid.scss";
import TestCards from "../cards/test-cards";
import { TestCardColumns } from "../cards/test-card/test-card";
import Question, { QuestionMode } from "component/question/question";

interface Props {
  srchString: string;
  srchtype: string;
}

export default function ImportTestGrid(props: Props) {
  const [limit] = useState(10);
  const [activePage, setactivePage] = useState(1);
  const {
    questions,
    loading: questionloading,
    search,
    totalcount: questTotalCount,
  } = useSearchQuestionByTopic(limit, (activePage - 1) * limit);

  useEffect(() => {
    if (props.srchtype === "Questions") {
      search(props.srchString);
    }
  }, [props, activePage, search]);

  const {
    testsByTopic,
    totalcount,
    loading: searching_test,
  } = useSearchTestByTopic(
    limit,
    (activePage - 1) * limit,
    props.srchtype === "Tests" ? props.srchString : undefined
  );

  const onPageChange = (page: number) => {
    setactivePage(page);
  };

  const onQuestionPageChange = (page: number) => {
    setactivePage(page);
    search(props.srchString);
  };

  return (
    <Container className="import-test-grid">
      {searching_test && <Loading />}
      {questionloading && <Loading />}
      <Row>
        <Col
          xs={12}
          hidden={props.srchtype !== "Tests"}
          className="text-center"
        >
          <Row className="d-flex justify-content-end">
            <PaginationBasic
              key={totalcount + activePage}
              onPageChange={onPageChange}
              total={Math.ceil(totalcount / limit)}
              active={activePage}
              className=" mx-lg-5 mx-1"
            />
          </Row>
          <TestCards
            tableData={testsByTopic}
            hideColumns={[TestCardColumns.ASSIGN, TestCardColumns.BUY]}
          />
          {testsByTopic?.length === 0 && <NoRecords />}
        </Col>

        <Col xs={12} hidden={props.srchtype !== "Questions"}>
          <Row className="d-flex justify-content-end">
            <PaginationBasic
              key={questTotalCount + activePage}
              onPageChange={onQuestionPageChange}
              total={Math.ceil(questTotalCount / limit)}
              active={activePage}
              className=" mx-lg-5 mx-1"
            />
          </Row>
          {questions &&
            questions.map((a: any) => {
              return (
                <Question
                  key={a.id}
                  question={a}
                  mode={QuestionMode.copy}
                  uploadImageCallBack={undefined}
                />
              );
            })}

          {questions.length === 0 && <NoRecords />}
        </Col>
      </Row>
    </Container>
  );
}
