import { Grid, ButtonGroup, Button } from "@material-ui/core";
import { useState, useEffect } from "react";
import { Examsonline_Session } from "generated/graphql";
import { useTable } from "hooks/useTable";
import { useParams, useHistory } from "react-router-dom";
import { useShareTest } from "hooks/useShare";
import { useCreateSession } from "hooks/useCreateSession";
import { Share } from "@material-ui/icons";
import moment from "moment";
import useSessionStatus from "hooks/useSessionStatus";
import useFetch from "hooks/useFetchPreview";

interface Props {
  limit?: number;
  testId?: number;
}

export default function SessionGrid(props: Props) {
  const [limit] = useState(props.limit || 10);
  const [searchTestSession, setSearchTestSession] = useState("");
  const [total, setTotal] = useState(10);

  const params: { id: string | undefined } = useParams();
  const history = useHistory();

  const handleOnChange = (e: any) => {
    setSearchTestSession(e.target.value);
  };
  const { data, loading } = useFetch<
    (Examsonline_Session & { metadata: { totalAttempts: number } })[]
  >(`/sessions/test/${params.id}`);

  useEffect(() => {
    data && setTotal(data.length);
  }, [data]);

  const { handleOpen } = useShareTest();
  const { ModalWithForm } = useCreateSession(
    props?.testId || parseInt(params.id || "")
  );

  const tableData = data?.map((session) => {
    const status = useSessionStatus(session.startTime, session.endTime);

    return {
      id: session.id,
      name: (
        <Button
          variant="text"
          color="primary"
          onClick={() => history.push(`/session/${session.id}`)}
        >
          {session.name}
        </Button>
      ),
      status: status().label,
      startTime: moment(session.startTime).format("LLL"),
      endTime: moment(session.endTime).format("LLL"),
      duration: moment
        .duration(moment(session.endTime).diff(moment(session.startTime)))
        .humanize(),
      attempts: session.metadata.totalAttempts,
      actions: (
        <ButtonGroup variant="text" size="small">
          <Button
            onClick={() =>
              handleOpen(
                `${import.meta.env.VITE_APP_URL}/session/login/${session?.id}`
              )
            }
            startIcon={<Share />}
          >
            {" "}
            Share
          </Button>
        </ButtonGroup>
      ),
    };
  });

  const table = useTable({
    data: tableData || [],
    filterColumns: ["__typename", "id"],
    pagination: true,
    limit, // pass the limit state variable
    total,
    toolbar: true,
    handleSearch: handleOnChange,
    heading: <ModalWithForm />,
  });

  return (
    <>
      {loading && <div>Loading...</div>}

      {table}
    </>
  );
}
