import "../cards.scss";

import { useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import { Badge, Col, Container, Row } from "react-bootstrap";
import { FaCartPlus, FaCheck, FaPencilAlt, FaTrash, FaCopy } from "react-icons/fa";
import Moment from "react-moment";
import {
  GET_TEST_BY_ID,
  MY_ORDERS,
  SEARCH_TEST_BY_TAG,
} from "../../../../queries/queries";
import Loading from "../../loader/loading";
import { useToast } from "shared/hooks/useToast";
import useDeleteTest from "shared/hooks/tests/useDeleteTest";
import {
  Add,
  BarChart,
  FileCopy,
  FileCopyRounded,
  List,
} from "@material-ui/icons";
import { Button, ButtonGroup, CardMedia, Paper } from "@material-ui/core";
import { useCopyQuestions } from "shared/hooks/tests/useCopyQuestions";
import { useHistory } from "react-router-dom";
import { Examsonline_Tests, useOrder_ItemMutation } from "generated/graphql";
import { useCreateSession } from "hooks/useCreateSession";
import { useGradient } from "hooks/useGradient";
import { Tags } from "shared/components/tags/tags";
import { useQuestionsModal } from "shared/hooks/modals/useQuestionModal";
import { Helper } from "helper/helper";
import useTestAnalytics from "component/analytics/hooks/useTestAnalytics";
import { useDuplicateTest } from "shared/hooks/tests/useDuplicateTest";
import { useApolloClient } from "@apollo/client";
import MarksInput from "component/marksInput";

export enum TestCardColumns {
  "MEDIA",
  "SELECT",
  "ACTIONS",
  "CREATEDAT",
  "STATS",
  "ASSIGN",
  "IMPORT",
  "CHANGE_STATUS",
  "BUY",
  "SHARE",
  "TOTAL",
  "MORE_INFO",
  "Name",
  "ANALYTICS",
  "NEW_SESSION",
  "DUPLICATE",
}
interface Props {
  test: Examsonline_Tests;
  edit: any;
  readonly?: any;
  hideColumns?: TestCardColumns[];
  isSelectable?: boolean;
  onSelect?: (test: Examsonline_Tests) => void;
}

/** fnctional component */
export function TestCardNext(props: Props) {
  const [loading] = useState(false);
  const history = useHistory();
  const { duplicateTest } = useDuplicateTest();

  const client = useApolloClient();

  const [order_test, { loading: ordering_test }] = useOrder_ItemMutation({
    refetchQueries: [{ query: SEARCH_TEST_BY_TAG }, { query: MY_ORDERS }],
  });

  const order = () => {
    order_test({
      variables: {
        objects: {
          amount: props.test.price,
          test_id: props.test.id,
          status: true,
        },
      },
    })
      .then(() => {
        successToast("Test has been ordered successfully");
      })
      .catch((e) => {
        console.log(e);
        dangerToast(
          "Something went wrong ordering test, please try again later"
        );
      });
  };

  const { successToast, dangerToast } = useToast();

  const { deleteTest: deleteTestMutation } = useDeleteTest(props?.test?.id);
  const editTest = () => {
    history.push(`/addtest/${props.test.id}`);
  };
  const viewTest = () => {
    history.push(`/preview/${props.test.id}`);
  };

  const { copyTest, loading: importingQuestions } = useCopyQuestions();

  const onImport = () => {
    copyTest(props.test.id);
  };

  const handleSelect = () => {
    if (props?.onSelect) {
      props.onSelect(props.test);
    }
  };

  const deleteTest = () => {
    const r = window.confirm(
      "Do you want to delete this test, once deleted all data will be lost!"
    );

    if (r === true) {
      deleteTestMutation()
        .then(() => {
          successToast("Test DeleteD Successfully");
        })
        .catch((e) => {
          dangerToast("Unable to delete test, an error ocurred");
        });
    }
  };

  const viewSessions = (e: any) => {
    history.push(`/sessions/${props.test.id}`);
    e.stopPropagation();
  };

  const handleDuplicate = async () => {
    const { data } = await client.query({
      query: GET_TEST_BY_ID,
      variables: {
        id: props.test.id,
      },
    });

    const temp = cloneDeep(data.examsonline_tests[0]);
    delete temp.attempts_aggregate;
    delete temp.id;
    delete temp.createdAt;
    delete temp.files;
    temp.pincode = "1234";

    duplicateTest(temp);
  };

  const showQuestions = useQuestionsModal(props.test);

  const { getGradient } = useGradient();

  const randomGradient = getGradient(props.test?.id);

  const shouldHide = (colName: TestCardColumns): boolean => {
    if (!props?.hideColumns) return false;

    if (props.hideColumns.includes(colName)) return true;

    return false;
  };

  if (!props?.test)
    return (
      <Col className="bg-info p-1 m-1">
        <p className="m-2 text-center ">Invalid Test</p>
      </Col>
    );

  const { createsession } = useCreateSession(props.test?.id);

  const createColumn = (colName: TestCardColumns, content, classes) => {
    return (
      <Col hidden={shouldHide(colName)} className={classes}>
        {content || colName}
      </Col>
    );
  };

  const { showModal } = useTestAnalytics();

  return (
    <Container fluid className=" my-0 my-lg-1 test-card  font-krona ">
      {(loading || importingQuestions || ordering_test) && <Loading />}

      <Paper
        style={{ width: "100%" }}
        className={
          props.test?.questions && Helper.validateExamsonlineTest(props.test)
            ? " rounded border-left-warning bg-warning"
            : " "
        }
      >
        <Row className="mx-1 mx-lg-0">
          {createColumn(
            TestCardColumns.MEDIA,
            <CardMedia
              onClick={viewTest}
              data-testid="test-card-thumbnail"
              style={{
                width: "90%",
                height: "100%",
                backgroundImage:
                  props?.test?.files?.length > 0
                    ? `url(${props.test?.files[0]?.info?.location})`
                    : randomGradient, // Random gradient background
              }}
              className="rounded"
              title="Live from space album cover"
            />,
            "m-0 p-2 d-flex justify-content-center align-items-center"
          )}
          <Col xs={12} lg={10}>
            <Row>
              {createColumn(
                TestCardColumns.Name,
                props.test.name,
                "test-name text-left m-auto"
              )}
              {createColumn(
                TestCardColumns.Name,
                <Tags tags={props.test.topics} variant={"default"} />,
                "d-flex my-auto"
              )}
              {createColumn(
                TestCardColumns.TOTAL,
                <MarksInput
                  initialMarks={props.test.points}
                  readonly
                  onMarksChange={() => {}}
                />,
                "text-left m-auto"
              )}
              <Col
                hidden={shouldHide(TestCardColumns.CREATEDAT)}
                title="Created Date"
                className=" mt-md-2"
              >
                <span className="small">
                  <Badge className=" text-black" variant="light">
                    created
                  </Badge>
                </span>{" "}
                <br />
                <Moment format="ll">{props.test.createdAt}</Moment>
              </Col>
              <Col
                title="Updated Date"
                hidden={!!props.readonly}
                className=" m-auto"
              >
                <span className="small">
                  <Badge variant="warning">updated</Badge>
                </span>
                <br />
                <Moment format="ll">{props.test.updatedAt}</Moment>
              </Col>

              <Col
                hidden={!props.readonly}
                title="Author "
                className="test-name text-left m-auto"
              >
                {props.test.author}
              </Col>

              <Col className=" d-flex mt-1 p-3 justify-content-center">
                <Button variant="outlined" size="small" onClick={showQuestions}>
                  <Badge>
                    <List />
                    <span>
                      {" "}
                      {props?.test?.questions_aggregate?.aggregate?.count}
                    </span>{" "}
                    Questions
                  </Badge>
                </Button>
              </Col>
              <Col hidden={!props.isSelectable} className="m-auto">
                <Button
                  title="Select"
                  onClick={handleSelect}
                  variant="outlined"
                  color="primary"
                >
                  Select
                </Button>
              </Col>

              {!shouldHide(TestCardColumns.IMPORT) && (
                <Col className="d-flex justify-content-center align-items-center m-auto">
                  <Button
                    className="m-auto "
                    onClick={onImport}
                    variant="outlined"
                    size="small"
                  >
                    <FileCopy /> Copy Q's
                  </Button>
                </Col>
              )}

              <Col
                hidden={shouldHide(TestCardColumns.BUY)}
                className=" text-center m-auto"
              >
                {!props.test.orders_aggregate?.aggregate?.count ? (
                  <Button
                    variant="outlined"
                    className="m-auto"
                    color="primary"
                    onClick={() => order()}
                  >
                    <FaCartPlus /> BUY {props.test.price}
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    color="secondary"
                    className="m-auto search-test-input"
                    onClick={() => viewTest()}
                  >
                    <FaCheck /> Purchased
                  </Button>
                )}
              </Col>
              {createColumn(
                TestCardColumns.ANALYTICS,
                <Button
                  variant="outlined"
                  color="primary"
                  className="m-auto"
                  size="small"
                  onClick={() => showModal(props.test.id.toString())}
                >
                  <BarChart />
                </Button>,
                "m-auto"
              )}

              {!shouldHide(TestCardColumns.NEW_SESSION) && (
                <Col
                  className="m-auto text-center d-flex"
                  style={{ gap: "10px" }}
                >
                  <ButtonGroup variant="contained" size="small" color="primary">
                    <Button
                      title={`Sessions`}
                      variant="outlined"
                      onClick={viewSessions}
                      startIcon={
                        props.test?.sessions_aggregate?.aggregate?.count || "0"
                      }
                    >
                      Sessions
                    </Button>
                    <Button title={`New Session`} onClick={createsession}>
                      <Add />
                    </Button>
                  </ButtonGroup>
                </Col>
              )}
              <Col
                className={`${
                  shouldHide(TestCardColumns.ACTIONS) || !!props.readonly
                    ? "d-none"
                    : "m-2 d-flex justify-content-around "
                }`}
              >
                <ButtonGroup variant="text" size="small">
                  <Button onClick={editTest}>
                    <FaPencilAlt size={15} />
                  </Button>
                  <Button onClick={deleteTest} color="secondary">
                    <FaTrash size={15} />
                  </Button>
                  <Button onClick={handleDuplicate}>
                    <FaCopy size={15} />
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
            <Row>
              {props.test?.questions &&
                Helper.validateExamsonlineTest(props.test)}
            </Row>
          </Col>
        </Row>
      </Paper>
    </Container>
  );
}
