import { useQuery } from "@apollo/client";
import { useState } from "react";

import { GET_RESULTS } from "../../../queries/queries";

export function useGetResults(
  test_id: number | undefined,
  fromDate: any,
  toDate: any,
  asg_id: string | null = null
) {
  const [results, setresults] = useState([]);
  const { data, loading, error } = useQuery(GET_RESULTS, {
    variables: {
      test_id: { _eq: test_id },
      _gte: fromDate,
      _eq: asg_id
    },
    skip: !test_id,
    onCompleted: (data) => {
      setresults(data.examsonline_attempt);
    },
  });

  return { results, loading, error, data };
}
