import { Grid, GridSize } from "@material-ui/core";
import draftToHtml from "draftjs-to-html";

import { Option } from "../../../../models/option.model";
import { Button } from "react-bootstrap";

const SelectOption: React.FC<{
  option: Option;
  index: number;
  onOptionClick: (op: Option) => void;
  isSelected: boolean;
  size?: GridSize;
}> = ({ option, index, onOptionClick, isSelected, size }) => {
  return (
    <Grid
      item
      onClick={() => onOptionClick(option)}
      xs={12}
      md={size || 5}
      className=" option-index rounded  border-left-primary  bg-white border  option pointer m-2 mx-auto "
    >
      <Grid
        container
        className={isSelected ? "h-100 selected border p-1" : "h-100 p-1"}
      >
        <Grid
          item
          xs={3}
          md={1}
          className="mr-md-3 d-flex flex-direction-column align-items-center"
        >
          <Button variant="primary" className="m-auto">
            {index + 1}
          </Button>
        </Grid>
        <Grid
          item
          xs={9}
          md={9}
          className="d-flex flex-direction-column align-items-center  p-3 text-left"
        >
          <div
            dangerouslySetInnerHTML={{
              __html: draftToHtml(JSON.parse(option?.value)),
            }}
          ></div>
          {}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SelectOption;
