import { useMutation } from "@apollo/client";
import { UPDATE_TEST } from "queries/queries";

export const useUpdateTest = () => {
  const [updateTestMutation, { data, loading, error }] = useMutation(UPDATE_TEST);

  const updateTest = async (test) => {
    try {
      const { data } = await updateTestMutation({
        variables: {
          _set: test,
          test_id: test.id
        }
      });
      return data;
    } catch (err) {
      console.error(err);
    }
  };

  return { updateTest, loading, error };
};