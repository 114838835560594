import { Route, Switch } from "react-router-dom";
import { Http404 } from "./lazLoadedComponents";

interface ErrorRoutesProps {}

export default function ErrorRoutes(props: ErrorRoutesProps) {
  return (
    <Switch>
      <Route path="/http404" component={Http404} />
      <Route component={Http404} />
    </Switch>
  );
}