import Slider from "@material-ui/core/Slider";
import { ResultSearchModel } from "models/results.search.model";
import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "react-tagsinput/react-tagsinput.css"; // If using WebPack and style-loader.

export interface Props {
  onsearch: (search: ResultSearchModel) => void;
  testid: number;
}

export default function TestSubmissionSearch(props: Props) {
  const [range, setrange] = useState(300);

  const handleChange = (e, value: number) => {
    setrange(value);
  };

  useEffect(() => {
    props.onsearch({
      fromdate: moment().subtract(range, "days").format("YYYY-MM-DD"),
      todate: moment().add(1, "days").format("YYYY-MM-DD"),
      enabled: true,
    });
  }, [range]);

  return (
    <Container className="p-4" id="result-search">
      <Form className="text-center " noValidate>
        <Row className=" justify-content-around ">
          <Col lg={6} md={12}>
            <p>Showing Results from last {range} days</p>
          <Slider
                
                max={360}
                value={range}
                onChange={handleChange}
                aria-labelledby="continuous-slider"
                valueLabelDisplay="auto"
                valueLabelFormat={(a)=> <div style={{fontSize:".8em"}}>{a} <br></br> days</div> }
              />
          </Col>
        </Row>
      </Form>
    </Container>
  );
}
