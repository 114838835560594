import axios from "axios";
import { read } from "fs";
import PropTypes from "prop-types";
import { Editor } from "react-draft-wysiwyg";

const uploadImageCallBack = (e: File) => {
  const formData = new FormData();
  formData.append(e.name, e);

  return axios.post("/file/image/editor", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const GenericEditor = ({
  editorState,
  onEditorStateChange,
  placeholder,
  readOnly,
  onBlur,
  toolbarOptions,
  wrapperClassName,
  editorClassName,
}) => {
  return (
    <Editor
      editorState={editorState}
      wrapperClassName={wrapperClassName}
      editorClassName={editorClassName}
      onEditorStateChange={onEditorStateChange}
      placeholder={placeholder}
      readOnly={readOnly}
      toolbar={toolbarOptions}
      toolbarHidden={readOnly}
      onBlur={onBlur}
    />
  );
};

GenericEditor.propTypes = {
  editorState: PropTypes.object.isRequired, // Editor state object
  onEditorStateChange: PropTypes.func.isRequired, // Callback to handle state change
  placeholder: PropTypes.string, // Placeholder text
  readOnly: PropTypes.bool, // Read-only mode
  onBlur: PropTypes.func, // Callback for onBlur event
  toolbarOptions: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]), // Toolbar configuration or `false` to hide
  wrapperClassName: PropTypes.string, // Class name for wrapper
  editorClassName: PropTypes.string, // Class name for editor
};
export const defaultToolbarOptions = {
  options: [
    "inline",
    "list",
    "image",
    "textAlign",
    "blockType",
    "colorPicker",
    "fontSize",
  ],
  inline: { inDropdown: false },
  list: { inDropdown: true },
  textAlign: { inDropdown: true },
  blockType: {
    inDropdown: false,
    options: ["Normal", "H1", "H2", "H3", "Code"],
  },
  image: {
    uploadCallback: uploadImageCallBack,
    alt: { present: false, mandatory: false },
    inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
    previewImage: true,
    defaultSize: {
      height: "200",
      width: "300",
    },
  },
};

GenericEditor.defaultProps = {
  placeholder: "Enter text here...",
  readOnly: false,
  onBlur: null,
  toolbarOptions: defaultToolbarOptions,
  wrapperClassName: "",
  editorClassName: "",
};

export default GenericEditor;
