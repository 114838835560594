import { useTheme } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles';

export const useGradient = () => {
  const theme = useTheme();

  // Define your gradients using theme colors
  const gradients = [
    `linear-gradient(to right, ${alpha(theme.palette.primary.main, 0.5)}, ${alpha(theme.palette.secondary.main, 0.5)})`,
    `linear-gradient(to right, ${alpha(theme.palette.error.main, 0.5)}, ${alpha(theme.palette.warning.main, 0.5)})`,
    `linear-gradient(to right, ${alpha(theme.palette.info.main, 0.5)}, ${alpha(theme.palette.success.main, 0.5)})`,
    `linear-gradient(to right, ${alpha(theme.palette.primary.light, 0.5)}, ${alpha(theme.palette.secondary.light, 0.5)})`,
    `linear-gradient(to right, ${alpha(theme.palette.error.light, 0.5)}, ${alpha(theme.palette.warning.light, 0.5)})`,
    `linear-gradient(to right, ${alpha(theme.palette.info.light, 0.5)}, ${alpha(theme.palette.success.light, 0.5)})`,
    // Add as many gradients as you like
  ];

  // Function to get a gradient based on a hash of the test ID
  const getGradient = (testId) => {
    const hash = hashCode(testId);
    const index = Math.abs(hash) % gradients.length;
    return gradients[index];
  };

  return { getGradient };
};

// Function to create a simple hash from a string
const hashCode = (num) => {
  let hash = num;
  hash = ((hash << 5) - hash) + num;
  hash = hash & hash; // Convert to 32bit integer
  hash = Math.abs(hash);
  return hash;
};