import { Examsonline_File, useMy_FilesQuery } from "generated/graphql";
import { useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Row,
} from "react-bootstrap";
import usePagintaion from "shared/hooks/pagination/usePagintaion";
import ErrorBanner from "component/banners/error";
import Loading from "shared/components/loader/loading";
import { useAppState } from "shared/hooks/useUpdateAppstate";
import { FaTrash } from "react-icons/fa";

export default function ImagesPanel(props) {
  const [total, settotal] = useState(0);
  const [limit] = useState(12);

  const { setLargeImage } = useAppState();

  const handleClick = (file: Examsonline_File) => {
    setLargeImage(file);
  };

  const { pages, activePage } = usePagintaion(total, limit);
  const { data, loading, error } = useMy_FilesQuery({
    onCompleted: (res) => {
      settotal(res?.total?.aggregate?.count || 0);
    },
    variables: {
      limit: limit,
      offset: limit * (activePage - 1),
    },
  });

  const files = data?.examsonline_file || [];

  if (error) return <ErrorBanner message="something went wrong" />;

  return (
    <Container>
      {loading && <Loading />}
      <Row>{pages()}</Row>
      <Row>
        {files.map((a: any) => {
          return (
            <Col onClick={() => handleClick(a)} className="p-2 " xs={6} md={3}>
              <Card style={{height:"100%"}} className="w-100">
                <Card.Img className="pointer"  src={a.info.location} />
                <Card.Body>
                  <Row className="text-center">
                    <Col className="m-auto">
                      <Badge variant="dark">{a.info.size / 1000} MB</Badge>
                    </Col>
                    <Col>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => console.log("Primary")}
                      >
                        <FaTrash />
                      </Button>
                    </Col>
                    <Col className="m-auto">
                      <Badge variant="dark">{a.info.size / 1000} MB</Badge>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
}
