import { Grid } from "@material-ui/core";
import { useEffect, useState } from "react";

import { Option } from "../../../../models/option.model";
import { QuestionType } from "../../../enums/question.type.enum";
import SelectOption from "./selectOption";
import TextOption from "./textOption";
import { Examsonline_Options } from "generated/graphql";
import "./options.scss";
import { useOptions } from "../../hooks/useOptions";
import { useTestContext } from "context/sessionProvider";

interface Props {
  q_id: number;
  type: QuestionType;
  onOptionsChange?: (selectedOptionIds: number[]) => void;
  options: Examsonline_Options[];
  selectedOptions: number[] ;
}

const Options: React.FC<Props> = (props) => {
  const [selectedOptionIds, setSelectedOptionIds] = useState<number[]>(
    props.selectedOptions || []
  );

  const { getTextValue, handleTextChange, updateOption } = useOptions(props);
  const { addAnswer } = useTestContext();

  const selectOptions = props.options.filter(
    (a: Option) => a.type === QuestionType.MCQ
  );
  const textOptions = props.options.filter(
    (a: Option) => a.type === QuestionType.TEXT
  );

  const handleOptionClick = (option: Option) => {
    if (selectedOptionIds.includes(option.id)) {
      setSelectedOptionIds(selectedOptionIds.filter((id) => id !== option.id));
    } else {
      setSelectedOptionIds([...selectedOptionIds, option.id]);
    }
  };

  const getIsSelected = (option: Option) => {
    return selectedOptionIds.includes(option.id);
  };

  useEffect(() => {
    props.onOptionsChange && props.onOptionsChange(selectedOptionIds);
    props.onOptionsChange && addAnswer(props.q_id, selectedOptionIds, "");
  }, [props.q_id, selectedOptionIds?.length]);

  return (
    <Grid container className="options">
      {selectOptions.map((option: Option, index: number) => (
        <SelectOption
          key={option.id}
          option={option}
          index={index}
          onOptionClick={handleOptionClick}
          isSelected={getIsSelected(option)}
        />
      ))}

      <Grid container item>
        <Grid item xs={12} lg={8} md={9} className="text-center m-2 mx-auto  ">
          {textOptions.map((option: Examsonline_Options) => (
            <TextOption
              key={option.id}
              option={option}
              getTextValue={getTextValue}
              handleTextChange={handleTextChange}
              updateOption={updateOption}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Options;
