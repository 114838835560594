import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Grid, TextField } from "@material-ui/core";
import { useGet_All_ParticipantsQuery } from "generated/graphql";
import Moment from "react-moment";
import { useState } from "react";
import usePagintaion from "shared/hooks/pagination/usePagintaion";
import Loading from "shared/components/loader/loading";
interface Props{
    contest_id?:any;
}
export default function ParticipantsGrid(props:Props) {
    const [limit] = useState(5);
    const [total, settotal] = useState<number | undefined>(0);
    const [search, setsearch] = useState("");
    const handleOnChange = (e: any) => {
        setsearch(e.target.value);
        reset();
    };

    const { pages, activePage, reset } = usePagintaion(total || 0, limit);

    const { data, loading } = useGet_All_ParticipantsQuery({
        variables: {
            limit: limit,
            offset: limit * (activePage - 1),
            _iregex: search,
            _eq:props.contest_id,
            _eq1:props.contest_id
            
        },
        fetchPolicy: "cache-and-network",
        onCompleted: () => {
            settotal(data?.total.aggregate?.count);
        }
    });


    const columns: GridColDef[] = [
        {
            field: "email",
            headerName: "Email",
            flex: 150,
            editable: false,
            renderCell: (params: GridRenderCellParams) => {
                return <p className="font-krona my-auto">{params.value}</p>;
            },
        },
        {
            field: "JoinedAt",
            headerName: "JointedAt",
            flex: 150,
            editable: false,
            renderCell: (params: GridRenderCellParams) => getjoinedDate(params),
        },
        {
            field: "SignedUp/not SignedUp",
            headerName: "SignedUp/not SignedUp",
            flex: 150,
            editable: false,
            renderCell: (params: GridRenderCellParams) => {
                return <p className="font-krona my-auto">-</p>;
            },
        },
    ];

    const getjoinedDate = (cell: any) => {
        return (
            <Moment className="text-primary" format="DD-MMM-YYYY hh:mm A">
                {cell.row.createdAt}
            </Moment>
        );
    };


    return (
        <Grid container>
            <Grid item xs={12} className="text-center" >
                <h2 className="text-center">{data?.total.aggregate?.count} Participants</h2>
            </Grid>
            {loading && <Loading />}
            <Grid item xs={12} lg={6} className="m-auto p-4">
                <TextField
                    fullWidth
                    variant="outlined"
                    onChange={handleOnChange}
                    value={search}
                    type="text"
                    placeholder="Search"
                    size="small"
                    className="m-auto bg-white"
                />
            </Grid>

            <Grid
                item
                xs={12}
                lg={6}
                className="p-1 m-auto"
                style={{ maxWidth: "100%", height: "34px" }}
            >
                {pages()}
            </Grid>
            <Grid xs={12}>
            {!!data?.examsonline_participate && data.examsonline_participate.length > 0 && (
                <DataGrid
                    autoHeight
                    rows={data.examsonline_participate}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    disableSelectionOnClick
                />
            )}
            </Grid>
        </Grid>
    );
}
