import AskChatGpt from "component/chatGpt/askChatGpt";
import { useModalContext } from "context/modalProvider";

// Custom hook to show the modal for generating AI questions
export const useGenerateAIQuestionsModal = () => {
  const { openModal } = useModalContext();

  const showGenerateAIQuestionsModal = () => {
    openModal(
      "Generate AI Questions", // Modal title
      <AskChatGpt />, // Modal content component
      null, // Modal footer component, if any
      "medium" // Modal size
    );
  };

  return { showGenerateAIQuestionsModal };
};
