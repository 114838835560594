import { gql } from "@apollo/client";
import { FileFragment } from "../file.fragment";
import { TopicsFragment } from "../topics.fragment";

export const TestPreviewFragment = gql`
  fragment TestPreviewFragment on examsonline_tests {
    id
    accesstype
    author
    createdAt
    description
    document
    emailnotify
    enabled
    errorMessage
    maxAttempts
    isDraft
    name
    randomise
    showResult
    time
    price
    files {
      ...FileFragment
    }
    test_sections {
      name
      id
      questions{
        id
      }
    }
    topics {
      ...TopicsFragment
    }
  }${FileFragment}${TopicsFragment}
`;
