import { useMutation } from "@apollo/client";
import { cloneDeep } from "lodash";
import { Option } from "../../../models/option.model";


import { ADD_SELECTED_OPTION, GET_SELECTED_OPTIONS } from "../../../queries/queries";

export function useSelectOption() {
  const [add] = useMutation(ADD_SELECTED_OPTION);

  const addSelectedOption = (option: Option, attempt_id: string) => {
    
    let temp = {...cloneDeep(option), attempt_id: attempt_id, option_id:option.id}
    delete temp.id;

    return add({
      variables: {
        object: temp,
        update_columns:['value']
      },
      refetchQueries:[
        {query: GET_SELECTED_OPTIONS,
        variables:{
          _eq:option.q_id,
          _eq1:attempt_id
        }}
      ]
    });
  };

  return { addSelectedOption };
}
