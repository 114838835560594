import { useDelete_TestMutation } from 'generated/graphql';
import { GET_TEST_BY_ID } from '../../../queries/queries';

export default function useDeleteTest(test_id: number) {


    const [deleteTest] = useDelete_TestMutation({
        variables: {
          _eq: test_id,
        },
        update(cache, { data }) {
            cache.writeQuery({
              query: GET_TEST_BY_ID,
              variables: { id: test_id },
              data: { examsonline_tests: null },
            });
          },
      });


  return { deleteTest}
}
