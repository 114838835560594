import "./author.scss";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Test } from "../../models/test.model";
import TestCards from "../../shared/components/cards/test-cards";
import Loading from "../../shared/components/loader/loading";
import ErrorBanner from "component/banners/error";
import { Examsonline_Tests_Order_By, Order_By } from "generated/graphql";
import { useGetTestsByAuthor } from "shared/hooks/author/useGetTestsByAuthor";
import usePagintaion from "shared/hooks/pagination/usePagintaion";
import moment from "moment";
import { useTour } from "shared/hooks/useTour";
import { onboardSteps } from "shared/onboarding/dashboard-steps";
import { Grid, Button } from "@material-ui/core";
import { useSearchString } from "shared/hooks/utils/useSearchString";
import { Add } from "@material-ui/icons";
import { TestCardColumns } from "shared/components/cards/test-card/test-card";
import { useTranslation } from "react-i18next";
import TableToolbar from "component/tableToolbar";
import { sortBy } from "lodash";
import { useCreateTestModal } from "hooks/useCreateTestModal";
interface Props {
  getAllTest: Function;
  getSubmissionCount: Function;
  submissions: number;
  allTests: Test[];
  history: any;
  createTestAction: Function;
  askForRating: Function;
  getBucketBalance: Function;
  bucketBalance: number;
}
/** Functional component */
export function AuthorDashboardNext(props: Props) {
  const { onBoardingSetup } = useTour();
  const { value, handleChange, srchstring } = useSearchString();
  useEffect(() => {
    onBoardingSetup(onboardSteps, {
      onComplete: () => {
        props.history.push("/addtest?tour=true");
      },
      doneLabel: "Create a test",
    });
  }, [props, onBoardingSetup]);
  const [sortyby, setsortyby] = useState<Examsonline_Tests_Order_By>({
    createdAt: Order_By.Desc,
  });
  // const [toogledSideBar] = useState(false);
  const [limit] = useState(7);
  const [offset, setoffset] = useState(0);
  const [fromdate] = useState(
    moment()
      .subtract(60, "days")
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
  );
  let { loading, data, error, totalcount } = useGetTestsByAuthor(
    "cache-and-network",
    limit,
    sortyby,
    srchstring,
    offset,
    { _gte: fromdate }
  );
  const { pages, activePage } = usePagintaion(totalcount, limit);

  const handleSort = (val: Examsonline_Tests_Order_By) => {
    setsortyby(val);
  };
  useEffect(() => {
    setoffset((activePage - 1) * limit);
  }, [activePage, limit]);

  const { t } = useTranslation("common");
  if (error)
    return (
      <Container>
        <ErrorBanner message="Something went wrong" />
      </Container>
    );

  const sortOptions: (keyof Examsonline_Tests_Order_By)[] = [
    "createdAt",
    "updatedAt",
    "name",
  ];

  const { openCreateTestModal } = useCreateTestModal();

  const createBtn = (
    <Button
      title={t("author.createTest", {})}
      onClick={openCreateTestModal}
      className=" btn-create-test"
      variant="contained"
      size="small"
      color="primary"
    >
      <Add /> New Test
    </Button>
  );

  return (
    <Container id="author" className="p-5">
      {loading && <Loading />}
      <Row>
        <Col xs={12}>
          <Grid container className="pt-lg-1 pt-md-2 mb-2 mt-3 " spacing={1}>
            <TableToolbar
              handleOnChange={handleChange}
              handleSort={handleSort}
              searchValue={value}
              sortValue={sortBy}
              title={createBtn}
              placeholder={"Search..."}
              sortOptions={sortOptions}
            />

            <TestCards
              hideColumns={[
                TestCardColumns.IMPORT,
                TestCardColumns.SELECT,
                TestCardColumns.CREATEDAT,
                TestCardColumns.BUY,
                TestCardColumns.TOTAL,
                TestCardColumns.CREATEDAT,
                TestCardColumns.CHANGE_STATUS,
                // TestCardColumns.STATS,
                TestCardColumns.MORE_INFO,
              ]}
              key={data?.examsonline_tests?.length}
              tableData={data?.examsonline_tests}
            />
          </Grid>
          <Grid item xs={12} lg={3} className="p-1 float-right">
            {pages()}
          </Grid>
        </Col>
      </Row>
    </Container>
  );
}
