import {  Typography } from "@material-ui/core";
import { useTotal_File_Size_UsedQuery } from "generated/graphql";
import { ProgressBar } from "react-bootstrap";

export default function FileSizeUsed() {
  const { data } = useTotal_File_Size_UsedQuery();
  const totalFileSizeUsedMb =
    (data?.examsonline_file_aggregate.aggregate?.sum?.size || 0) / 1000 || 0;

  return (
    <div>
      <Typography variant="h6" className="font-korona">
        Files
        <ProgressBar
          variant="success"
          now={totalFileSizeUsedMb}
          label={`${totalFileSizeUsedMb} mb used`}
        />
      </Typography>
    </div>
  );
}
