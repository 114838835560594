import { WatchQueryFetchPolicy } from "@apollo/client";
import aos from "aos";
import {
  Examsonline_Tests_Order_By,
  Order_By,
  useGet_All_Tests_By_AuthorsQuery,
  useGet_User_InfoQuery,
} from "generated/graphql";
import { Helper } from "helper/helper";
import { Roles } from "models/roles";
import { useState } from "react";

/**
 * 
 * @param policy 
 * @param limit 
 * @param sortby 
 * @param search 
 * @param offset 
 * @param createdAt 
 * @returns 
 */
export function useGetTestsByAuthor(
  policy = "cache-and-network",
  limit = 10,
  sortby: Examsonline_Tests_Order_By = { createdAt: Order_By.Desc },
  search = "",
  offset = 0,
  createdAt = {}
) {
  const [totalcount, settotalcount] = useState<number | undefined>();
  const [tests, settests] = useState<any[]>([]);

  const { data: user } = useGet_User_InfoQuery({
    fetchPolicy: "cache-first",
    variables:{
      _eq: Helper.decodeToken().username,
    }
  });

  let { loading, data, error, fetchMore, refetch } = useGet_All_Tests_By_AuthorsQuery(
    {
      variables: {
        _iregex: search,
        order_by: sortby,
        offset: offset,
        limit: limit,
        _eq: user?.examsonline_user[0].email,
        createdAt: createdAt,
      },
      skip: !user?.examsonline_user[0]?.email && user?.examsonline_user[0].role !== Roles.AUTHOR,
      fetchPolicy: policy as WatchQueryFetchPolicy,
      onCompleted: (res) => {
        settotalcount(res.examsonline_tests_aggregate.aggregate?.count);
        settests(res.examsonline_tests);
        aos.init();
      },
    }
  );

  return { tests, loading, error, data, totalcount, fetchMore, refetch };
}
