
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

interface Props {
  data: any[];
}

export default  function(props:Props) {

    return (
      <div style={{ width: "100%", height: 300 }}>
        <ResponsiveContainer>
          <BarChart
            data={props.data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis dataKey="index" padding={{ left: 2, right: 2 }} />
            <YAxis  />
            <Tooltip />
            <Legend />
            <Bar dataKey="correct" stackId="a" fill="#79cc3bd1" />
            <Bar dataKey="wrong" stackId="a" fill="#e87b7b" />
            <Bar dataKey="skipped" stackId="a" fill="#dec884" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
}
