import "./submission.scss";

import { useQuery } from "@apollo/client";
import Axios from "axios";
import moment from "moment";
import { Fragment, useState } from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";

import { ResultSearchModel } from "../../models/results.search.model";
import { GET_AVG_TIMESPENT_BY_QUES } from "../../queries/queries";
import StackedChart from "../../shared/components/charts/stacked-bar";
import { useGetResults } from "../../shared/hooks/attempt/useGetResults";
import { useGetTestQuestionStack } from "../../shared/hooks/tests/useGetTestCorrectWrongCount";
import ResultGrid from "../../shared/components/table/result-grid";
import TimeSpentChart from "../result/time-spent.chart";
import TestSubmissionSearch from "./test-submission-search";
import Loading from "../../shared/components/loader/loading";
import { useGetTopStudents } from "../../shared/hooks/result/useGetTopStudents";
import { StudentScoreCard } from "../../shared/components/cards/studentcard/student-score-card";
import { QuestionsAccordian } from "../../shared/components/questions-accordian/question-accordian";
import { useTestById } from "../../shared/hooks/useTestById";
import { Order_By } from "generated/graphql";
import { Paper } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useParams } from "react-router";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { AnswerSheetModal } from "./answersheet/answersheet-model";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: "60%",
    },
    dropdown: {
      textAlign: "center",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    button: {
      marginTop: "20px",
      textAlign: "center",
    },
    divider: {
      backgroundColor: "black",
      width: "80%",
      margin: "0 auto",
    },
    text: {
      textAlign: "center",
    },
    main: {
      paddingTop: "20px",
      paddingBottom: "20px",
    },
    modifyPaper: {
      height: "100%",
    },
  })
);
interface Props {
  history: any;
  match: any;
}

/** react functional component */

export function TestSubmissionsNext(props: Props) {
  const classes = useStyles();
  const [priority1, setpriority1] = useState("LOW");
  const [fromDate, setfromDate] = useState(moment().subtract(300, "days"));
  const [avgtimespent, setavgtimespent] = useState([]);
  const [answersheets, setanswersheets] = useState<string[]>([]);
  const [perc_order_by, setperc_order_by] = useState<Order_By>(
    Order_By.DescNullsFirst
  );
  const [correct_order_by, setcorrect_order_by] = useState<Order_By>(
    Order_By.Desc
  );

  const params: { id: string; asg_id: string } = useParams();

  const { results, loading, error } = useGetResults(
    Number(params.id),
    fromDate,
    Date.now(),
    params?.asg_id
  );

  /** get count for right wrong answers */

  const viewAnswerSheet = (id: any) => {
    if (!answersheets.includes(id)) {
      setanswersheets((answersheets) => [...answersheets, id]);
    }
  };

  const { questions } = useGetTestQuestionStack(Number(props.match.params.id));

  const { test } = useTestById(Number(props.match.params.id));

  /** get average timepent on each question */
  useQuery(GET_AVG_TIMESPENT_BY_QUES, {
    variables: {
      _eq: Number(props.match.params.id),
    },
    onCompleted: (res) => {
      setavgtimespent(
        res.examsonline_tests[0].questions.map((q: any, index: number) => {
          return {
            timeSpent: Math.round(
              q.timespents_aggregate.aggregate.avg.time / 1000
            ),
            name: "Q." + (Number(index) + 1),
          };
        })
      );
    },
  });

  const onsearch = (srch: ResultSearchModel) => {
    setfromDate(srch.fromdate);
  };

  const { students } = useGetTopStudents(
    Number(props.match.params.id),
    5,
    perc_order_by,
    correct_order_by
  );

  const downloadSubmissions = (testId: any): void => {
    // const id = e.target.getAttribute("data-id");
    Axios.get(`result/download/test/${testId}`, {
      responseType: "arraybuffer",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.xlsx");
      document.body.appendChild(link);
      link.click();
    });
  };

  const handleChange = (event) => {
    setpriority1(event.target.value);
  };

  const handleSelect = (e: any) => {
    console.log(e);
    switch (e) {
      case "top-score":
        setperc_order_by(Order_By.DescNullsLast);
        setcorrect_order_by(Order_By.Desc);
        break;
      case "weak-score":
        setperc_order_by(Order_By.Asc);
        setcorrect_order_by(Order_By.Asc);
        break;
      default:
        break;
    }
  };

  if (results.length === 0) <p>No data found</p>;

  if (error) <p>error occurred</p>;

  return (
    <Fragment>
      {loading && <Loading></Loading>}
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={2}>
            {/* <Paper className={classes.paper}>hi</Paper> */}
          </Grid>
          <Grid item xs={7}>
            {/* <Paper className={classes.paper}>there</Paper> */}
            <TestSubmissionSearch
              testid={props.match.params.id}
              onsearch={onsearch}
            ></TestSubmissionSearch>
            <ResultGrid viewAnswerSheet={viewAnswerSheet} data={results} />
            {/* <Container className="submissions"> */}
            <Container className="mt-lg-5 mb-2">
              <Tabs
                className="text-center"
                variant="pills"
                defaultActiveKey="top-score"
                id="uncontrolled-tab-example"
                onSelect={handleSelect}
              >
                <Tab eventKey="top-score" title="Top Scores">
                  {students &&
                    students.map((s) => {
                      return <StudentScoreCard data={s} />;
                    })}
                </Tab>
                <Tab eventKey="weak-score" title="Weak Scores">
                  {students &&
                    students.map((s) => {
                      return <StudentScoreCard data={s} />;
                    })}
                </Tab>
                <Tab eventKey="location " title="location">
                  Coming Soon
                </Tab>
              </Tabs>
            </Container>

            {/* answer sheets  */}

            {answersheets.length > 0 && (
              <AnswerSheetModal answersheets={answersheets} />
            )}

            {/* stacked chart */}
            <Row>
              <Col xs={12} className="text-center font-krona lead m-4">
                Question statistics
              </Col>
              <Paper className="w-100 m-2">
                <StackedChart data={questions} />
              </Paper>
            </Row>

            {/* timespent  chart */}
            <Row>
              <Paper className="w-100 m-2">
                <TimeSpentChart height={300} timeSpent={avgtimespent} />
              </Paper>
            </Row>

            {/* show questions */}

            <Container className="mb-lg-5 mb-2">
              <Row>
                <Col className="text-center lead font-krona">
                  Questions In This Test
                </Col>
              </Row>
              <Paper className="w-100 m-2">
                <QuestionsAccordian
                  questions={test?.questions}
                  sections={test?.test_sections}
                ></QuestionsAccordian>
              </Paper>
            </Container>
            {/* </Container> */}
          </Grid>
          <Grid item xs={3}>
            <Paper className={classes.modifyPaper}>
              {/* <Paper className={classes.paper}>kanji</Paper> */}
              <div className={classes.dropdown}>
                <FormControl
                  variant="outlined"
                  size="small"
                  className={classes.formControl}
                >
                  {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={priority1}
                    onChange={handleChange}
                  >
                    <MenuItem value="HIGH">ASSIGNMENT ID</MenuItem>
                    <MenuItem value="MEDIUM">ASSIGNMENT ID</MenuItem>
                    <MenuItem value="LOW">ASSIGNMENT ID</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className={classes.dropdown}>
                <FormControl
                  variant="outlined"
                  size="small"
                  className={classes.formControl}
                >
                  {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={priority1}
                    onChange={handleChange}
                  >
                    <MenuItem value="HIGH">GROUP</MenuItem>
                    <MenuItem value="MEDIUM">GROUP</MenuItem>
                    <MenuItem value="LOW">GROUP</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className={classes.main}>
                <h5 className={classes.text}>Date</h5>
                <Divider className={classes.divider} />
              </div>
              <div className={classes.dropdown}>
                <FormControl
                  variant="outlined"
                  size="small"
                  className={classes.formControl}
                >
                  {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={priority1}
                    onChange={handleChange}
                  >
                    <MenuItem value="HIGH">START DATE</MenuItem>
                    <MenuItem value="MEDIUM">START DATE</MenuItem>
                    <MenuItem value="LOW">START DATE</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className={classes.dropdown}>
                <FormControl
                  variant="outlined"
                  size="small"
                  className={classes.formControl}
                >
                  {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={priority1}
                    onChange={handleChange}
                  >
                    <MenuItem value="HIGH">END DATE</MenuItem>
                    <MenuItem value="MEDIUM">END DATE</MenuItem>
                    <MenuItem value="LOW">END DATE</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className={classes.main}>
                <h5 className={classes.text}>Report</h5>
                <Divider className={classes.divider} />
              </div>
              <div className={classes.button}>
                <Button
                  size="medium"
                  onClick={() => downloadSubmissions(props.match.params.id)}
                  variant="outlined"
                >
                  Download All
                </Button>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
}
