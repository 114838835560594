import { Grid, Paper, Typography, Button } from "@material-ui/core";
import NoRecords from "component/banners/no-records";
import draftToHtml from "draftjs-to-html";
import { useState } from "react";
import { OptionsReadonly } from "shared/components/options-readonly/option-readonly";
import { Examsonline_Questions } from "generated/graphql";

interface Props {
  questions: Examsonline_Questions[];
  onSelect: Function;
}

export default function QuizPreview(props: Props) {
  const [index, setindex] = useState(0);

  const handleSelect = (index) => {
    setindex(index);
    props.onSelect(index);
  };
  const gotoNext = () => {
    if (index < props.questions.length - 1) {
      setindex(index + 1);
    }
  };

  const onBack = () => {
    if (index > 0) {
      setindex(index - 1);
    }
  };

  if (!props?.questions || props?.questions?.length === 0) return <NoRecords />;

  return (
    <Paper>
      <Grid container>
        <Grid item xs={12} className="my-3 p-5">
          <Typography>Q No - {index + 1}</Typography>
          <Typography> Section - {props.questions[index]?.section}</Typography>
        </Grid>
        <Grid item xs={12} className="m-3 bg-white ">
          <Grid item xs={12} md={12} className="h5 text-center">
            <div
              dangerouslySetInnerHTML={{
                __html: draftToHtml(JSON.parse(props?.questions[index]?.text)),
              }}
            ></div>
          </Grid>
          <Grid container>
            <OptionsReadonly options={props.questions[index]?.options} selected={[]} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item className="d-flex justify-content-around my-1 m-lg-4">
            <Button onClick={onBack}>Back</Button>
            <Button onClick={gotoNext}>Next</Button>
          </Grid>
        </Grid>
        <Grid item>
          <Grid item>
            {props.questions.map((q, ind) => {
              return (
                <Button
                  variant={ind == index ? "contained" : "outlined"}
                  color={ind == index ? "primary" : "default"}
                  className="m-2"
                  onClick={() => handleSelect(ind)}
                >
                  {ind + 1}
                </Button>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}