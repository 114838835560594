import {
  Container,
  // CardContent,
  Divider,
  Grid,
  Paper,
  Tab,
  Tabs,
} from "@material-ui/core";
import ErrorBanner from "component/banners/error";
import { Examsonline_Tests } from "generated/graphql";
import { useState } from "react";
import { useParams } from "react-router";
import { Comments } from "shared/components/comments/comments";
import Loading from "shared/components/loader/loading";
import { AddCover } from "shared/files/add-cover";

import { Helper } from "../../helper/helper";
import { Test } from "../../models/test.model";
// import FacebookMetaTags from "../../shared/hemet/test-meta-tags";
import TestOffline from "../banners/test.offline";
// import Description from "./description";
import { Instruction } from "./instruction";
import "./preview.scss";
import { TestResult } from "./test-result";
// import Topic from "./topic";
import Sessions from "component/test/components/session/sessions";
import useFetchPreview from "hooks/useFetchPreview";
import TestInfoCard from "component/test/components/testInfoCard";

export interface Props {
  match: any;
  testInfo: Test;
}

/** functional component  */
export default function PreviewNext(props: Props) {
  const [tabindex, settabindex] = useState(0);
  const hanldeTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    settabindex(newValue);
  };

  const params: { id: string; asg_id: string; contest_id: string } =
    useParams();

  const { loading, data, error, refetch } = useFetchPreview<Examsonline_Tests>(
    `test/preview/${params.id}`
  );

  const test: any = data;

  // log event user
  Helper.sendGAEvent("STUDENT", "On Preview Page");

  const hasCover = () => {
    if (test && test) {
      return test?.files[0]?.info?.location;
    }

    return false;
  };

  const onUpload = () => {
    refetch();
  };

  if (error) return <ErrorBanner />;

  if (!test || loading) return <Loading />;

  return (
    <Container className="preview-next  bg-white ">
      <TestOffline hidden={test?.enabled} />
      <div
        style={{
          backgroundImage: hasCover() ? `url(${hasCover()})` : "",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "200px",
        }}
        className="text-primary d-flex flex-column justify-content-end cover"
      >
        <AddCover
          test_id={test?.id}
          placeholder="Add Cover Picture"
          onUpload={onUpload}
          hidden={hasCover()}
        />
      </div>
      <Divider />
      <Grid container>
        <Grid item xs={12}>
          <TestInfoCard test={test} totalMarks={10} />
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <Tabs
              scrollButtons="auto"
              indicatorColor="primary"
              variant="scrollable"
              textColor="primary"
              aria-label="disabled tabs example"
              onChange={hanldeTabChange}
              value={tabindex}
            >
              <Tab title="Sessions" label="sessions" className="overviewTab" />
              <Tab title="Rules" label="Instructions" className="RulesTab" />
              <Tab
                hidden={true}
                title="Results"
                label="Results"
                className="resultsTab"
              />
              <Tab title="Reviews" label="Reviews" className="reviewTab" />
            </Tabs>
          </Paper>
        </Grid>
        <Grid container hidden={tabindex !== 0}>
          <Sessions testId={test.id} />
        </Grid>

        <Grid hidden={tabindex !== 1}>
          <Grid style={{ fontSize: "1.2em" }}>
            <Instruction />
          </Grid>
        </Grid>
        <Grid hidden={tabindex !== 2}>
          <TestResult />
        </Grid>

        <Grid item xs={12} hidden={tabindex !== 3} className="p-2">
          <Comments test_id={props.match.params.id} enableAddComment={false} />
        </Grid>
      </Grid>
    </Container>
  );
}
