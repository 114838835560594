import { Container } from "@material-ui/core";
import { Examsonline_Options } from "generated/graphql";
import { Editor } from "react-draft-wysiwyg";

const TextOption: React.FC<{ option: Examsonline_Options, getTextValue: (op: Examsonline_Options) => any, handleTextChange: (e: any, op: Examsonline_Options) => void, updateOption: (op: Examsonline_Options) => void }> = ({ option, getTextValue, handleTextChange, updateOption }) => {
    return (
      <Container className="bg-info p-2 m-1 border" maxWidth="xl">
        <Editor
          toolbar={{
            options: [
              "inline",
              "list",
              "image",
              "textAlign",
              "blockType",
              "colorPicker",
            ],
            inline: { inDropdown: false },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            blockType: {
              inDropdown: false,
              options: ["Normal", "H1", "H2", "H3", "Code"],
            },
          }}
          editorState={getTextValue(option)}
          wrapperClassName="option-question-wrapper"
          editorClassName="option-question-editor"
          onEditorStateChange={(e) => handleTextChange(e, { ...option })}
          onBlur={() => updateOption(option)}
          placeholder={"Please type here to add your answer"}
        />
      </Container>
    );
  }

  export default TextOption;