import React, { useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import { AnswerSheet } from "component/submission/answersheet/answersheet";
import useFetch from "./useFetchPreview";
import NoRecords from "component/banners/no-records";

export function useAnswerSheet() {
  const [isOpen, setIsOpen] = useState(false);
  const [attemptId, setAttemptId] = useState("");

  const { data, error } = useFetch<any>(`/result/${attemptId}`, !attemptId);

  const toggleDrawer =
    (open: boolean, attemptId?: string) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      console.log(attemptId);
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setIsOpen(open);
      if (attemptId) setAttemptId(attemptId);
    };

  const AnswerSheetButton = ({
    attemptId,
  }: {
    studentId: string;
    attemptId: string;
  }) => (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      onClick={toggleDrawer(true, attemptId)}
    >
      Answer Sheet
    </Button>
  );

  const AnswerSheetDrawer = () => {
    if (!data || error) {
      return (
        <Drawer anchor="right" open={isOpen} onClose={toggleDrawer(false)}>
          <NoRecords />
        </Drawer>
      );
    }

    return (
      <Drawer anchor="right" open={isOpen} onClose={toggleDrawer(false)}>
        <AnswerSheet
          attempt={data.attempt}
          answers={data.records}
          test={data.test}
          attempt_id={attemptId}
          studentId={data?.email || ""}
        />
      </Drawer>
    );
  };

  return { AnswerSheetButton, AnswerSheetDrawer };
}
