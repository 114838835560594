import { toast } from "react-toastify";

export function useToast(delay = 3000) {
  const successToast = (msg: string) => {
    toast.success(msg, {
      position: "bottom-right",
      autoClose: delay,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const warningToast = (msg: string) => {
    toast.warning(msg, {
      position: "bottom-right",
      autoClose: delay,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const dangerToast = (msg: string) => {
    toast.error(msg, {
      position: "bottom-right",
      autoClose: delay,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const infoToast = (msg: string) => {
    toast.info(msg, {
      position: "top-center",
      autoClose: delay,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  return { successToast, warningToast, dangerToast, infoToast };
}
