import { useAdd_CommentMutation } from "generated/graphql";
import { useState } from "react";
import { useGetComments } from "shared/hooks/useComments";
import Loading from "shared/components/loader/loading";
import ViewComment from "./view-comment";
import { Container, Grid, TextField, Button, CircularProgress } from "@material-ui/core";

interface Props {
  ticket_id?: string;
  test_id?: number;
  asg_id?: string;
  ref_id?: string;
  placeholder?: string;
  enableAddComment?: boolean;
}

export function Comments(props: Props) {
  const [limit, setlimit] = useState(10);
  const [offset] = useState(0);
  const { comments, refetch, loading: loadingcomments, total } = useGetComments(offset, limit, props.test_id, props.ticket_id, props.ref_id);
  const [comment, setcomment] = useState("");
  const [addCommentMutation, { loading }] = useAdd_CommentMutation({
    variables: {
      objects: {
        ref_id: props.ref_id || "",
        ticket_id: props?.ticket_id,
        test_id: Number(props?.test_id),
        asg_id: props?.asg_id,
        value: comment,
      },
    },
    onCompleted: () => {
      refetch();
    },
  });

  const loadMore = () => {
    setlimit(limit + 10);
  };

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter" && !e.shiftKey) {
      addCommentMutation();
      setcomment("");
    }
  };

  const handleChange = (e: any) => {
    setcomment(e.target.value);
  };

  return (
    <Container>
      {loading && <Loading />}
      <Grid container justify="center" >
        <Grid item xs={12} sm={8} md={6}>
          <TextField
          hidden={!props.enableAddComment}
            value={comment}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            multiline
            rows={3}
            fullWidth
            label="Comments"
            variant="outlined"
            placeholder={props.placeholder || "Type your comment here"}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} className="text-center" spacing={5}>

      {comments.map((a) => {
        return <ViewComment key={a.id} comment={a}></ViewComment>;
      })}
      </Grid>
      <Grid item hidden={!!total && total < limit}>
        {total > 0 && (
          <Button
            fullWidth
            variant="outlined"
            onClick={loadMore}
          >
            Load More ({total - limit}){" "}
            {loadingcomments && <CircularProgress size={24} />}
          </Button>
        )}
      </Grid>
    </Container>
  );
}