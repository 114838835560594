import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Helper } from "helper/helper";
import Axios from "axios";

interface LoginParams {
  email: string;
  sessionId: string;
  testid: number;
  asg_id: string;
  contest_id?: string;
  onloginSuccess?: any;
}

interface LoginResult {
  hasError: boolean;
  login: (params: LoginParams) => void;
  token: string | null;
}

export function useStudentLogin(): LoginResult {
  const [hasError, setHasError] = useState(false);
  const history = useHistory();

  const login = (params: LoginParams) => {
    // event start test
    Helper.sendGAEvent("STUDENT", "start test");

    setHasError(false);
    Axios.post("/auth/session/login", {
      ...params,
    })
      .then((response) => {
        if (response.status === 201) {
          console.log("Login successfull");
          const token = response.data["sessionToken"];
          window.sessionStorage.setItem("sessionToken", token);
          sessionStorage.setItem("T_I_P", params.sessionId.toString());
          toast.success("Access Code verified, Now you can start the test!", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          history.push(`/next/test/${params.sessionId}`);

          params.onloginSuccess?.();
        }
      })
      .catch((error) => {
        console.log(error)
        setHasError(true);
        toast.warning("Access Code verification failed", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };


  return {
    hasError,
    login,
    token: sessionStorage.getItem("sessionToken")
  };
}
