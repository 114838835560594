import { Examsonline_Session, Maybe } from "generated/graphql";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "react-tagsinput/react-tagsinput.css";
import AllowListTable from "./allowListTable";
import NegativeMarking from "./negativeMarking";
import { useConfirmModal } from "hooks/useConfirmModal";
import moment from "moment";
import { useAppContext } from "context/appProvider";
import { useTestById } from "shared/hooks/useTestById";

interface Props {
  testId: number | undefined;
  onSuccess: (id: string) => void;
  initialSession?: Maybe<Examsonline_Session>;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius,
  },
}));

export default function CreateTestSessionForm(props: Props): JSX.Element {
  const confirm = useConfirmModal();
  const context = useAppContext();

  const { test } = useTestById(props.testId);
  const classes = useStyles();
  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const formik = useFormik({
    initialValues: {
      name: props.initialSession?.name || "",
      startTime: props.initialSession?.startTime || formatDate(new Date()),
      endTime: props.initialSession?.endTime || formatDate(tomorrow),
      allowList: props.initialSession?.allowList || [],
      negativemarking: props.initialSession?.negativemarking || 0,
      showResults: props.initialSession?.showResults,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Session name is required"),
      startTime: Yup.date().required("Start date is required"),
      endTime: Yup.date().required("End date is required"),
      allowList: Yup.array(),
      negativemarking: Yup.number()
        .min(0, "Negative marking must be at least 0")
        .max(1, "Negative marking can't be more than 1")
        .required("Negative marking is required"),
      showResults: Yup.boolean(),
    }),
    onSubmit: async (values, { resetForm }) => {
      await confirm(
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>{values.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Start Date</TableCell>
                <TableCell>
                  {moment(values.startTime).format("DD MMMM YYYY, hh:mm A")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>End Date</TableCell>
                <TableCell>
                  {moment(values.endTime).format("DD MMMM YYYY, hh:mm A")}
                </TableCell>{" "}
              </TableRow>
              <TableRow>
                <TableCell>Negative Marking</TableCell>
                <TableCell>{values.negativemarking ? "yes" : "no"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Allow List</TableCell>
                <TableCell>
                  <List>
                    {values.allowList.map((item, index) => (
                      <ListItem key={index}>{item}</ListItem>
                    ))}
                  </List>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      );
      let data;
      if (props.initialSession) {
        data = await context.sessionAPI.updateSession(
          props.initialSession.id,
          values
        );
      } else {
        data = await context.sessionAPI.create({
          ...values,
          test,
          sessionrecords: [],
        });
      }
      resetForm();
      props.onSuccess(data.session.id);
    },
  });

  if (props.testId === 0) return <></>;
  return (
    <Paper className={classes.paper}>
      <form className="d-flex m-2 m-md-3 m-lg-3" onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Session Name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container alignContent="center">
              <Grid item xs={6}>
                <NegativeMarking
                  value={formik.values.negativemarking}
                  onChange={(value) =>
                    formik.setFieldValue("negativemarking", value)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formik.values.showResults || false}
                      onChange={formik.handleChange}
                      name="showResults"
                      color="primary"
                    />
                  }
                  label="Show Results"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="startTime"
              value={formik.values?.startTime}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="startTime"
              label="Starts At"
              error={
                formik.touched.startTime && Boolean(formik.errors.startTime)
              }
              helperText={formik.touched.startTime && formik.errors.startTime}
              type="datetime-local"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="endTime"
              value={formik.values.endTime}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="endTime"
              label="Ends At"
              error={formik.touched.endTime && Boolean(formik.errors.endTime)}
              helperText={formik.touched.endTime && formik.errors.endTime}
              type="datetime-local"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={6} justifyContent="center" className="d-flex m-auto">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className="m-auto"
            >
              {props.initialSession ? "Update" : "Create"}
            </Button>
          </Grid>
          <Grid item xs={12} spacing={2}>
            <AllowListTable
              initialAllowList={formik.values?.allowList || []}
              onAllowListChange={(val) =>
                formik.handleChange({
                  target: { name: "allowList", value: val },
                })
              }
            />
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
}
