import { BaseAPI } from "./baseAPI";

export class CommonAPI extends BaseAPI {
  private currentSearchParams: any = {};

  constructor(baseURL?: string) {
    super(baseURL);
  }

  async getById(id: string, type: string) {
    return this.get(`/common/item/${id}/${type}`);
  }

  async postById(data: any) {
    return this.post(`/common/item`, data);
  }

  async putById(id: string, type: string, data: any) {
    return this.put(`/common/item/${id}/${type}`, data);
  }

  async deleteById(id: string, type: string) {
    return this.delete(`/common/item/${id}/${type}`);
  }

  async searchByTagsAndType(tags: string, type: string) {
    const params = new URLSearchParams({
      tags,
      type,
    });
    return this.get(`/common/search?${params.toString()}`);
  }

  async queryItems(
    attributeName: string,
    attributeValue: string,
    limit?: number | undefined,
    exclusiveStartKey?: string
  ) {
    const params = new URLSearchParams({
      ...this.currentSearchParams,
      attributeName,
      attributeValue,
      limit: limit?.toString() || "10",
    });
    return this.get(`/common/query?${params.toString()}`);
  }

  async findAllByType(type: string) {
    return this.get(`/common/items/${type}`);
  }
}
