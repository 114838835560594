import { useTranslation } from "react-i18next";

interface Props {
  text: string;
  options: any;
}

export default function Formatter(props: Props) {
  const { t } = useTranslation("common");
  return <span>{t(props.text, props.options)}</span>;
}
