import { Box, Typography } from "@material-ui/core";
import "./banner.scss";

interface Props {
  message?: string;
}

export default function NoRecords({ message }: Props) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" p={5} width="100%" className="banner-no-records">
      <Typography variant="h5" color="textSecondary">
        {message ?? "No Records To Display"}
      </Typography>
    </Box>
  );
}