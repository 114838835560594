import { useState } from "react";
import { Grid, Slider } from "@material-ui/core";

const DateRangePicker = ({ onDateRangeChange }) => {
  const [value, setValue] = useState(1); // Default to 6 months

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
    const endDate = new Date();
    let startDate = new Date();
    switch (newValue) {
      case 0:
        startDate.setMonth(endDate.getMonth() - 1);
        break;
      case 1:
        startDate.setMonth(endDate.getMonth() - 3);
        break;
      case 2:
        startDate.setMonth(endDate.getMonth() - 6);
        break;
      case 3:
        startDate.setFullYear(endDate.getFullYear() - 1);
        break;
      default:
        startDate.setMonth(endDate.getMonth() - 6);
    }
    onDateRangeChange(startDate, endDate);
  };

  return (
    <Grid container spacing={1} alignItems="center" className="px-2">
      <Grid item xs={12}>
        <Slider
          value={value}
          onChange={handleSliderChange}
          step={1}
          marks={[
            { value: 0, label: "1 Month" },
            { value: 1, label: "3 Months" },
            { value: 2, label: "6 Months" },
            { value: 3, label: "1 Year" },
          ]}
          min={0}
          max={3}
        />
      </Grid>
    </Grid>
  );
};

export default DateRangePicker;
