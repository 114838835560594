import moment from 'moment';
import Status from 'component/status';

const useSessionStatus = (startTime: string, endTime: string) => {
  return () => {
    const now = moment();
    const start = moment(startTime);
    const end = moment(endTime);

    if (now.isBetween(start, end)) {
      return { status: 'Active', label: <Status status="Active" /> };
    } else if (now.isBefore(start)) {
      return { status: 'Future', label: <Status status="Active" /> };
    } else {
      return { status: 'Expired', label: <Status status="Finished" />};
    }

    return { status: 'Expired', label: <Status status="Finished" />};
  }

};

export default useSessionStatus;