import { onError } from "@apollo/client/link/error";
import { GraphQLError } from "graphql";
import { appState } from "../../../shared/cache/appState";

export function LogoutLink() {
  const askLogin = () => {
    appState({ ...appState(), askToLogin: true });
  };

  const logoutLink = onError(({ graphQLErrors, networkError }) => {
    graphQLErrors?.map((e: GraphQLError) => {
      askLogin();
    });
    console.log("network-error", networkError);
  });

  return { logoutLink };
}
