import { CommonAPI } from "./common.API";

export class TestAPI extends CommonAPI {
  constructor(baseURL?: string) {
    super(baseURL);
  }

  get isLoading(): boolean {
    return this.loading;
  }

  async getTestById(id: string) {
    return this.getById(id, "TEST");
  }

  async saveTest(data: Record<string, any>) {
    return this.postById(data);
  }

  async updateTest(id: string, data: Record<string, any>) {
    return this.putById(id, "TEST", data);
  }

  async deleteTest(id: string) {
    return this.deleteById(id, "TEST");
  }

  async searchTestsByTags(tags: string) {
    return this.searchByTagsAndType(tags, "TEST");
  }

}
