import { Row, Form, Col, Container, Button } from "react-bootstrap";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import * as yup from "yup";
import { Formik } from "formik";
import { useAdd_QuizMutation } from "generated/graphql";
import { useAppState } from "shared/hooks/useUpdateAppstate";
import { useEffect, useState } from "react";
import _ from "lodash";
import { TextField } from "@material-ui/core";
import { GET_QUIZ_BY_ID } from "queries/queries";
import { useHistory } from "react-router";

interface Props {
  quiz: any;
}

export default function EditQuizForm(props: Props) {
  
  const history = useHistory()

  const [addQuizMutation] = useAdd_QuizMutation({
    refetchQueries:[
      {
        query: GET_QUIZ_BY_ID
      }
    ],
    onCompleted:(data)=>{

      history.push(`/quiz/edit/${data.insert_examsonline_quiz?.returning[0].id}`)
    }
  });

  const { state } = useAppState();
  const [inittiastate, setinittiastate] = useState({
    name: " ",
    time: 15,
    topics: [],
    isSecured: false,
  });

  useEffect(() => {
    if (!!props.quiz) {
      setinittiastate(props.quiz);
    }
  }, [props]);

  const schema = yup.object({
    name: yup.string().required("Quiz name is required"),
    time: yup
      .number()
      .required("completion time is required")
      .integer(" should be an integer number")
      .min(1, " time cannot be less than 1 minute"),
    topics: yup.array().required("please enter atleast one topic"),
    isSecured: yup.boolean(),
  });

  const handleSubmit = (values: any) => {
    addQuizMutation({
      variables: {
        objects: [
          {
            name: values.name,
            accesstype: 0,
            description: "test",
            errorMessage: "",
            time: 15,
            author: state.userInfo?.email,
            test_sections: {
              data: [{ name: "default" }],
            },
            topics: {
              data: values.topics
                .map((t: any) => (!!t?.tag ? t : { tag: t }))
                .map((t: any) => _.omit(t, "__typename")),
            },
          },
        ],
        update_columns: [],
      },
    });
  };

  return (
    <Container>
      <Formik
        enableReinitialize
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={inittiastate}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
          setFieldValue,
        }) => (
          <Form noValidate>
            <Row>
              <Col lg={4} xs={12} className="m-auto">
                <TextField
                  className="bg-white"
                  value={values.name}
                  name="name"
                  typeof="text"
                  id="outlined-basic"
                  label="Name"
                  size="small"
                  fullWidth
                  variant="outlined"
                  placeholder="Enter test name"
                  onChange={handleChange}
                  helperText={errors.name}
                />
              </Col>
              <Col lg={4} xs={12}>
                <Form.Group controlId="topics">
                  <Form.Label>Topics related to this test</Form.Label>
                  <TagsInput
                    inputProps={{
                      placeholder: "eg maths, science",
                    }}
                    value={values?.topics.map((t: any) => {
                      if (typeof t !== "string") {
                        return t?.tag;
                      }
                      return t;
                    })}
                    onChange={(e) =>
                      setFieldValue(
                        "topics",
                        e.map((sr) => sr.toLowerCase())
                      )
                    }
                    addOnBlur={true}
                  />
                  <Form.Control
                    autoComplete="off"
                    hidden={true}
                    name="topics"
                    value={values.topics}
                    isValid={Boolean(touched.topics && !errors.topics)}
                    isInvalid={!!errors.topics}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.topics}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col lg={4} xs={12} className="m-auto ">
                <TextField
                  className="bg-white"
                  value={values.time}
                  name="time"
                  typeof="text"
                  id="outlined-basic"
                  label="time"
                  size="small"
                  fullWidth
                  variant="outlined"
                  error={!!errors?.time}
                  placeholder="Enter test time"
                  onChange={handleChange}
                  helperText={errors.time}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6} lg={4} className="clearfix m-auto text-center">
                <Button
                  className="w-50"
                  block
                  variant="outline-dark"
                  size="sm"
                  type="button"
                  onClick={() => handleSubmit(values)}
                >
                  Save
                </Button>
              </Col>
              <Col lg={4} xs={12}>
                <Form.Group
                  className="mb-3 text-center font-krona"
                  controlId="validationFormik04"
                >
                  <Form.Label>
                    Do you want to Secure Quiz with Pincode ?
                  </Form.Label>
                  <Form.Check
                    title="change the status of this test "
                    type="switch"
                    name="isSecured"
                    value={values.isSecured}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col hidden={!Boolean(values.isSecured)}>
                <TextField
                  className="bg-white"
                  value={values.pincode}
                  name="pincode"
                  typeof="text"
                  id="outlined-basic"
                  label="pincode"
                  size="small"
                  fullWidth
                  variant="outlined"
                  error={!!errors?.pincode}
                  placeholder="Enter test pincode"
                  onChange={handleChange}
                  helperText={errors.pincode}
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  );
}
