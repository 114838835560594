import { useEffect, useState } from "react";
import { Container, Grid } from "@material-ui/core";
import { NetworkError } from "../../shared/components/errors/network-error";
import { useTestById } from "../../shared/hooks/useTestById";
import Loading from "shared/components/loader/loading";
import jwtDecode from "jwt-decode";
import LeftPanel from "./components/panels/leftPanel";
import CenterPanel from "./components/panels/centerPanel";
import "./test.scss";
import { useAppState } from "shared/hooks/useUpdateAppstate";
import HeaderPanel from "./components/panels/headerPanel";
import SessionProvider, { useTestContext } from "context/sessionProvider";
import { Examsonline_Questions, Examsonline_Tests } from "generated/graphql";

function NextTest(props: any) {
  const [token] = useState<any>(
    jwtDecode(window.sessionStorage.getItem("sessionToken") as string)
  );

  const { test, loading, error, refetch } = useTestById(parseInt(token.testId));

  const { showSidebar, setHeaderShow } = useAppState();
  const { setActiveQuestion, setActiveSection, setTest, back } =
    useTestContext();

  useEffect(() => {
    showSidebar(false);
    setHeaderShow(false);
    if (test) {
      setActiveSection(test?.test_sections[0].id);
      setActiveQuestion(test?.questions[0] as Examsonline_Questions);
      setTest(test as Examsonline_Tests);
    }

    return () => {
      showSidebar(true);
      setHeaderShow(true);
    };
  }, [test?.id]);

  if (error) return <NetworkError onclick={refetch} />;
  if (loading) return <Loading />;
  if (!window.sessionStorage.getItem("sessionToken"))
    return <p>Your token has expired , Please login again </p>;

  return (
    <>
      <HeaderPanel
        token={token}
        onTimeup={() => props.history.push(`/result/${token.sessionId}`)}
      />
      <Container maxWidth="xl" className="next-test-container  p-0">
        <Grid container xs={12}>
          <CenterPanel onBack={back} />
          <LeftPanel onBack={back} history={props.history} token={token} />
        </Grid>
      </Container>
    </>
  );
}

export default function WithTestContext(props: any) {
  return (
    <SessionProvider>
      <NextTest {...props} />
    </SessionProvider>
  );
}
