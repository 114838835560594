import { useMutation } from '@apollo/client';
import { QuestionType } from '../../../component/enums/question.type.enum';

import { ADD_ANSWERS } from '../../../queries/queries';

export interface AnswerModel {
  attempt_id: number;
  isCorrect?: boolean;
  q_id: number;
  score?: number;
  type:QuestionType;
}

export function useAddAnswer() {
  const [add] = useMutation(ADD_ANSWERS);

  const addAnswer = (answer: AnswerModel) => {
      if(!!answer){
        return add({
            variables: {
              objects: [answer],
              update_columns: ["attempt_id", "q_id"],
            },
          });
      } 
      return ;

  };

  return { addAnswer };
}
