import { useQuery, WatchQueryFetchPolicy } from "@apollo/client";
import { useState } from "react";
import { Helper } from "../../../helper/helper";
import { Option } from "../../../models/option.model";
import { GET_OPTIONS } from "../../../queries/queries";

export function useGetOptions(
  q_id: number | undefined,
  fetchPolicy = "cache-and-network"
) {
  const [options, setoptions] = useState<Option[]>([]);
  const [optionsEditorState, setoptionsEditorState] = useState<any[]>([]);

  const { loading, error, refetch } = useQuery(GET_OPTIONS, {
    variables: { q_id: q_id },
    fetchPolicy: fetchPolicy as WatchQueryFetchPolicy,
    skip: !q_id,
    onCompleted: (data) => {
      setoptions(data.examsonline_options);
      setoptionsEditorState(
        Helper.contentOptionsToEditorState(data.examsonline_options)
      );
    },
  });

  return { options, loading, error, refetch, optionsEditorState };
}
