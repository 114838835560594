import { Examsonline_Questions } from "generated/graphql";
import { Answer } from "models/answer.model";
import { useEffect, useRef, useState } from "react";

export const useReportTimesSpent = (
  activeQuestion: Examsonline_Questions | undefined,
  answers: Answer[]
) => {
  const [timeTaken, setTimeTaken] = useState(0);
  const [timeSpentMs, setTimeSpentMs] = useState<{ [key: string]: number }>({});
  const prevQuestion = usePrevious(activeQuestion?.id);

  useEffect(() => {
    if (!activeQuestion) return;

    const interval = setInterval(() => {
      setTimeTaken((prev) => prev + 1000);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [activeQuestion?.id]);

  useEffect(() => {
    if (prevQuestion) {
      const previousTimeSpent = timeSpentMs[prevQuestion] || 0;
      const updatedTimeSpentMs = {
        ...timeSpentMs,
        [prevQuestion]: previousTimeSpent + timeTaken,
      };
      setTimeSpentMs(updatedTimeSpentMs);

      answers.forEach((answer) => {
        if (answer.questionId === prevQuestion) {
          answer.timeSpent = previousTimeSpent + timeTaken;
        }
      });
    }
    setTimeTaken(0);
  }, [activeQuestion?.id]);

  useEffect(() => {
    if (activeQuestion) {
      const currentTimeSpent = timeSpentMs[activeQuestion.id] || 0;
      answers.forEach((answer) => {
        if (answer.questionId === activeQuestion.id) {
          answer.timeSpent = currentTimeSpent;
        }
      });
    }
  }, [answers, activeQuestion, timeSpentMs]);
};

// Helper hook to get the previous value of a prop or state
const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
