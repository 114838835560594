import { Grid, Typography } from "@material-ui/core";
import { TestCardColumns } from "shared/components/cards/test-card/test-card";
import TestGrid from "shared/grids/test-grid";
import Formatter from "shared/translations/formatter";
import ContestGrid from "component/contest/grid/contest-grid";
import { ContestCardColumns } from "component/contest/card/contest-card";
import GroupsGrid from "component/groups/grid/groups-grid";

export enum AppStoreColumns {
  "TESTGRID",
  "CONTESTGRID",
  "GROUPSGRID",
}

interface Props {
  hideColumns?: AppStoreColumns[];
}

export default function AppStore(props: Props) {
  const shouldHide = (gridName: AppStoreColumns): boolean => {
    if (!props?.hideColumns) return false;

    if (props.hideColumns.includes(gridName)) return true;

    return false;
  };

  return (
    <Grid container className="my-4">
      <Grid item xs={12} hidden={shouldHide(AppStoreColumns.TESTGRID)}>
        <Typography variant="h4" component="h2">
          <Formatter options={{}} text="student.student-library.findTest" />
        </Typography>
        <TestGrid
          hideColumns={[
            TestCardColumns.ACTIONS,
            TestCardColumns.CREATEDAT,
            TestCardColumns.STATS,
            TestCardColumns.CHANGE_STATUS,
            TestCardColumns.IMPORT,
            TestCardColumns.ASSIGN,
          ]}
        />
      </Grid>
      <Grid
        item
        xs={12}
        className="my-1 my-lg-3"
        hidden={shouldHide(AppStoreColumns.CONTESTGRID)}
      >
        <Typography variant="h4" component="h2">
          <Formatter options={{}} text="Find Contest" />
        </Typography>
        <ContestGrid
          canCreate={false}
          hideColumns={[ContestCardColumns.EDITTEST]}
        />
      </Grid>
      <Grid
        xs={12}
        className="mb-5"
        hidden={shouldHide(AppStoreColumns.GROUPSGRID)}
      >
        <Typography variant="h4" component="h2" className="mt-5">
          <Formatter options={{}} text="student.student-library.joinGroup" />
        </Typography>
        <GroupsGrid limit={8} variant="square" />
      </Grid>
    </Grid>
  );
}
