import { useMutation } from '@apollo/client';
import { cloneDeep } from 'lodash';

import {  DELETE_OPTIONS_BY_QID, GET_OPTIONS } from '../../../queries/queries';

export function useDeleteOptionsByQuesId(q_id: number) {
  const [deleteOpts, { loading, error }] = useMutation(DELETE_OPTIONS_BY_QID,
    {
        update(cache, { data }) {
          const existing: any = cache.readQuery({
            query: GET_OPTIONS,
            variables: { q_id: q_id },
          });
          console.log(existing)
          const updated = (cloneDeep(existing).examsonline_options =
            existing.examsonline_options.filter(
              (t: any) => data.delete_examsonline_options.returning.includes(t.id)
            ));
          cache.writeQuery({
            query: GET_OPTIONS,
            variables: { q_id: q_id },
            data: { examsonline_options: updated },
          });
        },
      });

  const deleteOptions = (Q_id: number) => {
    return deleteOpts({
      variables: {
        _eq: Q_id
      }
    },);
  };

  return { deleteOptions, error, loading };
}
