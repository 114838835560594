import Axios from "axios";
import _ from "lodash";
import { PureComponent } from "react";
import { Button, Card, Col, Container, Row, Table } from "react-bootstrap";
import { FaCheck, FaTimes } from "react-icons/fa";
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { Result } from "../../models/result.model";
import "./result.scss";
import { Examsonline_Tests } from "generated/graphql";

export interface Props {
  history: any;
  results: Result;
  test: Examsonline_Tests;
}
export interface States {
  data: any;
}

const COLORS = ["#14213d", "#00C49F", "#dec884"];

class pieChart extends PureComponent<Props, States> {
  constructor(props: any) {
    super(props);

    this.state = {
      data: [],
    };
  }

  download = (id: number) => {
    // const id = e.target.getAttribute("data-id");
    Axios.get(`result/download/${id}`, { responseType: "arraybuffer" }).then(
      (response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.xlsx");
        document.body.appendChild(link);
        link.click();
      }
    );
  };

  componentDidMount() {
    this.setState({
      data: [
        { name: "Wrong", value: this.props.results.attempt.metadata.incorrect },
        { name: "Correct", value: this.props.results.attempt.metadata.correct },
        {
          name: "Skipped",
          value: this.props.results.attempt.metadata.skipped,
        },
      ],
    });
  }

  formatLegend = (str: string) => {
    if (str === "Correct") {
      return (
        <p>
          {" "}
          <FaCheck></FaCheck> {this.props.results.attempt.metadata.correct}
        </p>
      );
    }
    if (str === "Skipped") {
      return <p> {this.props.results.attempt.metadata.skipped} </p>;
    }
    if (str === "Wrong") {
      return (
        <p>
          {" "}
          <FaTimes></FaTimes> {this.props.results.attempt.metadata.incorrect}
        </p>
      );
    }

    return;
  };

  render() {
    return (
      <Container fluid="md" className="mt-3" id="first">
        <Row className="text-center secondContainer font-weight-bold">
          <Col xs={12} md={5} className="alignCenter">
            <Row>
              <Table className="small" striped bordered hover>
                <thead>
                  <tr>
                    <th colSpan={4}>Summary</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={4} className="lead text-mute">
                      Test Name - <b>{this.props.test.name}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>Student</td>
                    <td className="text-primary">
                      {this.props.results.records[0].studentId}
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Percentage / Percentile</td>
                    <td className="text-danger">
                      {this.props.results.attempt.metadata.percentage?.toFixed(
                        2
                      )}{" "}
                      % / NA
                    </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>Score</td>
                    <td className="text-danger">
                      {this.props.results.attempt.metadata.score} /
                      {this.props.results.attempt.metadata.total}
                    </td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>Download</td>
                    <td className="text-danger">
                      <Button
                        size="sm"
                        onClick={() => {
                          this.download(
                            this.props.results.records[0].attemptId
                          );
                        }}
                      >
                        .xlsx
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>Negative Marking</td>
                    <td className="text-danger">
                      {this.props.results.attempt?.metadata.hasNegativeMarking
                        ? "Yes"
                        : "No"}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Row>
          </Col>
          <Col xs={12} md={5}>
            <Card>
              <Card.Body className="p-1">
                <ResponsiveContainer width="102%" height={270}>
                  <PieChart className="t">
                    <Pie
                      data={this.state.data}
                      innerRadius={50}
                      outerRadius={80}
                      fill="#106eea"
                      dataKey="value"
                      legendType="square"
                    >
                      {this.state.data.map((entry: any, index: any) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend
                      formatter={(a, b) => <p>{this.formatLegend(a)}</p>}
                    ></Legend>
                  </PieChart>
                </ResponsiveContainer>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default pieChart;
