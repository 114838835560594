import { Grid, Typography } from "@material-ui/core";
import BillingTable from "component/billing/billingTable";

export default function Bill() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4" component="h2" gutterBottom>
          Bills
        </Typography>
      </Grid>
      <BillingTable />
    </Grid>
  );
}
