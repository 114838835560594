import "./footer.scss";

import { useAppState } from "shared/hooks/useUpdateAppstate";
import { Badge } from "react-bootstrap";
import Formatter from "shared/translations/formatter";

export function Footer() {
  const { state } = useAppState();

  return (
    <>
      <div hidden={!state.showFooter} className="app-footer">
        <div className="container py-4">
          <div className="copyright">
            &copy;
            <Formatter options={{}} text={"footer1.copyright"} />{" "}
            <strong>
              <span>ExamsCreator</span>
            </strong>
            . <Formatter options={{}} text={"footer1.rightReserved"} />
          </div>
          <div className="text-right text-dark mx-2">
            <Formatter options={{}} text={"footer1.version"} /> :{" "}
            <Badge variant="info">
              {import.meta.env.VITE_REACT_APP_VERSION}
            </Badge>
          </div>
        </div>
      </div>
    </>
  );
}
