import { gql } from "@apollo/client";

export const CommentFragment = gql`
  fragment CommentFragment on examsonline_comments {
    value
    user
    id
    ticket_id
    role
    updatedAt
    createdAt
  }
`;
