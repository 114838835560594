import { Formik } from "formik";
import TagsInput from "react-tagsinput";
import { testValidationSchema } from "./helper";

import { Col, Form, Row } from "react-bootstrap";
import { Grid } from "@material-ui/core";
import NextBackButtons from "component/Nav/NextBackButtons";

export const TestForm = ({ handleSubmit, onBack, test, nextbtnRef }) => {
  return (
    <Formik
      enableReinitialize
      validationSchema={testValidationSchema}
      onSubmit={handleSubmit}
      initialValues={{ ...test, accesstype: test?.accesstype.toString() }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => (
        <Form className="text-center" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={3} className="text-center pb-3">
            <Grid item xs={12} lg={6} className="m-auto">
              <Grid container spacing={1}>
                <Col className="m-auto" xs={12} lg={9}>
                  <Row>
                    <Form.Group as={Col} xs={12} controlId="testname">
                      <Form.Label>Test Name</Form.Label>
                      <Form.Control
                        autoComplete="off"
                        type="text"
                        name="name"
                        placeholder="Enter test name"
                        value={values.name}
                        onChange={handleChange}
                        isValid={Boolean(touched.name && !errors.name)}
                        isInvalid={!!errors.name}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group
                      className="text-left"
                      as={Col}
                      xs={12}
                      controlId="topics"
                    >
                      <Form.Label>Topics related to this test</Form.Label>
                      <TagsInput
                        inputProps={{
                          placeholder: "eg maths, science",
                        }}
                        value={values?.topics.map((t: any) => {
                          if (typeof t !== "string") {
                            return t?.tag;
                          }
                          return t;
                        })}
                        onChange={(e) =>
                          setFieldValue(
                            "topics",
                            e.map((sr) => sr.toLowerCase())
                          )
                        }
                        addOnBlur={true}
                      />
                      <Form.Control
                        autoComplete="off"
                        onBlur={handleBlur}
                        hidden={true}
                        name="topics"
                        onChange={() => {}}
                        value={values.topics}
                        isValid={Boolean(touched.topics && !errors.topics)}
                        isInvalid={!!errors.topics}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.topics}
                      </Form.Control.Feedback>
                      <Form.Control.Feedback type="valid">
                        Topics help understand what is test is about
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      md="12"
                      xs={12}
                      controlId="description"
                    >
                      <Form.Label>Test Description</Form.Label>
                      <Form.Control
                        onBlur={handleBlur}
                        autoComplete="off"
                        as="textarea"
                        type="text"
                        name="description"
                        placeholder="Few words about this test"
                        value={values.description}
                        onChange={handleChange}
                        isValid={Boolean(
                          touched.description && !errors.description
                        )}
                        isInvalid={!!errors.description}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {errors.description}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} xs={12} controlId="time">
                      <Form.Label>Time (in minutes)</Form.Label>
                      <Form.Control
                        autoComplete="off"
                        type="number"
                        name="time"
                        value={values.time}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.time && !errors.time}
                        isInvalid={!!errors.time}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.time}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                </Col>
              </Grid>

              <Grid container spacing={3}>
                <NextBackButtons onBack={onBack} nextButtonRef={nextbtnRef} />
              </Grid>
            </Grid>
            <Grid item xs={12} className="p-3"></Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
