import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  date: { input: any; output: any; }
  json: { input: any; output: any; }
  jsonb: { input: any; output: any; }
  numeric: { input: any; output: any; }
  timestamp: { input: any; output: any; }
  timestamptz: { input: any; output: any; }
  uuid: { input: any; output: any; }
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "_enumtable.country" */
export type _Enumtable_Country = {
  __typename?: '_enumtable_country';
  code?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

/** aggregated selection of "_enumtable.country" */
export type _Enumtable_Country_Aggregate = {
  __typename?: '_enumtable_country_aggregate';
  aggregate?: Maybe<_Enumtable_Country_Aggregate_Fields>;
  nodes: Array<_Enumtable_Country>;
};

/** aggregate fields of "_enumtable.country" */
export type _Enumtable_Country_Aggregate_Fields = {
  __typename?: '_enumtable_country_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<_Enumtable_Country_Max_Fields>;
  min?: Maybe<_Enumtable_Country_Min_Fields>;
};


/** aggregate fields of "_enumtable.country" */
export type _Enumtable_Country_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<_Enumtable_Country_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "_enumtable.country". All fields are combined with a logical 'AND'. */
export type _Enumtable_Country_Bool_Exp = {
  _and?: InputMaybe<Array<_Enumtable_Country_Bool_Exp>>;
  _not?: InputMaybe<_Enumtable_Country_Bool_Exp>;
  _or?: InputMaybe<Array<_Enumtable_Country_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "_enumtable.country" */
export enum _Enumtable_Country_Constraint {
  /** unique or primary key constraint on columns "name" */
  CountryPkey = 'country_pkey'
}

/** input type for inserting data into table "_enumtable.country" */
export type _Enumtable_Country_Insert_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type _Enumtable_Country_Max_Fields = {
  __typename?: '_enumtable_country_max_fields';
  code?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type _Enumtable_Country_Min_Fields = {
  __typename?: '_enumtable_country_min_fields';
  code?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "_enumtable.country" */
export type _Enumtable_Country_Mutation_Response = {
  __typename?: '_enumtable_country_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<_Enumtable_Country>;
};

/** on_conflict condition type for table "_enumtable.country" */
export type _Enumtable_Country_On_Conflict = {
  constraint: _Enumtable_Country_Constraint;
  update_columns?: Array<_Enumtable_Country_Update_Column>;
  where?: InputMaybe<_Enumtable_Country_Bool_Exp>;
};

/** Ordering options when selecting data from "_enumtable.country". */
export type _Enumtable_Country_Order_By = {
  code?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: _enumtable.country */
export type _Enumtable_Country_Pk_Columns_Input = {
  name: Scalars['String']['input'];
};

/** select columns of table "_enumtable.country" */
export enum _Enumtable_Country_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "_enumtable.country" */
export type _Enumtable_Country_Set_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "_enumtable_country" */
export type _Enumtable_Country_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: _Enumtable_Country_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type _Enumtable_Country_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "_enumtable.country" */
export enum _Enumtable_Country_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Name = 'name'
}

export type _Enumtable_Country_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<_Enumtable_Country_Set_Input>;
  /** filter the rows which have to be updated */
  where: _Enumtable_Country_Bool_Exp;
};

/** columns and relationships of "_enumtable.priority" */
export type _Enumtable_Priority = {
  __typename?: '_enumtable_priority';
  comment?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

/** aggregated selection of "_enumtable.priority" */
export type _Enumtable_Priority_Aggregate = {
  __typename?: '_enumtable_priority_aggregate';
  aggregate?: Maybe<_Enumtable_Priority_Aggregate_Fields>;
  nodes: Array<_Enumtable_Priority>;
};

/** aggregate fields of "_enumtable.priority" */
export type _Enumtable_Priority_Aggregate_Fields = {
  __typename?: '_enumtable_priority_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<_Enumtable_Priority_Max_Fields>;
  min?: Maybe<_Enumtable_Priority_Min_Fields>;
};


/** aggregate fields of "_enumtable.priority" */
export type _Enumtable_Priority_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<_Enumtable_Priority_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "_enumtable.priority". All fields are combined with a logical 'AND'. */
export type _Enumtable_Priority_Bool_Exp = {
  _and?: InputMaybe<Array<_Enumtable_Priority_Bool_Exp>>;
  _not?: InputMaybe<_Enumtable_Priority_Bool_Exp>;
  _or?: InputMaybe<Array<_Enumtable_Priority_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "_enumtable.priority" */
export enum _Enumtable_Priority_Constraint {
  /** unique or primary key constraint on columns "value" */
  PriorityPkey = 'priority_pkey'
}

/** input type for inserting data into table "_enumtable.priority" */
export type _Enumtable_Priority_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type _Enumtable_Priority_Max_Fields = {
  __typename?: '_enumtable_priority_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type _Enumtable_Priority_Min_Fields = {
  __typename?: '_enumtable_priority_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "_enumtable.priority" */
export type _Enumtable_Priority_Mutation_Response = {
  __typename?: '_enumtable_priority_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<_Enumtable_Priority>;
};

/** on_conflict condition type for table "_enumtable.priority" */
export type _Enumtable_Priority_On_Conflict = {
  constraint: _Enumtable_Priority_Constraint;
  update_columns?: Array<_Enumtable_Priority_Update_Column>;
  where?: InputMaybe<_Enumtable_Priority_Bool_Exp>;
};

/** Ordering options when selecting data from "_enumtable.priority". */
export type _Enumtable_Priority_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: _enumtable.priority */
export type _Enumtable_Priority_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "_enumtable.priority" */
export enum _Enumtable_Priority_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "_enumtable.priority" */
export type _Enumtable_Priority_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "_enumtable_priority" */
export type _Enumtable_Priority_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: _Enumtable_Priority_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type _Enumtable_Priority_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "_enumtable.priority" */
export enum _Enumtable_Priority_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type _Enumtable_Priority_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<_Enumtable_Priority_Set_Input>;
  /** filter the rows which have to be updated */
  where: _Enumtable_Priority_Bool_Exp;
};

/** columns and relationships of "_enumtable.role" */
export type _Enumtable_Role = {
  __typename?: '_enumtable_role';
  comment?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  roles: Array<Examsonline_Roles>;
  /** An aggregate relationship */
  roles_aggregate: Examsonline_Roles_Aggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "_enumtable.role" */
export type _Enumtable_RoleRolesArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Roles_Order_By>>;
  where?: InputMaybe<Examsonline_Roles_Bool_Exp>;
};


/** columns and relationships of "_enumtable.role" */
export type _Enumtable_RoleRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Roles_Order_By>>;
  where?: InputMaybe<Examsonline_Roles_Bool_Exp>;
};

/** aggregated selection of "_enumtable.role" */
export type _Enumtable_Role_Aggregate = {
  __typename?: '_enumtable_role_aggregate';
  aggregate?: Maybe<_Enumtable_Role_Aggregate_Fields>;
  nodes: Array<_Enumtable_Role>;
};

/** aggregate fields of "_enumtable.role" */
export type _Enumtable_Role_Aggregate_Fields = {
  __typename?: '_enumtable_role_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<_Enumtable_Role_Max_Fields>;
  min?: Maybe<_Enumtable_Role_Min_Fields>;
};


/** aggregate fields of "_enumtable.role" */
export type _Enumtable_Role_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<_Enumtable_Role_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "_enumtable.role". All fields are combined with a logical 'AND'. */
export type _Enumtable_Role_Bool_Exp = {
  _and?: InputMaybe<Array<_Enumtable_Role_Bool_Exp>>;
  _not?: InputMaybe<_Enumtable_Role_Bool_Exp>;
  _or?: InputMaybe<Array<_Enumtable_Role_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  roles?: InputMaybe<Examsonline_Roles_Bool_Exp>;
  roles_aggregate?: InputMaybe<Examsonline_Roles_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "_enumtable.role" */
export enum _Enumtable_Role_Constraint {
  /** unique or primary key constraint on columns "value" */
  RolePkey = 'role_pkey'
}

/** input type for inserting data into table "_enumtable.role" */
export type _Enumtable_Role_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Examsonline_Roles_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type _Enumtable_Role_Max_Fields = {
  __typename?: '_enumtable_role_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type _Enumtable_Role_Min_Fields = {
  __typename?: '_enumtable_role_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "_enumtable.role" */
export type _Enumtable_Role_Mutation_Response = {
  __typename?: '_enumtable_role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<_Enumtable_Role>;
};

/** input type for inserting object relation for remote table "_enumtable.role" */
export type _Enumtable_Role_Obj_Rel_Insert_Input = {
  data: _Enumtable_Role_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<_Enumtable_Role_On_Conflict>;
};

/** on_conflict condition type for table "_enumtable.role" */
export type _Enumtable_Role_On_Conflict = {
  constraint: _Enumtable_Role_Constraint;
  update_columns?: Array<_Enumtable_Role_Update_Column>;
  where?: InputMaybe<_Enumtable_Role_Bool_Exp>;
};

/** Ordering options when selecting data from "_enumtable.role". */
export type _Enumtable_Role_Order_By = {
  comment?: InputMaybe<Order_By>;
  roles_aggregate?: InputMaybe<Examsonline_Roles_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: _enumtable.role */
export type _Enumtable_Role_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "_enumtable.role" */
export enum _Enumtable_Role_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "_enumtable.role" */
export type _Enumtable_Role_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "_enumtable_role" */
export type _Enumtable_Role_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: _Enumtable_Role_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type _Enumtable_Role_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "_enumtable.role" */
export enum _Enumtable_Role_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type _Enumtable_Role_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<_Enumtable_Role_Set_Input>;
  /** filter the rows which have to be updated */
  where: _Enumtable_Role_Bool_Exp;
};

/** columns and relationships of "_enumtable.ticket_status" */
export type _Enumtable_Ticket_Status = {
  __typename?: '_enumtable_ticket_status';
  comment?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

/** aggregated selection of "_enumtable.ticket_status" */
export type _Enumtable_Ticket_Status_Aggregate = {
  __typename?: '_enumtable_ticket_status_aggregate';
  aggregate?: Maybe<_Enumtable_Ticket_Status_Aggregate_Fields>;
  nodes: Array<_Enumtable_Ticket_Status>;
};

/** aggregate fields of "_enumtable.ticket_status" */
export type _Enumtable_Ticket_Status_Aggregate_Fields = {
  __typename?: '_enumtable_ticket_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<_Enumtable_Ticket_Status_Max_Fields>;
  min?: Maybe<_Enumtable_Ticket_Status_Min_Fields>;
};


/** aggregate fields of "_enumtable.ticket_status" */
export type _Enumtable_Ticket_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<_Enumtable_Ticket_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "_enumtable.ticket_status". All fields are combined with a logical 'AND'. */
export type _Enumtable_Ticket_Status_Bool_Exp = {
  _and?: InputMaybe<Array<_Enumtable_Ticket_Status_Bool_Exp>>;
  _not?: InputMaybe<_Enumtable_Ticket_Status_Bool_Exp>;
  _or?: InputMaybe<Array<_Enumtable_Ticket_Status_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "_enumtable.ticket_status" */
export enum _Enumtable_Ticket_Status_Constraint {
  /** unique or primary key constraint on columns "status" */
  TicketStatusPkey = 'ticket_status_pkey'
}

/** input type for inserting data into table "_enumtable.ticket_status" */
export type _Enumtable_Ticket_Status_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type _Enumtable_Ticket_Status_Max_Fields = {
  __typename?: '_enumtable_ticket_status_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type _Enumtable_Ticket_Status_Min_Fields = {
  __typename?: '_enumtable_ticket_status_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "_enumtable.ticket_status" */
export type _Enumtable_Ticket_Status_Mutation_Response = {
  __typename?: '_enumtable_ticket_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<_Enumtable_Ticket_Status>;
};

/** on_conflict condition type for table "_enumtable.ticket_status" */
export type _Enumtable_Ticket_Status_On_Conflict = {
  constraint: _Enumtable_Ticket_Status_Constraint;
  update_columns?: Array<_Enumtable_Ticket_Status_Update_Column>;
  where?: InputMaybe<_Enumtable_Ticket_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "_enumtable.ticket_status". */
export type _Enumtable_Ticket_Status_Order_By = {
  comment?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: _enumtable.ticket_status */
export type _Enumtable_Ticket_Status_Pk_Columns_Input = {
  status: Scalars['String']['input'];
};

/** select columns of table "_enumtable.ticket_status" */
export enum _Enumtable_Ticket_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "_enumtable.ticket_status" */
export type _Enumtable_Ticket_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "_enumtable_ticket_status" */
export type _Enumtable_Ticket_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: _Enumtable_Ticket_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type _Enumtable_Ticket_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "_enumtable.ticket_status" */
export enum _Enumtable_Ticket_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Status = 'status'
}

export type _Enumtable_Ticket_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<_Enumtable_Ticket_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: _Enumtable_Ticket_Status_Bool_Exp;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']['input']>;
  _gt?: InputMaybe<Scalars['date']['input']>;
  _gte?: InputMaybe<Scalars['date']['input']>;
  _in?: InputMaybe<Array<Scalars['date']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['date']['input']>;
  _lte?: InputMaybe<Scalars['date']['input']>;
  _neq?: InputMaybe<Scalars['date']['input']>;
  _nin?: InputMaybe<Array<Scalars['date']['input']>>;
};

/** for answers */
export type Examsonline_Answers = {
  __typename?: 'examsonline_answers';
  /** An object relationship */
  attempt: Examsonline_Attempt;
  attempt_id: Scalars['uuid']['output'];
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  isCorrect?: Maybe<Scalars['Boolean']['output']>;
  q_id: Scalars['Int']['output'];
  /** An object relationship */
  question: Examsonline_Questions;
  score?: Maybe<Scalars['Int']['output']>;
  skipped?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "examsonline.answers" */
export type Examsonline_Answers_Aggregate = {
  __typename?: 'examsonline_answers_aggregate';
  aggregate?: Maybe<Examsonline_Answers_Aggregate_Fields>;
  nodes: Array<Examsonline_Answers>;
};

export type Examsonline_Answers_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Examsonline_Answers_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Examsonline_Answers_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Examsonline_Answers_Aggregate_Bool_Exp_Count>;
};

export type Examsonline_Answers_Aggregate_Bool_Exp_Bool_And = {
  arguments: Examsonline_Answers_Select_Column_Examsonline_Answers_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Answers_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Examsonline_Answers_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Examsonline_Answers_Select_Column_Examsonline_Answers_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Answers_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Examsonline_Answers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Examsonline_Answers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Answers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "examsonline.answers" */
export type Examsonline_Answers_Aggregate_Fields = {
  __typename?: 'examsonline_answers_aggregate_fields';
  avg?: Maybe<Examsonline_Answers_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Examsonline_Answers_Max_Fields>;
  min?: Maybe<Examsonline_Answers_Min_Fields>;
  stddev?: Maybe<Examsonline_Answers_Stddev_Fields>;
  stddev_pop?: Maybe<Examsonline_Answers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Examsonline_Answers_Stddev_Samp_Fields>;
  sum?: Maybe<Examsonline_Answers_Sum_Fields>;
  var_pop?: Maybe<Examsonline_Answers_Var_Pop_Fields>;
  var_samp?: Maybe<Examsonline_Answers_Var_Samp_Fields>;
  variance?: Maybe<Examsonline_Answers_Variance_Fields>;
};


/** aggregate fields of "examsonline.answers" */
export type Examsonline_Answers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Examsonline_Answers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "examsonline.answers" */
export type Examsonline_Answers_Aggregate_Order_By = {
  avg?: InputMaybe<Examsonline_Answers_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Examsonline_Answers_Max_Order_By>;
  min?: InputMaybe<Examsonline_Answers_Min_Order_By>;
  stddev?: InputMaybe<Examsonline_Answers_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Examsonline_Answers_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Examsonline_Answers_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Examsonline_Answers_Sum_Order_By>;
  var_pop?: InputMaybe<Examsonline_Answers_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Examsonline_Answers_Var_Samp_Order_By>;
  variance?: InputMaybe<Examsonline_Answers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "examsonline.answers" */
export type Examsonline_Answers_Arr_Rel_Insert_Input = {
  data: Array<Examsonline_Answers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Examsonline_Answers_On_Conflict>;
};

/** aggregate avg on columns */
export type Examsonline_Answers_Avg_Fields = {
  __typename?: 'examsonline_answers_avg_fields';
  q_id?: Maybe<Scalars['Float']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "examsonline.answers" */
export type Examsonline_Answers_Avg_Order_By = {
  q_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "examsonline.answers". All fields are combined with a logical 'AND'. */
export type Examsonline_Answers_Bool_Exp = {
  _and?: InputMaybe<Array<Examsonline_Answers_Bool_Exp>>;
  _not?: InputMaybe<Examsonline_Answers_Bool_Exp>;
  _or?: InputMaybe<Array<Examsonline_Answers_Bool_Exp>>;
  attempt?: InputMaybe<Examsonline_Attempt_Bool_Exp>;
  attempt_id?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isCorrect?: InputMaybe<Boolean_Comparison_Exp>;
  q_id?: InputMaybe<Int_Comparison_Exp>;
  question?: InputMaybe<Examsonline_Questions_Bool_Exp>;
  score?: InputMaybe<Int_Comparison_Exp>;
  skipped?: InputMaybe<Boolean_Comparison_Exp>;
  type?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "examsonline.answers" */
export enum Examsonline_Answers_Constraint {
  /** unique or primary key constraint on columns "attempt_id", "q_id" */
  AnswersPkey = 'answers_pkey'
}

/** input type for incrementing numeric columns in table "examsonline.answers" */
export type Examsonline_Answers_Inc_Input = {
  q_id?: InputMaybe<Scalars['Int']['input']>;
  score?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "examsonline.answers" */
export type Examsonline_Answers_Insert_Input = {
  attempt?: InputMaybe<Examsonline_Attempt_Obj_Rel_Insert_Input>;
  attempt_id?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isCorrect?: InputMaybe<Scalars['Boolean']['input']>;
  q_id?: InputMaybe<Scalars['Int']['input']>;
  question?: InputMaybe<Examsonline_Questions_Obj_Rel_Insert_Input>;
  score?: InputMaybe<Scalars['Int']['input']>;
  skipped?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Examsonline_Answers_Max_Fields = {
  __typename?: 'examsonline_answers_max_fields';
  attempt_id?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  q_id?: Maybe<Scalars['Int']['output']>;
  score?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "examsonline.answers" */
export type Examsonline_Answers_Max_Order_By = {
  attempt_id?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Examsonline_Answers_Min_Fields = {
  __typename?: 'examsonline_answers_min_fields';
  attempt_id?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  q_id?: Maybe<Scalars['Int']['output']>;
  score?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "examsonline.answers" */
export type Examsonline_Answers_Min_Order_By = {
  attempt_id?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "examsonline.answers" */
export type Examsonline_Answers_Mutation_Response = {
  __typename?: 'examsonline_answers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Examsonline_Answers>;
};

/** on_conflict condition type for table "examsonline.answers" */
export type Examsonline_Answers_On_Conflict = {
  constraint: Examsonline_Answers_Constraint;
  update_columns?: Array<Examsonline_Answers_Update_Column>;
  where?: InputMaybe<Examsonline_Answers_Bool_Exp>;
};

/** Ordering options when selecting data from "examsonline.answers". */
export type Examsonline_Answers_Order_By = {
  attempt?: InputMaybe<Examsonline_Attempt_Order_By>;
  attempt_id?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isCorrect?: InputMaybe<Order_By>;
  q_id?: InputMaybe<Order_By>;
  question?: InputMaybe<Examsonline_Questions_Order_By>;
  score?: InputMaybe<Order_By>;
  skipped?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: examsonline.answers */
export type Examsonline_Answers_Pk_Columns_Input = {
  attempt_id: Scalars['uuid']['input'];
  q_id: Scalars['Int']['input'];
};

/** select columns of table "examsonline.answers" */
export enum Examsonline_Answers_Select_Column {
  /** column name */
  AttemptId = 'attempt_id',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsCorrect = 'isCorrect',
  /** column name */
  QId = 'q_id',
  /** column name */
  Score = 'score',
  /** column name */
  Skipped = 'skipped',
  /** column name */
  Type = 'type'
}

/** select "examsonline_answers_aggregate_bool_exp_bool_and_arguments_columns" columns of table "examsonline.answers" */
export enum Examsonline_Answers_Select_Column_Examsonline_Answers_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsCorrect = 'isCorrect',
  /** column name */
  Skipped = 'skipped'
}

/** select "examsonline_answers_aggregate_bool_exp_bool_or_arguments_columns" columns of table "examsonline.answers" */
export enum Examsonline_Answers_Select_Column_Examsonline_Answers_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsCorrect = 'isCorrect',
  /** column name */
  Skipped = 'skipped'
}

/** input type for updating data in table "examsonline.answers" */
export type Examsonline_Answers_Set_Input = {
  attempt_id?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isCorrect?: InputMaybe<Scalars['Boolean']['input']>;
  q_id?: InputMaybe<Scalars['Int']['input']>;
  score?: InputMaybe<Scalars['Int']['input']>;
  skipped?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Examsonline_Answers_Stddev_Fields = {
  __typename?: 'examsonline_answers_stddev_fields';
  q_id?: Maybe<Scalars['Float']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "examsonline.answers" */
export type Examsonline_Answers_Stddev_Order_By = {
  q_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Examsonline_Answers_Stddev_Pop_Fields = {
  __typename?: 'examsonline_answers_stddev_pop_fields';
  q_id?: Maybe<Scalars['Float']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "examsonline.answers" */
export type Examsonline_Answers_Stddev_Pop_Order_By = {
  q_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Examsonline_Answers_Stddev_Samp_Fields = {
  __typename?: 'examsonline_answers_stddev_samp_fields';
  q_id?: Maybe<Scalars['Float']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "examsonline.answers" */
export type Examsonline_Answers_Stddev_Samp_Order_By = {
  q_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "examsonline_answers" */
export type Examsonline_Answers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Examsonline_Answers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Examsonline_Answers_Stream_Cursor_Value_Input = {
  attempt_id?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isCorrect?: InputMaybe<Scalars['Boolean']['input']>;
  q_id?: InputMaybe<Scalars['Int']['input']>;
  score?: InputMaybe<Scalars['Int']['input']>;
  skipped?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Examsonline_Answers_Sum_Fields = {
  __typename?: 'examsonline_answers_sum_fields';
  q_id?: Maybe<Scalars['Int']['output']>;
  score?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "examsonline.answers" */
export type Examsonline_Answers_Sum_Order_By = {
  q_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** update columns of table "examsonline.answers" */
export enum Examsonline_Answers_Update_Column {
  /** column name */
  AttemptId = 'attempt_id',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsCorrect = 'isCorrect',
  /** column name */
  QId = 'q_id',
  /** column name */
  Score = 'score',
  /** column name */
  Skipped = 'skipped',
  /** column name */
  Type = 'type'
}

export type Examsonline_Answers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Examsonline_Answers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Examsonline_Answers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Examsonline_Answers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Examsonline_Answers_Var_Pop_Fields = {
  __typename?: 'examsonline_answers_var_pop_fields';
  q_id?: Maybe<Scalars['Float']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "examsonline.answers" */
export type Examsonline_Answers_Var_Pop_Order_By = {
  q_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Examsonline_Answers_Var_Samp_Fields = {
  __typename?: 'examsonline_answers_var_samp_fields';
  q_id?: Maybe<Scalars['Float']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "examsonline.answers" */
export type Examsonline_Answers_Var_Samp_Order_By = {
  q_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Examsonline_Answers_Variance_Fields = {
  __typename?: 'examsonline_answers_variance_fields';
  q_id?: Maybe<Scalars['Float']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "examsonline.answers" */
export type Examsonline_Answers_Variance_Order_By = {
  q_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** stores schedules */
export type Examsonline_Assignments = {
  __typename?: 'examsonline_assignments';
  /** An array relationship */
  attempts: Array<Examsonline_Attempt>;
  /** An aggregate relationship */
  attempts_aggregate: Examsonline_Attempt_Aggregate;
  /** An array relationship */
  comments: Array<Examsonline_Comments>;
  /** An aggregate relationship */
  comments_aggregate: Examsonline_Comments_Aggregate;
  createdAt: Scalars['timestamptz']['output'];
  endDate: Scalars['timestamptz']['output'];
  group: Scalars['uuid']['output'];
  /** An object relationship */
  groupByGroup: Examsonline_Groups;
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  /** An object relationship */
  quiz?: Maybe<Examsonline_Quiz>;
  quiz_id?: Maybe<Scalars['uuid']['output']>;
  startDate: Scalars['timestamptz']['output'];
  /** An object relationship */
  test?: Maybe<Examsonline_Tests>;
  test_id?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  user: Scalars['String']['output'];
};


/** stores schedules */
export type Examsonline_AssignmentsAttemptsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Attempt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Attempt_Order_By>>;
  where?: InputMaybe<Examsonline_Attempt_Bool_Exp>;
};


/** stores schedules */
export type Examsonline_AssignmentsAttempts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Attempt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Attempt_Order_By>>;
  where?: InputMaybe<Examsonline_Attempt_Bool_Exp>;
};


/** stores schedules */
export type Examsonline_AssignmentsCommentsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Comments_Order_By>>;
  where?: InputMaybe<Examsonline_Comments_Bool_Exp>;
};


/** stores schedules */
export type Examsonline_AssignmentsComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Comments_Order_By>>;
  where?: InputMaybe<Examsonline_Comments_Bool_Exp>;
};

/** aggregated selection of "examsonline.assignments" */
export type Examsonline_Assignments_Aggregate = {
  __typename?: 'examsonline_assignments_aggregate';
  aggregate?: Maybe<Examsonline_Assignments_Aggregate_Fields>;
  nodes: Array<Examsonline_Assignments>;
};

export type Examsonline_Assignments_Aggregate_Bool_Exp = {
  count?: InputMaybe<Examsonline_Assignments_Aggregate_Bool_Exp_Count>;
};

export type Examsonline_Assignments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Examsonline_Assignments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Assignments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "examsonline.assignments" */
export type Examsonline_Assignments_Aggregate_Fields = {
  __typename?: 'examsonline_assignments_aggregate_fields';
  avg?: Maybe<Examsonline_Assignments_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Examsonline_Assignments_Max_Fields>;
  min?: Maybe<Examsonline_Assignments_Min_Fields>;
  stddev?: Maybe<Examsonline_Assignments_Stddev_Fields>;
  stddev_pop?: Maybe<Examsonline_Assignments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Examsonline_Assignments_Stddev_Samp_Fields>;
  sum?: Maybe<Examsonline_Assignments_Sum_Fields>;
  var_pop?: Maybe<Examsonline_Assignments_Var_Pop_Fields>;
  var_samp?: Maybe<Examsonline_Assignments_Var_Samp_Fields>;
  variance?: Maybe<Examsonline_Assignments_Variance_Fields>;
};


/** aggregate fields of "examsonline.assignments" */
export type Examsonline_Assignments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Examsonline_Assignments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "examsonline.assignments" */
export type Examsonline_Assignments_Aggregate_Order_By = {
  avg?: InputMaybe<Examsonline_Assignments_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Examsonline_Assignments_Max_Order_By>;
  min?: InputMaybe<Examsonline_Assignments_Min_Order_By>;
  stddev?: InputMaybe<Examsonline_Assignments_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Examsonline_Assignments_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Examsonline_Assignments_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Examsonline_Assignments_Sum_Order_By>;
  var_pop?: InputMaybe<Examsonline_Assignments_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Examsonline_Assignments_Var_Samp_Order_By>;
  variance?: InputMaybe<Examsonline_Assignments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "examsonline.assignments" */
export type Examsonline_Assignments_Arr_Rel_Insert_Input = {
  data: Array<Examsonline_Assignments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Examsonline_Assignments_On_Conflict>;
};

/** aggregate avg on columns */
export type Examsonline_Assignments_Avg_Fields = {
  __typename?: 'examsonline_assignments_avg_fields';
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "examsonline.assignments" */
export type Examsonline_Assignments_Avg_Order_By = {
  test_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "examsonline.assignments". All fields are combined with a logical 'AND'. */
export type Examsonline_Assignments_Bool_Exp = {
  _and?: InputMaybe<Array<Examsonline_Assignments_Bool_Exp>>;
  _not?: InputMaybe<Examsonline_Assignments_Bool_Exp>;
  _or?: InputMaybe<Array<Examsonline_Assignments_Bool_Exp>>;
  attempts?: InputMaybe<Examsonline_Attempt_Bool_Exp>;
  attempts_aggregate?: InputMaybe<Examsonline_Attempt_Aggregate_Bool_Exp>;
  comments?: InputMaybe<Examsonline_Comments_Bool_Exp>;
  comments_aggregate?: InputMaybe<Examsonline_Comments_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  endDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  group?: InputMaybe<Uuid_Comparison_Exp>;
  groupByGroup?: InputMaybe<Examsonline_Groups_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  quiz?: InputMaybe<Examsonline_Quiz_Bool_Exp>;
  quiz_id?: InputMaybe<Uuid_Comparison_Exp>;
  startDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  test?: InputMaybe<Examsonline_Tests_Bool_Exp>;
  test_id?: InputMaybe<Int_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "examsonline.assignments" */
export enum Examsonline_Assignments_Constraint {
  /** unique or primary key constraint on columns "id" */
  SchedulePkey = 'schedule_pkey'
}

/** input type for incrementing numeric columns in table "examsonline.assignments" */
export type Examsonline_Assignments_Inc_Input = {
  test_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "examsonline.assignments" */
export type Examsonline_Assignments_Insert_Input = {
  attempts?: InputMaybe<Examsonline_Attempt_Arr_Rel_Insert_Input>;
  comments?: InputMaybe<Examsonline_Comments_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  endDate?: InputMaybe<Scalars['timestamptz']['input']>;
  group?: InputMaybe<Scalars['uuid']['input']>;
  groupByGroup?: InputMaybe<Examsonline_Groups_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  quiz?: InputMaybe<Examsonline_Quiz_Obj_Rel_Insert_Input>;
  quiz_id?: InputMaybe<Scalars['uuid']['input']>;
  startDate?: InputMaybe<Scalars['timestamptz']['input']>;
  test?: InputMaybe<Examsonline_Tests_Obj_Rel_Insert_Input>;
  test_id?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Examsonline_Assignments_Max_Fields = {
  __typename?: 'examsonline_assignments_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  endDate?: Maybe<Scalars['timestamptz']['output']>;
  group?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  quiz_id?: Maybe<Scalars['uuid']['output']>;
  startDate?: Maybe<Scalars['timestamptz']['output']>;
  test_id?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  user?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "examsonline.assignments" */
export type Examsonline_Assignments_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  endDate?: InputMaybe<Order_By>;
  group?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  quiz_id?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Examsonline_Assignments_Min_Fields = {
  __typename?: 'examsonline_assignments_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  endDate?: Maybe<Scalars['timestamptz']['output']>;
  group?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  quiz_id?: Maybe<Scalars['uuid']['output']>;
  startDate?: Maybe<Scalars['timestamptz']['output']>;
  test_id?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  user?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "examsonline.assignments" */
export type Examsonline_Assignments_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  endDate?: InputMaybe<Order_By>;
  group?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  quiz_id?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "examsonline.assignments" */
export type Examsonline_Assignments_Mutation_Response = {
  __typename?: 'examsonline_assignments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Examsonline_Assignments>;
};

/** input type for inserting object relation for remote table "examsonline.assignments" */
export type Examsonline_Assignments_Obj_Rel_Insert_Input = {
  data: Examsonline_Assignments_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Examsonline_Assignments_On_Conflict>;
};

/** on_conflict condition type for table "examsonline.assignments" */
export type Examsonline_Assignments_On_Conflict = {
  constraint: Examsonline_Assignments_Constraint;
  update_columns?: Array<Examsonline_Assignments_Update_Column>;
  where?: InputMaybe<Examsonline_Assignments_Bool_Exp>;
};

/** Ordering options when selecting data from "examsonline.assignments". */
export type Examsonline_Assignments_Order_By = {
  attempts_aggregate?: InputMaybe<Examsonline_Attempt_Aggregate_Order_By>;
  comments_aggregate?: InputMaybe<Examsonline_Comments_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  endDate?: InputMaybe<Order_By>;
  group?: InputMaybe<Order_By>;
  groupByGroup?: InputMaybe<Examsonline_Groups_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  quiz?: InputMaybe<Examsonline_Quiz_Order_By>;
  quiz_id?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
  test?: InputMaybe<Examsonline_Tests_Order_By>;
  test_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** primary key columns input for table: examsonline.assignments */
export type Examsonline_Assignments_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "examsonline.assignments" */
export enum Examsonline_Assignments_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  Group = 'group',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  QuizId = 'quiz_id',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  TestId = 'test_id',
  /** column name */
  Type = 'type',
  /** column name */
  User = 'user'
}

/** input type for updating data in table "examsonline.assignments" */
export type Examsonline_Assignments_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  endDate?: InputMaybe<Scalars['timestamptz']['input']>;
  group?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  quiz_id?: InputMaybe<Scalars['uuid']['input']>;
  startDate?: InputMaybe<Scalars['timestamptz']['input']>;
  test_id?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Examsonline_Assignments_Stddev_Fields = {
  __typename?: 'examsonline_assignments_stddev_fields';
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "examsonline.assignments" */
export type Examsonline_Assignments_Stddev_Order_By = {
  test_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Examsonline_Assignments_Stddev_Pop_Fields = {
  __typename?: 'examsonline_assignments_stddev_pop_fields';
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "examsonline.assignments" */
export type Examsonline_Assignments_Stddev_Pop_Order_By = {
  test_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Examsonline_Assignments_Stddev_Samp_Fields = {
  __typename?: 'examsonline_assignments_stddev_samp_fields';
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "examsonline.assignments" */
export type Examsonline_Assignments_Stddev_Samp_Order_By = {
  test_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "examsonline_assignments" */
export type Examsonline_Assignments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Examsonline_Assignments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Examsonline_Assignments_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  endDate?: InputMaybe<Scalars['timestamptz']['input']>;
  group?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  quiz_id?: InputMaybe<Scalars['uuid']['input']>;
  startDate?: InputMaybe<Scalars['timestamptz']['input']>;
  test_id?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Examsonline_Assignments_Sum_Fields = {
  __typename?: 'examsonline_assignments_sum_fields';
  test_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "examsonline.assignments" */
export type Examsonline_Assignments_Sum_Order_By = {
  test_id?: InputMaybe<Order_By>;
};

/** update columns of table "examsonline.assignments" */
export enum Examsonline_Assignments_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  Group = 'group',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  QuizId = 'quiz_id',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  TestId = 'test_id',
  /** column name */
  Type = 'type',
  /** column name */
  User = 'user'
}

export type Examsonline_Assignments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Examsonline_Assignments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Examsonline_Assignments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Examsonline_Assignments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Examsonline_Assignments_Var_Pop_Fields = {
  __typename?: 'examsonline_assignments_var_pop_fields';
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "examsonline.assignments" */
export type Examsonline_Assignments_Var_Pop_Order_By = {
  test_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Examsonline_Assignments_Var_Samp_Fields = {
  __typename?: 'examsonline_assignments_var_samp_fields';
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "examsonline.assignments" */
export type Examsonline_Assignments_Var_Samp_Order_By = {
  test_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Examsonline_Assignments_Variance_Fields = {
  __typename?: 'examsonline_assignments_variance_fields';
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "examsonline.assignments" */
export type Examsonline_Assignments_Variance_Order_By = {
  test_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "examsonline.attempt" */
export type Examsonline_Attempt = {
  __typename?: 'examsonline_attempt';
  /** An array relationship */
  answers: Array<Examsonline_Answers>;
  /** An aggregate relationship */
  answers_aggregate: Examsonline_Answers_Aggregate;
  asg_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  assignment?: Maybe<Examsonline_Assignments>;
  completed: Scalars['Boolean']['output'];
  /** An object relationship */
  contest?: Maybe<Examsonline_Contest>;
  contest_id?: Maybe<Scalars['uuid']['output']>;
  correct: Scalars['Int']['output'];
  createdAt: Scalars['timestamptz']['output'];
  email: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  orgId?: Maybe<Scalars['uuid']['output']>;
  percentage: Scalars['numeric']['output'];
  /** An object relationship */
  quiz?: Maybe<Examsonline_Quiz>;
  quiz_id?: Maybe<Scalars['uuid']['output']>;
  scheduleid?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  selected_options: Array<Examsonline_Selected_Options>;
  /** An aggregate relationship */
  selected_options_aggregate: Examsonline_Selected_Options_Aggregate;
  sessionId?: Maybe<Scalars['uuid']['output']>;
  skipped: Scalars['Int']['output'];
  stale: Scalars['Boolean']['output'];
  /** An object relationship */
  test: Examsonline_Tests;
  test_id: Scalars['Int']['output'];
  /** An array relationship */
  timespents: Array<Examsonline_Timespent>;
  /** An aggregate relationship */
  timespents_aggregate: Examsonline_Timespent_Aggregate;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  user?: Maybe<Scalars['String']['output']>;
  wrong: Scalars['Int']['output'];
};


/** columns and relationships of "examsonline.attempt" */
export type Examsonline_AttemptAnswersArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Answers_Order_By>>;
  where?: InputMaybe<Examsonline_Answers_Bool_Exp>;
};


/** columns and relationships of "examsonline.attempt" */
export type Examsonline_AttemptAnswers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Answers_Order_By>>;
  where?: InputMaybe<Examsonline_Answers_Bool_Exp>;
};


/** columns and relationships of "examsonline.attempt" */
export type Examsonline_AttemptSelected_OptionsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Selected_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Selected_Options_Order_By>>;
  where?: InputMaybe<Examsonline_Selected_Options_Bool_Exp>;
};


/** columns and relationships of "examsonline.attempt" */
export type Examsonline_AttemptSelected_Options_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Selected_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Selected_Options_Order_By>>;
  where?: InputMaybe<Examsonline_Selected_Options_Bool_Exp>;
};


/** columns and relationships of "examsonline.attempt" */
export type Examsonline_AttemptTimespentsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Timespent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Timespent_Order_By>>;
  where?: InputMaybe<Examsonline_Timespent_Bool_Exp>;
};


/** columns and relationships of "examsonline.attempt" */
export type Examsonline_AttemptTimespents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Timespent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Timespent_Order_By>>;
  where?: InputMaybe<Examsonline_Timespent_Bool_Exp>;
};

/** aggregated selection of "examsonline.attempt" */
export type Examsonline_Attempt_Aggregate = {
  __typename?: 'examsonline_attempt_aggregate';
  aggregate?: Maybe<Examsonline_Attempt_Aggregate_Fields>;
  nodes: Array<Examsonline_Attempt>;
};

export type Examsonline_Attempt_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Examsonline_Attempt_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Examsonline_Attempt_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Examsonline_Attempt_Aggregate_Bool_Exp_Count>;
};

export type Examsonline_Attempt_Aggregate_Bool_Exp_Bool_And = {
  arguments: Examsonline_Attempt_Select_Column_Examsonline_Attempt_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Attempt_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Examsonline_Attempt_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Examsonline_Attempt_Select_Column_Examsonline_Attempt_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Attempt_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Examsonline_Attempt_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Examsonline_Attempt_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Attempt_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "examsonline.attempt" */
export type Examsonline_Attempt_Aggregate_Fields = {
  __typename?: 'examsonline_attempt_aggregate_fields';
  avg?: Maybe<Examsonline_Attempt_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Examsonline_Attempt_Max_Fields>;
  min?: Maybe<Examsonline_Attempt_Min_Fields>;
  stddev?: Maybe<Examsonline_Attempt_Stddev_Fields>;
  stddev_pop?: Maybe<Examsonline_Attempt_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Examsonline_Attempt_Stddev_Samp_Fields>;
  sum?: Maybe<Examsonline_Attempt_Sum_Fields>;
  var_pop?: Maybe<Examsonline_Attempt_Var_Pop_Fields>;
  var_samp?: Maybe<Examsonline_Attempt_Var_Samp_Fields>;
  variance?: Maybe<Examsonline_Attempt_Variance_Fields>;
};


/** aggregate fields of "examsonline.attempt" */
export type Examsonline_Attempt_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Examsonline_Attempt_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "examsonline.attempt" */
export type Examsonline_Attempt_Aggregate_Order_By = {
  avg?: InputMaybe<Examsonline_Attempt_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Examsonline_Attempt_Max_Order_By>;
  min?: InputMaybe<Examsonline_Attempt_Min_Order_By>;
  stddev?: InputMaybe<Examsonline_Attempt_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Examsonline_Attempt_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Examsonline_Attempt_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Examsonline_Attempt_Sum_Order_By>;
  var_pop?: InputMaybe<Examsonline_Attempt_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Examsonline_Attempt_Var_Samp_Order_By>;
  variance?: InputMaybe<Examsonline_Attempt_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "examsonline.attempt" */
export type Examsonline_Attempt_Arr_Rel_Insert_Input = {
  data: Array<Examsonline_Attempt_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Examsonline_Attempt_On_Conflict>;
};

/** aggregate avg on columns */
export type Examsonline_Attempt_Avg_Fields = {
  __typename?: 'examsonline_attempt_avg_fields';
  correct?: Maybe<Scalars['Float']['output']>;
  percentage?: Maybe<Scalars['Float']['output']>;
  scheduleid?: Maybe<Scalars['Float']['output']>;
  skipped?: Maybe<Scalars['Float']['output']>;
  test_id?: Maybe<Scalars['Float']['output']>;
  wrong?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "examsonline.attempt" */
export type Examsonline_Attempt_Avg_Order_By = {
  correct?: InputMaybe<Order_By>;
  percentage?: InputMaybe<Order_By>;
  scheduleid?: InputMaybe<Order_By>;
  skipped?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
  wrong?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "examsonline.attempt". All fields are combined with a logical 'AND'. */
export type Examsonline_Attempt_Bool_Exp = {
  _and?: InputMaybe<Array<Examsonline_Attempt_Bool_Exp>>;
  _not?: InputMaybe<Examsonline_Attempt_Bool_Exp>;
  _or?: InputMaybe<Array<Examsonline_Attempt_Bool_Exp>>;
  answers?: InputMaybe<Examsonline_Answers_Bool_Exp>;
  answers_aggregate?: InputMaybe<Examsonline_Answers_Aggregate_Bool_Exp>;
  asg_id?: InputMaybe<Uuid_Comparison_Exp>;
  assignment?: InputMaybe<Examsonline_Assignments_Bool_Exp>;
  completed?: InputMaybe<Boolean_Comparison_Exp>;
  contest?: InputMaybe<Examsonline_Contest_Bool_Exp>;
  contest_id?: InputMaybe<Uuid_Comparison_Exp>;
  correct?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  orgId?: InputMaybe<Uuid_Comparison_Exp>;
  percentage?: InputMaybe<Numeric_Comparison_Exp>;
  quiz?: InputMaybe<Examsonline_Quiz_Bool_Exp>;
  quiz_id?: InputMaybe<Uuid_Comparison_Exp>;
  scheduleid?: InputMaybe<Int_Comparison_Exp>;
  selected_options?: InputMaybe<Examsonline_Selected_Options_Bool_Exp>;
  selected_options_aggregate?: InputMaybe<Examsonline_Selected_Options_Aggregate_Bool_Exp>;
  sessionId?: InputMaybe<Uuid_Comparison_Exp>;
  skipped?: InputMaybe<Int_Comparison_Exp>;
  stale?: InputMaybe<Boolean_Comparison_Exp>;
  test?: InputMaybe<Examsonline_Tests_Bool_Exp>;
  test_id?: InputMaybe<Int_Comparison_Exp>;
  timespents?: InputMaybe<Examsonline_Timespent_Bool_Exp>;
  timespents_aggregate?: InputMaybe<Examsonline_Timespent_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<String_Comparison_Exp>;
  wrong?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "examsonline.attempt" */
export enum Examsonline_Attempt_Constraint {
  /** unique or primary key constraint on columns "id" */
  AttemptIdKey = 'attempt_id_key',
  /** unique or primary key constraint on columns "id" */
  AttemptPkey = 'attempt_pkey'
}

/** input type for incrementing numeric columns in table "examsonline.attempt" */
export type Examsonline_Attempt_Inc_Input = {
  correct?: InputMaybe<Scalars['Int']['input']>;
  percentage?: InputMaybe<Scalars['numeric']['input']>;
  scheduleid?: InputMaybe<Scalars['Int']['input']>;
  skipped?: InputMaybe<Scalars['Int']['input']>;
  test_id?: InputMaybe<Scalars['Int']['input']>;
  wrong?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "examsonline.attempt" */
export type Examsonline_Attempt_Insert_Input = {
  answers?: InputMaybe<Examsonline_Answers_Arr_Rel_Insert_Input>;
  asg_id?: InputMaybe<Scalars['uuid']['input']>;
  assignment?: InputMaybe<Examsonline_Assignments_Obj_Rel_Insert_Input>;
  completed?: InputMaybe<Scalars['Boolean']['input']>;
  contest?: InputMaybe<Examsonline_Contest_Obj_Rel_Insert_Input>;
  contest_id?: InputMaybe<Scalars['uuid']['input']>;
  correct?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  orgId?: InputMaybe<Scalars['uuid']['input']>;
  percentage?: InputMaybe<Scalars['numeric']['input']>;
  quiz?: InputMaybe<Examsonline_Quiz_Obj_Rel_Insert_Input>;
  quiz_id?: InputMaybe<Scalars['uuid']['input']>;
  scheduleid?: InputMaybe<Scalars['Int']['input']>;
  selected_options?: InputMaybe<Examsonline_Selected_Options_Arr_Rel_Insert_Input>;
  sessionId?: InputMaybe<Scalars['uuid']['input']>;
  skipped?: InputMaybe<Scalars['Int']['input']>;
  stale?: InputMaybe<Scalars['Boolean']['input']>;
  test?: InputMaybe<Examsonline_Tests_Obj_Rel_Insert_Input>;
  test_id?: InputMaybe<Scalars['Int']['input']>;
  timespents?: InputMaybe<Examsonline_Timespent_Arr_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
  wrong?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Examsonline_Attempt_Max_Fields = {
  __typename?: 'examsonline_attempt_max_fields';
  asg_id?: Maybe<Scalars['uuid']['output']>;
  contest_id?: Maybe<Scalars['uuid']['output']>;
  correct?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  orgId?: Maybe<Scalars['uuid']['output']>;
  percentage?: Maybe<Scalars['numeric']['output']>;
  quiz_id?: Maybe<Scalars['uuid']['output']>;
  scheduleid?: Maybe<Scalars['Int']['output']>;
  sessionId?: Maybe<Scalars['uuid']['output']>;
  skipped?: Maybe<Scalars['Int']['output']>;
  test_id?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  user?: Maybe<Scalars['String']['output']>;
  wrong?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "examsonline.attempt" */
export type Examsonline_Attempt_Max_Order_By = {
  asg_id?: InputMaybe<Order_By>;
  contest_id?: InputMaybe<Order_By>;
  correct?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orgId?: InputMaybe<Order_By>;
  percentage?: InputMaybe<Order_By>;
  quiz_id?: InputMaybe<Order_By>;
  scheduleid?: InputMaybe<Order_By>;
  sessionId?: InputMaybe<Order_By>;
  skipped?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
  wrong?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Examsonline_Attempt_Min_Fields = {
  __typename?: 'examsonline_attempt_min_fields';
  asg_id?: Maybe<Scalars['uuid']['output']>;
  contest_id?: Maybe<Scalars['uuid']['output']>;
  correct?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  orgId?: Maybe<Scalars['uuid']['output']>;
  percentage?: Maybe<Scalars['numeric']['output']>;
  quiz_id?: Maybe<Scalars['uuid']['output']>;
  scheduleid?: Maybe<Scalars['Int']['output']>;
  sessionId?: Maybe<Scalars['uuid']['output']>;
  skipped?: Maybe<Scalars['Int']['output']>;
  test_id?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  user?: Maybe<Scalars['String']['output']>;
  wrong?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "examsonline.attempt" */
export type Examsonline_Attempt_Min_Order_By = {
  asg_id?: InputMaybe<Order_By>;
  contest_id?: InputMaybe<Order_By>;
  correct?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orgId?: InputMaybe<Order_By>;
  percentage?: InputMaybe<Order_By>;
  quiz_id?: InputMaybe<Order_By>;
  scheduleid?: InputMaybe<Order_By>;
  sessionId?: InputMaybe<Order_By>;
  skipped?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
  wrong?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "examsonline.attempt" */
export type Examsonline_Attempt_Mutation_Response = {
  __typename?: 'examsonline_attempt_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Examsonline_Attempt>;
};

/** input type for inserting object relation for remote table "examsonline.attempt" */
export type Examsonline_Attempt_Obj_Rel_Insert_Input = {
  data: Examsonline_Attempt_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Examsonline_Attempt_On_Conflict>;
};

/** on_conflict condition type for table "examsonline.attempt" */
export type Examsonline_Attempt_On_Conflict = {
  constraint: Examsonline_Attempt_Constraint;
  update_columns?: Array<Examsonline_Attempt_Update_Column>;
  where?: InputMaybe<Examsonline_Attempt_Bool_Exp>;
};

/** Ordering options when selecting data from "examsonline.attempt". */
export type Examsonline_Attempt_Order_By = {
  answers_aggregate?: InputMaybe<Examsonline_Answers_Aggregate_Order_By>;
  asg_id?: InputMaybe<Order_By>;
  assignment?: InputMaybe<Examsonline_Assignments_Order_By>;
  completed?: InputMaybe<Order_By>;
  contest?: InputMaybe<Examsonline_Contest_Order_By>;
  contest_id?: InputMaybe<Order_By>;
  correct?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orgId?: InputMaybe<Order_By>;
  percentage?: InputMaybe<Order_By>;
  quiz?: InputMaybe<Examsonline_Quiz_Order_By>;
  quiz_id?: InputMaybe<Order_By>;
  scheduleid?: InputMaybe<Order_By>;
  selected_options_aggregate?: InputMaybe<Examsonline_Selected_Options_Aggregate_Order_By>;
  sessionId?: InputMaybe<Order_By>;
  skipped?: InputMaybe<Order_By>;
  stale?: InputMaybe<Order_By>;
  test?: InputMaybe<Examsonline_Tests_Order_By>;
  test_id?: InputMaybe<Order_By>;
  timespents_aggregate?: InputMaybe<Examsonline_Timespent_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
  wrong?: InputMaybe<Order_By>;
};

/** primary key columns input for table: examsonline.attempt */
export type Examsonline_Attempt_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "examsonline.attempt" */
export enum Examsonline_Attempt_Select_Column {
  /** column name */
  AsgId = 'asg_id',
  /** column name */
  Completed = 'completed',
  /** column name */
  ContestId = 'contest_id',
  /** column name */
  Correct = 'correct',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  OrgId = 'orgId',
  /** column name */
  Percentage = 'percentage',
  /** column name */
  QuizId = 'quiz_id',
  /** column name */
  Scheduleid = 'scheduleid',
  /** column name */
  SessionId = 'sessionId',
  /** column name */
  Skipped = 'skipped',
  /** column name */
  Stale = 'stale',
  /** column name */
  TestId = 'test_id',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  User = 'user',
  /** column name */
  Wrong = 'wrong'
}

/** select "examsonline_attempt_aggregate_bool_exp_bool_and_arguments_columns" columns of table "examsonline.attempt" */
export enum Examsonline_Attempt_Select_Column_Examsonline_Attempt_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Completed = 'completed',
  /** column name */
  Stale = 'stale'
}

/** select "examsonline_attempt_aggregate_bool_exp_bool_or_arguments_columns" columns of table "examsonline.attempt" */
export enum Examsonline_Attempt_Select_Column_Examsonline_Attempt_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Completed = 'completed',
  /** column name */
  Stale = 'stale'
}

/** input type for updating data in table "examsonline.attempt" */
export type Examsonline_Attempt_Set_Input = {
  asg_id?: InputMaybe<Scalars['uuid']['input']>;
  completed?: InputMaybe<Scalars['Boolean']['input']>;
  contest_id?: InputMaybe<Scalars['uuid']['input']>;
  correct?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  orgId?: InputMaybe<Scalars['uuid']['input']>;
  percentage?: InputMaybe<Scalars['numeric']['input']>;
  quiz_id?: InputMaybe<Scalars['uuid']['input']>;
  scheduleid?: InputMaybe<Scalars['Int']['input']>;
  sessionId?: InputMaybe<Scalars['uuid']['input']>;
  skipped?: InputMaybe<Scalars['Int']['input']>;
  stale?: InputMaybe<Scalars['Boolean']['input']>;
  test_id?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
  wrong?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Examsonline_Attempt_Stddev_Fields = {
  __typename?: 'examsonline_attempt_stddev_fields';
  correct?: Maybe<Scalars['Float']['output']>;
  percentage?: Maybe<Scalars['Float']['output']>;
  scheduleid?: Maybe<Scalars['Float']['output']>;
  skipped?: Maybe<Scalars['Float']['output']>;
  test_id?: Maybe<Scalars['Float']['output']>;
  wrong?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "examsonline.attempt" */
export type Examsonline_Attempt_Stddev_Order_By = {
  correct?: InputMaybe<Order_By>;
  percentage?: InputMaybe<Order_By>;
  scheduleid?: InputMaybe<Order_By>;
  skipped?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
  wrong?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Examsonline_Attempt_Stddev_Pop_Fields = {
  __typename?: 'examsonline_attempt_stddev_pop_fields';
  correct?: Maybe<Scalars['Float']['output']>;
  percentage?: Maybe<Scalars['Float']['output']>;
  scheduleid?: Maybe<Scalars['Float']['output']>;
  skipped?: Maybe<Scalars['Float']['output']>;
  test_id?: Maybe<Scalars['Float']['output']>;
  wrong?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "examsonline.attempt" */
export type Examsonline_Attempt_Stddev_Pop_Order_By = {
  correct?: InputMaybe<Order_By>;
  percentage?: InputMaybe<Order_By>;
  scheduleid?: InputMaybe<Order_By>;
  skipped?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
  wrong?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Examsonline_Attempt_Stddev_Samp_Fields = {
  __typename?: 'examsonline_attempt_stddev_samp_fields';
  correct?: Maybe<Scalars['Float']['output']>;
  percentage?: Maybe<Scalars['Float']['output']>;
  scheduleid?: Maybe<Scalars['Float']['output']>;
  skipped?: Maybe<Scalars['Float']['output']>;
  test_id?: Maybe<Scalars['Float']['output']>;
  wrong?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "examsonline.attempt" */
export type Examsonline_Attempt_Stddev_Samp_Order_By = {
  correct?: InputMaybe<Order_By>;
  percentage?: InputMaybe<Order_By>;
  scheduleid?: InputMaybe<Order_By>;
  skipped?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
  wrong?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "examsonline_attempt" */
export type Examsonline_Attempt_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Examsonline_Attempt_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Examsonline_Attempt_Stream_Cursor_Value_Input = {
  asg_id?: InputMaybe<Scalars['uuid']['input']>;
  completed?: InputMaybe<Scalars['Boolean']['input']>;
  contest_id?: InputMaybe<Scalars['uuid']['input']>;
  correct?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  orgId?: InputMaybe<Scalars['uuid']['input']>;
  percentage?: InputMaybe<Scalars['numeric']['input']>;
  quiz_id?: InputMaybe<Scalars['uuid']['input']>;
  scheduleid?: InputMaybe<Scalars['Int']['input']>;
  sessionId?: InputMaybe<Scalars['uuid']['input']>;
  skipped?: InputMaybe<Scalars['Int']['input']>;
  stale?: InputMaybe<Scalars['Boolean']['input']>;
  test_id?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
  wrong?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Examsonline_Attempt_Sum_Fields = {
  __typename?: 'examsonline_attempt_sum_fields';
  correct?: Maybe<Scalars['Int']['output']>;
  percentage?: Maybe<Scalars['numeric']['output']>;
  scheduleid?: Maybe<Scalars['Int']['output']>;
  skipped?: Maybe<Scalars['Int']['output']>;
  test_id?: Maybe<Scalars['Int']['output']>;
  wrong?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "examsonline.attempt" */
export type Examsonline_Attempt_Sum_Order_By = {
  correct?: InputMaybe<Order_By>;
  percentage?: InputMaybe<Order_By>;
  scheduleid?: InputMaybe<Order_By>;
  skipped?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
  wrong?: InputMaybe<Order_By>;
};

/** update columns of table "examsonline.attempt" */
export enum Examsonline_Attempt_Update_Column {
  /** column name */
  AsgId = 'asg_id',
  /** column name */
  Completed = 'completed',
  /** column name */
  ContestId = 'contest_id',
  /** column name */
  Correct = 'correct',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  OrgId = 'orgId',
  /** column name */
  Percentage = 'percentage',
  /** column name */
  QuizId = 'quiz_id',
  /** column name */
  Scheduleid = 'scheduleid',
  /** column name */
  SessionId = 'sessionId',
  /** column name */
  Skipped = 'skipped',
  /** column name */
  Stale = 'stale',
  /** column name */
  TestId = 'test_id',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  User = 'user',
  /** column name */
  Wrong = 'wrong'
}

export type Examsonline_Attempt_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Examsonline_Attempt_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Examsonline_Attempt_Set_Input>;
  /** filter the rows which have to be updated */
  where: Examsonline_Attempt_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Examsonline_Attempt_Var_Pop_Fields = {
  __typename?: 'examsonline_attempt_var_pop_fields';
  correct?: Maybe<Scalars['Float']['output']>;
  percentage?: Maybe<Scalars['Float']['output']>;
  scheduleid?: Maybe<Scalars['Float']['output']>;
  skipped?: Maybe<Scalars['Float']['output']>;
  test_id?: Maybe<Scalars['Float']['output']>;
  wrong?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "examsonline.attempt" */
export type Examsonline_Attempt_Var_Pop_Order_By = {
  correct?: InputMaybe<Order_By>;
  percentage?: InputMaybe<Order_By>;
  scheduleid?: InputMaybe<Order_By>;
  skipped?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
  wrong?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Examsonline_Attempt_Var_Samp_Fields = {
  __typename?: 'examsonline_attempt_var_samp_fields';
  correct?: Maybe<Scalars['Float']['output']>;
  percentage?: Maybe<Scalars['Float']['output']>;
  scheduleid?: Maybe<Scalars['Float']['output']>;
  skipped?: Maybe<Scalars['Float']['output']>;
  test_id?: Maybe<Scalars['Float']['output']>;
  wrong?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "examsonline.attempt" */
export type Examsonline_Attempt_Var_Samp_Order_By = {
  correct?: InputMaybe<Order_By>;
  percentage?: InputMaybe<Order_By>;
  scheduleid?: InputMaybe<Order_By>;
  skipped?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
  wrong?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Examsonline_Attempt_Variance_Fields = {
  __typename?: 'examsonline_attempt_variance_fields';
  correct?: Maybe<Scalars['Float']['output']>;
  percentage?: Maybe<Scalars['Float']['output']>;
  scheduleid?: Maybe<Scalars['Float']['output']>;
  skipped?: Maybe<Scalars['Float']['output']>;
  test_id?: Maybe<Scalars['Float']['output']>;
  wrong?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "examsonline.attempt" */
export type Examsonline_Attempt_Variance_Order_By = {
  correct?: InputMaybe<Order_By>;
  percentage?: InputMaybe<Order_By>;
  scheduleid?: InputMaybe<Order_By>;
  skipped?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
  wrong?: InputMaybe<Order_By>;
};

/** contains banner messages for user or orgs */
export type Examsonline_Banner_Notification = {
  __typename?: 'examsonline_banner_notification';
  createdAt: Scalars['timestamptz']['output'];
  enabled?: Maybe<Scalars['Boolean']['output']>;
  expiresAt?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  group?: Maybe<Examsonline_Groups>;
  group_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['uuid']['output'];
  message: Scalars['String']['output'];
  org_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  organisation?: Maybe<Examsonline_Organisation>;
  ref?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  role?: Maybe<Examsonline_Roles>;
  role_id?: Maybe<Scalars['uuid']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
};

/** aggregated selection of "examsonline.banner_notification" */
export type Examsonline_Banner_Notification_Aggregate = {
  __typename?: 'examsonline_banner_notification_aggregate';
  aggregate?: Maybe<Examsonline_Banner_Notification_Aggregate_Fields>;
  nodes: Array<Examsonline_Banner_Notification>;
};

export type Examsonline_Banner_Notification_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Examsonline_Banner_Notification_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Examsonline_Banner_Notification_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Examsonline_Banner_Notification_Aggregate_Bool_Exp_Count>;
};

export type Examsonline_Banner_Notification_Aggregate_Bool_Exp_Bool_And = {
  arguments: Examsonline_Banner_Notification_Select_Column_Examsonline_Banner_Notification_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Banner_Notification_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Examsonline_Banner_Notification_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Examsonline_Banner_Notification_Select_Column_Examsonline_Banner_Notification_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Banner_Notification_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Examsonline_Banner_Notification_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Examsonline_Banner_Notification_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Banner_Notification_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "examsonline.banner_notification" */
export type Examsonline_Banner_Notification_Aggregate_Fields = {
  __typename?: 'examsonline_banner_notification_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Examsonline_Banner_Notification_Max_Fields>;
  min?: Maybe<Examsonline_Banner_Notification_Min_Fields>;
};


/** aggregate fields of "examsonline.banner_notification" */
export type Examsonline_Banner_Notification_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Examsonline_Banner_Notification_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "examsonline.banner_notification" */
export type Examsonline_Banner_Notification_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Examsonline_Banner_Notification_Max_Order_By>;
  min?: InputMaybe<Examsonline_Banner_Notification_Min_Order_By>;
};

/** input type for inserting array relation for remote table "examsonline.banner_notification" */
export type Examsonline_Banner_Notification_Arr_Rel_Insert_Input = {
  data: Array<Examsonline_Banner_Notification_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Examsonline_Banner_Notification_On_Conflict>;
};

/** Boolean expression to filter rows from the table "examsonline.banner_notification". All fields are combined with a logical 'AND'. */
export type Examsonline_Banner_Notification_Bool_Exp = {
  _and?: InputMaybe<Array<Examsonline_Banner_Notification_Bool_Exp>>;
  _not?: InputMaybe<Examsonline_Banner_Notification_Bool_Exp>;
  _or?: InputMaybe<Array<Examsonline_Banner_Notification_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  enabled?: InputMaybe<Boolean_Comparison_Exp>;
  expiresAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  group?: InputMaybe<Examsonline_Groups_Bool_Exp>;
  group_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  org_id?: InputMaybe<Uuid_Comparison_Exp>;
  organisation?: InputMaybe<Examsonline_Organisation_Bool_Exp>;
  ref?: InputMaybe<Uuid_Comparison_Exp>;
  role?: InputMaybe<Examsonline_Roles_Bool_Exp>;
  role_id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "examsonline.banner_notification" */
export enum Examsonline_Banner_Notification_Constraint {
  /** unique or primary key constraint on columns "id" */
  BannerNotificationPkey = 'banner_notification_pkey'
}

/** input type for inserting data into table "examsonline.banner_notification" */
export type Examsonline_Banner_Notification_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  group?: InputMaybe<Examsonline_Groups_Obj_Rel_Insert_Input>;
  group_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  organisation?: InputMaybe<Examsonline_Organisation_Obj_Rel_Insert_Input>;
  ref?: InputMaybe<Scalars['uuid']['input']>;
  role?: InputMaybe<Examsonline_Roles_Obj_Rel_Insert_Input>;
  role_id?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Examsonline_Banner_Notification_Max_Fields = {
  __typename?: 'examsonline_banner_notification_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  expiresAt?: Maybe<Scalars['timestamptz']['output']>;
  group_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  ref?: Maybe<Scalars['uuid']['output']>;
  role_id?: Maybe<Scalars['uuid']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "examsonline.banner_notification" */
export type Examsonline_Banner_Notification_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  expiresAt?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  ref?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Examsonline_Banner_Notification_Min_Fields = {
  __typename?: 'examsonline_banner_notification_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  expiresAt?: Maybe<Scalars['timestamptz']['output']>;
  group_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  ref?: Maybe<Scalars['uuid']['output']>;
  role_id?: Maybe<Scalars['uuid']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "examsonline.banner_notification" */
export type Examsonline_Banner_Notification_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  expiresAt?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  ref?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "examsonline.banner_notification" */
export type Examsonline_Banner_Notification_Mutation_Response = {
  __typename?: 'examsonline_banner_notification_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Examsonline_Banner_Notification>;
};

/** on_conflict condition type for table "examsonline.banner_notification" */
export type Examsonline_Banner_Notification_On_Conflict = {
  constraint: Examsonline_Banner_Notification_Constraint;
  update_columns?: Array<Examsonline_Banner_Notification_Update_Column>;
  where?: InputMaybe<Examsonline_Banner_Notification_Bool_Exp>;
};

/** Ordering options when selecting data from "examsonline.banner_notification". */
export type Examsonline_Banner_Notification_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  enabled?: InputMaybe<Order_By>;
  expiresAt?: InputMaybe<Order_By>;
  group?: InputMaybe<Examsonline_Groups_Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  organisation?: InputMaybe<Examsonline_Organisation_Order_By>;
  ref?: InputMaybe<Order_By>;
  role?: InputMaybe<Examsonline_Roles_Order_By>;
  role_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: examsonline.banner_notification */
export type Examsonline_Banner_Notification_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "examsonline.banner_notification" */
export enum Examsonline_Banner_Notification_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  ExpiresAt = 'expiresAt',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  Ref = 'ref',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "examsonline_banner_notification_aggregate_bool_exp_bool_and_arguments_columns" columns of table "examsonline.banner_notification" */
export enum Examsonline_Banner_Notification_Select_Column_Examsonline_Banner_Notification_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Enabled = 'enabled'
}

/** select "examsonline_banner_notification_aggregate_bool_exp_bool_or_arguments_columns" columns of table "examsonline.banner_notification" */
export enum Examsonline_Banner_Notification_Select_Column_Examsonline_Banner_Notification_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Enabled = 'enabled'
}

/** input type for updating data in table "examsonline.banner_notification" */
export type Examsonline_Banner_Notification_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  group_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  ref?: InputMaybe<Scalars['uuid']['input']>;
  role_id?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "examsonline_banner_notification" */
export type Examsonline_Banner_Notification_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Examsonline_Banner_Notification_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Examsonline_Banner_Notification_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  group_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  ref?: InputMaybe<Scalars['uuid']['input']>;
  role_id?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "examsonline.banner_notification" */
export enum Examsonline_Banner_Notification_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  ExpiresAt = 'expiresAt',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  Ref = 'ref',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Examsonline_Banner_Notification_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Examsonline_Banner_Notification_Set_Input>;
  /** filter the rows which have to be updated */
  where: Examsonline_Banner_Notification_Bool_Exp;
};

/** keeps record of bills generated for customer */
export type Examsonline_Bill = {
  __typename?: 'examsonline_bill';
  amount: Scalars['numeric']['output'];
  createdAt: Scalars['date']['output'];
  createdBy: Scalars['String']['output'];
  endDate: Scalars['date']['output'];
  id: Scalars['uuid']['output'];
  orgId: Scalars['uuid']['output'];
  plan: Scalars['uuid']['output'];
  startDate: Scalars['date']['output'];
  status: Scalars['String']['output'];
  udpatedAt: Scalars['date']['output'];
};

/** aggregated selection of "examsonline.bill" */
export type Examsonline_Bill_Aggregate = {
  __typename?: 'examsonline_bill_aggregate';
  aggregate?: Maybe<Examsonline_Bill_Aggregate_Fields>;
  nodes: Array<Examsonline_Bill>;
};

/** aggregate fields of "examsonline.bill" */
export type Examsonline_Bill_Aggregate_Fields = {
  __typename?: 'examsonline_bill_aggregate_fields';
  avg?: Maybe<Examsonline_Bill_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Examsonline_Bill_Max_Fields>;
  min?: Maybe<Examsonline_Bill_Min_Fields>;
  stddev?: Maybe<Examsonline_Bill_Stddev_Fields>;
  stddev_pop?: Maybe<Examsonline_Bill_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Examsonline_Bill_Stddev_Samp_Fields>;
  sum?: Maybe<Examsonline_Bill_Sum_Fields>;
  var_pop?: Maybe<Examsonline_Bill_Var_Pop_Fields>;
  var_samp?: Maybe<Examsonline_Bill_Var_Samp_Fields>;
  variance?: Maybe<Examsonline_Bill_Variance_Fields>;
};


/** aggregate fields of "examsonline.bill" */
export type Examsonline_Bill_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Examsonline_Bill_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Examsonline_Bill_Avg_Fields = {
  __typename?: 'examsonline_bill_avg_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "examsonline.bill". All fields are combined with a logical 'AND'. */
export type Examsonline_Bill_Bool_Exp = {
  _and?: InputMaybe<Array<Examsonline_Bill_Bool_Exp>>;
  _not?: InputMaybe<Examsonline_Bill_Bool_Exp>;
  _or?: InputMaybe<Array<Examsonline_Bill_Bool_Exp>>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  createdAt?: InputMaybe<Date_Comparison_Exp>;
  createdBy?: InputMaybe<String_Comparison_Exp>;
  endDate?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  orgId?: InputMaybe<Uuid_Comparison_Exp>;
  plan?: InputMaybe<Uuid_Comparison_Exp>;
  startDate?: InputMaybe<Date_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  udpatedAt?: InputMaybe<Date_Comparison_Exp>;
};

/** unique or primary key constraints on table "examsonline.bill" */
export enum Examsonline_Bill_Constraint {
  /** unique or primary key constraint on columns "id" */
  BillPkey = 'bill_pkey'
}

/** input type for incrementing numeric columns in table "examsonline.bill" */
export type Examsonline_Bill_Inc_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "examsonline.bill" */
export type Examsonline_Bill_Insert_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  createdAt?: InputMaybe<Scalars['date']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  orgId?: InputMaybe<Scalars['uuid']['input']>;
  plan?: InputMaybe<Scalars['uuid']['input']>;
  startDate?: InputMaybe<Scalars['date']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  udpatedAt?: InputMaybe<Scalars['date']['input']>;
};

/** aggregate max on columns */
export type Examsonline_Bill_Max_Fields = {
  __typename?: 'examsonline_bill_max_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
  createdAt?: Maybe<Scalars['date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  orgId?: Maybe<Scalars['uuid']['output']>;
  plan?: Maybe<Scalars['uuid']['output']>;
  startDate?: Maybe<Scalars['date']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  udpatedAt?: Maybe<Scalars['date']['output']>;
};

/** aggregate min on columns */
export type Examsonline_Bill_Min_Fields = {
  __typename?: 'examsonline_bill_min_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
  createdAt?: Maybe<Scalars['date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  orgId?: Maybe<Scalars['uuid']['output']>;
  plan?: Maybe<Scalars['uuid']['output']>;
  startDate?: Maybe<Scalars['date']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  udpatedAt?: Maybe<Scalars['date']['output']>;
};

/** response of any mutation on the table "examsonline.bill" */
export type Examsonline_Bill_Mutation_Response = {
  __typename?: 'examsonline_bill_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Examsonline_Bill>;
};

/** on_conflict condition type for table "examsonline.bill" */
export type Examsonline_Bill_On_Conflict = {
  constraint: Examsonline_Bill_Constraint;
  update_columns?: Array<Examsonline_Bill_Update_Column>;
  where?: InputMaybe<Examsonline_Bill_Bool_Exp>;
};

/** Ordering options when selecting data from "examsonline.bill". */
export type Examsonline_Bill_Order_By = {
  amount?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  endDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orgId?: InputMaybe<Order_By>;
  plan?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  udpatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: examsonline.bill */
export type Examsonline_Bill_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "examsonline.bill" */
export enum Examsonline_Bill_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  Id = 'id',
  /** column name */
  OrgId = 'orgId',
  /** column name */
  Plan = 'plan',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  Status = 'status',
  /** column name */
  UdpatedAt = 'udpatedAt'
}

/** input type for updating data in table "examsonline.bill" */
export type Examsonline_Bill_Set_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  createdAt?: InputMaybe<Scalars['date']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  orgId?: InputMaybe<Scalars['uuid']['input']>;
  plan?: InputMaybe<Scalars['uuid']['input']>;
  startDate?: InputMaybe<Scalars['date']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  udpatedAt?: InputMaybe<Scalars['date']['input']>;
};

/** aggregate stddev on columns */
export type Examsonline_Bill_Stddev_Fields = {
  __typename?: 'examsonline_bill_stddev_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Examsonline_Bill_Stddev_Pop_Fields = {
  __typename?: 'examsonline_bill_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Examsonline_Bill_Stddev_Samp_Fields = {
  __typename?: 'examsonline_bill_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "examsonline_bill" */
export type Examsonline_Bill_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Examsonline_Bill_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Examsonline_Bill_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  createdAt?: InputMaybe<Scalars['date']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  orgId?: InputMaybe<Scalars['uuid']['input']>;
  plan?: InputMaybe<Scalars['uuid']['input']>;
  startDate?: InputMaybe<Scalars['date']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  udpatedAt?: InputMaybe<Scalars['date']['input']>;
};

/** aggregate sum on columns */
export type Examsonline_Bill_Sum_Fields = {
  __typename?: 'examsonline_bill_sum_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "examsonline.bill" */
export enum Examsonline_Bill_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  Id = 'id',
  /** column name */
  OrgId = 'orgId',
  /** column name */
  Plan = 'plan',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  Status = 'status',
  /** column name */
  UdpatedAt = 'udpatedAt'
}

export type Examsonline_Bill_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Examsonline_Bill_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Examsonline_Bill_Set_Input>;
  /** filter the rows which have to be updated */
  where: Examsonline_Bill_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Examsonline_Bill_Var_Pop_Fields = {
  __typename?: 'examsonline_bill_var_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Examsonline_Bill_Var_Samp_Fields = {
  __typename?: 'examsonline_bill_var_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Examsonline_Bill_Variance_Fields = {
  __typename?: 'examsonline_bill_variance_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** stores comments */
export type Examsonline_Comments = {
  __typename?: 'examsonline_comments';
  asg_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  assignment?: Maybe<Examsonline_Assignments>;
  /** An object relationship */
  contest?: Maybe<Examsonline_Contest>;
  contest_id?: Maybe<Scalars['uuid']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  ref_id?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  test?: Maybe<Examsonline_Tests>;
  test_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  ticket?: Maybe<Examsonline_Tickets>;
  ticket_id?: Maybe<Scalars['uuid']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
  user: Scalars['String']['output'];
  /** An object relationship */
  userByUser: Examsonline_User;
  value: Scalars['String']['output'];
};

/** aggregated selection of "examsonline.comments" */
export type Examsonline_Comments_Aggregate = {
  __typename?: 'examsonline_comments_aggregate';
  aggregate?: Maybe<Examsonline_Comments_Aggregate_Fields>;
  nodes: Array<Examsonline_Comments>;
};

export type Examsonline_Comments_Aggregate_Bool_Exp = {
  count?: InputMaybe<Examsonline_Comments_Aggregate_Bool_Exp_Count>;
};

export type Examsonline_Comments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Examsonline_Comments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Comments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "examsonline.comments" */
export type Examsonline_Comments_Aggregate_Fields = {
  __typename?: 'examsonline_comments_aggregate_fields';
  avg?: Maybe<Examsonline_Comments_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Examsonline_Comments_Max_Fields>;
  min?: Maybe<Examsonline_Comments_Min_Fields>;
  stddev?: Maybe<Examsonline_Comments_Stddev_Fields>;
  stddev_pop?: Maybe<Examsonline_Comments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Examsonline_Comments_Stddev_Samp_Fields>;
  sum?: Maybe<Examsonline_Comments_Sum_Fields>;
  var_pop?: Maybe<Examsonline_Comments_Var_Pop_Fields>;
  var_samp?: Maybe<Examsonline_Comments_Var_Samp_Fields>;
  variance?: Maybe<Examsonline_Comments_Variance_Fields>;
};


/** aggregate fields of "examsonline.comments" */
export type Examsonline_Comments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Examsonline_Comments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "examsonline.comments" */
export type Examsonline_Comments_Aggregate_Order_By = {
  avg?: InputMaybe<Examsonline_Comments_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Examsonline_Comments_Max_Order_By>;
  min?: InputMaybe<Examsonline_Comments_Min_Order_By>;
  stddev?: InputMaybe<Examsonline_Comments_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Examsonline_Comments_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Examsonline_Comments_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Examsonline_Comments_Sum_Order_By>;
  var_pop?: InputMaybe<Examsonline_Comments_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Examsonline_Comments_Var_Samp_Order_By>;
  variance?: InputMaybe<Examsonline_Comments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "examsonline.comments" */
export type Examsonline_Comments_Arr_Rel_Insert_Input = {
  data: Array<Examsonline_Comments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Examsonline_Comments_On_Conflict>;
};

/** aggregate avg on columns */
export type Examsonline_Comments_Avg_Fields = {
  __typename?: 'examsonline_comments_avg_fields';
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "examsonline.comments" */
export type Examsonline_Comments_Avg_Order_By = {
  test_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "examsonline.comments". All fields are combined with a logical 'AND'. */
export type Examsonline_Comments_Bool_Exp = {
  _and?: InputMaybe<Array<Examsonline_Comments_Bool_Exp>>;
  _not?: InputMaybe<Examsonline_Comments_Bool_Exp>;
  _or?: InputMaybe<Array<Examsonline_Comments_Bool_Exp>>;
  asg_id?: InputMaybe<Uuid_Comparison_Exp>;
  assignment?: InputMaybe<Examsonline_Assignments_Bool_Exp>;
  contest?: InputMaybe<Examsonline_Contest_Bool_Exp>;
  contest_id?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  ref_id?: InputMaybe<String_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  test?: InputMaybe<Examsonline_Tests_Bool_Exp>;
  test_id?: InputMaybe<Int_Comparison_Exp>;
  ticket?: InputMaybe<Examsonline_Tickets_Bool_Exp>;
  ticket_id?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<String_Comparison_Exp>;
  userByUser?: InputMaybe<Examsonline_User_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "examsonline.comments" */
export enum Examsonline_Comments_Constraint {
  /** unique or primary key constraint on columns "id" */
  CommentsPkey = 'comments_pkey'
}

/** input type for incrementing numeric columns in table "examsonline.comments" */
export type Examsonline_Comments_Inc_Input = {
  test_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "examsonline.comments" */
export type Examsonline_Comments_Insert_Input = {
  asg_id?: InputMaybe<Scalars['uuid']['input']>;
  assignment?: InputMaybe<Examsonline_Assignments_Obj_Rel_Insert_Input>;
  contest?: InputMaybe<Examsonline_Contest_Obj_Rel_Insert_Input>;
  contest_id?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  ref_id?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  test?: InputMaybe<Examsonline_Tests_Obj_Rel_Insert_Input>;
  test_id?: InputMaybe<Scalars['Int']['input']>;
  ticket?: InputMaybe<Examsonline_Tickets_Obj_Rel_Insert_Input>;
  ticket_id?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
  userByUser?: InputMaybe<Examsonline_User_Obj_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Examsonline_Comments_Max_Fields = {
  __typename?: 'examsonline_comments_max_fields';
  asg_id?: Maybe<Scalars['uuid']['output']>;
  contest_id?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  ref_id?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  test_id?: Maybe<Scalars['Int']['output']>;
  ticket_id?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  user?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "examsonline.comments" */
export type Examsonline_Comments_Max_Order_By = {
  asg_id?: InputMaybe<Order_By>;
  contest_id?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ref_id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
  ticket_id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Examsonline_Comments_Min_Fields = {
  __typename?: 'examsonline_comments_min_fields';
  asg_id?: Maybe<Scalars['uuid']['output']>;
  contest_id?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  ref_id?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  test_id?: Maybe<Scalars['Int']['output']>;
  ticket_id?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  user?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "examsonline.comments" */
export type Examsonline_Comments_Min_Order_By = {
  asg_id?: InputMaybe<Order_By>;
  contest_id?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ref_id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
  ticket_id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "examsonline.comments" */
export type Examsonline_Comments_Mutation_Response = {
  __typename?: 'examsonline_comments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Examsonline_Comments>;
};

/** on_conflict condition type for table "examsonline.comments" */
export type Examsonline_Comments_On_Conflict = {
  constraint: Examsonline_Comments_Constraint;
  update_columns?: Array<Examsonline_Comments_Update_Column>;
  where?: InputMaybe<Examsonline_Comments_Bool_Exp>;
};

/** Ordering options when selecting data from "examsonline.comments". */
export type Examsonline_Comments_Order_By = {
  asg_id?: InputMaybe<Order_By>;
  assignment?: InputMaybe<Examsonline_Assignments_Order_By>;
  contest?: InputMaybe<Examsonline_Contest_Order_By>;
  contest_id?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ref_id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  test?: InputMaybe<Examsonline_Tests_Order_By>;
  test_id?: InputMaybe<Order_By>;
  ticket?: InputMaybe<Examsonline_Tickets_Order_By>;
  ticket_id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
  userByUser?: InputMaybe<Examsonline_User_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: examsonline.comments */
export type Examsonline_Comments_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "examsonline.comments" */
export enum Examsonline_Comments_Select_Column {
  /** column name */
  AsgId = 'asg_id',
  /** column name */
  ContestId = 'contest_id',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  RefId = 'ref_id',
  /** column name */
  Role = 'role',
  /** column name */
  TestId = 'test_id',
  /** column name */
  TicketId = 'ticket_id',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  User = 'user',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "examsonline.comments" */
export type Examsonline_Comments_Set_Input = {
  asg_id?: InputMaybe<Scalars['uuid']['input']>;
  contest_id?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  ref_id?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  test_id?: InputMaybe<Scalars['Int']['input']>;
  ticket_id?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Examsonline_Comments_Stddev_Fields = {
  __typename?: 'examsonline_comments_stddev_fields';
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "examsonline.comments" */
export type Examsonline_Comments_Stddev_Order_By = {
  test_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Examsonline_Comments_Stddev_Pop_Fields = {
  __typename?: 'examsonline_comments_stddev_pop_fields';
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "examsonline.comments" */
export type Examsonline_Comments_Stddev_Pop_Order_By = {
  test_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Examsonline_Comments_Stddev_Samp_Fields = {
  __typename?: 'examsonline_comments_stddev_samp_fields';
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "examsonline.comments" */
export type Examsonline_Comments_Stddev_Samp_Order_By = {
  test_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "examsonline_comments" */
export type Examsonline_Comments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Examsonline_Comments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Examsonline_Comments_Stream_Cursor_Value_Input = {
  asg_id?: InputMaybe<Scalars['uuid']['input']>;
  contest_id?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  ref_id?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  test_id?: InputMaybe<Scalars['Int']['input']>;
  ticket_id?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Examsonline_Comments_Sum_Fields = {
  __typename?: 'examsonline_comments_sum_fields';
  test_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "examsonline.comments" */
export type Examsonline_Comments_Sum_Order_By = {
  test_id?: InputMaybe<Order_By>;
};

/** update columns of table "examsonline.comments" */
export enum Examsonline_Comments_Update_Column {
  /** column name */
  AsgId = 'asg_id',
  /** column name */
  ContestId = 'contest_id',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  RefId = 'ref_id',
  /** column name */
  Role = 'role',
  /** column name */
  TestId = 'test_id',
  /** column name */
  TicketId = 'ticket_id',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  User = 'user',
  /** column name */
  Value = 'value'
}

export type Examsonline_Comments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Examsonline_Comments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Examsonline_Comments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Examsonline_Comments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Examsonline_Comments_Var_Pop_Fields = {
  __typename?: 'examsonline_comments_var_pop_fields';
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "examsonline.comments" */
export type Examsonline_Comments_Var_Pop_Order_By = {
  test_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Examsonline_Comments_Var_Samp_Fields = {
  __typename?: 'examsonline_comments_var_samp_fields';
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "examsonline.comments" */
export type Examsonline_Comments_Var_Samp_Order_By = {
  test_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Examsonline_Comments_Variance_Fields = {
  __typename?: 'examsonline_comments_variance_fields';
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "examsonline.comments" */
export type Examsonline_Comments_Variance_Order_By = {
  test_id?: InputMaybe<Order_By>;
};

/** saves contac  us info */
export type Examsonline_Contactus = {
  __typename?: 'examsonline_contactus';
  closed: Scalars['Boolean']['output'];
  createdAt: Scalars['timestamptz']['output'];
  email: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  message: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['numeric']['output']>;
  reason: Scalars['String']['output'];
  status: Scalars['String']['output'];
  updatedAt: Scalars['timestamptz']['output'];
};

/** aggregated selection of "examsonline.contactus" */
export type Examsonline_Contactus_Aggregate = {
  __typename?: 'examsonline_contactus_aggregate';
  aggregate?: Maybe<Examsonline_Contactus_Aggregate_Fields>;
  nodes: Array<Examsonline_Contactus>;
};

/** aggregate fields of "examsonline.contactus" */
export type Examsonline_Contactus_Aggregate_Fields = {
  __typename?: 'examsonline_contactus_aggregate_fields';
  avg?: Maybe<Examsonline_Contactus_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Examsonline_Contactus_Max_Fields>;
  min?: Maybe<Examsonline_Contactus_Min_Fields>;
  stddev?: Maybe<Examsonline_Contactus_Stddev_Fields>;
  stddev_pop?: Maybe<Examsonline_Contactus_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Examsonline_Contactus_Stddev_Samp_Fields>;
  sum?: Maybe<Examsonline_Contactus_Sum_Fields>;
  var_pop?: Maybe<Examsonline_Contactus_Var_Pop_Fields>;
  var_samp?: Maybe<Examsonline_Contactus_Var_Samp_Fields>;
  variance?: Maybe<Examsonline_Contactus_Variance_Fields>;
};


/** aggregate fields of "examsonline.contactus" */
export type Examsonline_Contactus_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Examsonline_Contactus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Examsonline_Contactus_Avg_Fields = {
  __typename?: 'examsonline_contactus_avg_fields';
  phone?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "examsonline.contactus". All fields are combined with a logical 'AND'. */
export type Examsonline_Contactus_Bool_Exp = {
  _and?: InputMaybe<Array<Examsonline_Contactus_Bool_Exp>>;
  _not?: InputMaybe<Examsonline_Contactus_Bool_Exp>;
  _or?: InputMaybe<Array<Examsonline_Contactus_Bool_Exp>>;
  closed?: InputMaybe<Boolean_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  phone?: InputMaybe<Numeric_Comparison_Exp>;
  reason?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "examsonline.contactus" */
export enum Examsonline_Contactus_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContactusPkey = 'contactus_pkey'
}

/** input type for incrementing numeric columns in table "examsonline.contactus" */
export type Examsonline_Contactus_Inc_Input = {
  phone?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "examsonline.contactus" */
export type Examsonline_Contactus_Insert_Input = {
  closed?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['numeric']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Examsonline_Contactus_Max_Fields = {
  __typename?: 'examsonline_contactus_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['numeric']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Examsonline_Contactus_Min_Fields = {
  __typename?: 'examsonline_contactus_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['numeric']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "examsonline.contactus" */
export type Examsonline_Contactus_Mutation_Response = {
  __typename?: 'examsonline_contactus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Examsonline_Contactus>;
};

/** on_conflict condition type for table "examsonline.contactus" */
export type Examsonline_Contactus_On_Conflict = {
  constraint: Examsonline_Contactus_Constraint;
  update_columns?: Array<Examsonline_Contactus_Update_Column>;
  where?: InputMaybe<Examsonline_Contactus_Bool_Exp>;
};

/** Ordering options when selecting data from "examsonline.contactus". */
export type Examsonline_Contactus_Order_By = {
  closed?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: examsonline.contactus */
export type Examsonline_Contactus_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "examsonline.contactus" */
export enum Examsonline_Contactus_Select_Column {
  /** column name */
  Closed = 'closed',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
  /** column name */
  Reason = 'reason',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "examsonline.contactus" */
export type Examsonline_Contactus_Set_Input = {
  closed?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['numeric']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Examsonline_Contactus_Stddev_Fields = {
  __typename?: 'examsonline_contactus_stddev_fields';
  phone?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Examsonline_Contactus_Stddev_Pop_Fields = {
  __typename?: 'examsonline_contactus_stddev_pop_fields';
  phone?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Examsonline_Contactus_Stddev_Samp_Fields = {
  __typename?: 'examsonline_contactus_stddev_samp_fields';
  phone?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "examsonline_contactus" */
export type Examsonline_Contactus_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Examsonline_Contactus_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Examsonline_Contactus_Stream_Cursor_Value_Input = {
  closed?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['numeric']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Examsonline_Contactus_Sum_Fields = {
  __typename?: 'examsonline_contactus_sum_fields';
  phone?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "examsonline.contactus" */
export enum Examsonline_Contactus_Update_Column {
  /** column name */
  Closed = 'closed',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
  /** column name */
  Reason = 'reason',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Examsonline_Contactus_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Examsonline_Contactus_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Examsonline_Contactus_Set_Input>;
  /** filter the rows which have to be updated */
  where: Examsonline_Contactus_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Examsonline_Contactus_Var_Pop_Fields = {
  __typename?: 'examsonline_contactus_var_pop_fields';
  phone?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Examsonline_Contactus_Var_Samp_Fields = {
  __typename?: 'examsonline_contactus_var_samp_fields';
  phone?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Examsonline_Contactus_Variance_Fields = {
  __typename?: 'examsonline_contactus_variance_fields';
  phone?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "examsonline.contest" */
export type Examsonline_Contest = {
  __typename?: 'examsonline_contest';
  /** An array relationship */
  attempts: Array<Examsonline_Attempt>;
  /** An aggregate relationship */
  attempts_aggregate: Examsonline_Attempt_Aggregate;
  author?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  comments: Array<Examsonline_Comments>;
  /** An aggregate relationship */
  comments_aggregate: Examsonline_Comments_Aggregate;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['numeric']['output']>;
  endDate?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  files: Array<Examsonline_File>;
  /** An aggregate relationship */
  files_aggregate: Examsonline_File_Aggregate;
  id: Scalars['uuid']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  participates: Array<Examsonline_Participate>;
  /** An aggregate relationship */
  participates_aggregate: Examsonline_Participate_Aggregate;
  /** An array relationship */
  ratings: Array<Examsonline_Rating>;
  /** An aggregate relationship */
  ratings_aggregate: Examsonline_Rating_Aggregate;
  startDate?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  test?: Maybe<Examsonline_Tests>;
  test_id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  topics: Array<Examsonline_Topics>;
  /** An aggregate relationship */
  topics_aggregate: Examsonline_Topics_Aggregate;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  user?: Maybe<Examsonline_User>;
};


/** columns and relationships of "examsonline.contest" */
export type Examsonline_ContestAttemptsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Attempt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Attempt_Order_By>>;
  where?: InputMaybe<Examsonline_Attempt_Bool_Exp>;
};


/** columns and relationships of "examsonline.contest" */
export type Examsonline_ContestAttempts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Attempt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Attempt_Order_By>>;
  where?: InputMaybe<Examsonline_Attempt_Bool_Exp>;
};


/** columns and relationships of "examsonline.contest" */
export type Examsonline_ContestCommentsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Comments_Order_By>>;
  where?: InputMaybe<Examsonline_Comments_Bool_Exp>;
};


/** columns and relationships of "examsonline.contest" */
export type Examsonline_ContestComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Comments_Order_By>>;
  where?: InputMaybe<Examsonline_Comments_Bool_Exp>;
};


/** columns and relationships of "examsonline.contest" */
export type Examsonline_ContestFilesArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_File_Order_By>>;
  where?: InputMaybe<Examsonline_File_Bool_Exp>;
};


/** columns and relationships of "examsonline.contest" */
export type Examsonline_ContestFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_File_Order_By>>;
  where?: InputMaybe<Examsonline_File_Bool_Exp>;
};


/** columns and relationships of "examsonline.contest" */
export type Examsonline_ContestParticipatesArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Participate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Participate_Order_By>>;
  where?: InputMaybe<Examsonline_Participate_Bool_Exp>;
};


/** columns and relationships of "examsonline.contest" */
export type Examsonline_ContestParticipates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Participate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Participate_Order_By>>;
  where?: InputMaybe<Examsonline_Participate_Bool_Exp>;
};


/** columns and relationships of "examsonline.contest" */
export type Examsonline_ContestRatingsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Rating_Order_By>>;
  where?: InputMaybe<Examsonline_Rating_Bool_Exp>;
};


/** columns and relationships of "examsonline.contest" */
export type Examsonline_ContestRatings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Rating_Order_By>>;
  where?: InputMaybe<Examsonline_Rating_Bool_Exp>;
};


/** columns and relationships of "examsonline.contest" */
export type Examsonline_ContestTopicsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Topics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Topics_Order_By>>;
  where?: InputMaybe<Examsonline_Topics_Bool_Exp>;
};


/** columns and relationships of "examsonline.contest" */
export type Examsonline_ContestTopics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Topics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Topics_Order_By>>;
  where?: InputMaybe<Examsonline_Topics_Bool_Exp>;
};

/** aggregated selection of "examsonline.contest" */
export type Examsonline_Contest_Aggregate = {
  __typename?: 'examsonline_contest_aggregate';
  aggregate?: Maybe<Examsonline_Contest_Aggregate_Fields>;
  nodes: Array<Examsonline_Contest>;
};

export type Examsonline_Contest_Aggregate_Bool_Exp = {
  count?: InputMaybe<Examsonline_Contest_Aggregate_Bool_Exp_Count>;
};

export type Examsonline_Contest_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Examsonline_Contest_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Contest_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "examsonline.contest" */
export type Examsonline_Contest_Aggregate_Fields = {
  __typename?: 'examsonline_contest_aggregate_fields';
  avg?: Maybe<Examsonline_Contest_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Examsonline_Contest_Max_Fields>;
  min?: Maybe<Examsonline_Contest_Min_Fields>;
  stddev?: Maybe<Examsonline_Contest_Stddev_Fields>;
  stddev_pop?: Maybe<Examsonline_Contest_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Examsonline_Contest_Stddev_Samp_Fields>;
  sum?: Maybe<Examsonline_Contest_Sum_Fields>;
  var_pop?: Maybe<Examsonline_Contest_Var_Pop_Fields>;
  var_samp?: Maybe<Examsonline_Contest_Var_Samp_Fields>;
  variance?: Maybe<Examsonline_Contest_Variance_Fields>;
};


/** aggregate fields of "examsonline.contest" */
export type Examsonline_Contest_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Examsonline_Contest_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "examsonline.contest" */
export type Examsonline_Contest_Aggregate_Order_By = {
  avg?: InputMaybe<Examsonline_Contest_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Examsonline_Contest_Max_Order_By>;
  min?: InputMaybe<Examsonline_Contest_Min_Order_By>;
  stddev?: InputMaybe<Examsonline_Contest_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Examsonline_Contest_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Examsonline_Contest_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Examsonline_Contest_Sum_Order_By>;
  var_pop?: InputMaybe<Examsonline_Contest_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Examsonline_Contest_Var_Samp_Order_By>;
  variance?: InputMaybe<Examsonline_Contest_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "examsonline.contest" */
export type Examsonline_Contest_Arr_Rel_Insert_Input = {
  data: Array<Examsonline_Contest_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Examsonline_Contest_On_Conflict>;
};

/** aggregate avg on columns */
export type Examsonline_Contest_Avg_Fields = {
  __typename?: 'examsonline_contest_avg_fields';
  duration?: Maybe<Scalars['Float']['output']>;
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "examsonline.contest" */
export type Examsonline_Contest_Avg_Order_By = {
  duration?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "examsonline.contest". All fields are combined with a logical 'AND'. */
export type Examsonline_Contest_Bool_Exp = {
  _and?: InputMaybe<Array<Examsonline_Contest_Bool_Exp>>;
  _not?: InputMaybe<Examsonline_Contest_Bool_Exp>;
  _or?: InputMaybe<Array<Examsonline_Contest_Bool_Exp>>;
  attempts?: InputMaybe<Examsonline_Attempt_Bool_Exp>;
  attempts_aggregate?: InputMaybe<Examsonline_Attempt_Aggregate_Bool_Exp>;
  author?: InputMaybe<String_Comparison_Exp>;
  comments?: InputMaybe<Examsonline_Comments_Bool_Exp>;
  comments_aggregate?: InputMaybe<Examsonline_Comments_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  duration?: InputMaybe<Numeric_Comparison_Exp>;
  endDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  files?: InputMaybe<Examsonline_File_Bool_Exp>;
  files_aggregate?: InputMaybe<Examsonline_File_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  participates?: InputMaybe<Examsonline_Participate_Bool_Exp>;
  participates_aggregate?: InputMaybe<Examsonline_Participate_Aggregate_Bool_Exp>;
  ratings?: InputMaybe<Examsonline_Rating_Bool_Exp>;
  ratings_aggregate?: InputMaybe<Examsonline_Rating_Aggregate_Bool_Exp>;
  startDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  test?: InputMaybe<Examsonline_Tests_Bool_Exp>;
  test_id?: InputMaybe<Int_Comparison_Exp>;
  topics?: InputMaybe<Examsonline_Topics_Bool_Exp>;
  topics_aggregate?: InputMaybe<Examsonline_Topics_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Examsonline_User_Bool_Exp>;
};

/** unique or primary key constraints on table "examsonline.contest" */
export enum Examsonline_Contest_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContestPkey = 'contest_pkey'
}

/** input type for incrementing numeric columns in table "examsonline.contest" */
export type Examsonline_Contest_Inc_Input = {
  duration?: InputMaybe<Scalars['numeric']['input']>;
  test_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "examsonline.contest" */
export type Examsonline_Contest_Insert_Input = {
  attempts?: InputMaybe<Examsonline_Attempt_Arr_Rel_Insert_Input>;
  author?: InputMaybe<Scalars['String']['input']>;
  comments?: InputMaybe<Examsonline_Comments_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['numeric']['input']>;
  endDate?: InputMaybe<Scalars['timestamptz']['input']>;
  files?: InputMaybe<Examsonline_File_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  participates?: InputMaybe<Examsonline_Participate_Arr_Rel_Insert_Input>;
  ratings?: InputMaybe<Examsonline_Rating_Arr_Rel_Insert_Input>;
  startDate?: InputMaybe<Scalars['timestamptz']['input']>;
  test?: InputMaybe<Examsonline_Tests_Obj_Rel_Insert_Input>;
  test_id?: InputMaybe<Scalars['Int']['input']>;
  topics?: InputMaybe<Examsonline_Topics_Arr_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Examsonline_User_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Examsonline_Contest_Max_Fields = {
  __typename?: 'examsonline_contest_max_fields';
  author?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['numeric']['output']>;
  endDate?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['timestamptz']['output']>;
  test_id?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "examsonline.contest" */
export type Examsonline_Contest_Max_Order_By = {
  author?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  endDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Examsonline_Contest_Min_Fields = {
  __typename?: 'examsonline_contest_min_fields';
  author?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['numeric']['output']>;
  endDate?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['timestamptz']['output']>;
  test_id?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "examsonline.contest" */
export type Examsonline_Contest_Min_Order_By = {
  author?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  endDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "examsonline.contest" */
export type Examsonline_Contest_Mutation_Response = {
  __typename?: 'examsonline_contest_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Examsonline_Contest>;
};

/** input type for inserting object relation for remote table "examsonline.contest" */
export type Examsonline_Contest_Obj_Rel_Insert_Input = {
  data: Examsonline_Contest_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Examsonline_Contest_On_Conflict>;
};

/** on_conflict condition type for table "examsonline.contest" */
export type Examsonline_Contest_On_Conflict = {
  constraint: Examsonline_Contest_Constraint;
  update_columns?: Array<Examsonline_Contest_Update_Column>;
  where?: InputMaybe<Examsonline_Contest_Bool_Exp>;
};

/** Ordering options when selecting data from "examsonline.contest". */
export type Examsonline_Contest_Order_By = {
  attempts_aggregate?: InputMaybe<Examsonline_Attempt_Aggregate_Order_By>;
  author?: InputMaybe<Order_By>;
  comments_aggregate?: InputMaybe<Examsonline_Comments_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  endDate?: InputMaybe<Order_By>;
  files_aggregate?: InputMaybe<Examsonline_File_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  participates_aggregate?: InputMaybe<Examsonline_Participate_Aggregate_Order_By>;
  ratings_aggregate?: InputMaybe<Examsonline_Rating_Aggregate_Order_By>;
  startDate?: InputMaybe<Order_By>;
  test?: InputMaybe<Examsonline_Tests_Order_By>;
  test_id?: InputMaybe<Order_By>;
  topics_aggregate?: InputMaybe<Examsonline_Topics_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Examsonline_User_Order_By>;
};

/** primary key columns input for table: examsonline.contest */
export type Examsonline_Contest_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "examsonline.contest" */
export enum Examsonline_Contest_Select_Column {
  /** column name */
  Author = 'author',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Duration = 'duration',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  TestId = 'test_id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "examsonline.contest" */
export type Examsonline_Contest_Set_Input = {
  author?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['numeric']['input']>;
  endDate?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['timestamptz']['input']>;
  test_id?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Examsonline_Contest_Stddev_Fields = {
  __typename?: 'examsonline_contest_stddev_fields';
  duration?: Maybe<Scalars['Float']['output']>;
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "examsonline.contest" */
export type Examsonline_Contest_Stddev_Order_By = {
  duration?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Examsonline_Contest_Stddev_Pop_Fields = {
  __typename?: 'examsonline_contest_stddev_pop_fields';
  duration?: Maybe<Scalars['Float']['output']>;
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "examsonline.contest" */
export type Examsonline_Contest_Stddev_Pop_Order_By = {
  duration?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Examsonline_Contest_Stddev_Samp_Fields = {
  __typename?: 'examsonline_contest_stddev_samp_fields';
  duration?: Maybe<Scalars['Float']['output']>;
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "examsonline.contest" */
export type Examsonline_Contest_Stddev_Samp_Order_By = {
  duration?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "examsonline_contest" */
export type Examsonline_Contest_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Examsonline_Contest_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Examsonline_Contest_Stream_Cursor_Value_Input = {
  author?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['numeric']['input']>;
  endDate?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['timestamptz']['input']>;
  test_id?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Examsonline_Contest_Sum_Fields = {
  __typename?: 'examsonline_contest_sum_fields';
  duration?: Maybe<Scalars['numeric']['output']>;
  test_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "examsonline.contest" */
export type Examsonline_Contest_Sum_Order_By = {
  duration?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
};

/** update columns of table "examsonline.contest" */
export enum Examsonline_Contest_Update_Column {
  /** column name */
  Author = 'author',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Duration = 'duration',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  TestId = 'test_id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Examsonline_Contest_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Examsonline_Contest_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Examsonline_Contest_Set_Input>;
  /** filter the rows which have to be updated */
  where: Examsonline_Contest_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Examsonline_Contest_Var_Pop_Fields = {
  __typename?: 'examsonline_contest_var_pop_fields';
  duration?: Maybe<Scalars['Float']['output']>;
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "examsonline.contest" */
export type Examsonline_Contest_Var_Pop_Order_By = {
  duration?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Examsonline_Contest_Var_Samp_Fields = {
  __typename?: 'examsonline_contest_var_samp_fields';
  duration?: Maybe<Scalars['Float']['output']>;
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "examsonline.contest" */
export type Examsonline_Contest_Var_Samp_Order_By = {
  duration?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Examsonline_Contest_Variance_Fields = {
  __typename?: 'examsonline_contest_variance_fields';
  duration?: Maybe<Scalars['Float']['output']>;
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "examsonline.contest" */
export type Examsonline_Contest_Variance_Order_By = {
  duration?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
};

/** table contains file owners */
export type Examsonline_File = {
  __typename?: 'examsonline_file';
  /** An object relationship */
  contest?: Maybe<Examsonline_Contest>;
  contest_id?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  group?: Maybe<Examsonline_Groups>;
  group_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['uuid']['output'];
  info: Scalars['json']['output'];
  name: Scalars['String']['output'];
  owner: Scalars['String']['output'];
  /** An object relationship */
  role?: Maybe<Examsonline_Roles>;
  role_id?: Maybe<Scalars['uuid']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  stale?: Maybe<Scalars['Boolean']['output']>;
  /** An object relationship */
  test?: Maybe<Examsonline_Tests>;
  test_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  user: Examsonline_User;
};


/** table contains file owners */
export type Examsonline_FileInfoArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "examsonline.file" */
export type Examsonline_File_Aggregate = {
  __typename?: 'examsonline_file_aggregate';
  aggregate?: Maybe<Examsonline_File_Aggregate_Fields>;
  nodes: Array<Examsonline_File>;
};

export type Examsonline_File_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Examsonline_File_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Examsonline_File_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Examsonline_File_Aggregate_Bool_Exp_Count>;
};

export type Examsonline_File_Aggregate_Bool_Exp_Bool_And = {
  arguments: Examsonline_File_Select_Column_Examsonline_File_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_File_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Examsonline_File_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Examsonline_File_Select_Column_Examsonline_File_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_File_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Examsonline_File_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Examsonline_File_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_File_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "examsonline.file" */
export type Examsonline_File_Aggregate_Fields = {
  __typename?: 'examsonline_file_aggregate_fields';
  avg?: Maybe<Examsonline_File_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Examsonline_File_Max_Fields>;
  min?: Maybe<Examsonline_File_Min_Fields>;
  stddev?: Maybe<Examsonline_File_Stddev_Fields>;
  stddev_pop?: Maybe<Examsonline_File_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Examsonline_File_Stddev_Samp_Fields>;
  sum?: Maybe<Examsonline_File_Sum_Fields>;
  var_pop?: Maybe<Examsonline_File_Var_Pop_Fields>;
  var_samp?: Maybe<Examsonline_File_Var_Samp_Fields>;
  variance?: Maybe<Examsonline_File_Variance_Fields>;
};


/** aggregate fields of "examsonline.file" */
export type Examsonline_File_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Examsonline_File_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "examsonline.file" */
export type Examsonline_File_Aggregate_Order_By = {
  avg?: InputMaybe<Examsonline_File_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Examsonline_File_Max_Order_By>;
  min?: InputMaybe<Examsonline_File_Min_Order_By>;
  stddev?: InputMaybe<Examsonline_File_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Examsonline_File_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Examsonline_File_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Examsonline_File_Sum_Order_By>;
  var_pop?: InputMaybe<Examsonline_File_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Examsonline_File_Var_Samp_Order_By>;
  variance?: InputMaybe<Examsonline_File_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "examsonline.file" */
export type Examsonline_File_Arr_Rel_Insert_Input = {
  data: Array<Examsonline_File_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Examsonline_File_On_Conflict>;
};

/** aggregate avg on columns */
export type Examsonline_File_Avg_Fields = {
  __typename?: 'examsonline_file_avg_fields';
  size?: Maybe<Scalars['Float']['output']>;
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "examsonline.file" */
export type Examsonline_File_Avg_Order_By = {
  size?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "examsonline.file". All fields are combined with a logical 'AND'. */
export type Examsonline_File_Bool_Exp = {
  _and?: InputMaybe<Array<Examsonline_File_Bool_Exp>>;
  _not?: InputMaybe<Examsonline_File_Bool_Exp>;
  _or?: InputMaybe<Array<Examsonline_File_Bool_Exp>>;
  contest?: InputMaybe<Examsonline_Contest_Bool_Exp>;
  contest_id?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  group?: InputMaybe<Examsonline_Groups_Bool_Exp>;
  group_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  info?: InputMaybe<Json_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  owner?: InputMaybe<String_Comparison_Exp>;
  role?: InputMaybe<Examsonline_Roles_Bool_Exp>;
  role_id?: InputMaybe<Uuid_Comparison_Exp>;
  size?: InputMaybe<Int_Comparison_Exp>;
  stale?: InputMaybe<Boolean_Comparison_Exp>;
  test?: InputMaybe<Examsonline_Tests_Bool_Exp>;
  test_id?: InputMaybe<Int_Comparison_Exp>;
  user?: InputMaybe<Examsonline_User_Bool_Exp>;
};

/** unique or primary key constraints on table "examsonline.file" */
export enum Examsonline_File_Constraint {
  /** unique or primary key constraint on columns "id" */
  FilePkey = 'File_pkey',
  /** unique or primary key constraint on columns "test_id", "id" */
  FileIdTestIdKey = 'file_id_test_id_key'
}

/** input type for incrementing numeric columns in table "examsonline.file" */
export type Examsonline_File_Inc_Input = {
  size?: InputMaybe<Scalars['Int']['input']>;
  test_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "examsonline.file" */
export type Examsonline_File_Insert_Input = {
  contest?: InputMaybe<Examsonline_Contest_Obj_Rel_Insert_Input>;
  contest_id?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  group?: InputMaybe<Examsonline_Groups_Obj_Rel_Insert_Input>;
  group_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  info?: InputMaybe<Scalars['json']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  owner?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Examsonline_Roles_Obj_Rel_Insert_Input>;
  role_id?: InputMaybe<Scalars['uuid']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  stale?: InputMaybe<Scalars['Boolean']['input']>;
  test?: InputMaybe<Examsonline_Tests_Obj_Rel_Insert_Input>;
  test_id?: InputMaybe<Scalars['Int']['input']>;
  user?: InputMaybe<Examsonline_User_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Examsonline_File_Max_Fields = {
  __typename?: 'examsonline_file_max_fields';
  contest_id?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  group_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<Scalars['String']['output']>;
  role_id?: Maybe<Scalars['uuid']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  test_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "examsonline.file" */
export type Examsonline_File_Max_Order_By = {
  contest_id?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  owner?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Examsonline_File_Min_Fields = {
  __typename?: 'examsonline_file_min_fields';
  contest_id?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  group_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<Scalars['String']['output']>;
  role_id?: Maybe<Scalars['uuid']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  test_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "examsonline.file" */
export type Examsonline_File_Min_Order_By = {
  contest_id?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  owner?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "examsonline.file" */
export type Examsonline_File_Mutation_Response = {
  __typename?: 'examsonline_file_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Examsonline_File>;
};

/** on_conflict condition type for table "examsonline.file" */
export type Examsonline_File_On_Conflict = {
  constraint: Examsonline_File_Constraint;
  update_columns?: Array<Examsonline_File_Update_Column>;
  where?: InputMaybe<Examsonline_File_Bool_Exp>;
};

/** Ordering options when selecting data from "examsonline.file". */
export type Examsonline_File_Order_By = {
  contest?: InputMaybe<Examsonline_Contest_Order_By>;
  contest_id?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  group?: InputMaybe<Examsonline_Groups_Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  info?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  owner?: InputMaybe<Order_By>;
  role?: InputMaybe<Examsonline_Roles_Order_By>;
  role_id?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  stale?: InputMaybe<Order_By>;
  test?: InputMaybe<Examsonline_Tests_Order_By>;
  test_id?: InputMaybe<Order_By>;
  user?: InputMaybe<Examsonline_User_Order_By>;
};

/** primary key columns input for table: examsonline.file */
export type Examsonline_File_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "examsonline.file" */
export enum Examsonline_File_Select_Column {
  /** column name */
  ContestId = 'contest_id',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  Info = 'info',
  /** column name */
  Name = 'name',
  /** column name */
  Owner = 'owner',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  Size = 'size',
  /** column name */
  Stale = 'stale',
  /** column name */
  TestId = 'test_id'
}

/** select "examsonline_file_aggregate_bool_exp_bool_and_arguments_columns" columns of table "examsonline.file" */
export enum Examsonline_File_Select_Column_Examsonline_File_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Stale = 'stale'
}

/** select "examsonline_file_aggregate_bool_exp_bool_or_arguments_columns" columns of table "examsonline.file" */
export enum Examsonline_File_Select_Column_Examsonline_File_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Stale = 'stale'
}

/** input type for updating data in table "examsonline.file" */
export type Examsonline_File_Set_Input = {
  contest_id?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  group_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  info?: InputMaybe<Scalars['json']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  owner?: InputMaybe<Scalars['String']['input']>;
  role_id?: InputMaybe<Scalars['uuid']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  stale?: InputMaybe<Scalars['Boolean']['input']>;
  test_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Examsonline_File_Stddev_Fields = {
  __typename?: 'examsonline_file_stddev_fields';
  size?: Maybe<Scalars['Float']['output']>;
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "examsonline.file" */
export type Examsonline_File_Stddev_Order_By = {
  size?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Examsonline_File_Stddev_Pop_Fields = {
  __typename?: 'examsonline_file_stddev_pop_fields';
  size?: Maybe<Scalars['Float']['output']>;
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "examsonline.file" */
export type Examsonline_File_Stddev_Pop_Order_By = {
  size?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Examsonline_File_Stddev_Samp_Fields = {
  __typename?: 'examsonline_file_stddev_samp_fields';
  size?: Maybe<Scalars['Float']['output']>;
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "examsonline.file" */
export type Examsonline_File_Stddev_Samp_Order_By = {
  size?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "examsonline_file" */
export type Examsonline_File_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Examsonline_File_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Examsonline_File_Stream_Cursor_Value_Input = {
  contest_id?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  group_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  info?: InputMaybe<Scalars['json']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  owner?: InputMaybe<Scalars['String']['input']>;
  role_id?: InputMaybe<Scalars['uuid']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  stale?: InputMaybe<Scalars['Boolean']['input']>;
  test_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Examsonline_File_Sum_Fields = {
  __typename?: 'examsonline_file_sum_fields';
  size?: Maybe<Scalars['Int']['output']>;
  test_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "examsonline.file" */
export type Examsonline_File_Sum_Order_By = {
  size?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
};

/** update columns of table "examsonline.file" */
export enum Examsonline_File_Update_Column {
  /** column name */
  ContestId = 'contest_id',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  Info = 'info',
  /** column name */
  Name = 'name',
  /** column name */
  Owner = 'owner',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  Size = 'size',
  /** column name */
  Stale = 'stale',
  /** column name */
  TestId = 'test_id'
}

export type Examsonline_File_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Examsonline_File_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Examsonline_File_Set_Input>;
  /** filter the rows which have to be updated */
  where: Examsonline_File_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Examsonline_File_Var_Pop_Fields = {
  __typename?: 'examsonline_file_var_pop_fields';
  size?: Maybe<Scalars['Float']['output']>;
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "examsonline.file" */
export type Examsonline_File_Var_Pop_Order_By = {
  size?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Examsonline_File_Var_Samp_Fields = {
  __typename?: 'examsonline_file_var_samp_fields';
  size?: Maybe<Scalars['Float']['output']>;
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "examsonline.file" */
export type Examsonline_File_Var_Samp_Order_By = {
  size?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Examsonline_File_Variance_Fields = {
  __typename?: 'examsonline_file_variance_fields';
  size?: Maybe<Scalars['Float']['output']>;
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "examsonline.file" */
export type Examsonline_File_Variance_Order_By = {
  size?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
};

/** saves group created by authors */
export type Examsonline_Groups = {
  __typename?: 'examsonline_groups';
  /** An array relationship */
  assignments: Array<Examsonline_Assignments>;
  /** An aggregate relationship */
  assignments_aggregate: Examsonline_Assignments_Aggregate;
  /** An array relationship */
  banner_notifications: Array<Examsonline_Banner_Notification>;
  /** An aggregate relationship */
  banner_notifications_aggregate: Examsonline_Banner_Notification_Aggregate;
  createdAt: Scalars['timestamptz']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  files: Array<Examsonline_File>;
  /** An aggregate relationship */
  files_aggregate: Examsonline_File_Aggregate;
  id: Scalars['uuid']['output'];
  /** An array relationship */
  members: Array<Examsonline_Members>;
  /** An aggregate relationship */
  members_aggregate: Examsonline_Members_Aggregate;
  name: Scalars['String']['output'];
  public: Scalars['Boolean']['output'];
  updatedAt: Scalars['timestamptz']['output'];
  user: Scalars['String']['output'];
  /** An object relationship */
  userByUser: Examsonline_User;
};


/** saves group created by authors */
export type Examsonline_GroupsAssignmentsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Assignments_Order_By>>;
  where?: InputMaybe<Examsonline_Assignments_Bool_Exp>;
};


/** saves group created by authors */
export type Examsonline_GroupsAssignments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Assignments_Order_By>>;
  where?: InputMaybe<Examsonline_Assignments_Bool_Exp>;
};


/** saves group created by authors */
export type Examsonline_GroupsBanner_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Banner_Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Banner_Notification_Order_By>>;
  where?: InputMaybe<Examsonline_Banner_Notification_Bool_Exp>;
};


/** saves group created by authors */
export type Examsonline_GroupsBanner_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Banner_Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Banner_Notification_Order_By>>;
  where?: InputMaybe<Examsonline_Banner_Notification_Bool_Exp>;
};


/** saves group created by authors */
export type Examsonline_GroupsFilesArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_File_Order_By>>;
  where?: InputMaybe<Examsonline_File_Bool_Exp>;
};


/** saves group created by authors */
export type Examsonline_GroupsFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_File_Order_By>>;
  where?: InputMaybe<Examsonline_File_Bool_Exp>;
};


/** saves group created by authors */
export type Examsonline_GroupsMembersArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Members_Order_By>>;
  where?: InputMaybe<Examsonline_Members_Bool_Exp>;
};


/** saves group created by authors */
export type Examsonline_GroupsMembers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Members_Order_By>>;
  where?: InputMaybe<Examsonline_Members_Bool_Exp>;
};

/** aggregated selection of "examsonline.groups" */
export type Examsonline_Groups_Aggregate = {
  __typename?: 'examsonline_groups_aggregate';
  aggregate?: Maybe<Examsonline_Groups_Aggregate_Fields>;
  nodes: Array<Examsonline_Groups>;
};

export type Examsonline_Groups_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Examsonline_Groups_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Examsonline_Groups_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Examsonline_Groups_Aggregate_Bool_Exp_Count>;
};

export type Examsonline_Groups_Aggregate_Bool_Exp_Bool_And = {
  arguments: Examsonline_Groups_Select_Column_Examsonline_Groups_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Groups_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Examsonline_Groups_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Examsonline_Groups_Select_Column_Examsonline_Groups_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Groups_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Examsonline_Groups_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Examsonline_Groups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Groups_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "examsonline.groups" */
export type Examsonline_Groups_Aggregate_Fields = {
  __typename?: 'examsonline_groups_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Examsonline_Groups_Max_Fields>;
  min?: Maybe<Examsonline_Groups_Min_Fields>;
};


/** aggregate fields of "examsonline.groups" */
export type Examsonline_Groups_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Examsonline_Groups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "examsonline.groups" */
export type Examsonline_Groups_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Examsonline_Groups_Max_Order_By>;
  min?: InputMaybe<Examsonline_Groups_Min_Order_By>;
};

/** input type for inserting array relation for remote table "examsonline.groups" */
export type Examsonline_Groups_Arr_Rel_Insert_Input = {
  data: Array<Examsonline_Groups_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Examsonline_Groups_On_Conflict>;
};

/** Boolean expression to filter rows from the table "examsonline.groups". All fields are combined with a logical 'AND'. */
export type Examsonline_Groups_Bool_Exp = {
  _and?: InputMaybe<Array<Examsonline_Groups_Bool_Exp>>;
  _not?: InputMaybe<Examsonline_Groups_Bool_Exp>;
  _or?: InputMaybe<Array<Examsonline_Groups_Bool_Exp>>;
  assignments?: InputMaybe<Examsonline_Assignments_Bool_Exp>;
  assignments_aggregate?: InputMaybe<Examsonline_Assignments_Aggregate_Bool_Exp>;
  banner_notifications?: InputMaybe<Examsonline_Banner_Notification_Bool_Exp>;
  banner_notifications_aggregate?: InputMaybe<Examsonline_Banner_Notification_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  files?: InputMaybe<Examsonline_File_Bool_Exp>;
  files_aggregate?: InputMaybe<Examsonline_File_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  members?: InputMaybe<Examsonline_Members_Bool_Exp>;
  members_aggregate?: InputMaybe<Examsonline_Members_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  public?: InputMaybe<Boolean_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<String_Comparison_Exp>;
  userByUser?: InputMaybe<Examsonline_User_Bool_Exp>;
};

/** unique or primary key constraints on table "examsonline.groups" */
export enum Examsonline_Groups_Constraint {
  /** unique or primary key constraint on columns "id" */
  GroupsPkey = 'groups_pkey'
}

/** input type for inserting data into table "examsonline.groups" */
export type Examsonline_Groups_Insert_Input = {
  assignments?: InputMaybe<Examsonline_Assignments_Arr_Rel_Insert_Input>;
  banner_notifications?: InputMaybe<Examsonline_Banner_Notification_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<Examsonline_File_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  members?: InputMaybe<Examsonline_Members_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']['input']>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
  userByUser?: InputMaybe<Examsonline_User_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Examsonline_Groups_Max_Fields = {
  __typename?: 'examsonline_groups_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  user?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "examsonline.groups" */
export type Examsonline_Groups_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Examsonline_Groups_Min_Fields = {
  __typename?: 'examsonline_groups_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  user?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "examsonline.groups" */
export type Examsonline_Groups_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "examsonline.groups" */
export type Examsonline_Groups_Mutation_Response = {
  __typename?: 'examsonline_groups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Examsonline_Groups>;
};

/** input type for inserting object relation for remote table "examsonline.groups" */
export type Examsonline_Groups_Obj_Rel_Insert_Input = {
  data: Examsonline_Groups_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Examsonline_Groups_On_Conflict>;
};

/** on_conflict condition type for table "examsonline.groups" */
export type Examsonline_Groups_On_Conflict = {
  constraint: Examsonline_Groups_Constraint;
  update_columns?: Array<Examsonline_Groups_Update_Column>;
  where?: InputMaybe<Examsonline_Groups_Bool_Exp>;
};

/** Ordering options when selecting data from "examsonline.groups". */
export type Examsonline_Groups_Order_By = {
  assignments_aggregate?: InputMaybe<Examsonline_Assignments_Aggregate_Order_By>;
  banner_notifications_aggregate?: InputMaybe<Examsonline_Banner_Notification_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  files_aggregate?: InputMaybe<Examsonline_File_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  members_aggregate?: InputMaybe<Examsonline_Members_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  public?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
  userByUser?: InputMaybe<Examsonline_User_Order_By>;
};

/** primary key columns input for table: examsonline.groups */
export type Examsonline_Groups_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "examsonline.groups" */
export enum Examsonline_Groups_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Public = 'public',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  User = 'user'
}

/** select "examsonline_groups_aggregate_bool_exp_bool_and_arguments_columns" columns of table "examsonline.groups" */
export enum Examsonline_Groups_Select_Column_Examsonline_Groups_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Public = 'public'
}

/** select "examsonline_groups_aggregate_bool_exp_bool_or_arguments_columns" columns of table "examsonline.groups" */
export enum Examsonline_Groups_Select_Column_Examsonline_Groups_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Public = 'public'
}

/** input type for updating data in table "examsonline.groups" */
export type Examsonline_Groups_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "examsonline_groups" */
export type Examsonline_Groups_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Examsonline_Groups_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Examsonline_Groups_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "examsonline.groups" */
export enum Examsonline_Groups_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Public = 'public',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  User = 'user'
}

export type Examsonline_Groups_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Examsonline_Groups_Set_Input>;
  /** filter the rows which have to be updated */
  where: Examsonline_Groups_Bool_Exp;
};

/** tracks group members */
export type Examsonline_Members = {
  __typename?: 'examsonline_members';
  createdAt: Scalars['timestamptz']['output'];
  email: Scalars['String']['output'];
  /** An object relationship */
  group: Examsonline_Groups;
  group_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  updatedAt: Scalars['timestamptz']['output'];
  user: Scalars['String']['output'];
};

/** aggregated selection of "examsonline.members" */
export type Examsonline_Members_Aggregate = {
  __typename?: 'examsonline_members_aggregate';
  aggregate?: Maybe<Examsonline_Members_Aggregate_Fields>;
  nodes: Array<Examsonline_Members>;
};

export type Examsonline_Members_Aggregate_Bool_Exp = {
  count?: InputMaybe<Examsonline_Members_Aggregate_Bool_Exp_Count>;
};

export type Examsonline_Members_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Examsonline_Members_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Members_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "examsonline.members" */
export type Examsonline_Members_Aggregate_Fields = {
  __typename?: 'examsonline_members_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Examsonline_Members_Max_Fields>;
  min?: Maybe<Examsonline_Members_Min_Fields>;
};


/** aggregate fields of "examsonline.members" */
export type Examsonline_Members_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Examsonline_Members_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "examsonline.members" */
export type Examsonline_Members_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Examsonline_Members_Max_Order_By>;
  min?: InputMaybe<Examsonline_Members_Min_Order_By>;
};

/** input type for inserting array relation for remote table "examsonline.members" */
export type Examsonline_Members_Arr_Rel_Insert_Input = {
  data: Array<Examsonline_Members_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Examsonline_Members_On_Conflict>;
};

/** Boolean expression to filter rows from the table "examsonline.members". All fields are combined with a logical 'AND'. */
export type Examsonline_Members_Bool_Exp = {
  _and?: InputMaybe<Array<Examsonline_Members_Bool_Exp>>;
  _not?: InputMaybe<Examsonline_Members_Bool_Exp>;
  _or?: InputMaybe<Array<Examsonline_Members_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  group?: InputMaybe<Examsonline_Groups_Bool_Exp>;
  group_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "examsonline.members" */
export enum Examsonline_Members_Constraint {
  /** unique or primary key constraint on columns "group_id", "email" */
  MemebersGroupIdEmailKey = 'memebers_group_id_email_key',
  /** unique or primary key constraint on columns "id" */
  MemebersPkey = 'memebers_pkey'
}

/** input type for inserting data into table "examsonline.members" */
export type Examsonline_Members_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  group?: InputMaybe<Examsonline_Groups_Obj_Rel_Insert_Input>;
  group_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Examsonline_Members_Max_Fields = {
  __typename?: 'examsonline_members_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  group_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  user?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "examsonline.members" */
export type Examsonline_Members_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Examsonline_Members_Min_Fields = {
  __typename?: 'examsonline_members_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  group_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  user?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "examsonline.members" */
export type Examsonline_Members_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "examsonline.members" */
export type Examsonline_Members_Mutation_Response = {
  __typename?: 'examsonline_members_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Examsonline_Members>;
};

/** on_conflict condition type for table "examsonline.members" */
export type Examsonline_Members_On_Conflict = {
  constraint: Examsonline_Members_Constraint;
  update_columns?: Array<Examsonline_Members_Update_Column>;
  where?: InputMaybe<Examsonline_Members_Bool_Exp>;
};

/** Ordering options when selecting data from "examsonline.members". */
export type Examsonline_Members_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  group?: InputMaybe<Examsonline_Groups_Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** primary key columns input for table: examsonline.members */
export type Examsonline_Members_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "examsonline.members" */
export enum Examsonline_Members_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  User = 'user'
}

/** input type for updating data in table "examsonline.members" */
export type Examsonline_Members_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  group_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "examsonline_members" */
export type Examsonline_Members_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Examsonline_Members_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Examsonline_Members_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  group_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "examsonline.members" */
export enum Examsonline_Members_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  User = 'user'
}

export type Examsonline_Members_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Examsonline_Members_Set_Input>;
  /** filter the rows which have to be updated */
  where: Examsonline_Members_Bool_Exp;
};

/** table for options */
export type Examsonline_Options = {
  __typename?: 'examsonline_options';
  id: Scalars['Int']['output'];
  isCorrect: Scalars['Boolean']['output'];
  q_id: Scalars['Int']['output'];
  /** An object relationship */
  question: Examsonline_Questions;
  role?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  roleByRole?: Maybe<Examsonline_Roles>;
  /** An array relationship */
  selected_options: Array<Examsonline_Selected_Options>;
  /** An aggregate relationship */
  selected_options_aggregate: Examsonline_Selected_Options_Aggregate;
  type: Scalars['Int']['output'];
  user?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  user_option?: Maybe<Examsonline_User>;
  value: Scalars['String']['output'];
};


/** table for options */
export type Examsonline_OptionsSelected_OptionsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Selected_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Selected_Options_Order_By>>;
  where?: InputMaybe<Examsonline_Selected_Options_Bool_Exp>;
};


/** table for options */
export type Examsonline_OptionsSelected_Options_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Selected_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Selected_Options_Order_By>>;
  where?: InputMaybe<Examsonline_Selected_Options_Bool_Exp>;
};

/** aggregated selection of "examsonline.options" */
export type Examsonline_Options_Aggregate = {
  __typename?: 'examsonline_options_aggregate';
  aggregate?: Maybe<Examsonline_Options_Aggregate_Fields>;
  nodes: Array<Examsonline_Options>;
};

export type Examsonline_Options_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Examsonline_Options_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Examsonline_Options_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Examsonline_Options_Aggregate_Bool_Exp_Count>;
};

export type Examsonline_Options_Aggregate_Bool_Exp_Bool_And = {
  arguments: Examsonline_Options_Select_Column_Examsonline_Options_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Options_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Examsonline_Options_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Examsonline_Options_Select_Column_Examsonline_Options_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Options_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Examsonline_Options_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Examsonline_Options_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Options_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "examsonline.options" */
export type Examsonline_Options_Aggregate_Fields = {
  __typename?: 'examsonline_options_aggregate_fields';
  avg?: Maybe<Examsonline_Options_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Examsonline_Options_Max_Fields>;
  min?: Maybe<Examsonline_Options_Min_Fields>;
  stddev?: Maybe<Examsonline_Options_Stddev_Fields>;
  stddev_pop?: Maybe<Examsonline_Options_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Examsonline_Options_Stddev_Samp_Fields>;
  sum?: Maybe<Examsonline_Options_Sum_Fields>;
  var_pop?: Maybe<Examsonline_Options_Var_Pop_Fields>;
  var_samp?: Maybe<Examsonline_Options_Var_Samp_Fields>;
  variance?: Maybe<Examsonline_Options_Variance_Fields>;
};


/** aggregate fields of "examsonline.options" */
export type Examsonline_Options_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Examsonline_Options_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "examsonline.options" */
export type Examsonline_Options_Aggregate_Order_By = {
  avg?: InputMaybe<Examsonline_Options_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Examsonline_Options_Max_Order_By>;
  min?: InputMaybe<Examsonline_Options_Min_Order_By>;
  stddev?: InputMaybe<Examsonline_Options_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Examsonline_Options_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Examsonline_Options_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Examsonline_Options_Sum_Order_By>;
  var_pop?: InputMaybe<Examsonline_Options_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Examsonline_Options_Var_Samp_Order_By>;
  variance?: InputMaybe<Examsonline_Options_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "examsonline.options" */
export type Examsonline_Options_Arr_Rel_Insert_Input = {
  data: Array<Examsonline_Options_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Examsonline_Options_On_Conflict>;
};

/** aggregate avg on columns */
export type Examsonline_Options_Avg_Fields = {
  __typename?: 'examsonline_options_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  q_id?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "examsonline.options" */
export type Examsonline_Options_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  q_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "examsonline.options". All fields are combined with a logical 'AND'. */
export type Examsonline_Options_Bool_Exp = {
  _and?: InputMaybe<Array<Examsonline_Options_Bool_Exp>>;
  _not?: InputMaybe<Examsonline_Options_Bool_Exp>;
  _or?: InputMaybe<Array<Examsonline_Options_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  isCorrect?: InputMaybe<Boolean_Comparison_Exp>;
  q_id?: InputMaybe<Int_Comparison_Exp>;
  question?: InputMaybe<Examsonline_Questions_Bool_Exp>;
  role?: InputMaybe<Uuid_Comparison_Exp>;
  roleByRole?: InputMaybe<Examsonline_Roles_Bool_Exp>;
  selected_options?: InputMaybe<Examsonline_Selected_Options_Bool_Exp>;
  selected_options_aggregate?: InputMaybe<Examsonline_Selected_Options_Aggregate_Bool_Exp>;
  type?: InputMaybe<Int_Comparison_Exp>;
  user?: InputMaybe<String_Comparison_Exp>;
  user_option?: InputMaybe<Examsonline_User_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "examsonline.options" */
export enum Examsonline_Options_Constraint {
  /** unique or primary key constraint on columns "id", "q_id" */
  OptionsIdQIdKey = 'options_id_q_id_key',
  /** unique or primary key constraint on columns "id" */
  OptionsPkey = 'options_pkey'
}

/** input type for incrementing numeric columns in table "examsonline.options" */
export type Examsonline_Options_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  q_id?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "examsonline.options" */
export type Examsonline_Options_Insert_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  isCorrect?: InputMaybe<Scalars['Boolean']['input']>;
  q_id?: InputMaybe<Scalars['Int']['input']>;
  question?: InputMaybe<Examsonline_Questions_Obj_Rel_Insert_Input>;
  role?: InputMaybe<Scalars['uuid']['input']>;
  roleByRole?: InputMaybe<Examsonline_Roles_Obj_Rel_Insert_Input>;
  selected_options?: InputMaybe<Examsonline_Selected_Options_Arr_Rel_Insert_Input>;
  type?: InputMaybe<Scalars['Int']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
  user_option?: InputMaybe<Examsonline_User_Obj_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Examsonline_Options_Max_Fields = {
  __typename?: 'examsonline_options_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  q_id?: Maybe<Scalars['Int']['output']>;
  role?: Maybe<Scalars['uuid']['output']>;
  type?: Maybe<Scalars['Int']['output']>;
  user?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "examsonline.options" */
export type Examsonline_Options_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  q_id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Examsonline_Options_Min_Fields = {
  __typename?: 'examsonline_options_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  q_id?: Maybe<Scalars['Int']['output']>;
  role?: Maybe<Scalars['uuid']['output']>;
  type?: Maybe<Scalars['Int']['output']>;
  user?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "examsonline.options" */
export type Examsonline_Options_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  q_id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "examsonline.options" */
export type Examsonline_Options_Mutation_Response = {
  __typename?: 'examsonline_options_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Examsonline_Options>;
};

/** input type for inserting object relation for remote table "examsonline.options" */
export type Examsonline_Options_Obj_Rel_Insert_Input = {
  data: Examsonline_Options_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Examsonline_Options_On_Conflict>;
};

/** on_conflict condition type for table "examsonline.options" */
export type Examsonline_Options_On_Conflict = {
  constraint: Examsonline_Options_Constraint;
  update_columns?: Array<Examsonline_Options_Update_Column>;
  where?: InputMaybe<Examsonline_Options_Bool_Exp>;
};

/** Ordering options when selecting data from "examsonline.options". */
export type Examsonline_Options_Order_By = {
  id?: InputMaybe<Order_By>;
  isCorrect?: InputMaybe<Order_By>;
  q_id?: InputMaybe<Order_By>;
  question?: InputMaybe<Examsonline_Questions_Order_By>;
  role?: InputMaybe<Order_By>;
  roleByRole?: InputMaybe<Examsonline_Roles_Order_By>;
  selected_options_aggregate?: InputMaybe<Examsonline_Selected_Options_Aggregate_Order_By>;
  type?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
  user_option?: InputMaybe<Examsonline_User_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: examsonline.options */
export type Examsonline_Options_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "examsonline.options" */
export enum Examsonline_Options_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsCorrect = 'isCorrect',
  /** column name */
  QId = 'q_id',
  /** column name */
  Role = 'role',
  /** column name */
  Type = 'type',
  /** column name */
  User = 'user',
  /** column name */
  Value = 'value'
}

/** select "examsonline_options_aggregate_bool_exp_bool_and_arguments_columns" columns of table "examsonline.options" */
export enum Examsonline_Options_Select_Column_Examsonline_Options_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsCorrect = 'isCorrect'
}

/** select "examsonline_options_aggregate_bool_exp_bool_or_arguments_columns" columns of table "examsonline.options" */
export enum Examsonline_Options_Select_Column_Examsonline_Options_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsCorrect = 'isCorrect'
}

/** input type for updating data in table "examsonline.options" */
export type Examsonline_Options_Set_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  isCorrect?: InputMaybe<Scalars['Boolean']['input']>;
  q_id?: InputMaybe<Scalars['Int']['input']>;
  role?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<Scalars['Int']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Examsonline_Options_Stddev_Fields = {
  __typename?: 'examsonline_options_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  q_id?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "examsonline.options" */
export type Examsonline_Options_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  q_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Examsonline_Options_Stddev_Pop_Fields = {
  __typename?: 'examsonline_options_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  q_id?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "examsonline.options" */
export type Examsonline_Options_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  q_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Examsonline_Options_Stddev_Samp_Fields = {
  __typename?: 'examsonline_options_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  q_id?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "examsonline.options" */
export type Examsonline_Options_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  q_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "examsonline_options" */
export type Examsonline_Options_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Examsonline_Options_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Examsonline_Options_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  isCorrect?: InputMaybe<Scalars['Boolean']['input']>;
  q_id?: InputMaybe<Scalars['Int']['input']>;
  role?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<Scalars['Int']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Examsonline_Options_Sum_Fields = {
  __typename?: 'examsonline_options_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  q_id?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "examsonline.options" */
export type Examsonline_Options_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  q_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** update columns of table "examsonline.options" */
export enum Examsonline_Options_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsCorrect = 'isCorrect',
  /** column name */
  QId = 'q_id',
  /** column name */
  Role = 'role',
  /** column name */
  Type = 'type',
  /** column name */
  User = 'user',
  /** column name */
  Value = 'value'
}

export type Examsonline_Options_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Examsonline_Options_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Examsonline_Options_Set_Input>;
  /** filter the rows which have to be updated */
  where: Examsonline_Options_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Examsonline_Options_Var_Pop_Fields = {
  __typename?: 'examsonline_options_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  q_id?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "examsonline.options" */
export type Examsonline_Options_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  q_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Examsonline_Options_Var_Samp_Fields = {
  __typename?: 'examsonline_options_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  q_id?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "examsonline.options" */
export type Examsonline_Options_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  q_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Examsonline_Options_Variance_Fields = {
  __typename?: 'examsonline_options_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  q_id?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "examsonline.options" */
export type Examsonline_Options_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  q_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** stores order details */
export type Examsonline_Order = {
  __typename?: 'examsonline_order';
  amount: Scalars['numeric']['output'];
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  plan?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  role: Examsonline_Roles;
  role_id: Scalars['uuid']['output'];
  status: Scalars['Boolean']['output'];
  /** An object relationship */
  test?: Maybe<Examsonline_Tests>;
  test_id?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregated selection of "examsonline.order" */
export type Examsonline_Order_Aggregate = {
  __typename?: 'examsonline_order_aggregate';
  aggregate?: Maybe<Examsonline_Order_Aggregate_Fields>;
  nodes: Array<Examsonline_Order>;
};

export type Examsonline_Order_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Examsonline_Order_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Examsonline_Order_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Examsonline_Order_Aggregate_Bool_Exp_Count>;
};

export type Examsonline_Order_Aggregate_Bool_Exp_Bool_And = {
  arguments: Examsonline_Order_Select_Column_Examsonline_Order_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Order_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Examsonline_Order_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Examsonline_Order_Select_Column_Examsonline_Order_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Order_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Examsonline_Order_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Examsonline_Order_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Order_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "examsonline.order" */
export type Examsonline_Order_Aggregate_Fields = {
  __typename?: 'examsonline_order_aggregate_fields';
  avg?: Maybe<Examsonline_Order_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Examsonline_Order_Max_Fields>;
  min?: Maybe<Examsonline_Order_Min_Fields>;
  stddev?: Maybe<Examsonline_Order_Stddev_Fields>;
  stddev_pop?: Maybe<Examsonline_Order_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Examsonline_Order_Stddev_Samp_Fields>;
  sum?: Maybe<Examsonline_Order_Sum_Fields>;
  var_pop?: Maybe<Examsonline_Order_Var_Pop_Fields>;
  var_samp?: Maybe<Examsonline_Order_Var_Samp_Fields>;
  variance?: Maybe<Examsonline_Order_Variance_Fields>;
};


/** aggregate fields of "examsonline.order" */
export type Examsonline_Order_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Examsonline_Order_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "examsonline.order" */
export type Examsonline_Order_Aggregate_Order_By = {
  avg?: InputMaybe<Examsonline_Order_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Examsonline_Order_Max_Order_By>;
  min?: InputMaybe<Examsonline_Order_Min_Order_By>;
  stddev?: InputMaybe<Examsonline_Order_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Examsonline_Order_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Examsonline_Order_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Examsonline_Order_Sum_Order_By>;
  var_pop?: InputMaybe<Examsonline_Order_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Examsonline_Order_Var_Samp_Order_By>;
  variance?: InputMaybe<Examsonline_Order_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "examsonline.order" */
export type Examsonline_Order_Arr_Rel_Insert_Input = {
  data: Array<Examsonline_Order_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Examsonline_Order_On_Conflict>;
};

/** aggregate avg on columns */
export type Examsonline_Order_Avg_Fields = {
  __typename?: 'examsonline_order_avg_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "examsonline.order" */
export type Examsonline_Order_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "examsonline.order". All fields are combined with a logical 'AND'. */
export type Examsonline_Order_Bool_Exp = {
  _and?: InputMaybe<Array<Examsonline_Order_Bool_Exp>>;
  _not?: InputMaybe<Examsonline_Order_Bool_Exp>;
  _or?: InputMaybe<Array<Examsonline_Order_Bool_Exp>>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  plan?: InputMaybe<String_Comparison_Exp>;
  role?: InputMaybe<Examsonline_Roles_Bool_Exp>;
  role_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Boolean_Comparison_Exp>;
  test?: InputMaybe<Examsonline_Tests_Bool_Exp>;
  test_id?: InputMaybe<Int_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "examsonline.order" */
export enum Examsonline_Order_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrderPkey = 'order_pkey'
}

/** input type for incrementing numeric columns in table "examsonline.order" */
export type Examsonline_Order_Inc_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  test_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "examsonline.order" */
export type Examsonline_Order_Insert_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  plan?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Examsonline_Roles_Obj_Rel_Insert_Input>;
  role_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Scalars['Boolean']['input']>;
  test?: InputMaybe<Examsonline_Tests_Obj_Rel_Insert_Input>;
  test_id?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Examsonline_Order_Max_Fields = {
  __typename?: 'examsonline_order_max_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  plan?: Maybe<Scalars['String']['output']>;
  role_id?: Maybe<Scalars['uuid']['output']>;
  test_id?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "examsonline.order" */
export type Examsonline_Order_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plan?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Examsonline_Order_Min_Fields = {
  __typename?: 'examsonline_order_min_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  plan?: Maybe<Scalars['String']['output']>;
  role_id?: Maybe<Scalars['uuid']['output']>;
  test_id?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "examsonline.order" */
export type Examsonline_Order_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plan?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "examsonline.order" */
export type Examsonline_Order_Mutation_Response = {
  __typename?: 'examsonline_order_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Examsonline_Order>;
};

/** on_conflict condition type for table "examsonline.order" */
export type Examsonline_Order_On_Conflict = {
  constraint: Examsonline_Order_Constraint;
  update_columns?: Array<Examsonline_Order_Update_Column>;
  where?: InputMaybe<Examsonline_Order_Bool_Exp>;
};

/** Ordering options when selecting data from "examsonline.order". */
export type Examsonline_Order_Order_By = {
  amount?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plan?: InputMaybe<Order_By>;
  role?: InputMaybe<Examsonline_Roles_Order_By>;
  role_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  test?: InputMaybe<Examsonline_Tests_Order_By>;
  test_id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: examsonline.order */
export type Examsonline_Order_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "examsonline.order" */
export enum Examsonline_Order_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Plan = 'plan',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  Status = 'status',
  /** column name */
  TestId = 'test_id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "examsonline_order_aggregate_bool_exp_bool_and_arguments_columns" columns of table "examsonline.order" */
export enum Examsonline_Order_Select_Column_Examsonline_Order_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Status = 'status'
}

/** select "examsonline_order_aggregate_bool_exp_bool_or_arguments_columns" columns of table "examsonline.order" */
export enum Examsonline_Order_Select_Column_Examsonline_Order_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "examsonline.order" */
export type Examsonline_Order_Set_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  plan?: InputMaybe<Scalars['String']['input']>;
  role_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Scalars['Boolean']['input']>;
  test_id?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Examsonline_Order_Stddev_Fields = {
  __typename?: 'examsonline_order_stddev_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "examsonline.order" */
export type Examsonline_Order_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Examsonline_Order_Stddev_Pop_Fields = {
  __typename?: 'examsonline_order_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "examsonline.order" */
export type Examsonline_Order_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Examsonline_Order_Stddev_Samp_Fields = {
  __typename?: 'examsonline_order_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "examsonline.order" */
export type Examsonline_Order_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "examsonline_order" */
export type Examsonline_Order_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Examsonline_Order_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Examsonline_Order_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  plan?: InputMaybe<Scalars['String']['input']>;
  role_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Scalars['Boolean']['input']>;
  test_id?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Examsonline_Order_Sum_Fields = {
  __typename?: 'examsonline_order_sum_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
  test_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "examsonline.order" */
export type Examsonline_Order_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
};

/** update columns of table "examsonline.order" */
export enum Examsonline_Order_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Plan = 'plan',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  Status = 'status',
  /** column name */
  TestId = 'test_id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Examsonline_Order_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Examsonline_Order_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Examsonline_Order_Set_Input>;
  /** filter the rows which have to be updated */
  where: Examsonline_Order_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Examsonline_Order_Var_Pop_Fields = {
  __typename?: 'examsonline_order_var_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "examsonline.order" */
export type Examsonline_Order_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Examsonline_Order_Var_Samp_Fields = {
  __typename?: 'examsonline_order_var_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "examsonline.order" */
export type Examsonline_Order_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Examsonline_Order_Variance_Fields = {
  __typename?: 'examsonline_order_variance_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "examsonline.order" */
export type Examsonline_Order_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
};

/** org data */
export type Examsonline_Organisation = {
  __typename?: 'examsonline_organisation';
  activated?: Maybe<Scalars['Boolean']['output']>;
  activationCode?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  banner_notifications: Array<Examsonline_Banner_Notification>;
  /** An aggregate relationship */
  banner_notifications_aggregate: Examsonline_Banner_Notification_Aggregate;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  metadata: Scalars['jsonb']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['numeric']['output']>;
  plan: Scalars['String']['output'];
  /** An object relationship */
  planByPlan: Examsonline_Plan;
  /** An array relationship */
  roles: Array<Examsonline_Roles>;
  /** An aggregate relationship */
  roles_aggregate: Examsonline_Roles_Aggregate;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  users: Array<Examsonline_User>;
  /** An aggregate relationship */
  users_aggregate: Examsonline_User_Aggregate;
};


/** org data */
export type Examsonline_OrganisationBanner_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Banner_Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Banner_Notification_Order_By>>;
  where?: InputMaybe<Examsonline_Banner_Notification_Bool_Exp>;
};


/** org data */
export type Examsonline_OrganisationBanner_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Banner_Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Banner_Notification_Order_By>>;
  where?: InputMaybe<Examsonline_Banner_Notification_Bool_Exp>;
};


/** org data */
export type Examsonline_OrganisationMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** org data */
export type Examsonline_OrganisationRolesArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Roles_Order_By>>;
  where?: InputMaybe<Examsonline_Roles_Bool_Exp>;
};


/** org data */
export type Examsonline_OrganisationRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Roles_Order_By>>;
  where?: InputMaybe<Examsonline_Roles_Bool_Exp>;
};


/** org data */
export type Examsonline_OrganisationUsersArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_User_Order_By>>;
  where?: InputMaybe<Examsonline_User_Bool_Exp>;
};


/** org data */
export type Examsonline_OrganisationUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_User_Order_By>>;
  where?: InputMaybe<Examsonline_User_Bool_Exp>;
};

/** aggregated selection of "examsonline.organisation" */
export type Examsonline_Organisation_Aggregate = {
  __typename?: 'examsonline_organisation_aggregate';
  aggregate?: Maybe<Examsonline_Organisation_Aggregate_Fields>;
  nodes: Array<Examsonline_Organisation>;
};

export type Examsonline_Organisation_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Examsonline_Organisation_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Examsonline_Organisation_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Examsonline_Organisation_Aggregate_Bool_Exp_Count>;
};

export type Examsonline_Organisation_Aggregate_Bool_Exp_Bool_And = {
  arguments: Examsonline_Organisation_Select_Column_Examsonline_Organisation_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Organisation_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Examsonline_Organisation_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Examsonline_Organisation_Select_Column_Examsonline_Organisation_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Organisation_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Examsonline_Organisation_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Examsonline_Organisation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Organisation_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "examsonline.organisation" */
export type Examsonline_Organisation_Aggregate_Fields = {
  __typename?: 'examsonline_organisation_aggregate_fields';
  avg?: Maybe<Examsonline_Organisation_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Examsonline_Organisation_Max_Fields>;
  min?: Maybe<Examsonline_Organisation_Min_Fields>;
  stddev?: Maybe<Examsonline_Organisation_Stddev_Fields>;
  stddev_pop?: Maybe<Examsonline_Organisation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Examsonline_Organisation_Stddev_Samp_Fields>;
  sum?: Maybe<Examsonline_Organisation_Sum_Fields>;
  var_pop?: Maybe<Examsonline_Organisation_Var_Pop_Fields>;
  var_samp?: Maybe<Examsonline_Organisation_Var_Samp_Fields>;
  variance?: Maybe<Examsonline_Organisation_Variance_Fields>;
};


/** aggregate fields of "examsonline.organisation" */
export type Examsonline_Organisation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Examsonline_Organisation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "examsonline.organisation" */
export type Examsonline_Organisation_Aggregate_Order_By = {
  avg?: InputMaybe<Examsonline_Organisation_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Examsonline_Organisation_Max_Order_By>;
  min?: InputMaybe<Examsonline_Organisation_Min_Order_By>;
  stddev?: InputMaybe<Examsonline_Organisation_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Examsonline_Organisation_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Examsonline_Organisation_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Examsonline_Organisation_Sum_Order_By>;
  var_pop?: InputMaybe<Examsonline_Organisation_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Examsonline_Organisation_Var_Samp_Order_By>;
  variance?: InputMaybe<Examsonline_Organisation_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Examsonline_Organisation_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "examsonline.organisation" */
export type Examsonline_Organisation_Arr_Rel_Insert_Input = {
  data: Array<Examsonline_Organisation_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Examsonline_Organisation_On_Conflict>;
};

/** aggregate avg on columns */
export type Examsonline_Organisation_Avg_Fields = {
  __typename?: 'examsonline_organisation_avg_fields';
  phone?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "examsonline.organisation" */
export type Examsonline_Organisation_Avg_Order_By = {
  phone?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "examsonline.organisation". All fields are combined with a logical 'AND'. */
export type Examsonline_Organisation_Bool_Exp = {
  _and?: InputMaybe<Array<Examsonline_Organisation_Bool_Exp>>;
  _not?: InputMaybe<Examsonline_Organisation_Bool_Exp>;
  _or?: InputMaybe<Array<Examsonline_Organisation_Bool_Exp>>;
  activated?: InputMaybe<Boolean_Comparison_Exp>;
  activationCode?: InputMaybe<String_Comparison_Exp>;
  banner_notifications?: InputMaybe<Examsonline_Banner_Notification_Bool_Exp>;
  banner_notifications_aggregate?: InputMaybe<Examsonline_Banner_Notification_Aggregate_Bool_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  phone?: InputMaybe<Numeric_Comparison_Exp>;
  plan?: InputMaybe<String_Comparison_Exp>;
  planByPlan?: InputMaybe<Examsonline_Plan_Bool_Exp>;
  roles?: InputMaybe<Examsonline_Roles_Bool_Exp>;
  roles_aggregate?: InputMaybe<Examsonline_Roles_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  users?: InputMaybe<Examsonline_User_Bool_Exp>;
  users_aggregate?: InputMaybe<Examsonline_User_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "examsonline.organisation" */
export enum Examsonline_Organisation_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrganisationPkey = 'organisation_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Examsonline_Organisation_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Examsonline_Organisation_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Examsonline_Organisation_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "examsonline.organisation" */
export type Examsonline_Organisation_Inc_Input = {
  phone?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "examsonline.organisation" */
export type Examsonline_Organisation_Insert_Input = {
  activated?: InputMaybe<Scalars['Boolean']['input']>;
  activationCode?: InputMaybe<Scalars['String']['input']>;
  banner_notifications?: InputMaybe<Examsonline_Banner_Notification_Arr_Rel_Insert_Input>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['numeric']['input']>;
  plan?: InputMaybe<Scalars['String']['input']>;
  planByPlan?: InputMaybe<Examsonline_Plan_Obj_Rel_Insert_Input>;
  roles?: InputMaybe<Examsonline_Roles_Arr_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  users?: InputMaybe<Examsonline_User_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Examsonline_Organisation_Max_Fields = {
  __typename?: 'examsonline_organisation_max_fields';
  activationCode?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['numeric']['output']>;
  plan?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "examsonline.organisation" */
export type Examsonline_Organisation_Max_Order_By = {
  activationCode?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  plan?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Examsonline_Organisation_Min_Fields = {
  __typename?: 'examsonline_organisation_min_fields';
  activationCode?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['numeric']['output']>;
  plan?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "examsonline.organisation" */
export type Examsonline_Organisation_Min_Order_By = {
  activationCode?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  plan?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "examsonline.organisation" */
export type Examsonline_Organisation_Mutation_Response = {
  __typename?: 'examsonline_organisation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Examsonline_Organisation>;
};

/** input type for inserting object relation for remote table "examsonline.organisation" */
export type Examsonline_Organisation_Obj_Rel_Insert_Input = {
  data: Examsonline_Organisation_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Examsonline_Organisation_On_Conflict>;
};

/** on_conflict condition type for table "examsonline.organisation" */
export type Examsonline_Organisation_On_Conflict = {
  constraint: Examsonline_Organisation_Constraint;
  update_columns?: Array<Examsonline_Organisation_Update_Column>;
  where?: InputMaybe<Examsonline_Organisation_Bool_Exp>;
};

/** Ordering options when selecting data from "examsonline.organisation". */
export type Examsonline_Organisation_Order_By = {
  activated?: InputMaybe<Order_By>;
  activationCode?: InputMaybe<Order_By>;
  banner_notifications_aggregate?: InputMaybe<Examsonline_Banner_Notification_Aggregate_Order_By>;
  country?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  plan?: InputMaybe<Order_By>;
  planByPlan?: InputMaybe<Examsonline_Plan_Order_By>;
  roles_aggregate?: InputMaybe<Examsonline_Roles_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  users_aggregate?: InputMaybe<Examsonline_User_Aggregate_Order_By>;
};

/** primary key columns input for table: examsonline.organisation */
export type Examsonline_Organisation_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Examsonline_Organisation_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "examsonline.organisation" */
export enum Examsonline_Organisation_Select_Column {
  /** column name */
  Activated = 'activated',
  /** column name */
  ActivationCode = 'activationCode',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
  /** column name */
  Plan = 'plan',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "examsonline_organisation_aggregate_bool_exp_bool_and_arguments_columns" columns of table "examsonline.organisation" */
export enum Examsonline_Organisation_Select_Column_Examsonline_Organisation_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Activated = 'activated'
}

/** select "examsonline_organisation_aggregate_bool_exp_bool_or_arguments_columns" columns of table "examsonline.organisation" */
export enum Examsonline_Organisation_Select_Column_Examsonline_Organisation_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Activated = 'activated'
}

/** input type for updating data in table "examsonline.organisation" */
export type Examsonline_Organisation_Set_Input = {
  activated?: InputMaybe<Scalars['Boolean']['input']>;
  activationCode?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['numeric']['input']>;
  plan?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Examsonline_Organisation_Stddev_Fields = {
  __typename?: 'examsonline_organisation_stddev_fields';
  phone?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "examsonline.organisation" */
export type Examsonline_Organisation_Stddev_Order_By = {
  phone?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Examsonline_Organisation_Stddev_Pop_Fields = {
  __typename?: 'examsonline_organisation_stddev_pop_fields';
  phone?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "examsonline.organisation" */
export type Examsonline_Organisation_Stddev_Pop_Order_By = {
  phone?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Examsonline_Organisation_Stddev_Samp_Fields = {
  __typename?: 'examsonline_organisation_stddev_samp_fields';
  phone?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "examsonline.organisation" */
export type Examsonline_Organisation_Stddev_Samp_Order_By = {
  phone?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "examsonline_organisation" */
export type Examsonline_Organisation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Examsonline_Organisation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Examsonline_Organisation_Stream_Cursor_Value_Input = {
  activated?: InputMaybe<Scalars['Boolean']['input']>;
  activationCode?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['numeric']['input']>;
  plan?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Examsonline_Organisation_Sum_Fields = {
  __typename?: 'examsonline_organisation_sum_fields';
  phone?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "examsonline.organisation" */
export type Examsonline_Organisation_Sum_Order_By = {
  phone?: InputMaybe<Order_By>;
};

/** update columns of table "examsonline.organisation" */
export enum Examsonline_Organisation_Update_Column {
  /** column name */
  Activated = 'activated',
  /** column name */
  ActivationCode = 'activationCode',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
  /** column name */
  Plan = 'plan',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Examsonline_Organisation_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Examsonline_Organisation_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Examsonline_Organisation_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Examsonline_Organisation_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Examsonline_Organisation_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Examsonline_Organisation_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Examsonline_Organisation_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Examsonline_Organisation_Set_Input>;
  /** filter the rows which have to be updated */
  where: Examsonline_Organisation_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Examsonline_Organisation_Var_Pop_Fields = {
  __typename?: 'examsonline_organisation_var_pop_fields';
  phone?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "examsonline.organisation" */
export type Examsonline_Organisation_Var_Pop_Order_By = {
  phone?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Examsonline_Organisation_Var_Samp_Fields = {
  __typename?: 'examsonline_organisation_var_samp_fields';
  phone?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "examsonline.organisation" */
export type Examsonline_Organisation_Var_Samp_Order_By = {
  phone?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Examsonline_Organisation_Variance_Fields = {
  __typename?: 'examsonline_organisation_variance_fields';
  phone?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "examsonline.organisation" */
export type Examsonline_Organisation_Variance_Order_By = {
  phone?: InputMaybe<Order_By>;
};

/** saves participants for events */
export type Examsonline_Participate = {
  __typename?: 'examsonline_participate';
  /** An object relationship */
  contest?: Maybe<Examsonline_Contest>;
  contest_id?: Maybe<Scalars['uuid']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  email: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  updatedAt: Scalars['timestamptz']['output'];
};

/** aggregated selection of "examsonline.participate" */
export type Examsonline_Participate_Aggregate = {
  __typename?: 'examsonline_participate_aggregate';
  aggregate?: Maybe<Examsonline_Participate_Aggregate_Fields>;
  nodes: Array<Examsonline_Participate>;
};

export type Examsonline_Participate_Aggregate_Bool_Exp = {
  count?: InputMaybe<Examsonline_Participate_Aggregate_Bool_Exp_Count>;
};

export type Examsonline_Participate_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Examsonline_Participate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Participate_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "examsonline.participate" */
export type Examsonline_Participate_Aggregate_Fields = {
  __typename?: 'examsonline_participate_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Examsonline_Participate_Max_Fields>;
  min?: Maybe<Examsonline_Participate_Min_Fields>;
};


/** aggregate fields of "examsonline.participate" */
export type Examsonline_Participate_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Examsonline_Participate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "examsonline.participate" */
export type Examsonline_Participate_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Examsonline_Participate_Max_Order_By>;
  min?: InputMaybe<Examsonline_Participate_Min_Order_By>;
};

/** input type for inserting array relation for remote table "examsonline.participate" */
export type Examsonline_Participate_Arr_Rel_Insert_Input = {
  data: Array<Examsonline_Participate_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Examsonline_Participate_On_Conflict>;
};

/** Boolean expression to filter rows from the table "examsonline.participate". All fields are combined with a logical 'AND'. */
export type Examsonline_Participate_Bool_Exp = {
  _and?: InputMaybe<Array<Examsonline_Participate_Bool_Exp>>;
  _not?: InputMaybe<Examsonline_Participate_Bool_Exp>;
  _or?: InputMaybe<Array<Examsonline_Participate_Bool_Exp>>;
  contest?: InputMaybe<Examsonline_Contest_Bool_Exp>;
  contest_id?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "examsonline.participate" */
export enum Examsonline_Participate_Constraint {
  /** unique or primary key constraint on columns "id" */
  ParticipatePkey = 'participate_pkey'
}

/** input type for inserting data into table "examsonline.participate" */
export type Examsonline_Participate_Insert_Input = {
  contest?: InputMaybe<Examsonline_Contest_Obj_Rel_Insert_Input>;
  contest_id?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Examsonline_Participate_Max_Fields = {
  __typename?: 'examsonline_participate_max_fields';
  contest_id?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "examsonline.participate" */
export type Examsonline_Participate_Max_Order_By = {
  contest_id?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Examsonline_Participate_Min_Fields = {
  __typename?: 'examsonline_participate_min_fields';
  contest_id?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "examsonline.participate" */
export type Examsonline_Participate_Min_Order_By = {
  contest_id?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "examsonline.participate" */
export type Examsonline_Participate_Mutation_Response = {
  __typename?: 'examsonline_participate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Examsonline_Participate>;
};

/** on_conflict condition type for table "examsonline.participate" */
export type Examsonline_Participate_On_Conflict = {
  constraint: Examsonline_Participate_Constraint;
  update_columns?: Array<Examsonline_Participate_Update_Column>;
  where?: InputMaybe<Examsonline_Participate_Bool_Exp>;
};

/** Ordering options when selecting data from "examsonline.participate". */
export type Examsonline_Participate_Order_By = {
  contest?: InputMaybe<Examsonline_Contest_Order_By>;
  contest_id?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: examsonline.participate */
export type Examsonline_Participate_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "examsonline.participate" */
export enum Examsonline_Participate_Select_Column {
  /** column name */
  ContestId = 'contest_id',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "examsonline.participate" */
export type Examsonline_Participate_Set_Input = {
  contest_id?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "examsonline_participate" */
export type Examsonline_Participate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Examsonline_Participate_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Examsonline_Participate_Stream_Cursor_Value_Input = {
  contest_id?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "examsonline.participate" */
export enum Examsonline_Participate_Update_Column {
  /** column name */
  ContestId = 'contest_id',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Examsonline_Participate_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Examsonline_Participate_Set_Input>;
  /** filter the rows which have to be updated */
  where: Examsonline_Participate_Bool_Exp;
};

/** contains details for platform payment */
export type Examsonline_Payment_Details = {
  __typename?: 'examsonline_payment_details';
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  org_id: Scalars['uuid']['output'];
  qr_code?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

/** aggregated selection of "examsonline.payment_details" */
export type Examsonline_Payment_Details_Aggregate = {
  __typename?: 'examsonline_payment_details_aggregate';
  aggregate?: Maybe<Examsonline_Payment_Details_Aggregate_Fields>;
  nodes: Array<Examsonline_Payment_Details>;
};

/** aggregate fields of "examsonline.payment_details" */
export type Examsonline_Payment_Details_Aggregate_Fields = {
  __typename?: 'examsonline_payment_details_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Examsonline_Payment_Details_Max_Fields>;
  min?: Maybe<Examsonline_Payment_Details_Min_Fields>;
};


/** aggregate fields of "examsonline.payment_details" */
export type Examsonline_Payment_Details_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Examsonline_Payment_Details_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "examsonline.payment_details". All fields are combined with a logical 'AND'. */
export type Examsonline_Payment_Details_Bool_Exp = {
  _and?: InputMaybe<Array<Examsonline_Payment_Details_Bool_Exp>>;
  _not?: InputMaybe<Examsonline_Payment_Details_Bool_Exp>;
  _or?: InputMaybe<Array<Examsonline_Payment_Details_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  org_id?: InputMaybe<Uuid_Comparison_Exp>;
  qr_code?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "examsonline.payment_details" */
export enum Examsonline_Payment_Details_Constraint {
  /** unique or primary key constraint on columns "id" */
  PaymentDetailsPkey = 'payment_details_pkey'
}

/** input type for inserting data into table "examsonline.payment_details" */
export type Examsonline_Payment_Details_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  qr_code?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Examsonline_Payment_Details_Max_Fields = {
  __typename?: 'examsonline_payment_details_max_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  qr_code?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Examsonline_Payment_Details_Min_Fields = {
  __typename?: 'examsonline_payment_details_min_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  qr_code?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "examsonline.payment_details" */
export type Examsonline_Payment_Details_Mutation_Response = {
  __typename?: 'examsonline_payment_details_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Examsonline_Payment_Details>;
};

/** on_conflict condition type for table "examsonline.payment_details" */
export type Examsonline_Payment_Details_On_Conflict = {
  constraint: Examsonline_Payment_Details_Constraint;
  update_columns?: Array<Examsonline_Payment_Details_Update_Column>;
  where?: InputMaybe<Examsonline_Payment_Details_Bool_Exp>;
};

/** Ordering options when selecting data from "examsonline.payment_details". */
export type Examsonline_Payment_Details_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  qr_code?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: examsonline.payment_details */
export type Examsonline_Payment_Details_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "examsonline.payment_details" */
export enum Examsonline_Payment_Details_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  QrCode = 'qr_code',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "examsonline.payment_details" */
export type Examsonline_Payment_Details_Set_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  qr_code?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "examsonline_payment_details" */
export type Examsonline_Payment_Details_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Examsonline_Payment_Details_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Examsonline_Payment_Details_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  qr_code?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "examsonline.payment_details" */
export enum Examsonline_Payment_Details_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  QrCode = 'qr_code',
  /** column name */
  Type = 'type'
}

export type Examsonline_Payment_Details_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Examsonline_Payment_Details_Set_Input>;
  /** filter the rows which have to be updated */
  where: Examsonline_Payment_Details_Bool_Exp;
};

/** columns and relationships of "examsonline.plan" */
export type Examsonline_Plan = {
  __typename?: 'examsonline_plan';
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  /** An array relationship */
  organisations: Array<Examsonline_Organisation>;
  /** An aggregate relationship */
  organisations_aggregate: Examsonline_Organisation_Aggregate;
  price: Scalars['numeric']['output'];
};


/** columns and relationships of "examsonline.plan" */
export type Examsonline_PlanOrganisationsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Organisation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Organisation_Order_By>>;
  where?: InputMaybe<Examsonline_Organisation_Bool_Exp>;
};


/** columns and relationships of "examsonline.plan" */
export type Examsonline_PlanOrganisations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Organisation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Organisation_Order_By>>;
  where?: InputMaybe<Examsonline_Organisation_Bool_Exp>;
};

/** aggregated selection of "examsonline.plan" */
export type Examsonline_Plan_Aggregate = {
  __typename?: 'examsonline_plan_aggregate';
  aggregate?: Maybe<Examsonline_Plan_Aggregate_Fields>;
  nodes: Array<Examsonline_Plan>;
};

/** aggregate fields of "examsonline.plan" */
export type Examsonline_Plan_Aggregate_Fields = {
  __typename?: 'examsonline_plan_aggregate_fields';
  avg?: Maybe<Examsonline_Plan_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Examsonline_Plan_Max_Fields>;
  min?: Maybe<Examsonline_Plan_Min_Fields>;
  stddev?: Maybe<Examsonline_Plan_Stddev_Fields>;
  stddev_pop?: Maybe<Examsonline_Plan_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Examsonline_Plan_Stddev_Samp_Fields>;
  sum?: Maybe<Examsonline_Plan_Sum_Fields>;
  var_pop?: Maybe<Examsonline_Plan_Var_Pop_Fields>;
  var_samp?: Maybe<Examsonline_Plan_Var_Samp_Fields>;
  variance?: Maybe<Examsonline_Plan_Variance_Fields>;
};


/** aggregate fields of "examsonline.plan" */
export type Examsonline_Plan_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Examsonline_Plan_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Examsonline_Plan_Avg_Fields = {
  __typename?: 'examsonline_plan_avg_fields';
  price?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "examsonline.plan". All fields are combined with a logical 'AND'. */
export type Examsonline_Plan_Bool_Exp = {
  _and?: InputMaybe<Array<Examsonline_Plan_Bool_Exp>>;
  _not?: InputMaybe<Examsonline_Plan_Bool_Exp>;
  _or?: InputMaybe<Array<Examsonline_Plan_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organisations?: InputMaybe<Examsonline_Organisation_Bool_Exp>;
  organisations_aggregate?: InputMaybe<Examsonline_Organisation_Aggregate_Bool_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "examsonline.plan" */
export enum Examsonline_Plan_Constraint {
  /** unique or primary key constraint on columns "name" */
  PlanNameKey = 'plan_name_key',
  /** unique or primary key constraint on columns "id" */
  PlanPkey = 'plan_pkey',
  /** unique or primary key constraint on columns "price" */
  PlanPriceKey = 'plan_price_key'
}

/** input type for incrementing numeric columns in table "examsonline.plan" */
export type Examsonline_Plan_Inc_Input = {
  price?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "examsonline.plan" */
export type Examsonline_Plan_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organisations?: InputMaybe<Examsonline_Organisation_Arr_Rel_Insert_Input>;
  price?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate max on columns */
export type Examsonline_Plan_Max_Fields = {
  __typename?: 'examsonline_plan_max_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate min on columns */
export type Examsonline_Plan_Min_Fields = {
  __typename?: 'examsonline_plan_min_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
};

/** response of any mutation on the table "examsonline.plan" */
export type Examsonline_Plan_Mutation_Response = {
  __typename?: 'examsonline_plan_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Examsonline_Plan>;
};

/** input type for inserting object relation for remote table "examsonline.plan" */
export type Examsonline_Plan_Obj_Rel_Insert_Input = {
  data: Examsonline_Plan_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Examsonline_Plan_On_Conflict>;
};

/** on_conflict condition type for table "examsonline.plan" */
export type Examsonline_Plan_On_Conflict = {
  constraint: Examsonline_Plan_Constraint;
  update_columns?: Array<Examsonline_Plan_Update_Column>;
  where?: InputMaybe<Examsonline_Plan_Bool_Exp>;
};

/** Ordering options when selecting data from "examsonline.plan". */
export type Examsonline_Plan_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisations_aggregate?: InputMaybe<Examsonline_Organisation_Aggregate_Order_By>;
  price?: InputMaybe<Order_By>;
};

/** primary key columns input for table: examsonline.plan */
export type Examsonline_Plan_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "examsonline.plan" */
export enum Examsonline_Plan_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Price = 'price'
}

/** input type for updating data in table "examsonline.plan" */
export type Examsonline_Plan_Set_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate stddev on columns */
export type Examsonline_Plan_Stddev_Fields = {
  __typename?: 'examsonline_plan_stddev_fields';
  price?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Examsonline_Plan_Stddev_Pop_Fields = {
  __typename?: 'examsonline_plan_stddev_pop_fields';
  price?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Examsonline_Plan_Stddev_Samp_Fields = {
  __typename?: 'examsonline_plan_stddev_samp_fields';
  price?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "examsonline_plan" */
export type Examsonline_Plan_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Examsonline_Plan_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Examsonline_Plan_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type Examsonline_Plan_Sum_Fields = {
  __typename?: 'examsonline_plan_sum_fields';
  price?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "examsonline.plan" */
export enum Examsonline_Plan_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Price = 'price'
}

export type Examsonline_Plan_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Examsonline_Plan_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Examsonline_Plan_Set_Input>;
  /** filter the rows which have to be updated */
  where: Examsonline_Plan_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Examsonline_Plan_Var_Pop_Fields = {
  __typename?: 'examsonline_plan_var_pop_fields';
  price?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Examsonline_Plan_Var_Samp_Fields = {
  __typename?: 'examsonline_plan_var_samp_fields';
  price?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Examsonline_Plan_Variance_Fields = {
  __typename?: 'examsonline_plan_variance_fields';
  price?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "examsonline.questions" */
export type Examsonline_Questions = {
  __typename?: 'examsonline_questions';
  /** An array relationship */
  answers: Array<Examsonline_Answers>;
  /** An aggregate relationship */
  answers_aggregate: Examsonline_Answers_Aggregate;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  explanation?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  marks: Scalars['numeric']['output'];
  metadata: Scalars['jsonb']['output'];
  /** An array relationship */
  options: Array<Examsonline_Options>;
  /** An aggregate relationship */
  options_aggregate: Examsonline_Options_Aggregate;
  order: Scalars['Int']['output'];
  /** An object relationship */
  quiz?: Maybe<Examsonline_Quiz>;
  quiz_id?: Maybe<Scalars['uuid']['output']>;
  role?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  roleByRole?: Maybe<Examsonline_Roles>;
  section?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  selected_options: Array<Examsonline_Selected_Options>;
  /** An aggregate relationship */
  selected_options_aggregate: Examsonline_Selected_Options_Aggregate;
  /** An object relationship */
  test?: Maybe<Examsonline_Tests>;
  test_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  test_section?: Maybe<Examsonline_Test_Sections>;
  text: Scalars['String']['output'];
  /** An array relationship */
  timespents: Array<Examsonline_Timespent>;
  /** An aggregate relationship */
  timespents_aggregate: Examsonline_Timespent_Aggregate;
  type: Scalars['Int']['output'];
  user?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "examsonline.questions" */
export type Examsonline_QuestionsAnswersArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Answers_Order_By>>;
  where?: InputMaybe<Examsonline_Answers_Bool_Exp>;
};


/** columns and relationships of "examsonline.questions" */
export type Examsonline_QuestionsAnswers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Answers_Order_By>>;
  where?: InputMaybe<Examsonline_Answers_Bool_Exp>;
};


/** columns and relationships of "examsonline.questions" */
export type Examsonline_QuestionsMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "examsonline.questions" */
export type Examsonline_QuestionsOptionsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Options_Order_By>>;
  where?: InputMaybe<Examsonline_Options_Bool_Exp>;
};


/** columns and relationships of "examsonline.questions" */
export type Examsonline_QuestionsOptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Options_Order_By>>;
  where?: InputMaybe<Examsonline_Options_Bool_Exp>;
};


/** columns and relationships of "examsonline.questions" */
export type Examsonline_QuestionsSelected_OptionsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Selected_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Selected_Options_Order_By>>;
  where?: InputMaybe<Examsonline_Selected_Options_Bool_Exp>;
};


/** columns and relationships of "examsonline.questions" */
export type Examsonline_QuestionsSelected_Options_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Selected_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Selected_Options_Order_By>>;
  where?: InputMaybe<Examsonline_Selected_Options_Bool_Exp>;
};


/** columns and relationships of "examsonline.questions" */
export type Examsonline_QuestionsTimespentsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Timespent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Timespent_Order_By>>;
  where?: InputMaybe<Examsonline_Timespent_Bool_Exp>;
};


/** columns and relationships of "examsonline.questions" */
export type Examsonline_QuestionsTimespents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Timespent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Timespent_Order_By>>;
  where?: InputMaybe<Examsonline_Timespent_Bool_Exp>;
};

/** aggregated selection of "examsonline.questions" */
export type Examsonline_Questions_Aggregate = {
  __typename?: 'examsonline_questions_aggregate';
  aggregate?: Maybe<Examsonline_Questions_Aggregate_Fields>;
  nodes: Array<Examsonline_Questions>;
};

export type Examsonline_Questions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Examsonline_Questions_Aggregate_Bool_Exp_Count>;
};

export type Examsonline_Questions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Examsonline_Questions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Questions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "examsonline.questions" */
export type Examsonline_Questions_Aggregate_Fields = {
  __typename?: 'examsonline_questions_aggregate_fields';
  avg?: Maybe<Examsonline_Questions_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Examsonline_Questions_Max_Fields>;
  min?: Maybe<Examsonline_Questions_Min_Fields>;
  stddev?: Maybe<Examsonline_Questions_Stddev_Fields>;
  stddev_pop?: Maybe<Examsonline_Questions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Examsonline_Questions_Stddev_Samp_Fields>;
  sum?: Maybe<Examsonline_Questions_Sum_Fields>;
  var_pop?: Maybe<Examsonline_Questions_Var_Pop_Fields>;
  var_samp?: Maybe<Examsonline_Questions_Var_Samp_Fields>;
  variance?: Maybe<Examsonline_Questions_Variance_Fields>;
};


/** aggregate fields of "examsonline.questions" */
export type Examsonline_Questions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Examsonline_Questions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "examsonline.questions" */
export type Examsonline_Questions_Aggregate_Order_By = {
  avg?: InputMaybe<Examsonline_Questions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Examsonline_Questions_Max_Order_By>;
  min?: InputMaybe<Examsonline_Questions_Min_Order_By>;
  stddev?: InputMaybe<Examsonline_Questions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Examsonline_Questions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Examsonline_Questions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Examsonline_Questions_Sum_Order_By>;
  var_pop?: InputMaybe<Examsonline_Questions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Examsonline_Questions_Var_Samp_Order_By>;
  variance?: InputMaybe<Examsonline_Questions_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Examsonline_Questions_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "examsonline.questions" */
export type Examsonline_Questions_Arr_Rel_Insert_Input = {
  data: Array<Examsonline_Questions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Examsonline_Questions_On_Conflict>;
};

/** aggregate avg on columns */
export type Examsonline_Questions_Avg_Fields = {
  __typename?: 'examsonline_questions_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  marks?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
  test_id?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "examsonline.questions" */
export type Examsonline_Questions_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  marks?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "examsonline.questions". All fields are combined with a logical 'AND'. */
export type Examsonline_Questions_Bool_Exp = {
  _and?: InputMaybe<Array<Examsonline_Questions_Bool_Exp>>;
  _not?: InputMaybe<Examsonline_Questions_Bool_Exp>;
  _or?: InputMaybe<Array<Examsonline_Questions_Bool_Exp>>;
  answers?: InputMaybe<Examsonline_Answers_Bool_Exp>;
  answers_aggregate?: InputMaybe<Examsonline_Answers_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  explanation?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  marks?: InputMaybe<Numeric_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  options?: InputMaybe<Examsonline_Options_Bool_Exp>;
  options_aggregate?: InputMaybe<Examsonline_Options_Aggregate_Bool_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  quiz?: InputMaybe<Examsonline_Quiz_Bool_Exp>;
  quiz_id?: InputMaybe<Uuid_Comparison_Exp>;
  role?: InputMaybe<Uuid_Comparison_Exp>;
  roleByRole?: InputMaybe<Examsonline_Roles_Bool_Exp>;
  section?: InputMaybe<Uuid_Comparison_Exp>;
  selected_options?: InputMaybe<Examsonline_Selected_Options_Bool_Exp>;
  selected_options_aggregate?: InputMaybe<Examsonline_Selected_Options_Aggregate_Bool_Exp>;
  test?: InputMaybe<Examsonline_Tests_Bool_Exp>;
  test_id?: InputMaybe<Int_Comparison_Exp>;
  test_section?: InputMaybe<Examsonline_Test_Sections_Bool_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
  timespents?: InputMaybe<Examsonline_Timespent_Bool_Exp>;
  timespents_aggregate?: InputMaybe<Examsonline_Timespent_Aggregate_Bool_Exp>;
  type?: InputMaybe<Int_Comparison_Exp>;
  user?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "examsonline.questions" */
export enum Examsonline_Questions_Constraint {
  /** unique or primary key constraint on columns "test_id", "id" */
  QuestionsIdTestIdKey = 'questions_id_test_id_key',
  /** unique or primary key constraint on columns "id" */
  QuestionsPkey = 'questions_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Examsonline_Questions_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Examsonline_Questions_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Examsonline_Questions_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "examsonline.questions" */
export type Examsonline_Questions_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  marks?: InputMaybe<Scalars['numeric']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  test_id?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "examsonline.questions" */
export type Examsonline_Questions_Insert_Input = {
  answers?: InputMaybe<Examsonline_Answers_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  explanation?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  marks?: InputMaybe<Scalars['numeric']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  options?: InputMaybe<Examsonline_Options_Arr_Rel_Insert_Input>;
  order?: InputMaybe<Scalars['Int']['input']>;
  quiz?: InputMaybe<Examsonline_Quiz_Obj_Rel_Insert_Input>;
  quiz_id?: InputMaybe<Scalars['uuid']['input']>;
  role?: InputMaybe<Scalars['uuid']['input']>;
  roleByRole?: InputMaybe<Examsonline_Roles_Obj_Rel_Insert_Input>;
  section?: InputMaybe<Scalars['uuid']['input']>;
  selected_options?: InputMaybe<Examsonline_Selected_Options_Arr_Rel_Insert_Input>;
  test?: InputMaybe<Examsonline_Tests_Obj_Rel_Insert_Input>;
  test_id?: InputMaybe<Scalars['Int']['input']>;
  test_section?: InputMaybe<Examsonline_Test_Sections_Obj_Rel_Insert_Input>;
  text?: InputMaybe<Scalars['String']['input']>;
  timespents?: InputMaybe<Examsonline_Timespent_Arr_Rel_Insert_Input>;
  type?: InputMaybe<Scalars['Int']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Examsonline_Questions_Max_Fields = {
  __typename?: 'examsonline_questions_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  explanation?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  marks?: Maybe<Scalars['numeric']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  quiz_id?: Maybe<Scalars['uuid']['output']>;
  role?: Maybe<Scalars['uuid']['output']>;
  section?: Maybe<Scalars['uuid']['output']>;
  test_id?: Maybe<Scalars['Int']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['Int']['output']>;
  user?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "examsonline.questions" */
export type Examsonline_Questions_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  explanation?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  marks?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  quiz_id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  section?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Examsonline_Questions_Min_Fields = {
  __typename?: 'examsonline_questions_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  explanation?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  marks?: Maybe<Scalars['numeric']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  quiz_id?: Maybe<Scalars['uuid']['output']>;
  role?: Maybe<Scalars['uuid']['output']>;
  section?: Maybe<Scalars['uuid']['output']>;
  test_id?: Maybe<Scalars['Int']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['Int']['output']>;
  user?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "examsonline.questions" */
export type Examsonline_Questions_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  explanation?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  marks?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  quiz_id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  section?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "examsonline.questions" */
export type Examsonline_Questions_Mutation_Response = {
  __typename?: 'examsonline_questions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Examsonline_Questions>;
};

/** input type for inserting object relation for remote table "examsonline.questions" */
export type Examsonline_Questions_Obj_Rel_Insert_Input = {
  data: Examsonline_Questions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Examsonline_Questions_On_Conflict>;
};

/** on_conflict condition type for table "examsonline.questions" */
export type Examsonline_Questions_On_Conflict = {
  constraint: Examsonline_Questions_Constraint;
  update_columns?: Array<Examsonline_Questions_Update_Column>;
  where?: InputMaybe<Examsonline_Questions_Bool_Exp>;
};

/** Ordering options when selecting data from "examsonline.questions". */
export type Examsonline_Questions_Order_By = {
  answers_aggregate?: InputMaybe<Examsonline_Answers_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  explanation?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  marks?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  options_aggregate?: InputMaybe<Examsonline_Options_Aggregate_Order_By>;
  order?: InputMaybe<Order_By>;
  quiz?: InputMaybe<Examsonline_Quiz_Order_By>;
  quiz_id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  roleByRole?: InputMaybe<Examsonline_Roles_Order_By>;
  section?: InputMaybe<Order_By>;
  selected_options_aggregate?: InputMaybe<Examsonline_Selected_Options_Aggregate_Order_By>;
  test?: InputMaybe<Examsonline_Tests_Order_By>;
  test_id?: InputMaybe<Order_By>;
  test_section?: InputMaybe<Examsonline_Test_Sections_Order_By>;
  text?: InputMaybe<Order_By>;
  timespents_aggregate?: InputMaybe<Examsonline_Timespent_Aggregate_Order_By>;
  type?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** primary key columns input for table: examsonline.questions */
export type Examsonline_Questions_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Examsonline_Questions_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "examsonline.questions" */
export enum Examsonline_Questions_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Explanation = 'explanation',
  /** column name */
  Id = 'id',
  /** column name */
  Marks = 'marks',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Order = 'order',
  /** column name */
  QuizId = 'quiz_id',
  /** column name */
  Role = 'role',
  /** column name */
  Section = 'section',
  /** column name */
  TestId = 'test_id',
  /** column name */
  Text = 'text',
  /** column name */
  Type = 'type',
  /** column name */
  User = 'user'
}

/** input type for updating data in table "examsonline.questions" */
export type Examsonline_Questions_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  explanation?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  marks?: InputMaybe<Scalars['numeric']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  quiz_id?: InputMaybe<Scalars['uuid']['input']>;
  role?: InputMaybe<Scalars['uuid']['input']>;
  section?: InputMaybe<Scalars['uuid']['input']>;
  test_id?: InputMaybe<Scalars['Int']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['Int']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Examsonline_Questions_Stddev_Fields = {
  __typename?: 'examsonline_questions_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  marks?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
  test_id?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "examsonline.questions" */
export type Examsonline_Questions_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  marks?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Examsonline_Questions_Stddev_Pop_Fields = {
  __typename?: 'examsonline_questions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  marks?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
  test_id?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "examsonline.questions" */
export type Examsonline_Questions_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  marks?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Examsonline_Questions_Stddev_Samp_Fields = {
  __typename?: 'examsonline_questions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  marks?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
  test_id?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "examsonline.questions" */
export type Examsonline_Questions_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  marks?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "examsonline_questions" */
export type Examsonline_Questions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Examsonline_Questions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Examsonline_Questions_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  explanation?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  marks?: InputMaybe<Scalars['numeric']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  quiz_id?: InputMaybe<Scalars['uuid']['input']>;
  role?: InputMaybe<Scalars['uuid']['input']>;
  section?: InputMaybe<Scalars['uuid']['input']>;
  test_id?: InputMaybe<Scalars['Int']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['Int']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Examsonline_Questions_Sum_Fields = {
  __typename?: 'examsonline_questions_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  marks?: Maybe<Scalars['numeric']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  test_id?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "examsonline.questions" */
export type Examsonline_Questions_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  marks?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** update columns of table "examsonline.questions" */
export enum Examsonline_Questions_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Explanation = 'explanation',
  /** column name */
  Id = 'id',
  /** column name */
  Marks = 'marks',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Order = 'order',
  /** column name */
  QuizId = 'quiz_id',
  /** column name */
  Role = 'role',
  /** column name */
  Section = 'section',
  /** column name */
  TestId = 'test_id',
  /** column name */
  Text = 'text',
  /** column name */
  Type = 'type',
  /** column name */
  User = 'user'
}

export type Examsonline_Questions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Examsonline_Questions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Examsonline_Questions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Examsonline_Questions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Examsonline_Questions_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Examsonline_Questions_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Examsonline_Questions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Examsonline_Questions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Examsonline_Questions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Examsonline_Questions_Var_Pop_Fields = {
  __typename?: 'examsonline_questions_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  marks?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
  test_id?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "examsonline.questions" */
export type Examsonline_Questions_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  marks?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Examsonline_Questions_Var_Samp_Fields = {
  __typename?: 'examsonline_questions_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  marks?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
  test_id?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "examsonline.questions" */
export type Examsonline_Questions_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  marks?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Examsonline_Questions_Variance_Fields = {
  __typename?: 'examsonline_questions_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  marks?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
  test_id?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "examsonline.questions" */
export type Examsonline_Questions_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  marks?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** columns and relationships of "examsonline.quiz" */
export type Examsonline_Quiz = {
  __typename?: 'examsonline_quiz';
  accesstype: Scalars['Int']['output'];
  /** An array relationship */
  attempts: Array<Examsonline_Attempt>;
  /** An aggregate relationship */
  attempts_aggregate: Examsonline_Attempt_Aggregate;
  author: Scalars['String']['output'];
  /** An object relationship */
  author_to_user: Examsonline_User;
  createdAt: Scalars['date']['output'];
  description: Scalars['String']['output'];
  document?: Maybe<Scalars['String']['output']>;
  emailnotify: Scalars['Boolean']['output'];
  enabled: Scalars['Boolean']['output'];
  errorMessage: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  isDraft: Scalars['Boolean']['output'];
  maxAttempts: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  pincode?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  question?: Maybe<Examsonline_Questions>;
  /** An array relationship */
  questions: Array<Examsonline_Questions>;
  /** An aggregate relationship */
  questions_aggregate: Examsonline_Questions_Aggregate;
  randomise: Scalars['Boolean']['output'];
  /** An array relationship */
  schedules: Array<Examsonline_Assignments>;
  /** An aggregate relationship */
  schedules_aggregate: Examsonline_Assignments_Aggregate;
  showResult: Scalars['Boolean']['output'];
  /** An array relationship */
  test_sections: Array<Examsonline_Test_Sections>;
  /** An aggregate relationship */
  test_sections_aggregate: Examsonline_Test_Sections_Aggregate;
  time: Scalars['Int']['output'];
  /** An array relationship */
  topics: Array<Examsonline_Topics>;
  /** An aggregate relationship */
  topics_aggregate: Examsonline_Topics_Aggregate;
  updatedAt: Scalars['timestamptz']['output'];
};


/** columns and relationships of "examsonline.quiz" */
export type Examsonline_QuizAttemptsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Attempt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Attempt_Order_By>>;
  where?: InputMaybe<Examsonline_Attempt_Bool_Exp>;
};


/** columns and relationships of "examsonline.quiz" */
export type Examsonline_QuizAttempts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Attempt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Attempt_Order_By>>;
  where?: InputMaybe<Examsonline_Attempt_Bool_Exp>;
};


/** columns and relationships of "examsonline.quiz" */
export type Examsonline_QuizQuestionsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Questions_Order_By>>;
  where?: InputMaybe<Examsonline_Questions_Bool_Exp>;
};


/** columns and relationships of "examsonline.quiz" */
export type Examsonline_QuizQuestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Questions_Order_By>>;
  where?: InputMaybe<Examsonline_Questions_Bool_Exp>;
};


/** columns and relationships of "examsonline.quiz" */
export type Examsonline_QuizSchedulesArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Assignments_Order_By>>;
  where?: InputMaybe<Examsonline_Assignments_Bool_Exp>;
};


/** columns and relationships of "examsonline.quiz" */
export type Examsonline_QuizSchedules_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Assignments_Order_By>>;
  where?: InputMaybe<Examsonline_Assignments_Bool_Exp>;
};


/** columns and relationships of "examsonline.quiz" */
export type Examsonline_QuizTest_SectionsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Test_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Test_Sections_Order_By>>;
  where?: InputMaybe<Examsonline_Test_Sections_Bool_Exp>;
};


/** columns and relationships of "examsonline.quiz" */
export type Examsonline_QuizTest_Sections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Test_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Test_Sections_Order_By>>;
  where?: InputMaybe<Examsonline_Test_Sections_Bool_Exp>;
};


/** columns and relationships of "examsonline.quiz" */
export type Examsonline_QuizTopicsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Topics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Topics_Order_By>>;
  where?: InputMaybe<Examsonline_Topics_Bool_Exp>;
};


/** columns and relationships of "examsonline.quiz" */
export type Examsonline_QuizTopics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Topics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Topics_Order_By>>;
  where?: InputMaybe<Examsonline_Topics_Bool_Exp>;
};

/** aggregated selection of "examsonline.quiz" */
export type Examsonline_Quiz_Aggregate = {
  __typename?: 'examsonline_quiz_aggregate';
  aggregate?: Maybe<Examsonline_Quiz_Aggregate_Fields>;
  nodes: Array<Examsonline_Quiz>;
};

export type Examsonline_Quiz_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Examsonline_Quiz_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Examsonline_Quiz_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Examsonline_Quiz_Aggregate_Bool_Exp_Count>;
};

export type Examsonline_Quiz_Aggregate_Bool_Exp_Bool_And = {
  arguments: Examsonline_Quiz_Select_Column_Examsonline_Quiz_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Quiz_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Examsonline_Quiz_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Examsonline_Quiz_Select_Column_Examsonline_Quiz_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Quiz_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Examsonline_Quiz_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Examsonline_Quiz_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Quiz_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "examsonline.quiz" */
export type Examsonline_Quiz_Aggregate_Fields = {
  __typename?: 'examsonline_quiz_aggregate_fields';
  avg?: Maybe<Examsonline_Quiz_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Examsonline_Quiz_Max_Fields>;
  min?: Maybe<Examsonline_Quiz_Min_Fields>;
  stddev?: Maybe<Examsonline_Quiz_Stddev_Fields>;
  stddev_pop?: Maybe<Examsonline_Quiz_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Examsonline_Quiz_Stddev_Samp_Fields>;
  sum?: Maybe<Examsonline_Quiz_Sum_Fields>;
  var_pop?: Maybe<Examsonline_Quiz_Var_Pop_Fields>;
  var_samp?: Maybe<Examsonline_Quiz_Var_Samp_Fields>;
  variance?: Maybe<Examsonline_Quiz_Variance_Fields>;
};


/** aggregate fields of "examsonline.quiz" */
export type Examsonline_Quiz_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Examsonline_Quiz_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "examsonline.quiz" */
export type Examsonline_Quiz_Aggregate_Order_By = {
  avg?: InputMaybe<Examsonline_Quiz_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Examsonline_Quiz_Max_Order_By>;
  min?: InputMaybe<Examsonline_Quiz_Min_Order_By>;
  stddev?: InputMaybe<Examsonline_Quiz_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Examsonline_Quiz_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Examsonline_Quiz_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Examsonline_Quiz_Sum_Order_By>;
  var_pop?: InputMaybe<Examsonline_Quiz_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Examsonline_Quiz_Var_Samp_Order_By>;
  variance?: InputMaybe<Examsonline_Quiz_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "examsonline.quiz" */
export type Examsonline_Quiz_Arr_Rel_Insert_Input = {
  data: Array<Examsonline_Quiz_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Examsonline_Quiz_On_Conflict>;
};

/** aggregate avg on columns */
export type Examsonline_Quiz_Avg_Fields = {
  __typename?: 'examsonline_quiz_avg_fields';
  accesstype?: Maybe<Scalars['Float']['output']>;
  maxAttempts?: Maybe<Scalars['Float']['output']>;
  time?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "examsonline.quiz" */
export type Examsonline_Quiz_Avg_Order_By = {
  accesstype?: InputMaybe<Order_By>;
  maxAttempts?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "examsonline.quiz". All fields are combined with a logical 'AND'. */
export type Examsonline_Quiz_Bool_Exp = {
  _and?: InputMaybe<Array<Examsonline_Quiz_Bool_Exp>>;
  _not?: InputMaybe<Examsonline_Quiz_Bool_Exp>;
  _or?: InputMaybe<Array<Examsonline_Quiz_Bool_Exp>>;
  accesstype?: InputMaybe<Int_Comparison_Exp>;
  attempts?: InputMaybe<Examsonline_Attempt_Bool_Exp>;
  attempts_aggregate?: InputMaybe<Examsonline_Attempt_Aggregate_Bool_Exp>;
  author?: InputMaybe<String_Comparison_Exp>;
  author_to_user?: InputMaybe<Examsonline_User_Bool_Exp>;
  createdAt?: InputMaybe<Date_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  document?: InputMaybe<String_Comparison_Exp>;
  emailnotify?: InputMaybe<Boolean_Comparison_Exp>;
  enabled?: InputMaybe<Boolean_Comparison_Exp>;
  errorMessage?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isDraft?: InputMaybe<Boolean_Comparison_Exp>;
  maxAttempts?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  pincode?: InputMaybe<String_Comparison_Exp>;
  question?: InputMaybe<Examsonline_Questions_Bool_Exp>;
  questions?: InputMaybe<Examsonline_Questions_Bool_Exp>;
  questions_aggregate?: InputMaybe<Examsonline_Questions_Aggregate_Bool_Exp>;
  randomise?: InputMaybe<Boolean_Comparison_Exp>;
  schedules?: InputMaybe<Examsonline_Assignments_Bool_Exp>;
  schedules_aggregate?: InputMaybe<Examsonline_Assignments_Aggregate_Bool_Exp>;
  showResult?: InputMaybe<Boolean_Comparison_Exp>;
  test_sections?: InputMaybe<Examsonline_Test_Sections_Bool_Exp>;
  test_sections_aggregate?: InputMaybe<Examsonline_Test_Sections_Aggregate_Bool_Exp>;
  time?: InputMaybe<Int_Comparison_Exp>;
  topics?: InputMaybe<Examsonline_Topics_Bool_Exp>;
  topics_aggregate?: InputMaybe<Examsonline_Topics_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "examsonline.quiz" */
export enum Examsonline_Quiz_Constraint {
  /** unique or primary key constraint on columns "id" */
  QuizPkey = 'quiz_pkey'
}

/** input type for incrementing numeric columns in table "examsonline.quiz" */
export type Examsonline_Quiz_Inc_Input = {
  accesstype?: InputMaybe<Scalars['Int']['input']>;
  maxAttempts?: InputMaybe<Scalars['Int']['input']>;
  time?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "examsonline.quiz" */
export type Examsonline_Quiz_Insert_Input = {
  accesstype?: InputMaybe<Scalars['Int']['input']>;
  attempts?: InputMaybe<Examsonline_Attempt_Arr_Rel_Insert_Input>;
  author?: InputMaybe<Scalars['String']['input']>;
  author_to_user?: InputMaybe<Examsonline_User_Obj_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['date']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  document?: InputMaybe<Scalars['String']['input']>;
  emailnotify?: InputMaybe<Scalars['Boolean']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  errorMessage?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isDraft?: InputMaybe<Scalars['Boolean']['input']>;
  maxAttempts?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pincode?: InputMaybe<Scalars['String']['input']>;
  question?: InputMaybe<Examsonline_Questions_Obj_Rel_Insert_Input>;
  questions?: InputMaybe<Examsonline_Questions_Arr_Rel_Insert_Input>;
  randomise?: InputMaybe<Scalars['Boolean']['input']>;
  schedules?: InputMaybe<Examsonline_Assignments_Arr_Rel_Insert_Input>;
  showResult?: InputMaybe<Scalars['Boolean']['input']>;
  test_sections?: InputMaybe<Examsonline_Test_Sections_Arr_Rel_Insert_Input>;
  time?: InputMaybe<Scalars['Int']['input']>;
  topics?: InputMaybe<Examsonline_Topics_Arr_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Examsonline_Quiz_Max_Fields = {
  __typename?: 'examsonline_quiz_max_fields';
  accesstype?: Maybe<Scalars['Int']['output']>;
  author?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Scalars['String']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  maxAttempts?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pincode?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "examsonline.quiz" */
export type Examsonline_Quiz_Max_Order_By = {
  accesstype?: InputMaybe<Order_By>;
  author?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  document?: InputMaybe<Order_By>;
  errorMessage?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  maxAttempts?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pincode?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Examsonline_Quiz_Min_Fields = {
  __typename?: 'examsonline_quiz_min_fields';
  accesstype?: Maybe<Scalars['Int']['output']>;
  author?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Scalars['String']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  maxAttempts?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pincode?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "examsonline.quiz" */
export type Examsonline_Quiz_Min_Order_By = {
  accesstype?: InputMaybe<Order_By>;
  author?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  document?: InputMaybe<Order_By>;
  errorMessage?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  maxAttempts?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pincode?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "examsonline.quiz" */
export type Examsonline_Quiz_Mutation_Response = {
  __typename?: 'examsonline_quiz_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Examsonline_Quiz>;
};

/** input type for inserting object relation for remote table "examsonline.quiz" */
export type Examsonline_Quiz_Obj_Rel_Insert_Input = {
  data: Examsonline_Quiz_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Examsonline_Quiz_On_Conflict>;
};

/** on_conflict condition type for table "examsonline.quiz" */
export type Examsonline_Quiz_On_Conflict = {
  constraint: Examsonline_Quiz_Constraint;
  update_columns?: Array<Examsonline_Quiz_Update_Column>;
  where?: InputMaybe<Examsonline_Quiz_Bool_Exp>;
};

/** Ordering options when selecting data from "examsonline.quiz". */
export type Examsonline_Quiz_Order_By = {
  accesstype?: InputMaybe<Order_By>;
  attempts_aggregate?: InputMaybe<Examsonline_Attempt_Aggregate_Order_By>;
  author?: InputMaybe<Order_By>;
  author_to_user?: InputMaybe<Examsonline_User_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  document?: InputMaybe<Order_By>;
  emailnotify?: InputMaybe<Order_By>;
  enabled?: InputMaybe<Order_By>;
  errorMessage?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isDraft?: InputMaybe<Order_By>;
  maxAttempts?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pincode?: InputMaybe<Order_By>;
  question?: InputMaybe<Examsonline_Questions_Order_By>;
  questions_aggregate?: InputMaybe<Examsonline_Questions_Aggregate_Order_By>;
  randomise?: InputMaybe<Order_By>;
  schedules_aggregate?: InputMaybe<Examsonline_Assignments_Aggregate_Order_By>;
  showResult?: InputMaybe<Order_By>;
  test_sections_aggregate?: InputMaybe<Examsonline_Test_Sections_Aggregate_Order_By>;
  time?: InputMaybe<Order_By>;
  topics_aggregate?: InputMaybe<Examsonline_Topics_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: examsonline.quiz */
export type Examsonline_Quiz_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "examsonline.quiz" */
export enum Examsonline_Quiz_Select_Column {
  /** column name */
  Accesstype = 'accesstype',
  /** column name */
  Author = 'author',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Document = 'document',
  /** column name */
  Emailnotify = 'emailnotify',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  ErrorMessage = 'errorMessage',
  /** column name */
  Id = 'id',
  /** column name */
  IsDraft = 'isDraft',
  /** column name */
  MaxAttempts = 'maxAttempts',
  /** column name */
  Name = 'name',
  /** column name */
  Pincode = 'pincode',
  /** column name */
  Randomise = 'randomise',
  /** column name */
  ShowResult = 'showResult',
  /** column name */
  Time = 'time',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "examsonline_quiz_aggregate_bool_exp_bool_and_arguments_columns" columns of table "examsonline.quiz" */
export enum Examsonline_Quiz_Select_Column_Examsonline_Quiz_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Emailnotify = 'emailnotify',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  IsDraft = 'isDraft',
  /** column name */
  Randomise = 'randomise',
  /** column name */
  ShowResult = 'showResult'
}

/** select "examsonline_quiz_aggregate_bool_exp_bool_or_arguments_columns" columns of table "examsonline.quiz" */
export enum Examsonline_Quiz_Select_Column_Examsonline_Quiz_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Emailnotify = 'emailnotify',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  IsDraft = 'isDraft',
  /** column name */
  Randomise = 'randomise',
  /** column name */
  ShowResult = 'showResult'
}

/** input type for updating data in table "examsonline.quiz" */
export type Examsonline_Quiz_Set_Input = {
  accesstype?: InputMaybe<Scalars['Int']['input']>;
  author?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['date']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  document?: InputMaybe<Scalars['String']['input']>;
  emailnotify?: InputMaybe<Scalars['Boolean']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  errorMessage?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isDraft?: InputMaybe<Scalars['Boolean']['input']>;
  maxAttempts?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pincode?: InputMaybe<Scalars['String']['input']>;
  randomise?: InputMaybe<Scalars['Boolean']['input']>;
  showResult?: InputMaybe<Scalars['Boolean']['input']>;
  time?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Examsonline_Quiz_Stddev_Fields = {
  __typename?: 'examsonline_quiz_stddev_fields';
  accesstype?: Maybe<Scalars['Float']['output']>;
  maxAttempts?: Maybe<Scalars['Float']['output']>;
  time?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "examsonline.quiz" */
export type Examsonline_Quiz_Stddev_Order_By = {
  accesstype?: InputMaybe<Order_By>;
  maxAttempts?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Examsonline_Quiz_Stddev_Pop_Fields = {
  __typename?: 'examsonline_quiz_stddev_pop_fields';
  accesstype?: Maybe<Scalars['Float']['output']>;
  maxAttempts?: Maybe<Scalars['Float']['output']>;
  time?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "examsonline.quiz" */
export type Examsonline_Quiz_Stddev_Pop_Order_By = {
  accesstype?: InputMaybe<Order_By>;
  maxAttempts?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Examsonline_Quiz_Stddev_Samp_Fields = {
  __typename?: 'examsonline_quiz_stddev_samp_fields';
  accesstype?: Maybe<Scalars['Float']['output']>;
  maxAttempts?: Maybe<Scalars['Float']['output']>;
  time?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "examsonline.quiz" */
export type Examsonline_Quiz_Stddev_Samp_Order_By = {
  accesstype?: InputMaybe<Order_By>;
  maxAttempts?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "examsonline_quiz" */
export type Examsonline_Quiz_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Examsonline_Quiz_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Examsonline_Quiz_Stream_Cursor_Value_Input = {
  accesstype?: InputMaybe<Scalars['Int']['input']>;
  author?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['date']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  document?: InputMaybe<Scalars['String']['input']>;
  emailnotify?: InputMaybe<Scalars['Boolean']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  errorMessage?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isDraft?: InputMaybe<Scalars['Boolean']['input']>;
  maxAttempts?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pincode?: InputMaybe<Scalars['String']['input']>;
  randomise?: InputMaybe<Scalars['Boolean']['input']>;
  showResult?: InputMaybe<Scalars['Boolean']['input']>;
  time?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Examsonline_Quiz_Sum_Fields = {
  __typename?: 'examsonline_quiz_sum_fields';
  accesstype?: Maybe<Scalars['Int']['output']>;
  maxAttempts?: Maybe<Scalars['Int']['output']>;
  time?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "examsonline.quiz" */
export type Examsonline_Quiz_Sum_Order_By = {
  accesstype?: InputMaybe<Order_By>;
  maxAttempts?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
};

/** update columns of table "examsonline.quiz" */
export enum Examsonline_Quiz_Update_Column {
  /** column name */
  Accesstype = 'accesstype',
  /** column name */
  Author = 'author',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Document = 'document',
  /** column name */
  Emailnotify = 'emailnotify',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  ErrorMessage = 'errorMessage',
  /** column name */
  Id = 'id',
  /** column name */
  IsDraft = 'isDraft',
  /** column name */
  MaxAttempts = 'maxAttempts',
  /** column name */
  Name = 'name',
  /** column name */
  Pincode = 'pincode',
  /** column name */
  Randomise = 'randomise',
  /** column name */
  ShowResult = 'showResult',
  /** column name */
  Time = 'time',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Examsonline_Quiz_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Examsonline_Quiz_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Examsonline_Quiz_Set_Input>;
  /** filter the rows which have to be updated */
  where: Examsonline_Quiz_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Examsonline_Quiz_Var_Pop_Fields = {
  __typename?: 'examsonline_quiz_var_pop_fields';
  accesstype?: Maybe<Scalars['Float']['output']>;
  maxAttempts?: Maybe<Scalars['Float']['output']>;
  time?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "examsonline.quiz" */
export type Examsonline_Quiz_Var_Pop_Order_By = {
  accesstype?: InputMaybe<Order_By>;
  maxAttempts?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Examsonline_Quiz_Var_Samp_Fields = {
  __typename?: 'examsonline_quiz_var_samp_fields';
  accesstype?: Maybe<Scalars['Float']['output']>;
  maxAttempts?: Maybe<Scalars['Float']['output']>;
  time?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "examsonline.quiz" */
export type Examsonline_Quiz_Var_Samp_Order_By = {
  accesstype?: InputMaybe<Order_By>;
  maxAttempts?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Examsonline_Quiz_Variance_Fields = {
  __typename?: 'examsonline_quiz_variance_fields';
  accesstype?: Maybe<Scalars['Float']['output']>;
  maxAttempts?: Maybe<Scalars['Float']['output']>;
  time?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "examsonline.quiz" */
export type Examsonline_Quiz_Variance_Order_By = {
  accesstype?: InputMaybe<Order_By>;
  maxAttempts?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
};

/** keeps rating data  */
export type Examsonline_Rating = {
  __typename?: 'examsonline_rating';
  /** An object relationship */
  contest?: Maybe<Examsonline_Contest>;
  contest_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  test: Examsonline_Tests;
  test_id: Scalars['Int']['output'];
  user?: Maybe<Scalars['String']['output']>;
  value: Scalars['numeric']['output'];
};

/** aggregated selection of "examsonline.rating" */
export type Examsonline_Rating_Aggregate = {
  __typename?: 'examsonline_rating_aggregate';
  aggregate?: Maybe<Examsonline_Rating_Aggregate_Fields>;
  nodes: Array<Examsonline_Rating>;
};

export type Examsonline_Rating_Aggregate_Bool_Exp = {
  count?: InputMaybe<Examsonline_Rating_Aggregate_Bool_Exp_Count>;
};

export type Examsonline_Rating_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Examsonline_Rating_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Rating_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "examsonline.rating" */
export type Examsonline_Rating_Aggregate_Fields = {
  __typename?: 'examsonline_rating_aggregate_fields';
  avg?: Maybe<Examsonline_Rating_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Examsonline_Rating_Max_Fields>;
  min?: Maybe<Examsonline_Rating_Min_Fields>;
  stddev?: Maybe<Examsonline_Rating_Stddev_Fields>;
  stddev_pop?: Maybe<Examsonline_Rating_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Examsonline_Rating_Stddev_Samp_Fields>;
  sum?: Maybe<Examsonline_Rating_Sum_Fields>;
  var_pop?: Maybe<Examsonline_Rating_Var_Pop_Fields>;
  var_samp?: Maybe<Examsonline_Rating_Var_Samp_Fields>;
  variance?: Maybe<Examsonline_Rating_Variance_Fields>;
};


/** aggregate fields of "examsonline.rating" */
export type Examsonline_Rating_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Examsonline_Rating_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "examsonline.rating" */
export type Examsonline_Rating_Aggregate_Order_By = {
  avg?: InputMaybe<Examsonline_Rating_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Examsonline_Rating_Max_Order_By>;
  min?: InputMaybe<Examsonline_Rating_Min_Order_By>;
  stddev?: InputMaybe<Examsonline_Rating_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Examsonline_Rating_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Examsonline_Rating_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Examsonline_Rating_Sum_Order_By>;
  var_pop?: InputMaybe<Examsonline_Rating_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Examsonline_Rating_Var_Samp_Order_By>;
  variance?: InputMaybe<Examsonline_Rating_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "examsonline.rating" */
export type Examsonline_Rating_Arr_Rel_Insert_Input = {
  data: Array<Examsonline_Rating_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Examsonline_Rating_On_Conflict>;
};

/** aggregate avg on columns */
export type Examsonline_Rating_Avg_Fields = {
  __typename?: 'examsonline_rating_avg_fields';
  test_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "examsonline.rating" */
export type Examsonline_Rating_Avg_Order_By = {
  test_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "examsonline.rating". All fields are combined with a logical 'AND'. */
export type Examsonline_Rating_Bool_Exp = {
  _and?: InputMaybe<Array<Examsonline_Rating_Bool_Exp>>;
  _not?: InputMaybe<Examsonline_Rating_Bool_Exp>;
  _or?: InputMaybe<Array<Examsonline_Rating_Bool_Exp>>;
  contest?: InputMaybe<Examsonline_Contest_Bool_Exp>;
  contest_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  test?: InputMaybe<Examsonline_Tests_Bool_Exp>;
  test_id?: InputMaybe<Int_Comparison_Exp>;
  user?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "examsonline.rating" */
export enum Examsonline_Rating_Constraint {
  /** unique or primary key constraint on columns "id" */
  RatingPkey = 'rating_pkey'
}

/** input type for incrementing numeric columns in table "examsonline.rating" */
export type Examsonline_Rating_Inc_Input = {
  test_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "examsonline.rating" */
export type Examsonline_Rating_Insert_Input = {
  contest?: InputMaybe<Examsonline_Contest_Obj_Rel_Insert_Input>;
  contest_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  test?: InputMaybe<Examsonline_Tests_Obj_Rel_Insert_Input>;
  test_id?: InputMaybe<Scalars['Int']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate max on columns */
export type Examsonline_Rating_Max_Fields = {
  __typename?: 'examsonline_rating_max_fields';
  contest_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  test_id?: Maybe<Scalars['Int']['output']>;
  user?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['numeric']['output']>;
};

/** order by max() on columns of table "examsonline.rating" */
export type Examsonline_Rating_Max_Order_By = {
  contest_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Examsonline_Rating_Min_Fields = {
  __typename?: 'examsonline_rating_min_fields';
  contest_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  test_id?: Maybe<Scalars['Int']['output']>;
  user?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['numeric']['output']>;
};

/** order by min() on columns of table "examsonline.rating" */
export type Examsonline_Rating_Min_Order_By = {
  contest_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "examsonline.rating" */
export type Examsonline_Rating_Mutation_Response = {
  __typename?: 'examsonline_rating_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Examsonline_Rating>;
};

/** on_conflict condition type for table "examsonline.rating" */
export type Examsonline_Rating_On_Conflict = {
  constraint: Examsonline_Rating_Constraint;
  update_columns?: Array<Examsonline_Rating_Update_Column>;
  where?: InputMaybe<Examsonline_Rating_Bool_Exp>;
};

/** Ordering options when selecting data from "examsonline.rating". */
export type Examsonline_Rating_Order_By = {
  contest?: InputMaybe<Examsonline_Contest_Order_By>;
  contest_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  test?: InputMaybe<Examsonline_Tests_Order_By>;
  test_id?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: examsonline.rating */
export type Examsonline_Rating_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "examsonline.rating" */
export enum Examsonline_Rating_Select_Column {
  /** column name */
  ContestId = 'contest_id',
  /** column name */
  Id = 'id',
  /** column name */
  TestId = 'test_id',
  /** column name */
  User = 'user',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "examsonline.rating" */
export type Examsonline_Rating_Set_Input = {
  contest_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  test_id?: InputMaybe<Scalars['Int']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate stddev on columns */
export type Examsonline_Rating_Stddev_Fields = {
  __typename?: 'examsonline_rating_stddev_fields';
  test_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "examsonline.rating" */
export type Examsonline_Rating_Stddev_Order_By = {
  test_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Examsonline_Rating_Stddev_Pop_Fields = {
  __typename?: 'examsonline_rating_stddev_pop_fields';
  test_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "examsonline.rating" */
export type Examsonline_Rating_Stddev_Pop_Order_By = {
  test_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Examsonline_Rating_Stddev_Samp_Fields = {
  __typename?: 'examsonline_rating_stddev_samp_fields';
  test_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "examsonline.rating" */
export type Examsonline_Rating_Stddev_Samp_Order_By = {
  test_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "examsonline_rating" */
export type Examsonline_Rating_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Examsonline_Rating_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Examsonline_Rating_Stream_Cursor_Value_Input = {
  contest_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  test_id?: InputMaybe<Scalars['Int']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type Examsonline_Rating_Sum_Fields = {
  __typename?: 'examsonline_rating_sum_fields';
  test_id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "examsonline.rating" */
export type Examsonline_Rating_Sum_Order_By = {
  test_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** update columns of table "examsonline.rating" */
export enum Examsonline_Rating_Update_Column {
  /** column name */
  ContestId = 'contest_id',
  /** column name */
  Id = 'id',
  /** column name */
  TestId = 'test_id',
  /** column name */
  User = 'user',
  /** column name */
  Value = 'value'
}

export type Examsonline_Rating_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Examsonline_Rating_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Examsonline_Rating_Set_Input>;
  /** filter the rows which have to be updated */
  where: Examsonline_Rating_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Examsonline_Rating_Var_Pop_Fields = {
  __typename?: 'examsonline_rating_var_pop_fields';
  test_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "examsonline.rating" */
export type Examsonline_Rating_Var_Pop_Order_By = {
  test_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Examsonline_Rating_Var_Samp_Fields = {
  __typename?: 'examsonline_rating_var_samp_fields';
  test_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "examsonline.rating" */
export type Examsonline_Rating_Var_Samp_Order_By = {
  test_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Examsonline_Rating_Variance_Fields = {
  __typename?: 'examsonline_rating_variance_fields';
  test_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "examsonline.rating" */
export type Examsonline_Rating_Variance_Order_By = {
  test_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** connects users to organisations */
export type Examsonline_Roles = {
  __typename?: 'examsonline_roles';
  active: Scalars['Boolean']['output'];
  /** An array relationship */
  banner_notifications: Array<Examsonline_Banner_Notification>;
  /** An aggregate relationship */
  banner_notifications_aggregate: Examsonline_Banner_Notification_Aggregate;
  createdAt: Scalars['timestamptz']['output'];
  /** An array relationship */
  files: Array<Examsonline_File>;
  /** An aggregate relationship */
  files_aggregate: Examsonline_File_Aggregate;
  id: Scalars['uuid']['output'];
  /** An array relationship */
  options: Array<Examsonline_Options>;
  /** An aggregate relationship */
  options_aggregate: Examsonline_Options_Aggregate;
  /** An array relationship */
  orders: Array<Examsonline_Order>;
  /** An aggregate relationship */
  orders_aggregate: Examsonline_Order_Aggregate;
  org_id: Scalars['uuid']['output'];
  /** An object relationship */
  organisation: Examsonline_Organisation;
  /** An array relationship */
  questions: Array<Examsonline_Questions>;
  /** An aggregate relationship */
  questions_aggregate: Examsonline_Questions_Aggregate;
  role: Scalars['String']['output'];
  /** An object relationship */
  roleByRole: _Enumtable_Role;
  updatedAt: Scalars['timestamptz']['output'];
  user: Scalars['String']['output'];
  /** An object relationship */
  userByUser: Examsonline_User;
};


/** connects users to organisations */
export type Examsonline_RolesBanner_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Banner_Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Banner_Notification_Order_By>>;
  where?: InputMaybe<Examsonline_Banner_Notification_Bool_Exp>;
};


/** connects users to organisations */
export type Examsonline_RolesBanner_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Banner_Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Banner_Notification_Order_By>>;
  where?: InputMaybe<Examsonline_Banner_Notification_Bool_Exp>;
};


/** connects users to organisations */
export type Examsonline_RolesFilesArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_File_Order_By>>;
  where?: InputMaybe<Examsonline_File_Bool_Exp>;
};


/** connects users to organisations */
export type Examsonline_RolesFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_File_Order_By>>;
  where?: InputMaybe<Examsonline_File_Bool_Exp>;
};


/** connects users to organisations */
export type Examsonline_RolesOptionsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Options_Order_By>>;
  where?: InputMaybe<Examsonline_Options_Bool_Exp>;
};


/** connects users to organisations */
export type Examsonline_RolesOptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Options_Order_By>>;
  where?: InputMaybe<Examsonline_Options_Bool_Exp>;
};


/** connects users to organisations */
export type Examsonline_RolesOrdersArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Order_Order_By>>;
  where?: InputMaybe<Examsonline_Order_Bool_Exp>;
};


/** connects users to organisations */
export type Examsonline_RolesOrders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Order_Order_By>>;
  where?: InputMaybe<Examsonline_Order_Bool_Exp>;
};


/** connects users to organisations */
export type Examsonline_RolesQuestionsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Questions_Order_By>>;
  where?: InputMaybe<Examsonline_Questions_Bool_Exp>;
};


/** connects users to organisations */
export type Examsonline_RolesQuestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Questions_Order_By>>;
  where?: InputMaybe<Examsonline_Questions_Bool_Exp>;
};

/** aggregated selection of "examsonline.roles" */
export type Examsonline_Roles_Aggregate = {
  __typename?: 'examsonline_roles_aggregate';
  aggregate?: Maybe<Examsonline_Roles_Aggregate_Fields>;
  nodes: Array<Examsonline_Roles>;
};

export type Examsonline_Roles_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Examsonline_Roles_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Examsonline_Roles_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Examsonline_Roles_Aggregate_Bool_Exp_Count>;
};

export type Examsonline_Roles_Aggregate_Bool_Exp_Bool_And = {
  arguments: Examsonline_Roles_Select_Column_Examsonline_Roles_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Roles_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Examsonline_Roles_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Examsonline_Roles_Select_Column_Examsonline_Roles_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Roles_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Examsonline_Roles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Examsonline_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Roles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "examsonline.roles" */
export type Examsonline_Roles_Aggregate_Fields = {
  __typename?: 'examsonline_roles_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Examsonline_Roles_Max_Fields>;
  min?: Maybe<Examsonline_Roles_Min_Fields>;
};


/** aggregate fields of "examsonline.roles" */
export type Examsonline_Roles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Examsonline_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "examsonline.roles" */
export type Examsonline_Roles_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Examsonline_Roles_Max_Order_By>;
  min?: InputMaybe<Examsonline_Roles_Min_Order_By>;
};

/** input type for inserting array relation for remote table "examsonline.roles" */
export type Examsonline_Roles_Arr_Rel_Insert_Input = {
  data: Array<Examsonline_Roles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Examsonline_Roles_On_Conflict>;
};

/** Boolean expression to filter rows from the table "examsonline.roles". All fields are combined with a logical 'AND'. */
export type Examsonline_Roles_Bool_Exp = {
  _and?: InputMaybe<Array<Examsonline_Roles_Bool_Exp>>;
  _not?: InputMaybe<Examsonline_Roles_Bool_Exp>;
  _or?: InputMaybe<Array<Examsonline_Roles_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  banner_notifications?: InputMaybe<Examsonline_Banner_Notification_Bool_Exp>;
  banner_notifications_aggregate?: InputMaybe<Examsonline_Banner_Notification_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  files?: InputMaybe<Examsonline_File_Bool_Exp>;
  files_aggregate?: InputMaybe<Examsonline_File_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  options?: InputMaybe<Examsonline_Options_Bool_Exp>;
  options_aggregate?: InputMaybe<Examsonline_Options_Aggregate_Bool_Exp>;
  orders?: InputMaybe<Examsonline_Order_Bool_Exp>;
  orders_aggregate?: InputMaybe<Examsonline_Order_Aggregate_Bool_Exp>;
  org_id?: InputMaybe<Uuid_Comparison_Exp>;
  organisation?: InputMaybe<Examsonline_Organisation_Bool_Exp>;
  questions?: InputMaybe<Examsonline_Questions_Bool_Exp>;
  questions_aggregate?: InputMaybe<Examsonline_Questions_Aggregate_Bool_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  roleByRole?: InputMaybe<_Enumtable_Role_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<String_Comparison_Exp>;
  userByUser?: InputMaybe<Examsonline_User_Bool_Exp>;
};

/** unique or primary key constraints on table "examsonline.roles" */
export enum Examsonline_Roles_Constraint {
  /** unique or primary key constraint on columns "org_id", "user", "role" */
  RolesRoleOrgIdUserKey = 'roles_role_org_id_user_key',
  /** unique or primary key constraint on columns "id" */
  UserOrgPkey = 'user_org_pkey'
}

/** input type for inserting data into table "examsonline.roles" */
export type Examsonline_Roles_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  banner_notifications?: InputMaybe<Examsonline_Banner_Notification_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  files?: InputMaybe<Examsonline_File_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  options?: InputMaybe<Examsonline_Options_Arr_Rel_Insert_Input>;
  orders?: InputMaybe<Examsonline_Order_Arr_Rel_Insert_Input>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  organisation?: InputMaybe<Examsonline_Organisation_Obj_Rel_Insert_Input>;
  questions?: InputMaybe<Examsonline_Questions_Arr_Rel_Insert_Input>;
  role?: InputMaybe<Scalars['String']['input']>;
  roleByRole?: InputMaybe<_Enumtable_Role_Obj_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
  userByUser?: InputMaybe<Examsonline_User_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Examsonline_Roles_Max_Fields = {
  __typename?: 'examsonline_roles_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  user?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "examsonline.roles" */
export type Examsonline_Roles_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Examsonline_Roles_Min_Fields = {
  __typename?: 'examsonline_roles_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  user?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "examsonline.roles" */
export type Examsonline_Roles_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "examsonline.roles" */
export type Examsonline_Roles_Mutation_Response = {
  __typename?: 'examsonline_roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Examsonline_Roles>;
};

/** input type for inserting object relation for remote table "examsonline.roles" */
export type Examsonline_Roles_Obj_Rel_Insert_Input = {
  data: Examsonline_Roles_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Examsonline_Roles_On_Conflict>;
};

/** on_conflict condition type for table "examsonline.roles" */
export type Examsonline_Roles_On_Conflict = {
  constraint: Examsonline_Roles_Constraint;
  update_columns?: Array<Examsonline_Roles_Update_Column>;
  where?: InputMaybe<Examsonline_Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "examsonline.roles". */
export type Examsonline_Roles_Order_By = {
  active?: InputMaybe<Order_By>;
  banner_notifications_aggregate?: InputMaybe<Examsonline_Banner_Notification_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  files_aggregate?: InputMaybe<Examsonline_File_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  options_aggregate?: InputMaybe<Examsonline_Options_Aggregate_Order_By>;
  orders_aggregate?: InputMaybe<Examsonline_Order_Aggregate_Order_By>;
  org_id?: InputMaybe<Order_By>;
  organisation?: InputMaybe<Examsonline_Organisation_Order_By>;
  questions_aggregate?: InputMaybe<Examsonline_Questions_Aggregate_Order_By>;
  role?: InputMaybe<Order_By>;
  roleByRole?: InputMaybe<_Enumtable_Role_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
  userByUser?: InputMaybe<Examsonline_User_Order_By>;
};

/** primary key columns input for table: examsonline.roles */
export type Examsonline_Roles_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "examsonline.roles" */
export enum Examsonline_Roles_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  User = 'user'
}

/** select "examsonline_roles_aggregate_bool_exp_bool_and_arguments_columns" columns of table "examsonline.roles" */
export enum Examsonline_Roles_Select_Column_Examsonline_Roles_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "examsonline_roles_aggregate_bool_exp_bool_or_arguments_columns" columns of table "examsonline.roles" */
export enum Examsonline_Roles_Select_Column_Examsonline_Roles_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "examsonline.roles" */
export type Examsonline_Roles_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "examsonline_roles" */
export type Examsonline_Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Examsonline_Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Examsonline_Roles_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "examsonline.roles" */
export enum Examsonline_Roles_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  User = 'user'
}

export type Examsonline_Roles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Examsonline_Roles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Examsonline_Roles_Bool_Exp;
};

/** stores answered options */
export type Examsonline_Selected_Options = {
  __typename?: 'examsonline_selected_options';
  /** An object relationship */
  attempt: Examsonline_Attempt;
  attempt_id: Scalars['uuid']['output'];
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  isCorrect?: Maybe<Scalars['Boolean']['output']>;
  /** An object relationship */
  option: Examsonline_Options;
  option_id: Scalars['Int']['output'];
  q_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  question?: Maybe<Examsonline_Questions>;
  type?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "examsonline.selected_options" */
export type Examsonline_Selected_Options_Aggregate = {
  __typename?: 'examsonline_selected_options_aggregate';
  aggregate?: Maybe<Examsonline_Selected_Options_Aggregate_Fields>;
  nodes: Array<Examsonline_Selected_Options>;
};

export type Examsonline_Selected_Options_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Examsonline_Selected_Options_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Examsonline_Selected_Options_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Examsonline_Selected_Options_Aggregate_Bool_Exp_Count>;
};

export type Examsonline_Selected_Options_Aggregate_Bool_Exp_Bool_And = {
  arguments: Examsonline_Selected_Options_Select_Column_Examsonline_Selected_Options_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Selected_Options_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Examsonline_Selected_Options_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Examsonline_Selected_Options_Select_Column_Examsonline_Selected_Options_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Selected_Options_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Examsonline_Selected_Options_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Examsonline_Selected_Options_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Selected_Options_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "examsonline.selected_options" */
export type Examsonline_Selected_Options_Aggregate_Fields = {
  __typename?: 'examsonline_selected_options_aggregate_fields';
  avg?: Maybe<Examsonline_Selected_Options_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Examsonline_Selected_Options_Max_Fields>;
  min?: Maybe<Examsonline_Selected_Options_Min_Fields>;
  stddev?: Maybe<Examsonline_Selected_Options_Stddev_Fields>;
  stddev_pop?: Maybe<Examsonline_Selected_Options_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Examsonline_Selected_Options_Stddev_Samp_Fields>;
  sum?: Maybe<Examsonline_Selected_Options_Sum_Fields>;
  var_pop?: Maybe<Examsonline_Selected_Options_Var_Pop_Fields>;
  var_samp?: Maybe<Examsonline_Selected_Options_Var_Samp_Fields>;
  variance?: Maybe<Examsonline_Selected_Options_Variance_Fields>;
};


/** aggregate fields of "examsonline.selected_options" */
export type Examsonline_Selected_Options_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Examsonline_Selected_Options_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "examsonline.selected_options" */
export type Examsonline_Selected_Options_Aggregate_Order_By = {
  avg?: InputMaybe<Examsonline_Selected_Options_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Examsonline_Selected_Options_Max_Order_By>;
  min?: InputMaybe<Examsonline_Selected_Options_Min_Order_By>;
  stddev?: InputMaybe<Examsonline_Selected_Options_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Examsonline_Selected_Options_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Examsonline_Selected_Options_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Examsonline_Selected_Options_Sum_Order_By>;
  var_pop?: InputMaybe<Examsonline_Selected_Options_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Examsonline_Selected_Options_Var_Samp_Order_By>;
  variance?: InputMaybe<Examsonline_Selected_Options_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "examsonline.selected_options" */
export type Examsonline_Selected_Options_Arr_Rel_Insert_Input = {
  data: Array<Examsonline_Selected_Options_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Examsonline_Selected_Options_On_Conflict>;
};

/** aggregate avg on columns */
export type Examsonline_Selected_Options_Avg_Fields = {
  __typename?: 'examsonline_selected_options_avg_fields';
  option_id?: Maybe<Scalars['Float']['output']>;
  q_id?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "examsonline.selected_options" */
export type Examsonline_Selected_Options_Avg_Order_By = {
  option_id?: InputMaybe<Order_By>;
  q_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "examsonline.selected_options". All fields are combined with a logical 'AND'. */
export type Examsonline_Selected_Options_Bool_Exp = {
  _and?: InputMaybe<Array<Examsonline_Selected_Options_Bool_Exp>>;
  _not?: InputMaybe<Examsonline_Selected_Options_Bool_Exp>;
  _or?: InputMaybe<Array<Examsonline_Selected_Options_Bool_Exp>>;
  attempt?: InputMaybe<Examsonline_Attempt_Bool_Exp>;
  attempt_id?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  isCorrect?: InputMaybe<Boolean_Comparison_Exp>;
  option?: InputMaybe<Examsonline_Options_Bool_Exp>;
  option_id?: InputMaybe<Int_Comparison_Exp>;
  q_id?: InputMaybe<Int_Comparison_Exp>;
  question?: InputMaybe<Examsonline_Questions_Bool_Exp>;
  type?: InputMaybe<Int_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "examsonline.selected_options" */
export enum Examsonline_Selected_Options_Constraint {
  /** unique or primary key constraint on columns "option_id", "attempt_id" */
  SelectedOptionsPkey = 'selected_options_pkey'
}

/** input type for incrementing numeric columns in table "examsonline.selected_options" */
export type Examsonline_Selected_Options_Inc_Input = {
  option_id?: InputMaybe<Scalars['Int']['input']>;
  q_id?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "examsonline.selected_options" */
export type Examsonline_Selected_Options_Insert_Input = {
  attempt?: InputMaybe<Examsonline_Attempt_Obj_Rel_Insert_Input>;
  attempt_id?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  isCorrect?: InputMaybe<Scalars['Boolean']['input']>;
  option?: InputMaybe<Examsonline_Options_Obj_Rel_Insert_Input>;
  option_id?: InputMaybe<Scalars['Int']['input']>;
  q_id?: InputMaybe<Scalars['Int']['input']>;
  question?: InputMaybe<Examsonline_Questions_Obj_Rel_Insert_Input>;
  type?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Examsonline_Selected_Options_Max_Fields = {
  __typename?: 'examsonline_selected_options_max_fields';
  attempt_id?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  option_id?: Maybe<Scalars['Int']['output']>;
  q_id?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "examsonline.selected_options" */
export type Examsonline_Selected_Options_Max_Order_By = {
  attempt_id?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  option_id?: InputMaybe<Order_By>;
  q_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Examsonline_Selected_Options_Min_Fields = {
  __typename?: 'examsonline_selected_options_min_fields';
  attempt_id?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  option_id?: Maybe<Scalars['Int']['output']>;
  q_id?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "examsonline.selected_options" */
export type Examsonline_Selected_Options_Min_Order_By = {
  attempt_id?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  option_id?: InputMaybe<Order_By>;
  q_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "examsonline.selected_options" */
export type Examsonline_Selected_Options_Mutation_Response = {
  __typename?: 'examsonline_selected_options_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Examsonline_Selected_Options>;
};

/** on_conflict condition type for table "examsonline.selected_options" */
export type Examsonline_Selected_Options_On_Conflict = {
  constraint: Examsonline_Selected_Options_Constraint;
  update_columns?: Array<Examsonline_Selected_Options_Update_Column>;
  where?: InputMaybe<Examsonline_Selected_Options_Bool_Exp>;
};

/** Ordering options when selecting data from "examsonline.selected_options". */
export type Examsonline_Selected_Options_Order_By = {
  attempt?: InputMaybe<Examsonline_Attempt_Order_By>;
  attempt_id?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  isCorrect?: InputMaybe<Order_By>;
  option?: InputMaybe<Examsonline_Options_Order_By>;
  option_id?: InputMaybe<Order_By>;
  q_id?: InputMaybe<Order_By>;
  question?: InputMaybe<Examsonline_Questions_Order_By>;
  type?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: examsonline.selected_options */
export type Examsonline_Selected_Options_Pk_Columns_Input = {
  attempt_id: Scalars['uuid']['input'];
  option_id: Scalars['Int']['input'];
};

/** select columns of table "examsonline.selected_options" */
export enum Examsonline_Selected_Options_Select_Column {
  /** column name */
  AttemptId = 'attempt_id',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  IsCorrect = 'isCorrect',
  /** column name */
  OptionId = 'option_id',
  /** column name */
  QId = 'q_id',
  /** column name */
  Type = 'type',
  /** column name */
  Value = 'value'
}

/** select "examsonline_selected_options_aggregate_bool_exp_bool_and_arguments_columns" columns of table "examsonline.selected_options" */
export enum Examsonline_Selected_Options_Select_Column_Examsonline_Selected_Options_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsCorrect = 'isCorrect'
}

/** select "examsonline_selected_options_aggregate_bool_exp_bool_or_arguments_columns" columns of table "examsonline.selected_options" */
export enum Examsonline_Selected_Options_Select_Column_Examsonline_Selected_Options_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsCorrect = 'isCorrect'
}

/** input type for updating data in table "examsonline.selected_options" */
export type Examsonline_Selected_Options_Set_Input = {
  attempt_id?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  isCorrect?: InputMaybe<Scalars['Boolean']['input']>;
  option_id?: InputMaybe<Scalars['Int']['input']>;
  q_id?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Examsonline_Selected_Options_Stddev_Fields = {
  __typename?: 'examsonline_selected_options_stddev_fields';
  option_id?: Maybe<Scalars['Float']['output']>;
  q_id?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "examsonline.selected_options" */
export type Examsonline_Selected_Options_Stddev_Order_By = {
  option_id?: InputMaybe<Order_By>;
  q_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Examsonline_Selected_Options_Stddev_Pop_Fields = {
  __typename?: 'examsonline_selected_options_stddev_pop_fields';
  option_id?: Maybe<Scalars['Float']['output']>;
  q_id?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "examsonline.selected_options" */
export type Examsonline_Selected_Options_Stddev_Pop_Order_By = {
  option_id?: InputMaybe<Order_By>;
  q_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Examsonline_Selected_Options_Stddev_Samp_Fields = {
  __typename?: 'examsonline_selected_options_stddev_samp_fields';
  option_id?: Maybe<Scalars['Float']['output']>;
  q_id?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "examsonline.selected_options" */
export type Examsonline_Selected_Options_Stddev_Samp_Order_By = {
  option_id?: InputMaybe<Order_By>;
  q_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "examsonline_selected_options" */
export type Examsonline_Selected_Options_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Examsonline_Selected_Options_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Examsonline_Selected_Options_Stream_Cursor_Value_Input = {
  attempt_id?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  isCorrect?: InputMaybe<Scalars['Boolean']['input']>;
  option_id?: InputMaybe<Scalars['Int']['input']>;
  q_id?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Examsonline_Selected_Options_Sum_Fields = {
  __typename?: 'examsonline_selected_options_sum_fields';
  option_id?: Maybe<Scalars['Int']['output']>;
  q_id?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "examsonline.selected_options" */
export type Examsonline_Selected_Options_Sum_Order_By = {
  option_id?: InputMaybe<Order_By>;
  q_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** update columns of table "examsonline.selected_options" */
export enum Examsonline_Selected_Options_Update_Column {
  /** column name */
  AttemptId = 'attempt_id',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  IsCorrect = 'isCorrect',
  /** column name */
  OptionId = 'option_id',
  /** column name */
  QId = 'q_id',
  /** column name */
  Type = 'type',
  /** column name */
  Value = 'value'
}

export type Examsonline_Selected_Options_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Examsonline_Selected_Options_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Examsonline_Selected_Options_Set_Input>;
  /** filter the rows which have to be updated */
  where: Examsonline_Selected_Options_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Examsonline_Selected_Options_Var_Pop_Fields = {
  __typename?: 'examsonline_selected_options_var_pop_fields';
  option_id?: Maybe<Scalars['Float']['output']>;
  q_id?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "examsonline.selected_options" */
export type Examsonline_Selected_Options_Var_Pop_Order_By = {
  option_id?: InputMaybe<Order_By>;
  q_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Examsonline_Selected_Options_Var_Samp_Fields = {
  __typename?: 'examsonline_selected_options_var_samp_fields';
  option_id?: Maybe<Scalars['Float']['output']>;
  q_id?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "examsonline.selected_options" */
export type Examsonline_Selected_Options_Var_Samp_Order_By = {
  option_id?: InputMaybe<Order_By>;
  q_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Examsonline_Selected_Options_Variance_Fields = {
  __typename?: 'examsonline_selected_options_variance_fields';
  option_id?: Maybe<Scalars['Float']['output']>;
  q_id?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "examsonline.selected_options" */
export type Examsonline_Selected_Options_Variance_Order_By = {
  option_id?: InputMaybe<Order_By>;
  q_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** columns and relationships of "examsonline.session" */
export type Examsonline_Session = {
  __typename?: 'examsonline_session';
  allowList?: Maybe<Scalars['jsonb']['output']>;
  endTime?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['uuid']['output'];
  instructions?: Maybe<Scalars['String']['output']>;
  maxattempt?: Maybe<Scalars['Int']['output']>;
  metadata?: Maybe<Scalars['jsonb']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  negativemarking?: Maybe<Scalars['numeric']['output']>;
  pause?: Maybe<Scalars['Boolean']['output']>;
  /** An array relationship */
  sessionrecords: Array<Examsonline_Sessionrecord>;
  /** An aggregate relationship */
  sessionrecords_aggregate: Examsonline_Sessionrecord_Aggregate;
  showResults?: Maybe<Scalars['Boolean']['output']>;
  startTime?: Maybe<Scalars['timestamp']['output']>;
  students?: Maybe<Scalars['jsonb']['output']>;
  /** An object relationship */
  test?: Maybe<Examsonline_Tests>;
  testId?: Maybe<Scalars['Int']['output']>;
};


/** columns and relationships of "examsonline.session" */
export type Examsonline_SessionAllowListArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "examsonline.session" */
export type Examsonline_SessionMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "examsonline.session" */
export type Examsonline_SessionSessionrecordsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Sessionrecord_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Sessionrecord_Order_By>>;
  where?: InputMaybe<Examsonline_Sessionrecord_Bool_Exp>;
};


/** columns and relationships of "examsonline.session" */
export type Examsonline_SessionSessionrecords_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Sessionrecord_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Sessionrecord_Order_By>>;
  where?: InputMaybe<Examsonline_Sessionrecord_Bool_Exp>;
};


/** columns and relationships of "examsonline.session" */
export type Examsonline_SessionStudentsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "examsonline.session" */
export type Examsonline_Session_Aggregate = {
  __typename?: 'examsonline_session_aggregate';
  aggregate?: Maybe<Examsonline_Session_Aggregate_Fields>;
  nodes: Array<Examsonline_Session>;
};

export type Examsonline_Session_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Examsonline_Session_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Examsonline_Session_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Examsonline_Session_Aggregate_Bool_Exp_Count>;
};

export type Examsonline_Session_Aggregate_Bool_Exp_Bool_And = {
  arguments: Examsonline_Session_Select_Column_Examsonline_Session_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Session_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Examsonline_Session_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Examsonline_Session_Select_Column_Examsonline_Session_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Session_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Examsonline_Session_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Examsonline_Session_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Session_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "examsonline.session" */
export type Examsonline_Session_Aggregate_Fields = {
  __typename?: 'examsonline_session_aggregate_fields';
  avg?: Maybe<Examsonline_Session_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Examsonline_Session_Max_Fields>;
  min?: Maybe<Examsonline_Session_Min_Fields>;
  stddev?: Maybe<Examsonline_Session_Stddev_Fields>;
  stddev_pop?: Maybe<Examsonline_Session_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Examsonline_Session_Stddev_Samp_Fields>;
  sum?: Maybe<Examsonline_Session_Sum_Fields>;
  var_pop?: Maybe<Examsonline_Session_Var_Pop_Fields>;
  var_samp?: Maybe<Examsonline_Session_Var_Samp_Fields>;
  variance?: Maybe<Examsonline_Session_Variance_Fields>;
};


/** aggregate fields of "examsonline.session" */
export type Examsonline_Session_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Examsonline_Session_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "examsonline.session" */
export type Examsonline_Session_Aggregate_Order_By = {
  avg?: InputMaybe<Examsonline_Session_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Examsonline_Session_Max_Order_By>;
  min?: InputMaybe<Examsonline_Session_Min_Order_By>;
  stddev?: InputMaybe<Examsonline_Session_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Examsonline_Session_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Examsonline_Session_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Examsonline_Session_Sum_Order_By>;
  var_pop?: InputMaybe<Examsonline_Session_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Examsonline_Session_Var_Samp_Order_By>;
  variance?: InputMaybe<Examsonline_Session_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Examsonline_Session_Append_Input = {
  allowList?: InputMaybe<Scalars['jsonb']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  students?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "examsonline.session" */
export type Examsonline_Session_Arr_Rel_Insert_Input = {
  data: Array<Examsonline_Session_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Examsonline_Session_On_Conflict>;
};

/** aggregate avg on columns */
export type Examsonline_Session_Avg_Fields = {
  __typename?: 'examsonline_session_avg_fields';
  maxattempt?: Maybe<Scalars['Float']['output']>;
  negativemarking?: Maybe<Scalars['Float']['output']>;
  testId?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "examsonline.session" */
export type Examsonline_Session_Avg_Order_By = {
  maxattempt?: InputMaybe<Order_By>;
  negativemarking?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "examsonline.session". All fields are combined with a logical 'AND'. */
export type Examsonline_Session_Bool_Exp = {
  _and?: InputMaybe<Array<Examsonline_Session_Bool_Exp>>;
  _not?: InputMaybe<Examsonline_Session_Bool_Exp>;
  _or?: InputMaybe<Array<Examsonline_Session_Bool_Exp>>;
  allowList?: InputMaybe<Jsonb_Comparison_Exp>;
  endTime?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  instructions?: InputMaybe<String_Comparison_Exp>;
  maxattempt?: InputMaybe<Int_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  negativemarking?: InputMaybe<Numeric_Comparison_Exp>;
  pause?: InputMaybe<Boolean_Comparison_Exp>;
  sessionrecords?: InputMaybe<Examsonline_Sessionrecord_Bool_Exp>;
  sessionrecords_aggregate?: InputMaybe<Examsonline_Sessionrecord_Aggregate_Bool_Exp>;
  showResults?: InputMaybe<Boolean_Comparison_Exp>;
  startTime?: InputMaybe<Timestamp_Comparison_Exp>;
  students?: InputMaybe<Jsonb_Comparison_Exp>;
  test?: InputMaybe<Examsonline_Tests_Bool_Exp>;
  testId?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "examsonline.session" */
export enum Examsonline_Session_Constraint {
  /** unique or primary key constraint on columns "id" */
  SessionsPkey = 'sessions_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Examsonline_Session_Delete_At_Path_Input = {
  allowList?: InputMaybe<Array<Scalars['String']['input']>>;
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
  students?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Examsonline_Session_Delete_Elem_Input = {
  allowList?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['Int']['input']>;
  students?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Examsonline_Session_Delete_Key_Input = {
  allowList?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  students?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "examsonline.session" */
export type Examsonline_Session_Inc_Input = {
  maxattempt?: InputMaybe<Scalars['Int']['input']>;
  negativemarking?: InputMaybe<Scalars['numeric']['input']>;
  testId?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "examsonline.session" */
export type Examsonline_Session_Insert_Input = {
  allowList?: InputMaybe<Scalars['jsonb']['input']>;
  endTime?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  maxattempt?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  negativemarking?: InputMaybe<Scalars['numeric']['input']>;
  pause?: InputMaybe<Scalars['Boolean']['input']>;
  sessionrecords?: InputMaybe<Examsonline_Sessionrecord_Arr_Rel_Insert_Input>;
  showResults?: InputMaybe<Scalars['Boolean']['input']>;
  startTime?: InputMaybe<Scalars['timestamp']['input']>;
  students?: InputMaybe<Scalars['jsonb']['input']>;
  test?: InputMaybe<Examsonline_Tests_Obj_Rel_Insert_Input>;
  testId?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Examsonline_Session_Max_Fields = {
  __typename?: 'examsonline_session_max_fields';
  endTime?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  instructions?: Maybe<Scalars['String']['output']>;
  maxattempt?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  negativemarking?: Maybe<Scalars['numeric']['output']>;
  startTime?: Maybe<Scalars['timestamp']['output']>;
  testId?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "examsonline.session" */
export type Examsonline_Session_Max_Order_By = {
  endTime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  instructions?: InputMaybe<Order_By>;
  maxattempt?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  negativemarking?: InputMaybe<Order_By>;
  startTime?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Examsonline_Session_Min_Fields = {
  __typename?: 'examsonline_session_min_fields';
  endTime?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  instructions?: Maybe<Scalars['String']['output']>;
  maxattempt?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  negativemarking?: Maybe<Scalars['numeric']['output']>;
  startTime?: Maybe<Scalars['timestamp']['output']>;
  testId?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "examsonline.session" */
export type Examsonline_Session_Min_Order_By = {
  endTime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  instructions?: InputMaybe<Order_By>;
  maxattempt?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  negativemarking?: InputMaybe<Order_By>;
  startTime?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "examsonline.session" */
export type Examsonline_Session_Mutation_Response = {
  __typename?: 'examsonline_session_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Examsonline_Session>;
};

/** input type for inserting object relation for remote table "examsonline.session" */
export type Examsonline_Session_Obj_Rel_Insert_Input = {
  data: Examsonline_Session_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Examsonline_Session_On_Conflict>;
};

/** on_conflict condition type for table "examsonline.session" */
export type Examsonline_Session_On_Conflict = {
  constraint: Examsonline_Session_Constraint;
  update_columns?: Array<Examsonline_Session_Update_Column>;
  where?: InputMaybe<Examsonline_Session_Bool_Exp>;
};

/** Ordering options when selecting data from "examsonline.session". */
export type Examsonline_Session_Order_By = {
  allowList?: InputMaybe<Order_By>;
  endTime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  instructions?: InputMaybe<Order_By>;
  maxattempt?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  negativemarking?: InputMaybe<Order_By>;
  pause?: InputMaybe<Order_By>;
  sessionrecords_aggregate?: InputMaybe<Examsonline_Sessionrecord_Aggregate_Order_By>;
  showResults?: InputMaybe<Order_By>;
  startTime?: InputMaybe<Order_By>;
  students?: InputMaybe<Order_By>;
  test?: InputMaybe<Examsonline_Tests_Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: examsonline.session */
export type Examsonline_Session_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Examsonline_Session_Prepend_Input = {
  allowList?: InputMaybe<Scalars['jsonb']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  students?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "examsonline.session" */
export enum Examsonline_Session_Select_Column {
  /** column name */
  AllowList = 'allowList',
  /** column name */
  EndTime = 'endTime',
  /** column name */
  Id = 'id',
  /** column name */
  Instructions = 'instructions',
  /** column name */
  Maxattempt = 'maxattempt',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  Negativemarking = 'negativemarking',
  /** column name */
  Pause = 'pause',
  /** column name */
  ShowResults = 'showResults',
  /** column name */
  StartTime = 'startTime',
  /** column name */
  Students = 'students',
  /** column name */
  TestId = 'testId'
}

/** select "examsonline_session_aggregate_bool_exp_bool_and_arguments_columns" columns of table "examsonline.session" */
export enum Examsonline_Session_Select_Column_Examsonline_Session_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Pause = 'pause',
  /** column name */
  ShowResults = 'showResults'
}

/** select "examsonline_session_aggregate_bool_exp_bool_or_arguments_columns" columns of table "examsonline.session" */
export enum Examsonline_Session_Select_Column_Examsonline_Session_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Pause = 'pause',
  /** column name */
  ShowResults = 'showResults'
}

/** input type for updating data in table "examsonline.session" */
export type Examsonline_Session_Set_Input = {
  allowList?: InputMaybe<Scalars['jsonb']['input']>;
  endTime?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  maxattempt?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  negativemarking?: InputMaybe<Scalars['numeric']['input']>;
  pause?: InputMaybe<Scalars['Boolean']['input']>;
  showResults?: InputMaybe<Scalars['Boolean']['input']>;
  startTime?: InputMaybe<Scalars['timestamp']['input']>;
  students?: InputMaybe<Scalars['jsonb']['input']>;
  testId?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Examsonline_Session_Stddev_Fields = {
  __typename?: 'examsonline_session_stddev_fields';
  maxattempt?: Maybe<Scalars['Float']['output']>;
  negativemarking?: Maybe<Scalars['Float']['output']>;
  testId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "examsonline.session" */
export type Examsonline_Session_Stddev_Order_By = {
  maxattempt?: InputMaybe<Order_By>;
  negativemarking?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Examsonline_Session_Stddev_Pop_Fields = {
  __typename?: 'examsonline_session_stddev_pop_fields';
  maxattempt?: Maybe<Scalars['Float']['output']>;
  negativemarking?: Maybe<Scalars['Float']['output']>;
  testId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "examsonline.session" */
export type Examsonline_Session_Stddev_Pop_Order_By = {
  maxattempt?: InputMaybe<Order_By>;
  negativemarking?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Examsonline_Session_Stddev_Samp_Fields = {
  __typename?: 'examsonline_session_stddev_samp_fields';
  maxattempt?: Maybe<Scalars['Float']['output']>;
  negativemarking?: Maybe<Scalars['Float']['output']>;
  testId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "examsonline.session" */
export type Examsonline_Session_Stddev_Samp_Order_By = {
  maxattempt?: InputMaybe<Order_By>;
  negativemarking?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "examsonline_session" */
export type Examsonline_Session_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Examsonline_Session_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Examsonline_Session_Stream_Cursor_Value_Input = {
  allowList?: InputMaybe<Scalars['jsonb']['input']>;
  endTime?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  maxattempt?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  negativemarking?: InputMaybe<Scalars['numeric']['input']>;
  pause?: InputMaybe<Scalars['Boolean']['input']>;
  showResults?: InputMaybe<Scalars['Boolean']['input']>;
  startTime?: InputMaybe<Scalars['timestamp']['input']>;
  students?: InputMaybe<Scalars['jsonb']['input']>;
  testId?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Examsonline_Session_Sum_Fields = {
  __typename?: 'examsonline_session_sum_fields';
  maxattempt?: Maybe<Scalars['Int']['output']>;
  negativemarking?: Maybe<Scalars['numeric']['output']>;
  testId?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "examsonline.session" */
export type Examsonline_Session_Sum_Order_By = {
  maxattempt?: InputMaybe<Order_By>;
  negativemarking?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** update columns of table "examsonline.session" */
export enum Examsonline_Session_Update_Column {
  /** column name */
  AllowList = 'allowList',
  /** column name */
  EndTime = 'endTime',
  /** column name */
  Id = 'id',
  /** column name */
  Instructions = 'instructions',
  /** column name */
  Maxattempt = 'maxattempt',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  Negativemarking = 'negativemarking',
  /** column name */
  Pause = 'pause',
  /** column name */
  ShowResults = 'showResults',
  /** column name */
  StartTime = 'startTime',
  /** column name */
  Students = 'students',
  /** column name */
  TestId = 'testId'
}

export type Examsonline_Session_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Examsonline_Session_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Examsonline_Session_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Examsonline_Session_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Examsonline_Session_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Examsonline_Session_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Examsonline_Session_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Examsonline_Session_Set_Input>;
  /** filter the rows which have to be updated */
  where: Examsonline_Session_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Examsonline_Session_Var_Pop_Fields = {
  __typename?: 'examsonline_session_var_pop_fields';
  maxattempt?: Maybe<Scalars['Float']['output']>;
  negativemarking?: Maybe<Scalars['Float']['output']>;
  testId?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "examsonline.session" */
export type Examsonline_Session_Var_Pop_Order_By = {
  maxattempt?: InputMaybe<Order_By>;
  negativemarking?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Examsonline_Session_Var_Samp_Fields = {
  __typename?: 'examsonline_session_var_samp_fields';
  maxattempt?: Maybe<Scalars['Float']['output']>;
  negativemarking?: Maybe<Scalars['Float']['output']>;
  testId?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "examsonline.session" */
export type Examsonline_Session_Var_Samp_Order_By = {
  maxattempt?: InputMaybe<Order_By>;
  negativemarking?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Examsonline_Session_Variance_Fields = {
  __typename?: 'examsonline_session_variance_fields';
  maxattempt?: Maybe<Scalars['Float']['output']>;
  negativemarking?: Maybe<Scalars['Float']['output']>;
  testId?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "examsonline.session" */
export type Examsonline_Session_Variance_Order_By = {
  maxattempt?: InputMaybe<Order_By>;
  negativemarking?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** columns and relationships of "examsonline.sessionrecord" */
export type Examsonline_Sessionrecord = {
  __typename?: 'examsonline_sessionrecord';
  attemptId?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['uuid']['output'];
  options?: Maybe<Scalars['jsonb']['output']>;
  points?: Maybe<Scalars['numeric']['output']>;
  questionId: Scalars['String']['output'];
  selectedOptions: Scalars['String']['output'];
  /** An object relationship */
  session: Examsonline_Session;
  sessionId: Scalars['uuid']['output'];
  studentId: Scalars['String']['output'];
  textAnswer?: Maybe<Scalars['String']['output']>;
  timeSpent: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};


/** columns and relationships of "examsonline.sessionrecord" */
export type Examsonline_SessionrecordOptionsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "examsonline.sessionrecord" */
export type Examsonline_Sessionrecord_Aggregate = {
  __typename?: 'examsonline_sessionrecord_aggregate';
  aggregate?: Maybe<Examsonline_Sessionrecord_Aggregate_Fields>;
  nodes: Array<Examsonline_Sessionrecord>;
};

export type Examsonline_Sessionrecord_Aggregate_Bool_Exp = {
  count?: InputMaybe<Examsonline_Sessionrecord_Aggregate_Bool_Exp_Count>;
};

export type Examsonline_Sessionrecord_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Examsonline_Sessionrecord_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Sessionrecord_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "examsonline.sessionrecord" */
export type Examsonline_Sessionrecord_Aggregate_Fields = {
  __typename?: 'examsonline_sessionrecord_aggregate_fields';
  avg?: Maybe<Examsonline_Sessionrecord_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Examsonline_Sessionrecord_Max_Fields>;
  min?: Maybe<Examsonline_Sessionrecord_Min_Fields>;
  stddev?: Maybe<Examsonline_Sessionrecord_Stddev_Fields>;
  stddev_pop?: Maybe<Examsonline_Sessionrecord_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Examsonline_Sessionrecord_Stddev_Samp_Fields>;
  sum?: Maybe<Examsonline_Sessionrecord_Sum_Fields>;
  var_pop?: Maybe<Examsonline_Sessionrecord_Var_Pop_Fields>;
  var_samp?: Maybe<Examsonline_Sessionrecord_Var_Samp_Fields>;
  variance?: Maybe<Examsonline_Sessionrecord_Variance_Fields>;
};


/** aggregate fields of "examsonline.sessionrecord" */
export type Examsonline_Sessionrecord_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Examsonline_Sessionrecord_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "examsonline.sessionrecord" */
export type Examsonline_Sessionrecord_Aggregate_Order_By = {
  avg?: InputMaybe<Examsonline_Sessionrecord_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Examsonline_Sessionrecord_Max_Order_By>;
  min?: InputMaybe<Examsonline_Sessionrecord_Min_Order_By>;
  stddev?: InputMaybe<Examsonline_Sessionrecord_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Examsonline_Sessionrecord_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Examsonline_Sessionrecord_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Examsonline_Sessionrecord_Sum_Order_By>;
  var_pop?: InputMaybe<Examsonline_Sessionrecord_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Examsonline_Sessionrecord_Var_Samp_Order_By>;
  variance?: InputMaybe<Examsonline_Sessionrecord_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Examsonline_Sessionrecord_Append_Input = {
  options?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "examsonline.sessionrecord" */
export type Examsonline_Sessionrecord_Arr_Rel_Insert_Input = {
  data: Array<Examsonline_Sessionrecord_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Examsonline_Sessionrecord_On_Conflict>;
};

/** aggregate avg on columns */
export type Examsonline_Sessionrecord_Avg_Fields = {
  __typename?: 'examsonline_sessionrecord_avg_fields';
  points?: Maybe<Scalars['Float']['output']>;
  timeSpent?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "examsonline.sessionrecord" */
export type Examsonline_Sessionrecord_Avg_Order_By = {
  points?: InputMaybe<Order_By>;
  timeSpent?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "examsonline.sessionrecord". All fields are combined with a logical 'AND'. */
export type Examsonline_Sessionrecord_Bool_Exp = {
  _and?: InputMaybe<Array<Examsonline_Sessionrecord_Bool_Exp>>;
  _not?: InputMaybe<Examsonline_Sessionrecord_Bool_Exp>;
  _or?: InputMaybe<Array<Examsonline_Sessionrecord_Bool_Exp>>;
  attemptId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  options?: InputMaybe<Jsonb_Comparison_Exp>;
  points?: InputMaybe<Numeric_Comparison_Exp>;
  questionId?: InputMaybe<String_Comparison_Exp>;
  selectedOptions?: InputMaybe<String_Comparison_Exp>;
  session?: InputMaybe<Examsonline_Session_Bool_Exp>;
  sessionId?: InputMaybe<Uuid_Comparison_Exp>;
  studentId?: InputMaybe<String_Comparison_Exp>;
  textAnswer?: InputMaybe<String_Comparison_Exp>;
  timeSpent?: InputMaybe<Int_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "examsonline.sessionrecord" */
export enum Examsonline_Sessionrecord_Constraint {
  /** unique or primary key constraint on columns "id" */
  SessionrecordPkey = 'sessionrecord_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Examsonline_Sessionrecord_Delete_At_Path_Input = {
  options?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Examsonline_Sessionrecord_Delete_Elem_Input = {
  options?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Examsonline_Sessionrecord_Delete_Key_Input = {
  options?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "examsonline.sessionrecord" */
export type Examsonline_Sessionrecord_Inc_Input = {
  points?: InputMaybe<Scalars['numeric']['input']>;
  timeSpent?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "examsonline.sessionrecord" */
export type Examsonline_Sessionrecord_Insert_Input = {
  attemptId?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
  points?: InputMaybe<Scalars['numeric']['input']>;
  questionId?: InputMaybe<Scalars['String']['input']>;
  selectedOptions?: InputMaybe<Scalars['String']['input']>;
  session?: InputMaybe<Examsonline_Session_Obj_Rel_Insert_Input>;
  sessionId?: InputMaybe<Scalars['uuid']['input']>;
  studentId?: InputMaybe<Scalars['String']['input']>;
  textAnswer?: InputMaybe<Scalars['String']['input']>;
  timeSpent?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Examsonline_Sessionrecord_Max_Fields = {
  __typename?: 'examsonline_sessionrecord_max_fields';
  attemptId?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  points?: Maybe<Scalars['numeric']['output']>;
  questionId?: Maybe<Scalars['String']['output']>;
  selectedOptions?: Maybe<Scalars['String']['output']>;
  sessionId?: Maybe<Scalars['uuid']['output']>;
  studentId?: Maybe<Scalars['String']['output']>;
  textAnswer?: Maybe<Scalars['String']['output']>;
  timeSpent?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "examsonline.sessionrecord" */
export type Examsonline_Sessionrecord_Max_Order_By = {
  attemptId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  points?: InputMaybe<Order_By>;
  questionId?: InputMaybe<Order_By>;
  selectedOptions?: InputMaybe<Order_By>;
  sessionId?: InputMaybe<Order_By>;
  studentId?: InputMaybe<Order_By>;
  textAnswer?: InputMaybe<Order_By>;
  timeSpent?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Examsonline_Sessionrecord_Min_Fields = {
  __typename?: 'examsonline_sessionrecord_min_fields';
  attemptId?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  points?: Maybe<Scalars['numeric']['output']>;
  questionId?: Maybe<Scalars['String']['output']>;
  selectedOptions?: Maybe<Scalars['String']['output']>;
  sessionId?: Maybe<Scalars['uuid']['output']>;
  studentId?: Maybe<Scalars['String']['output']>;
  textAnswer?: Maybe<Scalars['String']['output']>;
  timeSpent?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "examsonline.sessionrecord" */
export type Examsonline_Sessionrecord_Min_Order_By = {
  attemptId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  points?: InputMaybe<Order_By>;
  questionId?: InputMaybe<Order_By>;
  selectedOptions?: InputMaybe<Order_By>;
  sessionId?: InputMaybe<Order_By>;
  studentId?: InputMaybe<Order_By>;
  textAnswer?: InputMaybe<Order_By>;
  timeSpent?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "examsonline.sessionrecord" */
export type Examsonline_Sessionrecord_Mutation_Response = {
  __typename?: 'examsonline_sessionrecord_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Examsonline_Sessionrecord>;
};

/** on_conflict condition type for table "examsonline.sessionrecord" */
export type Examsonline_Sessionrecord_On_Conflict = {
  constraint: Examsonline_Sessionrecord_Constraint;
  update_columns?: Array<Examsonline_Sessionrecord_Update_Column>;
  where?: InputMaybe<Examsonline_Sessionrecord_Bool_Exp>;
};

/** Ordering options when selecting data from "examsonline.sessionrecord". */
export type Examsonline_Sessionrecord_Order_By = {
  attemptId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  options?: InputMaybe<Order_By>;
  points?: InputMaybe<Order_By>;
  questionId?: InputMaybe<Order_By>;
  selectedOptions?: InputMaybe<Order_By>;
  session?: InputMaybe<Examsonline_Session_Order_By>;
  sessionId?: InputMaybe<Order_By>;
  studentId?: InputMaybe<Order_By>;
  textAnswer?: InputMaybe<Order_By>;
  timeSpent?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: examsonline.sessionrecord */
export type Examsonline_Sessionrecord_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Examsonline_Sessionrecord_Prepend_Input = {
  options?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "examsonline.sessionrecord" */
export enum Examsonline_Sessionrecord_Select_Column {
  /** column name */
  AttemptId = 'attemptId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Options = 'options',
  /** column name */
  Points = 'points',
  /** column name */
  QuestionId = 'questionId',
  /** column name */
  SelectedOptions = 'selectedOptions',
  /** column name */
  SessionId = 'sessionId',
  /** column name */
  StudentId = 'studentId',
  /** column name */
  TextAnswer = 'textAnswer',
  /** column name */
  TimeSpent = 'timeSpent',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "examsonline.sessionrecord" */
export type Examsonline_Sessionrecord_Set_Input = {
  attemptId?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
  points?: InputMaybe<Scalars['numeric']['input']>;
  questionId?: InputMaybe<Scalars['String']['input']>;
  selectedOptions?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<Scalars['uuid']['input']>;
  studentId?: InputMaybe<Scalars['String']['input']>;
  textAnswer?: InputMaybe<Scalars['String']['input']>;
  timeSpent?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Examsonline_Sessionrecord_Stddev_Fields = {
  __typename?: 'examsonline_sessionrecord_stddev_fields';
  points?: Maybe<Scalars['Float']['output']>;
  timeSpent?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "examsonline.sessionrecord" */
export type Examsonline_Sessionrecord_Stddev_Order_By = {
  points?: InputMaybe<Order_By>;
  timeSpent?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Examsonline_Sessionrecord_Stddev_Pop_Fields = {
  __typename?: 'examsonline_sessionrecord_stddev_pop_fields';
  points?: Maybe<Scalars['Float']['output']>;
  timeSpent?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "examsonline.sessionrecord" */
export type Examsonline_Sessionrecord_Stddev_Pop_Order_By = {
  points?: InputMaybe<Order_By>;
  timeSpent?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Examsonline_Sessionrecord_Stddev_Samp_Fields = {
  __typename?: 'examsonline_sessionrecord_stddev_samp_fields';
  points?: Maybe<Scalars['Float']['output']>;
  timeSpent?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "examsonline.sessionrecord" */
export type Examsonline_Sessionrecord_Stddev_Samp_Order_By = {
  points?: InputMaybe<Order_By>;
  timeSpent?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "examsonline_sessionrecord" */
export type Examsonline_Sessionrecord_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Examsonline_Sessionrecord_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Examsonline_Sessionrecord_Stream_Cursor_Value_Input = {
  attemptId?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
  points?: InputMaybe<Scalars['numeric']['input']>;
  questionId?: InputMaybe<Scalars['String']['input']>;
  selectedOptions?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<Scalars['uuid']['input']>;
  studentId?: InputMaybe<Scalars['String']['input']>;
  textAnswer?: InputMaybe<Scalars['String']['input']>;
  timeSpent?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Examsonline_Sessionrecord_Sum_Fields = {
  __typename?: 'examsonline_sessionrecord_sum_fields';
  points?: Maybe<Scalars['numeric']['output']>;
  timeSpent?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "examsonline.sessionrecord" */
export type Examsonline_Sessionrecord_Sum_Order_By = {
  points?: InputMaybe<Order_By>;
  timeSpent?: InputMaybe<Order_By>;
};

/** update columns of table "examsonline.sessionrecord" */
export enum Examsonline_Sessionrecord_Update_Column {
  /** column name */
  AttemptId = 'attemptId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Options = 'options',
  /** column name */
  Points = 'points',
  /** column name */
  QuestionId = 'questionId',
  /** column name */
  SelectedOptions = 'selectedOptions',
  /** column name */
  SessionId = 'sessionId',
  /** column name */
  StudentId = 'studentId',
  /** column name */
  TextAnswer = 'textAnswer',
  /** column name */
  TimeSpent = 'timeSpent',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Examsonline_Sessionrecord_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Examsonline_Sessionrecord_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Examsonline_Sessionrecord_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Examsonline_Sessionrecord_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Examsonline_Sessionrecord_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Examsonline_Sessionrecord_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Examsonline_Sessionrecord_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Examsonline_Sessionrecord_Set_Input>;
  /** filter the rows which have to be updated */
  where: Examsonline_Sessionrecord_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Examsonline_Sessionrecord_Var_Pop_Fields = {
  __typename?: 'examsonline_sessionrecord_var_pop_fields';
  points?: Maybe<Scalars['Float']['output']>;
  timeSpent?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "examsonline.sessionrecord" */
export type Examsonline_Sessionrecord_Var_Pop_Order_By = {
  points?: InputMaybe<Order_By>;
  timeSpent?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Examsonline_Sessionrecord_Var_Samp_Fields = {
  __typename?: 'examsonline_sessionrecord_var_samp_fields';
  points?: Maybe<Scalars['Float']['output']>;
  timeSpent?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "examsonline.sessionrecord" */
export type Examsonline_Sessionrecord_Var_Samp_Order_By = {
  points?: InputMaybe<Order_By>;
  timeSpent?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Examsonline_Sessionrecord_Variance_Fields = {
  __typename?: 'examsonline_sessionrecord_variance_fields';
  points?: Maybe<Scalars['Float']['output']>;
  timeSpent?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "examsonline.sessionrecord" */
export type Examsonline_Sessionrecord_Variance_Order_By = {
  points?: InputMaybe<Order_By>;
  timeSpent?: InputMaybe<Order_By>;
};

/** columns and relationships of "examsonline.sessions" */
export type Examsonline_Sessions = {
  __typename?: 'examsonline_sessions';
  allow_list?: Maybe<Scalars['jsonb']['output']>;
  endTime?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['uuid']['output'];
  startTime?: Maybe<Scalars['timestamp']['output']>;
  students?: Maybe<Scalars['jsonb']['output']>;
  testId: Scalars['uuid']['output'];
};


/** columns and relationships of "examsonline.sessions" */
export type Examsonline_SessionsAllow_ListArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "examsonline.sessions" */
export type Examsonline_SessionsStudentsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "examsonline.sessions" */
export type Examsonline_Sessions_Aggregate = {
  __typename?: 'examsonline_sessions_aggregate';
  aggregate?: Maybe<Examsonline_Sessions_Aggregate_Fields>;
  nodes: Array<Examsonline_Sessions>;
};

/** aggregate fields of "examsonline.sessions" */
export type Examsonline_Sessions_Aggregate_Fields = {
  __typename?: 'examsonline_sessions_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Examsonline_Sessions_Max_Fields>;
  min?: Maybe<Examsonline_Sessions_Min_Fields>;
};


/** aggregate fields of "examsonline.sessions" */
export type Examsonline_Sessions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Examsonline_Sessions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Examsonline_Sessions_Append_Input = {
  allow_list?: InputMaybe<Scalars['jsonb']['input']>;
  students?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "examsonline.sessions". All fields are combined with a logical 'AND'. */
export type Examsonline_Sessions_Bool_Exp = {
  _and?: InputMaybe<Array<Examsonline_Sessions_Bool_Exp>>;
  _not?: InputMaybe<Examsonline_Sessions_Bool_Exp>;
  _or?: InputMaybe<Array<Examsonline_Sessions_Bool_Exp>>;
  allow_list?: InputMaybe<Jsonb_Comparison_Exp>;
  endTime?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  startTime?: InputMaybe<Timestamp_Comparison_Exp>;
  students?: InputMaybe<Jsonb_Comparison_Exp>;
  testId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "examsonline.sessions" */
export enum Examsonline_Sessions_Constraint {
  /** unique or primary key constraint on columns "id" */
  SessionsPkey1 = 'sessions_pkey1'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Examsonline_Sessions_Delete_At_Path_Input = {
  allow_list?: InputMaybe<Array<Scalars['String']['input']>>;
  students?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Examsonline_Sessions_Delete_Elem_Input = {
  allow_list?: InputMaybe<Scalars['Int']['input']>;
  students?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Examsonline_Sessions_Delete_Key_Input = {
  allow_list?: InputMaybe<Scalars['String']['input']>;
  students?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "examsonline.sessions" */
export type Examsonline_Sessions_Insert_Input = {
  allow_list?: InputMaybe<Scalars['jsonb']['input']>;
  endTime?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  startTime?: InputMaybe<Scalars['timestamp']['input']>;
  students?: InputMaybe<Scalars['jsonb']['input']>;
  testId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Examsonline_Sessions_Max_Fields = {
  __typename?: 'examsonline_sessions_max_fields';
  endTime?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  startTime?: Maybe<Scalars['timestamp']['output']>;
  testId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type Examsonline_Sessions_Min_Fields = {
  __typename?: 'examsonline_sessions_min_fields';
  endTime?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  startTime?: Maybe<Scalars['timestamp']['output']>;
  testId?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "examsonline.sessions" */
export type Examsonline_Sessions_Mutation_Response = {
  __typename?: 'examsonline_sessions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Examsonline_Sessions>;
};

/** on_conflict condition type for table "examsonline.sessions" */
export type Examsonline_Sessions_On_Conflict = {
  constraint: Examsonline_Sessions_Constraint;
  update_columns?: Array<Examsonline_Sessions_Update_Column>;
  where?: InputMaybe<Examsonline_Sessions_Bool_Exp>;
};

/** Ordering options when selecting data from "examsonline.sessions". */
export type Examsonline_Sessions_Order_By = {
  allow_list?: InputMaybe<Order_By>;
  endTime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  startTime?: InputMaybe<Order_By>;
  students?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: examsonline.sessions */
export type Examsonline_Sessions_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Examsonline_Sessions_Prepend_Input = {
  allow_list?: InputMaybe<Scalars['jsonb']['input']>;
  students?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "examsonline.sessions" */
export enum Examsonline_Sessions_Select_Column {
  /** column name */
  AllowList = 'allow_list',
  /** column name */
  EndTime = 'endTime',
  /** column name */
  Id = 'id',
  /** column name */
  StartTime = 'startTime',
  /** column name */
  Students = 'students',
  /** column name */
  TestId = 'testId'
}

/** input type for updating data in table "examsonline.sessions" */
export type Examsonline_Sessions_Set_Input = {
  allow_list?: InputMaybe<Scalars['jsonb']['input']>;
  endTime?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  startTime?: InputMaybe<Scalars['timestamp']['input']>;
  students?: InputMaybe<Scalars['jsonb']['input']>;
  testId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "examsonline_sessions" */
export type Examsonline_Sessions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Examsonline_Sessions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Examsonline_Sessions_Stream_Cursor_Value_Input = {
  allow_list?: InputMaybe<Scalars['jsonb']['input']>;
  endTime?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  startTime?: InputMaybe<Scalars['timestamp']['input']>;
  students?: InputMaybe<Scalars['jsonb']['input']>;
  testId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "examsonline.sessions" */
export enum Examsonline_Sessions_Update_Column {
  /** column name */
  AllowList = 'allow_list',
  /** column name */
  EndTime = 'endTime',
  /** column name */
  Id = 'id',
  /** column name */
  StartTime = 'startTime',
  /** column name */
  Students = 'students',
  /** column name */
  TestId = 'testId'
}

export type Examsonline_Sessions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Examsonline_Sessions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Examsonline_Sessions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Examsonline_Sessions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Examsonline_Sessions_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Examsonline_Sessions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Examsonline_Sessions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Examsonline_Sessions_Bool_Exp;
};

/** contains test sections */
export type Examsonline_Test_Sections = {
  __typename?: 'examsonline_test_sections';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  /** An array relationship */
  questions: Array<Examsonline_Questions>;
  /** An aggregate relationship */
  questions_aggregate: Examsonline_Questions_Aggregate;
  /** An object relationship */
  quiz?: Maybe<Examsonline_Quiz>;
  quiz_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  test?: Maybe<Examsonline_Tests>;
  test_id?: Maybe<Scalars['Int']['output']>;
};


/** contains test sections */
export type Examsonline_Test_SectionsQuestionsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Questions_Order_By>>;
  where?: InputMaybe<Examsonline_Questions_Bool_Exp>;
};


/** contains test sections */
export type Examsonline_Test_SectionsQuestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Questions_Order_By>>;
  where?: InputMaybe<Examsonline_Questions_Bool_Exp>;
};

/** aggregated selection of "examsonline.test_sections" */
export type Examsonline_Test_Sections_Aggregate = {
  __typename?: 'examsonline_test_sections_aggregate';
  aggregate?: Maybe<Examsonline_Test_Sections_Aggregate_Fields>;
  nodes: Array<Examsonline_Test_Sections>;
};

export type Examsonline_Test_Sections_Aggregate_Bool_Exp = {
  count?: InputMaybe<Examsonline_Test_Sections_Aggregate_Bool_Exp_Count>;
};

export type Examsonline_Test_Sections_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Examsonline_Test_Sections_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Test_Sections_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "examsonline.test_sections" */
export type Examsonline_Test_Sections_Aggregate_Fields = {
  __typename?: 'examsonline_test_sections_aggregate_fields';
  avg?: Maybe<Examsonline_Test_Sections_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Examsonline_Test_Sections_Max_Fields>;
  min?: Maybe<Examsonline_Test_Sections_Min_Fields>;
  stddev?: Maybe<Examsonline_Test_Sections_Stddev_Fields>;
  stddev_pop?: Maybe<Examsonline_Test_Sections_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Examsonline_Test_Sections_Stddev_Samp_Fields>;
  sum?: Maybe<Examsonline_Test_Sections_Sum_Fields>;
  var_pop?: Maybe<Examsonline_Test_Sections_Var_Pop_Fields>;
  var_samp?: Maybe<Examsonline_Test_Sections_Var_Samp_Fields>;
  variance?: Maybe<Examsonline_Test_Sections_Variance_Fields>;
};


/** aggregate fields of "examsonline.test_sections" */
export type Examsonline_Test_Sections_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Examsonline_Test_Sections_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "examsonline.test_sections" */
export type Examsonline_Test_Sections_Aggregate_Order_By = {
  avg?: InputMaybe<Examsonline_Test_Sections_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Examsonline_Test_Sections_Max_Order_By>;
  min?: InputMaybe<Examsonline_Test_Sections_Min_Order_By>;
  stddev?: InputMaybe<Examsonline_Test_Sections_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Examsonline_Test_Sections_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Examsonline_Test_Sections_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Examsonline_Test_Sections_Sum_Order_By>;
  var_pop?: InputMaybe<Examsonline_Test_Sections_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Examsonline_Test_Sections_Var_Samp_Order_By>;
  variance?: InputMaybe<Examsonline_Test_Sections_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "examsonline.test_sections" */
export type Examsonline_Test_Sections_Arr_Rel_Insert_Input = {
  data: Array<Examsonline_Test_Sections_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Examsonline_Test_Sections_On_Conflict>;
};

/** aggregate avg on columns */
export type Examsonline_Test_Sections_Avg_Fields = {
  __typename?: 'examsonline_test_sections_avg_fields';
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "examsonline.test_sections" */
export type Examsonline_Test_Sections_Avg_Order_By = {
  test_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "examsonline.test_sections". All fields are combined with a logical 'AND'. */
export type Examsonline_Test_Sections_Bool_Exp = {
  _and?: InputMaybe<Array<Examsonline_Test_Sections_Bool_Exp>>;
  _not?: InputMaybe<Examsonline_Test_Sections_Bool_Exp>;
  _or?: InputMaybe<Array<Examsonline_Test_Sections_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  questions?: InputMaybe<Examsonline_Questions_Bool_Exp>;
  questions_aggregate?: InputMaybe<Examsonline_Questions_Aggregate_Bool_Exp>;
  quiz?: InputMaybe<Examsonline_Quiz_Bool_Exp>;
  quiz_id?: InputMaybe<Uuid_Comparison_Exp>;
  test?: InputMaybe<Examsonline_Tests_Bool_Exp>;
  test_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "examsonline.test_sections" */
export enum Examsonline_Test_Sections_Constraint {
  /** unique or primary key constraint on columns "id" */
  TestSectionsPkey = 'test_sections_pkey'
}

/** input type for incrementing numeric columns in table "examsonline.test_sections" */
export type Examsonline_Test_Sections_Inc_Input = {
  test_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "examsonline.test_sections" */
export type Examsonline_Test_Sections_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  questions?: InputMaybe<Examsonline_Questions_Arr_Rel_Insert_Input>;
  quiz?: InputMaybe<Examsonline_Quiz_Obj_Rel_Insert_Input>;
  quiz_id?: InputMaybe<Scalars['uuid']['input']>;
  test?: InputMaybe<Examsonline_Tests_Obj_Rel_Insert_Input>;
  test_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Examsonline_Test_Sections_Max_Fields = {
  __typename?: 'examsonline_test_sections_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  quiz_id?: Maybe<Scalars['uuid']['output']>;
  test_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "examsonline.test_sections" */
export type Examsonline_Test_Sections_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  quiz_id?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Examsonline_Test_Sections_Min_Fields = {
  __typename?: 'examsonline_test_sections_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  quiz_id?: Maybe<Scalars['uuid']['output']>;
  test_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "examsonline.test_sections" */
export type Examsonline_Test_Sections_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  quiz_id?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "examsonline.test_sections" */
export type Examsonline_Test_Sections_Mutation_Response = {
  __typename?: 'examsonline_test_sections_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Examsonline_Test_Sections>;
};

/** input type for inserting object relation for remote table "examsonline.test_sections" */
export type Examsonline_Test_Sections_Obj_Rel_Insert_Input = {
  data: Examsonline_Test_Sections_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Examsonline_Test_Sections_On_Conflict>;
};

/** on_conflict condition type for table "examsonline.test_sections" */
export type Examsonline_Test_Sections_On_Conflict = {
  constraint: Examsonline_Test_Sections_Constraint;
  update_columns?: Array<Examsonline_Test_Sections_Update_Column>;
  where?: InputMaybe<Examsonline_Test_Sections_Bool_Exp>;
};

/** Ordering options when selecting data from "examsonline.test_sections". */
export type Examsonline_Test_Sections_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  questions_aggregate?: InputMaybe<Examsonline_Questions_Aggregate_Order_By>;
  quiz?: InputMaybe<Examsonline_Quiz_Order_By>;
  quiz_id?: InputMaybe<Order_By>;
  test?: InputMaybe<Examsonline_Tests_Order_By>;
  test_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: examsonline.test_sections */
export type Examsonline_Test_Sections_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "examsonline.test_sections" */
export enum Examsonline_Test_Sections_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  QuizId = 'quiz_id',
  /** column name */
  TestId = 'test_id'
}

/** input type for updating data in table "examsonline.test_sections" */
export type Examsonline_Test_Sections_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  quiz_id?: InputMaybe<Scalars['uuid']['input']>;
  test_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Examsonline_Test_Sections_Stddev_Fields = {
  __typename?: 'examsonline_test_sections_stddev_fields';
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "examsonline.test_sections" */
export type Examsonline_Test_Sections_Stddev_Order_By = {
  test_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Examsonline_Test_Sections_Stddev_Pop_Fields = {
  __typename?: 'examsonline_test_sections_stddev_pop_fields';
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "examsonline.test_sections" */
export type Examsonline_Test_Sections_Stddev_Pop_Order_By = {
  test_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Examsonline_Test_Sections_Stddev_Samp_Fields = {
  __typename?: 'examsonline_test_sections_stddev_samp_fields';
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "examsonline.test_sections" */
export type Examsonline_Test_Sections_Stddev_Samp_Order_By = {
  test_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "examsonline_test_sections" */
export type Examsonline_Test_Sections_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Examsonline_Test_Sections_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Examsonline_Test_Sections_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  quiz_id?: InputMaybe<Scalars['uuid']['input']>;
  test_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Examsonline_Test_Sections_Sum_Fields = {
  __typename?: 'examsonline_test_sections_sum_fields';
  test_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "examsonline.test_sections" */
export type Examsonline_Test_Sections_Sum_Order_By = {
  test_id?: InputMaybe<Order_By>;
};

/** update columns of table "examsonline.test_sections" */
export enum Examsonline_Test_Sections_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  QuizId = 'quiz_id',
  /** column name */
  TestId = 'test_id'
}

export type Examsonline_Test_Sections_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Examsonline_Test_Sections_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Examsonline_Test_Sections_Set_Input>;
  /** filter the rows which have to be updated */
  where: Examsonline_Test_Sections_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Examsonline_Test_Sections_Var_Pop_Fields = {
  __typename?: 'examsonline_test_sections_var_pop_fields';
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "examsonline.test_sections" */
export type Examsonline_Test_Sections_Var_Pop_Order_By = {
  test_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Examsonline_Test_Sections_Var_Samp_Fields = {
  __typename?: 'examsonline_test_sections_var_samp_fields';
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "examsonline.test_sections" */
export type Examsonline_Test_Sections_Var_Samp_Order_By = {
  test_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Examsonline_Test_Sections_Variance_Fields = {
  __typename?: 'examsonline_test_sections_variance_fields';
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "examsonline.test_sections" */
export type Examsonline_Test_Sections_Variance_Order_By = {
  test_id?: InputMaybe<Order_By>;
};

/** table for tests */
export type Examsonline_Tests = {
  __typename?: 'examsonline_tests';
  accesstype: Scalars['Int']['output'];
  /** An array relationship */
  assignments: Array<Examsonline_Assignments>;
  /** An aggregate relationship */
  assignments_aggregate: Examsonline_Assignments_Aggregate;
  /** An array relationship */
  attempts: Array<Examsonline_Attempt>;
  /** An aggregate relationship */
  attempts_aggregate: Examsonline_Attempt_Aggregate;
  author: Scalars['String']['output'];
  /** An object relationship */
  author_to_user?: Maybe<Examsonline_User>;
  /** An array relationship */
  comments: Array<Examsonline_Comments>;
  /** An aggregate relationship */
  comments_aggregate: Examsonline_Comments_Aggregate;
  /** An array relationship */
  contests: Array<Examsonline_Contest>;
  /** An aggregate relationship */
  contests_aggregate: Examsonline_Contest_Aggregate;
  createdAt: Scalars['timestamp']['output'];
  description: Scalars['String']['output'];
  document?: Maybe<Scalars['String']['output']>;
  emailnotify: Scalars['Boolean']['output'];
  enabled: Scalars['Boolean']['output'];
  errorMessage: Scalars['String']['output'];
  /** An array relationship */
  files: Array<Examsonline_File>;
  /** An aggregate relationship */
  files_aggregate: Examsonline_File_Aggregate;
  id: Scalars['Int']['output'];
  isDraft: Scalars['Boolean']['output'];
  maxAttempts: Scalars['Int']['output'];
  metadata?: Maybe<Scalars['jsonb']['output']>;
  name: Scalars['String']['output'];
  new_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  orders: Array<Examsonline_Order>;
  /** An aggregate relationship */
  orders_aggregate: Examsonline_Order_Aggregate;
  pincode?: Maybe<Scalars['String']['output']>;
  points: Scalars['numeric']['output'];
  price?: Maybe<Scalars['numeric']['output']>;
  /** An array relationship */
  questions: Array<Examsonline_Questions>;
  /** An aggregate relationship */
  questions_aggregate: Examsonline_Questions_Aggregate;
  randomise: Scalars['Boolean']['output'];
  /** An array relationship */
  ratings: Array<Examsonline_Rating>;
  /** An aggregate relationship */
  ratings_aggregate: Examsonline_Rating_Aggregate;
  /** An array relationship */
  sessions: Array<Examsonline_Session>;
  /** An aggregate relationship */
  sessions_aggregate: Examsonline_Session_Aggregate;
  showResult: Scalars['Boolean']['output'];
  /** An array relationship */
  test_sections: Array<Examsonline_Test_Sections>;
  /** An aggregate relationship */
  test_sections_aggregate: Examsonline_Test_Sections_Aggregate;
  time: Scalars['Int']['output'];
  /** An array relationship */
  topics: Array<Examsonline_Topics>;
  /** An aggregate relationship */
  topics_aggregate: Examsonline_Topics_Aggregate;
  updatedAt: Scalars['timestamptz']['output'];
};


/** table for tests */
export type Examsonline_TestsAssignmentsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Assignments_Order_By>>;
  where?: InputMaybe<Examsonline_Assignments_Bool_Exp>;
};


/** table for tests */
export type Examsonline_TestsAssignments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Assignments_Order_By>>;
  where?: InputMaybe<Examsonline_Assignments_Bool_Exp>;
};


/** table for tests */
export type Examsonline_TestsAttemptsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Attempt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Attempt_Order_By>>;
  where?: InputMaybe<Examsonline_Attempt_Bool_Exp>;
};


/** table for tests */
export type Examsonline_TestsAttempts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Attempt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Attempt_Order_By>>;
  where?: InputMaybe<Examsonline_Attempt_Bool_Exp>;
};


/** table for tests */
export type Examsonline_TestsCommentsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Comments_Order_By>>;
  where?: InputMaybe<Examsonline_Comments_Bool_Exp>;
};


/** table for tests */
export type Examsonline_TestsComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Comments_Order_By>>;
  where?: InputMaybe<Examsonline_Comments_Bool_Exp>;
};


/** table for tests */
export type Examsonline_TestsContestsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Contest_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Contest_Order_By>>;
  where?: InputMaybe<Examsonline_Contest_Bool_Exp>;
};


/** table for tests */
export type Examsonline_TestsContests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Contest_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Contest_Order_By>>;
  where?: InputMaybe<Examsonline_Contest_Bool_Exp>;
};


/** table for tests */
export type Examsonline_TestsFilesArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_File_Order_By>>;
  where?: InputMaybe<Examsonline_File_Bool_Exp>;
};


/** table for tests */
export type Examsonline_TestsFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_File_Order_By>>;
  where?: InputMaybe<Examsonline_File_Bool_Exp>;
};


/** table for tests */
export type Examsonline_TestsMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** table for tests */
export type Examsonline_TestsOrdersArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Order_Order_By>>;
  where?: InputMaybe<Examsonline_Order_Bool_Exp>;
};


/** table for tests */
export type Examsonline_TestsOrders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Order_Order_By>>;
  where?: InputMaybe<Examsonline_Order_Bool_Exp>;
};


/** table for tests */
export type Examsonline_TestsQuestionsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Questions_Order_By>>;
  where?: InputMaybe<Examsonline_Questions_Bool_Exp>;
};


/** table for tests */
export type Examsonline_TestsQuestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Questions_Order_By>>;
  where?: InputMaybe<Examsonline_Questions_Bool_Exp>;
};


/** table for tests */
export type Examsonline_TestsRatingsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Rating_Order_By>>;
  where?: InputMaybe<Examsonline_Rating_Bool_Exp>;
};


/** table for tests */
export type Examsonline_TestsRatings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Rating_Order_By>>;
  where?: InputMaybe<Examsonline_Rating_Bool_Exp>;
};


/** table for tests */
export type Examsonline_TestsSessionsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Session_Order_By>>;
  where?: InputMaybe<Examsonline_Session_Bool_Exp>;
};


/** table for tests */
export type Examsonline_TestsSessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Session_Order_By>>;
  where?: InputMaybe<Examsonline_Session_Bool_Exp>;
};


/** table for tests */
export type Examsonline_TestsTest_SectionsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Test_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Test_Sections_Order_By>>;
  where?: InputMaybe<Examsonline_Test_Sections_Bool_Exp>;
};


/** table for tests */
export type Examsonline_TestsTest_Sections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Test_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Test_Sections_Order_By>>;
  where?: InputMaybe<Examsonline_Test_Sections_Bool_Exp>;
};


/** table for tests */
export type Examsonline_TestsTopicsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Topics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Topics_Order_By>>;
  where?: InputMaybe<Examsonline_Topics_Bool_Exp>;
};


/** table for tests */
export type Examsonline_TestsTopics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Topics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Topics_Order_By>>;
  where?: InputMaybe<Examsonline_Topics_Bool_Exp>;
};

/** aggregated selection of "examsonline.tests" */
export type Examsonline_Tests_Aggregate = {
  __typename?: 'examsonline_tests_aggregate';
  aggregate?: Maybe<Examsonline_Tests_Aggregate_Fields>;
  nodes: Array<Examsonline_Tests>;
};

/** aggregate fields of "examsonline.tests" */
export type Examsonline_Tests_Aggregate_Fields = {
  __typename?: 'examsonline_tests_aggregate_fields';
  avg?: Maybe<Examsonline_Tests_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Examsonline_Tests_Max_Fields>;
  min?: Maybe<Examsonline_Tests_Min_Fields>;
  stddev?: Maybe<Examsonline_Tests_Stddev_Fields>;
  stddev_pop?: Maybe<Examsonline_Tests_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Examsonline_Tests_Stddev_Samp_Fields>;
  sum?: Maybe<Examsonline_Tests_Sum_Fields>;
  var_pop?: Maybe<Examsonline_Tests_Var_Pop_Fields>;
  var_samp?: Maybe<Examsonline_Tests_Var_Samp_Fields>;
  variance?: Maybe<Examsonline_Tests_Variance_Fields>;
};


/** aggregate fields of "examsonline.tests" */
export type Examsonline_Tests_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Examsonline_Tests_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Examsonline_Tests_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Examsonline_Tests_Avg_Fields = {
  __typename?: 'examsonline_tests_avg_fields';
  accesstype?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  maxAttempts?: Maybe<Scalars['Float']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  time?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "examsonline.tests". All fields are combined with a logical 'AND'. */
export type Examsonline_Tests_Bool_Exp = {
  _and?: InputMaybe<Array<Examsonline_Tests_Bool_Exp>>;
  _not?: InputMaybe<Examsonline_Tests_Bool_Exp>;
  _or?: InputMaybe<Array<Examsonline_Tests_Bool_Exp>>;
  accesstype?: InputMaybe<Int_Comparison_Exp>;
  assignments?: InputMaybe<Examsonline_Assignments_Bool_Exp>;
  assignments_aggregate?: InputMaybe<Examsonline_Assignments_Aggregate_Bool_Exp>;
  attempts?: InputMaybe<Examsonline_Attempt_Bool_Exp>;
  attempts_aggregate?: InputMaybe<Examsonline_Attempt_Aggregate_Bool_Exp>;
  author?: InputMaybe<String_Comparison_Exp>;
  author_to_user?: InputMaybe<Examsonline_User_Bool_Exp>;
  comments?: InputMaybe<Examsonline_Comments_Bool_Exp>;
  comments_aggregate?: InputMaybe<Examsonline_Comments_Aggregate_Bool_Exp>;
  contests?: InputMaybe<Examsonline_Contest_Bool_Exp>;
  contests_aggregate?: InputMaybe<Examsonline_Contest_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  document?: InputMaybe<String_Comparison_Exp>;
  emailnotify?: InputMaybe<Boolean_Comparison_Exp>;
  enabled?: InputMaybe<Boolean_Comparison_Exp>;
  errorMessage?: InputMaybe<String_Comparison_Exp>;
  files?: InputMaybe<Examsonline_File_Bool_Exp>;
  files_aggregate?: InputMaybe<Examsonline_File_Aggregate_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  isDraft?: InputMaybe<Boolean_Comparison_Exp>;
  maxAttempts?: InputMaybe<Int_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  new_id?: InputMaybe<Uuid_Comparison_Exp>;
  orders?: InputMaybe<Examsonline_Order_Bool_Exp>;
  orders_aggregate?: InputMaybe<Examsonline_Order_Aggregate_Bool_Exp>;
  pincode?: InputMaybe<String_Comparison_Exp>;
  points?: InputMaybe<Numeric_Comparison_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
  questions?: InputMaybe<Examsonline_Questions_Bool_Exp>;
  questions_aggregate?: InputMaybe<Examsonline_Questions_Aggregate_Bool_Exp>;
  randomise?: InputMaybe<Boolean_Comparison_Exp>;
  ratings?: InputMaybe<Examsonline_Rating_Bool_Exp>;
  ratings_aggregate?: InputMaybe<Examsonline_Rating_Aggregate_Bool_Exp>;
  sessions?: InputMaybe<Examsonline_Session_Bool_Exp>;
  sessions_aggregate?: InputMaybe<Examsonline_Session_Aggregate_Bool_Exp>;
  showResult?: InputMaybe<Boolean_Comparison_Exp>;
  test_sections?: InputMaybe<Examsonline_Test_Sections_Bool_Exp>;
  test_sections_aggregate?: InputMaybe<Examsonline_Test_Sections_Aggregate_Bool_Exp>;
  time?: InputMaybe<Int_Comparison_Exp>;
  topics?: InputMaybe<Examsonline_Topics_Bool_Exp>;
  topics_aggregate?: InputMaybe<Examsonline_Topics_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "examsonline.tests" */
export enum Examsonline_Tests_Constraint {
  /** unique or primary key constraint on columns "id" */
  TestsIdKey = 'tests_id_key',
  /** unique or primary key constraint on columns "id" */
  TestsPkey = 'tests_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Examsonline_Tests_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Examsonline_Tests_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Examsonline_Tests_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "examsonline.tests" */
export type Examsonline_Tests_Inc_Input = {
  accesstype?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  maxAttempts?: InputMaybe<Scalars['Int']['input']>;
  points?: InputMaybe<Scalars['numeric']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  time?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "examsonline.tests" */
export type Examsonline_Tests_Insert_Input = {
  accesstype?: InputMaybe<Scalars['Int']['input']>;
  assignments?: InputMaybe<Examsonline_Assignments_Arr_Rel_Insert_Input>;
  attempts?: InputMaybe<Examsonline_Attempt_Arr_Rel_Insert_Input>;
  author?: InputMaybe<Scalars['String']['input']>;
  author_to_user?: InputMaybe<Examsonline_User_Obj_Rel_Insert_Input>;
  comments?: InputMaybe<Examsonline_Comments_Arr_Rel_Insert_Input>;
  contests?: InputMaybe<Examsonline_Contest_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  document?: InputMaybe<Scalars['String']['input']>;
  emailnotify?: InputMaybe<Scalars['Boolean']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  errorMessage?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<Examsonline_File_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isDraft?: InputMaybe<Scalars['Boolean']['input']>;
  maxAttempts?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  new_id?: InputMaybe<Scalars['uuid']['input']>;
  orders?: InputMaybe<Examsonline_Order_Arr_Rel_Insert_Input>;
  pincode?: InputMaybe<Scalars['String']['input']>;
  points?: InputMaybe<Scalars['numeric']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  questions?: InputMaybe<Examsonline_Questions_Arr_Rel_Insert_Input>;
  randomise?: InputMaybe<Scalars['Boolean']['input']>;
  ratings?: InputMaybe<Examsonline_Rating_Arr_Rel_Insert_Input>;
  sessions?: InputMaybe<Examsonline_Session_Arr_Rel_Insert_Input>;
  showResult?: InputMaybe<Scalars['Boolean']['input']>;
  test_sections?: InputMaybe<Examsonline_Test_Sections_Arr_Rel_Insert_Input>;
  time?: InputMaybe<Scalars['Int']['input']>;
  topics?: InputMaybe<Examsonline_Topics_Arr_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Examsonline_Tests_Max_Fields = {
  __typename?: 'examsonline_tests_max_fields';
  accesstype?: Maybe<Scalars['Int']['output']>;
  author?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Scalars['String']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  maxAttempts?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  new_id?: Maybe<Scalars['uuid']['output']>;
  pincode?: Maybe<Scalars['String']['output']>;
  points?: Maybe<Scalars['numeric']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  time?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Examsonline_Tests_Min_Fields = {
  __typename?: 'examsonline_tests_min_fields';
  accesstype?: Maybe<Scalars['Int']['output']>;
  author?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Scalars['String']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  maxAttempts?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  new_id?: Maybe<Scalars['uuid']['output']>;
  pincode?: Maybe<Scalars['String']['output']>;
  points?: Maybe<Scalars['numeric']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  time?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "examsonline.tests" */
export type Examsonline_Tests_Mutation_Response = {
  __typename?: 'examsonline_tests_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Examsonline_Tests>;
};

/** input type for inserting object relation for remote table "examsonline.tests" */
export type Examsonline_Tests_Obj_Rel_Insert_Input = {
  data: Examsonline_Tests_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Examsonline_Tests_On_Conflict>;
};

/** on_conflict condition type for table "examsonline.tests" */
export type Examsonline_Tests_On_Conflict = {
  constraint: Examsonline_Tests_Constraint;
  update_columns?: Array<Examsonline_Tests_Update_Column>;
  where?: InputMaybe<Examsonline_Tests_Bool_Exp>;
};

/** Ordering options when selecting data from "examsonline.tests". */
export type Examsonline_Tests_Order_By = {
  accesstype?: InputMaybe<Order_By>;
  assignments_aggregate?: InputMaybe<Examsonline_Assignments_Aggregate_Order_By>;
  attempts_aggregate?: InputMaybe<Examsonline_Attempt_Aggregate_Order_By>;
  author?: InputMaybe<Order_By>;
  author_to_user?: InputMaybe<Examsonline_User_Order_By>;
  comments_aggregate?: InputMaybe<Examsonline_Comments_Aggregate_Order_By>;
  contests_aggregate?: InputMaybe<Examsonline_Contest_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  document?: InputMaybe<Order_By>;
  emailnotify?: InputMaybe<Order_By>;
  enabled?: InputMaybe<Order_By>;
  errorMessage?: InputMaybe<Order_By>;
  files_aggregate?: InputMaybe<Examsonline_File_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  isDraft?: InputMaybe<Order_By>;
  maxAttempts?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  new_id?: InputMaybe<Order_By>;
  orders_aggregate?: InputMaybe<Examsonline_Order_Aggregate_Order_By>;
  pincode?: InputMaybe<Order_By>;
  points?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  questions_aggregate?: InputMaybe<Examsonline_Questions_Aggregate_Order_By>;
  randomise?: InputMaybe<Order_By>;
  ratings_aggregate?: InputMaybe<Examsonline_Rating_Aggregate_Order_By>;
  sessions_aggregate?: InputMaybe<Examsonline_Session_Aggregate_Order_By>;
  showResult?: InputMaybe<Order_By>;
  test_sections_aggregate?: InputMaybe<Examsonline_Test_Sections_Aggregate_Order_By>;
  time?: InputMaybe<Order_By>;
  topics_aggregate?: InputMaybe<Examsonline_Topics_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: examsonline.tests */
export type Examsonline_Tests_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Examsonline_Tests_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "examsonline.tests" */
export enum Examsonline_Tests_Select_Column {
  /** column name */
  Accesstype = 'accesstype',
  /** column name */
  Author = 'author',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Document = 'document',
  /** column name */
  Emailnotify = 'emailnotify',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  ErrorMessage = 'errorMessage',
  /** column name */
  Id = 'id',
  /** column name */
  IsDraft = 'isDraft',
  /** column name */
  MaxAttempts = 'maxAttempts',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  NewId = 'new_id',
  /** column name */
  Pincode = 'pincode',
  /** column name */
  Points = 'points',
  /** column name */
  Price = 'price',
  /** column name */
  Randomise = 'randomise',
  /** column name */
  ShowResult = 'showResult',
  /** column name */
  Time = 'time',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "examsonline.tests" */
export type Examsonline_Tests_Set_Input = {
  accesstype?: InputMaybe<Scalars['Int']['input']>;
  author?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  document?: InputMaybe<Scalars['String']['input']>;
  emailnotify?: InputMaybe<Scalars['Boolean']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  errorMessage?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isDraft?: InputMaybe<Scalars['Boolean']['input']>;
  maxAttempts?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  new_id?: InputMaybe<Scalars['uuid']['input']>;
  pincode?: InputMaybe<Scalars['String']['input']>;
  points?: InputMaybe<Scalars['numeric']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  randomise?: InputMaybe<Scalars['Boolean']['input']>;
  showResult?: InputMaybe<Scalars['Boolean']['input']>;
  time?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Examsonline_Tests_Stddev_Fields = {
  __typename?: 'examsonline_tests_stddev_fields';
  accesstype?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  maxAttempts?: Maybe<Scalars['Float']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  time?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Examsonline_Tests_Stddev_Pop_Fields = {
  __typename?: 'examsonline_tests_stddev_pop_fields';
  accesstype?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  maxAttempts?: Maybe<Scalars['Float']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  time?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Examsonline_Tests_Stddev_Samp_Fields = {
  __typename?: 'examsonline_tests_stddev_samp_fields';
  accesstype?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  maxAttempts?: Maybe<Scalars['Float']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  time?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "examsonline_tests" */
export type Examsonline_Tests_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Examsonline_Tests_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Examsonline_Tests_Stream_Cursor_Value_Input = {
  accesstype?: InputMaybe<Scalars['Int']['input']>;
  author?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  document?: InputMaybe<Scalars['String']['input']>;
  emailnotify?: InputMaybe<Scalars['Boolean']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  errorMessage?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isDraft?: InputMaybe<Scalars['Boolean']['input']>;
  maxAttempts?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  new_id?: InputMaybe<Scalars['uuid']['input']>;
  pincode?: InputMaybe<Scalars['String']['input']>;
  points?: InputMaybe<Scalars['numeric']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  randomise?: InputMaybe<Scalars['Boolean']['input']>;
  showResult?: InputMaybe<Scalars['Boolean']['input']>;
  time?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Examsonline_Tests_Sum_Fields = {
  __typename?: 'examsonline_tests_sum_fields';
  accesstype?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  maxAttempts?: Maybe<Scalars['Int']['output']>;
  points?: Maybe<Scalars['numeric']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  time?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "examsonline.tests" */
export enum Examsonline_Tests_Update_Column {
  /** column name */
  Accesstype = 'accesstype',
  /** column name */
  Author = 'author',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Document = 'document',
  /** column name */
  Emailnotify = 'emailnotify',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  ErrorMessage = 'errorMessage',
  /** column name */
  Id = 'id',
  /** column name */
  IsDraft = 'isDraft',
  /** column name */
  MaxAttempts = 'maxAttempts',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  NewId = 'new_id',
  /** column name */
  Pincode = 'pincode',
  /** column name */
  Points = 'points',
  /** column name */
  Price = 'price',
  /** column name */
  Randomise = 'randomise',
  /** column name */
  ShowResult = 'showResult',
  /** column name */
  Time = 'time',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Examsonline_Tests_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Examsonline_Tests_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Examsonline_Tests_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Examsonline_Tests_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Examsonline_Tests_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Examsonline_Tests_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Examsonline_Tests_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Examsonline_Tests_Set_Input>;
  /** filter the rows which have to be updated */
  where: Examsonline_Tests_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Examsonline_Tests_Var_Pop_Fields = {
  __typename?: 'examsonline_tests_var_pop_fields';
  accesstype?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  maxAttempts?: Maybe<Scalars['Float']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  time?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Examsonline_Tests_Var_Samp_Fields = {
  __typename?: 'examsonline_tests_var_samp_fields';
  accesstype?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  maxAttempts?: Maybe<Scalars['Float']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  time?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Examsonline_Tests_Variance_Fields = {
  __typename?: 'examsonline_tests_variance_fields';
  accesstype?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  maxAttempts?: Maybe<Scalars['Float']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  time?: Maybe<Scalars['Float']['output']>;
};

/** saves all support tickets */
export type Examsonline_Tickets = {
  __typename?: 'examsonline_tickets';
  assigned?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  comments: Array<Examsonline_Comments>;
  /** An aggregate relationship */
  comments_aggregate: Examsonline_Comments_Aggregate;
  createdAt: Scalars['timestamptz']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  org_id?: Maybe<Scalars['uuid']['output']>;
  priority: Scalars['String']['output'];
  status: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  user?: Maybe<Examsonline_User>;
};


/** saves all support tickets */
export type Examsonline_TicketsCommentsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Comments_Order_By>>;
  where?: InputMaybe<Examsonline_Comments_Bool_Exp>;
};


/** saves all support tickets */
export type Examsonline_TicketsComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Comments_Order_By>>;
  where?: InputMaybe<Examsonline_Comments_Bool_Exp>;
};

/** aggregated selection of "examsonline.tickets" */
export type Examsonline_Tickets_Aggregate = {
  __typename?: 'examsonline_tickets_aggregate';
  aggregate?: Maybe<Examsonline_Tickets_Aggregate_Fields>;
  nodes: Array<Examsonline_Tickets>;
};

export type Examsonline_Tickets_Aggregate_Bool_Exp = {
  count?: InputMaybe<Examsonline_Tickets_Aggregate_Bool_Exp_Count>;
};

export type Examsonline_Tickets_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Examsonline_Tickets_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Tickets_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "examsonline.tickets" */
export type Examsonline_Tickets_Aggregate_Fields = {
  __typename?: 'examsonline_tickets_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Examsonline_Tickets_Max_Fields>;
  min?: Maybe<Examsonline_Tickets_Min_Fields>;
};


/** aggregate fields of "examsonline.tickets" */
export type Examsonline_Tickets_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Examsonline_Tickets_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "examsonline.tickets" */
export type Examsonline_Tickets_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Examsonline_Tickets_Max_Order_By>;
  min?: InputMaybe<Examsonline_Tickets_Min_Order_By>;
};

/** input type for inserting array relation for remote table "examsonline.tickets" */
export type Examsonline_Tickets_Arr_Rel_Insert_Input = {
  data: Array<Examsonline_Tickets_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Examsonline_Tickets_On_Conflict>;
};

/** Boolean expression to filter rows from the table "examsonline.tickets". All fields are combined with a logical 'AND'. */
export type Examsonline_Tickets_Bool_Exp = {
  _and?: InputMaybe<Array<Examsonline_Tickets_Bool_Exp>>;
  _not?: InputMaybe<Examsonline_Tickets_Bool_Exp>;
  _or?: InputMaybe<Array<Examsonline_Tickets_Bool_Exp>>;
  assigned?: InputMaybe<String_Comparison_Exp>;
  comments?: InputMaybe<Examsonline_Comments_Bool_Exp>;
  comments_aggregate?: InputMaybe<Examsonline_Comments_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  createdBy?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  org_id?: InputMaybe<Uuid_Comparison_Exp>;
  priority?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Examsonline_User_Bool_Exp>;
};

/** unique or primary key constraints on table "examsonline.tickets" */
export enum Examsonline_Tickets_Constraint {
  /** unique or primary key constraint on columns "id" */
  TicketsPkey = 'tickets_pkey'
}

/** input type for inserting data into table "examsonline.tickets" */
export type Examsonline_Tickets_Insert_Input = {
  assigned?: InputMaybe<Scalars['String']['input']>;
  comments?: InputMaybe<Examsonline_Comments_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  priority?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Examsonline_User_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Examsonline_Tickets_Max_Fields = {
  __typename?: 'examsonline_tickets_max_fields';
  assigned?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  priority?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "examsonline.tickets" */
export type Examsonline_Tickets_Max_Order_By = {
  assigned?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Examsonline_Tickets_Min_Fields = {
  __typename?: 'examsonline_tickets_min_fields';
  assigned?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['uuid']['output']>;
  priority?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "examsonline.tickets" */
export type Examsonline_Tickets_Min_Order_By = {
  assigned?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "examsonline.tickets" */
export type Examsonline_Tickets_Mutation_Response = {
  __typename?: 'examsonline_tickets_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Examsonline_Tickets>;
};

/** input type for inserting object relation for remote table "examsonline.tickets" */
export type Examsonline_Tickets_Obj_Rel_Insert_Input = {
  data: Examsonline_Tickets_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Examsonline_Tickets_On_Conflict>;
};

/** on_conflict condition type for table "examsonline.tickets" */
export type Examsonline_Tickets_On_Conflict = {
  constraint: Examsonline_Tickets_Constraint;
  update_columns?: Array<Examsonline_Tickets_Update_Column>;
  where?: InputMaybe<Examsonline_Tickets_Bool_Exp>;
};

/** Ordering options when selecting data from "examsonline.tickets". */
export type Examsonline_Tickets_Order_By = {
  assigned?: InputMaybe<Order_By>;
  comments_aggregate?: InputMaybe<Examsonline_Comments_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Examsonline_User_Order_By>;
};

/** primary key columns input for table: examsonline.tickets */
export type Examsonline_Tickets_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "examsonline.tickets" */
export enum Examsonline_Tickets_Select_Column {
  /** column name */
  Assigned = 'assigned',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  Priority = 'priority',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "examsonline.tickets" */
export type Examsonline_Tickets_Set_Input = {
  assigned?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  priority?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "examsonline_tickets" */
export type Examsonline_Tickets_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Examsonline_Tickets_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Examsonline_Tickets_Stream_Cursor_Value_Input = {
  assigned?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  org_id?: InputMaybe<Scalars['uuid']['input']>;
  priority?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "examsonline.tickets" */
export enum Examsonline_Tickets_Update_Column {
  /** column name */
  Assigned = 'assigned',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  Priority = 'priority',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Examsonline_Tickets_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Examsonline_Tickets_Set_Input>;
  /** filter the rows which have to be updated */
  where: Examsonline_Tickets_Bool_Exp;
};

/** contains time spent on each question */
export type Examsonline_Timespent = {
  __typename?: 'examsonline_timespent';
  /** An object relationship */
  attempt: Examsonline_Attempt;
  attempt_id: Scalars['uuid']['output'];
  id: Scalars['Int']['output'];
  q_id: Scalars['Int']['output'];
  /** An object relationship */
  question: Examsonline_Questions;
  time: Scalars['numeric']['output'];
};

/** aggregated selection of "examsonline.timespent" */
export type Examsonline_Timespent_Aggregate = {
  __typename?: 'examsonline_timespent_aggregate';
  aggregate?: Maybe<Examsonline_Timespent_Aggregate_Fields>;
  nodes: Array<Examsonline_Timespent>;
};

export type Examsonline_Timespent_Aggregate_Bool_Exp = {
  count?: InputMaybe<Examsonline_Timespent_Aggregate_Bool_Exp_Count>;
};

export type Examsonline_Timespent_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Examsonline_Timespent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Timespent_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "examsonline.timespent" */
export type Examsonline_Timespent_Aggregate_Fields = {
  __typename?: 'examsonline_timespent_aggregate_fields';
  avg?: Maybe<Examsonline_Timespent_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Examsonline_Timespent_Max_Fields>;
  min?: Maybe<Examsonline_Timespent_Min_Fields>;
  stddev?: Maybe<Examsonline_Timespent_Stddev_Fields>;
  stddev_pop?: Maybe<Examsonline_Timespent_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Examsonline_Timespent_Stddev_Samp_Fields>;
  sum?: Maybe<Examsonline_Timespent_Sum_Fields>;
  var_pop?: Maybe<Examsonline_Timespent_Var_Pop_Fields>;
  var_samp?: Maybe<Examsonline_Timespent_Var_Samp_Fields>;
  variance?: Maybe<Examsonline_Timespent_Variance_Fields>;
};


/** aggregate fields of "examsonline.timespent" */
export type Examsonline_Timespent_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Examsonline_Timespent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "examsonline.timespent" */
export type Examsonline_Timespent_Aggregate_Order_By = {
  avg?: InputMaybe<Examsonline_Timespent_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Examsonline_Timespent_Max_Order_By>;
  min?: InputMaybe<Examsonline_Timespent_Min_Order_By>;
  stddev?: InputMaybe<Examsonline_Timespent_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Examsonline_Timespent_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Examsonline_Timespent_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Examsonline_Timespent_Sum_Order_By>;
  var_pop?: InputMaybe<Examsonline_Timespent_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Examsonline_Timespent_Var_Samp_Order_By>;
  variance?: InputMaybe<Examsonline_Timespent_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "examsonline.timespent" */
export type Examsonline_Timespent_Arr_Rel_Insert_Input = {
  data: Array<Examsonline_Timespent_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Examsonline_Timespent_On_Conflict>;
};

/** aggregate avg on columns */
export type Examsonline_Timespent_Avg_Fields = {
  __typename?: 'examsonline_timespent_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  q_id?: Maybe<Scalars['Float']['output']>;
  time?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "examsonline.timespent" */
export type Examsonline_Timespent_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  q_id?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "examsonline.timespent". All fields are combined with a logical 'AND'. */
export type Examsonline_Timespent_Bool_Exp = {
  _and?: InputMaybe<Array<Examsonline_Timespent_Bool_Exp>>;
  _not?: InputMaybe<Examsonline_Timespent_Bool_Exp>;
  _or?: InputMaybe<Array<Examsonline_Timespent_Bool_Exp>>;
  attempt?: InputMaybe<Examsonline_Attempt_Bool_Exp>;
  attempt_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  q_id?: InputMaybe<Int_Comparison_Exp>;
  question?: InputMaybe<Examsonline_Questions_Bool_Exp>;
  time?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "examsonline.timespent" */
export enum Examsonline_Timespent_Constraint {
  /** unique or primary key constraint on columns "id" */
  TimespentPkey = 'timespent_pkey',
  /** unique or primary key constraint on columns "id", "attempt_id", "q_id" */
  TimespentQIdAttemptIdIdKey = 'timespent_q_id_attempt_id_id_key'
}

/** input type for incrementing numeric columns in table "examsonline.timespent" */
export type Examsonline_Timespent_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  q_id?: InputMaybe<Scalars['Int']['input']>;
  time?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "examsonline.timespent" */
export type Examsonline_Timespent_Insert_Input = {
  attempt?: InputMaybe<Examsonline_Attempt_Obj_Rel_Insert_Input>;
  attempt_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  q_id?: InputMaybe<Scalars['Int']['input']>;
  question?: InputMaybe<Examsonline_Questions_Obj_Rel_Insert_Input>;
  time?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate max on columns */
export type Examsonline_Timespent_Max_Fields = {
  __typename?: 'examsonline_timespent_max_fields';
  attempt_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  q_id?: Maybe<Scalars['Int']['output']>;
  time?: Maybe<Scalars['numeric']['output']>;
};

/** order by max() on columns of table "examsonline.timespent" */
export type Examsonline_Timespent_Max_Order_By = {
  attempt_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q_id?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Examsonline_Timespent_Min_Fields = {
  __typename?: 'examsonline_timespent_min_fields';
  attempt_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  q_id?: Maybe<Scalars['Int']['output']>;
  time?: Maybe<Scalars['numeric']['output']>;
};

/** order by min() on columns of table "examsonline.timespent" */
export type Examsonline_Timespent_Min_Order_By = {
  attempt_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q_id?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "examsonline.timespent" */
export type Examsonline_Timespent_Mutation_Response = {
  __typename?: 'examsonline_timespent_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Examsonline_Timespent>;
};

/** on_conflict condition type for table "examsonline.timespent" */
export type Examsonline_Timespent_On_Conflict = {
  constraint: Examsonline_Timespent_Constraint;
  update_columns?: Array<Examsonline_Timespent_Update_Column>;
  where?: InputMaybe<Examsonline_Timespent_Bool_Exp>;
};

/** Ordering options when selecting data from "examsonline.timespent". */
export type Examsonline_Timespent_Order_By = {
  attempt?: InputMaybe<Examsonline_Attempt_Order_By>;
  attempt_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q_id?: InputMaybe<Order_By>;
  question?: InputMaybe<Examsonline_Questions_Order_By>;
  time?: InputMaybe<Order_By>;
};

/** primary key columns input for table: examsonline.timespent */
export type Examsonline_Timespent_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "examsonline.timespent" */
export enum Examsonline_Timespent_Select_Column {
  /** column name */
  AttemptId = 'attempt_id',
  /** column name */
  Id = 'id',
  /** column name */
  QId = 'q_id',
  /** column name */
  Time = 'time'
}

/** input type for updating data in table "examsonline.timespent" */
export type Examsonline_Timespent_Set_Input = {
  attempt_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  q_id?: InputMaybe<Scalars['Int']['input']>;
  time?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate stddev on columns */
export type Examsonline_Timespent_Stddev_Fields = {
  __typename?: 'examsonline_timespent_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  q_id?: Maybe<Scalars['Float']['output']>;
  time?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "examsonline.timespent" */
export type Examsonline_Timespent_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  q_id?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Examsonline_Timespent_Stddev_Pop_Fields = {
  __typename?: 'examsonline_timespent_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  q_id?: Maybe<Scalars['Float']['output']>;
  time?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "examsonline.timespent" */
export type Examsonline_Timespent_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  q_id?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Examsonline_Timespent_Stddev_Samp_Fields = {
  __typename?: 'examsonline_timespent_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  q_id?: Maybe<Scalars['Float']['output']>;
  time?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "examsonline.timespent" */
export type Examsonline_Timespent_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  q_id?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "examsonline_timespent" */
export type Examsonline_Timespent_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Examsonline_Timespent_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Examsonline_Timespent_Stream_Cursor_Value_Input = {
  attempt_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  q_id?: InputMaybe<Scalars['Int']['input']>;
  time?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type Examsonline_Timespent_Sum_Fields = {
  __typename?: 'examsonline_timespent_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  q_id?: Maybe<Scalars['Int']['output']>;
  time?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "examsonline.timespent" */
export type Examsonline_Timespent_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  q_id?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
};

/** update columns of table "examsonline.timespent" */
export enum Examsonline_Timespent_Update_Column {
  /** column name */
  AttemptId = 'attempt_id',
  /** column name */
  Id = 'id',
  /** column name */
  QId = 'q_id',
  /** column name */
  Time = 'time'
}

export type Examsonline_Timespent_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Examsonline_Timespent_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Examsonline_Timespent_Set_Input>;
  /** filter the rows which have to be updated */
  where: Examsonline_Timespent_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Examsonline_Timespent_Var_Pop_Fields = {
  __typename?: 'examsonline_timespent_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  q_id?: Maybe<Scalars['Float']['output']>;
  time?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "examsonline.timespent" */
export type Examsonline_Timespent_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  q_id?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Examsonline_Timespent_Var_Samp_Fields = {
  __typename?: 'examsonline_timespent_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  q_id?: Maybe<Scalars['Float']['output']>;
  time?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "examsonline.timespent" */
export type Examsonline_Timespent_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  q_id?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Examsonline_Timespent_Variance_Fields = {
  __typename?: 'examsonline_timespent_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  q_id?: Maybe<Scalars['Float']['output']>;
  time?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "examsonline.timespent" */
export type Examsonline_Timespent_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  q_id?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
};

/** columns and relationships of "examsonline.topics" */
export type Examsonline_Topics = {
  __typename?: 'examsonline_topics';
  /** An object relationship */
  contest?: Maybe<Examsonline_Contest>;
  contest_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  quiz?: Maybe<Examsonline_Quiz>;
  quiz_id?: Maybe<Scalars['uuid']['output']>;
  tag: Scalars['String']['output'];
  /** An object relationship */
  test?: Maybe<Examsonline_Tests>;
  test_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "examsonline.topics" */
export type Examsonline_Topics_Aggregate = {
  __typename?: 'examsonline_topics_aggregate';
  aggregate?: Maybe<Examsonline_Topics_Aggregate_Fields>;
  nodes: Array<Examsonline_Topics>;
};

export type Examsonline_Topics_Aggregate_Bool_Exp = {
  count?: InputMaybe<Examsonline_Topics_Aggregate_Bool_Exp_Count>;
};

export type Examsonline_Topics_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Examsonline_Topics_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_Topics_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "examsonline.topics" */
export type Examsonline_Topics_Aggregate_Fields = {
  __typename?: 'examsonline_topics_aggregate_fields';
  avg?: Maybe<Examsonline_Topics_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Examsonline_Topics_Max_Fields>;
  min?: Maybe<Examsonline_Topics_Min_Fields>;
  stddev?: Maybe<Examsonline_Topics_Stddev_Fields>;
  stddev_pop?: Maybe<Examsonline_Topics_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Examsonline_Topics_Stddev_Samp_Fields>;
  sum?: Maybe<Examsonline_Topics_Sum_Fields>;
  var_pop?: Maybe<Examsonline_Topics_Var_Pop_Fields>;
  var_samp?: Maybe<Examsonline_Topics_Var_Samp_Fields>;
  variance?: Maybe<Examsonline_Topics_Variance_Fields>;
};


/** aggregate fields of "examsonline.topics" */
export type Examsonline_Topics_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Examsonline_Topics_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "examsonline.topics" */
export type Examsonline_Topics_Aggregate_Order_By = {
  avg?: InputMaybe<Examsonline_Topics_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Examsonline_Topics_Max_Order_By>;
  min?: InputMaybe<Examsonline_Topics_Min_Order_By>;
  stddev?: InputMaybe<Examsonline_Topics_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Examsonline_Topics_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Examsonline_Topics_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Examsonline_Topics_Sum_Order_By>;
  var_pop?: InputMaybe<Examsonline_Topics_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Examsonline_Topics_Var_Samp_Order_By>;
  variance?: InputMaybe<Examsonline_Topics_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "examsonline.topics" */
export type Examsonline_Topics_Arr_Rel_Insert_Input = {
  data: Array<Examsonline_Topics_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Examsonline_Topics_On_Conflict>;
};

/** aggregate avg on columns */
export type Examsonline_Topics_Avg_Fields = {
  __typename?: 'examsonline_topics_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "examsonline.topics" */
export type Examsonline_Topics_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "examsonline.topics". All fields are combined with a logical 'AND'. */
export type Examsonline_Topics_Bool_Exp = {
  _and?: InputMaybe<Array<Examsonline_Topics_Bool_Exp>>;
  _not?: InputMaybe<Examsonline_Topics_Bool_Exp>;
  _or?: InputMaybe<Array<Examsonline_Topics_Bool_Exp>>;
  contest?: InputMaybe<Examsonline_Contest_Bool_Exp>;
  contest_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  quiz?: InputMaybe<Examsonline_Quiz_Bool_Exp>;
  quiz_id?: InputMaybe<Uuid_Comparison_Exp>;
  tag?: InputMaybe<String_Comparison_Exp>;
  test?: InputMaybe<Examsonline_Tests_Bool_Exp>;
  test_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "examsonline.topics" */
export enum Examsonline_Topics_Constraint {
  /** unique or primary key constraint on columns "id" */
  TopicsPkey = 'topics_pkey'
}

/** input type for incrementing numeric columns in table "examsonline.topics" */
export type Examsonline_Topics_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  test_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "examsonline.topics" */
export type Examsonline_Topics_Insert_Input = {
  contest?: InputMaybe<Examsonline_Contest_Obj_Rel_Insert_Input>;
  contest_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  quiz?: InputMaybe<Examsonline_Quiz_Obj_Rel_Insert_Input>;
  quiz_id?: InputMaybe<Scalars['uuid']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
  test?: InputMaybe<Examsonline_Tests_Obj_Rel_Insert_Input>;
  test_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Examsonline_Topics_Max_Fields = {
  __typename?: 'examsonline_topics_max_fields';
  contest_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  quiz_id?: Maybe<Scalars['uuid']['output']>;
  tag?: Maybe<Scalars['String']['output']>;
  test_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "examsonline.topics" */
export type Examsonline_Topics_Max_Order_By = {
  contest_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quiz_id?: InputMaybe<Order_By>;
  tag?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Examsonline_Topics_Min_Fields = {
  __typename?: 'examsonline_topics_min_fields';
  contest_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  quiz_id?: Maybe<Scalars['uuid']['output']>;
  tag?: Maybe<Scalars['String']['output']>;
  test_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "examsonline.topics" */
export type Examsonline_Topics_Min_Order_By = {
  contest_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quiz_id?: InputMaybe<Order_By>;
  tag?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "examsonline.topics" */
export type Examsonline_Topics_Mutation_Response = {
  __typename?: 'examsonline_topics_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Examsonline_Topics>;
};

/** on_conflict condition type for table "examsonline.topics" */
export type Examsonline_Topics_On_Conflict = {
  constraint: Examsonline_Topics_Constraint;
  update_columns?: Array<Examsonline_Topics_Update_Column>;
  where?: InputMaybe<Examsonline_Topics_Bool_Exp>;
};

/** Ordering options when selecting data from "examsonline.topics". */
export type Examsonline_Topics_Order_By = {
  contest?: InputMaybe<Examsonline_Contest_Order_By>;
  contest_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quiz?: InputMaybe<Examsonline_Quiz_Order_By>;
  quiz_id?: InputMaybe<Order_By>;
  tag?: InputMaybe<Order_By>;
  test?: InputMaybe<Examsonline_Tests_Order_By>;
  test_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: examsonline.topics */
export type Examsonline_Topics_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "examsonline.topics" */
export enum Examsonline_Topics_Select_Column {
  /** column name */
  ContestId = 'contest_id',
  /** column name */
  Id = 'id',
  /** column name */
  QuizId = 'quiz_id',
  /** column name */
  Tag = 'tag',
  /** column name */
  TestId = 'test_id'
}

/** input type for updating data in table "examsonline.topics" */
export type Examsonline_Topics_Set_Input = {
  contest_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  quiz_id?: InputMaybe<Scalars['uuid']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
  test_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Examsonline_Topics_Stddev_Fields = {
  __typename?: 'examsonline_topics_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "examsonline.topics" */
export type Examsonline_Topics_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Examsonline_Topics_Stddev_Pop_Fields = {
  __typename?: 'examsonline_topics_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "examsonline.topics" */
export type Examsonline_Topics_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Examsonline_Topics_Stddev_Samp_Fields = {
  __typename?: 'examsonline_topics_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "examsonline.topics" */
export type Examsonline_Topics_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "examsonline_topics" */
export type Examsonline_Topics_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Examsonline_Topics_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Examsonline_Topics_Stream_Cursor_Value_Input = {
  contest_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  quiz_id?: InputMaybe<Scalars['uuid']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
  test_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Examsonline_Topics_Sum_Fields = {
  __typename?: 'examsonline_topics_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  test_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "examsonline.topics" */
export type Examsonline_Topics_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
};

/** update columns of table "examsonline.topics" */
export enum Examsonline_Topics_Update_Column {
  /** column name */
  ContestId = 'contest_id',
  /** column name */
  Id = 'id',
  /** column name */
  QuizId = 'quiz_id',
  /** column name */
  Tag = 'tag',
  /** column name */
  TestId = 'test_id'
}

export type Examsonline_Topics_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Examsonline_Topics_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Examsonline_Topics_Set_Input>;
  /** filter the rows which have to be updated */
  where: Examsonline_Topics_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Examsonline_Topics_Var_Pop_Fields = {
  __typename?: 'examsonline_topics_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "examsonline.topics" */
export type Examsonline_Topics_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Examsonline_Topics_Var_Samp_Fields = {
  __typename?: 'examsonline_topics_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "examsonline.topics" */
export type Examsonline_Topics_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Examsonline_Topics_Variance_Fields = {
  __typename?: 'examsonline_topics_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  test_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "examsonline.topics" */
export type Examsonline_Topics_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
};

/** table for user */
export type Examsonline_User = {
  __typename?: 'examsonline_user';
  acceptTerms: Scalars['Boolean']['output'];
  activated: Scalars['Boolean']['output'];
  activationToken?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  comments: Array<Examsonline_Comments>;
  /** An aggregate relationship */
  comments_aggregate: Examsonline_Comments_Aggregate;
  /** An array relationship */
  contests: Array<Examsonline_Contest>;
  /** An aggregate relationship */
  contests_aggregate: Examsonline_Contest_Aggregate;
  createdAt: Scalars['timestamptz']['output'];
  email: Scalars['String']['output'];
  facebook: Scalars['Boolean']['output'];
  /** An array relationship */
  files: Array<Examsonline_File>;
  /** An aggregate relationship */
  files_aggregate: Examsonline_File_Aggregate;
  google?: Maybe<Scalars['Boolean']['output']>;
  /** An array relationship */
  groups: Array<Examsonline_Groups>;
  /** An aggregate relationship */
  groups_aggregate: Examsonline_Groups_Aggregate;
  id?: Maybe<Scalars['uuid']['output']>;
  org: Scalars['uuid']['output'];
  /** An object relationship */
  organisation: Examsonline_Organisation;
  password: Scalars['String']['output'];
  /** An array relationship */
  quizzes: Array<Examsonline_Quiz>;
  /** An aggregate relationship */
  quizzes_aggregate: Examsonline_Quiz_Aggregate;
  role: Scalars['String']['output'];
  /** An array relationship */
  roles: Array<Examsonline_Roles>;
  /** An aggregate relationship */
  roles_aggregate: Examsonline_Roles_Aggregate;
  /** An array relationship */
  tickets: Array<Examsonline_Tickets>;
  /** An aggregate relationship */
  tickets_aggregate: Examsonline_Tickets_Aggregate;
  tourCompleted?: Maybe<Scalars['Boolean']['output']>;
  types?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  username: Scalars['String']['output'];
};


/** table for user */
export type Examsonline_UserCommentsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Comments_Order_By>>;
  where?: InputMaybe<Examsonline_Comments_Bool_Exp>;
};


/** table for user */
export type Examsonline_UserComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Comments_Order_By>>;
  where?: InputMaybe<Examsonline_Comments_Bool_Exp>;
};


/** table for user */
export type Examsonline_UserContestsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Contest_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Contest_Order_By>>;
  where?: InputMaybe<Examsonline_Contest_Bool_Exp>;
};


/** table for user */
export type Examsonline_UserContests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Contest_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Contest_Order_By>>;
  where?: InputMaybe<Examsonline_Contest_Bool_Exp>;
};


/** table for user */
export type Examsonline_UserFilesArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_File_Order_By>>;
  where?: InputMaybe<Examsonline_File_Bool_Exp>;
};


/** table for user */
export type Examsonline_UserFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_File_Order_By>>;
  where?: InputMaybe<Examsonline_File_Bool_Exp>;
};


/** table for user */
export type Examsonline_UserGroupsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Groups_Order_By>>;
  where?: InputMaybe<Examsonline_Groups_Bool_Exp>;
};


/** table for user */
export type Examsonline_UserGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Groups_Order_By>>;
  where?: InputMaybe<Examsonline_Groups_Bool_Exp>;
};


/** table for user */
export type Examsonline_UserQuizzesArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Quiz_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Quiz_Order_By>>;
  where?: InputMaybe<Examsonline_Quiz_Bool_Exp>;
};


/** table for user */
export type Examsonline_UserQuizzes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Quiz_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Quiz_Order_By>>;
  where?: InputMaybe<Examsonline_Quiz_Bool_Exp>;
};


/** table for user */
export type Examsonline_UserRolesArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Roles_Order_By>>;
  where?: InputMaybe<Examsonline_Roles_Bool_Exp>;
};


/** table for user */
export type Examsonline_UserRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Roles_Order_By>>;
  where?: InputMaybe<Examsonline_Roles_Bool_Exp>;
};


/** table for user */
export type Examsonline_UserTicketsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Tickets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Tickets_Order_By>>;
  where?: InputMaybe<Examsonline_Tickets_Bool_Exp>;
};


/** table for user */
export type Examsonline_UserTickets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Tickets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Tickets_Order_By>>;
  where?: InputMaybe<Examsonline_Tickets_Bool_Exp>;
};

/** aggregated selection of "examsonline.user" */
export type Examsonline_User_Aggregate = {
  __typename?: 'examsonline_user_aggregate';
  aggregate?: Maybe<Examsonline_User_Aggregate_Fields>;
  nodes: Array<Examsonline_User>;
};

export type Examsonline_User_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Examsonline_User_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Examsonline_User_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Examsonline_User_Aggregate_Bool_Exp_Count>;
};

export type Examsonline_User_Aggregate_Bool_Exp_Bool_And = {
  arguments: Examsonline_User_Select_Column_Examsonline_User_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_User_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Examsonline_User_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Examsonline_User_Select_Column_Examsonline_User_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_User_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Examsonline_User_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Examsonline_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Examsonline_User_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "examsonline.user" */
export type Examsonline_User_Aggregate_Fields = {
  __typename?: 'examsonline_user_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Examsonline_User_Max_Fields>;
  min?: Maybe<Examsonline_User_Min_Fields>;
};


/** aggregate fields of "examsonline.user" */
export type Examsonline_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Examsonline_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "examsonline.user" */
export type Examsonline_User_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Examsonline_User_Max_Order_By>;
  min?: InputMaybe<Examsonline_User_Min_Order_By>;
};

/** input type for inserting array relation for remote table "examsonline.user" */
export type Examsonline_User_Arr_Rel_Insert_Input = {
  data: Array<Examsonline_User_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Examsonline_User_On_Conflict>;
};

/** Boolean expression to filter rows from the table "examsonline.user". All fields are combined with a logical 'AND'. */
export type Examsonline_User_Bool_Exp = {
  _and?: InputMaybe<Array<Examsonline_User_Bool_Exp>>;
  _not?: InputMaybe<Examsonline_User_Bool_Exp>;
  _or?: InputMaybe<Array<Examsonline_User_Bool_Exp>>;
  acceptTerms?: InputMaybe<Boolean_Comparison_Exp>;
  activated?: InputMaybe<Boolean_Comparison_Exp>;
  activationToken?: InputMaybe<String_Comparison_Exp>;
  comments?: InputMaybe<Examsonline_Comments_Bool_Exp>;
  comments_aggregate?: InputMaybe<Examsonline_Comments_Aggregate_Bool_Exp>;
  contests?: InputMaybe<Examsonline_Contest_Bool_Exp>;
  contests_aggregate?: InputMaybe<Examsonline_Contest_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  facebook?: InputMaybe<Boolean_Comparison_Exp>;
  files?: InputMaybe<Examsonline_File_Bool_Exp>;
  files_aggregate?: InputMaybe<Examsonline_File_Aggregate_Bool_Exp>;
  google?: InputMaybe<Boolean_Comparison_Exp>;
  groups?: InputMaybe<Examsonline_Groups_Bool_Exp>;
  groups_aggregate?: InputMaybe<Examsonline_Groups_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  org?: InputMaybe<Uuid_Comparison_Exp>;
  organisation?: InputMaybe<Examsonline_Organisation_Bool_Exp>;
  password?: InputMaybe<String_Comparison_Exp>;
  quizzes?: InputMaybe<Examsonline_Quiz_Bool_Exp>;
  quizzes_aggregate?: InputMaybe<Examsonline_Quiz_Aggregate_Bool_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  roles?: InputMaybe<Examsonline_Roles_Bool_Exp>;
  roles_aggregate?: InputMaybe<Examsonline_Roles_Aggregate_Bool_Exp>;
  tickets?: InputMaybe<Examsonline_Tickets_Bool_Exp>;
  tickets_aggregate?: InputMaybe<Examsonline_Tickets_Aggregate_Bool_Exp>;
  tourCompleted?: InputMaybe<Boolean_Comparison_Exp>;
  types?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "examsonline.user" */
export enum Examsonline_User_Constraint {
  /** unique or primary key constraint on columns "email" */
  UserPkey = 'user_pkey'
}

/** input type for inserting data into table "examsonline.user" */
export type Examsonline_User_Insert_Input = {
  acceptTerms?: InputMaybe<Scalars['Boolean']['input']>;
  activated?: InputMaybe<Scalars['Boolean']['input']>;
  activationToken?: InputMaybe<Scalars['String']['input']>;
  comments?: InputMaybe<Examsonline_Comments_Arr_Rel_Insert_Input>;
  contests?: InputMaybe<Examsonline_Contest_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  facebook?: InputMaybe<Scalars['Boolean']['input']>;
  files?: InputMaybe<Examsonline_File_Arr_Rel_Insert_Input>;
  google?: InputMaybe<Scalars['Boolean']['input']>;
  groups?: InputMaybe<Examsonline_Groups_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  org?: InputMaybe<Scalars['uuid']['input']>;
  organisation?: InputMaybe<Examsonline_Organisation_Obj_Rel_Insert_Input>;
  password?: InputMaybe<Scalars['String']['input']>;
  quizzes?: InputMaybe<Examsonline_Quiz_Arr_Rel_Insert_Input>;
  role?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Examsonline_Roles_Arr_Rel_Insert_Input>;
  tickets?: InputMaybe<Examsonline_Tickets_Arr_Rel_Insert_Input>;
  tourCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  types?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Examsonline_User_Max_Fields = {
  __typename?: 'examsonline_user_max_fields';
  activationToken?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  org?: Maybe<Scalars['uuid']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  types?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "examsonline.user" */
export type Examsonline_User_Max_Order_By = {
  activationToken?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  org?: InputMaybe<Order_By>;
  password?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  types?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Examsonline_User_Min_Fields = {
  __typename?: 'examsonline_user_min_fields';
  activationToken?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  org?: Maybe<Scalars['uuid']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  types?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "examsonline.user" */
export type Examsonline_User_Min_Order_By = {
  activationToken?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  org?: InputMaybe<Order_By>;
  password?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  types?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "examsonline.user" */
export type Examsonline_User_Mutation_Response = {
  __typename?: 'examsonline_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Examsonline_User>;
};

/** input type for inserting object relation for remote table "examsonline.user" */
export type Examsonline_User_Obj_Rel_Insert_Input = {
  data: Examsonline_User_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Examsonline_User_On_Conflict>;
};

/** on_conflict condition type for table "examsonline.user" */
export type Examsonline_User_On_Conflict = {
  constraint: Examsonline_User_Constraint;
  update_columns?: Array<Examsonline_User_Update_Column>;
  where?: InputMaybe<Examsonline_User_Bool_Exp>;
};

/** Ordering options when selecting data from "examsonline.user". */
export type Examsonline_User_Order_By = {
  acceptTerms?: InputMaybe<Order_By>;
  activated?: InputMaybe<Order_By>;
  activationToken?: InputMaybe<Order_By>;
  comments_aggregate?: InputMaybe<Examsonline_Comments_Aggregate_Order_By>;
  contests_aggregate?: InputMaybe<Examsonline_Contest_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  facebook?: InputMaybe<Order_By>;
  files_aggregate?: InputMaybe<Examsonline_File_Aggregate_Order_By>;
  google?: InputMaybe<Order_By>;
  groups_aggregate?: InputMaybe<Examsonline_Groups_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  org?: InputMaybe<Order_By>;
  organisation?: InputMaybe<Examsonline_Organisation_Order_By>;
  password?: InputMaybe<Order_By>;
  quizzes_aggregate?: InputMaybe<Examsonline_Quiz_Aggregate_Order_By>;
  role?: InputMaybe<Order_By>;
  roles_aggregate?: InputMaybe<Examsonline_Roles_Aggregate_Order_By>;
  tickets_aggregate?: InputMaybe<Examsonline_Tickets_Aggregate_Order_By>;
  tourCompleted?: InputMaybe<Order_By>;
  types?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** primary key columns input for table: examsonline.user */
export type Examsonline_User_Pk_Columns_Input = {
  email: Scalars['String']['input'];
};

/** select columns of table "examsonline.user" */
export enum Examsonline_User_Select_Column {
  /** column name */
  AcceptTerms = 'acceptTerms',
  /** column name */
  Activated = 'activated',
  /** column name */
  ActivationToken = 'activationToken',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Facebook = 'facebook',
  /** column name */
  Google = 'google',
  /** column name */
  Id = 'id',
  /** column name */
  Org = 'org',
  /** column name */
  Password = 'password',
  /** column name */
  Role = 'role',
  /** column name */
  TourCompleted = 'tourCompleted',
  /** column name */
  Types = 'types',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Username = 'username'
}

/** select "examsonline_user_aggregate_bool_exp_bool_and_arguments_columns" columns of table "examsonline.user" */
export enum Examsonline_User_Select_Column_Examsonline_User_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  AcceptTerms = 'acceptTerms',
  /** column name */
  Activated = 'activated',
  /** column name */
  Facebook = 'facebook',
  /** column name */
  Google = 'google',
  /** column name */
  TourCompleted = 'tourCompleted'
}

/** select "examsonline_user_aggregate_bool_exp_bool_or_arguments_columns" columns of table "examsonline.user" */
export enum Examsonline_User_Select_Column_Examsonline_User_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  AcceptTerms = 'acceptTerms',
  /** column name */
  Activated = 'activated',
  /** column name */
  Facebook = 'facebook',
  /** column name */
  Google = 'google',
  /** column name */
  TourCompleted = 'tourCompleted'
}

/** input type for updating data in table "examsonline.user" */
export type Examsonline_User_Set_Input = {
  acceptTerms?: InputMaybe<Scalars['Boolean']['input']>;
  activated?: InputMaybe<Scalars['Boolean']['input']>;
  activationToken?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  facebook?: InputMaybe<Scalars['Boolean']['input']>;
  google?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  org?: InputMaybe<Scalars['uuid']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  tourCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  types?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "examsonline_user" */
export type Examsonline_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Examsonline_User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Examsonline_User_Stream_Cursor_Value_Input = {
  acceptTerms?: InputMaybe<Scalars['Boolean']['input']>;
  activated?: InputMaybe<Scalars['Boolean']['input']>;
  activationToken?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  facebook?: InputMaybe<Scalars['Boolean']['input']>;
  google?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  org?: InputMaybe<Scalars['uuid']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  tourCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  types?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "examsonline.user" */
export enum Examsonline_User_Update_Column {
  /** column name */
  AcceptTerms = 'acceptTerms',
  /** column name */
  Activated = 'activated',
  /** column name */
  ActivationToken = 'activationToken',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Facebook = 'facebook',
  /** column name */
  Google = 'google',
  /** column name */
  Id = 'id',
  /** column name */
  Org = 'org',
  /** column name */
  Password = 'password',
  /** column name */
  Role = 'role',
  /** column name */
  TourCompleted = 'tourCompleted',
  /** column name */
  Types = 'types',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Username = 'username'
}

export type Examsonline_User_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Examsonline_User_Set_Input>;
  /** filter the rows which have to be updated */
  where: Examsonline_User_Bool_Exp;
};

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['json']['input']>;
  _gt?: InputMaybe<Scalars['json']['input']>;
  _gte?: InputMaybe<Scalars['json']['input']>;
  _in?: InputMaybe<Array<Scalars['json']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['json']['input']>;
  _lte?: InputMaybe<Scalars['json']['input']>;
  _neq?: InputMaybe<Scalars['json']['input']>;
  _nin?: InputMaybe<Array<Scalars['json']['input']>>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "_enumtable.country" */
  delete__enumtable_country?: Maybe<_Enumtable_Country_Mutation_Response>;
  /** delete single row from the table: "_enumtable.country" */
  delete__enumtable_country_by_pk?: Maybe<_Enumtable_Country>;
  /** delete data from the table: "_enumtable.priority" */
  delete__enumtable_priority?: Maybe<_Enumtable_Priority_Mutation_Response>;
  /** delete single row from the table: "_enumtable.priority" */
  delete__enumtable_priority_by_pk?: Maybe<_Enumtable_Priority>;
  /** delete data from the table: "_enumtable.role" */
  delete__enumtable_role?: Maybe<_Enumtable_Role_Mutation_Response>;
  /** delete single row from the table: "_enumtable.role" */
  delete__enumtable_role_by_pk?: Maybe<_Enumtable_Role>;
  /** delete data from the table: "_enumtable.ticket_status" */
  delete__enumtable_ticket_status?: Maybe<_Enumtable_Ticket_Status_Mutation_Response>;
  /** delete single row from the table: "_enumtable.ticket_status" */
  delete__enumtable_ticket_status_by_pk?: Maybe<_Enumtable_Ticket_Status>;
  /** delete data from the table: "examsonline.answers" */
  delete_examsonline_answers?: Maybe<Examsonline_Answers_Mutation_Response>;
  /** delete single row from the table: "examsonline.answers" */
  delete_examsonline_answers_by_pk?: Maybe<Examsonline_Answers>;
  /** delete data from the table: "examsonline.assignments" */
  delete_examsonline_assignments?: Maybe<Examsonline_Assignments_Mutation_Response>;
  /** delete single row from the table: "examsonline.assignments" */
  delete_examsonline_assignments_by_pk?: Maybe<Examsonline_Assignments>;
  /** delete data from the table: "examsonline.attempt" */
  delete_examsonline_attempt?: Maybe<Examsonline_Attempt_Mutation_Response>;
  /** delete single row from the table: "examsonline.attempt" */
  delete_examsonline_attempt_by_pk?: Maybe<Examsonline_Attempt>;
  /** delete data from the table: "examsonline.banner_notification" */
  delete_examsonline_banner_notification?: Maybe<Examsonline_Banner_Notification_Mutation_Response>;
  /** delete single row from the table: "examsonline.banner_notification" */
  delete_examsonline_banner_notification_by_pk?: Maybe<Examsonline_Banner_Notification>;
  /** delete data from the table: "examsonline.bill" */
  delete_examsonline_bill?: Maybe<Examsonline_Bill_Mutation_Response>;
  /** delete single row from the table: "examsonline.bill" */
  delete_examsonline_bill_by_pk?: Maybe<Examsonline_Bill>;
  /** delete data from the table: "examsonline.comments" */
  delete_examsonline_comments?: Maybe<Examsonline_Comments_Mutation_Response>;
  /** delete single row from the table: "examsonline.comments" */
  delete_examsonline_comments_by_pk?: Maybe<Examsonline_Comments>;
  /** delete data from the table: "examsonline.contactus" */
  delete_examsonline_contactus?: Maybe<Examsonline_Contactus_Mutation_Response>;
  /** delete single row from the table: "examsonline.contactus" */
  delete_examsonline_contactus_by_pk?: Maybe<Examsonline_Contactus>;
  /** delete data from the table: "examsonline.contest" */
  delete_examsonline_contest?: Maybe<Examsonline_Contest_Mutation_Response>;
  /** delete single row from the table: "examsonline.contest" */
  delete_examsonline_contest_by_pk?: Maybe<Examsonline_Contest>;
  /** delete data from the table: "examsonline.file" */
  delete_examsonline_file?: Maybe<Examsonline_File_Mutation_Response>;
  /** delete single row from the table: "examsonline.file" */
  delete_examsonline_file_by_pk?: Maybe<Examsonline_File>;
  /** delete data from the table: "examsonline.groups" */
  delete_examsonline_groups?: Maybe<Examsonline_Groups_Mutation_Response>;
  /** delete single row from the table: "examsonline.groups" */
  delete_examsonline_groups_by_pk?: Maybe<Examsonline_Groups>;
  /** delete data from the table: "examsonline.members" */
  delete_examsonline_members?: Maybe<Examsonline_Members_Mutation_Response>;
  /** delete single row from the table: "examsonline.members" */
  delete_examsonline_members_by_pk?: Maybe<Examsonline_Members>;
  /** delete data from the table: "examsonline.options" */
  delete_examsonline_options?: Maybe<Examsonline_Options_Mutation_Response>;
  /** delete single row from the table: "examsonline.options" */
  delete_examsonline_options_by_pk?: Maybe<Examsonline_Options>;
  /** delete data from the table: "examsonline.order" */
  delete_examsonline_order?: Maybe<Examsonline_Order_Mutation_Response>;
  /** delete single row from the table: "examsonline.order" */
  delete_examsonline_order_by_pk?: Maybe<Examsonline_Order>;
  /** delete data from the table: "examsonline.organisation" */
  delete_examsonline_organisation?: Maybe<Examsonline_Organisation_Mutation_Response>;
  /** delete single row from the table: "examsonline.organisation" */
  delete_examsonline_organisation_by_pk?: Maybe<Examsonline_Organisation>;
  /** delete data from the table: "examsonline.participate" */
  delete_examsonline_participate?: Maybe<Examsonline_Participate_Mutation_Response>;
  /** delete single row from the table: "examsonline.participate" */
  delete_examsonline_participate_by_pk?: Maybe<Examsonline_Participate>;
  /** delete data from the table: "examsonline.payment_details" */
  delete_examsonline_payment_details?: Maybe<Examsonline_Payment_Details_Mutation_Response>;
  /** delete single row from the table: "examsonline.payment_details" */
  delete_examsonline_payment_details_by_pk?: Maybe<Examsonline_Payment_Details>;
  /** delete data from the table: "examsonline.plan" */
  delete_examsonline_plan?: Maybe<Examsonline_Plan_Mutation_Response>;
  /** delete single row from the table: "examsonline.plan" */
  delete_examsonline_plan_by_pk?: Maybe<Examsonline_Plan>;
  /** delete data from the table: "examsonline.questions" */
  delete_examsonline_questions?: Maybe<Examsonline_Questions_Mutation_Response>;
  /** delete single row from the table: "examsonline.questions" */
  delete_examsonline_questions_by_pk?: Maybe<Examsonline_Questions>;
  /** delete data from the table: "examsonline.quiz" */
  delete_examsonline_quiz?: Maybe<Examsonline_Quiz_Mutation_Response>;
  /** delete single row from the table: "examsonline.quiz" */
  delete_examsonline_quiz_by_pk?: Maybe<Examsonline_Quiz>;
  /** delete data from the table: "examsonline.rating" */
  delete_examsonline_rating?: Maybe<Examsonline_Rating_Mutation_Response>;
  /** delete single row from the table: "examsonline.rating" */
  delete_examsonline_rating_by_pk?: Maybe<Examsonline_Rating>;
  /** delete data from the table: "examsonline.roles" */
  delete_examsonline_roles?: Maybe<Examsonline_Roles_Mutation_Response>;
  /** delete single row from the table: "examsonline.roles" */
  delete_examsonline_roles_by_pk?: Maybe<Examsonline_Roles>;
  /** delete data from the table: "examsonline.selected_options" */
  delete_examsonline_selected_options?: Maybe<Examsonline_Selected_Options_Mutation_Response>;
  /** delete single row from the table: "examsonline.selected_options" */
  delete_examsonline_selected_options_by_pk?: Maybe<Examsonline_Selected_Options>;
  /** delete data from the table: "examsonline.session" */
  delete_examsonline_session?: Maybe<Examsonline_Session_Mutation_Response>;
  /** delete single row from the table: "examsonline.session" */
  delete_examsonline_session_by_pk?: Maybe<Examsonline_Session>;
  /** delete data from the table: "examsonline.sessionrecord" */
  delete_examsonline_sessionrecord?: Maybe<Examsonline_Sessionrecord_Mutation_Response>;
  /** delete single row from the table: "examsonline.sessionrecord" */
  delete_examsonline_sessionrecord_by_pk?: Maybe<Examsonline_Sessionrecord>;
  /** delete data from the table: "examsonline.sessions" */
  delete_examsonline_sessions?: Maybe<Examsonline_Sessions_Mutation_Response>;
  /** delete single row from the table: "examsonline.sessions" */
  delete_examsonline_sessions_by_pk?: Maybe<Examsonline_Sessions>;
  /** delete data from the table: "examsonline.test_sections" */
  delete_examsonline_test_sections?: Maybe<Examsonline_Test_Sections_Mutation_Response>;
  /** delete single row from the table: "examsonline.test_sections" */
  delete_examsonline_test_sections_by_pk?: Maybe<Examsonline_Test_Sections>;
  /** delete data from the table: "examsonline.tests" */
  delete_examsonline_tests?: Maybe<Examsonline_Tests_Mutation_Response>;
  /** delete single row from the table: "examsonline.tests" */
  delete_examsonline_tests_by_pk?: Maybe<Examsonline_Tests>;
  /** delete data from the table: "examsonline.tickets" */
  delete_examsonline_tickets?: Maybe<Examsonline_Tickets_Mutation_Response>;
  /** delete single row from the table: "examsonline.tickets" */
  delete_examsonline_tickets_by_pk?: Maybe<Examsonline_Tickets>;
  /** delete data from the table: "examsonline.timespent" */
  delete_examsonline_timespent?: Maybe<Examsonline_Timespent_Mutation_Response>;
  /** delete single row from the table: "examsonline.timespent" */
  delete_examsonline_timespent_by_pk?: Maybe<Examsonline_Timespent>;
  /** delete data from the table: "examsonline.topics" */
  delete_examsonline_topics?: Maybe<Examsonline_Topics_Mutation_Response>;
  /** delete single row from the table: "examsonline.topics" */
  delete_examsonline_topics_by_pk?: Maybe<Examsonline_Topics>;
  /** delete data from the table: "examsonline.user" */
  delete_examsonline_user?: Maybe<Examsonline_User_Mutation_Response>;
  /** delete single row from the table: "examsonline.user" */
  delete_examsonline_user_by_pk?: Maybe<Examsonline_User>;
  /** insert data into the table: "_enumtable.country" */
  insert__enumtable_country?: Maybe<_Enumtable_Country_Mutation_Response>;
  /** insert a single row into the table: "_enumtable.country" */
  insert__enumtable_country_one?: Maybe<_Enumtable_Country>;
  /** insert data into the table: "_enumtable.priority" */
  insert__enumtable_priority?: Maybe<_Enumtable_Priority_Mutation_Response>;
  /** insert a single row into the table: "_enumtable.priority" */
  insert__enumtable_priority_one?: Maybe<_Enumtable_Priority>;
  /** insert data into the table: "_enumtable.role" */
  insert__enumtable_role?: Maybe<_Enumtable_Role_Mutation_Response>;
  /** insert a single row into the table: "_enumtable.role" */
  insert__enumtable_role_one?: Maybe<_Enumtable_Role>;
  /** insert data into the table: "_enumtable.ticket_status" */
  insert__enumtable_ticket_status?: Maybe<_Enumtable_Ticket_Status_Mutation_Response>;
  /** insert a single row into the table: "_enumtable.ticket_status" */
  insert__enumtable_ticket_status_one?: Maybe<_Enumtable_Ticket_Status>;
  /** insert data into the table: "examsonline.answers" */
  insert_examsonline_answers?: Maybe<Examsonline_Answers_Mutation_Response>;
  /** insert a single row into the table: "examsonline.answers" */
  insert_examsonline_answers_one?: Maybe<Examsonline_Answers>;
  /** insert data into the table: "examsonline.assignments" */
  insert_examsonline_assignments?: Maybe<Examsonline_Assignments_Mutation_Response>;
  /** insert a single row into the table: "examsonline.assignments" */
  insert_examsonline_assignments_one?: Maybe<Examsonline_Assignments>;
  /** insert data into the table: "examsonline.attempt" */
  insert_examsonline_attempt?: Maybe<Examsonline_Attempt_Mutation_Response>;
  /** insert a single row into the table: "examsonline.attempt" */
  insert_examsonline_attempt_one?: Maybe<Examsonline_Attempt>;
  /** insert data into the table: "examsonline.banner_notification" */
  insert_examsonline_banner_notification?: Maybe<Examsonline_Banner_Notification_Mutation_Response>;
  /** insert a single row into the table: "examsonline.banner_notification" */
  insert_examsonline_banner_notification_one?: Maybe<Examsonline_Banner_Notification>;
  /** insert data into the table: "examsonline.bill" */
  insert_examsonline_bill?: Maybe<Examsonline_Bill_Mutation_Response>;
  /** insert a single row into the table: "examsonline.bill" */
  insert_examsonline_bill_one?: Maybe<Examsonline_Bill>;
  /** insert data into the table: "examsonline.comments" */
  insert_examsonline_comments?: Maybe<Examsonline_Comments_Mutation_Response>;
  /** insert a single row into the table: "examsonline.comments" */
  insert_examsonline_comments_one?: Maybe<Examsonline_Comments>;
  /** insert data into the table: "examsonline.contactus" */
  insert_examsonline_contactus?: Maybe<Examsonline_Contactus_Mutation_Response>;
  /** insert a single row into the table: "examsonline.contactus" */
  insert_examsonline_contactus_one?: Maybe<Examsonline_Contactus>;
  /** insert data into the table: "examsonline.contest" */
  insert_examsonline_contest?: Maybe<Examsonline_Contest_Mutation_Response>;
  /** insert a single row into the table: "examsonline.contest" */
  insert_examsonline_contest_one?: Maybe<Examsonline_Contest>;
  /** insert data into the table: "examsonline.file" */
  insert_examsonline_file?: Maybe<Examsonline_File_Mutation_Response>;
  /** insert a single row into the table: "examsonline.file" */
  insert_examsonline_file_one?: Maybe<Examsonline_File>;
  /** insert data into the table: "examsonline.groups" */
  insert_examsonline_groups?: Maybe<Examsonline_Groups_Mutation_Response>;
  /** insert a single row into the table: "examsonline.groups" */
  insert_examsonline_groups_one?: Maybe<Examsonline_Groups>;
  /** insert data into the table: "examsonline.members" */
  insert_examsonline_members?: Maybe<Examsonline_Members_Mutation_Response>;
  /** insert a single row into the table: "examsonline.members" */
  insert_examsonline_members_one?: Maybe<Examsonline_Members>;
  /** insert data into the table: "examsonline.options" */
  insert_examsonline_options?: Maybe<Examsonline_Options_Mutation_Response>;
  /** insert a single row into the table: "examsonline.options" */
  insert_examsonline_options_one?: Maybe<Examsonline_Options>;
  /** insert data into the table: "examsonline.order" */
  insert_examsonline_order?: Maybe<Examsonline_Order_Mutation_Response>;
  /** insert a single row into the table: "examsonline.order" */
  insert_examsonline_order_one?: Maybe<Examsonline_Order>;
  /** insert data into the table: "examsonline.organisation" */
  insert_examsonline_organisation?: Maybe<Examsonline_Organisation_Mutation_Response>;
  /** insert a single row into the table: "examsonline.organisation" */
  insert_examsonline_organisation_one?: Maybe<Examsonline_Organisation>;
  /** insert data into the table: "examsonline.participate" */
  insert_examsonline_participate?: Maybe<Examsonline_Participate_Mutation_Response>;
  /** insert a single row into the table: "examsonline.participate" */
  insert_examsonline_participate_one?: Maybe<Examsonline_Participate>;
  /** insert data into the table: "examsonline.payment_details" */
  insert_examsonline_payment_details?: Maybe<Examsonline_Payment_Details_Mutation_Response>;
  /** insert a single row into the table: "examsonline.payment_details" */
  insert_examsonline_payment_details_one?: Maybe<Examsonline_Payment_Details>;
  /** insert data into the table: "examsonline.plan" */
  insert_examsonline_plan?: Maybe<Examsonline_Plan_Mutation_Response>;
  /** insert a single row into the table: "examsonline.plan" */
  insert_examsonline_plan_one?: Maybe<Examsonline_Plan>;
  /** insert data into the table: "examsonline.questions" */
  insert_examsonline_questions?: Maybe<Examsonline_Questions_Mutation_Response>;
  /** insert a single row into the table: "examsonline.questions" */
  insert_examsonline_questions_one?: Maybe<Examsonline_Questions>;
  /** insert data into the table: "examsonline.quiz" */
  insert_examsonline_quiz?: Maybe<Examsonline_Quiz_Mutation_Response>;
  /** insert a single row into the table: "examsonline.quiz" */
  insert_examsonline_quiz_one?: Maybe<Examsonline_Quiz>;
  /** insert data into the table: "examsonline.rating" */
  insert_examsonline_rating?: Maybe<Examsonline_Rating_Mutation_Response>;
  /** insert a single row into the table: "examsonline.rating" */
  insert_examsonline_rating_one?: Maybe<Examsonline_Rating>;
  /** insert data into the table: "examsonline.roles" */
  insert_examsonline_roles?: Maybe<Examsonline_Roles_Mutation_Response>;
  /** insert a single row into the table: "examsonline.roles" */
  insert_examsonline_roles_one?: Maybe<Examsonline_Roles>;
  /** insert data into the table: "examsonline.selected_options" */
  insert_examsonline_selected_options?: Maybe<Examsonline_Selected_Options_Mutation_Response>;
  /** insert a single row into the table: "examsonline.selected_options" */
  insert_examsonline_selected_options_one?: Maybe<Examsonline_Selected_Options>;
  /** insert data into the table: "examsonline.session" */
  insert_examsonline_session?: Maybe<Examsonline_Session_Mutation_Response>;
  /** insert a single row into the table: "examsonline.session" */
  insert_examsonline_session_one?: Maybe<Examsonline_Session>;
  /** insert data into the table: "examsonline.sessionrecord" */
  insert_examsonline_sessionrecord?: Maybe<Examsonline_Sessionrecord_Mutation_Response>;
  /** insert a single row into the table: "examsonline.sessionrecord" */
  insert_examsonline_sessionrecord_one?: Maybe<Examsonline_Sessionrecord>;
  /** insert data into the table: "examsonline.sessions" */
  insert_examsonline_sessions?: Maybe<Examsonline_Sessions_Mutation_Response>;
  /** insert a single row into the table: "examsonline.sessions" */
  insert_examsonline_sessions_one?: Maybe<Examsonline_Sessions>;
  /** insert data into the table: "examsonline.test_sections" */
  insert_examsonline_test_sections?: Maybe<Examsonline_Test_Sections_Mutation_Response>;
  /** insert a single row into the table: "examsonline.test_sections" */
  insert_examsonline_test_sections_one?: Maybe<Examsonline_Test_Sections>;
  /** insert data into the table: "examsonline.tests" */
  insert_examsonline_tests?: Maybe<Examsonline_Tests_Mutation_Response>;
  /** insert a single row into the table: "examsonline.tests" */
  insert_examsonline_tests_one?: Maybe<Examsonline_Tests>;
  /** insert data into the table: "examsonline.tickets" */
  insert_examsonline_tickets?: Maybe<Examsonline_Tickets_Mutation_Response>;
  /** insert a single row into the table: "examsonline.tickets" */
  insert_examsonline_tickets_one?: Maybe<Examsonline_Tickets>;
  /** insert data into the table: "examsonline.timespent" */
  insert_examsonline_timespent?: Maybe<Examsonline_Timespent_Mutation_Response>;
  /** insert a single row into the table: "examsonline.timespent" */
  insert_examsonline_timespent_one?: Maybe<Examsonline_Timespent>;
  /** insert data into the table: "examsonline.topics" */
  insert_examsonline_topics?: Maybe<Examsonline_Topics_Mutation_Response>;
  /** insert a single row into the table: "examsonline.topics" */
  insert_examsonline_topics_one?: Maybe<Examsonline_Topics>;
  /** insert data into the table: "examsonline.user" */
  insert_examsonline_user?: Maybe<Examsonline_User_Mutation_Response>;
  /** insert a single row into the table: "examsonline.user" */
  insert_examsonline_user_one?: Maybe<Examsonline_User>;
  /** update data of the table: "_enumtable.country" */
  update__enumtable_country?: Maybe<_Enumtable_Country_Mutation_Response>;
  /** update single row of the table: "_enumtable.country" */
  update__enumtable_country_by_pk?: Maybe<_Enumtable_Country>;
  /** update multiples rows of table: "_enumtable.country" */
  update__enumtable_country_many?: Maybe<Array<Maybe<_Enumtable_Country_Mutation_Response>>>;
  /** update data of the table: "_enumtable.priority" */
  update__enumtable_priority?: Maybe<_Enumtable_Priority_Mutation_Response>;
  /** update single row of the table: "_enumtable.priority" */
  update__enumtable_priority_by_pk?: Maybe<_Enumtable_Priority>;
  /** update multiples rows of table: "_enumtable.priority" */
  update__enumtable_priority_many?: Maybe<Array<Maybe<_Enumtable_Priority_Mutation_Response>>>;
  /** update data of the table: "_enumtable.role" */
  update__enumtable_role?: Maybe<_Enumtable_Role_Mutation_Response>;
  /** update single row of the table: "_enumtable.role" */
  update__enumtable_role_by_pk?: Maybe<_Enumtable_Role>;
  /** update multiples rows of table: "_enumtable.role" */
  update__enumtable_role_many?: Maybe<Array<Maybe<_Enumtable_Role_Mutation_Response>>>;
  /** update data of the table: "_enumtable.ticket_status" */
  update__enumtable_ticket_status?: Maybe<_Enumtable_Ticket_Status_Mutation_Response>;
  /** update single row of the table: "_enumtable.ticket_status" */
  update__enumtable_ticket_status_by_pk?: Maybe<_Enumtable_Ticket_Status>;
  /** update multiples rows of table: "_enumtable.ticket_status" */
  update__enumtable_ticket_status_many?: Maybe<Array<Maybe<_Enumtable_Ticket_Status_Mutation_Response>>>;
  /** update data of the table: "examsonline.answers" */
  update_examsonline_answers?: Maybe<Examsonline_Answers_Mutation_Response>;
  /** update single row of the table: "examsonline.answers" */
  update_examsonline_answers_by_pk?: Maybe<Examsonline_Answers>;
  /** update multiples rows of table: "examsonline.answers" */
  update_examsonline_answers_many?: Maybe<Array<Maybe<Examsonline_Answers_Mutation_Response>>>;
  /** update data of the table: "examsonline.assignments" */
  update_examsonline_assignments?: Maybe<Examsonline_Assignments_Mutation_Response>;
  /** update single row of the table: "examsonline.assignments" */
  update_examsonline_assignments_by_pk?: Maybe<Examsonline_Assignments>;
  /** update multiples rows of table: "examsonline.assignments" */
  update_examsonline_assignments_many?: Maybe<Array<Maybe<Examsonline_Assignments_Mutation_Response>>>;
  /** update data of the table: "examsonline.attempt" */
  update_examsonline_attempt?: Maybe<Examsonline_Attempt_Mutation_Response>;
  /** update single row of the table: "examsonline.attempt" */
  update_examsonline_attempt_by_pk?: Maybe<Examsonline_Attempt>;
  /** update multiples rows of table: "examsonline.attempt" */
  update_examsonline_attempt_many?: Maybe<Array<Maybe<Examsonline_Attempt_Mutation_Response>>>;
  /** update data of the table: "examsonline.banner_notification" */
  update_examsonline_banner_notification?: Maybe<Examsonline_Banner_Notification_Mutation_Response>;
  /** update single row of the table: "examsonline.banner_notification" */
  update_examsonline_banner_notification_by_pk?: Maybe<Examsonline_Banner_Notification>;
  /** update multiples rows of table: "examsonline.banner_notification" */
  update_examsonline_banner_notification_many?: Maybe<Array<Maybe<Examsonline_Banner_Notification_Mutation_Response>>>;
  /** update data of the table: "examsonline.bill" */
  update_examsonline_bill?: Maybe<Examsonline_Bill_Mutation_Response>;
  /** update single row of the table: "examsonline.bill" */
  update_examsonline_bill_by_pk?: Maybe<Examsonline_Bill>;
  /** update multiples rows of table: "examsonline.bill" */
  update_examsonline_bill_many?: Maybe<Array<Maybe<Examsonline_Bill_Mutation_Response>>>;
  /** update data of the table: "examsonline.comments" */
  update_examsonline_comments?: Maybe<Examsonline_Comments_Mutation_Response>;
  /** update single row of the table: "examsonline.comments" */
  update_examsonline_comments_by_pk?: Maybe<Examsonline_Comments>;
  /** update multiples rows of table: "examsonline.comments" */
  update_examsonline_comments_many?: Maybe<Array<Maybe<Examsonline_Comments_Mutation_Response>>>;
  /** update data of the table: "examsonline.contactus" */
  update_examsonline_contactus?: Maybe<Examsonline_Contactus_Mutation_Response>;
  /** update single row of the table: "examsonline.contactus" */
  update_examsonline_contactus_by_pk?: Maybe<Examsonline_Contactus>;
  /** update multiples rows of table: "examsonline.contactus" */
  update_examsonline_contactus_many?: Maybe<Array<Maybe<Examsonline_Contactus_Mutation_Response>>>;
  /** update data of the table: "examsonline.contest" */
  update_examsonline_contest?: Maybe<Examsonline_Contest_Mutation_Response>;
  /** update single row of the table: "examsonline.contest" */
  update_examsonline_contest_by_pk?: Maybe<Examsonline_Contest>;
  /** update multiples rows of table: "examsonline.contest" */
  update_examsonline_contest_many?: Maybe<Array<Maybe<Examsonline_Contest_Mutation_Response>>>;
  /** update data of the table: "examsonline.file" */
  update_examsonline_file?: Maybe<Examsonline_File_Mutation_Response>;
  /** update single row of the table: "examsonline.file" */
  update_examsonline_file_by_pk?: Maybe<Examsonline_File>;
  /** update multiples rows of table: "examsonline.file" */
  update_examsonline_file_many?: Maybe<Array<Maybe<Examsonline_File_Mutation_Response>>>;
  /** update data of the table: "examsonline.groups" */
  update_examsonline_groups?: Maybe<Examsonline_Groups_Mutation_Response>;
  /** update single row of the table: "examsonline.groups" */
  update_examsonline_groups_by_pk?: Maybe<Examsonline_Groups>;
  /** update multiples rows of table: "examsonline.groups" */
  update_examsonline_groups_many?: Maybe<Array<Maybe<Examsonline_Groups_Mutation_Response>>>;
  /** update data of the table: "examsonline.members" */
  update_examsonline_members?: Maybe<Examsonline_Members_Mutation_Response>;
  /** update single row of the table: "examsonline.members" */
  update_examsonline_members_by_pk?: Maybe<Examsonline_Members>;
  /** update multiples rows of table: "examsonline.members" */
  update_examsonline_members_many?: Maybe<Array<Maybe<Examsonline_Members_Mutation_Response>>>;
  /** update data of the table: "examsonline.options" */
  update_examsonline_options?: Maybe<Examsonline_Options_Mutation_Response>;
  /** update single row of the table: "examsonline.options" */
  update_examsonline_options_by_pk?: Maybe<Examsonline_Options>;
  /** update multiples rows of table: "examsonline.options" */
  update_examsonline_options_many?: Maybe<Array<Maybe<Examsonline_Options_Mutation_Response>>>;
  /** update data of the table: "examsonline.order" */
  update_examsonline_order?: Maybe<Examsonline_Order_Mutation_Response>;
  /** update single row of the table: "examsonline.order" */
  update_examsonline_order_by_pk?: Maybe<Examsonline_Order>;
  /** update multiples rows of table: "examsonline.order" */
  update_examsonline_order_many?: Maybe<Array<Maybe<Examsonline_Order_Mutation_Response>>>;
  /** update data of the table: "examsonline.organisation" */
  update_examsonline_organisation?: Maybe<Examsonline_Organisation_Mutation_Response>;
  /** update single row of the table: "examsonline.organisation" */
  update_examsonline_organisation_by_pk?: Maybe<Examsonline_Organisation>;
  /** update multiples rows of table: "examsonline.organisation" */
  update_examsonline_organisation_many?: Maybe<Array<Maybe<Examsonline_Organisation_Mutation_Response>>>;
  /** update data of the table: "examsonline.participate" */
  update_examsonline_participate?: Maybe<Examsonline_Participate_Mutation_Response>;
  /** update single row of the table: "examsonline.participate" */
  update_examsonline_participate_by_pk?: Maybe<Examsonline_Participate>;
  /** update multiples rows of table: "examsonline.participate" */
  update_examsonline_participate_many?: Maybe<Array<Maybe<Examsonline_Participate_Mutation_Response>>>;
  /** update data of the table: "examsonline.payment_details" */
  update_examsonline_payment_details?: Maybe<Examsonline_Payment_Details_Mutation_Response>;
  /** update single row of the table: "examsonline.payment_details" */
  update_examsonline_payment_details_by_pk?: Maybe<Examsonline_Payment_Details>;
  /** update multiples rows of table: "examsonline.payment_details" */
  update_examsonline_payment_details_many?: Maybe<Array<Maybe<Examsonline_Payment_Details_Mutation_Response>>>;
  /** update data of the table: "examsonline.plan" */
  update_examsonline_plan?: Maybe<Examsonline_Plan_Mutation_Response>;
  /** update single row of the table: "examsonline.plan" */
  update_examsonline_plan_by_pk?: Maybe<Examsonline_Plan>;
  /** update multiples rows of table: "examsonline.plan" */
  update_examsonline_plan_many?: Maybe<Array<Maybe<Examsonline_Plan_Mutation_Response>>>;
  /** update data of the table: "examsonline.questions" */
  update_examsonline_questions?: Maybe<Examsonline_Questions_Mutation_Response>;
  /** update single row of the table: "examsonline.questions" */
  update_examsonline_questions_by_pk?: Maybe<Examsonline_Questions>;
  /** update multiples rows of table: "examsonline.questions" */
  update_examsonline_questions_many?: Maybe<Array<Maybe<Examsonline_Questions_Mutation_Response>>>;
  /** update data of the table: "examsonline.quiz" */
  update_examsonline_quiz?: Maybe<Examsonline_Quiz_Mutation_Response>;
  /** update single row of the table: "examsonline.quiz" */
  update_examsonline_quiz_by_pk?: Maybe<Examsonline_Quiz>;
  /** update multiples rows of table: "examsonline.quiz" */
  update_examsonline_quiz_many?: Maybe<Array<Maybe<Examsonline_Quiz_Mutation_Response>>>;
  /** update data of the table: "examsonline.rating" */
  update_examsonline_rating?: Maybe<Examsonline_Rating_Mutation_Response>;
  /** update single row of the table: "examsonline.rating" */
  update_examsonline_rating_by_pk?: Maybe<Examsonline_Rating>;
  /** update multiples rows of table: "examsonline.rating" */
  update_examsonline_rating_many?: Maybe<Array<Maybe<Examsonline_Rating_Mutation_Response>>>;
  /** update data of the table: "examsonline.roles" */
  update_examsonline_roles?: Maybe<Examsonline_Roles_Mutation_Response>;
  /** update single row of the table: "examsonline.roles" */
  update_examsonline_roles_by_pk?: Maybe<Examsonline_Roles>;
  /** update multiples rows of table: "examsonline.roles" */
  update_examsonline_roles_many?: Maybe<Array<Maybe<Examsonline_Roles_Mutation_Response>>>;
  /** update data of the table: "examsonline.selected_options" */
  update_examsonline_selected_options?: Maybe<Examsonline_Selected_Options_Mutation_Response>;
  /** update single row of the table: "examsonline.selected_options" */
  update_examsonline_selected_options_by_pk?: Maybe<Examsonline_Selected_Options>;
  /** update multiples rows of table: "examsonline.selected_options" */
  update_examsonline_selected_options_many?: Maybe<Array<Maybe<Examsonline_Selected_Options_Mutation_Response>>>;
  /** update data of the table: "examsonline.session" */
  update_examsonline_session?: Maybe<Examsonline_Session_Mutation_Response>;
  /** update single row of the table: "examsonline.session" */
  update_examsonline_session_by_pk?: Maybe<Examsonline_Session>;
  /** update multiples rows of table: "examsonline.session" */
  update_examsonline_session_many?: Maybe<Array<Maybe<Examsonline_Session_Mutation_Response>>>;
  /** update data of the table: "examsonline.sessionrecord" */
  update_examsonline_sessionrecord?: Maybe<Examsonline_Sessionrecord_Mutation_Response>;
  /** update single row of the table: "examsonline.sessionrecord" */
  update_examsonline_sessionrecord_by_pk?: Maybe<Examsonline_Sessionrecord>;
  /** update multiples rows of table: "examsonline.sessionrecord" */
  update_examsonline_sessionrecord_many?: Maybe<Array<Maybe<Examsonline_Sessionrecord_Mutation_Response>>>;
  /** update data of the table: "examsonline.sessions" */
  update_examsonline_sessions?: Maybe<Examsonline_Sessions_Mutation_Response>;
  /** update single row of the table: "examsonline.sessions" */
  update_examsonline_sessions_by_pk?: Maybe<Examsonline_Sessions>;
  /** update multiples rows of table: "examsonline.sessions" */
  update_examsonline_sessions_many?: Maybe<Array<Maybe<Examsonline_Sessions_Mutation_Response>>>;
  /** update data of the table: "examsonline.test_sections" */
  update_examsonline_test_sections?: Maybe<Examsonline_Test_Sections_Mutation_Response>;
  /** update single row of the table: "examsonline.test_sections" */
  update_examsonline_test_sections_by_pk?: Maybe<Examsonline_Test_Sections>;
  /** update multiples rows of table: "examsonline.test_sections" */
  update_examsonline_test_sections_many?: Maybe<Array<Maybe<Examsonline_Test_Sections_Mutation_Response>>>;
  /** update data of the table: "examsonline.tests" */
  update_examsonline_tests?: Maybe<Examsonline_Tests_Mutation_Response>;
  /** update single row of the table: "examsonline.tests" */
  update_examsonline_tests_by_pk?: Maybe<Examsonline_Tests>;
  /** update multiples rows of table: "examsonline.tests" */
  update_examsonline_tests_many?: Maybe<Array<Maybe<Examsonline_Tests_Mutation_Response>>>;
  /** update data of the table: "examsonline.tickets" */
  update_examsonline_tickets?: Maybe<Examsonline_Tickets_Mutation_Response>;
  /** update single row of the table: "examsonline.tickets" */
  update_examsonline_tickets_by_pk?: Maybe<Examsonline_Tickets>;
  /** update multiples rows of table: "examsonline.tickets" */
  update_examsonline_tickets_many?: Maybe<Array<Maybe<Examsonline_Tickets_Mutation_Response>>>;
  /** update data of the table: "examsonline.timespent" */
  update_examsonline_timespent?: Maybe<Examsonline_Timespent_Mutation_Response>;
  /** update single row of the table: "examsonline.timespent" */
  update_examsonline_timespent_by_pk?: Maybe<Examsonline_Timespent>;
  /** update multiples rows of table: "examsonline.timespent" */
  update_examsonline_timespent_many?: Maybe<Array<Maybe<Examsonline_Timespent_Mutation_Response>>>;
  /** update data of the table: "examsonline.topics" */
  update_examsonline_topics?: Maybe<Examsonline_Topics_Mutation_Response>;
  /** update single row of the table: "examsonline.topics" */
  update_examsonline_topics_by_pk?: Maybe<Examsonline_Topics>;
  /** update multiples rows of table: "examsonline.topics" */
  update_examsonline_topics_many?: Maybe<Array<Maybe<Examsonline_Topics_Mutation_Response>>>;
  /** update data of the table: "examsonline.user" */
  update_examsonline_user?: Maybe<Examsonline_User_Mutation_Response>;
  /** update single row of the table: "examsonline.user" */
  update_examsonline_user_by_pk?: Maybe<Examsonline_User>;
  /** update multiples rows of table: "examsonline.user" */
  update_examsonline_user_many?: Maybe<Array<Maybe<Examsonline_User_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootDelete__Enumtable_CountryArgs = {
  where: _Enumtable_Country_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete__Enumtable_Country_By_PkArgs = {
  name: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete__Enumtable_PriorityArgs = {
  where: _Enumtable_Priority_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete__Enumtable_Priority_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete__Enumtable_RoleArgs = {
  where: _Enumtable_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete__Enumtable_Role_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete__Enumtable_Ticket_StatusArgs = {
  where: _Enumtable_Ticket_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete__Enumtable_Ticket_Status_By_PkArgs = {
  status: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_AnswersArgs = {
  where: Examsonline_Answers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_Answers_By_PkArgs = {
  attempt_id: Scalars['uuid']['input'];
  q_id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_AssignmentsArgs = {
  where: Examsonline_Assignments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_Assignments_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_AttemptArgs = {
  where: Examsonline_Attempt_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_Attempt_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_Banner_NotificationArgs = {
  where: Examsonline_Banner_Notification_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_Banner_Notification_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_BillArgs = {
  where: Examsonline_Bill_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_Bill_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_CommentsArgs = {
  where: Examsonline_Comments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_Comments_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_ContactusArgs = {
  where: Examsonline_Contactus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_Contactus_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_ContestArgs = {
  where: Examsonline_Contest_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_Contest_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_FileArgs = {
  where: Examsonline_File_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_File_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_GroupsArgs = {
  where: Examsonline_Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_Groups_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_MembersArgs = {
  where: Examsonline_Members_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_Members_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_OptionsArgs = {
  where: Examsonline_Options_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_Options_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_OrderArgs = {
  where: Examsonline_Order_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_Order_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_OrganisationArgs = {
  where: Examsonline_Organisation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_Organisation_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_ParticipateArgs = {
  where: Examsonline_Participate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_Participate_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_Payment_DetailsArgs = {
  where: Examsonline_Payment_Details_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_Payment_Details_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_PlanArgs = {
  where: Examsonline_Plan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_Plan_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_QuestionsArgs = {
  where: Examsonline_Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_Questions_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_QuizArgs = {
  where: Examsonline_Quiz_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_Quiz_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_RatingArgs = {
  where: Examsonline_Rating_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_Rating_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_RolesArgs = {
  where: Examsonline_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_Roles_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_Selected_OptionsArgs = {
  where: Examsonline_Selected_Options_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_Selected_Options_By_PkArgs = {
  attempt_id: Scalars['uuid']['input'];
  option_id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_SessionArgs = {
  where: Examsonline_Session_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_Session_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_SessionrecordArgs = {
  where: Examsonline_Sessionrecord_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_Sessionrecord_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_SessionsArgs = {
  where: Examsonline_Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_Sessions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_Test_SectionsArgs = {
  where: Examsonline_Test_Sections_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_Test_Sections_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_TestsArgs = {
  where: Examsonline_Tests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_Tests_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_TicketsArgs = {
  where: Examsonline_Tickets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_Tickets_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_TimespentArgs = {
  where: Examsonline_Timespent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_Timespent_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_TopicsArgs = {
  where: Examsonline_Topics_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_Topics_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_UserArgs = {
  where: Examsonline_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Examsonline_User_By_PkArgs = {
  email: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootInsert__Enumtable_CountryArgs = {
  objects: Array<_Enumtable_Country_Insert_Input>;
  on_conflict?: InputMaybe<_Enumtable_Country_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert__Enumtable_Country_OneArgs = {
  object: _Enumtable_Country_Insert_Input;
  on_conflict?: InputMaybe<_Enumtable_Country_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert__Enumtable_PriorityArgs = {
  objects: Array<_Enumtable_Priority_Insert_Input>;
  on_conflict?: InputMaybe<_Enumtable_Priority_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert__Enumtable_Priority_OneArgs = {
  object: _Enumtable_Priority_Insert_Input;
  on_conflict?: InputMaybe<_Enumtable_Priority_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert__Enumtable_RoleArgs = {
  objects: Array<_Enumtable_Role_Insert_Input>;
  on_conflict?: InputMaybe<_Enumtable_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert__Enumtable_Role_OneArgs = {
  object: _Enumtable_Role_Insert_Input;
  on_conflict?: InputMaybe<_Enumtable_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert__Enumtable_Ticket_StatusArgs = {
  objects: Array<_Enumtable_Ticket_Status_Insert_Input>;
  on_conflict?: InputMaybe<_Enumtable_Ticket_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert__Enumtable_Ticket_Status_OneArgs = {
  object: _Enumtable_Ticket_Status_Insert_Input;
  on_conflict?: InputMaybe<_Enumtable_Ticket_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_AnswersArgs = {
  objects: Array<Examsonline_Answers_Insert_Input>;
  on_conflict?: InputMaybe<Examsonline_Answers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_Answers_OneArgs = {
  object: Examsonline_Answers_Insert_Input;
  on_conflict?: InputMaybe<Examsonline_Answers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_AssignmentsArgs = {
  objects: Array<Examsonline_Assignments_Insert_Input>;
  on_conflict?: InputMaybe<Examsonline_Assignments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_Assignments_OneArgs = {
  object: Examsonline_Assignments_Insert_Input;
  on_conflict?: InputMaybe<Examsonline_Assignments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_AttemptArgs = {
  objects: Array<Examsonline_Attempt_Insert_Input>;
  on_conflict?: InputMaybe<Examsonline_Attempt_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_Attempt_OneArgs = {
  object: Examsonline_Attempt_Insert_Input;
  on_conflict?: InputMaybe<Examsonline_Attempt_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_Banner_NotificationArgs = {
  objects: Array<Examsonline_Banner_Notification_Insert_Input>;
  on_conflict?: InputMaybe<Examsonline_Banner_Notification_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_Banner_Notification_OneArgs = {
  object: Examsonline_Banner_Notification_Insert_Input;
  on_conflict?: InputMaybe<Examsonline_Banner_Notification_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_BillArgs = {
  objects: Array<Examsonline_Bill_Insert_Input>;
  on_conflict?: InputMaybe<Examsonline_Bill_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_Bill_OneArgs = {
  object: Examsonline_Bill_Insert_Input;
  on_conflict?: InputMaybe<Examsonline_Bill_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_CommentsArgs = {
  objects: Array<Examsonline_Comments_Insert_Input>;
  on_conflict?: InputMaybe<Examsonline_Comments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_Comments_OneArgs = {
  object: Examsonline_Comments_Insert_Input;
  on_conflict?: InputMaybe<Examsonline_Comments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_ContactusArgs = {
  objects: Array<Examsonline_Contactus_Insert_Input>;
  on_conflict?: InputMaybe<Examsonline_Contactus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_Contactus_OneArgs = {
  object: Examsonline_Contactus_Insert_Input;
  on_conflict?: InputMaybe<Examsonline_Contactus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_ContestArgs = {
  objects: Array<Examsonline_Contest_Insert_Input>;
  on_conflict?: InputMaybe<Examsonline_Contest_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_Contest_OneArgs = {
  object: Examsonline_Contest_Insert_Input;
  on_conflict?: InputMaybe<Examsonline_Contest_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_FileArgs = {
  objects: Array<Examsonline_File_Insert_Input>;
  on_conflict?: InputMaybe<Examsonline_File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_File_OneArgs = {
  object: Examsonline_File_Insert_Input;
  on_conflict?: InputMaybe<Examsonline_File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_GroupsArgs = {
  objects: Array<Examsonline_Groups_Insert_Input>;
  on_conflict?: InputMaybe<Examsonline_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_Groups_OneArgs = {
  object: Examsonline_Groups_Insert_Input;
  on_conflict?: InputMaybe<Examsonline_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_MembersArgs = {
  objects: Array<Examsonline_Members_Insert_Input>;
  on_conflict?: InputMaybe<Examsonline_Members_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_Members_OneArgs = {
  object: Examsonline_Members_Insert_Input;
  on_conflict?: InputMaybe<Examsonline_Members_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_OptionsArgs = {
  objects: Array<Examsonline_Options_Insert_Input>;
  on_conflict?: InputMaybe<Examsonline_Options_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_Options_OneArgs = {
  object: Examsonline_Options_Insert_Input;
  on_conflict?: InputMaybe<Examsonline_Options_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_OrderArgs = {
  objects: Array<Examsonline_Order_Insert_Input>;
  on_conflict?: InputMaybe<Examsonline_Order_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_Order_OneArgs = {
  object: Examsonline_Order_Insert_Input;
  on_conflict?: InputMaybe<Examsonline_Order_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_OrganisationArgs = {
  objects: Array<Examsonline_Organisation_Insert_Input>;
  on_conflict?: InputMaybe<Examsonline_Organisation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_Organisation_OneArgs = {
  object: Examsonline_Organisation_Insert_Input;
  on_conflict?: InputMaybe<Examsonline_Organisation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_ParticipateArgs = {
  objects: Array<Examsonline_Participate_Insert_Input>;
  on_conflict?: InputMaybe<Examsonline_Participate_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_Participate_OneArgs = {
  object: Examsonline_Participate_Insert_Input;
  on_conflict?: InputMaybe<Examsonline_Participate_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_Payment_DetailsArgs = {
  objects: Array<Examsonline_Payment_Details_Insert_Input>;
  on_conflict?: InputMaybe<Examsonline_Payment_Details_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_Payment_Details_OneArgs = {
  object: Examsonline_Payment_Details_Insert_Input;
  on_conflict?: InputMaybe<Examsonline_Payment_Details_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_PlanArgs = {
  objects: Array<Examsonline_Plan_Insert_Input>;
  on_conflict?: InputMaybe<Examsonline_Plan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_Plan_OneArgs = {
  object: Examsonline_Plan_Insert_Input;
  on_conflict?: InputMaybe<Examsonline_Plan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_QuestionsArgs = {
  objects: Array<Examsonline_Questions_Insert_Input>;
  on_conflict?: InputMaybe<Examsonline_Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_Questions_OneArgs = {
  object: Examsonline_Questions_Insert_Input;
  on_conflict?: InputMaybe<Examsonline_Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_QuizArgs = {
  objects: Array<Examsonline_Quiz_Insert_Input>;
  on_conflict?: InputMaybe<Examsonline_Quiz_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_Quiz_OneArgs = {
  object: Examsonline_Quiz_Insert_Input;
  on_conflict?: InputMaybe<Examsonline_Quiz_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_RatingArgs = {
  objects: Array<Examsonline_Rating_Insert_Input>;
  on_conflict?: InputMaybe<Examsonline_Rating_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_Rating_OneArgs = {
  object: Examsonline_Rating_Insert_Input;
  on_conflict?: InputMaybe<Examsonline_Rating_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_RolesArgs = {
  objects: Array<Examsonline_Roles_Insert_Input>;
  on_conflict?: InputMaybe<Examsonline_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_Roles_OneArgs = {
  object: Examsonline_Roles_Insert_Input;
  on_conflict?: InputMaybe<Examsonline_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_Selected_OptionsArgs = {
  objects: Array<Examsonline_Selected_Options_Insert_Input>;
  on_conflict?: InputMaybe<Examsonline_Selected_Options_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_Selected_Options_OneArgs = {
  object: Examsonline_Selected_Options_Insert_Input;
  on_conflict?: InputMaybe<Examsonline_Selected_Options_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_SessionArgs = {
  objects: Array<Examsonline_Session_Insert_Input>;
  on_conflict?: InputMaybe<Examsonline_Session_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_Session_OneArgs = {
  object: Examsonline_Session_Insert_Input;
  on_conflict?: InputMaybe<Examsonline_Session_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_SessionrecordArgs = {
  objects: Array<Examsonline_Sessionrecord_Insert_Input>;
  on_conflict?: InputMaybe<Examsonline_Sessionrecord_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_Sessionrecord_OneArgs = {
  object: Examsonline_Sessionrecord_Insert_Input;
  on_conflict?: InputMaybe<Examsonline_Sessionrecord_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_SessionsArgs = {
  objects: Array<Examsonline_Sessions_Insert_Input>;
  on_conflict?: InputMaybe<Examsonline_Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_Sessions_OneArgs = {
  object: Examsonline_Sessions_Insert_Input;
  on_conflict?: InputMaybe<Examsonline_Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_Test_SectionsArgs = {
  objects: Array<Examsonline_Test_Sections_Insert_Input>;
  on_conflict?: InputMaybe<Examsonline_Test_Sections_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_Test_Sections_OneArgs = {
  object: Examsonline_Test_Sections_Insert_Input;
  on_conflict?: InputMaybe<Examsonline_Test_Sections_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_TestsArgs = {
  objects: Array<Examsonline_Tests_Insert_Input>;
  on_conflict?: InputMaybe<Examsonline_Tests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_Tests_OneArgs = {
  object: Examsonline_Tests_Insert_Input;
  on_conflict?: InputMaybe<Examsonline_Tests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_TicketsArgs = {
  objects: Array<Examsonline_Tickets_Insert_Input>;
  on_conflict?: InputMaybe<Examsonline_Tickets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_Tickets_OneArgs = {
  object: Examsonline_Tickets_Insert_Input;
  on_conflict?: InputMaybe<Examsonline_Tickets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_TimespentArgs = {
  objects: Array<Examsonline_Timespent_Insert_Input>;
  on_conflict?: InputMaybe<Examsonline_Timespent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_Timespent_OneArgs = {
  object: Examsonline_Timespent_Insert_Input;
  on_conflict?: InputMaybe<Examsonline_Timespent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_TopicsArgs = {
  objects: Array<Examsonline_Topics_Insert_Input>;
  on_conflict?: InputMaybe<Examsonline_Topics_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_Topics_OneArgs = {
  object: Examsonline_Topics_Insert_Input;
  on_conflict?: InputMaybe<Examsonline_Topics_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_UserArgs = {
  objects: Array<Examsonline_User_Insert_Input>;
  on_conflict?: InputMaybe<Examsonline_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Examsonline_User_OneArgs = {
  object: Examsonline_User_Insert_Input;
  on_conflict?: InputMaybe<Examsonline_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate__Enumtable_CountryArgs = {
  _set?: InputMaybe<_Enumtable_Country_Set_Input>;
  where: _Enumtable_Country_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate__Enumtable_Country_By_PkArgs = {
  _set?: InputMaybe<_Enumtable_Country_Set_Input>;
  pk_columns: _Enumtable_Country_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate__Enumtable_Country_ManyArgs = {
  updates: Array<_Enumtable_Country_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate__Enumtable_PriorityArgs = {
  _set?: InputMaybe<_Enumtable_Priority_Set_Input>;
  where: _Enumtable_Priority_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate__Enumtable_Priority_By_PkArgs = {
  _set?: InputMaybe<_Enumtable_Priority_Set_Input>;
  pk_columns: _Enumtable_Priority_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate__Enumtable_Priority_ManyArgs = {
  updates: Array<_Enumtable_Priority_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate__Enumtable_RoleArgs = {
  _set?: InputMaybe<_Enumtable_Role_Set_Input>;
  where: _Enumtable_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate__Enumtable_Role_By_PkArgs = {
  _set?: InputMaybe<_Enumtable_Role_Set_Input>;
  pk_columns: _Enumtable_Role_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate__Enumtable_Role_ManyArgs = {
  updates: Array<_Enumtable_Role_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate__Enumtable_Ticket_StatusArgs = {
  _set?: InputMaybe<_Enumtable_Ticket_Status_Set_Input>;
  where: _Enumtable_Ticket_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate__Enumtable_Ticket_Status_By_PkArgs = {
  _set?: InputMaybe<_Enumtable_Ticket_Status_Set_Input>;
  pk_columns: _Enumtable_Ticket_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate__Enumtable_Ticket_Status_ManyArgs = {
  updates: Array<_Enumtable_Ticket_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_AnswersArgs = {
  _inc?: InputMaybe<Examsonline_Answers_Inc_Input>;
  _set?: InputMaybe<Examsonline_Answers_Set_Input>;
  where: Examsonline_Answers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Answers_By_PkArgs = {
  _inc?: InputMaybe<Examsonline_Answers_Inc_Input>;
  _set?: InputMaybe<Examsonline_Answers_Set_Input>;
  pk_columns: Examsonline_Answers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Answers_ManyArgs = {
  updates: Array<Examsonline_Answers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_AssignmentsArgs = {
  _inc?: InputMaybe<Examsonline_Assignments_Inc_Input>;
  _set?: InputMaybe<Examsonline_Assignments_Set_Input>;
  where: Examsonline_Assignments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Assignments_By_PkArgs = {
  _inc?: InputMaybe<Examsonline_Assignments_Inc_Input>;
  _set?: InputMaybe<Examsonline_Assignments_Set_Input>;
  pk_columns: Examsonline_Assignments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Assignments_ManyArgs = {
  updates: Array<Examsonline_Assignments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_AttemptArgs = {
  _inc?: InputMaybe<Examsonline_Attempt_Inc_Input>;
  _set?: InputMaybe<Examsonline_Attempt_Set_Input>;
  where: Examsonline_Attempt_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Attempt_By_PkArgs = {
  _inc?: InputMaybe<Examsonline_Attempt_Inc_Input>;
  _set?: InputMaybe<Examsonline_Attempt_Set_Input>;
  pk_columns: Examsonline_Attempt_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Attempt_ManyArgs = {
  updates: Array<Examsonline_Attempt_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Banner_NotificationArgs = {
  _set?: InputMaybe<Examsonline_Banner_Notification_Set_Input>;
  where: Examsonline_Banner_Notification_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Banner_Notification_By_PkArgs = {
  _set?: InputMaybe<Examsonline_Banner_Notification_Set_Input>;
  pk_columns: Examsonline_Banner_Notification_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Banner_Notification_ManyArgs = {
  updates: Array<Examsonline_Banner_Notification_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_BillArgs = {
  _inc?: InputMaybe<Examsonline_Bill_Inc_Input>;
  _set?: InputMaybe<Examsonline_Bill_Set_Input>;
  where: Examsonline_Bill_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Bill_By_PkArgs = {
  _inc?: InputMaybe<Examsonline_Bill_Inc_Input>;
  _set?: InputMaybe<Examsonline_Bill_Set_Input>;
  pk_columns: Examsonline_Bill_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Bill_ManyArgs = {
  updates: Array<Examsonline_Bill_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_CommentsArgs = {
  _inc?: InputMaybe<Examsonline_Comments_Inc_Input>;
  _set?: InputMaybe<Examsonline_Comments_Set_Input>;
  where: Examsonline_Comments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Comments_By_PkArgs = {
  _inc?: InputMaybe<Examsonline_Comments_Inc_Input>;
  _set?: InputMaybe<Examsonline_Comments_Set_Input>;
  pk_columns: Examsonline_Comments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Comments_ManyArgs = {
  updates: Array<Examsonline_Comments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_ContactusArgs = {
  _inc?: InputMaybe<Examsonline_Contactus_Inc_Input>;
  _set?: InputMaybe<Examsonline_Contactus_Set_Input>;
  where: Examsonline_Contactus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Contactus_By_PkArgs = {
  _inc?: InputMaybe<Examsonline_Contactus_Inc_Input>;
  _set?: InputMaybe<Examsonline_Contactus_Set_Input>;
  pk_columns: Examsonline_Contactus_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Contactus_ManyArgs = {
  updates: Array<Examsonline_Contactus_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_ContestArgs = {
  _inc?: InputMaybe<Examsonline_Contest_Inc_Input>;
  _set?: InputMaybe<Examsonline_Contest_Set_Input>;
  where: Examsonline_Contest_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Contest_By_PkArgs = {
  _inc?: InputMaybe<Examsonline_Contest_Inc_Input>;
  _set?: InputMaybe<Examsonline_Contest_Set_Input>;
  pk_columns: Examsonline_Contest_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Contest_ManyArgs = {
  updates: Array<Examsonline_Contest_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_FileArgs = {
  _inc?: InputMaybe<Examsonline_File_Inc_Input>;
  _set?: InputMaybe<Examsonline_File_Set_Input>;
  where: Examsonline_File_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_File_By_PkArgs = {
  _inc?: InputMaybe<Examsonline_File_Inc_Input>;
  _set?: InputMaybe<Examsonline_File_Set_Input>;
  pk_columns: Examsonline_File_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_File_ManyArgs = {
  updates: Array<Examsonline_File_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_GroupsArgs = {
  _set?: InputMaybe<Examsonline_Groups_Set_Input>;
  where: Examsonline_Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Groups_By_PkArgs = {
  _set?: InputMaybe<Examsonline_Groups_Set_Input>;
  pk_columns: Examsonline_Groups_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Groups_ManyArgs = {
  updates: Array<Examsonline_Groups_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_MembersArgs = {
  _set?: InputMaybe<Examsonline_Members_Set_Input>;
  where: Examsonline_Members_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Members_By_PkArgs = {
  _set?: InputMaybe<Examsonline_Members_Set_Input>;
  pk_columns: Examsonline_Members_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Members_ManyArgs = {
  updates: Array<Examsonline_Members_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_OptionsArgs = {
  _inc?: InputMaybe<Examsonline_Options_Inc_Input>;
  _set?: InputMaybe<Examsonline_Options_Set_Input>;
  where: Examsonline_Options_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Options_By_PkArgs = {
  _inc?: InputMaybe<Examsonline_Options_Inc_Input>;
  _set?: InputMaybe<Examsonline_Options_Set_Input>;
  pk_columns: Examsonline_Options_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Options_ManyArgs = {
  updates: Array<Examsonline_Options_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_OrderArgs = {
  _inc?: InputMaybe<Examsonline_Order_Inc_Input>;
  _set?: InputMaybe<Examsonline_Order_Set_Input>;
  where: Examsonline_Order_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Order_By_PkArgs = {
  _inc?: InputMaybe<Examsonline_Order_Inc_Input>;
  _set?: InputMaybe<Examsonline_Order_Set_Input>;
  pk_columns: Examsonline_Order_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Order_ManyArgs = {
  updates: Array<Examsonline_Order_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_OrganisationArgs = {
  _append?: InputMaybe<Examsonline_Organisation_Append_Input>;
  _delete_at_path?: InputMaybe<Examsonline_Organisation_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Examsonline_Organisation_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Examsonline_Organisation_Delete_Key_Input>;
  _inc?: InputMaybe<Examsonline_Organisation_Inc_Input>;
  _prepend?: InputMaybe<Examsonline_Organisation_Prepend_Input>;
  _set?: InputMaybe<Examsonline_Organisation_Set_Input>;
  where: Examsonline_Organisation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Organisation_By_PkArgs = {
  _append?: InputMaybe<Examsonline_Organisation_Append_Input>;
  _delete_at_path?: InputMaybe<Examsonline_Organisation_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Examsonline_Organisation_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Examsonline_Organisation_Delete_Key_Input>;
  _inc?: InputMaybe<Examsonline_Organisation_Inc_Input>;
  _prepend?: InputMaybe<Examsonline_Organisation_Prepend_Input>;
  _set?: InputMaybe<Examsonline_Organisation_Set_Input>;
  pk_columns: Examsonline_Organisation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Organisation_ManyArgs = {
  updates: Array<Examsonline_Organisation_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_ParticipateArgs = {
  _set?: InputMaybe<Examsonline_Participate_Set_Input>;
  where: Examsonline_Participate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Participate_By_PkArgs = {
  _set?: InputMaybe<Examsonline_Participate_Set_Input>;
  pk_columns: Examsonline_Participate_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Participate_ManyArgs = {
  updates: Array<Examsonline_Participate_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Payment_DetailsArgs = {
  _set?: InputMaybe<Examsonline_Payment_Details_Set_Input>;
  where: Examsonline_Payment_Details_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Payment_Details_By_PkArgs = {
  _set?: InputMaybe<Examsonline_Payment_Details_Set_Input>;
  pk_columns: Examsonline_Payment_Details_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Payment_Details_ManyArgs = {
  updates: Array<Examsonline_Payment_Details_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_PlanArgs = {
  _inc?: InputMaybe<Examsonline_Plan_Inc_Input>;
  _set?: InputMaybe<Examsonline_Plan_Set_Input>;
  where: Examsonline_Plan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Plan_By_PkArgs = {
  _inc?: InputMaybe<Examsonline_Plan_Inc_Input>;
  _set?: InputMaybe<Examsonline_Plan_Set_Input>;
  pk_columns: Examsonline_Plan_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Plan_ManyArgs = {
  updates: Array<Examsonline_Plan_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_QuestionsArgs = {
  _append?: InputMaybe<Examsonline_Questions_Append_Input>;
  _delete_at_path?: InputMaybe<Examsonline_Questions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Examsonline_Questions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Examsonline_Questions_Delete_Key_Input>;
  _inc?: InputMaybe<Examsonline_Questions_Inc_Input>;
  _prepend?: InputMaybe<Examsonline_Questions_Prepend_Input>;
  _set?: InputMaybe<Examsonline_Questions_Set_Input>;
  where: Examsonline_Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Questions_By_PkArgs = {
  _append?: InputMaybe<Examsonline_Questions_Append_Input>;
  _delete_at_path?: InputMaybe<Examsonline_Questions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Examsonline_Questions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Examsonline_Questions_Delete_Key_Input>;
  _inc?: InputMaybe<Examsonline_Questions_Inc_Input>;
  _prepend?: InputMaybe<Examsonline_Questions_Prepend_Input>;
  _set?: InputMaybe<Examsonline_Questions_Set_Input>;
  pk_columns: Examsonline_Questions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Questions_ManyArgs = {
  updates: Array<Examsonline_Questions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_QuizArgs = {
  _inc?: InputMaybe<Examsonline_Quiz_Inc_Input>;
  _set?: InputMaybe<Examsonline_Quiz_Set_Input>;
  where: Examsonline_Quiz_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Quiz_By_PkArgs = {
  _inc?: InputMaybe<Examsonline_Quiz_Inc_Input>;
  _set?: InputMaybe<Examsonline_Quiz_Set_Input>;
  pk_columns: Examsonline_Quiz_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Quiz_ManyArgs = {
  updates: Array<Examsonline_Quiz_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_RatingArgs = {
  _inc?: InputMaybe<Examsonline_Rating_Inc_Input>;
  _set?: InputMaybe<Examsonline_Rating_Set_Input>;
  where: Examsonline_Rating_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Rating_By_PkArgs = {
  _inc?: InputMaybe<Examsonline_Rating_Inc_Input>;
  _set?: InputMaybe<Examsonline_Rating_Set_Input>;
  pk_columns: Examsonline_Rating_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Rating_ManyArgs = {
  updates: Array<Examsonline_Rating_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_RolesArgs = {
  _set?: InputMaybe<Examsonline_Roles_Set_Input>;
  where: Examsonline_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Roles_By_PkArgs = {
  _set?: InputMaybe<Examsonline_Roles_Set_Input>;
  pk_columns: Examsonline_Roles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Roles_ManyArgs = {
  updates: Array<Examsonline_Roles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Selected_OptionsArgs = {
  _inc?: InputMaybe<Examsonline_Selected_Options_Inc_Input>;
  _set?: InputMaybe<Examsonline_Selected_Options_Set_Input>;
  where: Examsonline_Selected_Options_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Selected_Options_By_PkArgs = {
  _inc?: InputMaybe<Examsonline_Selected_Options_Inc_Input>;
  _set?: InputMaybe<Examsonline_Selected_Options_Set_Input>;
  pk_columns: Examsonline_Selected_Options_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Selected_Options_ManyArgs = {
  updates: Array<Examsonline_Selected_Options_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_SessionArgs = {
  _append?: InputMaybe<Examsonline_Session_Append_Input>;
  _delete_at_path?: InputMaybe<Examsonline_Session_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Examsonline_Session_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Examsonline_Session_Delete_Key_Input>;
  _inc?: InputMaybe<Examsonline_Session_Inc_Input>;
  _prepend?: InputMaybe<Examsonline_Session_Prepend_Input>;
  _set?: InputMaybe<Examsonline_Session_Set_Input>;
  where: Examsonline_Session_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Session_By_PkArgs = {
  _append?: InputMaybe<Examsonline_Session_Append_Input>;
  _delete_at_path?: InputMaybe<Examsonline_Session_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Examsonline_Session_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Examsonline_Session_Delete_Key_Input>;
  _inc?: InputMaybe<Examsonline_Session_Inc_Input>;
  _prepend?: InputMaybe<Examsonline_Session_Prepend_Input>;
  _set?: InputMaybe<Examsonline_Session_Set_Input>;
  pk_columns: Examsonline_Session_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Session_ManyArgs = {
  updates: Array<Examsonline_Session_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_SessionrecordArgs = {
  _append?: InputMaybe<Examsonline_Sessionrecord_Append_Input>;
  _delete_at_path?: InputMaybe<Examsonline_Sessionrecord_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Examsonline_Sessionrecord_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Examsonline_Sessionrecord_Delete_Key_Input>;
  _inc?: InputMaybe<Examsonline_Sessionrecord_Inc_Input>;
  _prepend?: InputMaybe<Examsonline_Sessionrecord_Prepend_Input>;
  _set?: InputMaybe<Examsonline_Sessionrecord_Set_Input>;
  where: Examsonline_Sessionrecord_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Sessionrecord_By_PkArgs = {
  _append?: InputMaybe<Examsonline_Sessionrecord_Append_Input>;
  _delete_at_path?: InputMaybe<Examsonline_Sessionrecord_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Examsonline_Sessionrecord_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Examsonline_Sessionrecord_Delete_Key_Input>;
  _inc?: InputMaybe<Examsonline_Sessionrecord_Inc_Input>;
  _prepend?: InputMaybe<Examsonline_Sessionrecord_Prepend_Input>;
  _set?: InputMaybe<Examsonline_Sessionrecord_Set_Input>;
  pk_columns: Examsonline_Sessionrecord_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Sessionrecord_ManyArgs = {
  updates: Array<Examsonline_Sessionrecord_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_SessionsArgs = {
  _append?: InputMaybe<Examsonline_Sessions_Append_Input>;
  _delete_at_path?: InputMaybe<Examsonline_Sessions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Examsonline_Sessions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Examsonline_Sessions_Delete_Key_Input>;
  _prepend?: InputMaybe<Examsonline_Sessions_Prepend_Input>;
  _set?: InputMaybe<Examsonline_Sessions_Set_Input>;
  where: Examsonline_Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Sessions_By_PkArgs = {
  _append?: InputMaybe<Examsonline_Sessions_Append_Input>;
  _delete_at_path?: InputMaybe<Examsonline_Sessions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Examsonline_Sessions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Examsonline_Sessions_Delete_Key_Input>;
  _prepend?: InputMaybe<Examsonline_Sessions_Prepend_Input>;
  _set?: InputMaybe<Examsonline_Sessions_Set_Input>;
  pk_columns: Examsonline_Sessions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Sessions_ManyArgs = {
  updates: Array<Examsonline_Sessions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Test_SectionsArgs = {
  _inc?: InputMaybe<Examsonline_Test_Sections_Inc_Input>;
  _set?: InputMaybe<Examsonline_Test_Sections_Set_Input>;
  where: Examsonline_Test_Sections_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Test_Sections_By_PkArgs = {
  _inc?: InputMaybe<Examsonline_Test_Sections_Inc_Input>;
  _set?: InputMaybe<Examsonline_Test_Sections_Set_Input>;
  pk_columns: Examsonline_Test_Sections_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Test_Sections_ManyArgs = {
  updates: Array<Examsonline_Test_Sections_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_TestsArgs = {
  _append?: InputMaybe<Examsonline_Tests_Append_Input>;
  _delete_at_path?: InputMaybe<Examsonline_Tests_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Examsonline_Tests_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Examsonline_Tests_Delete_Key_Input>;
  _inc?: InputMaybe<Examsonline_Tests_Inc_Input>;
  _prepend?: InputMaybe<Examsonline_Tests_Prepend_Input>;
  _set?: InputMaybe<Examsonline_Tests_Set_Input>;
  where: Examsonline_Tests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Tests_By_PkArgs = {
  _append?: InputMaybe<Examsonline_Tests_Append_Input>;
  _delete_at_path?: InputMaybe<Examsonline_Tests_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Examsonline_Tests_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Examsonline_Tests_Delete_Key_Input>;
  _inc?: InputMaybe<Examsonline_Tests_Inc_Input>;
  _prepend?: InputMaybe<Examsonline_Tests_Prepend_Input>;
  _set?: InputMaybe<Examsonline_Tests_Set_Input>;
  pk_columns: Examsonline_Tests_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Tests_ManyArgs = {
  updates: Array<Examsonline_Tests_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_TicketsArgs = {
  _set?: InputMaybe<Examsonline_Tickets_Set_Input>;
  where: Examsonline_Tickets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Tickets_By_PkArgs = {
  _set?: InputMaybe<Examsonline_Tickets_Set_Input>;
  pk_columns: Examsonline_Tickets_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Tickets_ManyArgs = {
  updates: Array<Examsonline_Tickets_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_TimespentArgs = {
  _inc?: InputMaybe<Examsonline_Timespent_Inc_Input>;
  _set?: InputMaybe<Examsonline_Timespent_Set_Input>;
  where: Examsonline_Timespent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Timespent_By_PkArgs = {
  _inc?: InputMaybe<Examsonline_Timespent_Inc_Input>;
  _set?: InputMaybe<Examsonline_Timespent_Set_Input>;
  pk_columns: Examsonline_Timespent_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Timespent_ManyArgs = {
  updates: Array<Examsonline_Timespent_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_TopicsArgs = {
  _inc?: InputMaybe<Examsonline_Topics_Inc_Input>;
  _set?: InputMaybe<Examsonline_Topics_Set_Input>;
  where: Examsonline_Topics_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Topics_By_PkArgs = {
  _inc?: InputMaybe<Examsonline_Topics_Inc_Input>;
  _set?: InputMaybe<Examsonline_Topics_Set_Input>;
  pk_columns: Examsonline_Topics_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_Topics_ManyArgs = {
  updates: Array<Examsonline_Topics_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_UserArgs = {
  _set?: InputMaybe<Examsonline_User_Set_Input>;
  where: Examsonline_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_User_By_PkArgs = {
  _set?: InputMaybe<Examsonline_User_Set_Input>;
  pk_columns: Examsonline_User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Examsonline_User_ManyArgs = {
  updates: Array<Examsonline_User_Updates>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']['input']>;
  _gt?: InputMaybe<Scalars['numeric']['input']>;
  _gte?: InputMaybe<Scalars['numeric']['input']>;
  _in?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['numeric']['input']>;
  _lte?: InputMaybe<Scalars['numeric']['input']>;
  _neq?: InputMaybe<Scalars['numeric']['input']>;
  _nin?: InputMaybe<Array<Scalars['numeric']['input']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "_enumtable.country" */
  _enumtable_country: Array<_Enumtable_Country>;
  /** fetch aggregated fields from the table: "_enumtable.country" */
  _enumtable_country_aggregate: _Enumtable_Country_Aggregate;
  /** fetch data from the table: "_enumtable.country" using primary key columns */
  _enumtable_country_by_pk?: Maybe<_Enumtable_Country>;
  /** fetch data from the table: "_enumtable.priority" */
  _enumtable_priority: Array<_Enumtable_Priority>;
  /** fetch aggregated fields from the table: "_enumtable.priority" */
  _enumtable_priority_aggregate: _Enumtable_Priority_Aggregate;
  /** fetch data from the table: "_enumtable.priority" using primary key columns */
  _enumtable_priority_by_pk?: Maybe<_Enumtable_Priority>;
  /** fetch data from the table: "_enumtable.role" */
  _enumtable_role: Array<_Enumtable_Role>;
  /** fetch aggregated fields from the table: "_enumtable.role" */
  _enumtable_role_aggregate: _Enumtable_Role_Aggregate;
  /** fetch data from the table: "_enumtable.role" using primary key columns */
  _enumtable_role_by_pk?: Maybe<_Enumtable_Role>;
  /** fetch data from the table: "_enumtable.ticket_status" */
  _enumtable_ticket_status: Array<_Enumtable_Ticket_Status>;
  /** fetch aggregated fields from the table: "_enumtable.ticket_status" */
  _enumtable_ticket_status_aggregate: _Enumtable_Ticket_Status_Aggregate;
  /** fetch data from the table: "_enumtable.ticket_status" using primary key columns */
  _enumtable_ticket_status_by_pk?: Maybe<_Enumtable_Ticket_Status>;
  /** fetch data from the table: "examsonline.answers" */
  examsonline_answers: Array<Examsonline_Answers>;
  /** fetch aggregated fields from the table: "examsonline.answers" */
  examsonline_answers_aggregate: Examsonline_Answers_Aggregate;
  /** fetch data from the table: "examsonline.answers" using primary key columns */
  examsonline_answers_by_pk?: Maybe<Examsonline_Answers>;
  /** fetch data from the table: "examsonline.assignments" */
  examsonline_assignments: Array<Examsonline_Assignments>;
  /** fetch aggregated fields from the table: "examsonline.assignments" */
  examsonline_assignments_aggregate: Examsonline_Assignments_Aggregate;
  /** fetch data from the table: "examsonline.assignments" using primary key columns */
  examsonline_assignments_by_pk?: Maybe<Examsonline_Assignments>;
  /** fetch data from the table: "examsonline.attempt" */
  examsonline_attempt: Array<Examsonline_Attempt>;
  /** fetch aggregated fields from the table: "examsonline.attempt" */
  examsonline_attempt_aggregate: Examsonline_Attempt_Aggregate;
  /** fetch data from the table: "examsonline.attempt" using primary key columns */
  examsonline_attempt_by_pk?: Maybe<Examsonline_Attempt>;
  /** fetch data from the table: "examsonline.banner_notification" */
  examsonline_banner_notification: Array<Examsonline_Banner_Notification>;
  /** fetch aggregated fields from the table: "examsonline.banner_notification" */
  examsonline_banner_notification_aggregate: Examsonline_Banner_Notification_Aggregate;
  /** fetch data from the table: "examsonline.banner_notification" using primary key columns */
  examsonline_banner_notification_by_pk?: Maybe<Examsonline_Banner_Notification>;
  /** fetch data from the table: "examsonline.bill" */
  examsonline_bill: Array<Examsonline_Bill>;
  /** fetch aggregated fields from the table: "examsonline.bill" */
  examsonline_bill_aggregate: Examsonline_Bill_Aggregate;
  /** fetch data from the table: "examsonline.bill" using primary key columns */
  examsonline_bill_by_pk?: Maybe<Examsonline_Bill>;
  /** fetch data from the table: "examsonline.comments" */
  examsonline_comments: Array<Examsonline_Comments>;
  /** fetch aggregated fields from the table: "examsonline.comments" */
  examsonline_comments_aggregate: Examsonline_Comments_Aggregate;
  /** fetch data from the table: "examsonline.comments" using primary key columns */
  examsonline_comments_by_pk?: Maybe<Examsonline_Comments>;
  /** fetch data from the table: "examsonline.contactus" */
  examsonline_contactus: Array<Examsonline_Contactus>;
  /** fetch aggregated fields from the table: "examsonline.contactus" */
  examsonline_contactus_aggregate: Examsonline_Contactus_Aggregate;
  /** fetch data from the table: "examsonline.contactus" using primary key columns */
  examsonline_contactus_by_pk?: Maybe<Examsonline_Contactus>;
  /** fetch data from the table: "examsonline.contest" */
  examsonline_contest: Array<Examsonline_Contest>;
  /** fetch aggregated fields from the table: "examsonline.contest" */
  examsonline_contest_aggregate: Examsonline_Contest_Aggregate;
  /** fetch data from the table: "examsonline.contest" using primary key columns */
  examsonline_contest_by_pk?: Maybe<Examsonline_Contest>;
  /** fetch data from the table: "examsonline.file" */
  examsonline_file: Array<Examsonline_File>;
  /** fetch aggregated fields from the table: "examsonline.file" */
  examsonline_file_aggregate: Examsonline_File_Aggregate;
  /** fetch data from the table: "examsonline.file" using primary key columns */
  examsonline_file_by_pk?: Maybe<Examsonline_File>;
  /** fetch data from the table: "examsonline.groups" */
  examsonline_groups: Array<Examsonline_Groups>;
  /** fetch aggregated fields from the table: "examsonline.groups" */
  examsonline_groups_aggregate: Examsonline_Groups_Aggregate;
  /** fetch data from the table: "examsonline.groups" using primary key columns */
  examsonline_groups_by_pk?: Maybe<Examsonline_Groups>;
  /** fetch data from the table: "examsonline.members" */
  examsonline_members: Array<Examsonline_Members>;
  /** fetch aggregated fields from the table: "examsonline.members" */
  examsonline_members_aggregate: Examsonline_Members_Aggregate;
  /** fetch data from the table: "examsonline.members" using primary key columns */
  examsonline_members_by_pk?: Maybe<Examsonline_Members>;
  /** fetch data from the table: "examsonline.options" */
  examsonline_options: Array<Examsonline_Options>;
  /** fetch aggregated fields from the table: "examsonline.options" */
  examsonline_options_aggregate: Examsonline_Options_Aggregate;
  /** fetch data from the table: "examsonline.options" using primary key columns */
  examsonline_options_by_pk?: Maybe<Examsonline_Options>;
  /** fetch data from the table: "examsonline.order" */
  examsonline_order: Array<Examsonline_Order>;
  /** fetch aggregated fields from the table: "examsonline.order" */
  examsonline_order_aggregate: Examsonline_Order_Aggregate;
  /** fetch data from the table: "examsonline.order" using primary key columns */
  examsonline_order_by_pk?: Maybe<Examsonline_Order>;
  /** fetch data from the table: "examsonline.organisation" */
  examsonline_organisation: Array<Examsonline_Organisation>;
  /** fetch aggregated fields from the table: "examsonline.organisation" */
  examsonline_organisation_aggregate: Examsonline_Organisation_Aggregate;
  /** fetch data from the table: "examsonline.organisation" using primary key columns */
  examsonline_organisation_by_pk?: Maybe<Examsonline_Organisation>;
  /** fetch data from the table: "examsonline.participate" */
  examsonline_participate: Array<Examsonline_Participate>;
  /** fetch aggregated fields from the table: "examsonline.participate" */
  examsonline_participate_aggregate: Examsonline_Participate_Aggregate;
  /** fetch data from the table: "examsonline.participate" using primary key columns */
  examsonline_participate_by_pk?: Maybe<Examsonline_Participate>;
  /** fetch data from the table: "examsonline.payment_details" */
  examsonline_payment_details: Array<Examsonline_Payment_Details>;
  /** fetch aggregated fields from the table: "examsonline.payment_details" */
  examsonline_payment_details_aggregate: Examsonline_Payment_Details_Aggregate;
  /** fetch data from the table: "examsonline.payment_details" using primary key columns */
  examsonline_payment_details_by_pk?: Maybe<Examsonline_Payment_Details>;
  /** fetch data from the table: "examsonline.plan" */
  examsonline_plan: Array<Examsonline_Plan>;
  /** fetch aggregated fields from the table: "examsonline.plan" */
  examsonline_plan_aggregate: Examsonline_Plan_Aggregate;
  /** fetch data from the table: "examsonline.plan" using primary key columns */
  examsonline_plan_by_pk?: Maybe<Examsonline_Plan>;
  /** fetch data from the table: "examsonline.questions" */
  examsonline_questions: Array<Examsonline_Questions>;
  /** fetch aggregated fields from the table: "examsonline.questions" */
  examsonline_questions_aggregate: Examsonline_Questions_Aggregate;
  /** fetch data from the table: "examsonline.questions" using primary key columns */
  examsonline_questions_by_pk?: Maybe<Examsonline_Questions>;
  /** fetch data from the table: "examsonline.quiz" */
  examsonline_quiz: Array<Examsonline_Quiz>;
  /** fetch aggregated fields from the table: "examsonline.quiz" */
  examsonline_quiz_aggregate: Examsonline_Quiz_Aggregate;
  /** fetch data from the table: "examsonline.quiz" using primary key columns */
  examsonline_quiz_by_pk?: Maybe<Examsonline_Quiz>;
  /** fetch data from the table: "examsonline.rating" */
  examsonline_rating: Array<Examsonline_Rating>;
  /** fetch aggregated fields from the table: "examsonline.rating" */
  examsonline_rating_aggregate: Examsonline_Rating_Aggregate;
  /** fetch data from the table: "examsonline.rating" using primary key columns */
  examsonline_rating_by_pk?: Maybe<Examsonline_Rating>;
  /** fetch data from the table: "examsonline.roles" */
  examsonline_roles: Array<Examsonline_Roles>;
  /** fetch aggregated fields from the table: "examsonline.roles" */
  examsonline_roles_aggregate: Examsonline_Roles_Aggregate;
  /** fetch data from the table: "examsonline.roles" using primary key columns */
  examsonline_roles_by_pk?: Maybe<Examsonline_Roles>;
  /** fetch data from the table: "examsonline.selected_options" */
  examsonline_selected_options: Array<Examsonline_Selected_Options>;
  /** fetch aggregated fields from the table: "examsonline.selected_options" */
  examsonline_selected_options_aggregate: Examsonline_Selected_Options_Aggregate;
  /** fetch data from the table: "examsonline.selected_options" using primary key columns */
  examsonline_selected_options_by_pk?: Maybe<Examsonline_Selected_Options>;
  /** fetch data from the table: "examsonline.session" */
  examsonline_session: Array<Examsonline_Session>;
  /** fetch aggregated fields from the table: "examsonline.session" */
  examsonline_session_aggregate: Examsonline_Session_Aggregate;
  /** fetch data from the table: "examsonline.session" using primary key columns */
  examsonline_session_by_pk?: Maybe<Examsonline_Session>;
  /** fetch data from the table: "examsonline.sessionrecord" */
  examsonline_sessionrecord: Array<Examsonline_Sessionrecord>;
  /** fetch aggregated fields from the table: "examsonline.sessionrecord" */
  examsonline_sessionrecord_aggregate: Examsonline_Sessionrecord_Aggregate;
  /** fetch data from the table: "examsonline.sessionrecord" using primary key columns */
  examsonline_sessionrecord_by_pk?: Maybe<Examsonline_Sessionrecord>;
  /** fetch data from the table: "examsonline.sessions" */
  examsonline_sessions: Array<Examsonline_Sessions>;
  /** fetch aggregated fields from the table: "examsonline.sessions" */
  examsonline_sessions_aggregate: Examsonline_Sessions_Aggregate;
  /** fetch data from the table: "examsonline.sessions" using primary key columns */
  examsonline_sessions_by_pk?: Maybe<Examsonline_Sessions>;
  /** fetch data from the table: "examsonline.test_sections" */
  examsonline_test_sections: Array<Examsonline_Test_Sections>;
  /** fetch aggregated fields from the table: "examsonline.test_sections" */
  examsonline_test_sections_aggregate: Examsonline_Test_Sections_Aggregate;
  /** fetch data from the table: "examsonline.test_sections" using primary key columns */
  examsonline_test_sections_by_pk?: Maybe<Examsonline_Test_Sections>;
  /** fetch data from the table: "examsonline.tests" */
  examsonline_tests: Array<Examsonline_Tests>;
  /** fetch aggregated fields from the table: "examsonline.tests" */
  examsonline_tests_aggregate: Examsonline_Tests_Aggregate;
  /** fetch data from the table: "examsonline.tests" using primary key columns */
  examsonline_tests_by_pk?: Maybe<Examsonline_Tests>;
  /** fetch data from the table: "examsonline.tickets" */
  examsonline_tickets: Array<Examsonline_Tickets>;
  /** fetch aggregated fields from the table: "examsonline.tickets" */
  examsonline_tickets_aggregate: Examsonline_Tickets_Aggregate;
  /** fetch data from the table: "examsonline.tickets" using primary key columns */
  examsonline_tickets_by_pk?: Maybe<Examsonline_Tickets>;
  /** fetch data from the table: "examsonline.timespent" */
  examsonline_timespent: Array<Examsonline_Timespent>;
  /** fetch aggregated fields from the table: "examsonline.timespent" */
  examsonline_timespent_aggregate: Examsonline_Timespent_Aggregate;
  /** fetch data from the table: "examsonline.timespent" using primary key columns */
  examsonline_timespent_by_pk?: Maybe<Examsonline_Timespent>;
  /** fetch data from the table: "examsonline.topics" */
  examsonline_topics: Array<Examsonline_Topics>;
  /** fetch aggregated fields from the table: "examsonline.topics" */
  examsonline_topics_aggregate: Examsonline_Topics_Aggregate;
  /** fetch data from the table: "examsonline.topics" using primary key columns */
  examsonline_topics_by_pk?: Maybe<Examsonline_Topics>;
  /** fetch data from the table: "examsonline.user" */
  examsonline_user: Array<Examsonline_User>;
  /** fetch aggregated fields from the table: "examsonline.user" */
  examsonline_user_aggregate: Examsonline_User_Aggregate;
  /** fetch data from the table: "examsonline.user" using primary key columns */
  examsonline_user_by_pk?: Maybe<Examsonline_User>;
};


export type Query_Root_Enumtable_CountryArgs = {
  distinct_on?: InputMaybe<Array<_Enumtable_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<_Enumtable_Country_Order_By>>;
  where?: InputMaybe<_Enumtable_Country_Bool_Exp>;
};


export type Query_Root_Enumtable_Country_AggregateArgs = {
  distinct_on?: InputMaybe<Array<_Enumtable_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<_Enumtable_Country_Order_By>>;
  where?: InputMaybe<_Enumtable_Country_Bool_Exp>;
};


export type Query_Root_Enumtable_Country_By_PkArgs = {
  name: Scalars['String']['input'];
};


export type Query_Root_Enumtable_PriorityArgs = {
  distinct_on?: InputMaybe<Array<_Enumtable_Priority_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<_Enumtable_Priority_Order_By>>;
  where?: InputMaybe<_Enumtable_Priority_Bool_Exp>;
};


export type Query_Root_Enumtable_Priority_AggregateArgs = {
  distinct_on?: InputMaybe<Array<_Enumtable_Priority_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<_Enumtable_Priority_Order_By>>;
  where?: InputMaybe<_Enumtable_Priority_Bool_Exp>;
};


export type Query_Root_Enumtable_Priority_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_Root_Enumtable_RoleArgs = {
  distinct_on?: InputMaybe<Array<_Enumtable_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<_Enumtable_Role_Order_By>>;
  where?: InputMaybe<_Enumtable_Role_Bool_Exp>;
};


export type Query_Root_Enumtable_Role_AggregateArgs = {
  distinct_on?: InputMaybe<Array<_Enumtable_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<_Enumtable_Role_Order_By>>;
  where?: InputMaybe<_Enumtable_Role_Bool_Exp>;
};


export type Query_Root_Enumtable_Role_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_Root_Enumtable_Ticket_StatusArgs = {
  distinct_on?: InputMaybe<Array<_Enumtable_Ticket_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<_Enumtable_Ticket_Status_Order_By>>;
  where?: InputMaybe<_Enumtable_Ticket_Status_Bool_Exp>;
};


export type Query_Root_Enumtable_Ticket_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<_Enumtable_Ticket_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<_Enumtable_Ticket_Status_Order_By>>;
  where?: InputMaybe<_Enumtable_Ticket_Status_Bool_Exp>;
};


export type Query_Root_Enumtable_Ticket_Status_By_PkArgs = {
  status: Scalars['String']['input'];
};


export type Query_RootExamsonline_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Answers_Order_By>>;
  where?: InputMaybe<Examsonline_Answers_Bool_Exp>;
};


export type Query_RootExamsonline_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Answers_Order_By>>;
  where?: InputMaybe<Examsonline_Answers_Bool_Exp>;
};


export type Query_RootExamsonline_Answers_By_PkArgs = {
  attempt_id: Scalars['uuid']['input'];
  q_id: Scalars['Int']['input'];
};


export type Query_RootExamsonline_AssignmentsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Assignments_Order_By>>;
  where?: InputMaybe<Examsonline_Assignments_Bool_Exp>;
};


export type Query_RootExamsonline_Assignments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Assignments_Order_By>>;
  where?: InputMaybe<Examsonline_Assignments_Bool_Exp>;
};


export type Query_RootExamsonline_Assignments_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootExamsonline_AttemptArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Attempt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Attempt_Order_By>>;
  where?: InputMaybe<Examsonline_Attempt_Bool_Exp>;
};


export type Query_RootExamsonline_Attempt_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Attempt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Attempt_Order_By>>;
  where?: InputMaybe<Examsonline_Attempt_Bool_Exp>;
};


export type Query_RootExamsonline_Attempt_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootExamsonline_Banner_NotificationArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Banner_Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Banner_Notification_Order_By>>;
  where?: InputMaybe<Examsonline_Banner_Notification_Bool_Exp>;
};


export type Query_RootExamsonline_Banner_Notification_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Banner_Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Banner_Notification_Order_By>>;
  where?: InputMaybe<Examsonline_Banner_Notification_Bool_Exp>;
};


export type Query_RootExamsonline_Banner_Notification_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootExamsonline_BillArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Bill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Bill_Order_By>>;
  where?: InputMaybe<Examsonline_Bill_Bool_Exp>;
};


export type Query_RootExamsonline_Bill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Bill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Bill_Order_By>>;
  where?: InputMaybe<Examsonline_Bill_Bool_Exp>;
};


export type Query_RootExamsonline_Bill_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootExamsonline_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Comments_Order_By>>;
  where?: InputMaybe<Examsonline_Comments_Bool_Exp>;
};


export type Query_RootExamsonline_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Comments_Order_By>>;
  where?: InputMaybe<Examsonline_Comments_Bool_Exp>;
};


export type Query_RootExamsonline_Comments_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootExamsonline_ContactusArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Contactus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Contactus_Order_By>>;
  where?: InputMaybe<Examsonline_Contactus_Bool_Exp>;
};


export type Query_RootExamsonline_Contactus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Contactus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Contactus_Order_By>>;
  where?: InputMaybe<Examsonline_Contactus_Bool_Exp>;
};


export type Query_RootExamsonline_Contactus_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootExamsonline_ContestArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Contest_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Contest_Order_By>>;
  where?: InputMaybe<Examsonline_Contest_Bool_Exp>;
};


export type Query_RootExamsonline_Contest_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Contest_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Contest_Order_By>>;
  where?: InputMaybe<Examsonline_Contest_Bool_Exp>;
};


export type Query_RootExamsonline_Contest_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootExamsonline_FileArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_File_Order_By>>;
  where?: InputMaybe<Examsonline_File_Bool_Exp>;
};


export type Query_RootExamsonline_File_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_File_Order_By>>;
  where?: InputMaybe<Examsonline_File_Bool_Exp>;
};


export type Query_RootExamsonline_File_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootExamsonline_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Groups_Order_By>>;
  where?: InputMaybe<Examsonline_Groups_Bool_Exp>;
};


export type Query_RootExamsonline_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Groups_Order_By>>;
  where?: InputMaybe<Examsonline_Groups_Bool_Exp>;
};


export type Query_RootExamsonline_Groups_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootExamsonline_MembersArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Members_Order_By>>;
  where?: InputMaybe<Examsonline_Members_Bool_Exp>;
};


export type Query_RootExamsonline_Members_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Members_Order_By>>;
  where?: InputMaybe<Examsonline_Members_Bool_Exp>;
};


export type Query_RootExamsonline_Members_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootExamsonline_OptionsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Options_Order_By>>;
  where?: InputMaybe<Examsonline_Options_Bool_Exp>;
};


export type Query_RootExamsonline_Options_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Options_Order_By>>;
  where?: InputMaybe<Examsonline_Options_Bool_Exp>;
};


export type Query_RootExamsonline_Options_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootExamsonline_OrderArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Order_Order_By>>;
  where?: InputMaybe<Examsonline_Order_Bool_Exp>;
};


export type Query_RootExamsonline_Order_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Order_Order_By>>;
  where?: InputMaybe<Examsonline_Order_Bool_Exp>;
};


export type Query_RootExamsonline_Order_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootExamsonline_OrganisationArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Organisation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Organisation_Order_By>>;
  where?: InputMaybe<Examsonline_Organisation_Bool_Exp>;
};


export type Query_RootExamsonline_Organisation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Organisation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Organisation_Order_By>>;
  where?: InputMaybe<Examsonline_Organisation_Bool_Exp>;
};


export type Query_RootExamsonline_Organisation_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootExamsonline_ParticipateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Participate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Participate_Order_By>>;
  where?: InputMaybe<Examsonline_Participate_Bool_Exp>;
};


export type Query_RootExamsonline_Participate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Participate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Participate_Order_By>>;
  where?: InputMaybe<Examsonline_Participate_Bool_Exp>;
};


export type Query_RootExamsonline_Participate_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootExamsonline_Payment_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Payment_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Payment_Details_Order_By>>;
  where?: InputMaybe<Examsonline_Payment_Details_Bool_Exp>;
};


export type Query_RootExamsonline_Payment_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Payment_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Payment_Details_Order_By>>;
  where?: InputMaybe<Examsonline_Payment_Details_Bool_Exp>;
};


export type Query_RootExamsonline_Payment_Details_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootExamsonline_PlanArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Plan_Order_By>>;
  where?: InputMaybe<Examsonline_Plan_Bool_Exp>;
};


export type Query_RootExamsonline_Plan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Plan_Order_By>>;
  where?: InputMaybe<Examsonline_Plan_Bool_Exp>;
};


export type Query_RootExamsonline_Plan_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootExamsonline_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Questions_Order_By>>;
  where?: InputMaybe<Examsonline_Questions_Bool_Exp>;
};


export type Query_RootExamsonline_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Questions_Order_By>>;
  where?: InputMaybe<Examsonline_Questions_Bool_Exp>;
};


export type Query_RootExamsonline_Questions_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootExamsonline_QuizArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Quiz_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Quiz_Order_By>>;
  where?: InputMaybe<Examsonline_Quiz_Bool_Exp>;
};


export type Query_RootExamsonline_Quiz_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Quiz_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Quiz_Order_By>>;
  where?: InputMaybe<Examsonline_Quiz_Bool_Exp>;
};


export type Query_RootExamsonline_Quiz_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootExamsonline_RatingArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Rating_Order_By>>;
  where?: InputMaybe<Examsonline_Rating_Bool_Exp>;
};


export type Query_RootExamsonline_Rating_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Rating_Order_By>>;
  where?: InputMaybe<Examsonline_Rating_Bool_Exp>;
};


export type Query_RootExamsonline_Rating_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootExamsonline_RolesArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Roles_Order_By>>;
  where?: InputMaybe<Examsonline_Roles_Bool_Exp>;
};


export type Query_RootExamsonline_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Roles_Order_By>>;
  where?: InputMaybe<Examsonline_Roles_Bool_Exp>;
};


export type Query_RootExamsonline_Roles_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootExamsonline_Selected_OptionsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Selected_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Selected_Options_Order_By>>;
  where?: InputMaybe<Examsonline_Selected_Options_Bool_Exp>;
};


export type Query_RootExamsonline_Selected_Options_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Selected_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Selected_Options_Order_By>>;
  where?: InputMaybe<Examsonline_Selected_Options_Bool_Exp>;
};


export type Query_RootExamsonline_Selected_Options_By_PkArgs = {
  attempt_id: Scalars['uuid']['input'];
  option_id: Scalars['Int']['input'];
};


export type Query_RootExamsonline_SessionArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Session_Order_By>>;
  where?: InputMaybe<Examsonline_Session_Bool_Exp>;
};


export type Query_RootExamsonline_Session_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Session_Order_By>>;
  where?: InputMaybe<Examsonline_Session_Bool_Exp>;
};


export type Query_RootExamsonline_Session_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootExamsonline_SessionrecordArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Sessionrecord_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Sessionrecord_Order_By>>;
  where?: InputMaybe<Examsonline_Sessionrecord_Bool_Exp>;
};


export type Query_RootExamsonline_Sessionrecord_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Sessionrecord_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Sessionrecord_Order_By>>;
  where?: InputMaybe<Examsonline_Sessionrecord_Bool_Exp>;
};


export type Query_RootExamsonline_Sessionrecord_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootExamsonline_SessionsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Sessions_Order_By>>;
  where?: InputMaybe<Examsonline_Sessions_Bool_Exp>;
};


export type Query_RootExamsonline_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Sessions_Order_By>>;
  where?: InputMaybe<Examsonline_Sessions_Bool_Exp>;
};


export type Query_RootExamsonline_Sessions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootExamsonline_Test_SectionsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Test_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Test_Sections_Order_By>>;
  where?: InputMaybe<Examsonline_Test_Sections_Bool_Exp>;
};


export type Query_RootExamsonline_Test_Sections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Test_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Test_Sections_Order_By>>;
  where?: InputMaybe<Examsonline_Test_Sections_Bool_Exp>;
};


export type Query_RootExamsonline_Test_Sections_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootExamsonline_TestsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Tests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Tests_Order_By>>;
  where?: InputMaybe<Examsonline_Tests_Bool_Exp>;
};


export type Query_RootExamsonline_Tests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Tests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Tests_Order_By>>;
  where?: InputMaybe<Examsonline_Tests_Bool_Exp>;
};


export type Query_RootExamsonline_Tests_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootExamsonline_TicketsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Tickets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Tickets_Order_By>>;
  where?: InputMaybe<Examsonline_Tickets_Bool_Exp>;
};


export type Query_RootExamsonline_Tickets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Tickets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Tickets_Order_By>>;
  where?: InputMaybe<Examsonline_Tickets_Bool_Exp>;
};


export type Query_RootExamsonline_Tickets_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootExamsonline_TimespentArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Timespent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Timespent_Order_By>>;
  where?: InputMaybe<Examsonline_Timespent_Bool_Exp>;
};


export type Query_RootExamsonline_Timespent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Timespent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Timespent_Order_By>>;
  where?: InputMaybe<Examsonline_Timespent_Bool_Exp>;
};


export type Query_RootExamsonline_Timespent_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootExamsonline_TopicsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Topics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Topics_Order_By>>;
  where?: InputMaybe<Examsonline_Topics_Bool_Exp>;
};


export type Query_RootExamsonline_Topics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Topics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Topics_Order_By>>;
  where?: InputMaybe<Examsonline_Topics_Bool_Exp>;
};


export type Query_RootExamsonline_Topics_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootExamsonline_UserArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_User_Order_By>>;
  where?: InputMaybe<Examsonline_User_Bool_Exp>;
};


export type Query_RootExamsonline_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_User_Order_By>>;
  where?: InputMaybe<Examsonline_User_Bool_Exp>;
};


export type Query_RootExamsonline_User_By_PkArgs = {
  email: Scalars['String']['input'];
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "_enumtable.country" */
  _enumtable_country: Array<_Enumtable_Country>;
  /** fetch aggregated fields from the table: "_enumtable.country" */
  _enumtable_country_aggregate: _Enumtable_Country_Aggregate;
  /** fetch data from the table: "_enumtable.country" using primary key columns */
  _enumtable_country_by_pk?: Maybe<_Enumtable_Country>;
  /** fetch data from the table in a streaming manner: "_enumtable.country" */
  _enumtable_country_stream: Array<_Enumtable_Country>;
  /** fetch data from the table: "_enumtable.priority" */
  _enumtable_priority: Array<_Enumtable_Priority>;
  /** fetch aggregated fields from the table: "_enumtable.priority" */
  _enumtable_priority_aggregate: _Enumtable_Priority_Aggregate;
  /** fetch data from the table: "_enumtable.priority" using primary key columns */
  _enumtable_priority_by_pk?: Maybe<_Enumtable_Priority>;
  /** fetch data from the table in a streaming manner: "_enumtable.priority" */
  _enumtable_priority_stream: Array<_Enumtable_Priority>;
  /** fetch data from the table: "_enumtable.role" */
  _enumtable_role: Array<_Enumtable_Role>;
  /** fetch aggregated fields from the table: "_enumtable.role" */
  _enumtable_role_aggregate: _Enumtable_Role_Aggregate;
  /** fetch data from the table: "_enumtable.role" using primary key columns */
  _enumtable_role_by_pk?: Maybe<_Enumtable_Role>;
  /** fetch data from the table in a streaming manner: "_enumtable.role" */
  _enumtable_role_stream: Array<_Enumtable_Role>;
  /** fetch data from the table: "_enumtable.ticket_status" */
  _enumtable_ticket_status: Array<_Enumtable_Ticket_Status>;
  /** fetch aggregated fields from the table: "_enumtable.ticket_status" */
  _enumtable_ticket_status_aggregate: _Enumtable_Ticket_Status_Aggregate;
  /** fetch data from the table: "_enumtable.ticket_status" using primary key columns */
  _enumtable_ticket_status_by_pk?: Maybe<_Enumtable_Ticket_Status>;
  /** fetch data from the table in a streaming manner: "_enumtable.ticket_status" */
  _enumtable_ticket_status_stream: Array<_Enumtable_Ticket_Status>;
  /** fetch data from the table: "examsonline.answers" */
  examsonline_answers: Array<Examsonline_Answers>;
  /** fetch aggregated fields from the table: "examsonline.answers" */
  examsonline_answers_aggregate: Examsonline_Answers_Aggregate;
  /** fetch data from the table: "examsonline.answers" using primary key columns */
  examsonline_answers_by_pk?: Maybe<Examsonline_Answers>;
  /** fetch data from the table in a streaming manner: "examsonline.answers" */
  examsonline_answers_stream: Array<Examsonline_Answers>;
  /** fetch data from the table: "examsonline.assignments" */
  examsonline_assignments: Array<Examsonline_Assignments>;
  /** fetch aggregated fields from the table: "examsonline.assignments" */
  examsonline_assignments_aggregate: Examsonline_Assignments_Aggregate;
  /** fetch data from the table: "examsonline.assignments" using primary key columns */
  examsonline_assignments_by_pk?: Maybe<Examsonline_Assignments>;
  /** fetch data from the table in a streaming manner: "examsonline.assignments" */
  examsonline_assignments_stream: Array<Examsonline_Assignments>;
  /** fetch data from the table: "examsonline.attempt" */
  examsonline_attempt: Array<Examsonline_Attempt>;
  /** fetch aggregated fields from the table: "examsonline.attempt" */
  examsonline_attempt_aggregate: Examsonline_Attempt_Aggregate;
  /** fetch data from the table: "examsonline.attempt" using primary key columns */
  examsonline_attempt_by_pk?: Maybe<Examsonline_Attempt>;
  /** fetch data from the table in a streaming manner: "examsonline.attempt" */
  examsonline_attempt_stream: Array<Examsonline_Attempt>;
  /** fetch data from the table: "examsonline.banner_notification" */
  examsonline_banner_notification: Array<Examsonline_Banner_Notification>;
  /** fetch aggregated fields from the table: "examsonline.banner_notification" */
  examsonline_banner_notification_aggregate: Examsonline_Banner_Notification_Aggregate;
  /** fetch data from the table: "examsonline.banner_notification" using primary key columns */
  examsonline_banner_notification_by_pk?: Maybe<Examsonline_Banner_Notification>;
  /** fetch data from the table in a streaming manner: "examsonline.banner_notification" */
  examsonline_banner_notification_stream: Array<Examsonline_Banner_Notification>;
  /** fetch data from the table: "examsonline.bill" */
  examsonline_bill: Array<Examsonline_Bill>;
  /** fetch aggregated fields from the table: "examsonline.bill" */
  examsonline_bill_aggregate: Examsonline_Bill_Aggregate;
  /** fetch data from the table: "examsonline.bill" using primary key columns */
  examsonline_bill_by_pk?: Maybe<Examsonline_Bill>;
  /** fetch data from the table in a streaming manner: "examsonline.bill" */
  examsonline_bill_stream: Array<Examsonline_Bill>;
  /** fetch data from the table: "examsonline.comments" */
  examsonline_comments: Array<Examsonline_Comments>;
  /** fetch aggregated fields from the table: "examsonline.comments" */
  examsonline_comments_aggregate: Examsonline_Comments_Aggregate;
  /** fetch data from the table: "examsonline.comments" using primary key columns */
  examsonline_comments_by_pk?: Maybe<Examsonline_Comments>;
  /** fetch data from the table in a streaming manner: "examsonline.comments" */
  examsonline_comments_stream: Array<Examsonline_Comments>;
  /** fetch data from the table: "examsonline.contactus" */
  examsonline_contactus: Array<Examsonline_Contactus>;
  /** fetch aggregated fields from the table: "examsonline.contactus" */
  examsonline_contactus_aggregate: Examsonline_Contactus_Aggregate;
  /** fetch data from the table: "examsonline.contactus" using primary key columns */
  examsonline_contactus_by_pk?: Maybe<Examsonline_Contactus>;
  /** fetch data from the table in a streaming manner: "examsonline.contactus" */
  examsonline_contactus_stream: Array<Examsonline_Contactus>;
  /** fetch data from the table: "examsonline.contest" */
  examsonline_contest: Array<Examsonline_Contest>;
  /** fetch aggregated fields from the table: "examsonline.contest" */
  examsonline_contest_aggregate: Examsonline_Contest_Aggregate;
  /** fetch data from the table: "examsonline.contest" using primary key columns */
  examsonline_contest_by_pk?: Maybe<Examsonline_Contest>;
  /** fetch data from the table in a streaming manner: "examsonline.contest" */
  examsonline_contest_stream: Array<Examsonline_Contest>;
  /** fetch data from the table: "examsonline.file" */
  examsonline_file: Array<Examsonline_File>;
  /** fetch aggregated fields from the table: "examsonline.file" */
  examsonline_file_aggregate: Examsonline_File_Aggregate;
  /** fetch data from the table: "examsonline.file" using primary key columns */
  examsonline_file_by_pk?: Maybe<Examsonline_File>;
  /** fetch data from the table in a streaming manner: "examsonline.file" */
  examsonline_file_stream: Array<Examsonline_File>;
  /** fetch data from the table: "examsonline.groups" */
  examsonline_groups: Array<Examsonline_Groups>;
  /** fetch aggregated fields from the table: "examsonline.groups" */
  examsonline_groups_aggregate: Examsonline_Groups_Aggregate;
  /** fetch data from the table: "examsonline.groups" using primary key columns */
  examsonline_groups_by_pk?: Maybe<Examsonline_Groups>;
  /** fetch data from the table in a streaming manner: "examsonline.groups" */
  examsonline_groups_stream: Array<Examsonline_Groups>;
  /** fetch data from the table: "examsonline.members" */
  examsonline_members: Array<Examsonline_Members>;
  /** fetch aggregated fields from the table: "examsonline.members" */
  examsonline_members_aggregate: Examsonline_Members_Aggregate;
  /** fetch data from the table: "examsonline.members" using primary key columns */
  examsonline_members_by_pk?: Maybe<Examsonline_Members>;
  /** fetch data from the table in a streaming manner: "examsonline.members" */
  examsonline_members_stream: Array<Examsonline_Members>;
  /** fetch data from the table: "examsonline.options" */
  examsonline_options: Array<Examsonline_Options>;
  /** fetch aggregated fields from the table: "examsonline.options" */
  examsonline_options_aggregate: Examsonline_Options_Aggregate;
  /** fetch data from the table: "examsonline.options" using primary key columns */
  examsonline_options_by_pk?: Maybe<Examsonline_Options>;
  /** fetch data from the table in a streaming manner: "examsonline.options" */
  examsonline_options_stream: Array<Examsonline_Options>;
  /** fetch data from the table: "examsonline.order" */
  examsonline_order: Array<Examsonline_Order>;
  /** fetch aggregated fields from the table: "examsonline.order" */
  examsonline_order_aggregate: Examsonline_Order_Aggregate;
  /** fetch data from the table: "examsonline.order" using primary key columns */
  examsonline_order_by_pk?: Maybe<Examsonline_Order>;
  /** fetch data from the table in a streaming manner: "examsonline.order" */
  examsonline_order_stream: Array<Examsonline_Order>;
  /** fetch data from the table: "examsonline.organisation" */
  examsonline_organisation: Array<Examsonline_Organisation>;
  /** fetch aggregated fields from the table: "examsonline.organisation" */
  examsonline_organisation_aggregate: Examsonline_Organisation_Aggregate;
  /** fetch data from the table: "examsonline.organisation" using primary key columns */
  examsonline_organisation_by_pk?: Maybe<Examsonline_Organisation>;
  /** fetch data from the table in a streaming manner: "examsonline.organisation" */
  examsonline_organisation_stream: Array<Examsonline_Organisation>;
  /** fetch data from the table: "examsonline.participate" */
  examsonline_participate: Array<Examsonline_Participate>;
  /** fetch aggregated fields from the table: "examsonline.participate" */
  examsonline_participate_aggregate: Examsonline_Participate_Aggregate;
  /** fetch data from the table: "examsonline.participate" using primary key columns */
  examsonline_participate_by_pk?: Maybe<Examsonline_Participate>;
  /** fetch data from the table in a streaming manner: "examsonline.participate" */
  examsonline_participate_stream: Array<Examsonline_Participate>;
  /** fetch data from the table: "examsonline.payment_details" */
  examsonline_payment_details: Array<Examsonline_Payment_Details>;
  /** fetch aggregated fields from the table: "examsonline.payment_details" */
  examsonline_payment_details_aggregate: Examsonline_Payment_Details_Aggregate;
  /** fetch data from the table: "examsonline.payment_details" using primary key columns */
  examsonline_payment_details_by_pk?: Maybe<Examsonline_Payment_Details>;
  /** fetch data from the table in a streaming manner: "examsonline.payment_details" */
  examsonline_payment_details_stream: Array<Examsonline_Payment_Details>;
  /** fetch data from the table: "examsonline.plan" */
  examsonline_plan: Array<Examsonline_Plan>;
  /** fetch aggregated fields from the table: "examsonline.plan" */
  examsonline_plan_aggregate: Examsonline_Plan_Aggregate;
  /** fetch data from the table: "examsonline.plan" using primary key columns */
  examsonline_plan_by_pk?: Maybe<Examsonline_Plan>;
  /** fetch data from the table in a streaming manner: "examsonline.plan" */
  examsonline_plan_stream: Array<Examsonline_Plan>;
  /** fetch data from the table: "examsonline.questions" */
  examsonline_questions: Array<Examsonline_Questions>;
  /** fetch aggregated fields from the table: "examsonline.questions" */
  examsonline_questions_aggregate: Examsonline_Questions_Aggregate;
  /** fetch data from the table: "examsonline.questions" using primary key columns */
  examsonline_questions_by_pk?: Maybe<Examsonline_Questions>;
  /** fetch data from the table in a streaming manner: "examsonline.questions" */
  examsonline_questions_stream: Array<Examsonline_Questions>;
  /** fetch data from the table: "examsonline.quiz" */
  examsonline_quiz: Array<Examsonline_Quiz>;
  /** fetch aggregated fields from the table: "examsonline.quiz" */
  examsonline_quiz_aggregate: Examsonline_Quiz_Aggregate;
  /** fetch data from the table: "examsonline.quiz" using primary key columns */
  examsonline_quiz_by_pk?: Maybe<Examsonline_Quiz>;
  /** fetch data from the table in a streaming manner: "examsonline.quiz" */
  examsonline_quiz_stream: Array<Examsonline_Quiz>;
  /** fetch data from the table: "examsonline.rating" */
  examsonline_rating: Array<Examsonline_Rating>;
  /** fetch aggregated fields from the table: "examsonline.rating" */
  examsonline_rating_aggregate: Examsonline_Rating_Aggregate;
  /** fetch data from the table: "examsonline.rating" using primary key columns */
  examsonline_rating_by_pk?: Maybe<Examsonline_Rating>;
  /** fetch data from the table in a streaming manner: "examsonline.rating" */
  examsonline_rating_stream: Array<Examsonline_Rating>;
  /** fetch data from the table: "examsonline.roles" */
  examsonline_roles: Array<Examsonline_Roles>;
  /** fetch aggregated fields from the table: "examsonline.roles" */
  examsonline_roles_aggregate: Examsonline_Roles_Aggregate;
  /** fetch data from the table: "examsonline.roles" using primary key columns */
  examsonline_roles_by_pk?: Maybe<Examsonline_Roles>;
  /** fetch data from the table in a streaming manner: "examsonline.roles" */
  examsonline_roles_stream: Array<Examsonline_Roles>;
  /** fetch data from the table: "examsonline.selected_options" */
  examsonline_selected_options: Array<Examsonline_Selected_Options>;
  /** fetch aggregated fields from the table: "examsonline.selected_options" */
  examsonline_selected_options_aggregate: Examsonline_Selected_Options_Aggregate;
  /** fetch data from the table: "examsonline.selected_options" using primary key columns */
  examsonline_selected_options_by_pk?: Maybe<Examsonline_Selected_Options>;
  /** fetch data from the table in a streaming manner: "examsonline.selected_options" */
  examsonline_selected_options_stream: Array<Examsonline_Selected_Options>;
  /** fetch data from the table: "examsonline.session" */
  examsonline_session: Array<Examsonline_Session>;
  /** fetch aggregated fields from the table: "examsonline.session" */
  examsonline_session_aggregate: Examsonline_Session_Aggregate;
  /** fetch data from the table: "examsonline.session" using primary key columns */
  examsonline_session_by_pk?: Maybe<Examsonline_Session>;
  /** fetch data from the table in a streaming manner: "examsonline.session" */
  examsonline_session_stream: Array<Examsonline_Session>;
  /** fetch data from the table: "examsonline.sessionrecord" */
  examsonline_sessionrecord: Array<Examsonline_Sessionrecord>;
  /** fetch aggregated fields from the table: "examsonline.sessionrecord" */
  examsonline_sessionrecord_aggregate: Examsonline_Sessionrecord_Aggregate;
  /** fetch data from the table: "examsonline.sessionrecord" using primary key columns */
  examsonline_sessionrecord_by_pk?: Maybe<Examsonline_Sessionrecord>;
  /** fetch data from the table in a streaming manner: "examsonline.sessionrecord" */
  examsonline_sessionrecord_stream: Array<Examsonline_Sessionrecord>;
  /** fetch data from the table: "examsonline.sessions" */
  examsonline_sessions: Array<Examsonline_Sessions>;
  /** fetch aggregated fields from the table: "examsonline.sessions" */
  examsonline_sessions_aggregate: Examsonline_Sessions_Aggregate;
  /** fetch data from the table: "examsonline.sessions" using primary key columns */
  examsonline_sessions_by_pk?: Maybe<Examsonline_Sessions>;
  /** fetch data from the table in a streaming manner: "examsonline.sessions" */
  examsonline_sessions_stream: Array<Examsonline_Sessions>;
  /** fetch data from the table: "examsonline.test_sections" */
  examsonline_test_sections: Array<Examsonline_Test_Sections>;
  /** fetch aggregated fields from the table: "examsonline.test_sections" */
  examsonline_test_sections_aggregate: Examsonline_Test_Sections_Aggregate;
  /** fetch data from the table: "examsonline.test_sections" using primary key columns */
  examsonline_test_sections_by_pk?: Maybe<Examsonline_Test_Sections>;
  /** fetch data from the table in a streaming manner: "examsonline.test_sections" */
  examsonline_test_sections_stream: Array<Examsonline_Test_Sections>;
  /** fetch data from the table: "examsonline.tests" */
  examsonline_tests: Array<Examsonline_Tests>;
  /** fetch aggregated fields from the table: "examsonline.tests" */
  examsonline_tests_aggregate: Examsonline_Tests_Aggregate;
  /** fetch data from the table: "examsonline.tests" using primary key columns */
  examsonline_tests_by_pk?: Maybe<Examsonline_Tests>;
  /** fetch data from the table in a streaming manner: "examsonline.tests" */
  examsonline_tests_stream: Array<Examsonline_Tests>;
  /** fetch data from the table: "examsonline.tickets" */
  examsonline_tickets: Array<Examsonline_Tickets>;
  /** fetch aggregated fields from the table: "examsonline.tickets" */
  examsonline_tickets_aggregate: Examsonline_Tickets_Aggregate;
  /** fetch data from the table: "examsonline.tickets" using primary key columns */
  examsonline_tickets_by_pk?: Maybe<Examsonline_Tickets>;
  /** fetch data from the table in a streaming manner: "examsonline.tickets" */
  examsonline_tickets_stream: Array<Examsonline_Tickets>;
  /** fetch data from the table: "examsonline.timespent" */
  examsonline_timespent: Array<Examsonline_Timespent>;
  /** fetch aggregated fields from the table: "examsonline.timespent" */
  examsonline_timespent_aggregate: Examsonline_Timespent_Aggregate;
  /** fetch data from the table: "examsonline.timespent" using primary key columns */
  examsonline_timespent_by_pk?: Maybe<Examsonline_Timespent>;
  /** fetch data from the table in a streaming manner: "examsonline.timespent" */
  examsonline_timespent_stream: Array<Examsonline_Timespent>;
  /** fetch data from the table: "examsonline.topics" */
  examsonline_topics: Array<Examsonline_Topics>;
  /** fetch aggregated fields from the table: "examsonline.topics" */
  examsonline_topics_aggregate: Examsonline_Topics_Aggregate;
  /** fetch data from the table: "examsonline.topics" using primary key columns */
  examsonline_topics_by_pk?: Maybe<Examsonline_Topics>;
  /** fetch data from the table in a streaming manner: "examsonline.topics" */
  examsonline_topics_stream: Array<Examsonline_Topics>;
  /** fetch data from the table: "examsonline.user" */
  examsonline_user: Array<Examsonline_User>;
  /** fetch aggregated fields from the table: "examsonline.user" */
  examsonline_user_aggregate: Examsonline_User_Aggregate;
  /** fetch data from the table: "examsonline.user" using primary key columns */
  examsonline_user_by_pk?: Maybe<Examsonline_User>;
  /** fetch data from the table in a streaming manner: "examsonline.user" */
  examsonline_user_stream: Array<Examsonline_User>;
};


export type Subscription_Root_Enumtable_CountryArgs = {
  distinct_on?: InputMaybe<Array<_Enumtable_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<_Enumtable_Country_Order_By>>;
  where?: InputMaybe<_Enumtable_Country_Bool_Exp>;
};


export type Subscription_Root_Enumtable_Country_AggregateArgs = {
  distinct_on?: InputMaybe<Array<_Enumtable_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<_Enumtable_Country_Order_By>>;
  where?: InputMaybe<_Enumtable_Country_Bool_Exp>;
};


export type Subscription_Root_Enumtable_Country_By_PkArgs = {
  name: Scalars['String']['input'];
};


export type Subscription_Root_Enumtable_Country_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<_Enumtable_Country_Stream_Cursor_Input>>;
  where?: InputMaybe<_Enumtable_Country_Bool_Exp>;
};


export type Subscription_Root_Enumtable_PriorityArgs = {
  distinct_on?: InputMaybe<Array<_Enumtable_Priority_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<_Enumtable_Priority_Order_By>>;
  where?: InputMaybe<_Enumtable_Priority_Bool_Exp>;
};


export type Subscription_Root_Enumtable_Priority_AggregateArgs = {
  distinct_on?: InputMaybe<Array<_Enumtable_Priority_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<_Enumtable_Priority_Order_By>>;
  where?: InputMaybe<_Enumtable_Priority_Bool_Exp>;
};


export type Subscription_Root_Enumtable_Priority_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_Root_Enumtable_Priority_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<_Enumtable_Priority_Stream_Cursor_Input>>;
  where?: InputMaybe<_Enumtable_Priority_Bool_Exp>;
};


export type Subscription_Root_Enumtable_RoleArgs = {
  distinct_on?: InputMaybe<Array<_Enumtable_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<_Enumtable_Role_Order_By>>;
  where?: InputMaybe<_Enumtable_Role_Bool_Exp>;
};


export type Subscription_Root_Enumtable_Role_AggregateArgs = {
  distinct_on?: InputMaybe<Array<_Enumtable_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<_Enumtable_Role_Order_By>>;
  where?: InputMaybe<_Enumtable_Role_Bool_Exp>;
};


export type Subscription_Root_Enumtable_Role_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_Root_Enumtable_Role_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<_Enumtable_Role_Stream_Cursor_Input>>;
  where?: InputMaybe<_Enumtable_Role_Bool_Exp>;
};


export type Subscription_Root_Enumtable_Ticket_StatusArgs = {
  distinct_on?: InputMaybe<Array<_Enumtable_Ticket_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<_Enumtable_Ticket_Status_Order_By>>;
  where?: InputMaybe<_Enumtable_Ticket_Status_Bool_Exp>;
};


export type Subscription_Root_Enumtable_Ticket_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<_Enumtable_Ticket_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<_Enumtable_Ticket_Status_Order_By>>;
  where?: InputMaybe<_Enumtable_Ticket_Status_Bool_Exp>;
};


export type Subscription_Root_Enumtable_Ticket_Status_By_PkArgs = {
  status: Scalars['String']['input'];
};


export type Subscription_Root_Enumtable_Ticket_Status_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<_Enumtable_Ticket_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<_Enumtable_Ticket_Status_Bool_Exp>;
};


export type Subscription_RootExamsonline_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Answers_Order_By>>;
  where?: InputMaybe<Examsonline_Answers_Bool_Exp>;
};


export type Subscription_RootExamsonline_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Answers_Order_By>>;
  where?: InputMaybe<Examsonline_Answers_Bool_Exp>;
};


export type Subscription_RootExamsonline_Answers_By_PkArgs = {
  attempt_id: Scalars['uuid']['input'];
  q_id: Scalars['Int']['input'];
};


export type Subscription_RootExamsonline_Answers_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Examsonline_Answers_Stream_Cursor_Input>>;
  where?: InputMaybe<Examsonline_Answers_Bool_Exp>;
};


export type Subscription_RootExamsonline_AssignmentsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Assignments_Order_By>>;
  where?: InputMaybe<Examsonline_Assignments_Bool_Exp>;
};


export type Subscription_RootExamsonline_Assignments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Assignments_Order_By>>;
  where?: InputMaybe<Examsonline_Assignments_Bool_Exp>;
};


export type Subscription_RootExamsonline_Assignments_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootExamsonline_Assignments_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Examsonline_Assignments_Stream_Cursor_Input>>;
  where?: InputMaybe<Examsonline_Assignments_Bool_Exp>;
};


export type Subscription_RootExamsonline_AttemptArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Attempt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Attempt_Order_By>>;
  where?: InputMaybe<Examsonline_Attempt_Bool_Exp>;
};


export type Subscription_RootExamsonline_Attempt_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Attempt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Attempt_Order_By>>;
  where?: InputMaybe<Examsonline_Attempt_Bool_Exp>;
};


export type Subscription_RootExamsonline_Attempt_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootExamsonline_Attempt_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Examsonline_Attempt_Stream_Cursor_Input>>;
  where?: InputMaybe<Examsonline_Attempt_Bool_Exp>;
};


export type Subscription_RootExamsonline_Banner_NotificationArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Banner_Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Banner_Notification_Order_By>>;
  where?: InputMaybe<Examsonline_Banner_Notification_Bool_Exp>;
};


export type Subscription_RootExamsonline_Banner_Notification_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Banner_Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Banner_Notification_Order_By>>;
  where?: InputMaybe<Examsonline_Banner_Notification_Bool_Exp>;
};


export type Subscription_RootExamsonline_Banner_Notification_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootExamsonline_Banner_Notification_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Examsonline_Banner_Notification_Stream_Cursor_Input>>;
  where?: InputMaybe<Examsonline_Banner_Notification_Bool_Exp>;
};


export type Subscription_RootExamsonline_BillArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Bill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Bill_Order_By>>;
  where?: InputMaybe<Examsonline_Bill_Bool_Exp>;
};


export type Subscription_RootExamsonline_Bill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Bill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Bill_Order_By>>;
  where?: InputMaybe<Examsonline_Bill_Bool_Exp>;
};


export type Subscription_RootExamsonline_Bill_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootExamsonline_Bill_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Examsonline_Bill_Stream_Cursor_Input>>;
  where?: InputMaybe<Examsonline_Bill_Bool_Exp>;
};


export type Subscription_RootExamsonline_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Comments_Order_By>>;
  where?: InputMaybe<Examsonline_Comments_Bool_Exp>;
};


export type Subscription_RootExamsonline_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Comments_Order_By>>;
  where?: InputMaybe<Examsonline_Comments_Bool_Exp>;
};


export type Subscription_RootExamsonline_Comments_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootExamsonline_Comments_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Examsonline_Comments_Stream_Cursor_Input>>;
  where?: InputMaybe<Examsonline_Comments_Bool_Exp>;
};


export type Subscription_RootExamsonline_ContactusArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Contactus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Contactus_Order_By>>;
  where?: InputMaybe<Examsonline_Contactus_Bool_Exp>;
};


export type Subscription_RootExamsonline_Contactus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Contactus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Contactus_Order_By>>;
  where?: InputMaybe<Examsonline_Contactus_Bool_Exp>;
};


export type Subscription_RootExamsonline_Contactus_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootExamsonline_Contactus_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Examsonline_Contactus_Stream_Cursor_Input>>;
  where?: InputMaybe<Examsonline_Contactus_Bool_Exp>;
};


export type Subscription_RootExamsonline_ContestArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Contest_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Contest_Order_By>>;
  where?: InputMaybe<Examsonline_Contest_Bool_Exp>;
};


export type Subscription_RootExamsonline_Contest_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Contest_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Contest_Order_By>>;
  where?: InputMaybe<Examsonline_Contest_Bool_Exp>;
};


export type Subscription_RootExamsonline_Contest_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootExamsonline_Contest_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Examsonline_Contest_Stream_Cursor_Input>>;
  where?: InputMaybe<Examsonline_Contest_Bool_Exp>;
};


export type Subscription_RootExamsonline_FileArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_File_Order_By>>;
  where?: InputMaybe<Examsonline_File_Bool_Exp>;
};


export type Subscription_RootExamsonline_File_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_File_Order_By>>;
  where?: InputMaybe<Examsonline_File_Bool_Exp>;
};


export type Subscription_RootExamsonline_File_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootExamsonline_File_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Examsonline_File_Stream_Cursor_Input>>;
  where?: InputMaybe<Examsonline_File_Bool_Exp>;
};


export type Subscription_RootExamsonline_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Groups_Order_By>>;
  where?: InputMaybe<Examsonline_Groups_Bool_Exp>;
};


export type Subscription_RootExamsonline_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Groups_Order_By>>;
  where?: InputMaybe<Examsonline_Groups_Bool_Exp>;
};


export type Subscription_RootExamsonline_Groups_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootExamsonline_Groups_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Examsonline_Groups_Stream_Cursor_Input>>;
  where?: InputMaybe<Examsonline_Groups_Bool_Exp>;
};


export type Subscription_RootExamsonline_MembersArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Members_Order_By>>;
  where?: InputMaybe<Examsonline_Members_Bool_Exp>;
};


export type Subscription_RootExamsonline_Members_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Members_Order_By>>;
  where?: InputMaybe<Examsonline_Members_Bool_Exp>;
};


export type Subscription_RootExamsonline_Members_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootExamsonline_Members_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Examsonline_Members_Stream_Cursor_Input>>;
  where?: InputMaybe<Examsonline_Members_Bool_Exp>;
};


export type Subscription_RootExamsonline_OptionsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Options_Order_By>>;
  where?: InputMaybe<Examsonline_Options_Bool_Exp>;
};


export type Subscription_RootExamsonline_Options_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Options_Order_By>>;
  where?: InputMaybe<Examsonline_Options_Bool_Exp>;
};


export type Subscription_RootExamsonline_Options_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootExamsonline_Options_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Examsonline_Options_Stream_Cursor_Input>>;
  where?: InputMaybe<Examsonline_Options_Bool_Exp>;
};


export type Subscription_RootExamsonline_OrderArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Order_Order_By>>;
  where?: InputMaybe<Examsonline_Order_Bool_Exp>;
};


export type Subscription_RootExamsonline_Order_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Order_Order_By>>;
  where?: InputMaybe<Examsonline_Order_Bool_Exp>;
};


export type Subscription_RootExamsonline_Order_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootExamsonline_Order_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Examsonline_Order_Stream_Cursor_Input>>;
  where?: InputMaybe<Examsonline_Order_Bool_Exp>;
};


export type Subscription_RootExamsonline_OrganisationArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Organisation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Organisation_Order_By>>;
  where?: InputMaybe<Examsonline_Organisation_Bool_Exp>;
};


export type Subscription_RootExamsonline_Organisation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Organisation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Organisation_Order_By>>;
  where?: InputMaybe<Examsonline_Organisation_Bool_Exp>;
};


export type Subscription_RootExamsonline_Organisation_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootExamsonline_Organisation_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Examsonline_Organisation_Stream_Cursor_Input>>;
  where?: InputMaybe<Examsonline_Organisation_Bool_Exp>;
};


export type Subscription_RootExamsonline_ParticipateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Participate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Participate_Order_By>>;
  where?: InputMaybe<Examsonline_Participate_Bool_Exp>;
};


export type Subscription_RootExamsonline_Participate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Participate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Participate_Order_By>>;
  where?: InputMaybe<Examsonline_Participate_Bool_Exp>;
};


export type Subscription_RootExamsonline_Participate_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootExamsonline_Participate_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Examsonline_Participate_Stream_Cursor_Input>>;
  where?: InputMaybe<Examsonline_Participate_Bool_Exp>;
};


export type Subscription_RootExamsonline_Payment_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Payment_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Payment_Details_Order_By>>;
  where?: InputMaybe<Examsonline_Payment_Details_Bool_Exp>;
};


export type Subscription_RootExamsonline_Payment_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Payment_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Payment_Details_Order_By>>;
  where?: InputMaybe<Examsonline_Payment_Details_Bool_Exp>;
};


export type Subscription_RootExamsonline_Payment_Details_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootExamsonline_Payment_Details_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Examsonline_Payment_Details_Stream_Cursor_Input>>;
  where?: InputMaybe<Examsonline_Payment_Details_Bool_Exp>;
};


export type Subscription_RootExamsonline_PlanArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Plan_Order_By>>;
  where?: InputMaybe<Examsonline_Plan_Bool_Exp>;
};


export type Subscription_RootExamsonline_Plan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Plan_Order_By>>;
  where?: InputMaybe<Examsonline_Plan_Bool_Exp>;
};


export type Subscription_RootExamsonline_Plan_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootExamsonline_Plan_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Examsonline_Plan_Stream_Cursor_Input>>;
  where?: InputMaybe<Examsonline_Plan_Bool_Exp>;
};


export type Subscription_RootExamsonline_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Questions_Order_By>>;
  where?: InputMaybe<Examsonline_Questions_Bool_Exp>;
};


export type Subscription_RootExamsonline_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Questions_Order_By>>;
  where?: InputMaybe<Examsonline_Questions_Bool_Exp>;
};


export type Subscription_RootExamsonline_Questions_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootExamsonline_Questions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Examsonline_Questions_Stream_Cursor_Input>>;
  where?: InputMaybe<Examsonline_Questions_Bool_Exp>;
};


export type Subscription_RootExamsonline_QuizArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Quiz_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Quiz_Order_By>>;
  where?: InputMaybe<Examsonline_Quiz_Bool_Exp>;
};


export type Subscription_RootExamsonline_Quiz_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Quiz_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Quiz_Order_By>>;
  where?: InputMaybe<Examsonline_Quiz_Bool_Exp>;
};


export type Subscription_RootExamsonline_Quiz_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootExamsonline_Quiz_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Examsonline_Quiz_Stream_Cursor_Input>>;
  where?: InputMaybe<Examsonline_Quiz_Bool_Exp>;
};


export type Subscription_RootExamsonline_RatingArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Rating_Order_By>>;
  where?: InputMaybe<Examsonline_Rating_Bool_Exp>;
};


export type Subscription_RootExamsonline_Rating_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Rating_Order_By>>;
  where?: InputMaybe<Examsonline_Rating_Bool_Exp>;
};


export type Subscription_RootExamsonline_Rating_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootExamsonline_Rating_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Examsonline_Rating_Stream_Cursor_Input>>;
  where?: InputMaybe<Examsonline_Rating_Bool_Exp>;
};


export type Subscription_RootExamsonline_RolesArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Roles_Order_By>>;
  where?: InputMaybe<Examsonline_Roles_Bool_Exp>;
};


export type Subscription_RootExamsonline_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Roles_Order_By>>;
  where?: InputMaybe<Examsonline_Roles_Bool_Exp>;
};


export type Subscription_RootExamsonline_Roles_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootExamsonline_Roles_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Examsonline_Roles_Stream_Cursor_Input>>;
  where?: InputMaybe<Examsonline_Roles_Bool_Exp>;
};


export type Subscription_RootExamsonline_Selected_OptionsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Selected_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Selected_Options_Order_By>>;
  where?: InputMaybe<Examsonline_Selected_Options_Bool_Exp>;
};


export type Subscription_RootExamsonline_Selected_Options_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Selected_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Selected_Options_Order_By>>;
  where?: InputMaybe<Examsonline_Selected_Options_Bool_Exp>;
};


export type Subscription_RootExamsonline_Selected_Options_By_PkArgs = {
  attempt_id: Scalars['uuid']['input'];
  option_id: Scalars['Int']['input'];
};


export type Subscription_RootExamsonline_Selected_Options_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Examsonline_Selected_Options_Stream_Cursor_Input>>;
  where?: InputMaybe<Examsonline_Selected_Options_Bool_Exp>;
};


export type Subscription_RootExamsonline_SessionArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Session_Order_By>>;
  where?: InputMaybe<Examsonline_Session_Bool_Exp>;
};


export type Subscription_RootExamsonline_Session_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Session_Order_By>>;
  where?: InputMaybe<Examsonline_Session_Bool_Exp>;
};


export type Subscription_RootExamsonline_Session_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootExamsonline_Session_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Examsonline_Session_Stream_Cursor_Input>>;
  where?: InputMaybe<Examsonline_Session_Bool_Exp>;
};


export type Subscription_RootExamsonline_SessionrecordArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Sessionrecord_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Sessionrecord_Order_By>>;
  where?: InputMaybe<Examsonline_Sessionrecord_Bool_Exp>;
};


export type Subscription_RootExamsonline_Sessionrecord_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Sessionrecord_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Sessionrecord_Order_By>>;
  where?: InputMaybe<Examsonline_Sessionrecord_Bool_Exp>;
};


export type Subscription_RootExamsonline_Sessionrecord_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootExamsonline_Sessionrecord_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Examsonline_Sessionrecord_Stream_Cursor_Input>>;
  where?: InputMaybe<Examsonline_Sessionrecord_Bool_Exp>;
};


export type Subscription_RootExamsonline_SessionsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Sessions_Order_By>>;
  where?: InputMaybe<Examsonline_Sessions_Bool_Exp>;
};


export type Subscription_RootExamsonline_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Sessions_Order_By>>;
  where?: InputMaybe<Examsonline_Sessions_Bool_Exp>;
};


export type Subscription_RootExamsonline_Sessions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootExamsonline_Sessions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Examsonline_Sessions_Stream_Cursor_Input>>;
  where?: InputMaybe<Examsonline_Sessions_Bool_Exp>;
};


export type Subscription_RootExamsonline_Test_SectionsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Test_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Test_Sections_Order_By>>;
  where?: InputMaybe<Examsonline_Test_Sections_Bool_Exp>;
};


export type Subscription_RootExamsonline_Test_Sections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Test_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Test_Sections_Order_By>>;
  where?: InputMaybe<Examsonline_Test_Sections_Bool_Exp>;
};


export type Subscription_RootExamsonline_Test_Sections_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootExamsonline_Test_Sections_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Examsonline_Test_Sections_Stream_Cursor_Input>>;
  where?: InputMaybe<Examsonline_Test_Sections_Bool_Exp>;
};


export type Subscription_RootExamsonline_TestsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Tests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Tests_Order_By>>;
  where?: InputMaybe<Examsonline_Tests_Bool_Exp>;
};


export type Subscription_RootExamsonline_Tests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Tests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Tests_Order_By>>;
  where?: InputMaybe<Examsonline_Tests_Bool_Exp>;
};


export type Subscription_RootExamsonline_Tests_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootExamsonline_Tests_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Examsonline_Tests_Stream_Cursor_Input>>;
  where?: InputMaybe<Examsonline_Tests_Bool_Exp>;
};


export type Subscription_RootExamsonline_TicketsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Tickets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Tickets_Order_By>>;
  where?: InputMaybe<Examsonline_Tickets_Bool_Exp>;
};


export type Subscription_RootExamsonline_Tickets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Tickets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Tickets_Order_By>>;
  where?: InputMaybe<Examsonline_Tickets_Bool_Exp>;
};


export type Subscription_RootExamsonline_Tickets_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootExamsonline_Tickets_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Examsonline_Tickets_Stream_Cursor_Input>>;
  where?: InputMaybe<Examsonline_Tickets_Bool_Exp>;
};


export type Subscription_RootExamsonline_TimespentArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Timespent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Timespent_Order_By>>;
  where?: InputMaybe<Examsonline_Timespent_Bool_Exp>;
};


export type Subscription_RootExamsonline_Timespent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Timespent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Timespent_Order_By>>;
  where?: InputMaybe<Examsonline_Timespent_Bool_Exp>;
};


export type Subscription_RootExamsonline_Timespent_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootExamsonline_Timespent_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Examsonline_Timespent_Stream_Cursor_Input>>;
  where?: InputMaybe<Examsonline_Timespent_Bool_Exp>;
};


export type Subscription_RootExamsonline_TopicsArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Topics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Topics_Order_By>>;
  where?: InputMaybe<Examsonline_Topics_Bool_Exp>;
};


export type Subscription_RootExamsonline_Topics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_Topics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Topics_Order_By>>;
  where?: InputMaybe<Examsonline_Topics_Bool_Exp>;
};


export type Subscription_RootExamsonline_Topics_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootExamsonline_Topics_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Examsonline_Topics_Stream_Cursor_Input>>;
  where?: InputMaybe<Examsonline_Topics_Bool_Exp>;
};


export type Subscription_RootExamsonline_UserArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_User_Order_By>>;
  where?: InputMaybe<Examsonline_User_Bool_Exp>;
};


export type Subscription_RootExamsonline_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Examsonline_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_User_Order_By>>;
  where?: InputMaybe<Examsonline_User_Bool_Exp>;
};


export type Subscription_RootExamsonline_User_By_PkArgs = {
  email: Scalars['String']['input'];
};


export type Subscription_RootExamsonline_User_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Examsonline_User_Stream_Cursor_Input>>;
  where?: InputMaybe<Examsonline_User_Bool_Exp>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']['input']>;
  _gt?: InputMaybe<Scalars['timestamp']['input']>;
  _gte?: InputMaybe<Scalars['timestamp']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamp']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamp']['input']>;
  _lte?: InputMaybe<Scalars['timestamp']['input']>;
  _neq?: InputMaybe<Scalars['timestamp']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']['input']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};

export type Update_BillMutationVariables = Exact<{
  id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Scalars['String']['input']>;
}>;


export type Update_BillMutation = { __typename?: 'mutation_root', update_examsonline_bill?: { __typename?: 'examsonline_bill_mutation_response', returning: Array<{ __typename?: 'examsonline_bill', id: any, status: string, amount: any, createdAt: any, createdBy: string, endDate: any, orgId: any, plan: any, startDate: any }> } | null };

export type GetSessionRecordsByAttemptIdQueryVariables = Exact<{
  attemptId?: InputMaybe<Uuid_Comparison_Exp>;
  studentId?: InputMaybe<String_Comparison_Exp>;
}>;


export type GetSessionRecordsByAttemptIdQuery = { __typename?: 'query_root', examsonline_sessionrecord: Array<{ __typename?: 'examsonline_sessionrecord', attemptId?: any | null, id: any, options?: any | null, sessionId: any, studentId: string, questionId: string, selectedOptions: string }> };

export type Update_Test_SectionMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  _eq?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type Update_Test_SectionMutation = { __typename?: 'mutation_root', update_examsonline_test_sections?: { __typename?: 'examsonline_test_sections_mutation_response', returning: Array<{ __typename?: 'examsonline_test_sections', name: string, id: any }> } | null };

export type AddSessionRecordMutationVariables = Exact<{
  objects?: InputMaybe<Array<Examsonline_Sessionrecord_Insert_Input> | Examsonline_Sessionrecord_Insert_Input>;
}>;


export type AddSessionRecordMutation = { __typename?: 'mutation_root', insert_examsonline_sessionrecord?: { __typename?: 'examsonline_sessionrecord_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'examsonline_sessionrecord', selectedOptions: string, createdAt?: any | null, id: any, questionId: string, sessionId: any, studentId: string, textAnswer?: string | null, timeSpent: number, updatedAt?: any | null, options?: any | null }> } | null };

export type SessionRecordByQuesIdQueryVariables = Exact<{
  questionId?: InputMaybe<String_Comparison_Exp>;
  sessionId?: InputMaybe<Uuid_Comparison_Exp>;
}>;


export type SessionRecordByQuesIdQuery = { __typename?: 'query_root', examsonline_sessionrecord: Array<{ __typename?: 'examsonline_sessionrecord', createdAt?: any | null, id: any, questionId: string, selectedOptions: string, sessionId: any, textAnswer?: string | null, timeSpent: number, updatedAt?: any | null, studentId: string, options?: any | null }> };

export type Get_Test_SessionQueryVariables = Exact<{
  testId?: InputMaybe<Int_Comparison_Exp>;
  nameRegex?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
}>;


export type Get_Test_SessionQuery = { __typename?: 'query_root', examsonline_session_aggregate: { __typename?: 'examsonline_session_aggregate', aggregate?: { __typename?: 'examsonline_session_aggregate_fields', count: number } | null }, examsonline_session: Array<{ __typename?: 'examsonline_session', endTime?: any | null, id: any, startTime?: any | null, students?: any | null, testId?: number | null, name?: string | null, allowList?: any | null, negativemarking?: any | null, showResults?: boolean | null, sessionrecords: Array<{ __typename?: 'examsonline_sessionrecord', id: any, options?: any | null, selectedOptions: string }> }> };

export type Create_Test_SessionMutationVariables = Exact<{
  objects?: InputMaybe<Array<Examsonline_Session_Insert_Input> | Examsonline_Session_Insert_Input>;
  update_columns?: InputMaybe<Array<Examsonline_Session_Update_Column> | Examsonline_Session_Update_Column>;
}>;


export type Create_Test_SessionMutation = { __typename?: 'mutation_root', insert_examsonline_session?: { __typename?: 'examsonline_session_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'examsonline_session', allowList?: any | null, endTime?: any | null, id: any, startTime?: any | null, students?: any | null, testId?: number | null, negativemarking?: any | null, showResults?: boolean | null }> } | null };

export type Get_Session_By_IdQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type Get_Session_By_IdQuery = { __typename?: 'query_root', examsonline_session_by_pk?: { __typename?: 'examsonline_session', endTime?: any | null, id: any, startTime?: any | null, students?: any | null, testId?: number | null, name?: string | null, allowList?: any | null, negativemarking?: any | null, showResults?: boolean | null } | null };

export type CommentFragmentFragment = { __typename?: 'examsonline_comments', value: string, user: string, id: any, ticket_id?: any | null, role?: string | null, updatedAt: any, createdAt: any };

export type OptionsFragmentFragment = { __typename?: 'examsonline_options', id: number, value: string, isCorrect: boolean, type: number };

export type QuestionFragmentFragment = { __typename?: 'examsonline_questions', id: number, order: number, text: string, marks: any, explanation?: string | null, test_id?: number | null, type: number, section?: any | null, options: Array<{ __typename?: 'examsonline_options', id: number, value: string, isCorrect: boolean, type: number }> };

export type TestDetailFragmentFragment = { __typename?: 'examsonline_tests', id: number, accesstype: number, author: string, createdAt: any, description: string, document?: string | null, emailnotify: boolean, enabled: boolean, errorMessage: string, maxAttempts: number, isDraft: boolean, name: string, pincode?: string | null, randomise: boolean, showResult: boolean, time: number, price?: any | null, points: any, files: Array<{ __typename?: 'examsonline_file', id: any, info: any }>, test_sections: Array<{ __typename?: 'examsonline_test_sections', name: string, id: any, createdAt?: any | null, questions: Array<{ __typename?: 'examsonline_questions', id: number }> }>, questions: Array<{ __typename?: 'examsonline_questions', id: number, order: number, text: string, marks: any, explanation?: string | null, test_id?: number | null, type: number, section?: any | null, options: Array<{ __typename?: 'examsonline_options', id: number, value: string, isCorrect: boolean, type: number }> }>, topics: Array<{ __typename?: 'examsonline_topics', tag: string }> };

export type FileFragmentFragment = { __typename?: 'examsonline_file', id: any, info: any, name: string, size?: number | null };

export type TopicsFragmentFragment = { __typename?: 'examsonline_topics', id: number, tag: string };

export type TestPreviewFragmentFragment = { __typename?: 'examsonline_tests', id: number, accesstype: number, author: string, createdAt: any, description: string, document?: string | null, emailnotify: boolean, enabled: boolean, errorMessage: string, maxAttempts: number, isDraft: boolean, name: string, randomise: boolean, showResult: boolean, time: number, price?: any | null, files: Array<{ __typename?: 'examsonline_file', id: any, info: any, name: string, size?: number | null }>, test_sections: Array<{ __typename?: 'examsonline_test_sections', name: string, id: any, questions: Array<{ __typename?: 'examsonline_questions', id: number }> }>, topics: Array<{ __typename?: 'examsonline_topics', id: number, tag: string }> };

export type Get_BillsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['date']['input']>;
  _lte?: InputMaybe<Scalars['date']['input']>;
}>;


export type Get_BillsQuery = { __typename?: 'query_root', examsonline_bill: Array<{ __typename?: 'examsonline_bill', id: any, orgId: any, status: string, startDate: any, endDate: any, amount: any, plan: any }> };

export type All_TestQueryVariables = Exact<{ [key: string]: never; }>;


export type All_TestQuery = { __typename?: 'query_root', examsonline_tests: Array<{ __typename?: 'examsonline_tests', author: string, createdAt: any, errorMessage: string, name: string, time: number, topics: Array<{ __typename?: 'examsonline_topics', tag: string }>, questions: Array<{ __typename?: 'examsonline_questions', order: number, text: string }> }> };

export type Get_Test_By_IdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Tests_Order_By> | Examsonline_Tests_Order_By>;
}>;


export type Get_Test_By_IdQuery = { __typename?: 'query_root', examsonline_tests: Array<{ __typename?: 'examsonline_tests', id: number, accesstype: number, author: string, createdAt: any, description: string, document?: string | null, emailnotify: boolean, enabled: boolean, errorMessage: string, maxAttempts: number, isDraft: boolean, name: string, pincode?: string | null, randomise: boolean, showResult: boolean, time: number, price?: any | null, points: any, files: Array<{ __typename?: 'examsonline_file', id: any, info: any }>, test_sections: Array<{ __typename?: 'examsonline_test_sections', name: string, id: any, createdAt?: any | null, questions: Array<{ __typename?: 'examsonline_questions', id: number }> }>, questions: Array<{ __typename?: 'examsonline_questions', id: number, order: number, text: string, marks: any, explanation?: string | null, test_id?: number | null, type: number, section?: any | null, options: Array<{ __typename?: 'examsonline_options', id: number, value: string, isCorrect: boolean, type: number }> }>, topics: Array<{ __typename?: 'examsonline_topics', tag: string }> }> };

export type Get_Test_By_Id_StudentQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type Get_Test_By_Id_StudentQuery = { __typename?: 'query_root', examsonline_tests: Array<{ __typename?: 'examsonline_tests', id: number, accesstype: number, author: string, createdAt: any, description: string, document?: string | null, emailnotify: boolean, enabled: boolean, errorMessage: string, maxAttempts: number, isDraft: boolean, name: string, randomise: boolean, showResult: boolean, time: number, questions: Array<{ __typename?: 'examsonline_questions', id: number, order: number, text: string, marks: any, test_id?: number | null, type: number, section?: any | null, options: Array<{ __typename?: 'examsonline_options', id: number, value: string, type: number }> }>, topics: Array<{ __typename?: 'examsonline_topics', tag: string }> }> };

export type Preview_Test_By_IdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type Preview_Test_By_IdQuery = { __typename?: 'query_root', examsonline_tests: Array<{ __typename?: 'examsonline_tests', id: number, accesstype: number, author: string, createdAt: any, description: string, document?: string | null, emailnotify: boolean, enabled: boolean, errorMessage: string, maxAttempts: number, isDraft: boolean, name: string, randomise: boolean, showResult: boolean, time: number, price?: any | null, topics: Array<{ __typename?: 'examsonline_topics', tag: string, id: number }>, files: Array<{ __typename?: 'examsonline_file', id: any, info: any, name: string, size?: number | null }>, test_sections: Array<{ __typename?: 'examsonline_test_sections', name: string, id: any, questions: Array<{ __typename?: 'examsonline_questions', id: number }> }> }> };

export type Get_Test_Question_Correct_WrongQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['Int']['input']>;
}>;


export type Get_Test_Question_Correct_WrongQuery = { __typename?: 'query_root', examsonline_tests: Array<{ __typename?: 'examsonline_tests', questions: Array<{ __typename?: 'examsonline_questions', id: number, wrong: { __typename?: 'examsonline_answers_aggregate', aggregate?: { __typename?: 'examsonline_answers_aggregate_fields', count: number } | null }, correct: { __typename?: 'examsonline_answers_aggregate', aggregate?: { __typename?: 'examsonline_answers_aggregate_fields', count: number } | null }, skipped: { __typename?: 'examsonline_answers_aggregate', aggregate?: { __typename?: 'examsonline_answers_aggregate_fields', count: number } | null } }> }> };

export type Add_TestMutationVariables = Exact<{
  objects?: InputMaybe<Array<Examsonline_Tests_Insert_Input> | Examsonline_Tests_Insert_Input>;
  update_columns: Array<Examsonline_Tests_Update_Column> | Examsonline_Tests_Update_Column;
}>;


export type Add_TestMutation = { __typename?: 'mutation_root', insert_examsonline_tests?: { __typename?: 'examsonline_tests_mutation_response', returning: Array<{ __typename?: 'examsonline_tests', id: number, name: string, createdAt: any, test_sections: Array<{ __typename?: 'examsonline_test_sections', name: string, id: any }>, topics: Array<{ __typename?: 'examsonline_topics', tag: string, id: number }> }> } | null };

export type Update_TestMutationVariables = Exact<{
  _set?: InputMaybe<Examsonline_Tests_Set_Input>;
  test_id: Scalars['Int']['input'];
}>;


export type Update_TestMutation = { __typename?: 'mutation_root', update_examsonline_tests?: { __typename?: 'examsonline_tests_mutation_response', returning: Array<{ __typename?: 'examsonline_tests', id: number, enabled: boolean, topics: Array<{ __typename?: 'examsonline_topics', tag: string }> }> } | null };

export type Add_AnswersMutationVariables = Exact<{
  objects?: InputMaybe<Array<Examsonline_Answers_Insert_Input> | Examsonline_Answers_Insert_Input>;
  update_columns?: InputMaybe<Array<Examsonline_Answers_Update_Column> | Examsonline_Answers_Update_Column>;
}>;


export type Add_AnswersMutation = { __typename?: 'mutation_root', insert_examsonline_answers?: { __typename?: 'examsonline_answers_mutation_response', returning: Array<{ __typename?: 'examsonline_answers', q_id: number, attempt_id: any }> } | null };

export type Get_AttemptsQueryVariables = Exact<{
  sessionId: Scalars['uuid']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type Get_AttemptsQuery = { __typename?: 'query_root', examsonline_attempt: Array<{ __typename?: 'examsonline_attempt', email: string, test_id: number, createdAt: any, id: any, completed: boolean, percentage: any, correct: number, wrong: number, skipped: number }>, examsonline_attempt_aggregate: { __typename?: 'examsonline_attempt_aggregate', aggregate?: { __typename?: 'examsonline_attempt_aggregate_fields', count: number } | null } };

export type Get_OptionsQueryVariables = Exact<{
  q_id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type Get_OptionsQuery = { __typename?: 'query_root', examsonline_options: Array<{ __typename?: 'examsonline_options', id: number, isCorrect: boolean, q_id: number, value: string, type: number }> };

export type Get_Options_For_StudentQueryVariables = Exact<{
  q_id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type Get_Options_For_StudentQuery = { __typename?: 'query_root', examsonline_options: Array<{ __typename?: 'examsonline_options', id: number, q_id: number, value: string, type: number }> };

export type Get_OrgsQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_OrgsQuery = { __typename?: 'query_root', examsonline_organisation: Array<{ __typename?: 'examsonline_organisation', name: string, id: any }> };

export type Get_QuestionsQueryVariables = Exact<{
  test_id: Scalars['Int']['input'];
}>;


export type Get_QuestionsQuery = { __typename?: 'query_root', examsonline_questions: Array<{ __typename?: 'examsonline_questions', id: number, section?: any | null }> };

export type Get_QuestionQueryVariables = Exact<{
  test_id: Scalars['Int']['input'];
}>;


export type Get_QuestionQuery = { __typename?: 'query_root', examsonline_questions: Array<{ __typename?: 'examsonline_questions', id: number, options: Array<{ __typename?: 'examsonline_options', id: number }> }> };

export type Sub_TestsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type Sub_TestsSubscription = { __typename?: 'subscription_root', examsonline_tests: Array<{ __typename?: 'examsonline_tests', accesstype: number, id: number, author: string, createdAt: any, description: string, emailnotify: boolean, document?: string | null, updatedAt: any, time: number, showResult: boolean, randomise: boolean, name: string, maxAttempts: number, isDraft: boolean, errorMessage: string, enabled: boolean, topics: Array<{ __typename?: 'examsonline_topics', tag: string }> }> };

export type Add_UserMutationVariables = Exact<{
  input: Array<Examsonline_User_Insert_Input> | Examsonline_User_Insert_Input;
}>;


export type Add_UserMutation = { __typename?: 'mutation_root', insert_examsonline_user?: { __typename?: 'examsonline_user_mutation_response', returning: Array<{ __typename?: 'examsonline_user', email: string }> } | null };

export type Delete_Topics_By_Test_IdMutationVariables = Exact<{
  test_id: Scalars['Int']['input'];
}>;


export type Delete_Topics_By_Test_IdMutation = { __typename?: 'mutation_root', delete_examsonline_topics?: { __typename?: 'examsonline_topics_mutation_response', affected_rows: number } | null };

export type Edit_Test_SubsSubscriptionVariables = Exact<{
  test_id: Scalars['Int']['input'];
}>;


export type Edit_Test_SubsSubscription = { __typename?: 'subscription_root', examsonline_tests: Array<{ __typename?: 'examsonline_tests', id: number, questions: Array<{ __typename?: 'examsonline_questions', text: string, id: number }> }> };

export type AttemptQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type AttemptQuery = { __typename?: 'query_root', examsonline_attempt: Array<{ __typename?: 'examsonline_attempt', email: string, id: any, stale: boolean, test_id: number, completed: boolean, createdAt: any }> };

export type Add_TimespentMutationVariables = Exact<{
  objects?: InputMaybe<Array<Examsonline_Timespent_Insert_Input> | Examsonline_Timespent_Insert_Input>;
  update_columns?: InputMaybe<Array<Examsonline_Timespent_Update_Column> | Examsonline_Timespent_Update_Column>;
}>;


export type Add_TimespentMutation = { __typename?: 'mutation_root', insert_examsonline_timespent?: { __typename?: 'examsonline_timespent_mutation_response', returning: Array<{ __typename?: 'examsonline_timespent', attempt_id: any, id: number, q_id: number }> } | null };

export type Get_TimespentQueryVariables = Exact<{
  attempt_id: Scalars['uuid']['input'];
  q_id: Scalars['Int']['input'];
}>;


export type Get_TimespentQuery = { __typename?: 'query_root', examsonline_timespent: Array<{ __typename?: 'examsonline_timespent', time: any, q_id: number, attempt_id: any, id: number }> };

export type Get_Timespent_AttemptQueryVariables = Exact<{
  attempt_id: Scalars['uuid']['input'];
}>;


export type Get_Timespent_AttemptQuery = { __typename?: 'query_root', examsonline_timespent: Array<{ __typename?: 'examsonline_timespent', time: any, q_id: number, attempt_id: any, id: number }> };

export type Get_ResultsQueryVariables = Exact<{
  test_id?: InputMaybe<Int_Comparison_Exp>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
}>;


export type Get_ResultsQuery = { __typename?: 'query_root', examsonline_attempt: Array<{ __typename?: 'examsonline_attempt', id: any, email: string, createdAt: any, updatedAt?: any | null, completed: boolean, test_id: number, skipped: number, correct: number, wrong: number, percentage: any, timespents_aggregate: { __typename?: 'examsonline_timespent_aggregate', aggregate?: { __typename?: 'examsonline_timespent_aggregate_fields', avg?: { __typename?: 'examsonline_timespent_avg_fields', time?: number | null } | null } | null } }> };

export type Search_Test_By_TagQueryVariables = Exact<{
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _iregex1?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type Search_Test_By_TagQuery = { __typename?: 'query_root', examsonline_topics: Array<{ __typename?: 'examsonline_topics', test?: { __typename?: 'examsonline_tests', id: number, name: string, author: string, time: number, enabled: boolean, createdAt: any, description: string, accesstype: number, errorMessage: string, isDraft: boolean, price?: any | null, points: any, topics: Array<{ __typename?: 'examsonline_topics', tag: string }>, questions_aggregate: { __typename?: 'examsonline_questions_aggregate', aggregate?: { __typename?: 'examsonline_questions_aggregate_fields', count: number } | null }, files: Array<{ __typename?: 'examsonline_file', id: any, info: any }>, orders_aggregate: { __typename?: 'examsonline_order_aggregate', aggregate?: { __typename?: 'examsonline_order_aggregate_fields', count: number } | null } } | null }>, total_count: { __typename?: 'examsonline_topics_aggregate', aggregate?: { __typename?: 'examsonline_topics_aggregate_fields', count: number } | null } };

export type Get_Avg_Timespent_By_QuesQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['Int']['input']>;
}>;


export type Get_Avg_Timespent_By_QuesQuery = { __typename?: 'query_root', examsonline_tests: Array<{ __typename?: 'examsonline_tests', questions: Array<{ __typename?: 'examsonline_questions', timespents_aggregate: { __typename?: 'examsonline_timespent_aggregate', aggregate?: { __typename?: 'examsonline_timespent_aggregate_fields', avg?: { __typename?: 'examsonline_timespent_avg_fields', time?: number | null } | null } | null } }> }> };

export type Delete_QuestionMutationVariables = Exact<{
  _eq?: InputMaybe<Scalars['Int']['input']>;
}>;


export type Delete_QuestionMutation = { __typename?: 'mutation_root', delete_examsonline_questions?: { __typename?: 'examsonline_questions_mutation_response', returning: Array<{ __typename?: 'examsonline_questions', id: number, order: number, text: string, marks: any, test_id?: number | null, type: number, section?: any | null, options: Array<{ __typename?: 'examsonline_options', id: number, value: string, isCorrect: boolean, type: number }> }> } | null };

export type Get_Total_Users_CountQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_Total_Users_CountQuery = { __typename?: 'query_root', examsonline_user_aggregate: { __typename?: 'examsonline_user_aggregate', aggregate?: { __typename?: 'examsonline_user_aggregate_fields', count: number } | null } };

export type Get_App_Test_CountQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_App_Test_CountQuery = { __typename?: 'query_root', examsonline_tests_aggregate: { __typename?: 'examsonline_tests_aggregate', aggregate?: { __typename?: 'examsonline_tests_aggregate_fields', count: number } | null } };

export type Get_Org_CountQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_Org_CountQuery = { __typename?: 'query_root', examsonline_organisation_aggregate: { __typename?: 'examsonline_organisation_aggregate', aggregate?: { __typename?: 'examsonline_organisation_aggregate_fields', count: number } | null } };

export type Upsert_QuestionsMutationVariables = Exact<{
  objects?: InputMaybe<Array<Examsonline_Questions_Insert_Input> | Examsonline_Questions_Insert_Input>;
}>;


export type Upsert_QuestionsMutation = { __typename?: 'mutation_root', insert_examsonline_questions?: { __typename?: 'examsonline_questions_mutation_response', returning: Array<{ __typename?: 'examsonline_questions', createdAt?: any | null, id: number, marks: any, order: number, test_id?: number | null, quiz_id?: any | null, text: string, type: number, section?: any | null, explanation?: string | null, options: Array<{ __typename?: 'examsonline_options', id: number, isCorrect: boolean, q_id: number, type: number, value: string }> }> } | null };

export type Add_Initial_QuestionMutationVariables = Exact<{
  text: Scalars['String']['input'];
  options?: InputMaybe<Examsonline_Options_Arr_Rel_Insert_Input>;
  order?: InputMaybe<Scalars['Int']['input']>;
  section?: InputMaybe<Scalars['uuid']['input']>;
  quiz_id?: InputMaybe<Scalars['uuid']['input']>;
  test_id: Scalars['Int']['input'];
}>;


export type Add_Initial_QuestionMutation = { __typename?: 'mutation_root', insert_examsonline_questions?: { __typename?: 'examsonline_questions_mutation_response', returning: Array<{ __typename?: 'examsonline_questions', id: number, text: string, order: number, section?: any | null, options: Array<{ __typename?: 'examsonline_options', id: number, value: string, isCorrect: boolean, type: number }> }> } | null };

export type Upsert_OptionMutationVariables = Exact<{
  objects: Array<Examsonline_Options_Insert_Input> | Examsonline_Options_Insert_Input;
  update_columns?: InputMaybe<Array<Examsonline_Options_Update_Column> | Examsonline_Options_Update_Column>;
}>;


export type Upsert_OptionMutation = { __typename?: 'mutation_root', insert_examsonline_options?: { __typename?: 'examsonline_options_mutation_response', returning: Array<{ __typename?: 'examsonline_options', id: number, q_id: number, isCorrect: boolean, type: number, value: string }> } | null };

export type Get_All_Tests_By_AuthorsQueryVariables = Exact<{
  _iregex?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Tests_Order_By> | Examsonline_Tests_Order_By>;
  _eq?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Boolean_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
}>;


export type Get_All_Tests_By_AuthorsQuery = { __typename?: 'query_root', examsonline_tests: Array<{ __typename?: 'examsonline_tests', author: string, createdAt: any, description: string, document?: string | null, maxAttempts: number, pincode?: string | null, id: number, time: number, enabled: boolean, updatedAt: any, isDraft: boolean, points: any, name: string, attempts_aggregate: { __typename?: 'examsonline_attempt_aggregate', aggregate?: { __typename?: 'examsonline_attempt_aggregate_fields', count: number } | null }, questions: Array<{ __typename?: 'examsonline_questions', id: number }>, topics: Array<{ __typename?: 'examsonline_topics', tag: string }>, files: Array<{ __typename?: 'examsonline_file', id: any, info: any }> }>, examsonline_tests_aggregate: { __typename?: 'examsonline_tests_aggregate', aggregate?: { __typename?: 'examsonline_tests_aggregate_fields', count: number } | null } };

export type Update_Option_By_IdMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  _set: Examsonline_Options_Set_Input;
}>;


export type Update_Option_By_IdMutation = { __typename?: 'mutation_root', update_examsonline_options?: { __typename?: 'examsonline_options_mutation_response', returning: Array<{ __typename?: 'examsonline_options', id: number, isCorrect: boolean, value: string }> } | null };

export type TestcountQueryVariables = Exact<{
  author: Scalars['String']['input'];
}>;


export type TestcountQuery = { __typename?: 'query_root', examsonline_tests_aggregate: { __typename?: 'examsonline_tests_aggregate', aggregate?: { __typename?: 'examsonline_tests_aggregate_fields', count: number } | null } };

export type Add_Selected_OptionMutationVariables = Exact<{
  object?: InputMaybe<Examsonline_Selected_Options_Insert_Input>;
  update_columns?: InputMaybe<Array<Examsonline_Selected_Options_Update_Column> | Examsonline_Selected_Options_Update_Column>;
}>;


export type Add_Selected_OptionMutation = { __typename?: 'mutation_root', insert_examsonline_selected_options_one?: { __typename?: 'examsonline_selected_options', attempt_id: any, option_id: number, type?: number | null, value?: string | null } | null };

export type Delete_Selected_OptionsMutationVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _eq1?: InputMaybe<Scalars['Int']['input']>;
}>;


export type Delete_Selected_OptionsMutation = { __typename?: 'mutation_root', delete_examsonline_selected_options?: { __typename?: 'examsonline_selected_options_mutation_response', returning: Array<{ __typename?: 'examsonline_selected_options', attempt_id: any, option_id: number }> } | null };

export type Get_User_InfoQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['String']['input']>;
}>;


export type Get_User_InfoQuery = { __typename?: 'query_root', examsonline_user: Array<{ __typename?: 'examsonline_user', email: string, username: string, tourCompleted?: boolean | null, role: string, id?: any | null, organisation: { __typename?: 'examsonline_organisation', id: any, name: string }, roles: Array<{ __typename?: 'examsonline_roles', id: any, role: string, organisation: { __typename?: 'examsonline_organisation', id: any, name: string } }> }> };

export type Delete_OptionMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type Delete_OptionMutation = { __typename?: 'mutation_root', delete_examsonline_options_by_pk?: { __typename?: 'examsonline_options', id: number } | null };

export type Delete_Options_By_QidMutationVariables = Exact<{
  _eq?: InputMaybe<Scalars['Int']['input']>;
}>;


export type Delete_Options_By_QidMutation = { __typename?: 'mutation_root', delete_examsonline_options?: { __typename?: 'examsonline_options_mutation_response', returning: Array<{ __typename?: 'examsonline_options', id: number }> } | null };

export type Get_RatingQueryVariables = Exact<{
  _eq1?: InputMaybe<Scalars['Int']['input']>;
}>;


export type Get_RatingQuery = { __typename?: 'query_root', examsonline_rating: Array<{ __typename?: 'examsonline_rating', id: any, test_id: number, value: any }> };

export type Add_RatingMutationVariables = Exact<{
  objects?: InputMaybe<Array<Examsonline_Rating_Insert_Input> | Examsonline_Rating_Insert_Input>;
}>;


export type Add_RatingMutation = { __typename?: 'mutation_root', insert_examsonline_rating?: { __typename?: 'examsonline_rating_mutation_response', returning: Array<{ __typename?: 'examsonline_rating', id: any, test_id: number, value: any }> } | null };

export type Get_Selected_OptionsQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _eq1?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type Get_Selected_OptionsQuery = { __typename?: 'query_root', examsonline_selected_options: Array<{ __typename?: 'examsonline_selected_options', value?: string | null, type?: number | null, attempt_id: any, id: number }> };

export type Get_Answer_SheetQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type Get_Answer_SheetQuery = { __typename?: 'query_root', examsonline_answers: Array<{ __typename?: 'examsonline_answers', id?: any | null, score?: number | null, type?: number | null, attempt: { __typename?: 'examsonline_attempt', test_id: number, email: string }, question: { __typename?: 'examsonline_questions', id: number, marks: any, order: number, type: number, text: string, section?: any | null, answers: Array<{ __typename?: 'examsonline_answers', score?: number | null }>, selected_options: Array<{ __typename?: 'examsonline_selected_options', value?: string | null, type?: number | null }> } }> };

export type Get_All_Selected_OptionsQueryVariables = Exact<{
  _eq1?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type Get_All_Selected_OptionsQuery = { __typename?: 'query_root', examsonline_selected_options: Array<{ __typename?: 'examsonline_selected_options', value?: string | null, type?: number | null, attempt_id: any, isCorrect?: boolean | null, id: number }> };

export type Get_Top_StudentsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  _eq2?: InputMaybe<Scalars['Int']['input']>;
  _perc_order_by?: InputMaybe<Order_By>;
  _correct_order_by?: InputMaybe<Order_By>;
}>;


export type Get_Top_StudentsQuery = { __typename?: 'query_root', examsonline_attempt: Array<{ __typename?: 'examsonline_attempt', percentage: any, email: string, createdAt: any, correct: number, skipped: number, wrong: number, count: { __typename?: 'examsonline_answers_aggregate', aggregate?: { __typename?: 'examsonline_answers_aggregate_fields', count: number } | null } }> };

export type Update_ScoreMutationVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _eq1?: InputMaybe<Scalars['Int']['input']>;
  score?: InputMaybe<Scalars['Int']['input']>;
}>;


export type Update_ScoreMutation = { __typename?: 'mutation_root', update_examsonline_answers?: { __typename?: 'examsonline_answers_mutation_response', returning: Array<{ __typename?: 'examsonline_answers', score?: number | null }> } | null };

export type Search_QuestionsQueryVariables = Exact<{
  _iregex1?: InputMaybe<Scalars['String']['input']>;
  _iregex2?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type Search_QuestionsQuery = { __typename?: 'query_root', examsonline_questions: Array<{ __typename?: 'examsonline_questions', id: number, marks: any, type: number, text: string, options: Array<{ __typename?: 'examsonline_options', id: number, value: string, isCorrect: boolean, type: number }>, test?: { __typename?: 'examsonline_tests', name: string, author: string, ratings_aggregate: { __typename?: 'examsonline_rating_aggregate', aggregate?: { __typename?: 'examsonline_rating_aggregate_fields', avg?: { __typename?: 'examsonline_rating_avg_fields', value?: number | null } | null } | null } } | null }>, total_count: { __typename?: 'examsonline_questions_aggregate', aggregate?: { __typename?: 'examsonline_questions_aggregate_fields', count: number } | null } };

export type Update_PassMutationVariables = Exact<{
  email?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
}>;


export type Update_PassMutation = { __typename?: 'mutation_root', update_examsonline_user?: { __typename?: 'examsonline_user_mutation_response', returning: Array<{ __typename?: 'examsonline_user', email: string }> } | null };

export type Search_Questions_By_TagQueryVariables = Exact<{
  _ilike?: InputMaybe<Scalars['String']['input']>;
}>;


export type Search_Questions_By_TagQuery = { __typename?: 'query_root', examsonline_questions: Array<{ __typename?: 'examsonline_questions', text: string, id: number, author?: { __typename?: 'examsonline_tests', author: string } | null, incorrect_count: { __typename?: 'examsonline_answers_aggregate', aggregate?: { __typename?: 'examsonline_answers_aggregate_fields', count: number } | null }, correct_count: { __typename?: 'examsonline_answers_aggregate', aggregate?: { __typename?: 'examsonline_answers_aggregate_fields', count: number } | null } }> };

export type Register_UserMutationVariables = Exact<{
  email?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  org1?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type Register_UserMutation = { __typename?: 'mutation_root', insert_examsonline_user?: { __typename?: 'examsonline_user_mutation_response', returning: Array<{ __typename?: 'examsonline_user', email: string }> } | null };

export type Add_SectionMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  test_id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type Add_SectionMutation = { __typename?: 'mutation_root', insert_examsonline_test_sections?: { __typename?: 'examsonline_test_sections_mutation_response', returning: Array<{ __typename?: 'examsonline_test_sections', name: string, id: any, test_id?: number | null }> } | null };

export type Get_CommentsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  _eq?: InputMaybe<Scalars['Int']['input']>;
  ticket_id?: InputMaybe<Scalars['uuid']['input']>;
  asg_id?: InputMaybe<Scalars['uuid']['input']>;
  ref_id?: InputMaybe<Scalars['String']['input']>;
}>;


export type Get_CommentsQuery = { __typename?: 'query_root', examsonline_comments: Array<{ __typename?: 'examsonline_comments', value: string, user: string, id: any, ticket_id?: any | null, role?: string | null, updatedAt: any, createdAt: any }>, total_count: { __typename?: 'examsonline_comments_aggregate', aggregate?: { __typename?: 'examsonline_comments_aggregate_fields', count: number } | null } };

export type Add_CommentMutationVariables = Exact<{
  objects?: InputMaybe<Array<Examsonline_Comments_Insert_Input> | Examsonline_Comments_Insert_Input>;
  update_columns?: InputMaybe<Array<Examsonline_Comments_Update_Column> | Examsonline_Comments_Update_Column>;
}>;


export type Add_CommentMutation = { __typename?: 'mutation_root', insert_examsonline_comments?: { __typename?: 'examsonline_comments_mutation_response', returning: Array<{ __typename?: 'examsonline_comments', asg_id?: any | null, id: any, ref_id?: string | null, test_id?: number | null, ticket_id?: any | null, updatedAt: any, user: string, value: string }> } | null };

export type Delete_TestMutationVariables = Exact<{
  _eq?: InputMaybe<Scalars['Int']['input']>;
}>;


export type Delete_TestMutation = { __typename?: 'mutation_root', delete_examsonline_tests?: { __typename?: 'examsonline_tests_mutation_response', returning: Array<{ __typename?: 'examsonline_tests', id: number }> } | null };

export type Update_TourMutationVariables = Exact<{
  _eq?: InputMaybe<Scalars['String']['input']>;
  tourCompleted?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type Update_TourMutation = { __typename?: 'mutation_root', update_examsonline_user?: { __typename?: 'examsonline_user_mutation_response', returning: Array<{ __typename?: 'examsonline_user', tourCompleted?: boolean | null, email: string }> } | null };

export type Update_Question_SectionMutationVariables = Exact<{
  section?: InputMaybe<Scalars['uuid']['input']>;
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _eq1?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type Update_Question_SectionMutation = { __typename?: 'mutation_root', update_examsonline_questions?: { __typename?: 'examsonline_questions_mutation_response', returning: Array<{ __typename?: 'examsonline_questions', id: number, section?: any | null }> } | null };

export type Update_Draft_StatusMutationVariables = Exact<{
  _eq?: InputMaybe<Scalars['Int']['input']>;
  isDraft?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type Update_Draft_StatusMutation = { __typename?: 'mutation_root', update_examsonline_tests?: { __typename?: 'examsonline_tests_mutation_response', returning: Array<{ __typename?: 'examsonline_tests', isDraft: boolean, id: number }> } | null };

export type Get_Banner_NotificationQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  _iregex?: InputMaybe<Scalars['String']['input']>;
}>;


export type Get_Banner_NotificationQuery = { __typename?: 'query_root', examsonline_banner_notification: Array<{ __typename?: 'examsonline_banner_notification', id: any, message: string, type?: string | null, group?: { __typename?: 'examsonline_groups', name: string } | null, organisation?: { __typename?: 'examsonline_organisation', name: string, id: any } | null, role?: { __typename?: 'examsonline_roles', user: string } | null }>, examsonline_banner_notification_aggregate: { __typename?: 'examsonline_banner_notification_aggregate', aggregate?: { __typename?: 'examsonline_banner_notification_aggregate_fields', count: number } | null } };

export type Add_QuizMutationVariables = Exact<{
  objects?: InputMaybe<Array<Examsonline_Quiz_Insert_Input> | Examsonline_Quiz_Insert_Input>;
  update_columns: Array<Examsonline_Quiz_Update_Column> | Examsonline_Quiz_Update_Column;
}>;


export type Add_QuizMutation = { __typename?: 'mutation_root', insert_examsonline_quiz?: { __typename?: 'examsonline_quiz_mutation_response', returning: Array<{ __typename?: 'examsonline_quiz', id: any, name: string, createdAt: any }> } | null };

export type Add_Contact_UsMutationVariables = Exact<{
  contact?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
}>;


export type Add_Contact_UsMutation = { __typename?: 'mutation_root', insert_examsonline_contactus?: { __typename?: 'examsonline_contactus_mutation_response', returning: Array<{ __typename?: 'examsonline_contactus', id: any }> } | null };

export type Get_Org_UsersQueryVariables = Exact<{
  _iregex?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  _eq?: InputMaybe<Scalars['String']['input']>;
}>;


export type Get_Org_UsersQuery = { __typename?: 'query_root', examsonline_organisation: Array<{ __typename?: 'examsonline_organisation', roles: Array<{ __typename?: 'examsonline_roles', id: any, org_id: any, role: string, user: string, createdAt: any, active: boolean, userByUser: { __typename?: 'examsonline_user', username: string } }>, total: { __typename?: 'examsonline_roles_aggregate', aggregate?: { __typename?: 'examsonline_roles_aggregate_fields', count: number } | null } }> };

export type Get_Org_Name_PublicQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type Get_Org_Name_PublicQuery = { __typename?: 'query_root', examsonline_organisation: Array<{ __typename?: 'examsonline_organisation', id: any, name: string }> };

export type Get_Quiz_By_IdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']['input']>;
  order_by?: InputMaybe<Array<Examsonline_Quiz_Order_By> | Examsonline_Quiz_Order_By>;
}>;


export type Get_Quiz_By_IdQuery = { __typename?: 'query_root', examsonline_quiz: Array<{ __typename?: 'examsonline_quiz', id: any, accesstype: number, author: string, createdAt: any, description: string, document?: string | null, emailnotify: boolean, enabled: boolean, errorMessage: string, maxAttempts: number, isDraft: boolean, name: string, pincode?: string | null, randomise: boolean, showResult: boolean, time: number, test_sections: Array<{ __typename?: 'examsonline_test_sections', id: any, name: string }>, topics: Array<{ __typename?: 'examsonline_topics', tag: string }> }> };

export type Get_All_QuizQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  _iregex?: InputMaybe<Scalars['String']['input']>;
}>;


export type Get_All_QuizQuery = { __typename?: 'query_root', examsonline_quiz: Array<{ __typename?: 'examsonline_quiz', author: string, createdAt: any, description: string, document?: string | null, emailnotify: boolean, enabled: boolean, isDraft: boolean, maxAttempts: number, name: string, pincode?: string | null, randomise: boolean, showResult: boolean, time: number, updatedAt: any, errorMessage: string, id: any, questions: Array<{ __typename?: 'examsonline_questions', id: number, marks: any, order: number, quiz_id?: any | null, section?: any | null, text: string, options: Array<{ __typename?: 'examsonline_options', id: number, isCorrect: boolean, q_id: number }> }> }> };

export type Add_GroupMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type Add_GroupMutation = { __typename?: 'mutation_root', insert_examsonline_groups?: { __typename?: 'examsonline_groups_mutation_response', returning: Array<{ __typename?: 'examsonline_groups', id: any, name: string, user: string, updatedAt: any, description?: string | null, public: boolean }> } | null };

export type Get_GroupsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  _iregex?: InputMaybe<Scalars['String']['input']>;
}>;


export type Get_GroupsQuery = { __typename?: 'query_root', examsonline_groups: Array<{ __typename?: 'examsonline_groups', id: any, name: string, user: string, createdAt: any, updatedAt: any, members_aggregate: { __typename?: 'examsonline_members_aggregate', aggregate?: { __typename?: 'examsonline_members_aggregate_fields', count: number } | null }, files: Array<{ __typename?: 'examsonline_file', info: any }>, assignments_aggregate: { __typename?: 'examsonline_assignments_aggregate', aggregate?: { __typename?: 'examsonline_assignments_aggregate_fields', count: number } | null } }>, total: { __typename?: 'examsonline_groups_aggregate', aggregate?: { __typename?: 'examsonline_groups_aggregate_fields', count: number } | null } };

export type Get_Group_By_IdQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type Get_Group_By_IdQuery = { __typename?: 'query_root', examsonline_groups: Array<{ __typename?: 'examsonline_groups', id: any, name: string, updatedAt: any, createdAt: any, user: string, members_aggregate: { __typename?: 'examsonline_members_aggregate', aggregate?: { __typename?: 'examsonline_members_aggregate_fields', count: number } | null }, files: Array<{ __typename?: 'examsonline_file', info: any }> }> };

export type Delete_Quiz_By_IdMutationVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type Delete_Quiz_By_IdMutation = { __typename?: 'mutation_root', delete_examsonline_quiz?: { __typename?: 'examsonline_quiz_mutation_response', returning: Array<{ __typename?: 'examsonline_quiz', id: any }> } | null };

export type Create_AssignmentsMutationVariables = Exact<{
  quiz_id?: InputMaybe<Scalars['uuid']['input']>;
  test_id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['timestamptz']['input']>;
  endDate?: InputMaybe<Scalars['timestamptz']['input']>;
}>;


export type Create_AssignmentsMutation = { __typename?: 'mutation_root', insert_examsonline_assignments?: { __typename?: 'examsonline_assignments_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'examsonline_assignments', id: any }> } | null };

export type Delete_Group_By_IdMutationVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type Delete_Group_By_IdMutation = { __typename?: 'mutation_root', delete_examsonline_groups?: { __typename?: 'examsonline_groups_mutation_response', returning: Array<{ __typename?: 'examsonline_groups', id: any }> } | null };

export type Add_Member_To_GroupMutationVariables = Exact<{
  email?: InputMaybe<Scalars['String']['input']>;
  group_id?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type Add_Member_To_GroupMutation = { __typename?: 'mutation_root', insert_examsonline_members?: { __typename?: 'examsonline_members_mutation_response', returning: Array<{ __typename?: 'examsonline_members', email: string, group_id: any, id: any, updatedAt: any, createdAt: any }> } | null };

export type Assign_Test_To_GroupMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  test_id?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['timestamptz']['input']>;
  endDate?: InputMaybe<Scalars['timestamptz']['input']>;
  group?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type Assign_Test_To_GroupMutation = { __typename?: 'mutation_root', insert_examsonline_assignments?: { __typename?: 'examsonline_assignments_mutation_response', returning: Array<{ __typename?: 'examsonline_assignments', id: any, startDate: any, endDate: any, test_id?: number | null, name: string, group: any }> } | null };

export type Upcoming_AssignmentsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  _iregex?: InputMaybe<Scalars['String']['input']>;
}>;


export type Upcoming_AssignmentsQuery = { __typename?: 'query_root', examsonline_assignments: Array<{ __typename?: 'examsonline_assignments', createdAt: any, endDate: any, id: any, name: string, startDate: any, test_id?: number | null, user: string }>, total: { __typename?: 'examsonline_assignments_aggregate', aggregate?: { __typename?: 'examsonline_assignments_aggregate_fields', count: number } | null } };

export type Get_All_Members_Of_GroupQueryVariables = Exact<{
  _iregex?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  _eq?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type Get_All_Members_Of_GroupQuery = { __typename?: 'query_root', examsonline_members: Array<{ __typename?: 'examsonline_members', createdAt: any, email: string, group_id: any, id: any, updatedAt: any }>, total: { __typename?: 'examsonline_members_aggregate', aggregate?: { __typename?: 'examsonline_members_aggregate_fields', count: number } | null } };

export type Delete_Member_By_IdMutationVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type Delete_Member_By_IdMutation = { __typename?: 'mutation_root', delete_examsonline_members?: { __typename?: 'examsonline_members_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'examsonline_members', createdAt: any, email: string, id: any, group_id: any, updatedAt: any }> } | null };

export type Get_Total_Submissions_For_AuthorQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type Get_Total_Submissions_For_AuthorQuery = { __typename?: 'query_root', examsonline_attempt_aggregate: { __typename?: 'examsonline_attempt_aggregate', aggregate?: { __typename?: 'examsonline_attempt_aggregate_fields', count: number } | null } };

export type Group_MembershipsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type Group_MembershipsQuery = { __typename?: 'query_root', examsonline_members: Array<{ __typename?: 'examsonline_members', createdAt: any, email: string, group_id: any, id: any, user: string, group: { __typename?: 'examsonline_groups', name: string, id: any, user: string } }> };

export type Get_My_AssignmentsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  _iregex?: InputMaybe<Scalars['String']['input']>;
}>;


export type Get_My_AssignmentsQuery = { __typename?: 'query_root', examsonline_assignments: Array<{ __typename?: 'examsonline_assignments', name: string, createdAt: any, endDate: any, group: any, id: any, quiz_id?: any | null, startDate: any, test_id?: number | null, type?: string | null, user: string, comments_aggregate: { __typename?: 'examsonline_comments_aggregate', aggregate?: { __typename?: 'examsonline_comments_aggregate_fields', count: number } | null }, groupByGroup: { __typename?: 'examsonline_groups', id: any, name: string }, attempts: Array<{ __typename?: 'examsonline_attempt', completed: boolean, createdAt: any, email: string, correct: number, asg_id?: any | null, percentage: any, id: any, quiz_id?: any | null, scheduleid?: number | null, stale: boolean, test_id: number, updatedAt?: any | null, wrong: number, skipped: number }> }>, total: { __typename?: 'examsonline_assignments_aggregate', aggregate?: { __typename?: 'examsonline_assignments_aggregate_fields', count: number } | null } };

export type Get_All_Support_TicketsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  _iregex1?: InputMaybe<Scalars['String']['input']>;
}>;


export type Get_All_Support_TicketsQuery = { __typename?: 'query_root', examsonline_tickets: Array<{ __typename?: 'examsonline_tickets', assigned?: string | null, createdAt: any, createdBy?: string | null, id: any, description: string, name: string, status: string, updatedAt: any, org_id?: any | null, user?: { __typename?: 'examsonline_user', email: string, role: string, organisation: { __typename?: 'examsonline_organisation', id: any, name: string, plan: string, email?: string | null, country?: string | null, activated?: boolean | null } } | null }>, total: { __typename?: 'examsonline_tickets_aggregate', aggregate?: { __typename?: 'examsonline_tickets_aggregate_fields', count: number } | null } };

export type Get_All_Orgs_By_CountQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type Get_All_Orgs_By_CountQuery = { __typename?: 'query_root', examsonline_organisation: Array<{ __typename?: 'examsonline_organisation', activated?: boolean | null, country?: string | null, createdAt?: any | null, email?: string | null, id: any, name: string, phone?: any | null, plan: string, updatedAt?: any | null, total_users: { __typename?: 'examsonline_user_aggregate', aggregate?: { __typename?: 'examsonline_user_aggregate_fields', count: number } | null } }> };

export type Support_StatsQueryVariables = Exact<{ [key: string]: never; }>;


export type Support_StatsQuery = { __typename?: 'query_root', examsonline_organisation_aggregate: { __typename?: 'examsonline_organisation_aggregate', aggregate?: { __typename?: 'examsonline_organisation_aggregate_fields', count: number } | null }, examsonline_tickets_aggregate: { __typename?: 'examsonline_tickets_aggregate', aggregate?: { __typename?: 'examsonline_tickets_aggregate_fields', count: number } | null }, examsonline_user_aggregate: { __typename?: 'examsonline_user_aggregate', aggregate?: { __typename?: 'examsonline_user_aggregate_fields', count: number } | null } };

export type Group_DetailsQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type Group_DetailsQuery = { __typename?: 'query_root', examsonline_groups: Array<{ __typename?: 'examsonline_groups', id: any, name: string, updatedAt: any, user: string, createdAt: any, public: boolean, members_aggregate: { __typename?: 'examsonline_members_aggregate', aggregate?: { __typename?: 'examsonline_members_aggregate_fields', count: number } | null }, assignments_aggregate: { __typename?: 'examsonline_assignments_aggregate', aggregate?: { __typename?: 'examsonline_assignments_aggregate_fields', count: number } | null }, files: Array<{ __typename?: 'examsonline_file', id: any, info: any }> }> };

export type Group_AssignmentsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _iregex?: InputMaybe<Scalars['String']['input']>;
}>;


export type Group_AssignmentsQuery = { __typename?: 'query_root', examsonline_assignments: Array<{ __typename?: 'examsonline_assignments', id: any, name: string, startDate: any, test_id?: number | null, createdAt: any, endDate: any, group: any, comments_aggregate: { __typename?: 'examsonline_comments_aggregate', aggregate?: { __typename?: 'examsonline_comments_aggregate_fields', count: number } | null }, attempts_aggregate: { __typename?: 'examsonline_attempt_aggregate', aggregate?: { __typename?: 'examsonline_attempt_aggregate_fields', count: number } | null }, groupByGroup: { __typename?: 'examsonline_groups', members_aggregate: { __typename?: 'examsonline_members_aggregate', aggregate?: { __typename?: 'examsonline_members_aggregate_fields', count: number } | null } } }> };

export type Author_Groups_ListQueryVariables = Exact<{ [key: string]: never; }>;


export type Author_Groups_ListQuery = { __typename?: 'query_root', examsonline_groups: Array<{ __typename?: 'examsonline_groups', name: string, id: any, createdAt: any }> };

export type Get_Total_Role_CountQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_Total_Role_CountQuery = { __typename?: 'query_root', examsonline_user_aggregate: { __typename?: 'examsonline_user_aggregate', aggregate?: { __typename?: 'examsonline_user_aggregate_fields', count: number } | null }, total_author: { __typename?: 'examsonline_user_aggregate', aggregate?: { __typename?: 'examsonline_user_aggregate_fields', count: number } | null } };

export type Get_Total_GroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_Total_GroupsQuery = { __typename?: 'query_root', examsonline_groups_aggregate: { __typename?: 'examsonline_groups_aggregate', aggregate?: { __typename?: 'examsonline_groups_aggregate_fields', count: number } | null } };

export type Get_All_UsersQueryVariables = Exact<{
  _iregex?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type Get_All_UsersQuery = { __typename?: 'query_root', examsonline_user: Array<{ __typename?: 'examsonline_user', email: string, id?: any | null, role: string }>, total: { __typename?: 'examsonline_user_aggregate', aggregate?: { __typename?: 'examsonline_user_aggregate_fields', count: number } | null } };

export type Get_Student_Results_By_EmailQueryVariables = Exact<{
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  _iregex?: InputMaybe<Scalars['String']['input']>;
}>;


export type Get_Student_Results_By_EmailQuery = { __typename?: 'query_root', examsonline_attempt: Array<{ __typename?: 'examsonline_attempt', id: any, email: string, createdAt: any, updatedAt?: any | null, completed: boolean, test_id: number, skipped: number, correct: number, wrong: number, percentage: any, test: { __typename?: 'examsonline_tests', author: string, name: string, id: number } }>, total: { __typename?: 'examsonline_attempt_aggregate', aggregate?: { __typename?: 'examsonline_attempt_aggregate_fields', count: number } | null } };

export type Get_All_OrgQueryVariables = Exact<{
  _iregex?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type Get_All_OrgQuery = { __typename?: 'query_root', examsonline_organisation: Array<{ __typename?: 'examsonline_organisation', name: string, email?: string | null, id: any, plan: string, phone?: any | null }>, total: { __typename?: 'examsonline_organisation_aggregate', aggregate?: { __typename?: 'examsonline_organisation_aggregate_fields', count: number } | null } };

export type Get_Ticket_DetailsQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type Get_Ticket_DetailsQuery = { __typename?: 'query_root', examsonline_tickets: Array<{ __typename?: 'examsonline_tickets', assigned?: string | null, createdAt: any, createdBy?: string | null, description: string, name: string, id: any, org_id?: any | null, status: string, updatedAt: any, user?: { __typename?: 'examsonline_user', email: string, activated: boolean, organisation: { __typename?: 'examsonline_organisation', name: string, id: any } } | null, comments: Array<{ __typename?: 'examsonline_comments', value: string, user: string, id: any, ticket_id?: any | null, role?: string | null, updatedAt: any, createdAt: any }> }> };

export type Get_Issue_DetailsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  _iregex?: InputMaybe<Scalars['String']['input']>;
}>;


export type Get_Issue_DetailsQuery = { __typename?: 'query_root', examsonline_tickets: Array<{ __typename?: 'examsonline_tickets', createdAt: any, description: string, status: string, id: any, name: string, updatedAt: any }>, total: { __typename?: 'examsonline_tickets_aggregate', aggregate?: { __typename?: 'examsonline_tickets_aggregate_fields', count: number } | null } };

export type Get_Tickets_DescriptionQueryVariables = Exact<{
  _iregex?: InputMaybe<Scalars['String']['input']>;
}>;


export type Get_Tickets_DescriptionQuery = { __typename?: 'query_root', examsonline_tickets: Array<{ __typename?: 'examsonline_tickets', description: string, name: string, createdAt: any, createdBy?: string | null }> };

export type Create_TicketMutationVariables = Exact<{
  description?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type Create_TicketMutation = { __typename?: 'mutation_root', insert_examsonline_tickets?: { __typename?: 'examsonline_tickets_mutation_response', returning: Array<{ __typename?: 'examsonline_tickets', id: any, updatedAt: any, createdBy?: string | null, createdAt: any, description: string, name: string, org_id?: any | null, status: string, priority: string }> } | null };

export type Create_DemoMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['numeric']['input']>;
}>;


export type Create_DemoMutation = { __typename?: 'mutation_root', insert_examsonline_contactus?: { __typename?: 'examsonline_contactus_mutation_response', returning: Array<{ __typename?: 'examsonline_contactus', id: any, message: string, reason: string }> } | null };

export type Get_All_Joined_GroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_All_Joined_GroupsQuery = { __typename?: 'query_root', examsonline_members: Array<{ __typename?: 'examsonline_members', group: { __typename?: 'examsonline_groups', id: any, name: string } }> };

export type Create_Contest_FormMutationVariables = Exact<{
  objects?: InputMaybe<Array<Examsonline_Contest_Insert_Input> | Examsonline_Contest_Insert_Input>;
  update_columns?: InputMaybe<Array<Examsonline_Contest_Update_Column> | Examsonline_Contest_Update_Column>;
}>;


export type Create_Contest_FormMutation = { __typename?: 'mutation_root', insert_examsonline_contest?: { __typename?: 'examsonline_contest_mutation_response', returning: Array<{ __typename?: 'examsonline_contest', author?: string | null, id: any, name?: string | null, endDate?: any | null, duration?: any | null, description?: string | null, createdAt?: any | null, startDate?: any | null, test_id?: number | null, updatedAt?: any | null }> } | null };

export type Get_All_ContestQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type Get_All_ContestQuery = { __typename?: 'query_root', examsonline_contest: Array<{ __typename?: 'examsonline_contest', author?: string | null, description?: string | null, endDate?: any | null, duration?: any | null, createdAt?: any | null, id: any, name?: string | null, startDate?: any | null, test_id?: number | null, updatedAt?: any | null, topics: Array<{ __typename?: 'examsonline_topics', tag: string }>, user?: { __typename?: 'examsonline_user', email: string, organisation: { __typename?: 'examsonline_organisation', name: string } } | null }> };

export type Add_Priority_StatusQueryVariables = Exact<{ [key: string]: never; }>;


export type Add_Priority_StatusQuery = { __typename?: 'query_root', _enumtable_priority: Array<{ __typename?: '_enumtable_priority', value: string }> };

export type Add_CountryQueryVariables = Exact<{ [key: string]: never; }>;


export type Add_CountryQuery = { __typename?: 'query_root', _enumtable_country: Array<{ __typename?: '_enumtable_country', name: string }> };

export type Ticket_Status_ListQueryVariables = Exact<{ [key: string]: never; }>;


export type Ticket_Status_ListQuery = { __typename?: 'query_root', _enumtable_ticket_status: Array<{ __typename?: '_enumtable_ticket_status', status: string }> };

export type Support_User_ListQueryVariables = Exact<{ [key: string]: never; }>;


export type Support_User_ListQuery = { __typename?: 'query_root', examsonline_roles: Array<{ __typename?: 'examsonline_roles', user: string }> };

export type Get_My_Assignments_Filter_By_DateQueryVariables = Exact<{
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
}>;


export type Get_My_Assignments_Filter_By_DateQuery = { __typename?: 'query_root', examsonline_assignments: Array<{ __typename?: 'examsonline_assignments', startDate: any, id: any, name: string, test_id?: number | null }> };

export type Get_ContestQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  _iregex?: InputMaybe<Scalars['String']['input']>;
}>;


export type Get_ContestQuery = { __typename?: 'query_root', examsonline_contest: Array<{ __typename?: 'examsonline_contest', id: any, name?: string | null, createdAt?: any | null, author?: string | null, duration?: any | null, endDate?: any | null, startDate?: any | null, test_id?: number | null, files: Array<{ __typename?: 'examsonline_file', info: any }>, topics: Array<{ __typename?: 'examsonline_topics', tag: string }> }>, total: { __typename?: 'examsonline_contest_aggregate', aggregate?: { __typename?: 'examsonline_contest_aggregate_fields', count: number } | null } };

export type Get_Contest_By_IdQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type Get_Contest_By_IdQuery = { __typename?: 'query_root', examsonline_contest: Array<{ __typename?: 'examsonline_contest', id: any, name?: string | null, createdAt?: any | null, author?: string | null, duration?: any | null, description?: string | null, endDate?: any | null, test_id?: number | null, startDate?: any | null, files: Array<{ __typename?: 'examsonline_file', info: any }>, topics: Array<{ __typename?: 'examsonline_topics', tag: string }> }> };

export type Update_Contest_Test_IdMutationVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  test_id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type Update_Contest_Test_IdMutation = { __typename?: 'mutation_root', update_examsonline_contest?: { __typename?: 'examsonline_contest_mutation_response', returning: Array<{ __typename?: 'examsonline_contest', id: any, test_id?: number | null, updatedAt?: any | null }> } | null };

export type Join_ParticipantsMutationVariables = Exact<{
  contest_id?: InputMaybe<Scalars['uuid']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
}>;


export type Join_ParticipantsMutation = { __typename?: 'mutation_root', insert_examsonline_participate?: { __typename?: 'examsonline_participate_mutation_response', returning: Array<{ __typename?: 'examsonline_participate', email: string, id: any }> } | null };

export type Participating_In_ContestQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _eq1?: InputMaybe<Scalars['String']['input']>;
}>;


export type Participating_In_ContestQuery = { __typename?: 'query_root', examsonline_participate: Array<{ __typename?: 'examsonline_participate', email: string, id: any }> };

export type Join_Contest_ManyMutationVariables = Exact<{
  objects?: InputMaybe<Array<Examsonline_Participate_Insert_Input> | Examsonline_Participate_Insert_Input>;
}>;


export type Join_Contest_ManyMutation = { __typename?: 'mutation_root', insert_examsonline_participate?: { __typename?: 'examsonline_participate_mutation_response', affected_rows: number } | null };

export type Delete_Topics_By_Contest_IdMutationVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type Delete_Topics_By_Contest_IdMutation = { __typename?: 'mutation_root', delete_examsonline_topics?: { __typename?: 'examsonline_topics_mutation_response', affected_rows: number } | null };

export type Get_All_ParticipantsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _eq1?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type Get_All_ParticipantsQuery = { __typename?: 'query_root', examsonline_participate: Array<{ __typename?: 'examsonline_participate', email: string, createdAt: any, id: any, contest_id?: any | null }>, total: { __typename?: 'examsonline_participate_aggregate', aggregate?: { __typename?: 'examsonline_participate_aggregate_fields', count: number } | null } };

export type Get_Assignment_By_IdQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type Get_Assignment_By_IdQuery = { __typename?: 'query_root', examsonline_assignments: Array<{ __typename?: 'examsonline_assignments', id: any, name: string, startDate: any, endDate: any, createdAt: any, user: string, test_id?: number | null, comments: Array<{ __typename?: 'examsonline_comments', value: string, user: string, id: any, ticket_id?: any | null, role?: string | null, updatedAt: any, createdAt: any }> }> };

export type Remove_Test_From_ContestMutationVariables = Exact<{
  test_id?: InputMaybe<Scalars['Int']['input']>;
  _eq?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type Remove_Test_From_ContestMutation = { __typename?: 'mutation_root', update_examsonline_contest?: { __typename?: 'examsonline_contest_mutation_response', returning: Array<{ __typename?: 'examsonline_contest', id: any, test_id?: number | null }> } | null };

export type My_FilesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type My_FilesQuery = { __typename?: 'query_root', examsonline_file: Array<{ __typename?: 'examsonline_file', id: any, info: any, size?: number | null, test?: { __typename?: 'examsonline_tests', id: number, name: string, author: string } | null }>, total: { __typename?: 'examsonline_file_aggregate', aggregate?: { __typename?: 'examsonline_file_aggregate_fields', count: number } | null }, total_size: { __typename?: 'examsonline_file_aggregate', aggregate?: { __typename?: 'examsonline_file_aggregate_fields', sum?: { __typename?: 'examsonline_file_sum_fields', size?: number | null } | null } | null } };

export type Search_Contest_By_TagQueryVariables = Exact<{
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _iregex1?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type Search_Contest_By_TagQuery = { __typename?: 'query_root', examsonline_topics: Array<{ __typename?: 'examsonline_topics', contest?: { __typename?: 'examsonline_contest', id: any, name?: string | null, author?: string | null, createdAt?: any | null, duration?: any | null, description?: string | null, topics: Array<{ __typename?: 'examsonline_topics', tag: string }>, files: Array<{ __typename?: 'examsonline_file', id: any, info: any }> } | null }>, total_count: { __typename?: 'examsonline_contest_aggregate', aggregate?: { __typename?: 'examsonline_contest_aggregate_fields', count: number } | null } };

export type Update_Ticket_DetailsMutationVariables = Exact<{
  _set?: InputMaybe<Examsonline_Tickets_Set_Input>;
  _eq?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type Update_Ticket_DetailsMutation = { __typename?: 'mutation_root', update_examsonline_tickets?: { __typename?: 'examsonline_tickets_mutation_response', returning: Array<{ __typename?: 'examsonline_tickets', id: any, status: string, assigned?: string | null }> } | null };

export type Get_Test_PincodeQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['Int']['input']>;
}>;


export type Get_Test_PincodeQuery = { __typename?: 'query_root', examsonline_tests: Array<{ __typename?: 'examsonline_tests', pincode?: string | null }> };

export type Total_File_Size_UsedQueryVariables = Exact<{ [key: string]: never; }>;


export type Total_File_Size_UsedQuery = { __typename?: 'query_root', examsonline_file_aggregate: { __typename?: 'examsonline_file_aggregate', aggregate?: { __typename?: 'examsonline_file_aggregate_fields', sum?: { __typename?: 'examsonline_file_sum_fields', size?: number | null } | null } | null } };

export type Get_All_Participants_ResultQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _eq1?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type Get_All_Participants_ResultQuery = { __typename?: 'query_root', examsonline_attempt: Array<{ __typename?: 'examsonline_attempt', id: any, email: string, createdAt: any, updatedAt?: any | null, correct: number, wrong: number, percentage: any, skipped: number, completed: boolean }>, total: { __typename?: 'examsonline_attempt_aggregate', aggregate?: { __typename?: 'examsonline_attempt_aggregate_fields', count: number } | null } };

export type Get_All_Test_Participants_ResultQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _eq?: InputMaybe<Scalars['Int']['input']>;
  asg_id?: InputMaybe<Uuid_Comparison_Exp>;
}>;


export type Get_All_Test_Participants_ResultQuery = { __typename?: 'query_root', examsonline_attempt: Array<{ __typename?: 'examsonline_attempt', id: any, email: string, createdAt: any, updatedAt?: any | null, correct: number, wrong: number, percentage: any, skipped: number, completed: boolean }>, total: { __typename?: 'examsonline_attempt_aggregate', aggregate?: { __typename?: 'examsonline_attempt_aggregate_fields', count: number } | null } };

export type Deactivate_RoleMutationVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  active: Scalars['Boolean']['input'];
  _iregex?: InputMaybe<Scalars['String']['input']>;
}>;


export type Deactivate_RoleMutation = { __typename?: 'mutation_root', update_examsonline_roles?: { __typename?: 'examsonline_roles_mutation_response', returning: Array<{ __typename?: 'examsonline_roles', id: any, active: boolean, role: string }> } | null };

export type Order_ItemMutationVariables = Exact<{
  objects?: InputMaybe<Array<Examsonline_Order_Insert_Input> | Examsonline_Order_Insert_Input>;
}>;


export type Order_ItemMutation = { __typename?: 'mutation_root', insert_examsonline_order?: { __typename?: 'examsonline_order_mutation_response', returning: Array<{ __typename?: 'examsonline_order', id: any, plan?: string | null, email: string, createdAt?: any | null, role_id: any, status: boolean, test_id?: number | null, updatedAt?: any | null }> } | null };

export type My_OrdersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type My_OrdersQuery = { __typename?: 'query_root', examsonline_order: Array<{ __typename?: 'examsonline_order', amount: any, createdAt?: any | null, email: string, id: any, plan?: string | null, role_id: any, status: boolean, test?: { __typename?: 'examsonline_tests', id: number, name: string, pincode?: string | null, enabled: boolean, description: string, files: Array<{ __typename?: 'examsonline_file', id: any, info: any, owner: string }> } | null }>, total_count: { __typename?: 'examsonline_order_aggregate', aggregate?: { __typename?: 'examsonline_order_aggregate_fields', count: number } | null } };

export type Get_Question_By_IdQueryVariables = Exact<{
  _eq: Scalars['Int']['input'];
}>;


export type Get_Question_By_IdQuery = { __typename?: 'query_root', examsonline_questions: Array<{ __typename?: 'examsonline_questions', id: number, order: number, text: string, marks: any, explanation?: string | null, test_id?: number | null, type: number, section?: any | null, options: Array<{ __typename?: 'examsonline_options', id: number, value: string, isCorrect: boolean, type: number }> }> };

export type Update_Question_By_IdMutationVariables = Exact<{
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _set?: InputMaybe<Examsonline_Questions_Set_Input>;
}>;


export type Update_Question_By_IdMutation = { __typename?: 'mutation_root', update_examsonline_questions?: { __typename?: 'examsonline_questions_mutation_response', returning: Array<{ __typename?: 'examsonline_questions', id: number, order: number, text: string, marks: any, explanation?: string | null, test_id?: number | null, type: number, section?: any | null, options: Array<{ __typename?: 'examsonline_options', id: number, value: string, isCorrect: boolean, type: number }> }> } | null };

export type Update_Options_By_Question_IdMutationVariables = Exact<{
  _set?: InputMaybe<Examsonline_Options_Set_Input>;
  _eq: Scalars['Int']['input'];
}>;


export type Update_Options_By_Question_IdMutation = { __typename?: 'mutation_root', update_examsonline_options?: { __typename?: 'examsonline_options_mutation_response', returning: Array<{ __typename?: 'examsonline_options', id: number, isCorrect: boolean, q_id: number, type: number, value: string }> } | null };

export const CommentFragmentFragmentDoc = gql`
    fragment CommentFragment on examsonline_comments {
  value
  user
  id
  ticket_id
  role
  updatedAt
  createdAt
}
    `;
export const OptionsFragmentFragmentDoc = gql`
    fragment OptionsFragment on examsonline_options {
  id
  value
  isCorrect
  type
}
    `;
export const QuestionFragmentFragmentDoc = gql`
    fragment QuestionFragment on examsonline_questions {
  id
  order
  text
  marks
  explanation
  test_id
  type
  section
  options {
    ...OptionsFragment
  }
}
    ${OptionsFragmentFragmentDoc}`;
export const TestDetailFragmentFragmentDoc = gql`
    fragment TestDetailFragment on examsonline_tests {
  id
  accesstype
  author
  createdAt
  description
  document
  emailnotify
  enabled
  errorMessage
  maxAttempts
  isDraft
  name
  pincode
  randomise
  showResult
  time
  price
  points
  files {
    id
    info
  }
  test_sections {
    name
    id
    questions {
      id
    }
    createdAt
  }
  questions(order_by: {order: asc}) {
    ...QuestionFragment
  }
  topics {
    tag
  }
}
    ${QuestionFragmentFragmentDoc}`;
export const FileFragmentFragmentDoc = gql`
    fragment FileFragment on examsonline_file {
  id
  info
  name
  size
}
    `;
export const TopicsFragmentFragmentDoc = gql`
    fragment TopicsFragment on examsonline_topics {
  id
  tag
}
    `;
export const TestPreviewFragmentFragmentDoc = gql`
    fragment TestPreviewFragment on examsonline_tests {
  id
  accesstype
  author
  createdAt
  description
  document
  emailnotify
  enabled
  errorMessage
  maxAttempts
  isDraft
  name
  randomise
  showResult
  time
  price
  files {
    ...FileFragment
  }
  test_sections {
    name
    id
    questions {
      id
    }
  }
  topics {
    ...TopicsFragment
  }
}
    ${FileFragmentFragmentDoc}
${TopicsFragmentFragmentDoc}`;
export const Update_BillDocument = gql`
    mutation UPDATE_BILL($id: uuid_comparison_exp = {}, $status: String = "") {
  update_examsonline_bill(where: {id: $id}, _set: {status: $status}) {
    returning {
      id
      status
      amount
      createdAt
      createdBy
      endDate
      orgId
      plan
      startDate
    }
  }
}
    `;
export type Update_BillMutationFn = Apollo.MutationFunction<Update_BillMutation, Update_BillMutationVariables>;

/**
 * __useUpdate_BillMutation__
 *
 * To run a mutation, you first call `useUpdate_BillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_BillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBillMutation, { data, loading, error }] = useUpdate_BillMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdate_BillMutation(baseOptions?: Apollo.MutationHookOptions<Update_BillMutation, Update_BillMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_BillMutation, Update_BillMutationVariables>(Update_BillDocument, options);
      }
export type Update_BillMutationHookResult = ReturnType<typeof useUpdate_BillMutation>;
export type Update_BillMutationResult = Apollo.MutationResult<Update_BillMutation>;
export type Update_BillMutationOptions = Apollo.BaseMutationOptions<Update_BillMutation, Update_BillMutationVariables>;
export const GetSessionRecordsByAttemptIdDocument = gql`
    query getSessionRecordsByAttemptId($attemptId: uuid_comparison_exp = {}, $studentId: String_comparison_exp = {}) {
  examsonline_sessionrecord(where: {attemptId: $attemptId, studentId: $studentId}) {
    attemptId
    id
    options
    sessionId
    studentId
    questionId
    selectedOptions
  }
}
    `;

/**
 * __useGetSessionRecordsByAttemptIdQuery__
 *
 * To run a query within a React component, call `useGetSessionRecordsByAttemptIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionRecordsByAttemptIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionRecordsByAttemptIdQuery({
 *   variables: {
 *      attemptId: // value for 'attemptId'
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useGetSessionRecordsByAttemptIdQuery(baseOptions?: Apollo.QueryHookOptions<GetSessionRecordsByAttemptIdQuery, GetSessionRecordsByAttemptIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSessionRecordsByAttemptIdQuery, GetSessionRecordsByAttemptIdQueryVariables>(GetSessionRecordsByAttemptIdDocument, options);
      }
export function useGetSessionRecordsByAttemptIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSessionRecordsByAttemptIdQuery, GetSessionRecordsByAttemptIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSessionRecordsByAttemptIdQuery, GetSessionRecordsByAttemptIdQueryVariables>(GetSessionRecordsByAttemptIdDocument, options);
        }
export function useGetSessionRecordsByAttemptIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSessionRecordsByAttemptIdQuery, GetSessionRecordsByAttemptIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSessionRecordsByAttemptIdQuery, GetSessionRecordsByAttemptIdQueryVariables>(GetSessionRecordsByAttemptIdDocument, options);
        }
export type GetSessionRecordsByAttemptIdQueryHookResult = ReturnType<typeof useGetSessionRecordsByAttemptIdQuery>;
export type GetSessionRecordsByAttemptIdLazyQueryHookResult = ReturnType<typeof useGetSessionRecordsByAttemptIdLazyQuery>;
export type GetSessionRecordsByAttemptIdSuspenseQueryHookResult = ReturnType<typeof useGetSessionRecordsByAttemptIdSuspenseQuery>;
export type GetSessionRecordsByAttemptIdQueryResult = Apollo.QueryResult<GetSessionRecordsByAttemptIdQuery, GetSessionRecordsByAttemptIdQueryVariables>;
export const Update_Test_SectionDocument = gql`
    mutation UPDATE_TEST_SECTION($name: String = "", $_eq: uuid = "") {
  update_examsonline_test_sections(where: {id: {_eq: $_eq}}, _set: {name: $name}) {
    returning {
      name
      id
    }
  }
}
    `;
export type Update_Test_SectionMutationFn = Apollo.MutationFunction<Update_Test_SectionMutation, Update_Test_SectionMutationVariables>;

/**
 * __useUpdate_Test_SectionMutation__
 *
 * To run a mutation, you first call `useUpdate_Test_SectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Test_SectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTestSectionMutation, { data, loading, error }] = useUpdate_Test_SectionMutation({
 *   variables: {
 *      name: // value for 'name'
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useUpdate_Test_SectionMutation(baseOptions?: Apollo.MutationHookOptions<Update_Test_SectionMutation, Update_Test_SectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_Test_SectionMutation, Update_Test_SectionMutationVariables>(Update_Test_SectionDocument, options);
      }
export type Update_Test_SectionMutationHookResult = ReturnType<typeof useUpdate_Test_SectionMutation>;
export type Update_Test_SectionMutationResult = Apollo.MutationResult<Update_Test_SectionMutation>;
export type Update_Test_SectionMutationOptions = Apollo.BaseMutationOptions<Update_Test_SectionMutation, Update_Test_SectionMutationVariables>;
export const AddSessionRecordDocument = gql`
    mutation addSessionRecord($objects: [examsonline_sessionrecord_insert_input!] = {}) {
  insert_examsonline_sessionrecord(
    objects: $objects
    on_conflict: {constraint: sessionrecord_pkey, update_columns: [selectedOptions, createdAt, questionId, sessionId, studentId, textAnswer, timeSpent, updatedAt, options]}
  ) {
    affected_rows
    returning {
      selectedOptions
      createdAt
      id
      questionId
      sessionId
      studentId
      textAnswer
      timeSpent
      updatedAt
      options
    }
  }
}
    `;
export type AddSessionRecordMutationFn = Apollo.MutationFunction<AddSessionRecordMutation, AddSessionRecordMutationVariables>;

/**
 * __useAddSessionRecordMutation__
 *
 * To run a mutation, you first call `useAddSessionRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSessionRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSessionRecordMutation, { data, loading, error }] = useAddSessionRecordMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useAddSessionRecordMutation(baseOptions?: Apollo.MutationHookOptions<AddSessionRecordMutation, AddSessionRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSessionRecordMutation, AddSessionRecordMutationVariables>(AddSessionRecordDocument, options);
      }
export type AddSessionRecordMutationHookResult = ReturnType<typeof useAddSessionRecordMutation>;
export type AddSessionRecordMutationResult = Apollo.MutationResult<AddSessionRecordMutation>;
export type AddSessionRecordMutationOptions = Apollo.BaseMutationOptions<AddSessionRecordMutation, AddSessionRecordMutationVariables>;
export const SessionRecordByQuesIdDocument = gql`
    query sessionRecordByQuesId($questionId: String_comparison_exp = {}, $sessionId: uuid_comparison_exp = {}) {
  examsonline_sessionrecord(
    where: {questionId: $questionId, studentId: {}, sessionId: $sessionId}
  ) {
    createdAt
    id
    questionId
    selectedOptions
    sessionId
    textAnswer
    timeSpent
    updatedAt
    studentId
    options
  }
}
    `;

/**
 * __useSessionRecordByQuesIdQuery__
 *
 * To run a query within a React component, call `useSessionRecordByQuesIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionRecordByQuesIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionRecordByQuesIdQuery({
 *   variables: {
 *      questionId: // value for 'questionId'
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useSessionRecordByQuesIdQuery(baseOptions?: Apollo.QueryHookOptions<SessionRecordByQuesIdQuery, SessionRecordByQuesIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SessionRecordByQuesIdQuery, SessionRecordByQuesIdQueryVariables>(SessionRecordByQuesIdDocument, options);
      }
export function useSessionRecordByQuesIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SessionRecordByQuesIdQuery, SessionRecordByQuesIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SessionRecordByQuesIdQuery, SessionRecordByQuesIdQueryVariables>(SessionRecordByQuesIdDocument, options);
        }
export function useSessionRecordByQuesIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SessionRecordByQuesIdQuery, SessionRecordByQuesIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SessionRecordByQuesIdQuery, SessionRecordByQuesIdQueryVariables>(SessionRecordByQuesIdDocument, options);
        }
export type SessionRecordByQuesIdQueryHookResult = ReturnType<typeof useSessionRecordByQuesIdQuery>;
export type SessionRecordByQuesIdLazyQueryHookResult = ReturnType<typeof useSessionRecordByQuesIdLazyQuery>;
export type SessionRecordByQuesIdSuspenseQueryHookResult = ReturnType<typeof useSessionRecordByQuesIdSuspenseQuery>;
export type SessionRecordByQuesIdQueryResult = Apollo.QueryResult<SessionRecordByQuesIdQuery, SessionRecordByQuesIdQueryVariables>;
export const Get_Test_SessionDocument = gql`
    query Get_Test_Session($testId: Int_comparison_exp = {}, $nameRegex: String, $limit: Int!, $offset: Int!) {
  examsonline_session_aggregate(
    where: {testId: $testId, name: {_regex: $nameRegex}}
  ) {
    aggregate {
      count
    }
  }
  examsonline_session(
    where: {testId: $testId, name: {_regex: $nameRegex}}
    limit: $limit
    offset: $offset
    order_by: {startTime: asc}
  ) {
    endTime
    id
    startTime
    students
    testId
    name
    allowList
    negativemarking
    showResults
    sessionrecords {
      id
      options
      selectedOptions
    }
  }
}
    `;

/**
 * __useGet_Test_SessionQuery__
 *
 * To run a query within a React component, call `useGet_Test_SessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Test_SessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Test_SessionQuery({
 *   variables: {
 *      testId: // value for 'testId'
 *      nameRegex: // value for 'nameRegex'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGet_Test_SessionQuery(baseOptions: Apollo.QueryHookOptions<Get_Test_SessionQuery, Get_Test_SessionQueryVariables> & ({ variables: Get_Test_SessionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_Test_SessionQuery, Get_Test_SessionQueryVariables>(Get_Test_SessionDocument, options);
      }
export function useGet_Test_SessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Test_SessionQuery, Get_Test_SessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_Test_SessionQuery, Get_Test_SessionQueryVariables>(Get_Test_SessionDocument, options);
        }
export function useGet_Test_SessionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_Test_SessionQuery, Get_Test_SessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_Test_SessionQuery, Get_Test_SessionQueryVariables>(Get_Test_SessionDocument, options);
        }
export type Get_Test_SessionQueryHookResult = ReturnType<typeof useGet_Test_SessionQuery>;
export type Get_Test_SessionLazyQueryHookResult = ReturnType<typeof useGet_Test_SessionLazyQuery>;
export type Get_Test_SessionSuspenseQueryHookResult = ReturnType<typeof useGet_Test_SessionSuspenseQuery>;
export type Get_Test_SessionQueryResult = Apollo.QueryResult<Get_Test_SessionQuery, Get_Test_SessionQueryVariables>;
export const Create_Test_SessionDocument = gql`
    mutation Create_Test_Session($objects: [examsonline_session_insert_input!] = {}, $update_columns: [examsonline_session_update_column!] = [name, allowList]) {
  insert_examsonline_session(
    objects: $objects
    on_conflict: {constraint: sessions_pkey, update_columns: $update_columns}
  ) {
    affected_rows
    returning {
      allowList
      endTime
      id
      startTime
      students
      testId
      negativemarking
      showResults
    }
  }
}
    `;
export type Create_Test_SessionMutationFn = Apollo.MutationFunction<Create_Test_SessionMutation, Create_Test_SessionMutationVariables>;

/**
 * __useCreate_Test_SessionMutation__
 *
 * To run a mutation, you first call `useCreate_Test_SessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_Test_SessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTestSessionMutation, { data, loading, error }] = useCreate_Test_SessionMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      update_columns: // value for 'update_columns'
 *   },
 * });
 */
export function useCreate_Test_SessionMutation(baseOptions?: Apollo.MutationHookOptions<Create_Test_SessionMutation, Create_Test_SessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Create_Test_SessionMutation, Create_Test_SessionMutationVariables>(Create_Test_SessionDocument, options);
      }
export type Create_Test_SessionMutationHookResult = ReturnType<typeof useCreate_Test_SessionMutation>;
export type Create_Test_SessionMutationResult = Apollo.MutationResult<Create_Test_SessionMutation>;
export type Create_Test_SessionMutationOptions = Apollo.BaseMutationOptions<Create_Test_SessionMutation, Create_Test_SessionMutationVariables>;
export const Get_Session_By_IdDocument = gql`
    query Get_Session_By_Id($id: uuid!) {
  examsonline_session_by_pk(id: $id) {
    endTime
    id
    startTime
    students
    testId
    name
    allowList
    negativemarking
    showResults
  }
}
    `;

/**
 * __useGet_Session_By_IdQuery__
 *
 * To run a query within a React component, call `useGet_Session_By_IdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Session_By_IdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Session_By_IdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGet_Session_By_IdQuery(baseOptions: Apollo.QueryHookOptions<Get_Session_By_IdQuery, Get_Session_By_IdQueryVariables> & ({ variables: Get_Session_By_IdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_Session_By_IdQuery, Get_Session_By_IdQueryVariables>(Get_Session_By_IdDocument, options);
      }
export function useGet_Session_By_IdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Session_By_IdQuery, Get_Session_By_IdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_Session_By_IdQuery, Get_Session_By_IdQueryVariables>(Get_Session_By_IdDocument, options);
        }
export function useGet_Session_By_IdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_Session_By_IdQuery, Get_Session_By_IdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_Session_By_IdQuery, Get_Session_By_IdQueryVariables>(Get_Session_By_IdDocument, options);
        }
export type Get_Session_By_IdQueryHookResult = ReturnType<typeof useGet_Session_By_IdQuery>;
export type Get_Session_By_IdLazyQueryHookResult = ReturnType<typeof useGet_Session_By_IdLazyQuery>;
export type Get_Session_By_IdSuspenseQueryHookResult = ReturnType<typeof useGet_Session_By_IdSuspenseQuery>;
export type Get_Session_By_IdQueryResult = Apollo.QueryResult<Get_Session_By_IdQuery, Get_Session_By_IdQueryVariables>;
export const Get_BillsDocument = gql`
    query GET_BILLS($limit: Int, $offset: Int, $_gt: date = "", $_lte: date = "") {
  examsonline_bill(
    limit: $limit
    offset: $offset
    where: {startDate: {_gt: $_gt}, endDate: {_lte: $_lte}}
  ) {
    id
    orgId
    status
    startDate
    endDate
    amount
    plan
  }
}
    `;

/**
 * __useGet_BillsQuery__
 *
 * To run a query within a React component, call `useGet_BillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_BillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_BillsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      _gt: // value for '_gt'
 *      _lte: // value for '_lte'
 *   },
 * });
 */
export function useGet_BillsQuery(baseOptions?: Apollo.QueryHookOptions<Get_BillsQuery, Get_BillsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_BillsQuery, Get_BillsQueryVariables>(Get_BillsDocument, options);
      }
export function useGet_BillsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_BillsQuery, Get_BillsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_BillsQuery, Get_BillsQueryVariables>(Get_BillsDocument, options);
        }
export function useGet_BillsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_BillsQuery, Get_BillsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_BillsQuery, Get_BillsQueryVariables>(Get_BillsDocument, options);
        }
export type Get_BillsQueryHookResult = ReturnType<typeof useGet_BillsQuery>;
export type Get_BillsLazyQueryHookResult = ReturnType<typeof useGet_BillsLazyQuery>;
export type Get_BillsSuspenseQueryHookResult = ReturnType<typeof useGet_BillsSuspenseQuery>;
export type Get_BillsQueryResult = Apollo.QueryResult<Get_BillsQuery, Get_BillsQueryVariables>;
export const All_TestDocument = gql`
    query ALL_TEST {
  examsonline_tests(order_by: {id: asc}, limit: 10) {
    author
    createdAt
    errorMessage
    topics {
      tag
    }
    name
    time
    questions {
      order
      text
    }
  }
}
    `;

/**
 * __useAll_TestQuery__
 *
 * To run a query within a React component, call `useAll_TestQuery` and pass it any options that fit your needs.
 * When your component renders, `useAll_TestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAll_TestQuery({
 *   variables: {
 *   },
 * });
 */
export function useAll_TestQuery(baseOptions?: Apollo.QueryHookOptions<All_TestQuery, All_TestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<All_TestQuery, All_TestQueryVariables>(All_TestDocument, options);
      }
export function useAll_TestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<All_TestQuery, All_TestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<All_TestQuery, All_TestQueryVariables>(All_TestDocument, options);
        }
export function useAll_TestSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<All_TestQuery, All_TestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<All_TestQuery, All_TestQueryVariables>(All_TestDocument, options);
        }
export type All_TestQueryHookResult = ReturnType<typeof useAll_TestQuery>;
export type All_TestLazyQueryHookResult = ReturnType<typeof useAll_TestLazyQuery>;
export type All_TestSuspenseQueryHookResult = ReturnType<typeof useAll_TestSuspenseQuery>;
export type All_TestQueryResult = Apollo.QueryResult<All_TestQuery, All_TestQueryVariables>;
export const Get_Test_By_IdDocument = gql`
    query GET_TEST_BY_ID($id: Int, $order_by: [examsonline_tests_order_by!] = {id: desc}) {
  examsonline_tests(where: {id: {_eq: $id}}, order_by: $order_by) {
    ...TestDetailFragment
  }
}
    ${TestDetailFragmentFragmentDoc}`;

/**
 * __useGet_Test_By_IdQuery__
 *
 * To run a query within a React component, call `useGet_Test_By_IdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Test_By_IdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Test_By_IdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGet_Test_By_IdQuery(baseOptions?: Apollo.QueryHookOptions<Get_Test_By_IdQuery, Get_Test_By_IdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_Test_By_IdQuery, Get_Test_By_IdQueryVariables>(Get_Test_By_IdDocument, options);
      }
export function useGet_Test_By_IdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Test_By_IdQuery, Get_Test_By_IdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_Test_By_IdQuery, Get_Test_By_IdQueryVariables>(Get_Test_By_IdDocument, options);
        }
export function useGet_Test_By_IdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_Test_By_IdQuery, Get_Test_By_IdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_Test_By_IdQuery, Get_Test_By_IdQueryVariables>(Get_Test_By_IdDocument, options);
        }
export type Get_Test_By_IdQueryHookResult = ReturnType<typeof useGet_Test_By_IdQuery>;
export type Get_Test_By_IdLazyQueryHookResult = ReturnType<typeof useGet_Test_By_IdLazyQuery>;
export type Get_Test_By_IdSuspenseQueryHookResult = ReturnType<typeof useGet_Test_By_IdSuspenseQuery>;
export type Get_Test_By_IdQueryResult = Apollo.QueryResult<Get_Test_By_IdQuery, Get_Test_By_IdQueryVariables>;
export const Get_Test_By_Id_StudentDocument = gql`
    query GET_TEST_BY_ID_STUDENT($id: Int) {
  examsonline_tests(order_by: {id: desc}, where: {id: {_eq: $id}}) {
    id
    accesstype
    author
    createdAt
    description
    document
    emailnotify
    enabled
    errorMessage
    maxAttempts
    isDraft
    name
    randomise
    showResult
    time
    questions {
      id
      order
      text
      marks
      test_id
      type
      section
      options {
        id
        value
        type
      }
    }
    topics {
      tag
    }
  }
}
    `;

/**
 * __useGet_Test_By_Id_StudentQuery__
 *
 * To run a query within a React component, call `useGet_Test_By_Id_StudentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Test_By_Id_StudentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Test_By_Id_StudentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGet_Test_By_Id_StudentQuery(baseOptions?: Apollo.QueryHookOptions<Get_Test_By_Id_StudentQuery, Get_Test_By_Id_StudentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_Test_By_Id_StudentQuery, Get_Test_By_Id_StudentQueryVariables>(Get_Test_By_Id_StudentDocument, options);
      }
export function useGet_Test_By_Id_StudentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Test_By_Id_StudentQuery, Get_Test_By_Id_StudentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_Test_By_Id_StudentQuery, Get_Test_By_Id_StudentQueryVariables>(Get_Test_By_Id_StudentDocument, options);
        }
export function useGet_Test_By_Id_StudentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_Test_By_Id_StudentQuery, Get_Test_By_Id_StudentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_Test_By_Id_StudentQuery, Get_Test_By_Id_StudentQueryVariables>(Get_Test_By_Id_StudentDocument, options);
        }
export type Get_Test_By_Id_StudentQueryHookResult = ReturnType<typeof useGet_Test_By_Id_StudentQuery>;
export type Get_Test_By_Id_StudentLazyQueryHookResult = ReturnType<typeof useGet_Test_By_Id_StudentLazyQuery>;
export type Get_Test_By_Id_StudentSuspenseQueryHookResult = ReturnType<typeof useGet_Test_By_Id_StudentSuspenseQuery>;
export type Get_Test_By_Id_StudentQueryResult = Apollo.QueryResult<Get_Test_By_Id_StudentQuery, Get_Test_By_Id_StudentQueryVariables>;
export const Preview_Test_By_IdDocument = gql`
    query PREVIEW_TEST_BY_ID($id: Int) {
  examsonline_tests(order_by: {id: desc}, where: {id: {_eq: $id}}) {
    ...TestPreviewFragment
    topics {
      tag
    }
  }
}
    ${TestPreviewFragmentFragmentDoc}`;

/**
 * __usePreview_Test_By_IdQuery__
 *
 * To run a query within a React component, call `usePreview_Test_By_IdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreview_Test_By_IdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreview_Test_By_IdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePreview_Test_By_IdQuery(baseOptions?: Apollo.QueryHookOptions<Preview_Test_By_IdQuery, Preview_Test_By_IdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Preview_Test_By_IdQuery, Preview_Test_By_IdQueryVariables>(Preview_Test_By_IdDocument, options);
      }
export function usePreview_Test_By_IdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Preview_Test_By_IdQuery, Preview_Test_By_IdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Preview_Test_By_IdQuery, Preview_Test_By_IdQueryVariables>(Preview_Test_By_IdDocument, options);
        }
export function usePreview_Test_By_IdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Preview_Test_By_IdQuery, Preview_Test_By_IdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Preview_Test_By_IdQuery, Preview_Test_By_IdQueryVariables>(Preview_Test_By_IdDocument, options);
        }
export type Preview_Test_By_IdQueryHookResult = ReturnType<typeof usePreview_Test_By_IdQuery>;
export type Preview_Test_By_IdLazyQueryHookResult = ReturnType<typeof usePreview_Test_By_IdLazyQuery>;
export type Preview_Test_By_IdSuspenseQueryHookResult = ReturnType<typeof usePreview_Test_By_IdSuspenseQuery>;
export type Preview_Test_By_IdQueryResult = Apollo.QueryResult<Preview_Test_By_IdQuery, Preview_Test_By_IdQueryVariables>;
export const Get_Test_Question_Correct_WrongDocument = gql`
    query GET_TEST_QUESTION_CORRECT_WRONG($_eq: Int = 33) {
  examsonline_tests(where: {id: {_eq: $_eq}}) {
    questions {
      id
      wrong: answers_aggregate(where: {isCorrect: {_eq: false}}) {
        aggregate {
          count
        }
      }
      correct: answers_aggregate(where: {isCorrect: {_eq: true}}) {
        aggregate {
          count
        }
      }
      skipped: answers_aggregate(where: {skipped: {_eq: true}}) {
        aggregate {
          count
        }
      }
    }
  }
}
    `;

/**
 * __useGet_Test_Question_Correct_WrongQuery__
 *
 * To run a query within a React component, call `useGet_Test_Question_Correct_WrongQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Test_Question_Correct_WrongQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Test_Question_Correct_WrongQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_Test_Question_Correct_WrongQuery(baseOptions?: Apollo.QueryHookOptions<Get_Test_Question_Correct_WrongQuery, Get_Test_Question_Correct_WrongQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_Test_Question_Correct_WrongQuery, Get_Test_Question_Correct_WrongQueryVariables>(Get_Test_Question_Correct_WrongDocument, options);
      }
export function useGet_Test_Question_Correct_WrongLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Test_Question_Correct_WrongQuery, Get_Test_Question_Correct_WrongQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_Test_Question_Correct_WrongQuery, Get_Test_Question_Correct_WrongQueryVariables>(Get_Test_Question_Correct_WrongDocument, options);
        }
export function useGet_Test_Question_Correct_WrongSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_Test_Question_Correct_WrongQuery, Get_Test_Question_Correct_WrongQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_Test_Question_Correct_WrongQuery, Get_Test_Question_Correct_WrongQueryVariables>(Get_Test_Question_Correct_WrongDocument, options);
        }
export type Get_Test_Question_Correct_WrongQueryHookResult = ReturnType<typeof useGet_Test_Question_Correct_WrongQuery>;
export type Get_Test_Question_Correct_WrongLazyQueryHookResult = ReturnType<typeof useGet_Test_Question_Correct_WrongLazyQuery>;
export type Get_Test_Question_Correct_WrongSuspenseQueryHookResult = ReturnType<typeof useGet_Test_Question_Correct_WrongSuspenseQuery>;
export type Get_Test_Question_Correct_WrongQueryResult = Apollo.QueryResult<Get_Test_Question_Correct_WrongQuery, Get_Test_Question_Correct_WrongQueryVariables>;
export const Add_TestDocument = gql`
    mutation add_test($objects: [examsonline_tests_insert_input!] = {}, $update_columns: [examsonline_tests_update_column!]!) {
  insert_examsonline_tests(
    objects: $objects
    on_conflict: {constraint: tests_pkey, update_columns: $update_columns}
  ) {
    returning {
      id
      name
      createdAt
      test_sections {
        name
        id
      }
      topics {
        tag
        id
      }
    }
  }
}
    `;
export type Add_TestMutationFn = Apollo.MutationFunction<Add_TestMutation, Add_TestMutationVariables>;

/**
 * __useAdd_TestMutation__
 *
 * To run a mutation, you first call `useAdd_TestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_TestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTestMutation, { data, loading, error }] = useAdd_TestMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      update_columns: // value for 'update_columns'
 *   },
 * });
 */
export function useAdd_TestMutation(baseOptions?: Apollo.MutationHookOptions<Add_TestMutation, Add_TestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Add_TestMutation, Add_TestMutationVariables>(Add_TestDocument, options);
      }
export type Add_TestMutationHookResult = ReturnType<typeof useAdd_TestMutation>;
export type Add_TestMutationResult = Apollo.MutationResult<Add_TestMutation>;
export type Add_TestMutationOptions = Apollo.BaseMutationOptions<Add_TestMutation, Add_TestMutationVariables>;
export const Update_TestDocument = gql`
    mutation update_test($_set: examsonline_tests_set_input = {}, $test_id: Int!) {
  update_examsonline_tests(where: {id: {_eq: $test_id}}, _set: $_set) {
    returning {
      id
      enabled
      topics {
        tag
      }
    }
  }
}
    `;
export type Update_TestMutationFn = Apollo.MutationFunction<Update_TestMutation, Update_TestMutationVariables>;

/**
 * __useUpdate_TestMutation__
 *
 * To run a mutation, you first call `useUpdate_TestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_TestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTestMutation, { data, loading, error }] = useUpdate_TestMutation({
 *   variables: {
 *      _set: // value for '_set'
 *      test_id: // value for 'test_id'
 *   },
 * });
 */
export function useUpdate_TestMutation(baseOptions?: Apollo.MutationHookOptions<Update_TestMutation, Update_TestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_TestMutation, Update_TestMutationVariables>(Update_TestDocument, options);
      }
export type Update_TestMutationHookResult = ReturnType<typeof useUpdate_TestMutation>;
export type Update_TestMutationResult = Apollo.MutationResult<Update_TestMutation>;
export type Update_TestMutationOptions = Apollo.BaseMutationOptions<Update_TestMutation, Update_TestMutationVariables>;
export const Add_AnswersDocument = gql`
    mutation ADD_ANSWERS($objects: [examsonline_answers_insert_input!] = {}, $update_columns: [examsonline_answers_update_column!] = attempt_id) {
  insert_examsonline_answers(
    objects: $objects
    on_conflict: {constraint: answers_pkey, update_columns: $update_columns}
  ) {
    returning {
      q_id
      attempt_id
    }
  }
}
    `;
export type Add_AnswersMutationFn = Apollo.MutationFunction<Add_AnswersMutation, Add_AnswersMutationVariables>;

/**
 * __useAdd_AnswersMutation__
 *
 * To run a mutation, you first call `useAdd_AnswersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_AnswersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAnswersMutation, { data, loading, error }] = useAdd_AnswersMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      update_columns: // value for 'update_columns'
 *   },
 * });
 */
export function useAdd_AnswersMutation(baseOptions?: Apollo.MutationHookOptions<Add_AnswersMutation, Add_AnswersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Add_AnswersMutation, Add_AnswersMutationVariables>(Add_AnswersDocument, options);
      }
export type Add_AnswersMutationHookResult = ReturnType<typeof useAdd_AnswersMutation>;
export type Add_AnswersMutationResult = Apollo.MutationResult<Add_AnswersMutation>;
export type Add_AnswersMutationOptions = Apollo.BaseMutationOptions<Add_AnswersMutation, Add_AnswersMutationVariables>;
export const Get_AttemptsDocument = gql`
    query get_attempts($sessionId: uuid!, $email: String, $limit: Int, $offset: Int) {
  examsonline_attempt(
    where: {_and: [{sessionId: {_eq: $sessionId}}, {email: {_regex: $email}}]}
    limit: $limit
    offset: $offset
    order_by: {createdAt: desc}
  ) {
    email
    test_id
    createdAt
    id
    completed
    percentage
    correct
    wrong
    skipped
  }
  examsonline_attempt_aggregate(
    where: {_and: [{sessionId: {_eq: $sessionId}}, {email: {_regex: $email}}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGet_AttemptsQuery__
 *
 * To run a query within a React component, call `useGet_AttemptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_AttemptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_AttemptsQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      email: // value for 'email'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGet_AttemptsQuery(baseOptions: Apollo.QueryHookOptions<Get_AttemptsQuery, Get_AttemptsQueryVariables> & ({ variables: Get_AttemptsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_AttemptsQuery, Get_AttemptsQueryVariables>(Get_AttemptsDocument, options);
      }
export function useGet_AttemptsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_AttemptsQuery, Get_AttemptsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_AttemptsQuery, Get_AttemptsQueryVariables>(Get_AttemptsDocument, options);
        }
export function useGet_AttemptsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_AttemptsQuery, Get_AttemptsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_AttemptsQuery, Get_AttemptsQueryVariables>(Get_AttemptsDocument, options);
        }
export type Get_AttemptsQueryHookResult = ReturnType<typeof useGet_AttemptsQuery>;
export type Get_AttemptsLazyQueryHookResult = ReturnType<typeof useGet_AttemptsLazyQuery>;
export type Get_AttemptsSuspenseQueryHookResult = ReturnType<typeof useGet_AttemptsSuspenseQuery>;
export type Get_AttemptsQueryResult = Apollo.QueryResult<Get_AttemptsQuery, Get_AttemptsQueryVariables>;
export const Get_OptionsDocument = gql`
    query GET_OPTIONS($q_id: Int) {
  examsonline_options(order_by: {id: asc}, where: {q_id: {_eq: $q_id}}) {
    id
    isCorrect
    q_id
    value
    type
  }
}
    `;

/**
 * __useGet_OptionsQuery__
 *
 * To run a query within a React component, call `useGet_OptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_OptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_OptionsQuery({
 *   variables: {
 *      q_id: // value for 'q_id'
 *   },
 * });
 */
export function useGet_OptionsQuery(baseOptions?: Apollo.QueryHookOptions<Get_OptionsQuery, Get_OptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_OptionsQuery, Get_OptionsQueryVariables>(Get_OptionsDocument, options);
      }
export function useGet_OptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_OptionsQuery, Get_OptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_OptionsQuery, Get_OptionsQueryVariables>(Get_OptionsDocument, options);
        }
export function useGet_OptionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_OptionsQuery, Get_OptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_OptionsQuery, Get_OptionsQueryVariables>(Get_OptionsDocument, options);
        }
export type Get_OptionsQueryHookResult = ReturnType<typeof useGet_OptionsQuery>;
export type Get_OptionsLazyQueryHookResult = ReturnType<typeof useGet_OptionsLazyQuery>;
export type Get_OptionsSuspenseQueryHookResult = ReturnType<typeof useGet_OptionsSuspenseQuery>;
export type Get_OptionsQueryResult = Apollo.QueryResult<Get_OptionsQuery, Get_OptionsQueryVariables>;
export const Get_Options_For_StudentDocument = gql`
    query GET_OPTIONS_FOR_STUDENT($q_id: Int) {
  examsonline_options(order_by: {id: asc}, where: {q_id: {_eq: $q_id}}) {
    id
    q_id
    value
    type
  }
}
    `;

/**
 * __useGet_Options_For_StudentQuery__
 *
 * To run a query within a React component, call `useGet_Options_For_StudentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Options_For_StudentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Options_For_StudentQuery({
 *   variables: {
 *      q_id: // value for 'q_id'
 *   },
 * });
 */
export function useGet_Options_For_StudentQuery(baseOptions?: Apollo.QueryHookOptions<Get_Options_For_StudentQuery, Get_Options_For_StudentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_Options_For_StudentQuery, Get_Options_For_StudentQueryVariables>(Get_Options_For_StudentDocument, options);
      }
export function useGet_Options_For_StudentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Options_For_StudentQuery, Get_Options_For_StudentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_Options_For_StudentQuery, Get_Options_For_StudentQueryVariables>(Get_Options_For_StudentDocument, options);
        }
export function useGet_Options_For_StudentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_Options_For_StudentQuery, Get_Options_For_StudentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_Options_For_StudentQuery, Get_Options_For_StudentQueryVariables>(Get_Options_For_StudentDocument, options);
        }
export type Get_Options_For_StudentQueryHookResult = ReturnType<typeof useGet_Options_For_StudentQuery>;
export type Get_Options_For_StudentLazyQueryHookResult = ReturnType<typeof useGet_Options_For_StudentLazyQuery>;
export type Get_Options_For_StudentSuspenseQueryHookResult = ReturnType<typeof useGet_Options_For_StudentSuspenseQuery>;
export type Get_Options_For_StudentQueryResult = Apollo.QueryResult<Get_Options_For_StudentQuery, Get_Options_For_StudentQueryVariables>;
export const Get_OrgsDocument = gql`
    query GET_ORGS {
  examsonline_organisation {
    name
    id
  }
}
    `;

/**
 * __useGet_OrgsQuery__
 *
 * To run a query within a React component, call `useGet_OrgsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_OrgsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_OrgsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_OrgsQuery(baseOptions?: Apollo.QueryHookOptions<Get_OrgsQuery, Get_OrgsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_OrgsQuery, Get_OrgsQueryVariables>(Get_OrgsDocument, options);
      }
export function useGet_OrgsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_OrgsQuery, Get_OrgsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_OrgsQuery, Get_OrgsQueryVariables>(Get_OrgsDocument, options);
        }
export function useGet_OrgsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_OrgsQuery, Get_OrgsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_OrgsQuery, Get_OrgsQueryVariables>(Get_OrgsDocument, options);
        }
export type Get_OrgsQueryHookResult = ReturnType<typeof useGet_OrgsQuery>;
export type Get_OrgsLazyQueryHookResult = ReturnType<typeof useGet_OrgsLazyQuery>;
export type Get_OrgsSuspenseQueryHookResult = ReturnType<typeof useGet_OrgsSuspenseQuery>;
export type Get_OrgsQueryResult = Apollo.QueryResult<Get_OrgsQuery, Get_OrgsQueryVariables>;
export const Get_QuestionsDocument = gql`
    query get_questions($test_id: Int!) {
  examsonline_questions(where: {test_id: {_eq: $test_id}}) {
    id
    section
  }
}
    `;

/**
 * __useGet_QuestionsQuery__
 *
 * To run a query within a React component, call `useGet_QuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_QuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_QuestionsQuery({
 *   variables: {
 *      test_id: // value for 'test_id'
 *   },
 * });
 */
export function useGet_QuestionsQuery(baseOptions: Apollo.QueryHookOptions<Get_QuestionsQuery, Get_QuestionsQueryVariables> & ({ variables: Get_QuestionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_QuestionsQuery, Get_QuestionsQueryVariables>(Get_QuestionsDocument, options);
      }
export function useGet_QuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_QuestionsQuery, Get_QuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_QuestionsQuery, Get_QuestionsQueryVariables>(Get_QuestionsDocument, options);
        }
export function useGet_QuestionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_QuestionsQuery, Get_QuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_QuestionsQuery, Get_QuestionsQueryVariables>(Get_QuestionsDocument, options);
        }
export type Get_QuestionsQueryHookResult = ReturnType<typeof useGet_QuestionsQuery>;
export type Get_QuestionsLazyQueryHookResult = ReturnType<typeof useGet_QuestionsLazyQuery>;
export type Get_QuestionsSuspenseQueryHookResult = ReturnType<typeof useGet_QuestionsSuspenseQuery>;
export type Get_QuestionsQueryResult = Apollo.QueryResult<Get_QuestionsQuery, Get_QuestionsQueryVariables>;
export const Get_QuestionDocument = gql`
    query GET_QUESTION($test_id: Int!) {
  examsonline_questions(where: {test_id: {_eq: $test_id}}) {
    options {
      id
    }
    id
  }
}
    `;

/**
 * __useGet_QuestionQuery__
 *
 * To run a query within a React component, call `useGet_QuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_QuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_QuestionQuery({
 *   variables: {
 *      test_id: // value for 'test_id'
 *   },
 * });
 */
export function useGet_QuestionQuery(baseOptions: Apollo.QueryHookOptions<Get_QuestionQuery, Get_QuestionQueryVariables> & ({ variables: Get_QuestionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_QuestionQuery, Get_QuestionQueryVariables>(Get_QuestionDocument, options);
      }
export function useGet_QuestionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_QuestionQuery, Get_QuestionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_QuestionQuery, Get_QuestionQueryVariables>(Get_QuestionDocument, options);
        }
export function useGet_QuestionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_QuestionQuery, Get_QuestionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_QuestionQuery, Get_QuestionQueryVariables>(Get_QuestionDocument, options);
        }
export type Get_QuestionQueryHookResult = ReturnType<typeof useGet_QuestionQuery>;
export type Get_QuestionLazyQueryHookResult = ReturnType<typeof useGet_QuestionLazyQuery>;
export type Get_QuestionSuspenseQueryHookResult = ReturnType<typeof useGet_QuestionSuspenseQuery>;
export type Get_QuestionQueryResult = Apollo.QueryResult<Get_QuestionQuery, Get_QuestionQueryVariables>;
export const Sub_TestsDocument = gql`
    subscription SUB_TESTS {
  examsonline_tests {
    accesstype
    id
    author
    createdAt
    description
    emailnotify
    document
    updatedAt
    time
    showResult
    topics {
      tag
    }
    randomise
    name
    maxAttempts
    isDraft
    errorMessage
    enabled
  }
}
    `;

/**
 * __useSub_TestsSubscription__
 *
 * To run a query within a React component, call `useSub_TestsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSub_TestsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSub_TestsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useSub_TestsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<Sub_TestsSubscription, Sub_TestsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Sub_TestsSubscription, Sub_TestsSubscriptionVariables>(Sub_TestsDocument, options);
      }
export type Sub_TestsSubscriptionHookResult = ReturnType<typeof useSub_TestsSubscription>;
export type Sub_TestsSubscriptionResult = Apollo.SubscriptionResult<Sub_TestsSubscription>;
export const Add_UserDocument = gql`
    mutation add_user($input: [examsonline_user_insert_input!]!) {
  insert_examsonline_user(objects: $input) {
    returning {
      email
    }
  }
}
    `;
export type Add_UserMutationFn = Apollo.MutationFunction<Add_UserMutation, Add_UserMutationVariables>;

/**
 * __useAdd_UserMutation__
 *
 * To run a mutation, you first call `useAdd_UserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_UserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserMutation, { data, loading, error }] = useAdd_UserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdd_UserMutation(baseOptions?: Apollo.MutationHookOptions<Add_UserMutation, Add_UserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Add_UserMutation, Add_UserMutationVariables>(Add_UserDocument, options);
      }
export type Add_UserMutationHookResult = ReturnType<typeof useAdd_UserMutation>;
export type Add_UserMutationResult = Apollo.MutationResult<Add_UserMutation>;
export type Add_UserMutationOptions = Apollo.BaseMutationOptions<Add_UserMutation, Add_UserMutationVariables>;
export const Delete_Topics_By_Test_IdDocument = gql`
    mutation delete_topics_by_test_id($test_id: Int!) {
  delete_examsonline_topics(where: {test_id: {_eq: $test_id}}) {
    affected_rows
  }
}
    `;
export type Delete_Topics_By_Test_IdMutationFn = Apollo.MutationFunction<Delete_Topics_By_Test_IdMutation, Delete_Topics_By_Test_IdMutationVariables>;

/**
 * __useDelete_Topics_By_Test_IdMutation__
 *
 * To run a mutation, you first call `useDelete_Topics_By_Test_IdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_Topics_By_Test_IdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTopicsByTestIdMutation, { data, loading, error }] = useDelete_Topics_By_Test_IdMutation({
 *   variables: {
 *      test_id: // value for 'test_id'
 *   },
 * });
 */
export function useDelete_Topics_By_Test_IdMutation(baseOptions?: Apollo.MutationHookOptions<Delete_Topics_By_Test_IdMutation, Delete_Topics_By_Test_IdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Delete_Topics_By_Test_IdMutation, Delete_Topics_By_Test_IdMutationVariables>(Delete_Topics_By_Test_IdDocument, options);
      }
export type Delete_Topics_By_Test_IdMutationHookResult = ReturnType<typeof useDelete_Topics_By_Test_IdMutation>;
export type Delete_Topics_By_Test_IdMutationResult = Apollo.MutationResult<Delete_Topics_By_Test_IdMutation>;
export type Delete_Topics_By_Test_IdMutationOptions = Apollo.BaseMutationOptions<Delete_Topics_By_Test_IdMutation, Delete_Topics_By_Test_IdMutationVariables>;
export const Edit_Test_SubsDocument = gql`
    subscription EDIT_TEST_SUBS($test_id: Int!) {
  examsonline_tests(where: {id: {_eq: $test_id}}) {
    id
    questions {
      text
      id
    }
  }
}
    `;

/**
 * __useEdit_Test_SubsSubscription__
 *
 * To run a query within a React component, call `useEdit_Test_SubsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useEdit_Test_SubsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEdit_Test_SubsSubscription({
 *   variables: {
 *      test_id: // value for 'test_id'
 *   },
 * });
 */
export function useEdit_Test_SubsSubscription(baseOptions: Apollo.SubscriptionHookOptions<Edit_Test_SubsSubscription, Edit_Test_SubsSubscriptionVariables> & ({ variables: Edit_Test_SubsSubscriptionVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Edit_Test_SubsSubscription, Edit_Test_SubsSubscriptionVariables>(Edit_Test_SubsDocument, options);
      }
export type Edit_Test_SubsSubscriptionHookResult = ReturnType<typeof useEdit_Test_SubsSubscription>;
export type Edit_Test_SubsSubscriptionResult = Apollo.SubscriptionResult<Edit_Test_SubsSubscription>;
export const AttemptDocument = gql`
    query ATTEMPT($id: uuid!) {
  examsonline_attempt(where: {id: {_eq: $id}}) {
    email
    id
    stale
    test_id
    completed
    createdAt
  }
}
    `;

/**
 * __useAttemptQuery__
 *
 * To run a query within a React component, call `useAttemptQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttemptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttemptQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAttemptQuery(baseOptions: Apollo.QueryHookOptions<AttemptQuery, AttemptQueryVariables> & ({ variables: AttemptQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AttemptQuery, AttemptQueryVariables>(AttemptDocument, options);
      }
export function useAttemptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AttemptQuery, AttemptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AttemptQuery, AttemptQueryVariables>(AttemptDocument, options);
        }
export function useAttemptSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AttemptQuery, AttemptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AttemptQuery, AttemptQueryVariables>(AttemptDocument, options);
        }
export type AttemptQueryHookResult = ReturnType<typeof useAttemptQuery>;
export type AttemptLazyQueryHookResult = ReturnType<typeof useAttemptLazyQuery>;
export type AttemptSuspenseQueryHookResult = ReturnType<typeof useAttemptSuspenseQuery>;
export type AttemptQueryResult = Apollo.QueryResult<AttemptQuery, AttemptQueryVariables>;
export const Add_TimespentDocument = gql`
    mutation ADD_TIMESPENT($objects: [examsonline_timespent_insert_input!] = {}, $update_columns: [examsonline_timespent_update_column!] = time) {
  insert_examsonline_timespent(
    objects: $objects
    on_conflict: {constraint: timespent_q_id_attempt_id_id_key, update_columns: $update_columns}
  ) {
    returning {
      attempt_id
      id
      q_id
    }
  }
}
    `;
export type Add_TimespentMutationFn = Apollo.MutationFunction<Add_TimespentMutation, Add_TimespentMutationVariables>;

/**
 * __useAdd_TimespentMutation__
 *
 * To run a mutation, you first call `useAdd_TimespentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_TimespentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTimespentMutation, { data, loading, error }] = useAdd_TimespentMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      update_columns: // value for 'update_columns'
 *   },
 * });
 */
export function useAdd_TimespentMutation(baseOptions?: Apollo.MutationHookOptions<Add_TimespentMutation, Add_TimespentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Add_TimespentMutation, Add_TimespentMutationVariables>(Add_TimespentDocument, options);
      }
export type Add_TimespentMutationHookResult = ReturnType<typeof useAdd_TimespentMutation>;
export type Add_TimespentMutationResult = Apollo.MutationResult<Add_TimespentMutation>;
export type Add_TimespentMutationOptions = Apollo.BaseMutationOptions<Add_TimespentMutation, Add_TimespentMutationVariables>;
export const Get_TimespentDocument = gql`
    query GET_TIMESPENT($attempt_id: uuid!, $q_id: Int!) {
  examsonline_timespent(
    where: {attempt_id: {_eq: $attempt_id}, q_id: {_eq: $q_id}}
  ) {
    time
    q_id
    attempt_id
    id
  }
}
    `;

/**
 * __useGet_TimespentQuery__
 *
 * To run a query within a React component, call `useGet_TimespentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_TimespentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_TimespentQuery({
 *   variables: {
 *      attempt_id: // value for 'attempt_id'
 *      q_id: // value for 'q_id'
 *   },
 * });
 */
export function useGet_TimespentQuery(baseOptions: Apollo.QueryHookOptions<Get_TimespentQuery, Get_TimespentQueryVariables> & ({ variables: Get_TimespentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_TimespentQuery, Get_TimespentQueryVariables>(Get_TimespentDocument, options);
      }
export function useGet_TimespentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_TimespentQuery, Get_TimespentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_TimespentQuery, Get_TimespentQueryVariables>(Get_TimespentDocument, options);
        }
export function useGet_TimespentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_TimespentQuery, Get_TimespentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_TimespentQuery, Get_TimespentQueryVariables>(Get_TimespentDocument, options);
        }
export type Get_TimespentQueryHookResult = ReturnType<typeof useGet_TimespentQuery>;
export type Get_TimespentLazyQueryHookResult = ReturnType<typeof useGet_TimespentLazyQuery>;
export type Get_TimespentSuspenseQueryHookResult = ReturnType<typeof useGet_TimespentSuspenseQuery>;
export type Get_TimespentQueryResult = Apollo.QueryResult<Get_TimespentQuery, Get_TimespentQueryVariables>;
export const Get_Timespent_AttemptDocument = gql`
    query GET_TIMESPENT_ATTEMPT($attempt_id: uuid!) {
  examsonline_timespent(where: {attempt_id: {_eq: $attempt_id}}) {
    time
    q_id
    attempt_id
    id
  }
}
    `;

/**
 * __useGet_Timespent_AttemptQuery__
 *
 * To run a query within a React component, call `useGet_Timespent_AttemptQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Timespent_AttemptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Timespent_AttemptQuery({
 *   variables: {
 *      attempt_id: // value for 'attempt_id'
 *   },
 * });
 */
export function useGet_Timespent_AttemptQuery(baseOptions: Apollo.QueryHookOptions<Get_Timespent_AttemptQuery, Get_Timespent_AttemptQueryVariables> & ({ variables: Get_Timespent_AttemptQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_Timespent_AttemptQuery, Get_Timespent_AttemptQueryVariables>(Get_Timespent_AttemptDocument, options);
      }
export function useGet_Timespent_AttemptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Timespent_AttemptQuery, Get_Timespent_AttemptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_Timespent_AttemptQuery, Get_Timespent_AttemptQueryVariables>(Get_Timespent_AttemptDocument, options);
        }
export function useGet_Timespent_AttemptSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_Timespent_AttemptQuery, Get_Timespent_AttemptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_Timespent_AttemptQuery, Get_Timespent_AttemptQueryVariables>(Get_Timespent_AttemptDocument, options);
        }
export type Get_Timespent_AttemptQueryHookResult = ReturnType<typeof useGet_Timespent_AttemptQuery>;
export type Get_Timespent_AttemptLazyQueryHookResult = ReturnType<typeof useGet_Timespent_AttemptLazyQuery>;
export type Get_Timespent_AttemptSuspenseQueryHookResult = ReturnType<typeof useGet_Timespent_AttemptSuspenseQuery>;
export type Get_Timespent_AttemptQueryResult = Apollo.QueryResult<Get_Timespent_AttemptQuery, Get_Timespent_AttemptQueryVariables>;
export const Get_ResultsDocument = gql`
    query GET_RESULTS($test_id: Int_comparison_exp = {_eq: 33}, $_gte: timestamptz = "") {
  examsonline_attempt(where: {test_id: $test_id, createdAt: {_gte: $_gte}}) {
    id
    email
    createdAt
    updatedAt
    completed
    test_id
    skipped
    correct
    wrong
    percentage
    timespents_aggregate {
      aggregate {
        avg {
          time
        }
      }
    }
  }
}
    `;

/**
 * __useGet_ResultsQuery__
 *
 * To run a query within a React component, call `useGet_ResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_ResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_ResultsQuery({
 *   variables: {
 *      test_id: // value for 'test_id'
 *      _gte: // value for '_gte'
 *   },
 * });
 */
export function useGet_ResultsQuery(baseOptions?: Apollo.QueryHookOptions<Get_ResultsQuery, Get_ResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_ResultsQuery, Get_ResultsQueryVariables>(Get_ResultsDocument, options);
      }
export function useGet_ResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_ResultsQuery, Get_ResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_ResultsQuery, Get_ResultsQueryVariables>(Get_ResultsDocument, options);
        }
export function useGet_ResultsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_ResultsQuery, Get_ResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_ResultsQuery, Get_ResultsQueryVariables>(Get_ResultsDocument, options);
        }
export type Get_ResultsQueryHookResult = ReturnType<typeof useGet_ResultsQuery>;
export type Get_ResultsLazyQueryHookResult = ReturnType<typeof useGet_ResultsLazyQuery>;
export type Get_ResultsSuspenseQueryHookResult = ReturnType<typeof useGet_ResultsSuspenseQuery>;
export type Get_ResultsQueryResult = Apollo.QueryResult<Get_ResultsQuery, Get_ResultsQueryVariables>;
export const Search_Test_By_TagDocument = gql`
    query SEARCH_TEST_BY_TAG($_iregex: String = "", $_iregex1: String = "", $limit: Int = 140, $offset: Int = 0) {
  examsonline_topics(
    order_by: {test: {createdAt: desc_nulls_last}}
    where: {_or: [{tag: {_iregex: $_iregex}}, {test: {name: {_iregex: $_iregex1}}}]}
    limit: $limit
    offset: $offset
  ) {
    test {
      id
      topics {
        tag
      }
      questions_aggregate {
        aggregate {
          count
        }
      }
      name
      author
      time
      enabled
      createdAt
      description
      accesstype
      errorMessage
      isDraft
      price
      points
      files {
        id
        info
      }
      orders_aggregate {
        aggregate {
          count
        }
      }
    }
  }
  total_count: examsonline_topics_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useSearch_Test_By_TagQuery__
 *
 * To run a query within a React component, call `useSearch_Test_By_TagQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearch_Test_By_TagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearch_Test_By_TagQuery({
 *   variables: {
 *      _iregex: // value for '_iregex'
 *      _iregex1: // value for '_iregex1'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useSearch_Test_By_TagQuery(baseOptions?: Apollo.QueryHookOptions<Search_Test_By_TagQuery, Search_Test_By_TagQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Search_Test_By_TagQuery, Search_Test_By_TagQueryVariables>(Search_Test_By_TagDocument, options);
      }
export function useSearch_Test_By_TagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Search_Test_By_TagQuery, Search_Test_By_TagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Search_Test_By_TagQuery, Search_Test_By_TagQueryVariables>(Search_Test_By_TagDocument, options);
        }
export function useSearch_Test_By_TagSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Search_Test_By_TagQuery, Search_Test_By_TagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Search_Test_By_TagQuery, Search_Test_By_TagQueryVariables>(Search_Test_By_TagDocument, options);
        }
export type Search_Test_By_TagQueryHookResult = ReturnType<typeof useSearch_Test_By_TagQuery>;
export type Search_Test_By_TagLazyQueryHookResult = ReturnType<typeof useSearch_Test_By_TagLazyQuery>;
export type Search_Test_By_TagSuspenseQueryHookResult = ReturnType<typeof useSearch_Test_By_TagSuspenseQuery>;
export type Search_Test_By_TagQueryResult = Apollo.QueryResult<Search_Test_By_TagQuery, Search_Test_By_TagQueryVariables>;
export const Get_Avg_Timespent_By_QuesDocument = gql`
    query GET_AVG_TIMESPENT_BY_QUES($_eq: Int = 33) {
  examsonline_tests(where: {id: {_eq: $_eq}}) {
    questions {
      timespents_aggregate {
        aggregate {
          avg {
            time
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGet_Avg_Timespent_By_QuesQuery__
 *
 * To run a query within a React component, call `useGet_Avg_Timespent_By_QuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Avg_Timespent_By_QuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Avg_Timespent_By_QuesQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_Avg_Timespent_By_QuesQuery(baseOptions?: Apollo.QueryHookOptions<Get_Avg_Timespent_By_QuesQuery, Get_Avg_Timespent_By_QuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_Avg_Timespent_By_QuesQuery, Get_Avg_Timespent_By_QuesQueryVariables>(Get_Avg_Timespent_By_QuesDocument, options);
      }
export function useGet_Avg_Timespent_By_QuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Avg_Timespent_By_QuesQuery, Get_Avg_Timespent_By_QuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_Avg_Timespent_By_QuesQuery, Get_Avg_Timespent_By_QuesQueryVariables>(Get_Avg_Timespent_By_QuesDocument, options);
        }
export function useGet_Avg_Timespent_By_QuesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_Avg_Timespent_By_QuesQuery, Get_Avg_Timespent_By_QuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_Avg_Timespent_By_QuesQuery, Get_Avg_Timespent_By_QuesQueryVariables>(Get_Avg_Timespent_By_QuesDocument, options);
        }
export type Get_Avg_Timespent_By_QuesQueryHookResult = ReturnType<typeof useGet_Avg_Timespent_By_QuesQuery>;
export type Get_Avg_Timespent_By_QuesLazyQueryHookResult = ReturnType<typeof useGet_Avg_Timespent_By_QuesLazyQuery>;
export type Get_Avg_Timespent_By_QuesSuspenseQueryHookResult = ReturnType<typeof useGet_Avg_Timespent_By_QuesSuspenseQuery>;
export type Get_Avg_Timespent_By_QuesQueryResult = Apollo.QueryResult<Get_Avg_Timespent_By_QuesQuery, Get_Avg_Timespent_By_QuesQueryVariables>;
export const Delete_QuestionDocument = gql`
    mutation DELETE_QUESTION($_eq: Int = 10) {
  delete_examsonline_questions(where: {id: {_eq: $_eq}}) {
    returning {
      id
      order
      text
      marks
      test_id
      type
      section
      options {
        id
        value
        isCorrect
        type
      }
    }
  }
}
    `;
export type Delete_QuestionMutationFn = Apollo.MutationFunction<Delete_QuestionMutation, Delete_QuestionMutationVariables>;

/**
 * __useDelete_QuestionMutation__
 *
 * To run a mutation, you first call `useDelete_QuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_QuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuestionMutation, { data, loading, error }] = useDelete_QuestionMutation({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useDelete_QuestionMutation(baseOptions?: Apollo.MutationHookOptions<Delete_QuestionMutation, Delete_QuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Delete_QuestionMutation, Delete_QuestionMutationVariables>(Delete_QuestionDocument, options);
      }
export type Delete_QuestionMutationHookResult = ReturnType<typeof useDelete_QuestionMutation>;
export type Delete_QuestionMutationResult = Apollo.MutationResult<Delete_QuestionMutation>;
export type Delete_QuestionMutationOptions = Apollo.BaseMutationOptions<Delete_QuestionMutation, Delete_QuestionMutationVariables>;
export const Get_Total_Users_CountDocument = gql`
    query GET_TOTAL_USERS_COUNT {
  examsonline_user_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGet_Total_Users_CountQuery__
 *
 * To run a query within a React component, call `useGet_Total_Users_CountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Total_Users_CountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Total_Users_CountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_Total_Users_CountQuery(baseOptions?: Apollo.QueryHookOptions<Get_Total_Users_CountQuery, Get_Total_Users_CountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_Total_Users_CountQuery, Get_Total_Users_CountQueryVariables>(Get_Total_Users_CountDocument, options);
      }
export function useGet_Total_Users_CountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Total_Users_CountQuery, Get_Total_Users_CountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_Total_Users_CountQuery, Get_Total_Users_CountQueryVariables>(Get_Total_Users_CountDocument, options);
        }
export function useGet_Total_Users_CountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_Total_Users_CountQuery, Get_Total_Users_CountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_Total_Users_CountQuery, Get_Total_Users_CountQueryVariables>(Get_Total_Users_CountDocument, options);
        }
export type Get_Total_Users_CountQueryHookResult = ReturnType<typeof useGet_Total_Users_CountQuery>;
export type Get_Total_Users_CountLazyQueryHookResult = ReturnType<typeof useGet_Total_Users_CountLazyQuery>;
export type Get_Total_Users_CountSuspenseQueryHookResult = ReturnType<typeof useGet_Total_Users_CountSuspenseQuery>;
export type Get_Total_Users_CountQueryResult = Apollo.QueryResult<Get_Total_Users_CountQuery, Get_Total_Users_CountQueryVariables>;
export const Get_App_Test_CountDocument = gql`
    query GET_APP_TEST_COUNT {
  examsonline_tests_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGet_App_Test_CountQuery__
 *
 * To run a query within a React component, call `useGet_App_Test_CountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_App_Test_CountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_App_Test_CountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_App_Test_CountQuery(baseOptions?: Apollo.QueryHookOptions<Get_App_Test_CountQuery, Get_App_Test_CountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_App_Test_CountQuery, Get_App_Test_CountQueryVariables>(Get_App_Test_CountDocument, options);
      }
export function useGet_App_Test_CountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_App_Test_CountQuery, Get_App_Test_CountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_App_Test_CountQuery, Get_App_Test_CountQueryVariables>(Get_App_Test_CountDocument, options);
        }
export function useGet_App_Test_CountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_App_Test_CountQuery, Get_App_Test_CountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_App_Test_CountQuery, Get_App_Test_CountQueryVariables>(Get_App_Test_CountDocument, options);
        }
export type Get_App_Test_CountQueryHookResult = ReturnType<typeof useGet_App_Test_CountQuery>;
export type Get_App_Test_CountLazyQueryHookResult = ReturnType<typeof useGet_App_Test_CountLazyQuery>;
export type Get_App_Test_CountSuspenseQueryHookResult = ReturnType<typeof useGet_App_Test_CountSuspenseQuery>;
export type Get_App_Test_CountQueryResult = Apollo.QueryResult<Get_App_Test_CountQuery, Get_App_Test_CountQueryVariables>;
export const Get_Org_CountDocument = gql`
    query GET_ORG_COUNT {
  examsonline_organisation_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGet_Org_CountQuery__
 *
 * To run a query within a React component, call `useGet_Org_CountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Org_CountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Org_CountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_Org_CountQuery(baseOptions?: Apollo.QueryHookOptions<Get_Org_CountQuery, Get_Org_CountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_Org_CountQuery, Get_Org_CountQueryVariables>(Get_Org_CountDocument, options);
      }
export function useGet_Org_CountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Org_CountQuery, Get_Org_CountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_Org_CountQuery, Get_Org_CountQueryVariables>(Get_Org_CountDocument, options);
        }
export function useGet_Org_CountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_Org_CountQuery, Get_Org_CountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_Org_CountQuery, Get_Org_CountQueryVariables>(Get_Org_CountDocument, options);
        }
export type Get_Org_CountQueryHookResult = ReturnType<typeof useGet_Org_CountQuery>;
export type Get_Org_CountLazyQueryHookResult = ReturnType<typeof useGet_Org_CountLazyQuery>;
export type Get_Org_CountSuspenseQueryHookResult = ReturnType<typeof useGet_Org_CountSuspenseQuery>;
export type Get_Org_CountQueryResult = Apollo.QueryResult<Get_Org_CountQuery, Get_Org_CountQueryVariables>;
export const Upsert_QuestionsDocument = gql`
    mutation UPSERT_QUESTIONS($objects: [examsonline_questions_insert_input!] = {}) {
  insert_examsonline_questions(
    objects: $objects
    on_conflict: {constraint: questions_pkey, update_columns: [text, marks, explanation]}
  ) {
    returning {
      options {
        id
        isCorrect
        q_id
        type
        value
      }
      createdAt
      id
      marks
      order
      test_id
      quiz_id
      text
      type
      section
      explanation
    }
  }
}
    `;
export type Upsert_QuestionsMutationFn = Apollo.MutationFunction<Upsert_QuestionsMutation, Upsert_QuestionsMutationVariables>;

/**
 * __useUpsert_QuestionsMutation__
 *
 * To run a mutation, you first call `useUpsert_QuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsert_QuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertQuestionsMutation, { data, loading, error }] = useUpsert_QuestionsMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpsert_QuestionsMutation(baseOptions?: Apollo.MutationHookOptions<Upsert_QuestionsMutation, Upsert_QuestionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Upsert_QuestionsMutation, Upsert_QuestionsMutationVariables>(Upsert_QuestionsDocument, options);
      }
export type Upsert_QuestionsMutationHookResult = ReturnType<typeof useUpsert_QuestionsMutation>;
export type Upsert_QuestionsMutationResult = Apollo.MutationResult<Upsert_QuestionsMutation>;
export type Upsert_QuestionsMutationOptions = Apollo.BaseMutationOptions<Upsert_QuestionsMutation, Upsert_QuestionsMutationVariables>;
export const Add_Initial_QuestionDocument = gql`
    mutation ADD_INITIAL_QUESTION($text: String!, $options: examsonline_options_arr_rel_insert_input = {data: []}, $order: Int = 1, $section: uuid = "", $quiz_id: uuid = null, $test_id: Int!) {
  insert_examsonline_questions(
    objects: {marks: 1, order: $order, text: $text, type: 0, options: $options, section: $section, quiz_id: $quiz_id, test_id: $test_id}
  ) {
    returning {
      id
      text
      order
      section
      options {
        id
        value
        isCorrect
        type
      }
    }
  }
}
    `;
export type Add_Initial_QuestionMutationFn = Apollo.MutationFunction<Add_Initial_QuestionMutation, Add_Initial_QuestionMutationVariables>;

/**
 * __useAdd_Initial_QuestionMutation__
 *
 * To run a mutation, you first call `useAdd_Initial_QuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_Initial_QuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInitialQuestionMutation, { data, loading, error }] = useAdd_Initial_QuestionMutation({
 *   variables: {
 *      text: // value for 'text'
 *      options: // value for 'options'
 *      order: // value for 'order'
 *      section: // value for 'section'
 *      quiz_id: // value for 'quiz_id'
 *      test_id: // value for 'test_id'
 *   },
 * });
 */
export function useAdd_Initial_QuestionMutation(baseOptions?: Apollo.MutationHookOptions<Add_Initial_QuestionMutation, Add_Initial_QuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Add_Initial_QuestionMutation, Add_Initial_QuestionMutationVariables>(Add_Initial_QuestionDocument, options);
      }
export type Add_Initial_QuestionMutationHookResult = ReturnType<typeof useAdd_Initial_QuestionMutation>;
export type Add_Initial_QuestionMutationResult = Apollo.MutationResult<Add_Initial_QuestionMutation>;
export type Add_Initial_QuestionMutationOptions = Apollo.BaseMutationOptions<Add_Initial_QuestionMutation, Add_Initial_QuestionMutationVariables>;
export const Upsert_OptionDocument = gql`
    mutation upsert_option($objects: [examsonline_options_insert_input!]!, $update_columns: [examsonline_options_update_column!] = id) {
  insert_examsonline_options(
    objects: $objects
    on_conflict: {constraint: options_pkey, update_columns: $update_columns}
  ) {
    returning {
      id
      q_id
      isCorrect
      type
      value
    }
  }
}
    `;
export type Upsert_OptionMutationFn = Apollo.MutationFunction<Upsert_OptionMutation, Upsert_OptionMutationVariables>;

/**
 * __useUpsert_OptionMutation__
 *
 * To run a mutation, you first call `useUpsert_OptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsert_OptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertOptionMutation, { data, loading, error }] = useUpsert_OptionMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      update_columns: // value for 'update_columns'
 *   },
 * });
 */
export function useUpsert_OptionMutation(baseOptions?: Apollo.MutationHookOptions<Upsert_OptionMutation, Upsert_OptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Upsert_OptionMutation, Upsert_OptionMutationVariables>(Upsert_OptionDocument, options);
      }
export type Upsert_OptionMutationHookResult = ReturnType<typeof useUpsert_OptionMutation>;
export type Upsert_OptionMutationResult = Apollo.MutationResult<Upsert_OptionMutation>;
export type Upsert_OptionMutationOptions = Apollo.BaseMutationOptions<Upsert_OptionMutation, Upsert_OptionMutationVariables>;
export const Get_All_Tests_By_AuthorsDocument = gql`
    query GET_ALL_TESTS_BY_AUTHORS($_iregex: String = "", $limit: Int = 5, $offset: Int = 0, $order_by: [examsonline_tests_order_by!] = {createdAt: desc}, $_eq: String = "", $enabled: Boolean_comparison_exp = {}, $createdAt: timestamp_comparison_exp = {}) @cached {
  examsonline_tests(
    order_by: $order_by
    where: {_or: [{name: {_iregex: $_iregex}}, {topics: {tag: {_iregex: $_iregex}}}], author: {_eq: $_eq}, enabled: $enabled, createdAt: $createdAt}
    limit: $limit
    offset: $offset
  ) {
    author
    createdAt
    description
    document
    maxAttempts
    pincode
    id
    time
    enabled
    updatedAt
    isDraft
    points
    attempts_aggregate {
      aggregate {
        count
      }
    }
    questions {
      id
    }
    topics {
      tag
    }
    files {
      id
      info
    }
    name
  }
  examsonline_tests_aggregate(
    where: {_or: [{name: {_iregex: $_iregex}}, {topics: {tag: {_iregex: $_iregex}}}], author: {_eq: $_eq}, createdAt: $createdAt, enabled: $enabled}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGet_All_Tests_By_AuthorsQuery__
 *
 * To run a query within a React component, call `useGet_All_Tests_By_AuthorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_All_Tests_By_AuthorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_All_Tests_By_AuthorsQuery({
 *   variables: {
 *      _iregex: // value for '_iregex'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      _eq: // value for '_eq'
 *      enabled: // value for 'enabled'
 *      createdAt: // value for 'createdAt'
 *   },
 * });
 */
export function useGet_All_Tests_By_AuthorsQuery(baseOptions?: Apollo.QueryHookOptions<Get_All_Tests_By_AuthorsQuery, Get_All_Tests_By_AuthorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_All_Tests_By_AuthorsQuery, Get_All_Tests_By_AuthorsQueryVariables>(Get_All_Tests_By_AuthorsDocument, options);
      }
export function useGet_All_Tests_By_AuthorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_All_Tests_By_AuthorsQuery, Get_All_Tests_By_AuthorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_All_Tests_By_AuthorsQuery, Get_All_Tests_By_AuthorsQueryVariables>(Get_All_Tests_By_AuthorsDocument, options);
        }
export function useGet_All_Tests_By_AuthorsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_All_Tests_By_AuthorsQuery, Get_All_Tests_By_AuthorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_All_Tests_By_AuthorsQuery, Get_All_Tests_By_AuthorsQueryVariables>(Get_All_Tests_By_AuthorsDocument, options);
        }
export type Get_All_Tests_By_AuthorsQueryHookResult = ReturnType<typeof useGet_All_Tests_By_AuthorsQuery>;
export type Get_All_Tests_By_AuthorsLazyQueryHookResult = ReturnType<typeof useGet_All_Tests_By_AuthorsLazyQuery>;
export type Get_All_Tests_By_AuthorsSuspenseQueryHookResult = ReturnType<typeof useGet_All_Tests_By_AuthorsSuspenseQuery>;
export type Get_All_Tests_By_AuthorsQueryResult = Apollo.QueryResult<Get_All_Tests_By_AuthorsQuery, Get_All_Tests_By_AuthorsQueryVariables>;
export const Update_Option_By_IdDocument = gql`
    mutation UPDATE_OPTION_BY_ID($id: Int!, $_set: examsonline_options_set_input!) {
  update_examsonline_options(where: {id: {_eq: $id}}, _inc: {}, _set: $_set) {
    returning {
      id
      isCorrect
      value
    }
  }
}
    `;
export type Update_Option_By_IdMutationFn = Apollo.MutationFunction<Update_Option_By_IdMutation, Update_Option_By_IdMutationVariables>;

/**
 * __useUpdate_Option_By_IdMutation__
 *
 * To run a mutation, you first call `useUpdate_Option_By_IdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Option_By_IdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOptionByIdMutation, { data, loading, error }] = useUpdate_Option_By_IdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdate_Option_By_IdMutation(baseOptions?: Apollo.MutationHookOptions<Update_Option_By_IdMutation, Update_Option_By_IdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_Option_By_IdMutation, Update_Option_By_IdMutationVariables>(Update_Option_By_IdDocument, options);
      }
export type Update_Option_By_IdMutationHookResult = ReturnType<typeof useUpdate_Option_By_IdMutation>;
export type Update_Option_By_IdMutationResult = Apollo.MutationResult<Update_Option_By_IdMutation>;
export type Update_Option_By_IdMutationOptions = Apollo.BaseMutationOptions<Update_Option_By_IdMutation, Update_Option_By_IdMutationVariables>;
export const TestcountDocument = gql`
    query testcount($author: String!) {
  examsonline_tests_aggregate(where: {author: {_eq: $author}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useTestcountQuery__
 *
 * To run a query within a React component, call `useTestcountQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestcountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestcountQuery({
 *   variables: {
 *      author: // value for 'author'
 *   },
 * });
 */
export function useTestcountQuery(baseOptions: Apollo.QueryHookOptions<TestcountQuery, TestcountQueryVariables> & ({ variables: TestcountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TestcountQuery, TestcountQueryVariables>(TestcountDocument, options);
      }
export function useTestcountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TestcountQuery, TestcountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TestcountQuery, TestcountQueryVariables>(TestcountDocument, options);
        }
export function useTestcountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TestcountQuery, TestcountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TestcountQuery, TestcountQueryVariables>(TestcountDocument, options);
        }
export type TestcountQueryHookResult = ReturnType<typeof useTestcountQuery>;
export type TestcountLazyQueryHookResult = ReturnType<typeof useTestcountLazyQuery>;
export type TestcountSuspenseQueryHookResult = ReturnType<typeof useTestcountSuspenseQuery>;
export type TestcountQueryResult = Apollo.QueryResult<TestcountQuery, TestcountQueryVariables>;
export const Add_Selected_OptionDocument = gql`
    mutation ADD_SELECTED_OPTION($object: examsonline_selected_options_insert_input = {}, $update_columns: [examsonline_selected_options_update_column!] = attempt_id) {
  insert_examsonline_selected_options_one(
    object: $object
    on_conflict: {constraint: selected_options_pkey, update_columns: $update_columns}
  ) {
    attempt_id
    option_id
    type
    value
  }
}
    `;
export type Add_Selected_OptionMutationFn = Apollo.MutationFunction<Add_Selected_OptionMutation, Add_Selected_OptionMutationVariables>;

/**
 * __useAdd_Selected_OptionMutation__
 *
 * To run a mutation, you first call `useAdd_Selected_OptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_Selected_OptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSelectedOptionMutation, { data, loading, error }] = useAdd_Selected_OptionMutation({
 *   variables: {
 *      object: // value for 'object'
 *      update_columns: // value for 'update_columns'
 *   },
 * });
 */
export function useAdd_Selected_OptionMutation(baseOptions?: Apollo.MutationHookOptions<Add_Selected_OptionMutation, Add_Selected_OptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Add_Selected_OptionMutation, Add_Selected_OptionMutationVariables>(Add_Selected_OptionDocument, options);
      }
export type Add_Selected_OptionMutationHookResult = ReturnType<typeof useAdd_Selected_OptionMutation>;
export type Add_Selected_OptionMutationResult = Apollo.MutationResult<Add_Selected_OptionMutation>;
export type Add_Selected_OptionMutationOptions = Apollo.BaseMutationOptions<Add_Selected_OptionMutation, Add_Selected_OptionMutationVariables>;
export const Delete_Selected_OptionsDocument = gql`
    mutation DELETE_SELECTED_OPTIONS($_eq: uuid = "", $_eq1: Int = 10) {
  delete_examsonline_selected_options(
    where: {attempt_id: {_eq: $_eq}, option_id: {_eq: $_eq1}}
  ) {
    returning {
      attempt_id
      option_id
    }
  }
}
    `;
export type Delete_Selected_OptionsMutationFn = Apollo.MutationFunction<Delete_Selected_OptionsMutation, Delete_Selected_OptionsMutationVariables>;

/**
 * __useDelete_Selected_OptionsMutation__
 *
 * To run a mutation, you first call `useDelete_Selected_OptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_Selected_OptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSelectedOptionsMutation, { data, loading, error }] = useDelete_Selected_OptionsMutation({
 *   variables: {
 *      _eq: // value for '_eq'
 *      _eq1: // value for '_eq1'
 *   },
 * });
 */
export function useDelete_Selected_OptionsMutation(baseOptions?: Apollo.MutationHookOptions<Delete_Selected_OptionsMutation, Delete_Selected_OptionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Delete_Selected_OptionsMutation, Delete_Selected_OptionsMutationVariables>(Delete_Selected_OptionsDocument, options);
      }
export type Delete_Selected_OptionsMutationHookResult = ReturnType<typeof useDelete_Selected_OptionsMutation>;
export type Delete_Selected_OptionsMutationResult = Apollo.MutationResult<Delete_Selected_OptionsMutation>;
export type Delete_Selected_OptionsMutationOptions = Apollo.BaseMutationOptions<Delete_Selected_OptionsMutation, Delete_Selected_OptionsMutationVariables>;
export const Get_User_InfoDocument = gql`
    query GET_USER_INFO($_eq: String = "") {
  examsonline_user(where: {email: {_eq: $_eq}}) {
    organisation {
      id
      name
    }
    email
    username
    tourCompleted
    role
    id
    roles(order_by: {organisation: {roles_aggregate: {count: desc}}}) {
      id
      role
      organisation {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGet_User_InfoQuery__
 *
 * To run a query within a React component, call `useGet_User_InfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_User_InfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_User_InfoQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_User_InfoQuery(baseOptions?: Apollo.QueryHookOptions<Get_User_InfoQuery, Get_User_InfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_User_InfoQuery, Get_User_InfoQueryVariables>(Get_User_InfoDocument, options);
      }
export function useGet_User_InfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_User_InfoQuery, Get_User_InfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_User_InfoQuery, Get_User_InfoQueryVariables>(Get_User_InfoDocument, options);
        }
export function useGet_User_InfoSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_User_InfoQuery, Get_User_InfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_User_InfoQuery, Get_User_InfoQueryVariables>(Get_User_InfoDocument, options);
        }
export type Get_User_InfoQueryHookResult = ReturnType<typeof useGet_User_InfoQuery>;
export type Get_User_InfoLazyQueryHookResult = ReturnType<typeof useGet_User_InfoLazyQuery>;
export type Get_User_InfoSuspenseQueryHookResult = ReturnType<typeof useGet_User_InfoSuspenseQuery>;
export type Get_User_InfoQueryResult = Apollo.QueryResult<Get_User_InfoQuery, Get_User_InfoQueryVariables>;
export const Delete_OptionDocument = gql`
    mutation DELETE_OPTION($id: Int = 10) {
  delete_examsonline_options_by_pk(id: $id) {
    id
  }
}
    `;
export type Delete_OptionMutationFn = Apollo.MutationFunction<Delete_OptionMutation, Delete_OptionMutationVariables>;

/**
 * __useDelete_OptionMutation__
 *
 * To run a mutation, you first call `useDelete_OptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_OptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOptionMutation, { data, loading, error }] = useDelete_OptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDelete_OptionMutation(baseOptions?: Apollo.MutationHookOptions<Delete_OptionMutation, Delete_OptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Delete_OptionMutation, Delete_OptionMutationVariables>(Delete_OptionDocument, options);
      }
export type Delete_OptionMutationHookResult = ReturnType<typeof useDelete_OptionMutation>;
export type Delete_OptionMutationResult = Apollo.MutationResult<Delete_OptionMutation>;
export type Delete_OptionMutationOptions = Apollo.BaseMutationOptions<Delete_OptionMutation, Delete_OptionMutationVariables>;
export const Delete_Options_By_QidDocument = gql`
    mutation DELETE_OPTIONS_BY_QID($_eq: Int = 10) {
  delete_examsonline_options(where: {_and: {q_id: {_eq: $_eq}}}) {
    returning {
      id
    }
  }
}
    `;
export type Delete_Options_By_QidMutationFn = Apollo.MutationFunction<Delete_Options_By_QidMutation, Delete_Options_By_QidMutationVariables>;

/**
 * __useDelete_Options_By_QidMutation__
 *
 * To run a mutation, you first call `useDelete_Options_By_QidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_Options_By_QidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOptionsByQidMutation, { data, loading, error }] = useDelete_Options_By_QidMutation({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useDelete_Options_By_QidMutation(baseOptions?: Apollo.MutationHookOptions<Delete_Options_By_QidMutation, Delete_Options_By_QidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Delete_Options_By_QidMutation, Delete_Options_By_QidMutationVariables>(Delete_Options_By_QidDocument, options);
      }
export type Delete_Options_By_QidMutationHookResult = ReturnType<typeof useDelete_Options_By_QidMutation>;
export type Delete_Options_By_QidMutationResult = Apollo.MutationResult<Delete_Options_By_QidMutation>;
export type Delete_Options_By_QidMutationOptions = Apollo.BaseMutationOptions<Delete_Options_By_QidMutation, Delete_Options_By_QidMutationVariables>;
export const Get_RatingDocument = gql`
    query GET_RATING($_eq1: Int = 10) {
  examsonline_rating(where: {test_id: {_eq: $_eq1}}) {
    id
    test_id
    value
  }
}
    `;

/**
 * __useGet_RatingQuery__
 *
 * To run a query within a React component, call `useGet_RatingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_RatingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_RatingQuery({
 *   variables: {
 *      _eq1: // value for '_eq1'
 *   },
 * });
 */
export function useGet_RatingQuery(baseOptions?: Apollo.QueryHookOptions<Get_RatingQuery, Get_RatingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_RatingQuery, Get_RatingQueryVariables>(Get_RatingDocument, options);
      }
export function useGet_RatingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_RatingQuery, Get_RatingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_RatingQuery, Get_RatingQueryVariables>(Get_RatingDocument, options);
        }
export function useGet_RatingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_RatingQuery, Get_RatingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_RatingQuery, Get_RatingQueryVariables>(Get_RatingDocument, options);
        }
export type Get_RatingQueryHookResult = ReturnType<typeof useGet_RatingQuery>;
export type Get_RatingLazyQueryHookResult = ReturnType<typeof useGet_RatingLazyQuery>;
export type Get_RatingSuspenseQueryHookResult = ReturnType<typeof useGet_RatingSuspenseQuery>;
export type Get_RatingQueryResult = Apollo.QueryResult<Get_RatingQuery, Get_RatingQueryVariables>;
export const Add_RatingDocument = gql`
    mutation ADD_RATING($objects: [examsonline_rating_insert_input!] = {}) {
  insert_examsonline_rating(
    objects: $objects
    on_conflict: {constraint: rating_pkey, update_columns: value}
  ) {
    returning {
      id
      test_id
      value
    }
  }
}
    `;
export type Add_RatingMutationFn = Apollo.MutationFunction<Add_RatingMutation, Add_RatingMutationVariables>;

/**
 * __useAdd_RatingMutation__
 *
 * To run a mutation, you first call `useAdd_RatingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_RatingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRatingMutation, { data, loading, error }] = useAdd_RatingMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useAdd_RatingMutation(baseOptions?: Apollo.MutationHookOptions<Add_RatingMutation, Add_RatingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Add_RatingMutation, Add_RatingMutationVariables>(Add_RatingDocument, options);
      }
export type Add_RatingMutationHookResult = ReturnType<typeof useAdd_RatingMutation>;
export type Add_RatingMutationResult = Apollo.MutationResult<Add_RatingMutation>;
export type Add_RatingMutationOptions = Apollo.BaseMutationOptions<Add_RatingMutation, Add_RatingMutationVariables>;
export const Get_Selected_OptionsDocument = gql`
    query GET_SELECTED_OPTIONS($_eq: Int = 10, $_eq1: uuid = "") {
  examsonline_selected_options(
    where: {option: {q_id: {_eq: $_eq}}, attempt_id: {_eq: $_eq1}}
  ) {
    value
    type
    attempt_id
    id: option_id
  }
}
    `;

/**
 * __useGet_Selected_OptionsQuery__
 *
 * To run a query within a React component, call `useGet_Selected_OptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Selected_OptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Selected_OptionsQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *      _eq1: // value for '_eq1'
 *   },
 * });
 */
export function useGet_Selected_OptionsQuery(baseOptions?: Apollo.QueryHookOptions<Get_Selected_OptionsQuery, Get_Selected_OptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_Selected_OptionsQuery, Get_Selected_OptionsQueryVariables>(Get_Selected_OptionsDocument, options);
      }
export function useGet_Selected_OptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Selected_OptionsQuery, Get_Selected_OptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_Selected_OptionsQuery, Get_Selected_OptionsQueryVariables>(Get_Selected_OptionsDocument, options);
        }
export function useGet_Selected_OptionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_Selected_OptionsQuery, Get_Selected_OptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_Selected_OptionsQuery, Get_Selected_OptionsQueryVariables>(Get_Selected_OptionsDocument, options);
        }
export type Get_Selected_OptionsQueryHookResult = ReturnType<typeof useGet_Selected_OptionsQuery>;
export type Get_Selected_OptionsLazyQueryHookResult = ReturnType<typeof useGet_Selected_OptionsLazyQuery>;
export type Get_Selected_OptionsSuspenseQueryHookResult = ReturnType<typeof useGet_Selected_OptionsSuspenseQuery>;
export type Get_Selected_OptionsQueryResult = Apollo.QueryResult<Get_Selected_OptionsQuery, Get_Selected_OptionsQueryVariables>;
export const Get_Answer_SheetDocument = gql`
    query GET_ANSWER_SHEET($_eq: uuid = "") {
  examsonline_answers(
    where: {attempt_id: {_eq: $_eq}}
    order_by: {question: {order: asc}}
  ) {
    id
    score
    type
    attempt {
      test_id
      email
    }
    question {
      id
      marks
      order
      type
      text
      section
      answers(where: {attempt_id: {_eq: $_eq}}) {
        score
      }
      selected_options(where: {attempt_id: {_eq: $_eq}}) {
        value
        type
      }
    }
  }
}
    `;

/**
 * __useGet_Answer_SheetQuery__
 *
 * To run a query within a React component, call `useGet_Answer_SheetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Answer_SheetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Answer_SheetQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_Answer_SheetQuery(baseOptions?: Apollo.QueryHookOptions<Get_Answer_SheetQuery, Get_Answer_SheetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_Answer_SheetQuery, Get_Answer_SheetQueryVariables>(Get_Answer_SheetDocument, options);
      }
export function useGet_Answer_SheetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Answer_SheetQuery, Get_Answer_SheetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_Answer_SheetQuery, Get_Answer_SheetQueryVariables>(Get_Answer_SheetDocument, options);
        }
export function useGet_Answer_SheetSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_Answer_SheetQuery, Get_Answer_SheetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_Answer_SheetQuery, Get_Answer_SheetQueryVariables>(Get_Answer_SheetDocument, options);
        }
export type Get_Answer_SheetQueryHookResult = ReturnType<typeof useGet_Answer_SheetQuery>;
export type Get_Answer_SheetLazyQueryHookResult = ReturnType<typeof useGet_Answer_SheetLazyQuery>;
export type Get_Answer_SheetSuspenseQueryHookResult = ReturnType<typeof useGet_Answer_SheetSuspenseQuery>;
export type Get_Answer_SheetQueryResult = Apollo.QueryResult<Get_Answer_SheetQuery, Get_Answer_SheetQueryVariables>;
export const Get_All_Selected_OptionsDocument = gql`
    query GET_ALL_SELECTED_OPTIONS($_eq1: uuid = "") {
  examsonline_selected_options(where: {attempt_id: {_eq: $_eq1}}) {
    value
    type
    attempt_id
    id: option_id
    isCorrect
  }
}
    `;

/**
 * __useGet_All_Selected_OptionsQuery__
 *
 * To run a query within a React component, call `useGet_All_Selected_OptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_All_Selected_OptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_All_Selected_OptionsQuery({
 *   variables: {
 *      _eq1: // value for '_eq1'
 *   },
 * });
 */
export function useGet_All_Selected_OptionsQuery(baseOptions?: Apollo.QueryHookOptions<Get_All_Selected_OptionsQuery, Get_All_Selected_OptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_All_Selected_OptionsQuery, Get_All_Selected_OptionsQueryVariables>(Get_All_Selected_OptionsDocument, options);
      }
export function useGet_All_Selected_OptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_All_Selected_OptionsQuery, Get_All_Selected_OptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_All_Selected_OptionsQuery, Get_All_Selected_OptionsQueryVariables>(Get_All_Selected_OptionsDocument, options);
        }
export function useGet_All_Selected_OptionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_All_Selected_OptionsQuery, Get_All_Selected_OptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_All_Selected_OptionsQuery, Get_All_Selected_OptionsQueryVariables>(Get_All_Selected_OptionsDocument, options);
        }
export type Get_All_Selected_OptionsQueryHookResult = ReturnType<typeof useGet_All_Selected_OptionsQuery>;
export type Get_All_Selected_OptionsLazyQueryHookResult = ReturnType<typeof useGet_All_Selected_OptionsLazyQuery>;
export type Get_All_Selected_OptionsSuspenseQueryHookResult = ReturnType<typeof useGet_All_Selected_OptionsSuspenseQuery>;
export type Get_All_Selected_OptionsQueryResult = Apollo.QueryResult<Get_All_Selected_OptionsQuery, Get_All_Selected_OptionsQueryVariables>;
export const Get_Top_StudentsDocument = gql`
    query GET_TOP_STUDENTS($limit: Int = 10, $_eq2: Int = 10, $_perc_order_by: order_by = desc_nulls_last, $_correct_order_by: order_by = desc) {
  examsonline_attempt(
    limit: $limit
    order_by: {percentage: $_perc_order_by, correct: $_correct_order_by}
    where: {test_id: {_eq: $_eq2}}
  ) {
    percentage
    email
    createdAt
    correct
    skipped
    wrong
    count: answers_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGet_Top_StudentsQuery__
 *
 * To run a query within a React component, call `useGet_Top_StudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Top_StudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Top_StudentsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      _eq2: // value for '_eq2'
 *      _perc_order_by: // value for '_perc_order_by'
 *      _correct_order_by: // value for '_correct_order_by'
 *   },
 * });
 */
export function useGet_Top_StudentsQuery(baseOptions?: Apollo.QueryHookOptions<Get_Top_StudentsQuery, Get_Top_StudentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_Top_StudentsQuery, Get_Top_StudentsQueryVariables>(Get_Top_StudentsDocument, options);
      }
export function useGet_Top_StudentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Top_StudentsQuery, Get_Top_StudentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_Top_StudentsQuery, Get_Top_StudentsQueryVariables>(Get_Top_StudentsDocument, options);
        }
export function useGet_Top_StudentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_Top_StudentsQuery, Get_Top_StudentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_Top_StudentsQuery, Get_Top_StudentsQueryVariables>(Get_Top_StudentsDocument, options);
        }
export type Get_Top_StudentsQueryHookResult = ReturnType<typeof useGet_Top_StudentsQuery>;
export type Get_Top_StudentsLazyQueryHookResult = ReturnType<typeof useGet_Top_StudentsLazyQuery>;
export type Get_Top_StudentsSuspenseQueryHookResult = ReturnType<typeof useGet_Top_StudentsSuspenseQuery>;
export type Get_Top_StudentsQueryResult = Apollo.QueryResult<Get_Top_StudentsQuery, Get_Top_StudentsQueryVariables>;
export const Update_ScoreDocument = gql`
    mutation UPDATE_SCORE($_eq: uuid = "", $_eq1: Int = 10, $score: Int = 10) {
  update_examsonline_answers(
    where: {attempt_id: {_eq: $_eq}, q_id: {_eq: $_eq1}}
    _set: {score: $score}
  ) {
    returning {
      score
    }
  }
}
    `;
export type Update_ScoreMutationFn = Apollo.MutationFunction<Update_ScoreMutation, Update_ScoreMutationVariables>;

/**
 * __useUpdate_ScoreMutation__
 *
 * To run a mutation, you first call `useUpdate_ScoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_ScoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScoreMutation, { data, loading, error }] = useUpdate_ScoreMutation({
 *   variables: {
 *      _eq: // value for '_eq'
 *      _eq1: // value for '_eq1'
 *      score: // value for 'score'
 *   },
 * });
 */
export function useUpdate_ScoreMutation(baseOptions?: Apollo.MutationHookOptions<Update_ScoreMutation, Update_ScoreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_ScoreMutation, Update_ScoreMutationVariables>(Update_ScoreDocument, options);
      }
export type Update_ScoreMutationHookResult = ReturnType<typeof useUpdate_ScoreMutation>;
export type Update_ScoreMutationResult = Apollo.MutationResult<Update_ScoreMutation>;
export type Update_ScoreMutationOptions = Apollo.BaseMutationOptions<Update_ScoreMutation, Update_ScoreMutationVariables>;
export const Search_QuestionsDocument = gql`
    query SEARCH_QUESTIONS($_iregex1: String = "", $_iregex2: String = "", $limit: Int = 10, $offset: Int = 10) {
  examsonline_questions(
    where: {_or: {test: {topics: {tag: {_iregex: $_iregex1}}}}, test: {name: {_iregex: $_iregex2}}}
    limit: $limit
    offset: $offset
  ) {
    id
    marks
    type
    text
    options {
      id
      value
      isCorrect
      type
    }
    test {
      name
      author
      ratings_aggregate {
        aggregate {
          avg {
            value
          }
        }
      }
    }
  }
  total_count: examsonline_questions_aggregate(
    where: {_or: {test: {topics: {tag: {_iregex: $_iregex1}}}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useSearch_QuestionsQuery__
 *
 * To run a query within a React component, call `useSearch_QuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearch_QuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearch_QuestionsQuery({
 *   variables: {
 *      _iregex1: // value for '_iregex1'
 *      _iregex2: // value for '_iregex2'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useSearch_QuestionsQuery(baseOptions?: Apollo.QueryHookOptions<Search_QuestionsQuery, Search_QuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Search_QuestionsQuery, Search_QuestionsQueryVariables>(Search_QuestionsDocument, options);
      }
export function useSearch_QuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Search_QuestionsQuery, Search_QuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Search_QuestionsQuery, Search_QuestionsQueryVariables>(Search_QuestionsDocument, options);
        }
export function useSearch_QuestionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Search_QuestionsQuery, Search_QuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Search_QuestionsQuery, Search_QuestionsQueryVariables>(Search_QuestionsDocument, options);
        }
export type Search_QuestionsQueryHookResult = ReturnType<typeof useSearch_QuestionsQuery>;
export type Search_QuestionsLazyQueryHookResult = ReturnType<typeof useSearch_QuestionsLazyQuery>;
export type Search_QuestionsSuspenseQueryHookResult = ReturnType<typeof useSearch_QuestionsSuspenseQuery>;
export type Search_QuestionsQueryResult = Apollo.QueryResult<Search_QuestionsQuery, Search_QuestionsQueryVariables>;
export const Update_PassDocument = gql`
    mutation UPDATE_PASS($email: String = "", $password: String = "") {
  update_examsonline_user(
    where: {email: {_eq: $email}}
    _set: {email: $email, password: $password}
  ) {
    returning {
      email
    }
  }
}
    `;
export type Update_PassMutationFn = Apollo.MutationFunction<Update_PassMutation, Update_PassMutationVariables>;

/**
 * __useUpdate_PassMutation__
 *
 * To run a mutation, you first call `useUpdate_PassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_PassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePassMutation, { data, loading, error }] = useUpdate_PassMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUpdate_PassMutation(baseOptions?: Apollo.MutationHookOptions<Update_PassMutation, Update_PassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_PassMutation, Update_PassMutationVariables>(Update_PassDocument, options);
      }
export type Update_PassMutationHookResult = ReturnType<typeof useUpdate_PassMutation>;
export type Update_PassMutationResult = Apollo.MutationResult<Update_PassMutation>;
export type Update_PassMutationOptions = Apollo.BaseMutationOptions<Update_PassMutation, Update_PassMutationVariables>;
export const Search_Questions_By_TagDocument = gql`
    query SEARCH_QUESTIONS_BY_TAG($_ilike: String = "") {
  examsonline_questions(where: {test: {topics: {tag: {_ilike: $_ilike}}}}) {
    text
    id
    author: test {
      author
    }
    incorrect_count: answers_aggregate(where: {isCorrect: {_eq: false}}) {
      aggregate {
        count
      }
    }
    correct_count: answers_aggregate(where: {isCorrect: {_eq: false}}) {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useSearch_Questions_By_TagQuery__
 *
 * To run a query within a React component, call `useSearch_Questions_By_TagQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearch_Questions_By_TagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearch_Questions_By_TagQuery({
 *   variables: {
 *      _ilike: // value for '_ilike'
 *   },
 * });
 */
export function useSearch_Questions_By_TagQuery(baseOptions?: Apollo.QueryHookOptions<Search_Questions_By_TagQuery, Search_Questions_By_TagQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Search_Questions_By_TagQuery, Search_Questions_By_TagQueryVariables>(Search_Questions_By_TagDocument, options);
      }
export function useSearch_Questions_By_TagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Search_Questions_By_TagQuery, Search_Questions_By_TagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Search_Questions_By_TagQuery, Search_Questions_By_TagQueryVariables>(Search_Questions_By_TagDocument, options);
        }
export function useSearch_Questions_By_TagSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Search_Questions_By_TagQuery, Search_Questions_By_TagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Search_Questions_By_TagQuery, Search_Questions_By_TagQueryVariables>(Search_Questions_By_TagDocument, options);
        }
export type Search_Questions_By_TagQueryHookResult = ReturnType<typeof useSearch_Questions_By_TagQuery>;
export type Search_Questions_By_TagLazyQueryHookResult = ReturnType<typeof useSearch_Questions_By_TagLazyQuery>;
export type Search_Questions_By_TagSuspenseQueryHookResult = ReturnType<typeof useSearch_Questions_By_TagSuspenseQuery>;
export type Search_Questions_By_TagQueryResult = Apollo.QueryResult<Search_Questions_By_TagQuery, Search_Questions_By_TagQueryVariables>;
export const Register_UserDocument = gql`
    mutation REGISTER_USER($email: String = "", $password: String = "", $org1: uuid = "") {
  insert_examsonline_user(
    objects: {email: $email, password: $password, org: $org1}
  ) {
    returning {
      email
    }
  }
}
    `;
export type Register_UserMutationFn = Apollo.MutationFunction<Register_UserMutation, Register_UserMutationVariables>;

/**
 * __useRegister_UserMutation__
 *
 * To run a mutation, you first call `useRegister_UserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegister_UserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegister_UserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      org1: // value for 'org1'
 *   },
 * });
 */
export function useRegister_UserMutation(baseOptions?: Apollo.MutationHookOptions<Register_UserMutation, Register_UserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Register_UserMutation, Register_UserMutationVariables>(Register_UserDocument, options);
      }
export type Register_UserMutationHookResult = ReturnType<typeof useRegister_UserMutation>;
export type Register_UserMutationResult = Apollo.MutationResult<Register_UserMutation>;
export type Register_UserMutationOptions = Apollo.BaseMutationOptions<Register_UserMutation, Register_UserMutationVariables>;
export const Add_SectionDocument = gql`
    mutation ADD_SECTION($name: String = "", $test_id: Int = 10) {
  insert_examsonline_test_sections(objects: {name: $name, test_id: $test_id}) {
    returning {
      name
      id
      test_id
    }
  }
}
    `;
export type Add_SectionMutationFn = Apollo.MutationFunction<Add_SectionMutation, Add_SectionMutationVariables>;

/**
 * __useAdd_SectionMutation__
 *
 * To run a mutation, you first call `useAdd_SectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_SectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSectionMutation, { data, loading, error }] = useAdd_SectionMutation({
 *   variables: {
 *      name: // value for 'name'
 *      test_id: // value for 'test_id'
 *   },
 * });
 */
export function useAdd_SectionMutation(baseOptions?: Apollo.MutationHookOptions<Add_SectionMutation, Add_SectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Add_SectionMutation, Add_SectionMutationVariables>(Add_SectionDocument, options);
      }
export type Add_SectionMutationHookResult = ReturnType<typeof useAdd_SectionMutation>;
export type Add_SectionMutationResult = Apollo.MutationResult<Add_SectionMutation>;
export type Add_SectionMutationOptions = Apollo.BaseMutationOptions<Add_SectionMutation, Add_SectionMutationVariables>;
export const Get_CommentsDocument = gql`
    query GET_COMMENTS($limit: Int = 6, $offset: Int = 0, $_eq: Int = 0, $ticket_id: uuid = "00000000-0000-0000-0000-000000000000", $asg_id: uuid = "00000000-0000-0000-0000-000000000000", $ref_id: String = "00000000-0000-0000-0000-000000000000") {
  examsonline_comments(
    where: {_or: [{test_id: {_eq: $_eq}}, {ticket_id: {_eq: $ticket_id}}, {asg_id: {_eq: $asg_id}}, {ref_id: {_eq: $ref_id}}]}
    limit: $limit
    offset: $offset
    order_by: {createdAt: desc}
  ) {
    ...CommentFragment
  }
  total_count: examsonline_comments_aggregate(
    where: {_or: [{test_id: {_eq: $_eq}}, {ticket_id: {_eq: $ticket_id}}, {asg_id: {_eq: $asg_id}}, {ref_id: {_eq: $ref_id}}]}
  ) {
    aggregate {
      count
    }
  }
}
    ${CommentFragmentFragmentDoc}`;

/**
 * __useGet_CommentsQuery__
 *
 * To run a query within a React component, call `useGet_CommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_CommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_CommentsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      _eq: // value for '_eq'
 *      ticket_id: // value for 'ticket_id'
 *      asg_id: // value for 'asg_id'
 *      ref_id: // value for 'ref_id'
 *   },
 * });
 */
export function useGet_CommentsQuery(baseOptions?: Apollo.QueryHookOptions<Get_CommentsQuery, Get_CommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_CommentsQuery, Get_CommentsQueryVariables>(Get_CommentsDocument, options);
      }
export function useGet_CommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_CommentsQuery, Get_CommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_CommentsQuery, Get_CommentsQueryVariables>(Get_CommentsDocument, options);
        }
export function useGet_CommentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_CommentsQuery, Get_CommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_CommentsQuery, Get_CommentsQueryVariables>(Get_CommentsDocument, options);
        }
export type Get_CommentsQueryHookResult = ReturnType<typeof useGet_CommentsQuery>;
export type Get_CommentsLazyQueryHookResult = ReturnType<typeof useGet_CommentsLazyQuery>;
export type Get_CommentsSuspenseQueryHookResult = ReturnType<typeof useGet_CommentsSuspenseQuery>;
export type Get_CommentsQueryResult = Apollo.QueryResult<Get_CommentsQuery, Get_CommentsQueryVariables>;
export const Add_CommentDocument = gql`
    mutation ADD_COMMENT($objects: [examsonline_comments_insert_input!] = {}, $update_columns: [examsonline_comments_update_column!] = createdAt) {
  insert_examsonline_comments(
    objects: $objects
    on_conflict: {constraint: comments_pkey, update_columns: $update_columns}
  ) {
    returning {
      asg_id
      id
      ref_id
      test_id
      ticket_id
      updatedAt
      user
      value
    }
  }
}
    `;
export type Add_CommentMutationFn = Apollo.MutationFunction<Add_CommentMutation, Add_CommentMutationVariables>;

/**
 * __useAdd_CommentMutation__
 *
 * To run a mutation, you first call `useAdd_CommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_CommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCommentMutation, { data, loading, error }] = useAdd_CommentMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      update_columns: // value for 'update_columns'
 *   },
 * });
 */
export function useAdd_CommentMutation(baseOptions?: Apollo.MutationHookOptions<Add_CommentMutation, Add_CommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Add_CommentMutation, Add_CommentMutationVariables>(Add_CommentDocument, options);
      }
export type Add_CommentMutationHookResult = ReturnType<typeof useAdd_CommentMutation>;
export type Add_CommentMutationResult = Apollo.MutationResult<Add_CommentMutation>;
export type Add_CommentMutationOptions = Apollo.BaseMutationOptions<Add_CommentMutation, Add_CommentMutationVariables>;
export const Delete_TestDocument = gql`
    mutation DELETE_TEST($_eq: Int = 10) {
  delete_examsonline_tests(where: {id: {_eq: $_eq}}) {
    returning {
      id
    }
  }
}
    `;
export type Delete_TestMutationFn = Apollo.MutationFunction<Delete_TestMutation, Delete_TestMutationVariables>;

/**
 * __useDelete_TestMutation__
 *
 * To run a mutation, you first call `useDelete_TestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_TestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTestMutation, { data, loading, error }] = useDelete_TestMutation({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useDelete_TestMutation(baseOptions?: Apollo.MutationHookOptions<Delete_TestMutation, Delete_TestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Delete_TestMutation, Delete_TestMutationVariables>(Delete_TestDocument, options);
      }
export type Delete_TestMutationHookResult = ReturnType<typeof useDelete_TestMutation>;
export type Delete_TestMutationResult = Apollo.MutationResult<Delete_TestMutation>;
export type Delete_TestMutationOptions = Apollo.BaseMutationOptions<Delete_TestMutation, Delete_TestMutationVariables>;
export const Update_TourDocument = gql`
    mutation UPDATE_TOUR($_eq: String = "", $tourCompleted: Boolean = false) {
  update_examsonline_user(
    where: {email: {_eq: $_eq}}
    _set: {tourCompleted: $tourCompleted}
  ) {
    returning {
      tourCompleted
      email
    }
  }
}
    `;
export type Update_TourMutationFn = Apollo.MutationFunction<Update_TourMutation, Update_TourMutationVariables>;

/**
 * __useUpdate_TourMutation__
 *
 * To run a mutation, you first call `useUpdate_TourMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_TourMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTourMutation, { data, loading, error }] = useUpdate_TourMutation({
 *   variables: {
 *      _eq: // value for '_eq'
 *      tourCompleted: // value for 'tourCompleted'
 *   },
 * });
 */
export function useUpdate_TourMutation(baseOptions?: Apollo.MutationHookOptions<Update_TourMutation, Update_TourMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_TourMutation, Update_TourMutationVariables>(Update_TourDocument, options);
      }
export type Update_TourMutationHookResult = ReturnType<typeof useUpdate_TourMutation>;
export type Update_TourMutationResult = Apollo.MutationResult<Update_TourMutation>;
export type Update_TourMutationOptions = Apollo.BaseMutationOptions<Update_TourMutation, Update_TourMutationVariables>;
export const Update_Question_SectionDocument = gql`
    mutation UPDATE_QUESTION_SECTION($section: uuid = "", $_eq: Int = 10, $_eq1: uuid = "") {
  update_examsonline_questions(
    where: {test_id: {_eq: $_eq}, section: {_eq: $_eq1}}
    _set: {section: $section}
  ) {
    returning {
      id
      section
    }
  }
}
    `;
export type Update_Question_SectionMutationFn = Apollo.MutationFunction<Update_Question_SectionMutation, Update_Question_SectionMutationVariables>;

/**
 * __useUpdate_Question_SectionMutation__
 *
 * To run a mutation, you first call `useUpdate_Question_SectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Question_SectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionSectionMutation, { data, loading, error }] = useUpdate_Question_SectionMutation({
 *   variables: {
 *      section: // value for 'section'
 *      _eq: // value for '_eq'
 *      _eq1: // value for '_eq1'
 *   },
 * });
 */
export function useUpdate_Question_SectionMutation(baseOptions?: Apollo.MutationHookOptions<Update_Question_SectionMutation, Update_Question_SectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_Question_SectionMutation, Update_Question_SectionMutationVariables>(Update_Question_SectionDocument, options);
      }
export type Update_Question_SectionMutationHookResult = ReturnType<typeof useUpdate_Question_SectionMutation>;
export type Update_Question_SectionMutationResult = Apollo.MutationResult<Update_Question_SectionMutation>;
export type Update_Question_SectionMutationOptions = Apollo.BaseMutationOptions<Update_Question_SectionMutation, Update_Question_SectionMutationVariables>;
export const Update_Draft_StatusDocument = gql`
    mutation UPDATE_DRAFT_STATUS($_eq: Int = 10, $isDraft: Boolean = false) {
  update_examsonline_tests(where: {id: {_eq: $_eq}}, _set: {isDraft: $isDraft}) {
    returning {
      isDraft
      id
    }
  }
}
    `;
export type Update_Draft_StatusMutationFn = Apollo.MutationFunction<Update_Draft_StatusMutation, Update_Draft_StatusMutationVariables>;

/**
 * __useUpdate_Draft_StatusMutation__
 *
 * To run a mutation, you first call `useUpdate_Draft_StatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Draft_StatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDraftStatusMutation, { data, loading, error }] = useUpdate_Draft_StatusMutation({
 *   variables: {
 *      _eq: // value for '_eq'
 *      isDraft: // value for 'isDraft'
 *   },
 * });
 */
export function useUpdate_Draft_StatusMutation(baseOptions?: Apollo.MutationHookOptions<Update_Draft_StatusMutation, Update_Draft_StatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_Draft_StatusMutation, Update_Draft_StatusMutationVariables>(Update_Draft_StatusDocument, options);
      }
export type Update_Draft_StatusMutationHookResult = ReturnType<typeof useUpdate_Draft_StatusMutation>;
export type Update_Draft_StatusMutationResult = Apollo.MutationResult<Update_Draft_StatusMutation>;
export type Update_Draft_StatusMutationOptions = Apollo.BaseMutationOptions<Update_Draft_StatusMutation, Update_Draft_StatusMutationVariables>;
export const Get_Banner_NotificationDocument = gql`
    query GET_BANNER_NOTIFICATION($limit: Int = 10, $offset: Int = 10, $_iregex: String = "") {
  examsonline_banner_notification(
    limit: $limit
    offset: $offset
    where: {message: {_iregex: $_iregex}}
  ) {
    id
    message
    type
    group {
      name
    }
    organisation {
      name
      id
    }
    role {
      user
    }
  }
  examsonline_banner_notification_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGet_Banner_NotificationQuery__
 *
 * To run a query within a React component, call `useGet_Banner_NotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Banner_NotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Banner_NotificationQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      _iregex: // value for '_iregex'
 *   },
 * });
 */
export function useGet_Banner_NotificationQuery(baseOptions?: Apollo.QueryHookOptions<Get_Banner_NotificationQuery, Get_Banner_NotificationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_Banner_NotificationQuery, Get_Banner_NotificationQueryVariables>(Get_Banner_NotificationDocument, options);
      }
export function useGet_Banner_NotificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Banner_NotificationQuery, Get_Banner_NotificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_Banner_NotificationQuery, Get_Banner_NotificationQueryVariables>(Get_Banner_NotificationDocument, options);
        }
export function useGet_Banner_NotificationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_Banner_NotificationQuery, Get_Banner_NotificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_Banner_NotificationQuery, Get_Banner_NotificationQueryVariables>(Get_Banner_NotificationDocument, options);
        }
export type Get_Banner_NotificationQueryHookResult = ReturnType<typeof useGet_Banner_NotificationQuery>;
export type Get_Banner_NotificationLazyQueryHookResult = ReturnType<typeof useGet_Banner_NotificationLazyQuery>;
export type Get_Banner_NotificationSuspenseQueryHookResult = ReturnType<typeof useGet_Banner_NotificationSuspenseQuery>;
export type Get_Banner_NotificationQueryResult = Apollo.QueryResult<Get_Banner_NotificationQuery, Get_Banner_NotificationQueryVariables>;
export const Add_QuizDocument = gql`
    mutation ADD_QUIZ($objects: [examsonline_quiz_insert_input!] = {}, $update_columns: [examsonline_quiz_update_column!]!) {
  insert_examsonline_quiz(
    objects: $objects
    on_conflict: {constraint: quiz_pkey, update_columns: $update_columns}
  ) {
    returning {
      id
      name
      createdAt
    }
  }
}
    `;
export type Add_QuizMutationFn = Apollo.MutationFunction<Add_QuizMutation, Add_QuizMutationVariables>;

/**
 * __useAdd_QuizMutation__
 *
 * To run a mutation, you first call `useAdd_QuizMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_QuizMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addQuizMutation, { data, loading, error }] = useAdd_QuizMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      update_columns: // value for 'update_columns'
 *   },
 * });
 */
export function useAdd_QuizMutation(baseOptions?: Apollo.MutationHookOptions<Add_QuizMutation, Add_QuizMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Add_QuizMutation, Add_QuizMutationVariables>(Add_QuizDocument, options);
      }
export type Add_QuizMutationHookResult = ReturnType<typeof useAdd_QuizMutation>;
export type Add_QuizMutationResult = Apollo.MutationResult<Add_QuizMutation>;
export type Add_QuizMutationOptions = Apollo.BaseMutationOptions<Add_QuizMutation, Add_QuizMutationVariables>;
export const Add_Contact_UsDocument = gql`
    mutation ADD_CONTACT_US($contact: String = "", $message: String = "", $name: String = "", $reason: String = "") {
  insert_examsonline_contactus(
    objects: {email: $contact, message: $message, name: $name, reason: $reason}
  ) {
    returning {
      id
    }
  }
}
    `;
export type Add_Contact_UsMutationFn = Apollo.MutationFunction<Add_Contact_UsMutation, Add_Contact_UsMutationVariables>;

/**
 * __useAdd_Contact_UsMutation__
 *
 * To run a mutation, you first call `useAdd_Contact_UsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_Contact_UsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addContactUsMutation, { data, loading, error }] = useAdd_Contact_UsMutation({
 *   variables: {
 *      contact: // value for 'contact'
 *      message: // value for 'message'
 *      name: // value for 'name'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useAdd_Contact_UsMutation(baseOptions?: Apollo.MutationHookOptions<Add_Contact_UsMutation, Add_Contact_UsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Add_Contact_UsMutation, Add_Contact_UsMutationVariables>(Add_Contact_UsDocument, options);
      }
export type Add_Contact_UsMutationHookResult = ReturnType<typeof useAdd_Contact_UsMutation>;
export type Add_Contact_UsMutationResult = Apollo.MutationResult<Add_Contact_UsMutation>;
export type Add_Contact_UsMutationOptions = Apollo.BaseMutationOptions<Add_Contact_UsMutation, Add_Contact_UsMutationVariables>;
export const Get_Org_UsersDocument = gql`
    query GET_ORG_USERS($_iregex: String = "", $limit: Int = 5, $offset: Int = 0, $_eq: String = "") {
  examsonline_organisation {
    roles(
      where: {user: {_iregex: $_iregex}, role: {_eq: $_eq}}
      limit: $limit
      offset: $offset
      order_by: {createdAt: desc}
    ) {
      id
      org_id
      role
      user
      createdAt
      active
      userByUser {
        username
      }
    }
    total: roles_aggregate(where: {user: {_iregex: $_iregex}, role: {_eq: $_eq}}) {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGet_Org_UsersQuery__
 *
 * To run a query within a React component, call `useGet_Org_UsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Org_UsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Org_UsersQuery({
 *   variables: {
 *      _iregex: // value for '_iregex'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_Org_UsersQuery(baseOptions?: Apollo.QueryHookOptions<Get_Org_UsersQuery, Get_Org_UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_Org_UsersQuery, Get_Org_UsersQueryVariables>(Get_Org_UsersDocument, options);
      }
export function useGet_Org_UsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Org_UsersQuery, Get_Org_UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_Org_UsersQuery, Get_Org_UsersQueryVariables>(Get_Org_UsersDocument, options);
        }
export function useGet_Org_UsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_Org_UsersQuery, Get_Org_UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_Org_UsersQuery, Get_Org_UsersQueryVariables>(Get_Org_UsersDocument, options);
        }
export type Get_Org_UsersQueryHookResult = ReturnType<typeof useGet_Org_UsersQuery>;
export type Get_Org_UsersLazyQueryHookResult = ReturnType<typeof useGet_Org_UsersLazyQuery>;
export type Get_Org_UsersSuspenseQueryHookResult = ReturnType<typeof useGet_Org_UsersSuspenseQuery>;
export type Get_Org_UsersQueryResult = Apollo.QueryResult<Get_Org_UsersQuery, Get_Org_UsersQueryVariables>;
export const Get_Org_Name_PublicDocument = gql`
    query GET_ORG_NAME_PUBLIC($_eq: uuid = "") {
  examsonline_organisation(where: {id: {_eq: $_eq}}) {
    id
    name
  }
}
    `;

/**
 * __useGet_Org_Name_PublicQuery__
 *
 * To run a query within a React component, call `useGet_Org_Name_PublicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Org_Name_PublicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Org_Name_PublicQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_Org_Name_PublicQuery(baseOptions?: Apollo.QueryHookOptions<Get_Org_Name_PublicQuery, Get_Org_Name_PublicQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_Org_Name_PublicQuery, Get_Org_Name_PublicQueryVariables>(Get_Org_Name_PublicDocument, options);
      }
export function useGet_Org_Name_PublicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Org_Name_PublicQuery, Get_Org_Name_PublicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_Org_Name_PublicQuery, Get_Org_Name_PublicQueryVariables>(Get_Org_Name_PublicDocument, options);
        }
export function useGet_Org_Name_PublicSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_Org_Name_PublicQuery, Get_Org_Name_PublicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_Org_Name_PublicQuery, Get_Org_Name_PublicQueryVariables>(Get_Org_Name_PublicDocument, options);
        }
export type Get_Org_Name_PublicQueryHookResult = ReturnType<typeof useGet_Org_Name_PublicQuery>;
export type Get_Org_Name_PublicLazyQueryHookResult = ReturnType<typeof useGet_Org_Name_PublicLazyQuery>;
export type Get_Org_Name_PublicSuspenseQueryHookResult = ReturnType<typeof useGet_Org_Name_PublicSuspenseQuery>;
export type Get_Org_Name_PublicQueryResult = Apollo.QueryResult<Get_Org_Name_PublicQuery, Get_Org_Name_PublicQueryVariables>;
export const Get_Quiz_By_IdDocument = gql`
    query GET_QUIZ_BY_ID($id: uuid, $order_by: [examsonline_quiz_order_by!] = {id: desc}) {
  examsonline_quiz(where: {id: {_eq: $id}}, order_by: $order_by) {
    id
    accesstype
    author
    createdAt
    description
    document
    emailnotify
    enabled
    errorMessage
    maxAttempts
    isDraft
    name
    pincode
    randomise
    showResult
    time
    test_sections {
      id
      name
    }
    topics {
      tag
    }
  }
}
    `;

/**
 * __useGet_Quiz_By_IdQuery__
 *
 * To run a query within a React component, call `useGet_Quiz_By_IdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Quiz_By_IdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Quiz_By_IdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGet_Quiz_By_IdQuery(baseOptions?: Apollo.QueryHookOptions<Get_Quiz_By_IdQuery, Get_Quiz_By_IdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_Quiz_By_IdQuery, Get_Quiz_By_IdQueryVariables>(Get_Quiz_By_IdDocument, options);
      }
export function useGet_Quiz_By_IdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Quiz_By_IdQuery, Get_Quiz_By_IdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_Quiz_By_IdQuery, Get_Quiz_By_IdQueryVariables>(Get_Quiz_By_IdDocument, options);
        }
export function useGet_Quiz_By_IdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_Quiz_By_IdQuery, Get_Quiz_By_IdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_Quiz_By_IdQuery, Get_Quiz_By_IdQueryVariables>(Get_Quiz_By_IdDocument, options);
        }
export type Get_Quiz_By_IdQueryHookResult = ReturnType<typeof useGet_Quiz_By_IdQuery>;
export type Get_Quiz_By_IdLazyQueryHookResult = ReturnType<typeof useGet_Quiz_By_IdLazyQuery>;
export type Get_Quiz_By_IdSuspenseQueryHookResult = ReturnType<typeof useGet_Quiz_By_IdSuspenseQuery>;
export type Get_Quiz_By_IdQueryResult = Apollo.QueryResult<Get_Quiz_By_IdQuery, Get_Quiz_By_IdQueryVariables>;
export const Get_All_QuizDocument = gql`
    query GET_ALL_QUIZ($limit: Int = 10, $offset: Int = 0, $_iregex: String = "") {
  examsonline_quiz(
    limit: $limit
    offset: $offset
    where: {name: {_iregex: $_iregex}}
  ) {
    author
    createdAt
    description
    document
    emailnotify
    enabled
    isDraft
    maxAttempts
    name
    pincode
    randomise
    showResult
    time
    updatedAt
    errorMessage
    id
    questions {
      id
      marks
      order
      quiz_id
      section
      text
      options {
        id
        isCorrect
        q_id
      }
    }
  }
}
    `;

/**
 * __useGet_All_QuizQuery__
 *
 * To run a query within a React component, call `useGet_All_QuizQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_All_QuizQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_All_QuizQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      _iregex: // value for '_iregex'
 *   },
 * });
 */
export function useGet_All_QuizQuery(baseOptions?: Apollo.QueryHookOptions<Get_All_QuizQuery, Get_All_QuizQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_All_QuizQuery, Get_All_QuizQueryVariables>(Get_All_QuizDocument, options);
      }
export function useGet_All_QuizLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_All_QuizQuery, Get_All_QuizQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_All_QuizQuery, Get_All_QuizQueryVariables>(Get_All_QuizDocument, options);
        }
export function useGet_All_QuizSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_All_QuizQuery, Get_All_QuizQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_All_QuizQuery, Get_All_QuizQueryVariables>(Get_All_QuizDocument, options);
        }
export type Get_All_QuizQueryHookResult = ReturnType<typeof useGet_All_QuizQuery>;
export type Get_All_QuizLazyQueryHookResult = ReturnType<typeof useGet_All_QuizLazyQuery>;
export type Get_All_QuizSuspenseQueryHookResult = ReturnType<typeof useGet_All_QuizSuspenseQuery>;
export type Get_All_QuizQueryResult = Apollo.QueryResult<Get_All_QuizQuery, Get_All_QuizQueryVariables>;
export const Add_GroupDocument = gql`
    mutation ADD_GROUP($name: String = "", $description: String = "", $public: Boolean = true) {
  insert_examsonline_groups(
    objects: {name: $name, description: $description, public: $public}
  ) {
    returning {
      id
      name
      user
      updatedAt
      description
      public
    }
  }
}
    `;
export type Add_GroupMutationFn = Apollo.MutationFunction<Add_GroupMutation, Add_GroupMutationVariables>;

/**
 * __useAdd_GroupMutation__
 *
 * To run a mutation, you first call `useAdd_GroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_GroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGroupMutation, { data, loading, error }] = useAdd_GroupMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      public: // value for 'public'
 *   },
 * });
 */
export function useAdd_GroupMutation(baseOptions?: Apollo.MutationHookOptions<Add_GroupMutation, Add_GroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Add_GroupMutation, Add_GroupMutationVariables>(Add_GroupDocument, options);
      }
export type Add_GroupMutationHookResult = ReturnType<typeof useAdd_GroupMutation>;
export type Add_GroupMutationResult = Apollo.MutationResult<Add_GroupMutation>;
export type Add_GroupMutationOptions = Apollo.BaseMutationOptions<Add_GroupMutation, Add_GroupMutationVariables>;
export const Get_GroupsDocument = gql`
    query GET_GROUPS($limit: Int = 10, $offset: Int = 0, $_iregex: String = "") {
  examsonline_groups(
    limit: $limit
    offset: $offset
    where: {name: {_iregex: $_iregex}}
    order_by: {createdAt: desc_nulls_last}
  ) {
    id
    name
    user
    createdAt
    updatedAt
    members_aggregate {
      aggregate {
        count
      }
    }
    files {
      info
    }
    assignments_aggregate {
      aggregate {
        count
      }
    }
  }
  total: examsonline_groups_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGet_GroupsQuery__
 *
 * To run a query within a React component, call `useGet_GroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_GroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_GroupsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      _iregex: // value for '_iregex'
 *   },
 * });
 */
export function useGet_GroupsQuery(baseOptions?: Apollo.QueryHookOptions<Get_GroupsQuery, Get_GroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_GroupsQuery, Get_GroupsQueryVariables>(Get_GroupsDocument, options);
      }
export function useGet_GroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_GroupsQuery, Get_GroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_GroupsQuery, Get_GroupsQueryVariables>(Get_GroupsDocument, options);
        }
export function useGet_GroupsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_GroupsQuery, Get_GroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_GroupsQuery, Get_GroupsQueryVariables>(Get_GroupsDocument, options);
        }
export type Get_GroupsQueryHookResult = ReturnType<typeof useGet_GroupsQuery>;
export type Get_GroupsLazyQueryHookResult = ReturnType<typeof useGet_GroupsLazyQuery>;
export type Get_GroupsSuspenseQueryHookResult = ReturnType<typeof useGet_GroupsSuspenseQuery>;
export type Get_GroupsQueryResult = Apollo.QueryResult<Get_GroupsQuery, Get_GroupsQueryVariables>;
export const Get_Group_By_IdDocument = gql`
    query GET_GROUP_BY_ID($_eq: uuid = "") {
  examsonline_groups(where: {id: {_eq: $_eq}}) {
    id
    name
    updatedAt
    createdAt
    user
    members_aggregate {
      aggregate {
        count
      }
    }
    files {
      info
    }
  }
}
    `;

/**
 * __useGet_Group_By_IdQuery__
 *
 * To run a query within a React component, call `useGet_Group_By_IdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Group_By_IdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Group_By_IdQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_Group_By_IdQuery(baseOptions?: Apollo.QueryHookOptions<Get_Group_By_IdQuery, Get_Group_By_IdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_Group_By_IdQuery, Get_Group_By_IdQueryVariables>(Get_Group_By_IdDocument, options);
      }
export function useGet_Group_By_IdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Group_By_IdQuery, Get_Group_By_IdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_Group_By_IdQuery, Get_Group_By_IdQueryVariables>(Get_Group_By_IdDocument, options);
        }
export function useGet_Group_By_IdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_Group_By_IdQuery, Get_Group_By_IdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_Group_By_IdQuery, Get_Group_By_IdQueryVariables>(Get_Group_By_IdDocument, options);
        }
export type Get_Group_By_IdQueryHookResult = ReturnType<typeof useGet_Group_By_IdQuery>;
export type Get_Group_By_IdLazyQueryHookResult = ReturnType<typeof useGet_Group_By_IdLazyQuery>;
export type Get_Group_By_IdSuspenseQueryHookResult = ReturnType<typeof useGet_Group_By_IdSuspenseQuery>;
export type Get_Group_By_IdQueryResult = Apollo.QueryResult<Get_Group_By_IdQuery, Get_Group_By_IdQueryVariables>;
export const Delete_Quiz_By_IdDocument = gql`
    mutation DELETE_QUIZ_BY_ID($_eq: uuid = "") {
  delete_examsonline_quiz(where: {id: {_eq: $_eq}}) {
    returning {
      id
    }
  }
}
    `;
export type Delete_Quiz_By_IdMutationFn = Apollo.MutationFunction<Delete_Quiz_By_IdMutation, Delete_Quiz_By_IdMutationVariables>;

/**
 * __useDelete_Quiz_By_IdMutation__
 *
 * To run a mutation, you first call `useDelete_Quiz_By_IdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_Quiz_By_IdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuizByIdMutation, { data, loading, error }] = useDelete_Quiz_By_IdMutation({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useDelete_Quiz_By_IdMutation(baseOptions?: Apollo.MutationHookOptions<Delete_Quiz_By_IdMutation, Delete_Quiz_By_IdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Delete_Quiz_By_IdMutation, Delete_Quiz_By_IdMutationVariables>(Delete_Quiz_By_IdDocument, options);
      }
export type Delete_Quiz_By_IdMutationHookResult = ReturnType<typeof useDelete_Quiz_By_IdMutation>;
export type Delete_Quiz_By_IdMutationResult = Apollo.MutationResult<Delete_Quiz_By_IdMutation>;
export type Delete_Quiz_By_IdMutationOptions = Apollo.BaseMutationOptions<Delete_Quiz_By_IdMutation, Delete_Quiz_By_IdMutationVariables>;
export const Create_AssignmentsDocument = gql`
    mutation CREATE_assignments($quiz_id: uuid = "", $test_id: Int = 10, $name: String = "", $startDate: timestamptz = "", $endDate: timestamptz = "") {
  insert_examsonline_assignments(
    objects: {name: $name, test_id: $test_id, quiz_id: $quiz_id, startDate: $startDate, endDate: $endDate}
  ) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export type Create_AssignmentsMutationFn = Apollo.MutationFunction<Create_AssignmentsMutation, Create_AssignmentsMutationVariables>;

/**
 * __useCreate_AssignmentsMutation__
 *
 * To run a mutation, you first call `useCreate_AssignmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_AssignmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssignmentsMutation, { data, loading, error }] = useCreate_AssignmentsMutation({
 *   variables: {
 *      quiz_id: // value for 'quiz_id'
 *      test_id: // value for 'test_id'
 *      name: // value for 'name'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useCreate_AssignmentsMutation(baseOptions?: Apollo.MutationHookOptions<Create_AssignmentsMutation, Create_AssignmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Create_AssignmentsMutation, Create_AssignmentsMutationVariables>(Create_AssignmentsDocument, options);
      }
export type Create_AssignmentsMutationHookResult = ReturnType<typeof useCreate_AssignmentsMutation>;
export type Create_AssignmentsMutationResult = Apollo.MutationResult<Create_AssignmentsMutation>;
export type Create_AssignmentsMutationOptions = Apollo.BaseMutationOptions<Create_AssignmentsMutation, Create_AssignmentsMutationVariables>;
export const Delete_Group_By_IdDocument = gql`
    mutation DELETE_GROUP_BY_ID($_eq: uuid = "") {
  delete_examsonline_groups(where: {id: {_eq: $_eq}}) {
    returning {
      id
    }
  }
}
    `;
export type Delete_Group_By_IdMutationFn = Apollo.MutationFunction<Delete_Group_By_IdMutation, Delete_Group_By_IdMutationVariables>;

/**
 * __useDelete_Group_By_IdMutation__
 *
 * To run a mutation, you first call `useDelete_Group_By_IdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_Group_By_IdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupByIdMutation, { data, loading, error }] = useDelete_Group_By_IdMutation({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useDelete_Group_By_IdMutation(baseOptions?: Apollo.MutationHookOptions<Delete_Group_By_IdMutation, Delete_Group_By_IdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Delete_Group_By_IdMutation, Delete_Group_By_IdMutationVariables>(Delete_Group_By_IdDocument, options);
      }
export type Delete_Group_By_IdMutationHookResult = ReturnType<typeof useDelete_Group_By_IdMutation>;
export type Delete_Group_By_IdMutationResult = Apollo.MutationResult<Delete_Group_By_IdMutation>;
export type Delete_Group_By_IdMutationOptions = Apollo.BaseMutationOptions<Delete_Group_By_IdMutation, Delete_Group_By_IdMutationVariables>;
export const Add_Member_To_GroupDocument = gql`
    mutation ADD_MEMBER_TO_GROUP($email: String = "", $group_id: uuid = "") {
  insert_examsonline_members(objects: {email: $email, group_id: $group_id}) {
    returning {
      email
      group_id
      id
      updatedAt
      createdAt
    }
  }
}
    `;
export type Add_Member_To_GroupMutationFn = Apollo.MutationFunction<Add_Member_To_GroupMutation, Add_Member_To_GroupMutationVariables>;

/**
 * __useAdd_Member_To_GroupMutation__
 *
 * To run a mutation, you first call `useAdd_Member_To_GroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_Member_To_GroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMemberToGroupMutation, { data, loading, error }] = useAdd_Member_To_GroupMutation({
 *   variables: {
 *      email: // value for 'email'
 *      group_id: // value for 'group_id'
 *   },
 * });
 */
export function useAdd_Member_To_GroupMutation(baseOptions?: Apollo.MutationHookOptions<Add_Member_To_GroupMutation, Add_Member_To_GroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Add_Member_To_GroupMutation, Add_Member_To_GroupMutationVariables>(Add_Member_To_GroupDocument, options);
      }
export type Add_Member_To_GroupMutationHookResult = ReturnType<typeof useAdd_Member_To_GroupMutation>;
export type Add_Member_To_GroupMutationResult = Apollo.MutationResult<Add_Member_To_GroupMutation>;
export type Add_Member_To_GroupMutationOptions = Apollo.BaseMutationOptions<Add_Member_To_GroupMutation, Add_Member_To_GroupMutationVariables>;
export const Assign_Test_To_GroupDocument = gql`
    mutation ASSIGN_TEST_TO_GROUP($name: String = "", $test_id: Int = 10, $startDate: timestamptz = "", $endDate: timestamptz = "", $group: uuid = "") {
  insert_examsonline_assignments(
    objects: {name: $name, test_id: $test_id, startDate: $startDate, endDate: $endDate, group: $group}
  ) {
    returning {
      id
      startDate
      endDate
      test_id
      name
      group
    }
  }
}
    `;
export type Assign_Test_To_GroupMutationFn = Apollo.MutationFunction<Assign_Test_To_GroupMutation, Assign_Test_To_GroupMutationVariables>;

/**
 * __useAssign_Test_To_GroupMutation__
 *
 * To run a mutation, you first call `useAssign_Test_To_GroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssign_Test_To_GroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignTestToGroupMutation, { data, loading, error }] = useAssign_Test_To_GroupMutation({
 *   variables: {
 *      name: // value for 'name'
 *      test_id: // value for 'test_id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      group: // value for 'group'
 *   },
 * });
 */
export function useAssign_Test_To_GroupMutation(baseOptions?: Apollo.MutationHookOptions<Assign_Test_To_GroupMutation, Assign_Test_To_GroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Assign_Test_To_GroupMutation, Assign_Test_To_GroupMutationVariables>(Assign_Test_To_GroupDocument, options);
      }
export type Assign_Test_To_GroupMutationHookResult = ReturnType<typeof useAssign_Test_To_GroupMutation>;
export type Assign_Test_To_GroupMutationResult = Apollo.MutationResult<Assign_Test_To_GroupMutation>;
export type Assign_Test_To_GroupMutationOptions = Apollo.BaseMutationOptions<Assign_Test_To_GroupMutation, Assign_Test_To_GroupMutationVariables>;
export const Upcoming_AssignmentsDocument = gql`
    query UPCOMING_ASSIGNMENTS($limit: Int = 10, $offset: Int = 0, $_iregex: String = "") {
  examsonline_assignments(
    order_by: {startDate: desc}
    limit: $limit
    offset: $offset
    where: {name: {_iregex: $_iregex}}
  ) {
    createdAt
    endDate
    id
    name
    startDate
    test_id
    user
  }
  total: examsonline_assignments_aggregate(where: {name: {_iregex: $_iregex}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useUpcoming_AssignmentsQuery__
 *
 * To run a query within a React component, call `useUpcoming_AssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcoming_AssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcoming_AssignmentsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      _iregex: // value for '_iregex'
 *   },
 * });
 */
export function useUpcoming_AssignmentsQuery(baseOptions?: Apollo.QueryHookOptions<Upcoming_AssignmentsQuery, Upcoming_AssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Upcoming_AssignmentsQuery, Upcoming_AssignmentsQueryVariables>(Upcoming_AssignmentsDocument, options);
      }
export function useUpcoming_AssignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Upcoming_AssignmentsQuery, Upcoming_AssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Upcoming_AssignmentsQuery, Upcoming_AssignmentsQueryVariables>(Upcoming_AssignmentsDocument, options);
        }
export function useUpcoming_AssignmentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Upcoming_AssignmentsQuery, Upcoming_AssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Upcoming_AssignmentsQuery, Upcoming_AssignmentsQueryVariables>(Upcoming_AssignmentsDocument, options);
        }
export type Upcoming_AssignmentsQueryHookResult = ReturnType<typeof useUpcoming_AssignmentsQuery>;
export type Upcoming_AssignmentsLazyQueryHookResult = ReturnType<typeof useUpcoming_AssignmentsLazyQuery>;
export type Upcoming_AssignmentsSuspenseQueryHookResult = ReturnType<typeof useUpcoming_AssignmentsSuspenseQuery>;
export type Upcoming_AssignmentsQueryResult = Apollo.QueryResult<Upcoming_AssignmentsQuery, Upcoming_AssignmentsQueryVariables>;
export const Get_All_Members_Of_GroupDocument = gql`
    query GET_ALL_MEMBERS_OF_GROUP($_iregex: String = "", $limit: Int = 10, $offset: Int = 0, $_eq: uuid = "") {
  examsonline_members(
    limit: $limit
    offset: $offset
    where: {email: {_iregex: $_iregex}, group_id: {_eq: $_eq}}
  ) {
    createdAt
    email
    group_id
    id
    updatedAt
  }
  total: examsonline_members_aggregate(
    where: {email: {_iregex: $_iregex}, group_id: {_eq: $_eq}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGet_All_Members_Of_GroupQuery__
 *
 * To run a query within a React component, call `useGet_All_Members_Of_GroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_All_Members_Of_GroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_All_Members_Of_GroupQuery({
 *   variables: {
 *      _iregex: // value for '_iregex'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_All_Members_Of_GroupQuery(baseOptions?: Apollo.QueryHookOptions<Get_All_Members_Of_GroupQuery, Get_All_Members_Of_GroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_All_Members_Of_GroupQuery, Get_All_Members_Of_GroupQueryVariables>(Get_All_Members_Of_GroupDocument, options);
      }
export function useGet_All_Members_Of_GroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_All_Members_Of_GroupQuery, Get_All_Members_Of_GroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_All_Members_Of_GroupQuery, Get_All_Members_Of_GroupQueryVariables>(Get_All_Members_Of_GroupDocument, options);
        }
export function useGet_All_Members_Of_GroupSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_All_Members_Of_GroupQuery, Get_All_Members_Of_GroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_All_Members_Of_GroupQuery, Get_All_Members_Of_GroupQueryVariables>(Get_All_Members_Of_GroupDocument, options);
        }
export type Get_All_Members_Of_GroupQueryHookResult = ReturnType<typeof useGet_All_Members_Of_GroupQuery>;
export type Get_All_Members_Of_GroupLazyQueryHookResult = ReturnType<typeof useGet_All_Members_Of_GroupLazyQuery>;
export type Get_All_Members_Of_GroupSuspenseQueryHookResult = ReturnType<typeof useGet_All_Members_Of_GroupSuspenseQuery>;
export type Get_All_Members_Of_GroupQueryResult = Apollo.QueryResult<Get_All_Members_Of_GroupQuery, Get_All_Members_Of_GroupQueryVariables>;
export const Delete_Member_By_IdDocument = gql`
    mutation DELETE_MEMBER_BY_ID($_eq: uuid = "") {
  delete_examsonline_members(where: {id: {_eq: $_eq}}) {
    returning {
      createdAt
      email
      id
      group_id
      updatedAt
    }
    affected_rows
  }
}
    `;
export type Delete_Member_By_IdMutationFn = Apollo.MutationFunction<Delete_Member_By_IdMutation, Delete_Member_By_IdMutationVariables>;

/**
 * __useDelete_Member_By_IdMutation__
 *
 * To run a mutation, you first call `useDelete_Member_By_IdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_Member_By_IdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMemberByIdMutation, { data, loading, error }] = useDelete_Member_By_IdMutation({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useDelete_Member_By_IdMutation(baseOptions?: Apollo.MutationHookOptions<Delete_Member_By_IdMutation, Delete_Member_By_IdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Delete_Member_By_IdMutation, Delete_Member_By_IdMutationVariables>(Delete_Member_By_IdDocument, options);
      }
export type Delete_Member_By_IdMutationHookResult = ReturnType<typeof useDelete_Member_By_IdMutation>;
export type Delete_Member_By_IdMutationResult = Apollo.MutationResult<Delete_Member_By_IdMutation>;
export type Delete_Member_By_IdMutationOptions = Apollo.BaseMutationOptions<Delete_Member_By_IdMutation, Delete_Member_By_IdMutationVariables>;
export const Get_Total_Submissions_For_AuthorDocument = gql`
    query GET_TOTAL_SUBMISSIONS_FOR_AUTHOR($_eq: Boolean = true) {
  examsonline_attempt_aggregate(where: {completed: {_eq: $_eq}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGet_Total_Submissions_For_AuthorQuery__
 *
 * To run a query within a React component, call `useGet_Total_Submissions_For_AuthorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Total_Submissions_For_AuthorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Total_Submissions_For_AuthorQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_Total_Submissions_For_AuthorQuery(baseOptions?: Apollo.QueryHookOptions<Get_Total_Submissions_For_AuthorQuery, Get_Total_Submissions_For_AuthorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_Total_Submissions_For_AuthorQuery, Get_Total_Submissions_For_AuthorQueryVariables>(Get_Total_Submissions_For_AuthorDocument, options);
      }
export function useGet_Total_Submissions_For_AuthorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Total_Submissions_For_AuthorQuery, Get_Total_Submissions_For_AuthorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_Total_Submissions_For_AuthorQuery, Get_Total_Submissions_For_AuthorQueryVariables>(Get_Total_Submissions_For_AuthorDocument, options);
        }
export function useGet_Total_Submissions_For_AuthorSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_Total_Submissions_For_AuthorQuery, Get_Total_Submissions_For_AuthorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_Total_Submissions_For_AuthorQuery, Get_Total_Submissions_For_AuthorQueryVariables>(Get_Total_Submissions_For_AuthorDocument, options);
        }
export type Get_Total_Submissions_For_AuthorQueryHookResult = ReturnType<typeof useGet_Total_Submissions_For_AuthorQuery>;
export type Get_Total_Submissions_For_AuthorLazyQueryHookResult = ReturnType<typeof useGet_Total_Submissions_For_AuthorLazyQuery>;
export type Get_Total_Submissions_For_AuthorSuspenseQueryHookResult = ReturnType<typeof useGet_Total_Submissions_For_AuthorSuspenseQuery>;
export type Get_Total_Submissions_For_AuthorQueryResult = Apollo.QueryResult<Get_Total_Submissions_For_AuthorQuery, Get_Total_Submissions_For_AuthorQueryVariables>;
export const Group_MembershipsDocument = gql`
    query GROUP_MEMBERSHIPS($limit: Int = 10, $offset: Int = 0) {
  examsonline_members(limit: $limit, offset: $offset) {
    createdAt
    email
    group_id
    id
    user
    group {
      name
      id
      user
    }
  }
}
    `;

/**
 * __useGroup_MembershipsQuery__
 *
 * To run a query within a React component, call `useGroup_MembershipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroup_MembershipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroup_MembershipsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGroup_MembershipsQuery(baseOptions?: Apollo.QueryHookOptions<Group_MembershipsQuery, Group_MembershipsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Group_MembershipsQuery, Group_MembershipsQueryVariables>(Group_MembershipsDocument, options);
      }
export function useGroup_MembershipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Group_MembershipsQuery, Group_MembershipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Group_MembershipsQuery, Group_MembershipsQueryVariables>(Group_MembershipsDocument, options);
        }
export function useGroup_MembershipsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Group_MembershipsQuery, Group_MembershipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Group_MembershipsQuery, Group_MembershipsQueryVariables>(Group_MembershipsDocument, options);
        }
export type Group_MembershipsQueryHookResult = ReturnType<typeof useGroup_MembershipsQuery>;
export type Group_MembershipsLazyQueryHookResult = ReturnType<typeof useGroup_MembershipsLazyQuery>;
export type Group_MembershipsSuspenseQueryHookResult = ReturnType<typeof useGroup_MembershipsSuspenseQuery>;
export type Group_MembershipsQueryResult = Apollo.QueryResult<Group_MembershipsQuery, Group_MembershipsQueryVariables>;
export const Get_My_AssignmentsDocument = gql`
    query GET_MY_ASSIGNMENTS($limit: Int = 10, $offset: Int = 0, $_iregex: String = "") {
  examsonline_assignments(
    limit: $limit
    offset: $offset
    order_by: {endDate: asc}
    where: {name: {_iregex: $_iregex}}
  ) {
    name
    createdAt
    endDate
    group
    id
    quiz_id
    startDate
    test_id
    type
    user
    comments_aggregate {
      aggregate {
        count
      }
    }
    groupByGroup {
      id
      name
    }
    attempts(where: {assignment: {name: {_iregex: $_iregex}}}) {
      completed
      createdAt
      email
      correct
      asg_id
      percentage
      id
      quiz_id
      scheduleid
      stale
      test_id
      updatedAt
      wrong
      skipped
    }
  }
  total: examsonline_assignments_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGet_My_AssignmentsQuery__
 *
 * To run a query within a React component, call `useGet_My_AssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_My_AssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_My_AssignmentsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      _iregex: // value for '_iregex'
 *   },
 * });
 */
export function useGet_My_AssignmentsQuery(baseOptions?: Apollo.QueryHookOptions<Get_My_AssignmentsQuery, Get_My_AssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_My_AssignmentsQuery, Get_My_AssignmentsQueryVariables>(Get_My_AssignmentsDocument, options);
      }
export function useGet_My_AssignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_My_AssignmentsQuery, Get_My_AssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_My_AssignmentsQuery, Get_My_AssignmentsQueryVariables>(Get_My_AssignmentsDocument, options);
        }
export function useGet_My_AssignmentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_My_AssignmentsQuery, Get_My_AssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_My_AssignmentsQuery, Get_My_AssignmentsQueryVariables>(Get_My_AssignmentsDocument, options);
        }
export type Get_My_AssignmentsQueryHookResult = ReturnType<typeof useGet_My_AssignmentsQuery>;
export type Get_My_AssignmentsLazyQueryHookResult = ReturnType<typeof useGet_My_AssignmentsLazyQuery>;
export type Get_My_AssignmentsSuspenseQueryHookResult = ReturnType<typeof useGet_My_AssignmentsSuspenseQuery>;
export type Get_My_AssignmentsQueryResult = Apollo.QueryResult<Get_My_AssignmentsQuery, Get_My_AssignmentsQueryVariables>;
export const Get_All_Support_TicketsDocument = gql`
    query GET_ALL_SUPPORT_TICKETS($limit: Int = 10, $offset: Int = 0, $_iregex1: String = "") {
  examsonline_tickets(
    limit: $limit
    offset: $offset
    order_by: {createdAt: desc}
    where: {description: {_iregex: $_iregex1}}
  ) {
    assigned
    createdAt
    createdBy
    id
    description
    name
    status
    updatedAt
    user {
      email
      organisation {
        id
        name
        plan
        email
        country
        activated
      }
      role
    }
    org_id
  }
  total: examsonline_tickets_aggregate(where: {description: {_iregex: $_iregex1}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGet_All_Support_TicketsQuery__
 *
 * To run a query within a React component, call `useGet_All_Support_TicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_All_Support_TicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_All_Support_TicketsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      _iregex1: // value for '_iregex1'
 *   },
 * });
 */
export function useGet_All_Support_TicketsQuery(baseOptions?: Apollo.QueryHookOptions<Get_All_Support_TicketsQuery, Get_All_Support_TicketsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_All_Support_TicketsQuery, Get_All_Support_TicketsQueryVariables>(Get_All_Support_TicketsDocument, options);
      }
export function useGet_All_Support_TicketsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_All_Support_TicketsQuery, Get_All_Support_TicketsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_All_Support_TicketsQuery, Get_All_Support_TicketsQueryVariables>(Get_All_Support_TicketsDocument, options);
        }
export function useGet_All_Support_TicketsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_All_Support_TicketsQuery, Get_All_Support_TicketsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_All_Support_TicketsQuery, Get_All_Support_TicketsQueryVariables>(Get_All_Support_TicketsDocument, options);
        }
export type Get_All_Support_TicketsQueryHookResult = ReturnType<typeof useGet_All_Support_TicketsQuery>;
export type Get_All_Support_TicketsLazyQueryHookResult = ReturnType<typeof useGet_All_Support_TicketsLazyQuery>;
export type Get_All_Support_TicketsSuspenseQueryHookResult = ReturnType<typeof useGet_All_Support_TicketsSuspenseQuery>;
export type Get_All_Support_TicketsQueryResult = Apollo.QueryResult<Get_All_Support_TicketsQuery, Get_All_Support_TicketsQueryVariables>;
export const Get_All_Orgs_By_CountDocument = gql`
    query GET_ALL_ORGS_BY_COUNT($limit: Int = 10) {
  examsonline_organisation(
    order_by: {users_aggregate: {count: desc_nulls_last}}
    limit: $limit
  ) {
    activated
    country
    createdAt
    email
    id
    name
    phone
    plan
    updatedAt
    total_users: users_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGet_All_Orgs_By_CountQuery__
 *
 * To run a query within a React component, call `useGet_All_Orgs_By_CountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_All_Orgs_By_CountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_All_Orgs_By_CountQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGet_All_Orgs_By_CountQuery(baseOptions?: Apollo.QueryHookOptions<Get_All_Orgs_By_CountQuery, Get_All_Orgs_By_CountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_All_Orgs_By_CountQuery, Get_All_Orgs_By_CountQueryVariables>(Get_All_Orgs_By_CountDocument, options);
      }
export function useGet_All_Orgs_By_CountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_All_Orgs_By_CountQuery, Get_All_Orgs_By_CountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_All_Orgs_By_CountQuery, Get_All_Orgs_By_CountQueryVariables>(Get_All_Orgs_By_CountDocument, options);
        }
export function useGet_All_Orgs_By_CountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_All_Orgs_By_CountQuery, Get_All_Orgs_By_CountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_All_Orgs_By_CountQuery, Get_All_Orgs_By_CountQueryVariables>(Get_All_Orgs_By_CountDocument, options);
        }
export type Get_All_Orgs_By_CountQueryHookResult = ReturnType<typeof useGet_All_Orgs_By_CountQuery>;
export type Get_All_Orgs_By_CountLazyQueryHookResult = ReturnType<typeof useGet_All_Orgs_By_CountLazyQuery>;
export type Get_All_Orgs_By_CountSuspenseQueryHookResult = ReturnType<typeof useGet_All_Orgs_By_CountSuspenseQuery>;
export type Get_All_Orgs_By_CountQueryResult = Apollo.QueryResult<Get_All_Orgs_By_CountQuery, Get_All_Orgs_By_CountQueryVariables>;
export const Support_StatsDocument = gql`
    query SUPPORT_STATS {
  examsonline_organisation_aggregate {
    aggregate {
      count
    }
  }
  examsonline_tickets_aggregate {
    aggregate {
      count
    }
  }
  examsonline_user_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useSupport_StatsQuery__
 *
 * To run a query within a React component, call `useSupport_StatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupport_StatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupport_StatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSupport_StatsQuery(baseOptions?: Apollo.QueryHookOptions<Support_StatsQuery, Support_StatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Support_StatsQuery, Support_StatsQueryVariables>(Support_StatsDocument, options);
      }
export function useSupport_StatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Support_StatsQuery, Support_StatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Support_StatsQuery, Support_StatsQueryVariables>(Support_StatsDocument, options);
        }
export function useSupport_StatsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Support_StatsQuery, Support_StatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Support_StatsQuery, Support_StatsQueryVariables>(Support_StatsDocument, options);
        }
export type Support_StatsQueryHookResult = ReturnType<typeof useSupport_StatsQuery>;
export type Support_StatsLazyQueryHookResult = ReturnType<typeof useSupport_StatsLazyQuery>;
export type Support_StatsSuspenseQueryHookResult = ReturnType<typeof useSupport_StatsSuspenseQuery>;
export type Support_StatsQueryResult = Apollo.QueryResult<Support_StatsQuery, Support_StatsQueryVariables>;
export const Group_DetailsDocument = gql`
    query GROUP_DETAILS($_eq: uuid = "") {
  examsonline_groups(where: {id: {_eq: $_eq}}) {
    id
    name
    updatedAt
    user
    createdAt
    public
    members_aggregate {
      aggregate {
        count
      }
    }
    assignments_aggregate {
      aggregate {
        count
      }
    }
    files {
      id
      info
    }
  }
}
    `;

/**
 * __useGroup_DetailsQuery__
 *
 * To run a query within a React component, call `useGroup_DetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroup_DetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroup_DetailsQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGroup_DetailsQuery(baseOptions?: Apollo.QueryHookOptions<Group_DetailsQuery, Group_DetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Group_DetailsQuery, Group_DetailsQueryVariables>(Group_DetailsDocument, options);
      }
export function useGroup_DetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Group_DetailsQuery, Group_DetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Group_DetailsQuery, Group_DetailsQueryVariables>(Group_DetailsDocument, options);
        }
export function useGroup_DetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Group_DetailsQuery, Group_DetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Group_DetailsQuery, Group_DetailsQueryVariables>(Group_DetailsDocument, options);
        }
export type Group_DetailsQueryHookResult = ReturnType<typeof useGroup_DetailsQuery>;
export type Group_DetailsLazyQueryHookResult = ReturnType<typeof useGroup_DetailsLazyQuery>;
export type Group_DetailsSuspenseQueryHookResult = ReturnType<typeof useGroup_DetailsSuspenseQuery>;
export type Group_DetailsQueryResult = Apollo.QueryResult<Group_DetailsQuery, Group_DetailsQueryVariables>;
export const Group_AssignmentsDocument = gql`
    query GROUP_ASSIGNMENTS($limit: Int = 10, $offset: Int = 10, $_eq: uuid = "", $_iregex: String = "") {
  examsonline_assignments(
    limit: $limit
    offset: $offset
    where: {group: {_eq: $_eq}, name: {_iregex: $_iregex}}
    order_by: {createdAt: desc}
  ) {
    id
    name
    startDate
    test_id
    createdAt
    endDate
    group
    comments_aggregate {
      aggregate {
        count
      }
    }
    attempts_aggregate {
      aggregate {
        count
      }
    }
    groupByGroup {
      members_aggregate {
        aggregate {
          count
        }
      }
    }
  }
}
    `;

/**
 * __useGroup_AssignmentsQuery__
 *
 * To run a query within a React component, call `useGroup_AssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroup_AssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroup_AssignmentsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      _eq: // value for '_eq'
 *      _iregex: // value for '_iregex'
 *   },
 * });
 */
export function useGroup_AssignmentsQuery(baseOptions?: Apollo.QueryHookOptions<Group_AssignmentsQuery, Group_AssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Group_AssignmentsQuery, Group_AssignmentsQueryVariables>(Group_AssignmentsDocument, options);
      }
export function useGroup_AssignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Group_AssignmentsQuery, Group_AssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Group_AssignmentsQuery, Group_AssignmentsQueryVariables>(Group_AssignmentsDocument, options);
        }
export function useGroup_AssignmentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Group_AssignmentsQuery, Group_AssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Group_AssignmentsQuery, Group_AssignmentsQueryVariables>(Group_AssignmentsDocument, options);
        }
export type Group_AssignmentsQueryHookResult = ReturnType<typeof useGroup_AssignmentsQuery>;
export type Group_AssignmentsLazyQueryHookResult = ReturnType<typeof useGroup_AssignmentsLazyQuery>;
export type Group_AssignmentsSuspenseQueryHookResult = ReturnType<typeof useGroup_AssignmentsSuspenseQuery>;
export type Group_AssignmentsQueryResult = Apollo.QueryResult<Group_AssignmentsQuery, Group_AssignmentsQueryVariables>;
export const Author_Groups_ListDocument = gql`
    query AUTHOR_GROUPS_LIST {
  examsonline_groups {
    name
    id
    createdAt
  }
}
    `;

/**
 * __useAuthor_Groups_ListQuery__
 *
 * To run a query within a React component, call `useAuthor_Groups_ListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthor_Groups_ListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthor_Groups_ListQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthor_Groups_ListQuery(baseOptions?: Apollo.QueryHookOptions<Author_Groups_ListQuery, Author_Groups_ListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Author_Groups_ListQuery, Author_Groups_ListQueryVariables>(Author_Groups_ListDocument, options);
      }
export function useAuthor_Groups_ListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Author_Groups_ListQuery, Author_Groups_ListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Author_Groups_ListQuery, Author_Groups_ListQueryVariables>(Author_Groups_ListDocument, options);
        }
export function useAuthor_Groups_ListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Author_Groups_ListQuery, Author_Groups_ListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Author_Groups_ListQuery, Author_Groups_ListQueryVariables>(Author_Groups_ListDocument, options);
        }
export type Author_Groups_ListQueryHookResult = ReturnType<typeof useAuthor_Groups_ListQuery>;
export type Author_Groups_ListLazyQueryHookResult = ReturnType<typeof useAuthor_Groups_ListLazyQuery>;
export type Author_Groups_ListSuspenseQueryHookResult = ReturnType<typeof useAuthor_Groups_ListSuspenseQuery>;
export type Author_Groups_ListQueryResult = Apollo.QueryResult<Author_Groups_ListQuery, Author_Groups_ListQueryVariables>;
export const Get_Total_Role_CountDocument = gql`
    query GET_TOTAL_ROLE_COUNT {
  examsonline_user_aggregate(where: {role: {_eq: "STUDENT"}}) {
    aggregate {
      count
    }
  }
  total_author: examsonline_user_aggregate(where: {role: {_eq: "AUTHOR"}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGet_Total_Role_CountQuery__
 *
 * To run a query within a React component, call `useGet_Total_Role_CountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Total_Role_CountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Total_Role_CountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_Total_Role_CountQuery(baseOptions?: Apollo.QueryHookOptions<Get_Total_Role_CountQuery, Get_Total_Role_CountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_Total_Role_CountQuery, Get_Total_Role_CountQueryVariables>(Get_Total_Role_CountDocument, options);
      }
export function useGet_Total_Role_CountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Total_Role_CountQuery, Get_Total_Role_CountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_Total_Role_CountQuery, Get_Total_Role_CountQueryVariables>(Get_Total_Role_CountDocument, options);
        }
export function useGet_Total_Role_CountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_Total_Role_CountQuery, Get_Total_Role_CountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_Total_Role_CountQuery, Get_Total_Role_CountQueryVariables>(Get_Total_Role_CountDocument, options);
        }
export type Get_Total_Role_CountQueryHookResult = ReturnType<typeof useGet_Total_Role_CountQuery>;
export type Get_Total_Role_CountLazyQueryHookResult = ReturnType<typeof useGet_Total_Role_CountLazyQuery>;
export type Get_Total_Role_CountSuspenseQueryHookResult = ReturnType<typeof useGet_Total_Role_CountSuspenseQuery>;
export type Get_Total_Role_CountQueryResult = Apollo.QueryResult<Get_Total_Role_CountQuery, Get_Total_Role_CountQueryVariables>;
export const Get_Total_GroupsDocument = gql`
    query GET_TOTAL_GROUPS {
  examsonline_groups_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGet_Total_GroupsQuery__
 *
 * To run a query within a React component, call `useGet_Total_GroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Total_GroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Total_GroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_Total_GroupsQuery(baseOptions?: Apollo.QueryHookOptions<Get_Total_GroupsQuery, Get_Total_GroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_Total_GroupsQuery, Get_Total_GroupsQueryVariables>(Get_Total_GroupsDocument, options);
      }
export function useGet_Total_GroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Total_GroupsQuery, Get_Total_GroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_Total_GroupsQuery, Get_Total_GroupsQueryVariables>(Get_Total_GroupsDocument, options);
        }
export function useGet_Total_GroupsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_Total_GroupsQuery, Get_Total_GroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_Total_GroupsQuery, Get_Total_GroupsQueryVariables>(Get_Total_GroupsDocument, options);
        }
export type Get_Total_GroupsQueryHookResult = ReturnType<typeof useGet_Total_GroupsQuery>;
export type Get_Total_GroupsLazyQueryHookResult = ReturnType<typeof useGet_Total_GroupsLazyQuery>;
export type Get_Total_GroupsSuspenseQueryHookResult = ReturnType<typeof useGet_Total_GroupsSuspenseQuery>;
export type Get_Total_GroupsQueryResult = Apollo.QueryResult<Get_Total_GroupsQuery, Get_Total_GroupsQueryVariables>;
export const Get_All_UsersDocument = gql`
    query GET_ALL_USERS($_iregex: String = "", $limit: Int = 10, $offset: Int = 0) {
  examsonline_user(
    where: {email: {_iregex: $_iregex}}
    limit: $limit
    offset: $offset
  ) {
    email
    id
    role
  }
  total: examsonline_user_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGet_All_UsersQuery__
 *
 * To run a query within a React component, call `useGet_All_UsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_All_UsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_All_UsersQuery({
 *   variables: {
 *      _iregex: // value for '_iregex'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGet_All_UsersQuery(baseOptions?: Apollo.QueryHookOptions<Get_All_UsersQuery, Get_All_UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_All_UsersQuery, Get_All_UsersQueryVariables>(Get_All_UsersDocument, options);
      }
export function useGet_All_UsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_All_UsersQuery, Get_All_UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_All_UsersQuery, Get_All_UsersQueryVariables>(Get_All_UsersDocument, options);
        }
export function useGet_All_UsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_All_UsersQuery, Get_All_UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_All_UsersQuery, Get_All_UsersQueryVariables>(Get_All_UsersDocument, options);
        }
export type Get_All_UsersQueryHookResult = ReturnType<typeof useGet_All_UsersQuery>;
export type Get_All_UsersLazyQueryHookResult = ReturnType<typeof useGet_All_UsersLazyQuery>;
export type Get_All_UsersSuspenseQueryHookResult = ReturnType<typeof useGet_All_UsersSuspenseQuery>;
export type Get_All_UsersQueryResult = Apollo.QueryResult<Get_All_UsersQuery, Get_All_UsersQueryVariables>;
export const Get_Student_Results_By_EmailDocument = gql`
    query GET_STUDENT_RESULTS_BY_EMAIL($_gte: timestamptz = "11/01/2020", $limit: Int = 10, $offset: Int = 0, $_iregex: String = "") {
  examsonline_attempt(
    limit: $limit
    offset: $offset
    where: {createdAt: {_gte: $_gte}, email: {_iregex: $_iregex}}
    order_by: {createdAt: desc_nulls_last}
  ) {
    id
    email
    createdAt
    updatedAt
    completed
    test_id
    skipped
    correct
    wrong
    percentage
    test {
      author
      name
      id
    }
  }
  total: examsonline_attempt_aggregate(
    where: {createdAt: {_gte: $_gte}, email: {_iregex: $_iregex}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGet_Student_Results_By_EmailQuery__
 *
 * To run a query within a React component, call `useGet_Student_Results_By_EmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Student_Results_By_EmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Student_Results_By_EmailQuery({
 *   variables: {
 *      _gte: // value for '_gte'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      _iregex: // value for '_iregex'
 *   },
 * });
 */
export function useGet_Student_Results_By_EmailQuery(baseOptions?: Apollo.QueryHookOptions<Get_Student_Results_By_EmailQuery, Get_Student_Results_By_EmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_Student_Results_By_EmailQuery, Get_Student_Results_By_EmailQueryVariables>(Get_Student_Results_By_EmailDocument, options);
      }
export function useGet_Student_Results_By_EmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Student_Results_By_EmailQuery, Get_Student_Results_By_EmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_Student_Results_By_EmailQuery, Get_Student_Results_By_EmailQueryVariables>(Get_Student_Results_By_EmailDocument, options);
        }
export function useGet_Student_Results_By_EmailSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_Student_Results_By_EmailQuery, Get_Student_Results_By_EmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_Student_Results_By_EmailQuery, Get_Student_Results_By_EmailQueryVariables>(Get_Student_Results_By_EmailDocument, options);
        }
export type Get_Student_Results_By_EmailQueryHookResult = ReturnType<typeof useGet_Student_Results_By_EmailQuery>;
export type Get_Student_Results_By_EmailLazyQueryHookResult = ReturnType<typeof useGet_Student_Results_By_EmailLazyQuery>;
export type Get_Student_Results_By_EmailSuspenseQueryHookResult = ReturnType<typeof useGet_Student_Results_By_EmailSuspenseQuery>;
export type Get_Student_Results_By_EmailQueryResult = Apollo.QueryResult<Get_Student_Results_By_EmailQuery, Get_Student_Results_By_EmailQueryVariables>;
export const Get_All_OrgDocument = gql`
    query GET_ALL_ORG($_iregex: String = "", $limit: Int = 10, $offset: Int = 0) {
  examsonline_organisation(
    where: {name: {_iregex: $_iregex}}
    limit: $limit
    offset: $offset
  ) {
    name
    email
    id
    plan
    phone
  }
  total: examsonline_organisation_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGet_All_OrgQuery__
 *
 * To run a query within a React component, call `useGet_All_OrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_All_OrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_All_OrgQuery({
 *   variables: {
 *      _iregex: // value for '_iregex'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGet_All_OrgQuery(baseOptions?: Apollo.QueryHookOptions<Get_All_OrgQuery, Get_All_OrgQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_All_OrgQuery, Get_All_OrgQueryVariables>(Get_All_OrgDocument, options);
      }
export function useGet_All_OrgLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_All_OrgQuery, Get_All_OrgQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_All_OrgQuery, Get_All_OrgQueryVariables>(Get_All_OrgDocument, options);
        }
export function useGet_All_OrgSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_All_OrgQuery, Get_All_OrgQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_All_OrgQuery, Get_All_OrgQueryVariables>(Get_All_OrgDocument, options);
        }
export type Get_All_OrgQueryHookResult = ReturnType<typeof useGet_All_OrgQuery>;
export type Get_All_OrgLazyQueryHookResult = ReturnType<typeof useGet_All_OrgLazyQuery>;
export type Get_All_OrgSuspenseQueryHookResult = ReturnType<typeof useGet_All_OrgSuspenseQuery>;
export type Get_All_OrgQueryResult = Apollo.QueryResult<Get_All_OrgQuery, Get_All_OrgQueryVariables>;
export const Get_Ticket_DetailsDocument = gql`
    query GET_TICKET_DETAILS($_eq: uuid = "") {
  examsonline_tickets(where: {id: {_eq: $_eq}, description: {}}) {
    assigned
    createdAt
    createdBy
    description
    name
    id
    org_id
    status
    updatedAt
    user {
      email
      organisation {
        name
        id
      }
      activated
    }
    comments {
      ...CommentFragment
    }
  }
}
    ${CommentFragmentFragmentDoc}`;

/**
 * __useGet_Ticket_DetailsQuery__
 *
 * To run a query within a React component, call `useGet_Ticket_DetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Ticket_DetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Ticket_DetailsQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_Ticket_DetailsQuery(baseOptions?: Apollo.QueryHookOptions<Get_Ticket_DetailsQuery, Get_Ticket_DetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_Ticket_DetailsQuery, Get_Ticket_DetailsQueryVariables>(Get_Ticket_DetailsDocument, options);
      }
export function useGet_Ticket_DetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Ticket_DetailsQuery, Get_Ticket_DetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_Ticket_DetailsQuery, Get_Ticket_DetailsQueryVariables>(Get_Ticket_DetailsDocument, options);
        }
export function useGet_Ticket_DetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_Ticket_DetailsQuery, Get_Ticket_DetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_Ticket_DetailsQuery, Get_Ticket_DetailsQueryVariables>(Get_Ticket_DetailsDocument, options);
        }
export type Get_Ticket_DetailsQueryHookResult = ReturnType<typeof useGet_Ticket_DetailsQuery>;
export type Get_Ticket_DetailsLazyQueryHookResult = ReturnType<typeof useGet_Ticket_DetailsLazyQuery>;
export type Get_Ticket_DetailsSuspenseQueryHookResult = ReturnType<typeof useGet_Ticket_DetailsSuspenseQuery>;
export type Get_Ticket_DetailsQueryResult = Apollo.QueryResult<Get_Ticket_DetailsQuery, Get_Ticket_DetailsQueryVariables>;
export const Get_Issue_DetailsDocument = gql`
    query GET_ISSUE_DETAILS($limit: Int = 10, $offset: Int = 0, $_iregex: String = "") {
  examsonline_tickets(
    limit: $limit
    offset: $offset
    where: {description: {_iregex: $_iregex}}
    order_by: {createdAt: desc}
  ) {
    createdAt
    description
    status
    id
    name
    updatedAt
  }
  total: examsonline_tickets_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGet_Issue_DetailsQuery__
 *
 * To run a query within a React component, call `useGet_Issue_DetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Issue_DetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Issue_DetailsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      _iregex: // value for '_iregex'
 *   },
 * });
 */
export function useGet_Issue_DetailsQuery(baseOptions?: Apollo.QueryHookOptions<Get_Issue_DetailsQuery, Get_Issue_DetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_Issue_DetailsQuery, Get_Issue_DetailsQueryVariables>(Get_Issue_DetailsDocument, options);
      }
export function useGet_Issue_DetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Issue_DetailsQuery, Get_Issue_DetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_Issue_DetailsQuery, Get_Issue_DetailsQueryVariables>(Get_Issue_DetailsDocument, options);
        }
export function useGet_Issue_DetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_Issue_DetailsQuery, Get_Issue_DetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_Issue_DetailsQuery, Get_Issue_DetailsQueryVariables>(Get_Issue_DetailsDocument, options);
        }
export type Get_Issue_DetailsQueryHookResult = ReturnType<typeof useGet_Issue_DetailsQuery>;
export type Get_Issue_DetailsLazyQueryHookResult = ReturnType<typeof useGet_Issue_DetailsLazyQuery>;
export type Get_Issue_DetailsSuspenseQueryHookResult = ReturnType<typeof useGet_Issue_DetailsSuspenseQuery>;
export type Get_Issue_DetailsQueryResult = Apollo.QueryResult<Get_Issue_DetailsQuery, Get_Issue_DetailsQueryVariables>;
export const Get_Tickets_DescriptionDocument = gql`
    query Get_Tickets_Description($_iregex: String = "") {
  examsonline_tickets(
    limit: 5
    order_by: {createdAt: desc}
    where: {description: {_iregex: $_iregex}}
  ) {
    description
    name
    createdAt
    createdBy
  }
}
    `;

/**
 * __useGet_Tickets_DescriptionQuery__
 *
 * To run a query within a React component, call `useGet_Tickets_DescriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Tickets_DescriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Tickets_DescriptionQuery({
 *   variables: {
 *      _iregex: // value for '_iregex'
 *   },
 * });
 */
export function useGet_Tickets_DescriptionQuery(baseOptions?: Apollo.QueryHookOptions<Get_Tickets_DescriptionQuery, Get_Tickets_DescriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_Tickets_DescriptionQuery, Get_Tickets_DescriptionQueryVariables>(Get_Tickets_DescriptionDocument, options);
      }
export function useGet_Tickets_DescriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Tickets_DescriptionQuery, Get_Tickets_DescriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_Tickets_DescriptionQuery, Get_Tickets_DescriptionQueryVariables>(Get_Tickets_DescriptionDocument, options);
        }
export function useGet_Tickets_DescriptionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_Tickets_DescriptionQuery, Get_Tickets_DescriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_Tickets_DescriptionQuery, Get_Tickets_DescriptionQueryVariables>(Get_Tickets_DescriptionDocument, options);
        }
export type Get_Tickets_DescriptionQueryHookResult = ReturnType<typeof useGet_Tickets_DescriptionQuery>;
export type Get_Tickets_DescriptionLazyQueryHookResult = ReturnType<typeof useGet_Tickets_DescriptionLazyQuery>;
export type Get_Tickets_DescriptionSuspenseQueryHookResult = ReturnType<typeof useGet_Tickets_DescriptionSuspenseQuery>;
export type Get_Tickets_DescriptionQueryResult = Apollo.QueryResult<Get_Tickets_DescriptionQuery, Get_Tickets_DescriptionQueryVariables>;
export const Create_TicketDocument = gql`
    mutation CREATE_TICKET($description: String = "", $priority: String = "", $name: String = "") {
  insert_examsonline_tickets(
    objects: {description: $description, priority: $priority, name: $name}
  ) {
    returning {
      id
      updatedAt
      createdBy
      createdAt
      description
      name
      org_id
      status
      priority
    }
  }
}
    `;
export type Create_TicketMutationFn = Apollo.MutationFunction<Create_TicketMutation, Create_TicketMutationVariables>;

/**
 * __useCreate_TicketMutation__
 *
 * To run a mutation, you first call `useCreate_TicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_TicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTicketMutation, { data, loading, error }] = useCreate_TicketMutation({
 *   variables: {
 *      description: // value for 'description'
 *      priority: // value for 'priority'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreate_TicketMutation(baseOptions?: Apollo.MutationHookOptions<Create_TicketMutation, Create_TicketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Create_TicketMutation, Create_TicketMutationVariables>(Create_TicketDocument, options);
      }
export type Create_TicketMutationHookResult = ReturnType<typeof useCreate_TicketMutation>;
export type Create_TicketMutationResult = Apollo.MutationResult<Create_TicketMutation>;
export type Create_TicketMutationOptions = Apollo.BaseMutationOptions<Create_TicketMutation, Create_TicketMutationVariables>;
export const Create_DemoDocument = gql`
    mutation CREATE_DEMO($name: String = "", $message: String = "", $reason: String = "", $email: String = "", $phone: numeric = "") {
  insert_examsonline_contactus(
    objects: {name: $name, message: $message, reason: $reason, email: $email, phone: $phone}
  ) {
    returning {
      id
      message
      reason
    }
  }
}
    `;
export type Create_DemoMutationFn = Apollo.MutationFunction<Create_DemoMutation, Create_DemoMutationVariables>;

/**
 * __useCreate_DemoMutation__
 *
 * To run a mutation, you first call `useCreate_DemoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_DemoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDemoMutation, { data, loading, error }] = useCreate_DemoMutation({
 *   variables: {
 *      name: // value for 'name'
 *      message: // value for 'message'
 *      reason: // value for 'reason'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useCreate_DemoMutation(baseOptions?: Apollo.MutationHookOptions<Create_DemoMutation, Create_DemoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Create_DemoMutation, Create_DemoMutationVariables>(Create_DemoDocument, options);
      }
export type Create_DemoMutationHookResult = ReturnType<typeof useCreate_DemoMutation>;
export type Create_DemoMutationResult = Apollo.MutationResult<Create_DemoMutation>;
export type Create_DemoMutationOptions = Apollo.BaseMutationOptions<Create_DemoMutation, Create_DemoMutationVariables>;
export const Get_All_Joined_GroupsDocument = gql`
    query GET_ALL_JOINED_GROUPS {
  examsonline_members {
    group: group {
      id
      name
    }
  }
}
    `;

/**
 * __useGet_All_Joined_GroupsQuery__
 *
 * To run a query within a React component, call `useGet_All_Joined_GroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_All_Joined_GroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_All_Joined_GroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_All_Joined_GroupsQuery(baseOptions?: Apollo.QueryHookOptions<Get_All_Joined_GroupsQuery, Get_All_Joined_GroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_All_Joined_GroupsQuery, Get_All_Joined_GroupsQueryVariables>(Get_All_Joined_GroupsDocument, options);
      }
export function useGet_All_Joined_GroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_All_Joined_GroupsQuery, Get_All_Joined_GroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_All_Joined_GroupsQuery, Get_All_Joined_GroupsQueryVariables>(Get_All_Joined_GroupsDocument, options);
        }
export function useGet_All_Joined_GroupsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_All_Joined_GroupsQuery, Get_All_Joined_GroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_All_Joined_GroupsQuery, Get_All_Joined_GroupsQueryVariables>(Get_All_Joined_GroupsDocument, options);
        }
export type Get_All_Joined_GroupsQueryHookResult = ReturnType<typeof useGet_All_Joined_GroupsQuery>;
export type Get_All_Joined_GroupsLazyQueryHookResult = ReturnType<typeof useGet_All_Joined_GroupsLazyQuery>;
export type Get_All_Joined_GroupsSuspenseQueryHookResult = ReturnType<typeof useGet_All_Joined_GroupsSuspenseQuery>;
export type Get_All_Joined_GroupsQueryResult = Apollo.QueryResult<Get_All_Joined_GroupsQuery, Get_All_Joined_GroupsQueryVariables>;
export const Create_Contest_FormDocument = gql`
    mutation CREATE_CONTEST_FORM($objects: [examsonline_contest_insert_input!] = {}, $update_columns: [examsonline_contest_update_column!] = createdAt) {
  insert_examsonline_contest(
    objects: $objects
    on_conflict: {constraint: contest_pkey, update_columns: $update_columns}
  ) {
    returning {
      author
      id
      name
      endDate
      duration
      description
      createdAt
      startDate
      test_id
      updatedAt
    }
  }
}
    `;
export type Create_Contest_FormMutationFn = Apollo.MutationFunction<Create_Contest_FormMutation, Create_Contest_FormMutationVariables>;

/**
 * __useCreate_Contest_FormMutation__
 *
 * To run a mutation, you first call `useCreate_Contest_FormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_Contest_FormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContestFormMutation, { data, loading, error }] = useCreate_Contest_FormMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      update_columns: // value for 'update_columns'
 *   },
 * });
 */
export function useCreate_Contest_FormMutation(baseOptions?: Apollo.MutationHookOptions<Create_Contest_FormMutation, Create_Contest_FormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Create_Contest_FormMutation, Create_Contest_FormMutationVariables>(Create_Contest_FormDocument, options);
      }
export type Create_Contest_FormMutationHookResult = ReturnType<typeof useCreate_Contest_FormMutation>;
export type Create_Contest_FormMutationResult = Apollo.MutationResult<Create_Contest_FormMutation>;
export type Create_Contest_FormMutationOptions = Apollo.BaseMutationOptions<Create_Contest_FormMutation, Create_Contest_FormMutationVariables>;
export const Get_All_ContestDocument = gql`
    query GET_ALL_CONTEST($_eq: uuid = "") {
  examsonline_contest(where: {id: {_eq: $_eq}}) {
    author
    description
    endDate
    duration
    createdAt
    id
    name
    topics {
      tag
    }
    startDate
    test_id
    updatedAt
    user {
      email
      organisation {
        name
      }
    }
  }
}
    `;

/**
 * __useGet_All_ContestQuery__
 *
 * To run a query within a React component, call `useGet_All_ContestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_All_ContestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_All_ContestQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_All_ContestQuery(baseOptions?: Apollo.QueryHookOptions<Get_All_ContestQuery, Get_All_ContestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_All_ContestQuery, Get_All_ContestQueryVariables>(Get_All_ContestDocument, options);
      }
export function useGet_All_ContestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_All_ContestQuery, Get_All_ContestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_All_ContestQuery, Get_All_ContestQueryVariables>(Get_All_ContestDocument, options);
        }
export function useGet_All_ContestSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_All_ContestQuery, Get_All_ContestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_All_ContestQuery, Get_All_ContestQueryVariables>(Get_All_ContestDocument, options);
        }
export type Get_All_ContestQueryHookResult = ReturnType<typeof useGet_All_ContestQuery>;
export type Get_All_ContestLazyQueryHookResult = ReturnType<typeof useGet_All_ContestLazyQuery>;
export type Get_All_ContestSuspenseQueryHookResult = ReturnType<typeof useGet_All_ContestSuspenseQuery>;
export type Get_All_ContestQueryResult = Apollo.QueryResult<Get_All_ContestQuery, Get_All_ContestQueryVariables>;
export const Add_Priority_StatusDocument = gql`
    query ADD_PRIORITY_STATUS {
  _enumtable_priority(limit: 10) {
    value
  }
}
    `;

/**
 * __useAdd_Priority_StatusQuery__
 *
 * To run a query within a React component, call `useAdd_Priority_StatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdd_Priority_StatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdd_Priority_StatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdd_Priority_StatusQuery(baseOptions?: Apollo.QueryHookOptions<Add_Priority_StatusQuery, Add_Priority_StatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Add_Priority_StatusQuery, Add_Priority_StatusQueryVariables>(Add_Priority_StatusDocument, options);
      }
export function useAdd_Priority_StatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Add_Priority_StatusQuery, Add_Priority_StatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Add_Priority_StatusQuery, Add_Priority_StatusQueryVariables>(Add_Priority_StatusDocument, options);
        }
export function useAdd_Priority_StatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Add_Priority_StatusQuery, Add_Priority_StatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Add_Priority_StatusQuery, Add_Priority_StatusQueryVariables>(Add_Priority_StatusDocument, options);
        }
export type Add_Priority_StatusQueryHookResult = ReturnType<typeof useAdd_Priority_StatusQuery>;
export type Add_Priority_StatusLazyQueryHookResult = ReturnType<typeof useAdd_Priority_StatusLazyQuery>;
export type Add_Priority_StatusSuspenseQueryHookResult = ReturnType<typeof useAdd_Priority_StatusSuspenseQuery>;
export type Add_Priority_StatusQueryResult = Apollo.QueryResult<Add_Priority_StatusQuery, Add_Priority_StatusQueryVariables>;
export const Add_CountryDocument = gql`
    query ADD_COUNTRY {
  _enumtable_country {
    name
  }
}
    `;

/**
 * __useAdd_CountryQuery__
 *
 * To run a query within a React component, call `useAdd_CountryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdd_CountryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdd_CountryQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdd_CountryQuery(baseOptions?: Apollo.QueryHookOptions<Add_CountryQuery, Add_CountryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Add_CountryQuery, Add_CountryQueryVariables>(Add_CountryDocument, options);
      }
export function useAdd_CountryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Add_CountryQuery, Add_CountryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Add_CountryQuery, Add_CountryQueryVariables>(Add_CountryDocument, options);
        }
export function useAdd_CountrySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Add_CountryQuery, Add_CountryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Add_CountryQuery, Add_CountryQueryVariables>(Add_CountryDocument, options);
        }
export type Add_CountryQueryHookResult = ReturnType<typeof useAdd_CountryQuery>;
export type Add_CountryLazyQueryHookResult = ReturnType<typeof useAdd_CountryLazyQuery>;
export type Add_CountrySuspenseQueryHookResult = ReturnType<typeof useAdd_CountrySuspenseQuery>;
export type Add_CountryQueryResult = Apollo.QueryResult<Add_CountryQuery, Add_CountryQueryVariables>;
export const Ticket_Status_ListDocument = gql`
    query TICKET_STATUS_LIST {
  _enumtable_ticket_status {
    status
  }
}
    `;

/**
 * __useTicket_Status_ListQuery__
 *
 * To run a query within a React component, call `useTicket_Status_ListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicket_Status_ListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicket_Status_ListQuery({
 *   variables: {
 *   },
 * });
 */
export function useTicket_Status_ListQuery(baseOptions?: Apollo.QueryHookOptions<Ticket_Status_ListQuery, Ticket_Status_ListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Ticket_Status_ListQuery, Ticket_Status_ListQueryVariables>(Ticket_Status_ListDocument, options);
      }
export function useTicket_Status_ListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Ticket_Status_ListQuery, Ticket_Status_ListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Ticket_Status_ListQuery, Ticket_Status_ListQueryVariables>(Ticket_Status_ListDocument, options);
        }
export function useTicket_Status_ListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Ticket_Status_ListQuery, Ticket_Status_ListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Ticket_Status_ListQuery, Ticket_Status_ListQueryVariables>(Ticket_Status_ListDocument, options);
        }
export type Ticket_Status_ListQueryHookResult = ReturnType<typeof useTicket_Status_ListQuery>;
export type Ticket_Status_ListLazyQueryHookResult = ReturnType<typeof useTicket_Status_ListLazyQuery>;
export type Ticket_Status_ListSuspenseQueryHookResult = ReturnType<typeof useTicket_Status_ListSuspenseQuery>;
export type Ticket_Status_ListQueryResult = Apollo.QueryResult<Ticket_Status_ListQuery, Ticket_Status_ListQueryVariables>;
export const Support_User_ListDocument = gql`
    query SUPPORT_USER_LIST {
  examsonline_roles(where: {role: {_eq: "SUPPORT"}}) {
    user
  }
}
    `;

/**
 * __useSupport_User_ListQuery__
 *
 * To run a query within a React component, call `useSupport_User_ListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupport_User_ListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupport_User_ListQuery({
 *   variables: {
 *   },
 * });
 */
export function useSupport_User_ListQuery(baseOptions?: Apollo.QueryHookOptions<Support_User_ListQuery, Support_User_ListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Support_User_ListQuery, Support_User_ListQueryVariables>(Support_User_ListDocument, options);
      }
export function useSupport_User_ListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Support_User_ListQuery, Support_User_ListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Support_User_ListQuery, Support_User_ListQueryVariables>(Support_User_ListDocument, options);
        }
export function useSupport_User_ListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Support_User_ListQuery, Support_User_ListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Support_User_ListQuery, Support_User_ListQueryVariables>(Support_User_ListDocument, options);
        }
export type Support_User_ListQueryHookResult = ReturnType<typeof useSupport_User_ListQuery>;
export type Support_User_ListLazyQueryHookResult = ReturnType<typeof useSupport_User_ListLazyQuery>;
export type Support_User_ListSuspenseQueryHookResult = ReturnType<typeof useSupport_User_ListSuspenseQuery>;
export type Support_User_ListQueryResult = Apollo.QueryResult<Support_User_ListQuery, Support_User_ListQueryVariables>;
export const Get_My_Assignments_Filter_By_DateDocument = gql`
    query GET_MY_ASSIGNMENTS_FILTER_BY_DATE($_gte: timestamptz = "", $_lte: timestamptz = "") {
  examsonline_assignments(where: {startDate: {_gte: $_gte, _lte: $_lte}}) {
    startDate
    id
    name
    test_id
  }
}
    `;

/**
 * __useGet_My_Assignments_Filter_By_DateQuery__
 *
 * To run a query within a React component, call `useGet_My_Assignments_Filter_By_DateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_My_Assignments_Filter_By_DateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_My_Assignments_Filter_By_DateQuery({
 *   variables: {
 *      _gte: // value for '_gte'
 *      _lte: // value for '_lte'
 *   },
 * });
 */
export function useGet_My_Assignments_Filter_By_DateQuery(baseOptions?: Apollo.QueryHookOptions<Get_My_Assignments_Filter_By_DateQuery, Get_My_Assignments_Filter_By_DateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_My_Assignments_Filter_By_DateQuery, Get_My_Assignments_Filter_By_DateQueryVariables>(Get_My_Assignments_Filter_By_DateDocument, options);
      }
export function useGet_My_Assignments_Filter_By_DateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_My_Assignments_Filter_By_DateQuery, Get_My_Assignments_Filter_By_DateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_My_Assignments_Filter_By_DateQuery, Get_My_Assignments_Filter_By_DateQueryVariables>(Get_My_Assignments_Filter_By_DateDocument, options);
        }
export function useGet_My_Assignments_Filter_By_DateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_My_Assignments_Filter_By_DateQuery, Get_My_Assignments_Filter_By_DateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_My_Assignments_Filter_By_DateQuery, Get_My_Assignments_Filter_By_DateQueryVariables>(Get_My_Assignments_Filter_By_DateDocument, options);
        }
export type Get_My_Assignments_Filter_By_DateQueryHookResult = ReturnType<typeof useGet_My_Assignments_Filter_By_DateQuery>;
export type Get_My_Assignments_Filter_By_DateLazyQueryHookResult = ReturnType<typeof useGet_My_Assignments_Filter_By_DateLazyQuery>;
export type Get_My_Assignments_Filter_By_DateSuspenseQueryHookResult = ReturnType<typeof useGet_My_Assignments_Filter_By_DateSuspenseQuery>;
export type Get_My_Assignments_Filter_By_DateQueryResult = Apollo.QueryResult<Get_My_Assignments_Filter_By_DateQuery, Get_My_Assignments_Filter_By_DateQueryVariables>;
export const Get_ContestDocument = gql`
    query GET_CONTEST($limit: Int = 10, $offset: Int = 0, $_iregex: String = "") {
  examsonline_contest(
    limit: $limit
    offset: $offset
    where: {name: {_iregex: $_iregex}}
    order_by: {createdAt: desc_nulls_last}
  ) {
    id
    name
    createdAt
    author
    duration
    endDate
    startDate
    test_id
    files {
      info
    }
    topics {
      tag
    }
  }
  total: examsonline_contest_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGet_ContestQuery__
 *
 * To run a query within a React component, call `useGet_ContestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_ContestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_ContestQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      _iregex: // value for '_iregex'
 *   },
 * });
 */
export function useGet_ContestQuery(baseOptions?: Apollo.QueryHookOptions<Get_ContestQuery, Get_ContestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_ContestQuery, Get_ContestQueryVariables>(Get_ContestDocument, options);
      }
export function useGet_ContestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_ContestQuery, Get_ContestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_ContestQuery, Get_ContestQueryVariables>(Get_ContestDocument, options);
        }
export function useGet_ContestSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_ContestQuery, Get_ContestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_ContestQuery, Get_ContestQueryVariables>(Get_ContestDocument, options);
        }
export type Get_ContestQueryHookResult = ReturnType<typeof useGet_ContestQuery>;
export type Get_ContestLazyQueryHookResult = ReturnType<typeof useGet_ContestLazyQuery>;
export type Get_ContestSuspenseQueryHookResult = ReturnType<typeof useGet_ContestSuspenseQuery>;
export type Get_ContestQueryResult = Apollo.QueryResult<Get_ContestQuery, Get_ContestQueryVariables>;
export const Get_Contest_By_IdDocument = gql`
    query GET_CONTEST_BY_ID($_eq: uuid = "") {
  examsonline_contest(
    where: {id: {_eq: $_eq}}
    order_by: {createdAt: asc_nulls_last}
  ) {
    id
    name
    createdAt
    author
    duration
    description
    endDate
    test_id
    startDate
    files {
      info
    }
    topics {
      tag
    }
  }
}
    `;

/**
 * __useGet_Contest_By_IdQuery__
 *
 * To run a query within a React component, call `useGet_Contest_By_IdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Contest_By_IdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Contest_By_IdQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_Contest_By_IdQuery(baseOptions?: Apollo.QueryHookOptions<Get_Contest_By_IdQuery, Get_Contest_By_IdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_Contest_By_IdQuery, Get_Contest_By_IdQueryVariables>(Get_Contest_By_IdDocument, options);
      }
export function useGet_Contest_By_IdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Contest_By_IdQuery, Get_Contest_By_IdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_Contest_By_IdQuery, Get_Contest_By_IdQueryVariables>(Get_Contest_By_IdDocument, options);
        }
export function useGet_Contest_By_IdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_Contest_By_IdQuery, Get_Contest_By_IdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_Contest_By_IdQuery, Get_Contest_By_IdQueryVariables>(Get_Contest_By_IdDocument, options);
        }
export type Get_Contest_By_IdQueryHookResult = ReturnType<typeof useGet_Contest_By_IdQuery>;
export type Get_Contest_By_IdLazyQueryHookResult = ReturnType<typeof useGet_Contest_By_IdLazyQuery>;
export type Get_Contest_By_IdSuspenseQueryHookResult = ReturnType<typeof useGet_Contest_By_IdSuspenseQuery>;
export type Get_Contest_By_IdQueryResult = Apollo.QueryResult<Get_Contest_By_IdQuery, Get_Contest_By_IdQueryVariables>;
export const Update_Contest_Test_IdDocument = gql`
    mutation UPDATE_CONTEST_TEST_ID($_eq: uuid = "", $test_id: Int = 10) {
  update_examsonline_contest(where: {id: {_eq: $_eq}}, _set: {test_id: $test_id}) {
    returning {
      id
      test_id
      updatedAt
    }
  }
}
    `;
export type Update_Contest_Test_IdMutationFn = Apollo.MutationFunction<Update_Contest_Test_IdMutation, Update_Contest_Test_IdMutationVariables>;

/**
 * __useUpdate_Contest_Test_IdMutation__
 *
 * To run a mutation, you first call `useUpdate_Contest_Test_IdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Contest_Test_IdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContestTestIdMutation, { data, loading, error }] = useUpdate_Contest_Test_IdMutation({
 *   variables: {
 *      _eq: // value for '_eq'
 *      test_id: // value for 'test_id'
 *   },
 * });
 */
export function useUpdate_Contest_Test_IdMutation(baseOptions?: Apollo.MutationHookOptions<Update_Contest_Test_IdMutation, Update_Contest_Test_IdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_Contest_Test_IdMutation, Update_Contest_Test_IdMutationVariables>(Update_Contest_Test_IdDocument, options);
      }
export type Update_Contest_Test_IdMutationHookResult = ReturnType<typeof useUpdate_Contest_Test_IdMutation>;
export type Update_Contest_Test_IdMutationResult = Apollo.MutationResult<Update_Contest_Test_IdMutation>;
export type Update_Contest_Test_IdMutationOptions = Apollo.BaseMutationOptions<Update_Contest_Test_IdMutation, Update_Contest_Test_IdMutationVariables>;
export const Join_ParticipantsDocument = gql`
    mutation JOIN_PARTICIPANTS($contest_id: uuid = "", $email: String = "") {
  insert_examsonline_participate(
    objects: {contest_id: $contest_id, email: $email}
  ) {
    returning {
      email
      id
    }
  }
}
    `;
export type Join_ParticipantsMutationFn = Apollo.MutationFunction<Join_ParticipantsMutation, Join_ParticipantsMutationVariables>;

/**
 * __useJoin_ParticipantsMutation__
 *
 * To run a mutation, you first call `useJoin_ParticipantsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoin_ParticipantsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinParticipantsMutation, { data, loading, error }] = useJoin_ParticipantsMutation({
 *   variables: {
 *      contest_id: // value for 'contest_id'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useJoin_ParticipantsMutation(baseOptions?: Apollo.MutationHookOptions<Join_ParticipantsMutation, Join_ParticipantsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Join_ParticipantsMutation, Join_ParticipantsMutationVariables>(Join_ParticipantsDocument, options);
      }
export type Join_ParticipantsMutationHookResult = ReturnType<typeof useJoin_ParticipantsMutation>;
export type Join_ParticipantsMutationResult = Apollo.MutationResult<Join_ParticipantsMutation>;
export type Join_ParticipantsMutationOptions = Apollo.BaseMutationOptions<Join_ParticipantsMutation, Join_ParticipantsMutationVariables>;
export const Participating_In_ContestDocument = gql`
    query PARTICIPATING_IN_CONTEST($_eq: uuid = "", $_eq1: String = "") {
  examsonline_participate(where: {contest_id: {_eq: $_eq}, email: {_eq: $_eq1}}) {
    email
    id
  }
}
    `;

/**
 * __useParticipating_In_ContestQuery__
 *
 * To run a query within a React component, call `useParticipating_In_ContestQuery` and pass it any options that fit your needs.
 * When your component renders, `useParticipating_In_ContestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParticipating_In_ContestQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *      _eq1: // value for '_eq1'
 *   },
 * });
 */
export function useParticipating_In_ContestQuery(baseOptions?: Apollo.QueryHookOptions<Participating_In_ContestQuery, Participating_In_ContestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Participating_In_ContestQuery, Participating_In_ContestQueryVariables>(Participating_In_ContestDocument, options);
      }
export function useParticipating_In_ContestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Participating_In_ContestQuery, Participating_In_ContestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Participating_In_ContestQuery, Participating_In_ContestQueryVariables>(Participating_In_ContestDocument, options);
        }
export function useParticipating_In_ContestSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Participating_In_ContestQuery, Participating_In_ContestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Participating_In_ContestQuery, Participating_In_ContestQueryVariables>(Participating_In_ContestDocument, options);
        }
export type Participating_In_ContestQueryHookResult = ReturnType<typeof useParticipating_In_ContestQuery>;
export type Participating_In_ContestLazyQueryHookResult = ReturnType<typeof useParticipating_In_ContestLazyQuery>;
export type Participating_In_ContestSuspenseQueryHookResult = ReturnType<typeof useParticipating_In_ContestSuspenseQuery>;
export type Participating_In_ContestQueryResult = Apollo.QueryResult<Participating_In_ContestQuery, Participating_In_ContestQueryVariables>;
export const Join_Contest_ManyDocument = gql`
    mutation JOIN_CONTEST_MANY($objects: [examsonline_participate_insert_input!] = {}) {
  insert_examsonline_participate(objects: $objects) {
    affected_rows
  }
}
    `;
export type Join_Contest_ManyMutationFn = Apollo.MutationFunction<Join_Contest_ManyMutation, Join_Contest_ManyMutationVariables>;

/**
 * __useJoin_Contest_ManyMutation__
 *
 * To run a mutation, you first call `useJoin_Contest_ManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoin_Contest_ManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinContestManyMutation, { data, loading, error }] = useJoin_Contest_ManyMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useJoin_Contest_ManyMutation(baseOptions?: Apollo.MutationHookOptions<Join_Contest_ManyMutation, Join_Contest_ManyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Join_Contest_ManyMutation, Join_Contest_ManyMutationVariables>(Join_Contest_ManyDocument, options);
      }
export type Join_Contest_ManyMutationHookResult = ReturnType<typeof useJoin_Contest_ManyMutation>;
export type Join_Contest_ManyMutationResult = Apollo.MutationResult<Join_Contest_ManyMutation>;
export type Join_Contest_ManyMutationOptions = Apollo.BaseMutationOptions<Join_Contest_ManyMutation, Join_Contest_ManyMutationVariables>;
export const Delete_Topics_By_Contest_IdDocument = gql`
    mutation delete_topics_by_contest_id($_eq: uuid = "") {
  delete_examsonline_topics(where: {contest_id: {_eq: $_eq}}) {
    affected_rows
  }
}
    `;
export type Delete_Topics_By_Contest_IdMutationFn = Apollo.MutationFunction<Delete_Topics_By_Contest_IdMutation, Delete_Topics_By_Contest_IdMutationVariables>;

/**
 * __useDelete_Topics_By_Contest_IdMutation__
 *
 * To run a mutation, you first call `useDelete_Topics_By_Contest_IdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_Topics_By_Contest_IdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTopicsByContestIdMutation, { data, loading, error }] = useDelete_Topics_By_Contest_IdMutation({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useDelete_Topics_By_Contest_IdMutation(baseOptions?: Apollo.MutationHookOptions<Delete_Topics_By_Contest_IdMutation, Delete_Topics_By_Contest_IdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Delete_Topics_By_Contest_IdMutation, Delete_Topics_By_Contest_IdMutationVariables>(Delete_Topics_By_Contest_IdDocument, options);
      }
export type Delete_Topics_By_Contest_IdMutationHookResult = ReturnType<typeof useDelete_Topics_By_Contest_IdMutation>;
export type Delete_Topics_By_Contest_IdMutationResult = Apollo.MutationResult<Delete_Topics_By_Contest_IdMutation>;
export type Delete_Topics_By_Contest_IdMutationOptions = Apollo.BaseMutationOptions<Delete_Topics_By_Contest_IdMutation, Delete_Topics_By_Contest_IdMutationVariables>;
export const Get_All_ParticipantsDocument = gql`
    query GET_ALL_PARTICIPANTS($limit: Int = 10, $offset: Int = 0, $_iregex: String = "", $_eq: uuid = "", $_eq1: uuid = "") {
  examsonline_participate(
    limit: $limit
    offset: $offset
    where: {email: {_iregex: $_iregex}, contest_id: {_eq: $_eq}}
    order_by: {createdAt: desc_nulls_last}
  ) {
    email
    createdAt
    id
    contest_id
  }
  total: examsonline_participate_aggregate(where: {contest_id: {_eq: $_eq1}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGet_All_ParticipantsQuery__
 *
 * To run a query within a React component, call `useGet_All_ParticipantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_All_ParticipantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_All_ParticipantsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      _iregex: // value for '_iregex'
 *      _eq: // value for '_eq'
 *      _eq1: // value for '_eq1'
 *   },
 * });
 */
export function useGet_All_ParticipantsQuery(baseOptions?: Apollo.QueryHookOptions<Get_All_ParticipantsQuery, Get_All_ParticipantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_All_ParticipantsQuery, Get_All_ParticipantsQueryVariables>(Get_All_ParticipantsDocument, options);
      }
export function useGet_All_ParticipantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_All_ParticipantsQuery, Get_All_ParticipantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_All_ParticipantsQuery, Get_All_ParticipantsQueryVariables>(Get_All_ParticipantsDocument, options);
        }
export function useGet_All_ParticipantsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_All_ParticipantsQuery, Get_All_ParticipantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_All_ParticipantsQuery, Get_All_ParticipantsQueryVariables>(Get_All_ParticipantsDocument, options);
        }
export type Get_All_ParticipantsQueryHookResult = ReturnType<typeof useGet_All_ParticipantsQuery>;
export type Get_All_ParticipantsLazyQueryHookResult = ReturnType<typeof useGet_All_ParticipantsLazyQuery>;
export type Get_All_ParticipantsSuspenseQueryHookResult = ReturnType<typeof useGet_All_ParticipantsSuspenseQuery>;
export type Get_All_ParticipantsQueryResult = Apollo.QueryResult<Get_All_ParticipantsQuery, Get_All_ParticipantsQueryVariables>;
export const Get_Assignment_By_IdDocument = gql`
    query GET_ASSIGNMENT_BY_ID($_eq: uuid = "") {
  examsonline_assignments(where: {id: {_eq: $_eq}}) {
    id
    name
    startDate
    endDate
    createdAt
    user
    test_id
    comments {
      ...CommentFragment
    }
  }
}
    ${CommentFragmentFragmentDoc}`;

/**
 * __useGet_Assignment_By_IdQuery__
 *
 * To run a query within a React component, call `useGet_Assignment_By_IdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Assignment_By_IdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Assignment_By_IdQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_Assignment_By_IdQuery(baseOptions?: Apollo.QueryHookOptions<Get_Assignment_By_IdQuery, Get_Assignment_By_IdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_Assignment_By_IdQuery, Get_Assignment_By_IdQueryVariables>(Get_Assignment_By_IdDocument, options);
      }
export function useGet_Assignment_By_IdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Assignment_By_IdQuery, Get_Assignment_By_IdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_Assignment_By_IdQuery, Get_Assignment_By_IdQueryVariables>(Get_Assignment_By_IdDocument, options);
        }
export function useGet_Assignment_By_IdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_Assignment_By_IdQuery, Get_Assignment_By_IdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_Assignment_By_IdQuery, Get_Assignment_By_IdQueryVariables>(Get_Assignment_By_IdDocument, options);
        }
export type Get_Assignment_By_IdQueryHookResult = ReturnType<typeof useGet_Assignment_By_IdQuery>;
export type Get_Assignment_By_IdLazyQueryHookResult = ReturnType<typeof useGet_Assignment_By_IdLazyQuery>;
export type Get_Assignment_By_IdSuspenseQueryHookResult = ReturnType<typeof useGet_Assignment_By_IdSuspenseQuery>;
export type Get_Assignment_By_IdQueryResult = Apollo.QueryResult<Get_Assignment_By_IdQuery, Get_Assignment_By_IdQueryVariables>;
export const Remove_Test_From_ContestDocument = gql`
    mutation REMOVE_TEST_FROM_CONTEST($test_id: Int = null, $_eq: uuid = "") {
  update_examsonline_contest(where: {id: {_eq: $_eq}}, _set: {test_id: $test_id}) {
    returning {
      id
      test_id
    }
  }
}
    `;
export type Remove_Test_From_ContestMutationFn = Apollo.MutationFunction<Remove_Test_From_ContestMutation, Remove_Test_From_ContestMutationVariables>;

/**
 * __useRemove_Test_From_ContestMutation__
 *
 * To run a mutation, you first call `useRemove_Test_From_ContestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemove_Test_From_ContestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTestFromContestMutation, { data, loading, error }] = useRemove_Test_From_ContestMutation({
 *   variables: {
 *      test_id: // value for 'test_id'
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useRemove_Test_From_ContestMutation(baseOptions?: Apollo.MutationHookOptions<Remove_Test_From_ContestMutation, Remove_Test_From_ContestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Remove_Test_From_ContestMutation, Remove_Test_From_ContestMutationVariables>(Remove_Test_From_ContestDocument, options);
      }
export type Remove_Test_From_ContestMutationHookResult = ReturnType<typeof useRemove_Test_From_ContestMutation>;
export type Remove_Test_From_ContestMutationResult = Apollo.MutationResult<Remove_Test_From_ContestMutation>;
export type Remove_Test_From_ContestMutationOptions = Apollo.BaseMutationOptions<Remove_Test_From_ContestMutation, Remove_Test_From_ContestMutationVariables>;
export const My_FilesDocument = gql`
    query MY_FILES($limit: Int = 4, $offset: Int = 0) {
  examsonline_file(limit: $limit, offset: $offset) {
    id
    info
    size
    test {
      id
      name
      author
    }
  }
  total: examsonline_file_aggregate {
    aggregate {
      count
    }
  }
  total_size: examsonline_file_aggregate {
    aggregate {
      sum {
        size
      }
    }
  }
}
    `;

/**
 * __useMy_FilesQuery__
 *
 * To run a query within a React component, call `useMy_FilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMy_FilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMy_FilesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useMy_FilesQuery(baseOptions?: Apollo.QueryHookOptions<My_FilesQuery, My_FilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<My_FilesQuery, My_FilesQueryVariables>(My_FilesDocument, options);
      }
export function useMy_FilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<My_FilesQuery, My_FilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<My_FilesQuery, My_FilesQueryVariables>(My_FilesDocument, options);
        }
export function useMy_FilesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<My_FilesQuery, My_FilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<My_FilesQuery, My_FilesQueryVariables>(My_FilesDocument, options);
        }
export type My_FilesQueryHookResult = ReturnType<typeof useMy_FilesQuery>;
export type My_FilesLazyQueryHookResult = ReturnType<typeof useMy_FilesLazyQuery>;
export type My_FilesSuspenseQueryHookResult = ReturnType<typeof useMy_FilesSuspenseQuery>;
export type My_FilesQueryResult = Apollo.QueryResult<My_FilesQuery, My_FilesQueryVariables>;
export const Search_Contest_By_TagDocument = gql`
    query SEARCH_CONTEST_BY_TAG($_iregex: String = "", $_iregex1: String = "", $limit: Int = 140, $offset: Int = 0) {
  examsonline_topics(
    order_by: {contest: {createdAt: desc_nulls_last}}
    where: {_or: [{tag: {_iregex: $_iregex}}, {contest: {name: {_iregex: $_iregex1}}}]}
    limit: $limit
    offset: $offset
  ) {
    contest {
      id
      topics {
        tag
      }
      name
      author
      createdAt
      duration
      description
      files {
        id
        info
      }
    }
  }
  total_count: examsonline_contest_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useSearch_Contest_By_TagQuery__
 *
 * To run a query within a React component, call `useSearch_Contest_By_TagQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearch_Contest_By_TagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearch_Contest_By_TagQuery({
 *   variables: {
 *      _iregex: // value for '_iregex'
 *      _iregex1: // value for '_iregex1'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useSearch_Contest_By_TagQuery(baseOptions?: Apollo.QueryHookOptions<Search_Contest_By_TagQuery, Search_Contest_By_TagQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Search_Contest_By_TagQuery, Search_Contest_By_TagQueryVariables>(Search_Contest_By_TagDocument, options);
      }
export function useSearch_Contest_By_TagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Search_Contest_By_TagQuery, Search_Contest_By_TagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Search_Contest_By_TagQuery, Search_Contest_By_TagQueryVariables>(Search_Contest_By_TagDocument, options);
        }
export function useSearch_Contest_By_TagSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Search_Contest_By_TagQuery, Search_Contest_By_TagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Search_Contest_By_TagQuery, Search_Contest_By_TagQueryVariables>(Search_Contest_By_TagDocument, options);
        }
export type Search_Contest_By_TagQueryHookResult = ReturnType<typeof useSearch_Contest_By_TagQuery>;
export type Search_Contest_By_TagLazyQueryHookResult = ReturnType<typeof useSearch_Contest_By_TagLazyQuery>;
export type Search_Contest_By_TagSuspenseQueryHookResult = ReturnType<typeof useSearch_Contest_By_TagSuspenseQuery>;
export type Search_Contest_By_TagQueryResult = Apollo.QueryResult<Search_Contest_By_TagQuery, Search_Contest_By_TagQueryVariables>;
export const Update_Ticket_DetailsDocument = gql`
    mutation UPDATE_TICKET_DETAILS($_set: examsonline_tickets_set_input = {}, $_eq: uuid = "") {
  update_examsonline_tickets(where: {id: {_eq: $_eq}}, _set: $_set) {
    returning {
      id
      status
      assigned
    }
  }
}
    `;
export type Update_Ticket_DetailsMutationFn = Apollo.MutationFunction<Update_Ticket_DetailsMutation, Update_Ticket_DetailsMutationVariables>;

/**
 * __useUpdate_Ticket_DetailsMutation__
 *
 * To run a mutation, you first call `useUpdate_Ticket_DetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Ticket_DetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTicketDetailsMutation, { data, loading, error }] = useUpdate_Ticket_DetailsMutation({
 *   variables: {
 *      _set: // value for '_set'
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useUpdate_Ticket_DetailsMutation(baseOptions?: Apollo.MutationHookOptions<Update_Ticket_DetailsMutation, Update_Ticket_DetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_Ticket_DetailsMutation, Update_Ticket_DetailsMutationVariables>(Update_Ticket_DetailsDocument, options);
      }
export type Update_Ticket_DetailsMutationHookResult = ReturnType<typeof useUpdate_Ticket_DetailsMutation>;
export type Update_Ticket_DetailsMutationResult = Apollo.MutationResult<Update_Ticket_DetailsMutation>;
export type Update_Ticket_DetailsMutationOptions = Apollo.BaseMutationOptions<Update_Ticket_DetailsMutation, Update_Ticket_DetailsMutationVariables>;
export const Get_Test_PincodeDocument = gql`
    query GET_TEST_PINCODE($_eq: Int = 10) {
  examsonline_tests(where: {id: {_eq: $_eq}}) {
    pincode
  }
}
    `;

/**
 * __useGet_Test_PincodeQuery__
 *
 * To run a query within a React component, call `useGet_Test_PincodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Test_PincodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Test_PincodeQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_Test_PincodeQuery(baseOptions?: Apollo.QueryHookOptions<Get_Test_PincodeQuery, Get_Test_PincodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_Test_PincodeQuery, Get_Test_PincodeQueryVariables>(Get_Test_PincodeDocument, options);
      }
export function useGet_Test_PincodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Test_PincodeQuery, Get_Test_PincodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_Test_PincodeQuery, Get_Test_PincodeQueryVariables>(Get_Test_PincodeDocument, options);
        }
export function useGet_Test_PincodeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_Test_PincodeQuery, Get_Test_PincodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_Test_PincodeQuery, Get_Test_PincodeQueryVariables>(Get_Test_PincodeDocument, options);
        }
export type Get_Test_PincodeQueryHookResult = ReturnType<typeof useGet_Test_PincodeQuery>;
export type Get_Test_PincodeLazyQueryHookResult = ReturnType<typeof useGet_Test_PincodeLazyQuery>;
export type Get_Test_PincodeSuspenseQueryHookResult = ReturnType<typeof useGet_Test_PincodeSuspenseQuery>;
export type Get_Test_PincodeQueryResult = Apollo.QueryResult<Get_Test_PincodeQuery, Get_Test_PincodeQueryVariables>;
export const Total_File_Size_UsedDocument = gql`
    query TOTAL_FILE_SIZE_USED {
  examsonline_file_aggregate {
    aggregate {
      sum {
        size
      }
    }
  }
}
    `;

/**
 * __useTotal_File_Size_UsedQuery__
 *
 * To run a query within a React component, call `useTotal_File_Size_UsedQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotal_File_Size_UsedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotal_File_Size_UsedQuery({
 *   variables: {
 *   },
 * });
 */
export function useTotal_File_Size_UsedQuery(baseOptions?: Apollo.QueryHookOptions<Total_File_Size_UsedQuery, Total_File_Size_UsedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Total_File_Size_UsedQuery, Total_File_Size_UsedQueryVariables>(Total_File_Size_UsedDocument, options);
      }
export function useTotal_File_Size_UsedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Total_File_Size_UsedQuery, Total_File_Size_UsedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Total_File_Size_UsedQuery, Total_File_Size_UsedQueryVariables>(Total_File_Size_UsedDocument, options);
        }
export function useTotal_File_Size_UsedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Total_File_Size_UsedQuery, Total_File_Size_UsedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Total_File_Size_UsedQuery, Total_File_Size_UsedQueryVariables>(Total_File_Size_UsedDocument, options);
        }
export type Total_File_Size_UsedQueryHookResult = ReturnType<typeof useTotal_File_Size_UsedQuery>;
export type Total_File_Size_UsedLazyQueryHookResult = ReturnType<typeof useTotal_File_Size_UsedLazyQuery>;
export type Total_File_Size_UsedSuspenseQueryHookResult = ReturnType<typeof useTotal_File_Size_UsedSuspenseQuery>;
export type Total_File_Size_UsedQueryResult = Apollo.QueryResult<Total_File_Size_UsedQuery, Total_File_Size_UsedQueryVariables>;
export const Get_All_Participants_ResultDocument = gql`
    query GET_ALL_PARTICIPANTS_RESULT($limit: Int = 10, $offset: Int = 0, $_iregex: String = "", $_eq: uuid = "", $_eq1: uuid = "") {
  examsonline_attempt(
    limit: $limit
    offset: $offset
    where: {email: {_iregex: $_iregex}, contest_id: {_eq: $_eq}}
    order_by: {percentage: desc_nulls_first}
  ) {
    id
    email
    createdAt
    updatedAt
    correct
    wrong
    percentage
    skipped
    completed
  }
  total: examsonline_attempt_aggregate(where: {contest_id: {_eq: $_eq1}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGet_All_Participants_ResultQuery__
 *
 * To run a query within a React component, call `useGet_All_Participants_ResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_All_Participants_ResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_All_Participants_ResultQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      _iregex: // value for '_iregex'
 *      _eq: // value for '_eq'
 *      _eq1: // value for '_eq1'
 *   },
 * });
 */
export function useGet_All_Participants_ResultQuery(baseOptions?: Apollo.QueryHookOptions<Get_All_Participants_ResultQuery, Get_All_Participants_ResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_All_Participants_ResultQuery, Get_All_Participants_ResultQueryVariables>(Get_All_Participants_ResultDocument, options);
      }
export function useGet_All_Participants_ResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_All_Participants_ResultQuery, Get_All_Participants_ResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_All_Participants_ResultQuery, Get_All_Participants_ResultQueryVariables>(Get_All_Participants_ResultDocument, options);
        }
export function useGet_All_Participants_ResultSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_All_Participants_ResultQuery, Get_All_Participants_ResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_All_Participants_ResultQuery, Get_All_Participants_ResultQueryVariables>(Get_All_Participants_ResultDocument, options);
        }
export type Get_All_Participants_ResultQueryHookResult = ReturnType<typeof useGet_All_Participants_ResultQuery>;
export type Get_All_Participants_ResultLazyQueryHookResult = ReturnType<typeof useGet_All_Participants_ResultLazyQuery>;
export type Get_All_Participants_ResultSuspenseQueryHookResult = ReturnType<typeof useGet_All_Participants_ResultSuspenseQuery>;
export type Get_All_Participants_ResultQueryResult = Apollo.QueryResult<Get_All_Participants_ResultQuery, Get_All_Participants_ResultQueryVariables>;
export const Get_All_Test_Participants_ResultDocument = gql`
    query GET_ALL_TEST_PARTICIPANTS_RESULT($limit: Int = 10, $offset: Int = 0, $_iregex: String = "", $_eq: Int = 10, $asg_id: uuid_comparison_exp = {}) {
  examsonline_attempt(
    limit: $limit
    offset: $offset
    where: {_and: {email: {_iregex: $_iregex}}, _or: {asg_id: $asg_id, test_id: {_eq: $_eq}}}
    order_by: {percentage: desc_nulls_first}
  ) {
    id
    email
    createdAt
    updatedAt
    correct
    wrong
    percentage
    skipped
    completed
  }
  total: examsonline_attempt_aggregate(
    where: {_and: {email: {_iregex: $_iregex}}, _or: {asg_id: $asg_id, test_id: {_eq: $_eq}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGet_All_Test_Participants_ResultQuery__
 *
 * To run a query within a React component, call `useGet_All_Test_Participants_ResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_All_Test_Participants_ResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_All_Test_Participants_ResultQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      _iregex: // value for '_iregex'
 *      _eq: // value for '_eq'
 *      asg_id: // value for 'asg_id'
 *   },
 * });
 */
export function useGet_All_Test_Participants_ResultQuery(baseOptions?: Apollo.QueryHookOptions<Get_All_Test_Participants_ResultQuery, Get_All_Test_Participants_ResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_All_Test_Participants_ResultQuery, Get_All_Test_Participants_ResultQueryVariables>(Get_All_Test_Participants_ResultDocument, options);
      }
export function useGet_All_Test_Participants_ResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_All_Test_Participants_ResultQuery, Get_All_Test_Participants_ResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_All_Test_Participants_ResultQuery, Get_All_Test_Participants_ResultQueryVariables>(Get_All_Test_Participants_ResultDocument, options);
        }
export function useGet_All_Test_Participants_ResultSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_All_Test_Participants_ResultQuery, Get_All_Test_Participants_ResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_All_Test_Participants_ResultQuery, Get_All_Test_Participants_ResultQueryVariables>(Get_All_Test_Participants_ResultDocument, options);
        }
export type Get_All_Test_Participants_ResultQueryHookResult = ReturnType<typeof useGet_All_Test_Participants_ResultQuery>;
export type Get_All_Test_Participants_ResultLazyQueryHookResult = ReturnType<typeof useGet_All_Test_Participants_ResultLazyQuery>;
export type Get_All_Test_Participants_ResultSuspenseQueryHookResult = ReturnType<typeof useGet_All_Test_Participants_ResultSuspenseQuery>;
export type Get_All_Test_Participants_ResultQueryResult = Apollo.QueryResult<Get_All_Test_Participants_ResultQuery, Get_All_Test_Participants_ResultQueryVariables>;
export const Deactivate_RoleDocument = gql`
    mutation DEACTIVATE_ROLE($_eq: uuid = "", $active: Boolean!, $_iregex: String = "") {
  update_examsonline_roles(
    where: {id: {_eq: $_eq}, role: {_iregex: $_iregex}}
    _set: {active: $active}
  ) {
    returning {
      id
      active
      role
    }
  }
}
    `;
export type Deactivate_RoleMutationFn = Apollo.MutationFunction<Deactivate_RoleMutation, Deactivate_RoleMutationVariables>;

/**
 * __useDeactivate_RoleMutation__
 *
 * To run a mutation, you first call `useDeactivate_RoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivate_RoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateRoleMutation, { data, loading, error }] = useDeactivate_RoleMutation({
 *   variables: {
 *      _eq: // value for '_eq'
 *      active: // value for 'active'
 *      _iregex: // value for '_iregex'
 *   },
 * });
 */
export function useDeactivate_RoleMutation(baseOptions?: Apollo.MutationHookOptions<Deactivate_RoleMutation, Deactivate_RoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Deactivate_RoleMutation, Deactivate_RoleMutationVariables>(Deactivate_RoleDocument, options);
      }
export type Deactivate_RoleMutationHookResult = ReturnType<typeof useDeactivate_RoleMutation>;
export type Deactivate_RoleMutationResult = Apollo.MutationResult<Deactivate_RoleMutation>;
export type Deactivate_RoleMutationOptions = Apollo.BaseMutationOptions<Deactivate_RoleMutation, Deactivate_RoleMutationVariables>;
export const Order_ItemDocument = gql`
    mutation ORDER_ITEM($objects: [examsonline_order_insert_input!] = {}) {
  insert_examsonline_order(objects: $objects) {
    returning {
      id
      plan
      email
      createdAt
      role_id
      status
      test_id
      updatedAt
    }
  }
}
    `;
export type Order_ItemMutationFn = Apollo.MutationFunction<Order_ItemMutation, Order_ItemMutationVariables>;

/**
 * __useOrder_ItemMutation__
 *
 * To run a mutation, you first call `useOrder_ItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrder_ItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderItemMutation, { data, loading, error }] = useOrder_ItemMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useOrder_ItemMutation(baseOptions?: Apollo.MutationHookOptions<Order_ItemMutation, Order_ItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Order_ItemMutation, Order_ItemMutationVariables>(Order_ItemDocument, options);
      }
export type Order_ItemMutationHookResult = ReturnType<typeof useOrder_ItemMutation>;
export type Order_ItemMutationResult = Apollo.MutationResult<Order_ItemMutation>;
export type Order_ItemMutationOptions = Apollo.BaseMutationOptions<Order_ItemMutation, Order_ItemMutationVariables>;
export const My_OrdersDocument = gql`
    query MY_ORDERS($limit: Int = 10, $offset: Int = 0) {
  examsonline_order(
    limit: $limit
    offset: $offset
    order_by: {createdAt: desc_nulls_last}
  ) {
    amount
    createdAt
    email
    id
    plan
    role_id
    status
    test {
      id
      name
      files {
        id
        info
        owner
      }
      pincode
      enabled
      description
    }
  }
  total_count: examsonline_order_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useMy_OrdersQuery__
 *
 * To run a query within a React component, call `useMy_OrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMy_OrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMy_OrdersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useMy_OrdersQuery(baseOptions?: Apollo.QueryHookOptions<My_OrdersQuery, My_OrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<My_OrdersQuery, My_OrdersQueryVariables>(My_OrdersDocument, options);
      }
export function useMy_OrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<My_OrdersQuery, My_OrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<My_OrdersQuery, My_OrdersQueryVariables>(My_OrdersDocument, options);
        }
export function useMy_OrdersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<My_OrdersQuery, My_OrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<My_OrdersQuery, My_OrdersQueryVariables>(My_OrdersDocument, options);
        }
export type My_OrdersQueryHookResult = ReturnType<typeof useMy_OrdersQuery>;
export type My_OrdersLazyQueryHookResult = ReturnType<typeof useMy_OrdersLazyQuery>;
export type My_OrdersSuspenseQueryHookResult = ReturnType<typeof useMy_OrdersSuspenseQuery>;
export type My_OrdersQueryResult = Apollo.QueryResult<My_OrdersQuery, My_OrdersQueryVariables>;
export const Get_Question_By_IdDocument = gql`
    query GET_QUESTION_BY_ID($_eq: Int!) {
  examsonline_questions(where: {id: {_eq: $_eq}}) {
    ...QuestionFragment
  }
}
    ${QuestionFragmentFragmentDoc}`;

/**
 * __useGet_Question_By_IdQuery__
 *
 * To run a query within a React component, call `useGet_Question_By_IdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Question_By_IdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Question_By_IdQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_Question_By_IdQuery(baseOptions: Apollo.QueryHookOptions<Get_Question_By_IdQuery, Get_Question_By_IdQueryVariables> & ({ variables: Get_Question_By_IdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_Question_By_IdQuery, Get_Question_By_IdQueryVariables>(Get_Question_By_IdDocument, options);
      }
export function useGet_Question_By_IdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Question_By_IdQuery, Get_Question_By_IdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_Question_By_IdQuery, Get_Question_By_IdQueryVariables>(Get_Question_By_IdDocument, options);
        }
export function useGet_Question_By_IdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get_Question_By_IdQuery, Get_Question_By_IdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get_Question_By_IdQuery, Get_Question_By_IdQueryVariables>(Get_Question_By_IdDocument, options);
        }
export type Get_Question_By_IdQueryHookResult = ReturnType<typeof useGet_Question_By_IdQuery>;
export type Get_Question_By_IdLazyQueryHookResult = ReturnType<typeof useGet_Question_By_IdLazyQuery>;
export type Get_Question_By_IdSuspenseQueryHookResult = ReturnType<typeof useGet_Question_By_IdSuspenseQuery>;
export type Get_Question_By_IdQueryResult = Apollo.QueryResult<Get_Question_By_IdQuery, Get_Question_By_IdQueryVariables>;
export const Update_Question_By_IdDocument = gql`
    mutation UPDATE_QUESTION_BY_ID($_eq: Int = 10, $_set: examsonline_questions_set_input = {}) {
  update_examsonline_questions(where: {id: {_eq: $_eq}}, _set: $_set) {
    returning {
      ...QuestionFragment
    }
  }
}
    ${QuestionFragmentFragmentDoc}`;
export type Update_Question_By_IdMutationFn = Apollo.MutationFunction<Update_Question_By_IdMutation, Update_Question_By_IdMutationVariables>;

/**
 * __useUpdate_Question_By_IdMutation__
 *
 * To run a mutation, you first call `useUpdate_Question_By_IdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Question_By_IdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionByIdMutation, { data, loading, error }] = useUpdate_Question_By_IdMutation({
 *   variables: {
 *      _eq: // value for '_eq'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdate_Question_By_IdMutation(baseOptions?: Apollo.MutationHookOptions<Update_Question_By_IdMutation, Update_Question_By_IdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_Question_By_IdMutation, Update_Question_By_IdMutationVariables>(Update_Question_By_IdDocument, options);
      }
export type Update_Question_By_IdMutationHookResult = ReturnType<typeof useUpdate_Question_By_IdMutation>;
export type Update_Question_By_IdMutationResult = Apollo.MutationResult<Update_Question_By_IdMutation>;
export type Update_Question_By_IdMutationOptions = Apollo.BaseMutationOptions<Update_Question_By_IdMutation, Update_Question_By_IdMutationVariables>;
export const Update_Options_By_Question_IdDocument = gql`
    mutation UPDATE_OPTIONS_BY_QUESTION_ID($_set: examsonline_options_set_input = {}, $_eq: Int!) {
  update_examsonline_options(where: {q_id: {_eq: $_eq}}, _set: $_set) {
    returning {
      id
      isCorrect
      q_id
      type
      value
    }
  }
}
    `;
export type Update_Options_By_Question_IdMutationFn = Apollo.MutationFunction<Update_Options_By_Question_IdMutation, Update_Options_By_Question_IdMutationVariables>;

/**
 * __useUpdate_Options_By_Question_IdMutation__
 *
 * To run a mutation, you first call `useUpdate_Options_By_Question_IdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Options_By_Question_IdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOptionsByQuestionIdMutation, { data, loading, error }] = useUpdate_Options_By_Question_IdMutation({
 *   variables: {
 *      _set: // value for '_set'
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useUpdate_Options_By_Question_IdMutation(baseOptions?: Apollo.MutationHookOptions<Update_Options_By_Question_IdMutation, Update_Options_By_Question_IdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_Options_By_Question_IdMutation, Update_Options_By_Question_IdMutationVariables>(Update_Options_By_Question_IdDocument, options);
      }
export type Update_Options_By_Question_IdMutationHookResult = ReturnType<typeof useUpdate_Options_By_Question_IdMutation>;
export type Update_Options_By_Question_IdMutationResult = Apollo.MutationResult<Update_Options_By_Question_IdMutation>;
export type Update_Options_By_Question_IdMutationOptions = Apollo.BaseMutationOptions<Update_Options_By_Question_IdMutation, Update_Options_By_Question_IdMutationVariables>;