import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  CardMedia,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Formatter from "shared/translations/formatter";

export default function AboutUs() {
  return (
    <Container id="about" className="p-3 p-5 p-lg-0">
      <Grid container spacing={3} >
      <Grid item xs={12} lg={4} md={4} >
        <IconBox
          imageSrc={"/about/pie-chart.svg"}
          altText="easy create test"
          title={"home.about.analytics"}
          description1={null}
          description2={"home.about.analytics-description2"}
          cardImage={"/about/analytics.png"}
        />
      </Grid>
      <Grid item xs={12} lg={4} md={4}>
        <IconBox
          imageSrc={"/about/easy.svg"}
          altText="easy create test"
          title={"home.about.simple"}
          description1={null}
          description2={"home.about.simple-description"}
          cardImage={"/about/simple.png"}
        />
      </Grid>

      <Grid item xs={12} lg={4} md={4}>
        <IconBox
          imageSrc={"/about/secure.png"}
          altText="secure"
          title={"home.secure"}
          description1={null}
          description2={"home.secure-description"}
          cardImage={"/about/secure.png"}
        />
      </Grid>
    </Grid>
            
      </Container >
  );
}

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
});


function IconBox({ imageSrc, altText, title, description1, description2, cardImage }) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      {cardImage && (
        <CardMedia
          component="img"
          alt="Card image"
          height={190}
          image={cardImage}
        />
      )}
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          <Formatter options={{}} text={title} />
        </Typography>
        <Typography color="textSecondary">
          <Formatter options={{}} text={description1} />
        </Typography>
        {description2 && (
          <Typography variant="body2" component="p">
            <Formatter options={{}} text={description2} />
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}